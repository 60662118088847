<template>
    <div>
        <div class="sa-tab-scroll">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-card class="mb-4">
                        <b-card-body class="text-left">
                            <b-row>
                                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                    <h4 class="text-left">
                                        {{ getLabelTraduora("patient.diet.lblDietType") }}
                                        <a data-toggle="modal" data-target="#collapse-info" href="#collapse-info" @click="showModal()" onclick="return false"><b-icon icon="info-circle" font-scale="1"></b-icon></a>
                                    </h4>
                                    <span class="text-left" :key="componentKey">{{ tipoDieta }}</span>
                                    <b-modal id="collapse-info" centered :title="tipoDieta" size="lg" v-if="data.descrizione !== null" hide-footer>
                                        <b-row class="text-justify" style="padding-left:10px; padding-right:10px;">
                                            <b-col cols="12">
                                                <span>{{ descrizioneDieta }}</span>
                                            </b-col>
                                        </b-row>
                                        <hr />
                                        <div style="height: 30px;"></div>
                                    </b-modal>
                                </b-col>
                                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                    <h4 class="text-left">
                                        {{ getLabelTraduora("patient.diet.lblStartDate") }}
                                    </h4>
                                    <span>{{ formatDate(data.dataInizio) }}</span>
                                </b-col>
                                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                    <h4 class="text-left">
                                        {{ getLabelTraduora("therapy.lblEndDate") }}
                                    </h4>
                                    <span>{{ formatDate(data.dataFine) }}</span>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                    <h4 class="text-left">
                                        {{ getLabelTraduora("patient.diet.lblNote") }}
                                    </h4>
                                    <span class="text-left">{{ data.note }}</span>
                                </b-col>
                                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                    <h4 class="text-left">
                                        BMI
                                    </h4>
                                    <span class="text-left"></span>
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
            <b-row cols="12">
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <!-- <b-card class="mb-4">
            <h4>{{ getLabelTraduora("patient.diet.lblDietLastAnswer") }}:</h4>
            <br />
            <p v-if="risposteQuestionarioDiete.length === 0">{{ getLabelTraduora("patient.diet.lblNoAnswer") }}</p>
            <b-form-group v-if="risposteQuestionarioDiete.length !== 0">
              <b-row class="text-left" cols="12" v-for="item in risposteQuestionarioDiete" :key="item.id">
                <b-col cols="12" v-if="item.measurename === 'Colazione'"
                  ><span
                    ><b>{{ returnQuantita(item.value, "patient.diet.lblBreakfast") }}</b></span
                  ></b-col
                ><b-col cols="12" v-if="item.measurename === 'Spuntino_meta_mattina'">
                  <span
                    ><b>{{ returnQuantita(item.value, "patient.diet.lblMorningSnack") }}</b></span
                  ></b-col
                >
                <b-col cols="12" v-if="item.measurename === 'Pranzo'"
                  ><span
                    ><b>{{ returnQuantita(item.value, "patient.diet.lblLunch") }}</b></span
                  ></b-col
                >
                <b-col cols="12" v-if="item.measurename === 'Spuntino_pomeridiano'"
                  ><span
                    ><b>{{ returnQuantita(item.value, "patient.diet.lblAfternoonSnack") }}</b></span
                  ></b-col
                >
                <b-col cols="12" v-if="item.measurename === 'Cena'"
                  ><span
                    ><b>{{ returnQuantita(item.value, "patient.diet.lblDinner") }}</b></span
                  ></b-col
                >
                <b-col cols="12" v-if="item.measurename === 'Spuntino_serale'"
                  ><span
                    ><b>{{ returnQuantita(item.value, "patient.diet.lblNightSnack") }}</b></span
                  ></b-col
                >
                <b-col cols="12" v-if="item.measurename === 'Sale'"
                  ><span
                    ><b>{{ traduciConValue("patient.diet.lblvarSalt", item.value) }}</b></span
                  ></b-col
                ><b-col cols="12" v-if="item.measurename === 'Acqua'"
                  ><span
                    ><b>{{ traduciConValue("patient.diet.lblvarWater", item.value) }}</b></span
                  ></b-col
                >
              </b-row>
            </b-form-group>
          </b-card> -->
                    <paziente-dieta-view-questionari :idMeasure="'diet'" :idPaziente="idPaziente" :forPage="8"></paziente-dieta-view-questionari>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
                    <h4>{{ getLabelTraduora("patient.diet.lblWeightTrend") }}</h4>
                    <b-card class="mb-4">
                        <!-- Grafico andamento peso -->
                        <b-form-group>
                            <b-row
                                ><b-col><b-form-select v-model="meseSelezione" :options="opzioniSelezione"></b-form-select></b-col><b-col> <b-form-input type="number" min="1900" max="2100" v-model="annoSelezione"></b-form-input></b-col><b-col></b-col
                                ><b-col>
                                    <b-button class="secondary" @click="misureGraficoPeso(meseSelezione, annoSelezione, idPaziente)">{{ getLabelTraduora("patient.diet.lblLoadData") }}</b-button>
                                </b-col></b-row
                            >
                        </b-form-group>
                        <line-chart :chart-data="chartAndamentoPeso" :height="75" :width="250" :options="options"></line-chart>
                    </b-card>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="4">
                    <b-card>
                        <b-card-title style="width: 100%">
                            <b-row>
                                <b-col cols="4">
                                    <label for="">
                                        <img src="@/assets/images/users/breakfast.svg" alt="" width="85px /" />
                                    </label>
                                </b-col>
                                <b-col cols="8">
                                    <label for="">
                                        <h4 class="sa-card-title-position">
                                            {{ getLabelTraduora("patient.diet.lblBreakfast") }}
                                        </h4>
                                    </label>
                                </b-col>
                            </b-row>
                        </b-card-title>
                        <b-card-body>
                            <b-row v-for="(item, index) in breakfastData" :key="index">
                                <b-col cols="4">
                                    <label>
                                        <p class="text-muted">{{ item.pietanza }}</p>
                                    </label>
                                </b-col>
                                <b-col cols="3">
                                    <strong>{{ getLabelTraduora("patient.diet.lblQuantity") }}:</strong>
                                    {{ item.quantita }} ({{ item.unitaMisura }})
                                </b-col>
                                <b-col cols="3">
                                    <strong>{{ getLabelTraduora("patient.diet.lblKcal") }}:</strong>
                                    {{ item.kiloCal }}
                                </b-col>
                                <b-col cols="2"> </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-col>

                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="4">
                    <b-card>
                        <b-card-title style="width: 100%">
                            <b-row>
                                <b-col cols="4">
                                    <label for="">
                                        <img src="@/assets/images/users/apple.svg" alt="" width="85px /" style="float: right" />
                                    </label>
                                </b-col>
                                <b-col cols="8">
                                    <label for="">
                                        <h4 class="sa-card-title-position">
                                            {{ getLabelTraduora("patient.diet.lblMorningSnack") }}
                                        </h4>
                                    </label>
                                </b-col>
                            </b-row>
                        </b-card-title>
                        <b-card-body>
                            <b-row v-for="(item, index) in morningSnackData" :key="index">
                                <b-col cols="4">
                                    <label>
                                        <p class="text-muted">{{ item.pietanza }}</p>
                                    </label>
                                </b-col>
                                <b-col cols="3">
                                    <strong>{{ getLabelTraduora("patient.diet.lblQuantity") }}:</strong>
                                    {{ item.quantita }} ({{ item.unitaMisura }})
                                </b-col>
                                <b-col cols="3">
                                    <strong>{{ getLabelTraduora("patient.diet.lblKcal") }}:</strong>
                                    {{ item.kiloCal }}
                                </b-col>
                                <b-col cols="2"> </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-col>

                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="4">
                    <b-card>
                        <b-card-title style="width: 100%">
                            <b-row>
                                <b-col cols="4">
                                    <label for="">
                                        <img src="@/assets/images/users/lunch-bag.svg" alt="" width="85px /" />
                                    </label>
                                </b-col>
                                <b-col cols="8">
                                    <label for="">
                                        <h4 class="sa-card-title-position">
                                            {{ getLabelTraduora("patient.diet.lblLunch") }}
                                        </h4>
                                    </label>
                                </b-col>
                            </b-row>
                        </b-card-title>
                        <b-card-body>
                            <b-row v-for="(item, index) in lunchData" :key="index">
                                <b-col cols="4">
                                    <label>
                                        <p class="text-muted">{{ item.pietanza }}</p>
                                    </label>
                                </b-col>
                                <b-col cols="3">
                                    <strong>{{ getLabelTraduora("patient.diet.lblQuantity") }}:</strong>
                                    {{ item.quantita }} ({{ item.unitaMisura }})</b-col
                                >
                                <b-col cols="3">
                                    <strong>{{ getLabelTraduora("patient.diet.lblKcal") }}:</strong>
                                    {{ item.kiloCal }}
                                </b-col>
                                <b-col cols="2"> </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-col>

                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="4">
                    <b-card>
                        <b-card-title style="width: 100%">
                            <b-row>
                                <b-col cols="4">
                                    <label for="">
                                        <img src="@/assets/images/users/energy-food.svg" alt="" width="85px /" />
                                    </label>
                                </b-col>
                                <b-col cols="8">
                                    <label for="">
                                        <h4 class="sa-card-title-position">
                                            {{ getLabelTraduora("patient.diet.lblAfternoonSnack") }}
                                        </h4>
                                    </label>
                                </b-col>
                            </b-row>
                        </b-card-title>
                        <b-card-body>
                            <b-row v-for="(item, index) in afternoonSnackData" :key="index">
                                <b-col cols="4">
                                    <label>
                                        <p class="text-muted">{{ item.pietanza }}</p>
                                    </label>
                                </b-col>
                                <b-col cols="3">
                                    <strong>{{ getLabelTraduora("patient.diet.lblQuantity") }}:</strong>
                                    {{ item.quantita }} ({{ item.unitaMisura }})</b-col
                                >
                                <b-col cols="3">
                                    <strong>{{ getLabelTraduora("patient.diet.lblKcal") }}:</strong>
                                    {{ item.kiloCal }}
                                </b-col>
                                <b-col cols="2"> </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-col>

                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="4">
                    <b-card>
                        <b-card-title style="width: 100%">
                            <b-row>
                                <b-col cols="4">
                                    <label for="">
                                        <img src="@/assets/images/users/dinner.svg" alt="" width="85px /" />
                                    </label>
                                </b-col>
                                <b-col cols="8">
                                    <label for="">
                                        <h4 class="sa-card-title-position">
                                            {{ getLabelTraduora("patient.diet.lblDinner") }}
                                        </h4>
                                    </label>
                                </b-col>
                            </b-row>
                        </b-card-title>
                        <b-card-body>
                            <b-row v-for="(item, index) in dinnerData" :key="index">
                                <b-col cols="4">
                                    <label>
                                        <p class="text-muted">{{ item.pietanza }}</p>
                                    </label>
                                </b-col>
                                <b-col cols="3">
                                    <strong>{{ getLabelTraduora("patient.diet.lblQuantity") }}:</strong>
                                    {{ item.quantita }} ({{ item.unitaMisura }})</b-col
                                >
                                <b-col cols="3">
                                    <strong>{{ getLabelTraduora("patient.diet.lblKcal") }}:</strong>
                                    {{ item.kiloCal }}
                                </b-col>
                                <b-col cols="2"> </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-col>

                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="4">
                    <b-card>
                        <b-card-title style="width: 100%">
                            <b-row>
                                <b-col cols="4">
                                    <label for="">
                                        <img src="@/assets/images/users/fruits.svg" alt="" width="85px /" />
                                    </label>
                                </b-col>
                                <b-col cols="8">
                                    <label for="">
                                        <h4 class="sa-card-title-position">
                                            {{ getLabelTraduora("patient.diet.lblNightSnack") }}
                                        </h4>
                                    </label>
                                </b-col>
                            </b-row>
                        </b-card-title>
                        <b-card-body>
                            <b-row v-for="(item, index) in nightSnackData" :key="index">
                                <b-col cols="4">
                                    <label>
                                        <p class="text-muted">{{ item.pietanza }}</p>
                                    </label>
                                </b-col>
                                <b-col cols="3">
                                    <strong>{{ getLabelTraduora("patient.diet.lblQuantity") }}:</strong>
                                    {{ item.quantita }} ({{ item.unitaMisura }})</b-col
                                >
                                <b-col cols="3">
                                    <strong>{{ getLabelTraduora("patient.diet.lblKcal") }}:</strong>
                                    {{ item.kiloCal }}
                                </b-col>
                                <b-col cols="2"> </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-col>

                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="4">
                    <b-card>
                        <b-card-title style="width: 100%">
                            <b-row>
                                <b-col cols="4">
                                    <label for="">
                                        <img src="@/assets/images/users/salt.svg" alt="" width="85px /" />
                                    </label>
                                </b-col>
                                <b-col cols="8">
                                    <label for="">
                                        <h4 class="sa-card-title-position">
                                            {{ getLabelTraduora("patient.diet.lblSalt") }}
                                        </h4>
                                    </label>
                                </b-col>
                            </b-row>
                        </b-card-title>
                        <b-card-body>
                            <b-row v-for="(item, index) in saltData" :key="index">
                                <b-col cols="4">
                                    <label>
                                        <p class="text-muted">{{ item.pietanza }}</p>
                                    </label>
                                </b-col>
                                <b-col cols="3">
                                    <strong>{{ getLabelTraduora("patient.diet.lblQuantity") }}:</strong>
                                    {{ item.quantita }} ({{ item.unitaMisura }})</b-col
                                >
                                <b-col cols="3">
                                    <strong>{{ getLabelTraduora("patient.diet.lblKcal") }}:</strong>
                                    {{ item.kiloCal }}
                                </b-col>
                                <b-col cols="2"> </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="4">
                    <b-card>
                        <b-card-title style="width: 100%">
                            <b-row>
                                <b-col cols="4">
                                    <label for="">
                                        <img src="@/assets/images/users/waterglass.svg" alt="" width="85px /" />
                                    </label>
                                </b-col>
                                <b-col cols="8">
                                    <label for="">
                                        <h4 class="sa-card-title-position">
                                            {{ getLabelTraduora("patient.diet.lblWaterGlasses") }}
                                        </h4>
                                    </label>
                                </b-col>
                            </b-row>
                        </b-card-title>
                        <b-card-body>
                            <b-row v-for="(item, index) in saltData" :key="index">
                                <b-col cols="4">
                                    <label>
                                        <p class="text-muted">{{ item.pietanza }}</p>
                                    </label>
                                </b-col>
                                <b-col cols="3">
                                    <strong>{{ getLabelTraduora("patient.diet.lblQuantity") }}:</strong>
                                    {{ item.quantita }} ({{ item.unitaMisura }})</b-col
                                >
                                <b-col cols="3">
                                    <strong>{{ getLabelTraduora("patient.diet.lblKcal") }}:</strong>
                                    {{ item.kiloCal }}
                                </b-col>
                                <b-col cols="2"> </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
            <div></div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import LineChart from "./LineChart.js";
import UtilityMixin from "../../utility/UtilityMixin.js";
import PazienteDietaViewQuestionari from "./PazienteDietaViewQuestionari.vue";

export default {
    mixins: [UtilityMixin],
    components: { LineChart, PazienteDietaViewQuestionari },
    props: {
        utente: {
            type: Object,
            default: function() {
                return null;
            },
        },
        id: {
            Type: String,
            default: function() {
                return "-1";
            },
        },
    },
    data() {
        return {
            showModalLoading: false,
            linkback: "/paziente/diete",
            linkedit: "/paziente/diete",
            pathResource: "/dieta",
            nuovaPietanza: {
                pasto: "",
                pietanza: "",
                quantita: "",
                unitaMisura: "",
                kiloCal: "",
            },
            componentKey: 0,
            risposteQuestionarioDiete: [],
            breakfastData: [],
            morningSnackData: [],
            lunchData: [],
            afternoonSnackData: [],
            dinnerData: [],
            nightSnackData: [],
            saltData: [],
            items: [],
            data: {},
            tipiDieta: [],
            descrizioneDieta: null,
            tipoDieta: null,
            chartAndamentoPeso: {},
            meseSelezione: null,
            opzioniSelezione: [
                { text: this.getLabelTraduora("patient.lifeStyle.lblJanuary"), value: 1 },
                { text: this.getLabelTraduora("patient.lifeStyle.lblFebruary"), value: 2 },
                { text: this.getLabelTraduora("patient.lifeStyle.lblMarch"), value: 3 },
                { text: this.getLabelTraduora("patient.lifeStyle.lblApril"), value: 4 },
                { text: this.getLabelTraduora("patient.lifeStyle.lblMay"), value: 5 },
                { text: this.getLabelTraduora("patient.lifeStyle.lblJune"), value: 6 },
                { text: this.getLabelTraduora("patient.lifeStyle.lblJune"), value: 7 },
                { text: this.getLabelTraduora("patient.lifeStyle.lblAugust"), value: 8 },
                { text: this.getLabelTraduora("patient.lifeStyle.lblSeptember"), value: 9 },
                { text: this.getLabelTraduora("patient.lifeStyle.lblOctober"), value: 10 },
                { text: this.getLabelTraduora("patient.lifeStyle.lblNovember"), value: 11 },
                { text: this.getLabelTraduora("patient.lifeStyle.lblDecember"), value: 12 },
            ],
            annoSelezione: null,
            options: {
                scales: {
                    yAxes: [
                        {
                            display: true,
                            ticks: {
                                suggestedMin: 20,
                            },
                        },
                    ],
                },
            },
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient: {
            get: function() {
                let returnValue = "";
                if (this.utente.gruppo) {
                    if (this.utente.gruppo.menuGruppo) {
                        if (this.utente.gruppo.menuGruppo.length > 1) {
                            return "/paziente/view/" + this.idPaziente;
                        } else {
                            if (this.utente.gruppo.menuGruppo.length == 1) {
                                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                                    return "/paziente/view/" + this.idPaziente;
                                } else {
                                    return "/paziente/homepaziente";
                                }
                            }
                        }
                    }
                }
                return returnValue;
            },
        },
        btnNewVisible: {
            get: function() {
                let me = this;
                return UtilityMixin.methods.verificaPermessi(me.utente, "dieta", 4);
            },
        },
    },
    watch: {
        id: {
            immediate: true,
            handler() {
                if (this.id !== "-1") {
                    this.loadData();
                }
            },
        },
    },
    mounted: async function() {
        let me = this;
        me.meseSelezione = new Date(Date.now()).getMonth() + 1;
        me.annoSelezione = new Date(Date.now()).getFullYear();
        let id = this.$route.query.idPaziente;

        await me.getTipiDieta();
        me.risposteDieta(id);
    },
    methods: {
        showModal() {
            this.$bvModal.show("collapse-info");
        },
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY");
            } else {
                return "---";
            }
        },
        getTipiDieta() {
            let me = this;
            me.tipiDieta = [];
            let link = process.env.VUE_APP_PATH_API + "/pazientedietatipi";
            axios
                .get(link)
                .then((res) => {
                    let arrayDiete = res.data.data;
                    arrayDiete.forEach((element) => {
                        let obj = { text: this.getLabelTraduora(element.nomeDieta), value: element.dieta, description: this.getLabelTraduora(element.descrizione) };
                        me.tipiDieta.push(obj);
                    });
                })
                .catch();
        },
        risposteDieta(idPaziente) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + "/hyperhealth/lastmeasure?idPaziente=" + idPaziente;
            try {
                axios
                    .get(link)
                    .then((res) => {
                        let misure = [];
                        misure = res.data.data;
                        if (misure !== null && misure !== undefined && misure !== []) {
                            misure.forEach((element) => {
                                let abitudini = ["lbl.measure.breakfast", "lbl.measure.midMorningSnack", "lbl.measure.lunch", "lbl.measure.afternoonSnack", "lbl.measure.dinner", "lbl.measure.eveningSnack", "lbl.measure.salt", "lbl.measure.water"];
                                if (abitudini.includes(element.measurename)) {
                                    me.risposteQuestionarioDiete.push(element);
                                }
                            });
                        }
                        // console.log(me.risposteQuestionarioDiete);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } catch (e) {
                console.log(e);
            }
        },
        loadData() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            axios.get(link + me.id).then((response) => {
                me.data = response.data.data;
                // console.log(me.data);
                me.getTipoDieta(me.data.tipo);
                me.data.pazienteDietaDettaglio.forEach((element) => {
                    switch (element.pasto) {
                        case "BREAKFAST":
                            me.breakfastData.push(element);
                            break;
                        case "MORNINGSNACK":
                            me.morningSnackData.push(element);
                            break;
                        case "LUNCH":
                            me.lunchData.push(element);
                            break;
                        case "AFTERNOONSNACK":
                            me.afternoonSnackData.push(element);
                            break;
                        case "DINNER":
                            me.dinnerData.push(element);
                            break;
                        case "NIGHTSNACK":
                            me.nightSnackData.push(element);
                            break;
                        case "SALT":
                            me.saltData.push(element);
                            break;
                    }
                });
                //console.log(me.data.pazienteDietaDettaglio);
                me.showModalLoading = false;
            });
        },
        returnQuantita(value, label) {
            let me = this;
            let variable = me.getLabelTraduora(label);
            let returnValue = "";

            if (value === "0") {
                returnValue = me.traduciConValue("patient.diet.lblvarNothing", variable);
            }
            if (value === "1") {
                returnValue = me.traduciConValue("patient.diet.lblvarLittle", variable);
            }
            if (value === "2") {
                returnValue = me.traduciConValue("patient.diet.lblvarNormal", variable);
            }
            if (value === "3") {
                returnValue = me.traduciConValue("patient.diet.lblvarALot", variable);
            }
            if (value === "4") {
                returnValue = me.traduciConValue("patient.diet.lblvarTooMuch", variable);
            }
            return returnValue;
        },
        misureGraficoPeso(mese, anno, idPaziente) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + "/pazientemisure?idPaziente=" + idPaziente + "&dal=" + anno + "-" + mese + "-01 00:00:00&al=" + anno + "-" + mese + "-31 00:00:00&misura=lbl.measure.weight";
            axios.get(link).then((res) => {
                // console.log(res.data.data);
                let datePeso = [];
                let misurePeso = [];
                let dati = res.data.data.list;
                if (dati !== null && dati !== undefined && dati !== []) {
                    for (let i = 0; i < dati.length; i++) {
                        // if (!dati[i].measurename.includes("Mass")) {
                        // console.log(dati[i]);
                        datePeso.push(moment(dati[i].istant).format("DD/MM"));
                        misurePeso.push(dati[i].value);
                        // }
                    }
                }
                me.chartAndamentoPeso = {
                    labels: datePeso,
                    datasets: [
                        {
                            label: this.getLabelTraduora("patient.diet.lblWeightGraph"),
                            data: misurePeso,
                            lineTension: 0,
                            backgroundColor: "rgba(255, 100, 100, 0.2)",
                            pointBorderColor: "rgba(255, 100, 100, 1)",
                            bgColor: "'rgba(100, 0, 0, 0)'",
                            dotColor: "'rgba(100, 10, 255, 1.8)'",
                            borderColor: "'rgba(100, 10, 255, 1)'",
                        },
                    ],
                };
            });
        },
        andamentoBMI() {},
        getTipoDieta(tipo) {
            let me = this;
            me.tipiDieta.forEach((element) => {
                if (element.value === tipo) {
                    me.tipoDieta = element.text;
                    me.descrizioneDieta = element.description;
                    me.componentKey++;
                }
            });
        },
    },
};
</script>
<style lang="css" scoped>
.btn-card-bottom {
    position: relative;
    top: 30px;
}
.sa-card-title-position {
    margin-top: 40px;
    margin-left: 5px;
}
</style>
