<template>
  <b-card class="mb-8">
    <div class="bg-picture card-box">
      <div class="profile-info-name">
        <div class="container-fluid">
          <b-row>
            <b-col>
              <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                  <div style="height: 100%; display: flex; align-items: center;">
                    <div style="width: 6rem; height: 6rem; float: left;"><img :src="patientAvatar" class="rounded-circle avatar-xl img-thumbnail float-left mr-3" alt="profile-image" /></div>
                    <div style="margin-left: 10px;">
                      <h4 class="m-0">{{ data.cognome }} {{ data.nome }}</h4>
                      <p class="text-muted">
                        <i>{{ data.identificativo }}</i>
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="text-right">
              <b-button variant="purple btn-generic btn-notext sa-margin-right" size="sm" @click="onEdit"><b-icon icon="pencil"></b-icon></b-button>
              <b-button variant="purple btn-generic btn-notext sa-margin-right" size="sm" v-b-toggle.info-1><b-icon icon="info" scale="2"></b-icon></b-button>
            </b-col>
          </b-row>
        </div>
        <hr />

        <b-collapse id="info-1" role="tabpanel">
          <div class="row">
            <div class="col">
              <strong>{{this.$i18n.t("patients.lblFiscalCode")}}</strong><br />
              {{ data.identificativo }}
            </div>
            <div class="col">
              <strong>{{this.$i18n.t("patient.lblSex")}}</strong><br />
              <i class="fas fa-venus rosa font-18"></i> {{ data.sesso }}
            </div>
            <div class="col">
              <strong><i class="fas fa-tint rosso"></i>{{this.$i18n.t("patients.lblBloodGroup")}}</strong><br />
              {{ data.gruppoSanguigno }}
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col">
              <strong>{{this.$i18n.t("patients.birthPlace")}}</strong><br />
              {{ data.comuneNascita }} ({{ data.provinciaNascita }})
            </div>
            <div class="col">
              <strong>{{this.$i18n.t("patients.lblBirthDate")}} </strong><br />
              {{ formatDate(data.dataNascita) }}
            </div>
            <div class="col">
              <strong>{{this.$i18n.t("patient.lblResidence")}} </strong><br />
              {{ data.indirizzoResidenza }} {{ data.capResidenza }} {{ data.comuneResidenza }} ({{ data.provinciaResidenza }})
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col">
              <strong>{{this.$i18n.t("patients.lblDomicile")}} </strong><br />
              {{ data.indirizzoDomicilio }} {{ data.capDomicilio }} {{ data.comuneDomicilio }} ({{ data.provinciaDomicilio }})
            </div>
            <div class="col">
              <strong>{{this.$i18n.t("patient.lblPhone")}} </strong><br />
              {{ data.telefono }}
            </div>
            <div class="col">
              <strong>{{this.$i18n.t("patients.lblEmail")}}</strong><br />
              {{ data.email }}
            </div>
          </div>
          <hr />
        </b-collapse>
        <h5 class="mt-0">
          <span class="text-dark"><i class="fas fa-comment-medical "></i> {{ this.$i18n.t("patients.lblSharedSupportPlan") }}</span>
        </h5>
        <!-- <b-button class="float-sm-right " variant="primary btn-toolbar" size="sm" @click="onDashboard"
                  ><b-icon icon="tachometer-alt" variant="secondary"></b-icon>{{ this.$i18n.t("patients.lblGraphChart") }}</b-button
                >
                <b-button class="float-sm-right " variant="primary btn-toolbar" size="sm" @click="onAllegati"
                  ><b-icon icon="paperclip" variant="secondary"></b-icon>{{ this.$i18n.t("patient.attachments.lblAttachments") }}</b-button
                > -->
        <div class="clearfix"></div>
      </div>
    </div>
  </b-card>
</template>
<script>
import moment from "moment";
import female from "@/assets/images/female.jpg";
import male from "@/assets/images/male.jpg";

export default {
  props: ["data"],
  data() {
    return {
      pagelink: "/paziente",
    };
  },
  computed: {
    idPaziente() {
      return this.$route.params.id;
    },
    patientAvatar: {
      get: function() {
        let me = this;
        if (me.data.sesso === "M") {
          return male;
        } else {
          return female;
        }
      },
    },
  },
  mounted() {
    this.id = this.$route.params.id;
  },
  methods: {
    forceRerender() {
      let me = this;
      me.componentKey += 1;
    },
    getItemMenuHref(value) {
      let me = this;
      let href = "#/paziente" + value + "/?idPaziente=" + me.id;

      return href;
    },
    formatDate(value) {
      if (value) {
        return moment(new Date(value)).format("DD-MM-YYYY");
      } else {
        return "---";
      }
    },
    formatDateTime(value) {
      if (value) {
        return moment(new Date(value)).format("DD-MM-YYYY HH:mm");
      } else {
        return "---";
      }
    },
    onPazienteTeleconsulto: function() {
      let me = this;
      me.$router.replace("/paziente/teleconsulti/" + me.id).catch((err) => {
        err;
      });
    },
    onEdit: function() {
      let me = this;
      me.$router.replace(me.pagelink + "/edit/" + me.id).catch((err) => {
        err;
      });
    },
    onDashboard() {
      let me = this;
      me.$router.replace("paziente/dashboard?idPaziente=" + me.idPaziente).catch((err) => {
        err;
      });
    },
    onAllegati() {
      let me = this;
      me.$router.replace("/paziente/allegati/" + me.id).catch((err) => {
        err;
      });
    },
  },
};
</script>
