<template>
  <sa-page-layout :showModalLoading="showModalLoading" :btnNewVisible="true" :btnRefreshVisible="true" @refresh="onRefresh()" :linkedit="linkedit" :pathResource="pathResource">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-header">
      <h3>{{ getLabelTraduora("patient.afa.lblVisit") }}</h3>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" :filter="filter" responsive stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template v-slot:cell(dataEvento)="{ item }">
            <router-link class="sa-edit-link" :to="'/paziente/medicinadellosportafa/view/' + item.id">{{ formatDateTime(item.dataEvento) }}</router-link>
          </template>
          <template #cell(actions)="row">
            <b-button size="sm" variant="btn btn-outline-primary waves-effect  waves-light width-sm no-text" style="margin-right:3px" @click="onStampa(row.item)">
              <b-icon icon="printer"></b-icon>
            </b-button>
            <b-button size="sm" variant="btn btn-outline-info no-text" @click="onCopy(row.item)">
              <b-icon icon="files"></b-icon>
            </b-button>
          </template>
        </b-table>
      </div>
      <b-modal ref="mdlStampaReferto" size="xl" style="height: 100%;">
        <b-embed type="iframe" :src="stampaReport" allowfullscreen></b-embed>
      </b-modal>
    </template>
  </sa-page-layout>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { SaPageLayout } from "../../../../../../template/sa/components/layout/contents";
import PazienteAnagrafica from "../../../../components/PazienteAnagrafica.vue";
import UtilityMixin from "../../../../../utility/UtilityMixin.js";
export default {
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  mixins: [UtilityMixin],
  components: {
    SaPageLayout,
    PazienteAnagrafica,
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient: {
      get: function() {
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length == 1) {
              if (this.utente.gruppo.menuGruppo[0].primaryKey === "homestudiomedico") {
                return "/paziente/view/" + this.idPaziente;
              } else if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                return "view/" + this.idPaziente;
              } else {
                return "homepaziente";
              }
            } else {
              return "";
            }
          } else {
            return "";
          }
        } else {
          return "";
        }
      },
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      filter: {},
      stampaReport: null,
      showModalLoading: false,
      linkedit: "/paziente/medicinadellosportafa",
      pathResourceStampa: "/print/createreport",
      pathResource: "/medicinadellosportafa",
      fields: [
        {
          label: this.getLabelTraduora("patient.events.lblEventDate"),
          key: "dataEvento",
          sortable: true,
          formatter: (value) => {
            return moment(new Date(value)).format("DD-MM-YYYY");
          },
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 6rem",
        },
      ],
      items: [],
    };
  },
  mounted() {
    let me = this;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "?idPaziente=";
      axios
        .get(link + me.idPaziente, { params: me.filtro })
        .then((response) => {
          me.items = [];
          me.items = response.data.data;
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    onStampa(row) {
      let me = this;
      // console.log(row);
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + row.id;
      axios.get(link).then((response) => {
        let printData = response.data.data;
        let linkReport = process.env.VUE_APP_PATH_API + me.pathResourceStampa;
        axios.post(linkReport, { reportName: me.reportName, data: printData }).then((response) => {
          me.$refs.mdlStampaReferto.show();
          me.stampaReport = "data:application/pdf;base64," + response.data.base64;
        });
      });
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onCopy(row) {
      let me = this;
      console.log(row);
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/duplicavisita/" + row.id;
      axios.post(link).then((response) => {
        me.items.push(response.data.data);
        me.loadData();
      });
    },
  },
};
</script>
