import GastroAnamnesiPatologicaList from "./anamnesipatologica/GastroAnamnesiPatologicaList.vue";
import GastroAnamnesiPatologicaEdit from "./anamnesipatologica/GastroAnamnesiPatologicaEdit.vue";
import GastroAnamnesiPatologicaView from "./anamnesipatologica/GastroAnamnesiPatologicaView.vue";

import GastroAnamnesiFisiologicaList from "./anamnesifisiologica/GastroAnamnesiFisiologicaList.vue";
import GastroAnamnesiFisiologicaEdit from "./anamnesifisiologica/GastroAnamnesiFisiologicaEdit.vue";
import GastroAnamnesiFisiologicaView from "./anamnesifisiologica/GastroAnamnesiFisiologicaView.vue";

import GastroAnamnesiFamiliareList from "./anamensifamiliare/GastroAnamnesiFamiliareList.vue";
import GastroAnamnesiFamiliareEdit from "./anamensifamiliare/GastroAnamnesiFamiliareEdit.vue";
import GastroAnamnesiFamiliareView from "./anamensifamiliare/GastroAnamnesiFamiliareView.vue";
import GastroAnoRettoColonscopiaList from "./eventi/gastroanorettocolonscopia/GastroAnoRettoColonscopiaList.vue";
import GastroAnoRettoColonscopiaEdit from "./eventi/gastroanorettocolonscopia/GastroAnoRettoColonscopiaEdit.vue";
import GastroAnoRettoColonscopiaView from "./eventi/gastroanorettocolonscopia/GastroAnoRettoColonscopiaView.vue";
import EgdsEdit from "./eventi/egds/EgdsEdit.vue";
import EgdsView from "./eventi/egds/EgdsView.vue";
import EgdsList from "./eventi/egds/EgdsList.vue";
import ConfigSelectEgdsStrumentoList from "./components/ConfigSelectEgdsStrumentoList.vue";
import ConfigSelectEgdsStrumentoEdit from "./components/ConfigSelectEgdsStrumentoEdit.vue";
import ConfigSelectEgdsStrumentoView from "./components/ConfigSelectEgdsStrumentoView.vue";
import ConfigSelectEgdsLavaggioDisinfezioneList from "./components/ConfigSelectEgdsLavaggioDisinfezioneList.vue";
import ConfigSelectEgdsLavaggioDisinfezioneEdit from "./components/ConfigSelectEgdsLavaggioDisinfezioneEdit.vue";
import ConfigSelectEgdsLavaggioDisinfezioneView from "./components/ConfigSelectEgdsLavaggioDisinfezioneView.vue";
import ConfigSelectEgdsIndicazioneList from "./components/ConfigSelectEgdsIndicazioneList.vue";
import ConfigSelectEgdsIndicazioneEdit from "./components/ConfigSelectEgdsIndicazioneEdit.vue";
import ConfigSelectEgdsIndicazioneView from "./components/ConfigSelectEgdsIndicazioneView.vue";
import VisitaGastroenterologicaEdit from "./eventi/visitagastroenterologica/VisitaGastroenterologicaEdit.vue";
import VisitaGastroenterologicaView from "./eventi/visitagastroenterologica/VisitaGastroenterologicaView.vue";
import VisitaGastroenterologicaList from "./eventi/visitagastroenterologica/VisitaGastroenterologicaList.vue";
import EcografiaAddominaleEdit from "./eventi/ecografiaaddominale/EcografiaAddominaleEdit.vue";
import EcografiaAddominaleView from "./eventi/ecografiaaddominale/EcografiaAddominaleView.vue";
import EcografiaAddominaleList from "./eventi/ecografiaaddominale/EcografiaAddominaleList.vue";
import EcoendoscopiaEdit from "./eventi/ecoendoscopia/EcoendoscopiaEdit.vue";
import EcoendoscopiaView from "./eventi/ecoendoscopia/EcoendoscopiaView.vue";
import EcoendoscopiaList from "./eventi/ecoendoscopia/EcoendoscopiaList.vue";

export default {
  GastroAnamnesiPatologicaList,
  GastroAnamnesiPatologicaEdit,
  GastroAnamnesiPatologicaView,
  GastroAnamnesiFisiologicaList,
  GastroAnamnesiFisiologicaEdit,
  GastroAnamnesiFisiologicaView,
  GastroAnamnesiFamiliareList,
  GastroAnamnesiFamiliareEdit,
  GastroAnamnesiFamiliareView,
  GastroAnoRettoColonscopiaList,
  GastroAnoRettoColonscopiaEdit,
  GastroAnoRettoColonscopiaView,
  EgdsEdit,
  EgdsView,
  EgdsList,
  VisitaGastroenterologicaEdit,
  VisitaGastroenterologicaView,
  VisitaGastroenterologicaList,
  EcografiaAddominaleEdit,
  EcografiaAddominaleView,
  EcografiaAddominaleList,
  EcoendoscopiaEdit,
  EcoendoscopiaList,
  EcoendoscopiaView,
  ConfigSelectEgdsStrumentoList,
  ConfigSelectEgdsStrumentoEdit,
  ConfigSelectEgdsStrumentoView,
  ConfigSelectEgdsLavaggioDisinfezioneList,
  ConfigSelectEgdsLavaggioDisinfezioneEdit,
  ConfigSelectEgdsLavaggioDisinfezioneView,
  ConfigSelectEgdsIndicazioneList,
  ConfigSelectEgdsIndicazioneEdit,
  ConfigSelectEgdsIndicazioneView,
};
