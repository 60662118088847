<template>
    <sa-page-layout ref="pgaView" :data="data" :pathResource="pathResource" :btnAnnullaVisible="true" :btnSaveVisible="true" :linkback="linkback" :showmolalloading="showmolalloading">
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <div class="sa-form-section">
                    <h5 class="sa-text-left">Nuova Sacca</h5>
                    <b-row>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Richiesta</span>
                            <b-input-group>
                                <b-form-input v-model="datiRichiesta" placeholder="Richiesta" readonly></b-form-input>
                                <b-button size="sm" variant="" v-b-modal.ricercaRichiesta @click="onClick">
                                    <b-icon icon="search"></b-icon>
                                </b-button>
                            </b-input-group>
                        </b-col>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Codice Sacca</span>
                            <b-form-input ref="codiceSacca" v-model="data.codiceSacca" placeholder="Codice Sacca"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Gruppo Sanguigno</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="data.gruppoSanguigno" class="mb-2 mr-sm-2 mb-sm-0" :options="gruppoSanguignoOption" :value="null"></b-form-select>
                        </b-col>
                    </b-row>
                </div>
                <div class="sa-form-section">
                    <b-row>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Tipologia</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="data.tipologia" class="mb-2 mr-sm-2 mb-sm-0" :options="tipologiaOption" :value="null"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Fattore RH</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="data.fattoreRh" class="mb-2 mr-sm-2 mb-sm-0" :options="fattoreRhOption" :value="null"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Stato Attuale</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="data.statoAttuale" class="mb-2 mr-sm-2 mb-sm-0" :options="statoOption" :value="null"></b-form-select>
                        </b-col>
                    </b-row>
                </div>
                <div class="sa-form-section">
                    <b-row>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Data di Scadenza</span>
                            <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" @focus="onFocusDataScadenza" v-model="data.dataScadenza" type="datetime" placeholder="Data Scadenza"></date-picker>
                        </b-col>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">HB</span>
                            <b-form-input v-model="data.hb" placeholder="HB"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">INR</span>
                            <b-form-input v-model="data.inr" placeholder="INR"></b-form-input>
                        </b-col>
                    </b-row>
                </div>
                <div class="sa-form-section">
                    <b-row>
                        <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                            <span class="sa-form-label">PT</span>
                            <b-form-input v-model="data.pt" placeholder="PT"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                            <span class="sa-form-label">PT</span>
                            <b-form-input v-model="data.pt" placeholder="PT"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                            <span class="sa-form-label">PTT</span>
                            <b-form-input v-model="data.pit" placeholder="PTT"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                            <span class="sa-form-label">APTT</span>
                            <b-form-input v-model="data.aptt" placeholder="APTT"></b-form-input>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </template>
        <template slot="table-footer">
            <b-modal id="ricercaRichiesta" refs="ricercaRichiesta" title="Ricerca Richiesta" @show="onShow" @ok="onOk">
                <b-input-group class="mb-3">
                    <b-form-input v-model="numeroRichiesta" placeholder="Numero Richiesta"></b-form-input>
                    <b-button size="sm" variant="" v-b-modal.ricercaRichiesta @click="onClick">
                        <b-icon icon="search"></b-icon>
                    </b-button>
                </b-input-group>
                <b-table id="tabellaRichieste" :items="tabellaRichieste" :fields="richiesteFields" selectable select-mode="single" @row-dblclicked="onDoubleClickRow" @row-selected="onRowSelected">
                    <template v-slot:cell(gruppoSanguigno)="{ item }">
                        <a> {{ item.gruppoSanguigno }} {{ onModificaFattoreRh(item.fattoreRh) }} </a>
                    </template>
                </b-table>
            </b-modal>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { DatePicker, SaPageLayout },
    data() {
        return {
            disabledBefore: new Date(),
            pathResource: "/bancheematiche",
            linkback: null,
            linkstato: "/bancheematichestatitipi",
            linkgrupposanguigno: "/bancheematichegruppisanguigni",
            linktipologie: "/bancheematichetipiemoderivatitipologie",
            linkfattorerh: "/bancaematicafattorirh",
            pathResourceAggiornaStato: "/aggiornaStato",
            showmolalloading: false,
            id: "-1",
            profili: [],
            statoOption: [{ value: null, text: "--Seleziona Valore--" }],
            gruppoSanguignoOption: [{ value: null, text: "--Seleziona Valore--" }],
            fattoreRhOption: [{ value: null, text: "--Seleziona Valore--" }],
            tipologiaOption: [{ value: null, text: "--Seleziona Valore--" }],
            tabellaRichieste: [],
            rigaSelezionata: null,
            numeroRichiesta: null,
            pathRestRichiesteScorte: "/bancheematichetrasfusionirichiestescorte",
            data: { dataScadenza: null, statoAttuale: null, gruppoSanguigno: null, fattoreRh: null, idBancaEmaticaTrasfusioneRichiestaScorte: null, tipologia: null, bancaEmaticaTrasfusioneRichiestaScorte: { numeroRichiesta: "" } },
            richiesteFields: [
                {
                    label: "Numero Richiesta",
                    key: "numeroRichiesta",
                    sortable: true,
                    thStyle: "width: 7rem",
                },
                {
                    label: "Data Richiesta",
                    key: "dataRichiesta",
                    formatter: (value) => {
                        if (!value) {
                            return "";
                        } else {
                            return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
                        }
                    },
                    sortable: true,
                    thStyle: "width: 7rem",
                },
                {
                    label: "Gruppo Sanguigno",
                    key: "gruppoSanguigno",
                    sortable: true,
                    thStyle: "width: 7rem",
                },
            ],
        };
    },
    computed: {
        datiRichiesta: {
            get() {
                let me = this;
                let richiesta = me.data.bancaEmaticaTrasfusioneRichiestaScorte.numeroRichiesta;
                return richiesta;
            },
            set() {},
        },
    },
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        if (me.id === "-1") {
            me.linkback = "/bancaematica";
        } else {
            me.linkback = "/bancaematica/view/" + me.id;
        }
        me.loadDefaultData();
    },
    methods: {
        loadDefaultData() {
            let me = this;
            me.loadData();
            me.loadFattoreRh();
            me.loadGruppoSanguigno();
            me.loadTipologia();
            me.loadStato();
        },
        loadData() {
            let me = this;
            if (me.id === "-1") {
                me.showmolalloading = false;
            } else {
                //me.showmolalloading = true;
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.data = response.data.data;
                    me.showmolalloading = false;
                });
            }
        },
        loadStato() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkstato;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.statoOption.push({ value: element.valore, text: element.valore });
                });
            });
        },
        loadGruppoSanguigno() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkgrupposanguigno;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.gruppoSanguignoOption.push({ value: element.valore, text: element.valore });
                });
            });
        },
        loadTipologia() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linktipologie;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.tipologiaOption.push({ value: element.tipologia, text: element.tipologia });
                });
            });
        },
        loadFattoreRh() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkfattorerh;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.fattoreRhOption.push({ value: element.valore, text: element.valore });
                });
            });
        },
        onModificaFattoreRh(valore) {
            if (valore === "POSITIVO") {
                return "+";
            } else {
                return "-";
            }
        },
        onShow() {
            let me = this;
            me.tabellaRichieste = [];
        },
        onClose() {
            this.$refs.codiceSacca.focus();
        },
        onRowSelected(items) {
            if (items.length > 0) {
                this.rigaSelezionata = items[0];
            }
        },
        onDoubleClickRow() {
            let me = this;
            me.inputValori();
            this.$root.$emit("bv::hide::modal", "ricercaRichiesta", "");
            setTimeout(function() {
                me.$refs.codiceSacca.focus();
            }, 500);
            me.data.statoAttuale = "DISPONIBILE";
        },
        onOk() {
            let me = this;
            me.inputValori();
            setTimeout(function() {
                me.$refs.codiceSacca.focus();
            }, 500);
            me.data.statoAttuale = "DISPONIBILE";
        },
        inputValori() {
            let me = this;
            me.data.bancaEmaticaTrasfusioneRichiestaScorte = me.rigaSelezionata;
            me.datiRichiesta = me.rigaSelezionata;
            //me.data.numeroRichiesta = me.rigaSelezionata.numeroRichiesta;
            me.data.idBancaEmaticaTrasfusioneRichiestaScorte = me.rigaSelezionata.id;
            me.data.hb = me.rigaSelezionata.hb;
            me.data.inr = me.rigaSelezionata.inr;
            me.data.pt = me.rigaSelezionata.pt;
            me.data.pit = me.rigaSelezionata.pit;
            me.data.aptt = me.rigaSelezionata.aptt;
            me.data.gruppoSanguigno = me.rigaSelezionata.gruppoSanguigno;
            // me.data.codiceSacca = me.rigaSelezionata.codiceSacca;
            me.data.fattoreRh = me.rigaSelezionata.fattoreRh;
            me.data.tipologia = me.rigaSelezionata.tipologia;
        },
        onFocusDataScadenza() {
            let me = this;
            console.log("OK");
            if (!me.data.dataScadenza) {
                me.data.dataScadenza = new Date().getTime();
                console.log(me.data.dataScadenza);
            }
        },
        onClick() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathRestRichiesteScorte + "?statoAttuale=RICHIESTA";
            axios
                .get(link, { params: { numeroRichiesta: me.numeroRichiesta } })
                .then((response) => {
                    me.tabellaRichieste = response.data.data;
                    me.showmolalloading = false;
                    me.numeroRichiesta = null;
                })
                .catch((error) => {
                    me.showmolalloading = false;
                    me.$bvModal
                        .msgBoxOk(error.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxTwo = value;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
    },
};
</script>
