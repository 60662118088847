<template>
    <div class="sa-component-patient-info">
        <b-row>
            <b-col>
                <img :src="patientAvatar" class="rounded-circle avatar-toolbar img-thumbnail float-left mr-3" alt="profile-image" />
                <h4 class="m-0">
                    <a>{{ data.cognome }} {{ data.nome }}</a>
                    <!-- <span v-else class="">{{ data.cognome }} {{ data.nome }}</span> -->
                </h4>
                <p class="text-muted">
                    <span><b>Data Nascita: </b> {{ formatDate(data.dataNascita) }} </span> - <span> <b>Età:</b> {{ calcoloEta(data.dataNascita) }} </span> - <i> {{ data.codiceFiscale }} </i>
                    <br />

                    <span><b>Prog. anno: </b> {{ data.progressivoAnno }} </span>
                    <span><b>Provenienza: </b> {{ data.provenienza }} </span>
                    <span><b>Sport: </b> {{ data.sport }}</span>
                    <span><b> Tipo Atleta: </b> {{ data.tipoAttleta }} </span>
                    <span><b>Visita: </b>{{ data.tipoVisita }}</span>
                </p>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import female from "@/assets/images/female.jpg";
import male from "@/assets/images/male.jpg";
import moment from "moment";
export default {
    name: "PazienteAnagrafica",
    components: {},
    props: {
        idPaziente: {
            type: String,
            default: function() {
                return null;
            },
        },
        patientLink: {
            type: Boolean,
            default: function() {
                return true;
            },
        },
        data: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    data() {
        return {};
    },
    created() {
        let me = this;
        if (me.idPaziente !== null) {
            me.loadData();
        }
    },
    computed: {
        patientAvatar: {
            get: function() {
                let me = this;
                if (me.data.sesso === "M") {
                    return male;
                } else {
                    return female;
                }
            },
        },
        // dataValue() {
        //     let me = this;
        //     if (me.data === null) {
        //         return me.patientData;
        //     }

        //     return me.data;
        // },
    },
    methods: {
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY");
            } else {
                return "---";
            }
        },
        calcoloEta(value) {
            if (value) {
                let today = new Date();
                let birthDate = new Date(value);
                let age = today.getFullYear() - birthDate.getFullYear();
                let ageMonth = today.getMonth() - birthDate.getMonth();
                let ageDays = today.getDate() - birthDate.getDate();
                if (ageMonth < 0 || (ageMonth === 0 && today.getDate() < birthDate.getDate())) {
                    age--;
                    ageMonth = 12;
                }

                if (ageDays < 0) {
                    ageMonth--;
                    ageDays = birthDate.getDate() - today.getDate();
                }

                return age + " " + "anni " + ageMonth + " mesi " + ageDays + " giorni";
            } else {
                return "---";
            }
        },
    },
};
</script>
