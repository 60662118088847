<template>
  <sa-page-layout :showModalLoading="showModalLoading" :btnSaveVisible="true" :btnAnnullaVisible="true" :btnBackVisible="false" :linkback="linkback" :pathResource="pathResource" :data="jsonData">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-header">
      <h5 class="sa-text-left">Dati</h5>
    </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <!-- InizioBody  -->
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><h5>{{ getTranslate("patient.cardiology.lblGeneralData") }}</h5>
            <br />
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblWeight") }}</span
            ><br />
            <b-form-input v-model="jsonData.peso" type="number"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblHeight") }}</span
            ><br />
            <b-form-input v-model="jsonData.altezza" type="number"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblBMI") }}</span
            ><br />
            <b-form-input v-model="jsonData.bmi" type="number"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblWaistCircumference") }}</span
            ><br />
            <b-form-input v-model="jsonData.circonferenzaVita" type="number"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblWaistCircumference") }}</span
            ><br />
            <b-form-input v-model="jsonData.circonferenzaFianchi" type="number"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblSmoking") }}</span
            ><br />
            <b-form-select v-model="jsonData.fumo" :options="opzioniFumo"></b-form-select
          ></b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><h5>{{ getTranslate("patient.cardiology.lblOnGoingTherapies") }}</h5>
            <br />
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAlphablockers") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.alfabloccanti" switch :checked="jsonData.alfabloccanti"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAlphabetablockers") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.alfabetabloccanti" switch :checked="jsonData.alfabetabloccanti"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblACEinhibitors") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.aceinebitori" switch :checked="jsonData.aceinebitori"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAT1antagonists") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.at1Antagonisiti" switch :checked="jsonData.at1Antagonisiti"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAntiplateletAgents") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.antiaggreganti" switch :checked="jsonData.antiaggreganti"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnticoagulants") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.anticoagulanti" switch :checked="jsonData.anticoagulanti"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAntiarrhythmics") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.antiaritmici" switch :checked="jsonData.antiaritmici"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblOralAntidiabetics") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.antidiabeticiOrali" switch :checked="jsonData.antidiabeticiOrali"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblBetablockers") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.betabloccanti" switch :checked="jsonData.betabloccanti"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblBenzothiazepines") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.benzotiazepine" switch :checked="jsonData.benzotiazepine"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblDiuretics") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.diuretici" switch :checked="jsonData.diuretici"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblDigital") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.digitale" switch :checked="jsonData.digitale"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblDihydropyridines") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.diidropiridinici" switch :checked="jsonData.diidropiridinici"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblFibrates") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.fibrati" switch :checked="jsonData.fibrati"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPhenylalkylamines") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.fenilalchilamine" switch :checked="jsonData.fenilalchilamine"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblInsulin") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.insulina" switch :checked="jsonData.insulina"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblNitrates") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.nitrati" switch :checked="jsonData.nitrati"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblStatins") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.statine" switch :checked="jsonData.statine"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblOther") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.other" switch :checked="jsonData.other"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><span>{{ getTranslate("patient.cardiology.lblFollowsTherapy") }}</span
            ><br />
            <b-form-input v-model="jsonData.segueTerapia" type="text"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblSideEffects") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.effettiCollaterali" switch :checked="jsonData.effettiCollaterali"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="8" md="8" lg="8" xl="8"
            ><span>{{ getTranslate("patient.cardiology.lblInfoSideEffects") }}</span
            ><br />
            <b-form-input v-model="jsonData.infoEffettiCollaterali" type="text" :disabled="!jsonData.effettiCollaterali"></b-form-input
          ></b-col>
          <b-col xs="12" sm="6" md="4" lg="4" xl="4">
            <span>{{ getTranslate("patient.cardiology.lblBloodPressure") }}</span
            ><br />
            <b-row
              ><b-col xs="12" sm="6" md="6" lg="6" xl="6">
                <b-form-input v-model="jsonData.pressioneSistolica" type="number" placeholder="Sys"></b-form-input>
              </b-col>
              <b-col xs="12" sm="6" md="6" lg="6" xl="6"> <b-form-input v-model="jsonData.pressioneDiastolica" type="number" placeholder="Dias"></b-form-input></b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <span>{{ getTranslate("patient.cardiology.lblHeartRecoveryType") }}</span
            ><br />
            <b-form-select v-model="jsonData.tipoRicoveroCardio" :options="opzioniTipoRicovero"></b-form-select>
          </b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <span>{{ getTranslate("patient.cardiology.lblHeartRecoveryCause") }}</span
            ><br />
            <b-form-select v-model="jsonData.causaRicoveroCardio" :options="opzioniCausaRicovero"></b-form-select>
          </b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <span>{{ getTranslate("patient.cardiology.lblExtraRecoveryType") }}</span
            ><br />
            <b-form-select v-model="jsonData.tipoRicoveroExtra" :options="opzioniTipoRicovero"></b-form-select>
          </b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <span>{{ getTranslate("patient.cardiology.lblHeartRecoveryCause") }}</span
            ><br />
            <b-form-select v-model="jsonData.causaRicoveroExtra" :options="opzioniCausaRicovero"></b-form-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><h5>{{ getTranslate("patient.cardiology.lblAnamnesys") }}</h5>
            <br />
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><span>{{ getTranslate("patient.cardiology.lblNextAnamnesys") }}</span
            ><br />
            <b-form-textarea v-model="jsonData.anamnesiProssima" rows="6"></b-form-textarea
          ></b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><span>{{ getTranslate("patient.cardiology.lblHeartAndGeneralAnamnesys") }}</span
            ><br />
            <b-form-textarea v-model="jsonData.anamnesiGeneraleCardiovascolare" rows="6"></b-form-textarea
          ></b-col>
        </b-row>
        <b-row
          ><b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><h5>{{ getTranslate("patient.cardiology.lblFinalTherapy") }}</h5>
            <br />
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAlphablockers") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.alfabloccantiFinale" switch :checked="jsonData.alfabloccantiFinale"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAlphabetablockers") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.alfabetabloccantiFinale" switch :checked="jsonData.alfabetabloccantiFinale"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblACEinhibitors") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.aceinebitoriFinale" switch :checked="jsonData.aceinebitoriFinale"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAT1antagonists") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.at1AntagonistiFinale" switch :checked="jsonData.at1AntagonistiiFinale"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAntiplateletAgents") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.antiaggregantiFinale" switch :checked="jsonData.antiaggregantiFinale"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnticoagulants") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.anticoagulantiFinale" switch :checked="jsonData.anticoagulantiFinale"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAntiarrhythmics") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.antiaritmiciFinale" switch :checked="jsonData.antiaritmiciFinale"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblOralAntidiabetics") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.antidiabeticiOraliFinale" switch :checked="jsonData.antidiabeticiOraliFinale"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblBetablockers") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.betabloccantiFinale" switch :checked="jsonData.betabloccantiFinale"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblBenzothiazepines") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.benzotiazepineFinale" switch :checked="jsonData.benzotiazepineFinale"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblDiuretics") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.diureticiFinale" switch :checked="jsonData.diureticiFinale"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblDigital") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.digitaleFinale" switch :checked="jsonData.digitaleFinale"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblDihydropyridines") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.diidropiridiniciFinale" switch :checked="jsonData.diidropiridiniciFinale"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblFibrates") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.fibratiFinale" switch :checked="jsonData.fibratiFinale"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPhenylalkylamines") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.fenilalchilamineFinale" switch :checked="jsonData.fenilalchilamineFinale"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblInsulin") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.insulinaFinale" switch :checked="jsonData.insulinaFinale"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblNitrates") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.nitratiFinale" switch :checked="jsonData.nitratiFinale"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblStatins") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.statineFinale" switch :checked="jsonData.statineFinale"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblOther") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.otherFinale" switch :checked="jsonData.otherFinale"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><span>{{ getTranslate("patient.cardiology.lblDiagnosisAndNotes") }}</span
            ><br />
            <b-form-textarea v-model="jsonData.DiagnosiNote" rows="6"></b-form-textarea
          ></b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><h5>{{ getTranslate("patient.cardiology.lblExamsBeforeNextCheck") }}</h5>
            <br />
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblBloodRoutineTest") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.routineSangue" switch :checked="jsonData.routineSangue"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblBloodCountPlatelets") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.emocromoPiastrine" switch :checked="jsonData.emocromoPiastrine"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblBrainTAC") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.tacCerebrale" switch :checked="jsonData.tacCerebrale"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAbdomenTAC") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.tacAddome" switch :checked="jsonData.tacAddome"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblCortisol") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.cortisolemiOttoVenti" switch :checked="jsonData.cortisolemiOttoVenti"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblUrine") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.urine" switch :checked="jsonData.urine"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblRenalUltrasonography") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.ecoRenale" switch :checked="jsonData.ecoRenale"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblHeartUltrasonographyColordoppler") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.ecocardioColordoppler" switch :checked="jsonData.ecocardioColordoppler"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblSerumElectrolytes") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.elettrolitiSierici" switch :checked="jsonData.elettrolitiSierici"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblCpkLdh") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.cpkLdh" switch :checked="jsonData.cpkLdh"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblThyroidUltrasonography") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.ecografiaTiroidea" switch :checked="jsonData.ecografiaTiroidea"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblUltrasoundTsaColordoppler") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.ecoTsaColordoppler" switch :checked="jsonData.ecoTsaColordoppler"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblTotalCholesterolHdlTriglycerides") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.colesteroloHdlTrigliceridi" switch :checked="jsonData.colesteroloHdlTrigliceridi"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblHepatobiliaryUltrasonography") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.ecografiaEpatobiliare" switch :checked="jsonData.ecografiaEpatobiliare"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblHolterECG") }}</span
            ><br />\ <b-form-checkbox v-model="jsonData.holterEcg" switch :checked="jsonData.holterEcg"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblGlycemiaAzotemiaCreatinemiaUricemia") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.glicemiaAzotemiaCreatinemiaUricemia" switch :checked="jsonData.glicemiaAzotemiaCreatinemiaUricemia"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblAmbulatoryMonitoringBloodPressure") }}</span>
            <b-row
              ><b-col xs="12" sm="2" md="2" lg="2" xl="2"><b-form-checkbox v-model="jsonData.monitoraggioPressione" switch :checked="jsonData.monitoraggioPressione"></b-form-checkbox></b-col>
              <b-col xs="12" sm="5" md="5" lg="5" xl="5"
                ><date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataNascita" type="date" placeholder="Data Visita" :disabled="!jsonData.monitoraggioPressione"></date-picker> </b-col
              ><b-col xs="12" sm="5" md="5" lg="5" xl="5"
                ><date-picker format="HH:mm" value-type="timestamp" v-model="jsonData.setDalleOre" type="time" placeholder="Alle Ore" :disabled="!jsonData.monitoraggioPressione"></date-picker></b-col
            ></b-row>
          </b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lbl24hSampleUrinaryElectrolytesDosage") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.dosaggioUrineEllettroliti" switch :checked="jsonData.dosaggioUrineEllettroliti"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblBasalSequentialRenalScintigraphy1hAfterCaptopril") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.scintigrafiaRenaleCaptopril" switch :checked="jsonData.scintigrafiaRenaleCaptopril"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblPraAndBasalAldosteronemia1hAfterCaptopril") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.dosaggioPraAldosteronemia" switch :checked="jsonData.dosaggioPraAldosteronemia"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblMyocardialPerfusionSpectRestStimulation") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.spectMiocardica" switch :checked="jsonData.spectMiocardica"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblPraAndBasalAldosteronemiaOnClinic") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.dosaggioPraAldosteronemiaClino" switch :checked="jsonData.dosaggioPraAldosteronemiaClino"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblThyroidScintigraphy") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.scintigrafiaTiroidea" switch :checked="jsonData.scintigrafiaTiroidea"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblAbcHepatitisMarkers") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.markersEpatite" switch :checked="jsonData.markersEpatite"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblAngiocardiopneumoscintigraphy ") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.angiocardiopneumoscintigrafia" switch :checked="jsonData.angiocardiopneumoscintigrafia"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblFT3") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.ft3" switch :checked="jsonData.ft3"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblFT4") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.ft4" switch :checked="jsonData.ft4"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblTSH") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.tsh" switch :checked="jsonData.tsh"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblErgonometricTest") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.testErgonometrico" switch :checked="jsonData.testErgonometrico"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblFundus") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.fondoOculare" switch :checked="jsonData.fondoOculare"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblPtApttInr") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.ptApttInr" switch :checked="jsonData.ptApttInr"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblHomocysteinemiaDosage ") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.dosaggioOmocisteinemia" switch :checked="jsonData.dosaggioOmocisteinemia"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblProteinuria") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.proteinuria" switch :checked="jsonData.proteinura"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblPCR") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.pcr" switch :checked="jsonData.pcr"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblMicroalbuminuria") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.microalbuminuria" switch :checked="jsonData.microalbuminuria"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblDigoxinaemia") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.digossinemia" switch :checked="jsonData.digossinemia"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblHBA1C") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.hba1c" switch :checked="jsonData.hba1c"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblUrinaryCatecholaminesMetanephrinesDosage24hSample") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.dosaggioCatecolamineMetafrine" switch :checked="jsonData.dosaggioCatecolamineMetafrine"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><span>{{ getTranslate("patient.cardiology.lblOther") }}</span
            ><br />
            <b-form-textarea v-model="jsonData.altroOsservazioni" rows="6"></b-form-textarea
          ></b-col>
        </b-row>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
import DatePicker from "vue2-datepicker";
export default {
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  components: { SaPageLayout, PazienteAnagrafica, DatePicker },
  data() {
    return {
      linkback: null,
      pathResource: "/cardiovisitacontrolloecg",
      jsonData: {},
      showModalLoading: false,
      opzioniCausaRicovero: [
        { text: "-", value: null },
        { text: "Angina", value: "angina" },
        { text: "Aritmia", value: "arrhythmia " },
        { text: "BAV", value: "BAV" },
        { text: "Crisi Ipertensiva", value: "hypertension" },
        { text: "ICTUS", value: "ICTUS" },
        { text: "IMA", value: "IMA" },
        { text: "TIA", value: "TIA" },
      ],
      opzioniTipoRicovero: [
        { text: "-", value: null },
        { text: "Elezione", value: "election" },
        { text: "Urgente", value: "urgente" },
      ],
      opzioniFumo: [
        { text: "-", value: null },
        { text: "No", value: "N" },
        { text: "Ex (meno di 300 giorni)", value: "EX-" },
        { text: "Ex (più di 300 giorni)", value: "EX+" },
        { text: "Sì (meno di 10 anni)", value: "-10years" },
        { text: "Sì (da 10-20 anni)", value: "-20years" },
        { text: "Sì (più di 20 anni)", value: "+20years" },
      ],
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient: {
      get: function() {
        if (this.utente.gruppo.menuGruppo) {
          if (this.utente.gruppo.menuGruppo.length > 1) {
            return "/paziente/view/" + this.idPaziente;
          } else {
            if (this.utente.gruppo.menuGruppo.length == 1) {
              if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                return "/paziente/view/" + this.idPaziente;
              } else {
                return "/paziente/homepaziente";
              }
            } else {
              return "";
            }
          }
        } else {
          return "";
        }
      },
    },
  },
  mounted() {
    let me = this;
    me.jsonData.idPaziente = this.$route.query.idPaziente;
    me.jsonData.tipoEvento = "Cardiologia - Visita Controllo ECG";
    me.jsonData.idAmbulatorio = sessionStorage.getItem("idAmbulatorio");
    me.id = this.$route.params.id;
    me.linkedit = "/paziente/cardiologia/visitacontrolloecg/edit/" + me.id + "?idPaziente=" + me.idPaziente;
    if (me.id !== "-1") {
      me.linkback = "/paziente/cardiologia/visitacontrolloecg/view/" + me.id + "?idPaziente=" + me.idPaziente;
      me.loadData();
    } else {
      me.linkback = "/paziente/cardiologia/visitacontrolloecg?idPaziente=" + me.idPaziente;
    }
  },

  methods: {
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        });
      }
    },
    getTranslate(text) {
      return this.$i18n.t(text);
    },
  },
};
</script>
