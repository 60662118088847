<template>
  <sa-page-layout :showModalLoading="showModalLoading" :btnEditVisible="true" :btnAnnullaVisible="false" :btnBackVisible="true" :linkback="linkback" :linkedit="linkedit" :pathResource="pathResource" :data="jsonData">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-header">
      <h5 class="sa-text-left">Dati</h5>
    </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <!-- InizioBody  -->
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><h5>{{ getTranslate("patient.cardiology.lblFamilyAnamnesys") }}</h5></b-col
          >
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblHypertensiveFather") }}</span
            ><br />
            <span :class="setColor(jsonData.padreIperteso)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.padreIperteso)"></b-icon></span>
          </b-col>

          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblHypertensiveMother") }}</span
            ><br />
            <span :class="setColor(jsonData.madreIpertesa)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.madreIpertesa)"></b-icon></span>
          </b-col>

          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblHypertensiveSibilings") }}</span
            ><br />
            <span :class="setColor(jsonData.fratelliIpertesi)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.fratelliIpertesi)"></b-icon></span>
          </b-col>

          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblDiabeticRelatives") }}</span
            ><br />
            <span :class="setColor(jsonData.parentiDiabetici)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.parentiDiabetici)"></b-icon></span>
          </b-col>

          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblNephropathicRelatives") }}</span
            ><br />
            <span :class="setColor(jsonData.parentiNefropatici)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.parentiNefropatici)"></b-icon></span>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblDyslipidemiaRelatives") }}</span
            ><br />
            <span :class="setColor(jsonData.parentidislip)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.parentidislip)"></b-icon></span>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblIschemicRelatives") }}</span
            ><br />
            <span>{{ jsonData.parentiCardiopatiaIsch }}</span></b-col
          >
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblCerebrovascularAccidentsRelatives") }}</span
            ><br />
            <span>{{ jsonData.parentiCerebrovasc }}</span></b-col
          >
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><hr />
            <h5>{{ getTranslate("patient.cardiology.lblPersonalAnamnesys") }}</h5></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblHypertensiveYears") }}</span
            ><br />
            <span>{{ jsonData.anniIpertensione }}</span></b-col
          >
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblDiabeticYears") }}</span
            ><br />
            <span>{{ jsonData.anniDiabetici }}</span></b-col
          >
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblDyslipidemia") }}</span
            ><br />
            <span>{{ jsonData.dislipidemia }}</span></b-col
          >
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPaBeforeTherapy") }}</span
            ><br />
            <b-row
              ><b-col xs="12" sm="12" md="6" lg="6" xl="6"
                ><span>Min: {{ jsonData.paminBeforeterapia }}</span></b-col
              ><b-col xs="12" sm="12" md="6" lg="6" xl="6"
                ><span>Max: {{ jsonData.pamaxBeforeterapia }}</span></b-col
              ></b-row
            ></b-col
          >
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblOnTherapy") }}</span
            ><br />
            <span :class="setColor(jsonData.terapiaUltimoAnno)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.terapiaUltimoAnno)"></b-icon></span>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblCarbohydrateIntolerance") }}</span
            ><br />
            <span :class="setColor(jsonData.intolleranzaCarboidrati)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.intolleranzaCarboidrati)"></b-icon></span>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblDiabetesMellitus") }}</span
            ><br />
            <span :class="setColor(jsonData.diabeteMellito)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.diabeteMellito)"></b-icon></span>
          </b-col>
          <b-col xs="0" sm="8" md="8" lg="8" xl="8"></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAlcoholConsumption") }}</span
            ><br />
            <span>{{ jsonData.consumoDiAlcool }}</span></b-col
          >
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblQualification") }}</span
            ><br />
            <span>{{ jsonData.titoloDiStudio }}</span></b-col
          >
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblWorkActivity") }}</span
            ><br />
            <span>{{ jsonData.attivitaLavorativa }}</span></b-col
          >
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblIrrascibilityWork") }}</span
            ><br />
            <span>{{ jsonData.problemiLavoro }}</span></b-col
          >
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPersonalMatters") }}</span
            ><br />
            <span>{{ jsonData.problemiCasa }}</span></b-col
          >
          <b-col xs="0" sm="4" md="4" lg="4" xl="4"></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPastIMA") }}</span
            ><br />
            <b-row
              ><b-col xs="12" sm="12" md="6" lg="6" xl="6"
                ><span :class="setColor(jsonData.pregIma)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.pregIma)"></b-icon></span> </b-col
              ><b-col xs="12" sm="12" md="6" lg="6" xl="6"
                ><span>{{ jsonData.sedePregIma }}</span></b-col
              ></b-row
            ></b-col
          >
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPastPTCA") }}</span
            ><br />
            <b-row
              ><b-col xs="12" sm="12" md="6" lg="6" xl="6"
                ><span :class="setColor(jsonData.pregPtca)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.pregPtca)"></b-icon></span> </b-col
              ><b-col xs="12" sm="12" md="6" lg="6" xl="6"
                ><span>{{ jsonData.sedePregPtca }}</span></b-col
              ></b-row
            ></b-col
          >
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPastCABG") }}</span
            ><br />
            <b-row
              ><b-col xs="12" sm="12" md="6" lg="6" xl="6"
                ><span :class="setColor(jsonData.pregCabg)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.pregCabg)"></b-icon></span> </b-col
              ><b-col xs="12" sm="12" md="6" lg="6" xl="6"
                ><span>{{ jsonData.sedePregCabg }}</span></b-col
              ></b-row
            ></b-col
          >
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblValvulopathy") }}</span
            ><br />
            <b-row
              ><b-col xs="12" sm="12" md="6" lg="6" xl="6"
                ><span :class="setColor(jsonData.valvulop)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.valvulop)"></b-icon></span> </b-col
              ><b-col xs="12" sm="12" md="6" lg="6" xl="6"
                ><span>{{ jsonData.sedeValvulop }}</span></b-col
              ></b-row
            ></b-col
          >
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPastCrerebralIschemia") }}</span
            ><br />
            <b-row
              ><b-col xs="12" sm="12" md="6" lg="6" xl="6"
                ><span :class="setColor(jsonData.pregIschemia)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.pregIschemia)"></b-icon></span> </b-col
              ><b-col xs="12" sm="12" md="6" lg="6" xl="6"
                ><span>{{ jsonData.cmbPregIschemia }}</span></b-col
              ></b-row
            ></b-col
          >
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><hr />
            <h5>{{ getTranslate("patient.cardiology.lblAnamnesysPositivity") }}</h5></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblRenalLithiasis") }}</span
            ><br />
            <span :class="setColor(jsonData.litiasi)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.litiasi)"></b-icon></span>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblUrinaryInfections") }}</span
            ><br />
            <span :class="setColor(jsonData.infezioni)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.infezioni)"></b-icon></span>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAlcoholAbuse") }}</span
            ><br />
            <span :class="setColor(jsonData.alcool)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.alcool)"></b-icon></span>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLicoriceAbuse") }}</span
            ><br />
            <span :class="setColor(jsonData.liquirizia)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.liquirizia)"></b-icon></span>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblNoseSpray") }}</span
            ><br />
            <span :class="setColor(jsonData.sprayNasali)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.sprayNasali)"></b-icon></span>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLipidloweringDrugs") }}</span
            ><br />
            <span :class="setColor(jsonData.ipolipemizzanti)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.ipolipemizzanti)"></b-icon></span>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblMenoAndroPause") }}</span
            ><br />
            <span :class="setColor(jsonData.menopausa)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.menopausa)"></b-icon></span>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblOralContraceptives") }}</span
            ><br />
            <span :class="setColor(jsonData.contraccettivi)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.contraccettivi)"></b-icon></span>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPregnancyHypertension") }}</span
            ><br />
            <span :class="setColor(jsonData.ipertGravidanz)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.ipertGravidanz)"></b-icon></span>
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><hr />
            <h5>{{ getTranslate("patient.cardiology.lbllDietActivityHabits") }}</h5></b-col
          >
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblFruit") }}</span
            ><br />
            <span>{{ jsonData.frutta }}</span>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblFish") }}</span
            ><br />
            <span>{{ jsonData.pesce }}</span></b-col
          >
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblOil") }}</span
            ><br />
            <span>{{ jsonData.olio }}</span></b-col
          >
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblCheese") }}</span
            ><br />
            <span>{{ jsonData.formaggio }}</span></b-col
          >
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPhysicalActivity") }}</span
            ><br />
            <span>{{ jsonData.attivitaFisica }}</span></b-col
          >
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPersonality") }}</span
            ><br />
            <span>{{ jsonData.personalita }}</span></b-col
          >
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><span>{{ getTranslate("patient.cardiology.lblOther") }}</span
            ><br />
            <span>{{ jsonData.altro }}</span></b-col
          >
        </b-row>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  components: { SaPageLayout, PazienteAnagrafica },
  data() {
    return {
      linkback: null,
      pathResource: "/cardiologiaanamnesi",
      jsonData: {},
      showModalLoading: false,
      fontScale: 2,
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient: {
      get: function() {
        if (this.utente.gruppo.menuGruppo) {
          if (this.utente.gruppo.menuGruppo.length > 1) {
            return "/paziente/view/" + this.idPaziente;
          } else {
            if (this.utente.gruppo.menuGruppo.length == 1) {
              if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                return "/paziente/view/" + this.idPaziente;
              } else {
                return "/paziente/homepaziente";
              }
            } else {
              return "";
            }
          }
        } else {
          return "";
        }
      },
    },
  },
  mounted() {
    let me = this;
    me.jsonData.idPaziente = this.$route.query.idPaziente;
    me.jsonData.tipoEvento = "Cardiologia - Anamnesi";
    me.id = this.$route.params.id;
    me.linkedit = "/paziente/cardiologia/anamnesi/edit/" + me.id + "?idPaziente=" + me.idPaziente;
    if (me.id !== "-1") {
      me.linkback = "/paziente/cardiologia/anamnesi?idPaziente=" + me.idPaziente;
      me.loadData();
    } else {
      me.linkback = "/paziente/cardiologia/anamnesi?idPaziente=" + me.idPaziente;
    }
  },

  methods: {
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        });
      }
    },
    getTranslate(text) {
      return this.$i18n.t(text);
    },
    setColor(value) {
      let color = value == true ? "text-success" : "text-danger";
      return color;
    },
    setIcon(value) {
      let icon = value === true ? "check" : "x";
      return icon;
    },
  },
};
</script>
