<template>
  <sa-page-layout :showModalLoading="showModalLoading" :btnSaveVisible="false" :btnAnnullaVisible="false" :btnBackVisible="true" :btnEditVisible="true" :linkedit="linkedit" :linkback="linkback" :pathResource="pathResource" :data="jsonData">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <strong>{{ this.$i18n.t("patients.lblMother") }}</strong>
            <br />
            <span>{{ jsonData.madre }}</span>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <strong>{{ this.$i18n.t("patients.lblFather") }}</strong>
            <br />
            <span>{{ jsonData.padre }}</span>
          </b-col>
        </b-row>

        <hr />

        <b-row>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <strong>{{ this.$i18n.t("patients.lblBrothers") }}</strong>
            <br />
            <span>{{ jsonData.numeroFratelli }}</span>
          </b-col>
          <hr />
          <b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <strong>{{ this.$i18n.t("patients.lblSisters") }}</strong>
            <br />
            <span>{{ jsonData.numeroSorelle }}</span>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <strong>{{ this.$i18n.t("patients.lblSpouse") }}</strong>
            <br />
            <b-icon class="h5 mb-0" :icon="getIconConiuge(jsonData.coniuge)"></b-icon>
          </b-col>
          <hr />
          <b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <strong>{{ this.$i18n.t("patients.lblSpouseStatus") }}</strong>
            <br />
            <span>{{ jsonData.coniugeSino }}</span></b-col
          >
        </b-row>
        <hr />
        <b-row>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <strong>{{ this.$i18n.t("patients.lblNumberOfChildren") }}</strong>
            <br />
            <span>{{ jsonData.numeroFigli }}</span>
          </b-col>
          <hr />
          <b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <strong>{{ this.$i18n.t("patients.lblOther") }}</strong>
            <br />
            <span>{{ jsonData.altro }}</span>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12">
            <strong
              ><h4>{{ this.$i18n.t("patients.lblDiseasesPresent") }}:</h4></strong
            >
            <br />
          </b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <strong>{{ this.$i18n.t("patients.lblCalculosisOfTheGallbladder") }}</strong>
            <br />
            <span>{{ jsonData.calcolosiDellaColecisti }}</span>
          </b-col>
          <hr />
          <b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <strong>{{ this.$i18n.t("patients.lblGastricUlcer") }}</strong>
            <br />
            <span>{{ jsonData.ulceraGastrica }}</span>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <strong>{{ this.$i18n.t("patients.lblDuodenalUlcer") }}</strong>
            <br />
            <span>{{ jsonData.ulceraDuodenale }}</span>
          </b-col>
          <hr />
          <b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <strong>{{ this.$i18n.t("patients.lblStomachCarcinoma") }}</strong>
            <br />
            <span>{{ jsonData.carcinomaDelloStomaco }}</span>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <strong>{{ this.$i18n.t("patients.lblGastricLymphoma") }}</strong>
            <br />
            <span>{{ jsonData.linfomaGastrico }}</span>
          </b-col>
          <hr />
          <b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <strong>{{ this.$i18n.t("patients.lblRefluxEsophagitis") }}</strong>
            <br />
            <span>{{ jsonData.esofagiteDaReflusso }}</span>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <strong>{{ this.$i18n.t("patients.lblColonCancer") }}</strong>
            <br />
            <span>{{ jsonData.carcinomaColon }}</span>
          </b-col>
          <hr />
          <b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <strong>{{ this.$i18n.t("patients.lblOther") }}</strong>
            <br />
            <span>{{ jsonData.altraMalattia }}</span>
          </b-col>
        </b-row>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
export default {
  components: { SaPageLayout, PazienteAnagrafica },
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  data() {
    return {
      linkback: null,
      linkedit: null,
      pathResource: "/gastroanamnesifamiliare",
      jsonData: {},
      showModalLoading: false,
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient: {
      get: function() {
        let returnValue = "";
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length > 1) {
              return "/paziente/view/" + this.idPaziente;
            } else {
              if (this.utente.gruppo.menuGruppo.length == 1) {
                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                  return "/paziente/view/" + this.idPaziente;
                } else {
                  return "/paziente/homepaziente";
                }
              }
            }
          }
        }
        return returnValue;
      },
    },
  },
  mounted() {
    let me = this;
    me.jsonData.idPaziente = this.$route.query.idPaziente;
    me.jsonData.tipoEvento = "Gastroenterologia - Anamnesi Familiare";
    me.id = this.$route.params.id;
    me.linkedit = "/paziente/gastroenterologia/anamnesifamiliare/edit/" + me.id + "?idPaziente=" + me.idPaziente;
    me.loadData();
    me.linkback = "/paziente/gastroenterologia/anamnesifamiliare?idPaziente=" + me.idPaziente;
  },

  methods: {
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        });
      }
    },
    getIconConiuge() {
      let me = this;
      let icon = me.jsonData.coniuge === "true" ? "check2" : "x";
      return icon;
    },
  },
};
</script>
