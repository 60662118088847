<template>
    <div class="sa-page">
        <div class="content-header text-center">
            <p>Gesan srl</p>
            <!-- <p>Benvenuto sulla piattaforma di consultazione dei referti Aziendali</p> -->
        </div>
        <div class="content text-center sa-logo-home"><img :src="logo" cless="sa-logo-home" /></div>
        <!-- <div class="content text-center sa-logo-home"><medico-dashboard></medico-dashboard></div> -->
        <div class="content-footer" style="background-color: #E2EEF2;">
            <p></p>
        </div>
    </div>
</template>
<script>
import logo from "../../assets/images/logo.png";
// import MedicoDashboard from "../medici/components/MedicoDashboard.vue";

export default {
    components: { /*MedicoDashboard*/ },
    created() {},
    data() {
        return {
            logo,
        };
    },
};
</script>
<style lang="css">
.sa-logo-home img {
    width: 100%;
    max-width: 35rem;
}
</style>
