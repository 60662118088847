<template>
  <sa-page-layout :showmolalloading="showmolalloading" :btnNewVisible="true" @refresh="onRefresh" :linkedit="linkedit" :btnRefreshVisible="true">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="toolbar-row">
      <paziente-dashboard-component :idPaziente="idPaziente"></paziente-dashboard-component>
    </template>
    <!-- <template slot="toolbar">
      <b-button class="float-sm-right " variant="success btn-toolbar sa-margin-right" size="sm" @click="onAdd"><b-icon icon="plus" variant="secondary"></b-icon>{{ this.$i18n.t("global.lblBtnNew") }}</b-button>
      <b-button class="float-sm-right " variant="info btn-toolbar" size="sm" @click="onRefresh" v-b-modal.modal-center><b-icon icon="arrow-clockwise" variant="secondary" />Refresh</b-button>
    </template> -->
    <template slot="table-filter"> </template>
    <template slot="table-header">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-table
          sticky-header
          ref="table"
          :filter="filter"
          responsive
          stacked="lg"
          striped
          hover
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          sort-icon-left
          head-variant="light"
          class="sa-b-table"
        >
          <template v-slot:cell(nomeMedicoPresente)="{ item }">
            <router-link class="sa-edit-link" :to="'/paziente/teleconsulti/view/' + item.id + '?idPaziente=' + idPaziente"> {{ item.nomeMedicoPresente }} </router-link>
          </template>
          <template v-slot:cell(actions)="row">
            <b-button size="sm" class="mr-1" variant="secondary" @click="onAvviaTeleconsulto(row.item, row.index, $event.target)"> <b-icon icon="display" variant="secondary" /> Avvia </b-button>
          </template>
        </b-table>
      </div>
    </template>
    <template slot="table-footer">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
//import moment from "moment";
import { SaPageLayout } from "../../../template/sa/components/layout/contents";
import PazienteAnagrafica from "./PazienteAnagrafica.vue";
import UtilityMixin from "../../utility/UtilityMixin.js";
import PazienteDashboardComponent from "./PazienteDashboardComponent.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  components: { SaPageLayout, PazienteAnagrafica, PazienteDashboardComponent },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    rows() {
      return this.items.length;
    },
    btnNewVisible: {
      get: function() {
        let me = this;
        console.log(me.utente.gruppo);
        console.log(UtilityMixin.methods.verificaPermessi(me.utente, "teleconsulto", 2));
        return UtilityMixin.methods.verificaPermessi(me.utente, "teleconsulto", 2);
      },
    },
    linkPatient: {
      get: function() {
        let returnValue = "";
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length > 1) {
              return "/paziente/view/" + this.idPaziente;
            } else {
              if (this.utente.gruppo.menuGruppo.length == 1) {
                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                  return "/paziente/view/" + this.idPaziente;
                } else {
                  return "/paziente/homepaziente";
                }
              }
            }
          }
        }
        return returnValue;
      },
    },
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      filter: null,
      id: "-1",
      pathRest: "/teleconsultoprenotazioni",
      // pagelink: "/paziente/teleconsulti",
      linkedit: "/paziente/teleconsulti",
      filtro: {},
      filterOn: [],
      showmolalloading: false,
      fields: [
        {
          label: this.$i18n.t('teleconsulto.lblDoctorFirstname'),
          key: "nomeMedicoPresente",
          sortable: true,
          //thStyle: "width: 7rem",
        },
        {
          label: this.$i18n.t('teleconsulto.lblDoctorLastname'),
          key: "cognomeMedico",
          //thStyle: "width: rem",
          sortable: true,
        },
        {
          label: this.$i18n.t('teleconsulto.lblDoctorCode'),
          key: "codiceMedicoPresente",
          sortable: true,
        },
        { key: "actions", label: "", thStyle: "width: 8rem" },
      ],
      items: [],
    };
  },
  created: function() {},
  mounted() {
    let me = this;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      me.showmolalloading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathRest + "?idPaziente=";
      axios
        .get(link + me.idPaziente, { params: me.filtro })
        .then((response) => {
          me.items = [];
          me.items = response.data.data;
          me.showmolalloading = false;
        })
        .catch(() => {
          me.showmolalloading = false;
        });
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onReset() {
      let me = this;
      me.filtro = {};
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    // onAdd() {
    //   let me = this;
    //   me.$router.replace(me.pagelink + "/edit/-1?idPaziente=" + me.idPaziente).catch((err) => {
    //     err;
    //   });
    // },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onAvviaTeleconsulto(item) {
      let me = this;
      // me.$router.replace("/paziente/teleconsulti/" + item.id + "?idPaziente=" + this.idPaziente).catch((err) => {
      //     err;
      // });
      me.$router.replace("/paziente/teleconsultohassisto/" + item.id + "?idPaziente=" + this.idPaziente).catch((err) => {
        err;
      });
    },
  },
};
</script>
