<template>
    <sa-page-layout ref="pgaView" :btnBackVisible="true" :btnEditVisible="true" :pathResource="pathResource" :data="data" :linkback="linkback" :linkedit="linkedit" :showModalLoading="showModalLoading">
        <template slot="toolbar">
            <div style="float: right">
                <print-component :reportName="reportName" :data="data"></print-component>
            </div>
        </template>
        <template slot="table-filter">
            <div style="max-height: 18rem; overflow: auto;">
                <legend>Dati Richiesta</legend>
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col lg="4">
                        <fieldset class="sa-fieldset-intestazione">
                            <label>Cod. provetta pretrasfusionale: </label>
                            <p>{{ data.codiceProvettaPretrasfusionale }}</p>
                            <hr />
                            <label>Gruppo Sanguigno :</label>
                            <p>{{ data.gruppoSanguigno }} {{ onModificaFattoreRh(data.fattoreRh) }}</p>
                            <hr />
                            <label>Motivo richiesta: </label>
                            <p>{{ data.motivoRichiesta }}</p>
                            <hr />
                            <label>Tipologia: </label>
                            <p>{{ data.tipologia }}</p>
                            <hr />
                            <label>HB: </label>
                            <p>{{ data.hb }}</p>
                            <hr />
                            <label>APTT: </label>
                            <p>{{ data.aptt }}</p>
                            <hr />
                            <label>Codice Diagnosi: </label>
                            <p>{{ data.codiceDiagnosi }}</p>
                            <hr />
                            <label>Codice Reparto: </label>
                            <p>{{ data.codiceReparto }}</p>
                            <hr />
                        </fieldset>
                    </b-col>
                    <b-col lg="4">
                        <fieldset class="sa-fieldset-intestazione">
                            <label>N° sacche richieste: </label>
                            <p>{{ data.numeroSaccheRichieste }}</p>
                            <hr />
                            <label>Matricola Medico: </label>
                            <p>{{ data.matricolaMedico }}</p>
                            <hr />
                            <label>Data Richiesta: </label>
                            <p>{{ formatDate(data.dataRichiesta) }}</p>
                            <hr />
                            <label>Reparto: </label>
                            <p>{{ data.reparto }}</p>
                            <hr />
                            <label>INR: </label>
                            <p>{{ data.inr }}</p>
                            <hr />
                            <label>PT: </label>
                            <p>{{ data.pt }}</p>
                            <hr />
                            <label>Codice Struttura: </label>
                            <p>{{ data.codiceStruttura }}</p>
                            <hr />
                        </fieldset>
                    </b-col>
                    <b-col lg="4">
                        <fieldset class="sa-fieldset-intestazione">
                            <label>Provetta Pretrasfusionale: </label>
                            <p>{{ data.provettaPretrasfusionale }}</p>
                            <hr />
                            <label>Stato Attuale: </label>
                            <p>{{ data.statoAttuale }}</p>
                            <hr />
                            <label>Matricola Operatore: </label>
                            <p>{{ data.matricolaOperatore }}</p>
                            <hr />
                            <label>Data Stato Attuale: </label>
                            <p>{{ formatDate(data.statoAttualeDataOra) }}</p>
                            <hr />
                            <label>PLT: </label>
                            <p>{{ data.plt }}</p>
                            <hr />
                            <label>Stato Richiesta: </label>
                            <p>{{ data.statoRichiesta }}</p>
                            <hr />
                            <label>Grado Urgenza: </label>
                            <p>{{ data.gradoUrgenza }}</p>
                            <hr />
                        </fieldset>
                    </b-col>
                </b-row>

                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <legend>Dati Paziente</legend>
                    <b-col lg="4">
                        <fieldset class="sa-fieldset-intestazione">
                            <label>Nominativo Paziente: </label>
                            <p>{{ data.nomePaziente }} {{ data.cognomePaziente }}</p>
                            <hr />
                            <label>Luogo di Nascita: </label>
                            <p>{{ data.luogoNascitaPaziente }}</p>
                            <hr />
                            <label>Trasfusioni Pregresse: </label>
                            <p>{{ data.trasfusioniPregresse }}</p>
                            <hr />
                            <label>Parti/Aborti Pregressi: </label>
                            <p>{{ data.partiAbortiPregressi }}</p>
                            <hr />
                        </fieldset>
                    </b-col>
                    <b-col lg="4">
                        <fieldset class="sa-fieldset-intestazione">
                            <label>Data di Nascita: </label>
                            <p>{{ formatDate(data.dataNascitaPaziente) }}</p>
                            <hr />
                            <label>Data Ricovero: </label>
                            <p>{{ formatDate(data.dataRicovero) }}</p>
                            <hr />
                            <label>Ultima Trasfusione: </label>
                            <p>{{ formatDate(data.dataUltimaTrasfusione) }}</p>
                            <hr />
                            <label>Data Ultimo Aborto/Parto: </label>
                            <p>{{ formatDate(data.dataUltimoAbortoParto) }}</p>
                            <hr />
                        </fieldset>
                    </b-col>
                    <b-col lg="4">
                        <fieldset class="sa-fieldset-intestazione">
                            <label>Sesso: </label>
                            <p>{{ data.sessoPaziente }}</p>
                            <hr />
                            <label>Cartella Clinica Paziente: </label>
                            <p>{{ data.cartellaClinica }}</p>
                            <hr />
                            <label>Reazione Trasfusionale: </label>
                            <p>{{ data.reazioniTrasfusionali }}</p>
                            <hr />
                            <label>Ultima Reazione Trasfusionale: </label>
                            <p>{{ formatDate(data.dataUltimaReazioneTrasfusionale) }}</p>
                            <hr />
                        </fieldset>
                    </b-col>
                </b-row>
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col lg="12">
                        <user-information :data="data"></user-information>
                        <hr />
                    </b-col>
                    <!-- <b-card header="User Information">
                        <blockquote class="blockquote mb-0">
                            <user-information :data="data"></user-information>
                        </blockquote>
                    </b-card> -->
                </b-row>
            </div>
        </template>
        <template slot="table-header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6" lg="3">
                    <p>Totale Record: {{ rows }}</p>
                </b-col>
                <b-col cols="6" lg="9">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>

        <template slot="table-body">
            <div class="sa-tab-scroll">
                <div class="b-table">
                    <b-table sticky-header id="richiesteReparti" responsive="xl" stacked="xl" striped hover :items="items" :fields="fieldsRichiesteReparti" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="data">
                            {{ data.index + 1 }}
                        </template>
                    </b-table>
                </div>
            </div>
        </template>
        <template slot="table-footer">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>Totale Record: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import UserInformation from "../../utenti/components/UserInformation.vue";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PrintComponent from "../../utility/components/PrintComponent.vue";
export default {
    components: { UserInformation, SaPageLayout, PrintComponent },
    computed: {
        rows() {
            return this.items.length;
        },
    },
    data() {
        return {
            showModalLoading: false,
            pathResource: "/bancaematicatrasfusionirichiestereparti",
            linkback: "/bancaematicarichiestereparto",
            linkrichiestereparti: "",
            reportName: "RichiestaRepartoSingola",
            linkedit: null,
            search: {},
            elaborazione: false,
            currentPage: 1,
            totalRows: 1,
            perPage: 1500,
            fieldsRichiesteReparti: [
                {
                    label: "N.",
                    key: "index",
                    sortable: true,
                    thStyle: "width: auto",
                },
                {
                    label: "Stato",
                    key: "stato",
                    sortable: true,
                    thStyle: "width: auto",
                },
                {
                    label: "Stato Data e Ora",
                    key: "statoDataOra",
                    formatter: (value) => {
                        if (!value) {
                            return "- - -";
                        } else {
                            return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
                        }
                    },
                    thStyle: "width: auto",
                    sortable: true,
                },
                {
                    label: "Data Creazione",
                    key: "createDate",
                    formatter: (value) => {
                        if (!value) {
                            return "- - -";
                        } else {
                            return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
                        }
                    },
                    thStyle: "width: auto",
                    sortable: true,
                },
                {
                    label: "Note",
                    key: "note",
                    thStyle: "width: auto",
                    sortable: true,
                },
                { key: "actions", label: "", thStyle: "width: 7rem", tdClass: "text-center" },
            ],

            items: [],
            richiesteReparti: [],
            data: {},
        };
    },
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/bancaematicarichiestereparto/edit/" + me.id;
        me.loadData();
    },
    update: function() {
        console.log("Update");
    },
    methods: {
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            } else {
                return "---";
            }
        },
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            me.isBusy = true;
            axios
                .get(link + me.id)
                .then((response) => {
                    me.data = response.data.data;
                    me.items = response.data.data.bancaEmaticaTrasfusioneRichiestaRepartoStato;
                    me.perPage = me.data.length;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },

        onSubmit() {
            let me = this;
            console.log(me.search);
        },
        onModificaFattoreRh(valore) {
            if (valore === "POSITIVO") {
                return "+";
            } else {
                return "-";
            }
        },
    },
};
</script>
