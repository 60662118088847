<template>
  <sa-page-layout :showModalLoading="showModalLoading" :btnSaveVisible="true" :btnAnnullaVisible="true" :btnBackVisible="false" :linkback="linkback" :pathResource="pathResource" :data="jsonData">
    <!-- <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template> -->
    <template slot="table-header">
      <h5 class="sa-text-left">{{ this.$i18n.t("paziente.questionari.lblSurveyData") }}</h5>
    </template>
    <template slot="table-body">
      <div class="mb-4 sa-tab-scroll">
        <b-row>
          <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
            <strong>{{ this.$i18n.t("paziente.questionari.lblSurveyDescription") }}:</strong>
            <b-form-input type="text" v-model="jsonData.descrizioneQuestionario"></b-form-input
          ></b-col>
          <b-col cols="12" xs="12" sm="2" md="2" lg="2" xl="2">
            <strong>{{ this.$i18n.t("paziente.questionari.lblTotalQuestions") }}:</strong>
            <b-form-input type="number" disabled v-model="jsonData.questionarioDomandaBeans.length"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" v-if="check">
            <div style="height: 11px;"></div>
            <b-row
              ><b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <b-button class="float-sm-right " variant="success btn-toolbar sa-margin-right" size="sm" @click="onSave"
                  ><b-icon icon="check"></b-icon>{{ this.$i18n.t("paziente.questionari.lblBtnSaveQuestions") }}</b-button
                >
              </b-col>
              <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6"
                ><b-button class="float-sm-right " variant="secondary btn-toolbar sa-margin-right" size="sm" @click="onAdd"
                  ><b-icon icon="plus"></b-icon>{{ this.$i18n.t("paziente.questionari.lblBtnAddQuestion") }}</b-button
                >
              </b-col></b-row
            >
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <strong>{{ this.$i18n.t("paziente.questionari.lblSurveyNotes") }}:</strong>
            <b-form-textarea rows="4" no-resize v-model="jsonData.testo"></b-form-textarea
          ></b-col>
        </b-row>
        <hr />
        <b-row
          ><b-col>
            <div v-if="check">
              <div v-for="item in domande" :key="item.id">
                <paziente-questionari-edit-component :domanda="item" :idQuestionario="id"></paziente-questionari-edit-component>
              </div>
            </div> </b-col
        ></b-row>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
// import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
import PazienteQuestionariEditComponent from "./PazienteQuestionariEditComponent.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  components: {
    SaPageLayout,
    // PazienteAnagrafica,
    PazienteQuestionariEditComponent,
  },
  data() {
    return {
      linkback: null,
      pathResource: "/questionario",
      jsonData: { questionarioDomandaBeans: [] },
      showModalLoading: false,
      domande: null,
      check: false,
      count: 1,
    };
  },
  watch: {
    count: function() {
      this.$nextTick(function() {
        var container = this.$refs.msgContainer;
        container.scrollTop = container.scrollHeight + 120;
      });
    },
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    // linkPatient: {
    //   get: function() {
    //     let returnValue = "";
    //     if (this.utente.gruppo) {
    //       if (this.utente.gruppo.menuGruppo) {
    //         if (this.utente.gruppo.menuGruppo.length > 1) {
    //           return "/paziente/view/" + this.idPaziente;
    //         } else {
    //           if (this.utente.gruppo.menuGruppo.length == 1) {
    //             if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
    //               return "/paziente/view/" + this.idPaziente;
    //             } else {
    //               return "/paziente/homepaziente";
    //             }
    //           }
    //         }
    //       }
    //     }
    //     return returnValue;
    //   },
    // },
  },
  mounted() {
    let me = this;
    me.jsonData.idPaziente = this.$route.query.idPaziente;
    me.id = this.$route.params.id;

    addEventListener("eliminaDomanda", (e) => {
      me.onRemoveQuestion(e.detail);
    });

    if (me.id === "-1") {
      let id = (me.jsonData.questionarioDomandaBeans.length + 1) * -1;
      let domanda = { id: id, questionarioDomandaRispostaBeans: [] };
      me.jsonData.questionarioDomandaBeans.push(domanda);
      me.domande = me.jsonData.questionarioDomandaBeans;
    }
    me.linkedit = "/questionari/edit/" + me.id;
    if (me.id !== "-1") {
      me.linkback = "/questionari/view/" + me.id;
      me.loadData();
      console.log(me.check);
    } else {
      me.linkback = "/questionari?";
    }
  },

  methods: {
    loadData() {
      let me = this;
      me.domande = [];
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          me.jsonData = response.data.data;
          me.domande = me.jsonData.questionarioDomandaBeans;
          me.check = true;
          me.showModalLoading = false;

          console.log(me.jsonData);
        });
      }
    },
    onSave() {
      let event = new CustomEvent("salvaDomande", { detail: this.id });
      dispatchEvent(event);
    },
    onAdd() {
      let id = (this.domande.length + 1) * -1;
      let domanda = { id: id, questionarioDomandaRispostaBeans: [] };
      this.domande.push(domanda);
    },
    onRemoveQuestion(domanda) {
      let me = this;
      me.domande.forEach((element, index) => {
        if (element.id === domanda.id) {
          me.domande.splice(index, 1);
          let linkDelete = process.env.VUE_APP_PATH_API + "/questionaridomande/" + domanda.id;
          axios
            .delete(linkDelete)
            .then((res) => {
              console.log(res);
            })
            .catch();
        }
      });
    },
    onFormattText(value) {
      let me = this;
      switch (value) {
        case "coincidenzaConMalattie":
          me.jsonData.coincidenzaConMalattieText = me.jsonData.coincidenzaConMalattie ? me.jsonData.coincidenzaConMalattieText : "";
          break;
        case "coincidenzaConAltro":
          me.jsonData.coincidenzaConAltroText = me.jsonData.coincidenzaConAltro ? me.jsonData.coincidenzaConAltroText : "";
          break;
        case "patologieConcomitantiAltreEndocrinopatie":
          me.jsonData.patologieConcomitantiAltreEndocrinopatieText = me.jsonData.patologieConcomitantiAltreEndocrinopatie ? me.jsonData.patologieConcomitantiAltreEndocrinopatieText : "";
          break;
        case "terapiePraticateAltriFarmaci":
          me.jsonData.terapiePraticateAltriFarmaciText = me.jsonData.terapiePraticateAltriFarmaci ? me.jsonData.terapiePraticateAltriFarmaciText : "";
          break;
        case "terapiePraticateInsulina":
          me.jsonData.terapiePraticateInsulinaText = me.jsonData.terapiePraticateInsulina ? me.jsonData.terapiePraticateInsulinaText : "";
          break;
        case "patologieConcomitantiAltro":
          me.jsonData.patologieConcomitantiAltroText = me.jsonData.patologieConcomitantiAltro ? me.jsonData.patologieConcomitantiAltroText : "";
          break;
        case "terapiePraticateAntidiabeticiOrali":
          me.jsonData.terapiePraticateAntidiabeticiOraliText = me.jsonData.terapiePraticateAntidiabeticiOrali ? me.jsonData.terapiePraticateAntidiabeticiOraliText : "";
          break;
      }
    },
  },
};
</script>

<style scoped>
.scrollable {
  overflow: hidden;
  overflow-y: scroll;
}
</style>
