import SorveglianzaRefertiList from "./components/SorveglianzaRefertiList.vue";
import SorveglianzaRefertiView from "./components/SorveglianzaRefertiView.vue";
import SorveglianzaRefertiUtentiList from "./components/SorveglianzaRefertiUtentiList.vue"
import SorveglianzaRefertiUtentiEdit from "./components/SorveglianzaRefertiUtentiEdit.vue"
import SorveglianzaRefertiUtentiView from "./components/SorveglianzaRefertiUtentiView.vue"

export default {
    SorveglianzaRefertiList,
    SorveglianzaRefertiView,
    SorveglianzaRefertiUtentiList,
    SorveglianzaRefertiUtentiEdit,
    SorveglianzaRefertiUtentiView
};
