<template>
    <sa-page-layout :titolo="titolo" :showModalLoading="showModalLoading" :btnNewVisible="false" :linkedit="linkedit">
        <template slot="toolbar">
            <b-button class="float-sm-right " variant="success btn-toolbar sa-margin-right" size="sm" v-b-modal.mdlCaricaCsv @click="onAdd"><b-icon icon="plus"></b-icon>{{ this.$i18n.t("global.lblBtnNew") }}</b-button>
            <b-button class="float-sm-right sa-btn-header sa-btn-icon-text" variant="light sa-margin-right" size="sm" @click="onRefresh" v-b-modal.modal-center><b-icon icon="arrow-clockwise" variant="secondary" />Refresh</b-button>
        </template>
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col lg="12">
                        <b-input-group size="sm">
                            <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Testo da Cercare"></b-form-input>
                            <b-input-group-append>
                                <b-button :disabled="!filter" @click="filter = ''">Svuota</b-button>
                            </b-input-group-append>
                            <!--<b-form-input v-model="filtro.testo" type="search" placeholder="Testo"></b-form-input>-->
                        </b-input-group>
                    </b-col>
                </b-row>
            </b-form>
        </template>
        <template slot="table-header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>Totale Record: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" id="tbl" :filter="filter" responsive stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template #cell(nomeCsv)="{ item }">
                        <router-link class="sa-edit-link" :to="'/importtamponialchymia/view/' + item.id">{{ item.nomeCsv }}</router-link>
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>Totale Record: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
            <b-modal id="mdlCaricaCsv" ref="mdlCaricaCsv" no-close-on-backdrop centered :title="data.titolo" @hidden="onHideCaricaFileCsv" @show="onShowCaricaFileCsv" ok-only>
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <span class="sa-form-label">Data File</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="fileCsv.data" type="datetime" placeholder="Data Nascita"></date-picker>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <span class="sa-form-label">Note</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <b-form-textarea v-model="fileCsv.note"></b-form-textarea>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" lg="12" md="12" class="sa-text-left">
                        <b-form-file v-model="fileCsv.file" ref="file" accept=".csv" placeholder="Seleziona o trascina il file qui..."></b-form-file>
                    </b-col>
                    <b-col cols="12" lg="12" md="12" class="sa-text-left">
                        <b-form-checkbox v-model="fileCsv.primaRigaIntestazione" name="check-button" switch>
                            La prima riga è l'intestazione
                        </b-form-checkbox>
                    </b-col>
                </b-row>

                <template #modal-footer="{ ok }">
                    <b-button size="sm" variant="outline-secondary" @click="onCaricaFileCsv()"> <font-awesome-icon size="xs" icon="upload" tabindex="-1" /> Carica </b-button>
                    <b-button size="sm" variant="info" @click="ok()">
                        Chiudi
                    </b-button>
                </template>
            </b-modal>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { DatePicker, SaPageLayout },
    computed: {
        rows() {
            return this.items.length;
        },
    },
    data() {
        return {
            titolo: "Lista Files",
            showModalLoading: false,
            pagelink: "/importtamponialchymia",
            pathResource: "/importtamponialchymia",
            linkedit: "/importtamponialchymia",
            currentPage: 1,
            perPage: 30,
            fileCsv: { file: null, primaRigaIntestazione: true, data: new Date().getTime(), note: "" },
            filter: "",
            fields: [
                {
                    label: "Data",
                    key: "data",
                    sortable: true,
                    thStyle: "width: 9rem",
                    formatter: (value) => {
                        return moment(new Date(value)).format("DD-MM-YYYY");
                    },
                },
                {
                    label: "Nome File csv",
                    key: "nomeCsv",
                    sortable: true,
                    thStyle: "width: 18rem",
                },
                {
                    label: "Totale",
                    key: "totaleTamponi",
                    sortable: true,
                    thStyle: "width: 8rem",
                },
                {
                    label: "Importati",
                    key: "totaleImportati",
                    thStyle: "width: 8rem",
                    sortable: true,
                },
                {
                    label: "Scartati",
                    key: "totaleScartati",
                    thStyle: "width: 8rem",
                    sortable: true,
                },
                {
                    label: "Note",
                    key: "note",
                    sortable: true,
                },
                {
                    label: "Note",
                    key: "note",
                    sortable: true,
                },
            ],
            items: [],
            data: [],
            filtro: {},
        };
    },
    created: function() {},
    mounted: function() {
        let me = this;
        me.loadData();
    },
    update: function() {
        console.log("Update");
    },
    methods: {
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            } else {
                return "---";
            }
        },
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.showModalLoading = true;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(function(error) {
                    me.showModalLoading = false;
                    me.$bvToast.toast(error.response.data.messaggio, {
                        title: "Sorveglianza Covid Admin",
                        variant: "danger",
                        solid: true,
                    });
                });
        },
        onAdd() {
            // let me = this;
            // me.$router.replace(me.pagelink + "/edit/-1").catch((err) => {
            //     err;
            // });
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onSubmit: function() {
            let me = this;
            me.filter = me.filtro.titolo;
            return false;
        },
        onCaricaFileCsv() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/upload";
            let reader = new FileReader();
            reader.readAsDataURL(me.fileCsv.file);
            reader.onload = () => {
                let invioFileCsv = {};
                invioFileCsv.idInvioSms = me.data.id;
                invioFileCsv.file = reader.result;
                invioFileCsv.primaRigaIntestazione = me.fileCsv.primaRigaIntestazione;
                invioFileCsv.fileName = me.fileCsv.file.name;
                invioFileCsv.data = me.fileCsv.data;
                invioFileCsv.note = me.fileCsv.note;
                let jsonData = JSON.stringify(invioFileCsv);
                axios.post(link, jsonData).then(() => {
                    me.showmolalloading = false;
                    me.$refs.mdlCaricaCsv.hide();
                    me.loadData();
                });
            };
        },
        onHideCaricaFileCsv() {},
        onShowCaricaFileCsv() {},
    },
};
</script>
<style scoped></style>
