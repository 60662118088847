<template>
  <div>
    <b-card>
      <b-row
        ><b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <strong>{{ getLabelTraduora("paziente.questionari.lblBtnQuestion") }}:</strong>

          <b-form-input type="text" v-model="domanda.domanda"></b-form-input></b-col
        ><b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <b-row>
            <b-col cols="12" xs="12" sm="8" md="8" lg="8" xl="8"
              ><strong>{{ getLabelTraduora("paziente.questionari.lblBtnQuestionType") }}:</strong>

              <b-form-select type="text" v-model="domanda.tipoRisposta" :options="opzioniTipoDomanda" :disabled="domanda.questionarioDomandaRispostaBeans[0] ? true : false"></b-form-select
            ></b-col>
            <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
              <div style="height: 20px;"></div>
              <b-button class="float-sm-right " variant="secondary btn-toolbar sa-margin-right" size="sm" @click="onAdd(domanda.tipoRisposta)"
                ><b-icon icon="plus"></b-icon>{{ getLabelTraduora("paziente.questionari.lblBtnAddAnswer") }}</b-button
              >
            </b-col>
          </b-row>
        </b-col></b-row
      >
      <b-row v-if="domanda.tipoRisposta === 'text' && domanda.questionarioDomandaRispostaBeans[0]">
        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4" v-for="risposta in domanda.questionarioDomandaRispostaBeans" :key="risposta.id">
          <b-card>
            <b-row>
              <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
                ><strong>{{ getLabelTraduora("paziente.questionari.lblBtnAnswerLabel") }}:</strong> <b-form-input v-model="risposta.label"></b-form-input
              ></b-col>
              <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
                ><strong>{{ getLabelTraduora("paziente.questionari.lblBtnAnswerType") }}:</strong> <b-form-select v-model="risposta.tipo" :options="opzioniTipoRispostaText"></b-form-select
              ></b-col>
              <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-center"
                ><div style="height: 20px;"></div>
                <b-button variant="outline-danger" @click="onRemove(risposta.id)"><b-icon icon="trash"></b-icon>{{ getLabelTraduora("paziente.questionari.lblBtnDeleteAnswer") }}</b-button></b-col
              >
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row v-if="domanda.tipoRisposta === 'select' && domanda.questionarioDomandaRispostaBeans[0]">
        <b-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4" v-for="risposta in domanda.questionarioDomandaRispostaBeans" :key="risposta.id">
          <b-card>
            <b-row>
              <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
                ><strong>{{ getLabelTraduora("paziente.questionari.lblBtnAnswerlabel") }}:</strong> <b-form-input v-model="risposta.label"></b-form-input
              ></b-col>
              <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
                ><strong>{{ getLabelTraduora("paziente.questionari.lblBtnAnswerOption") }}:</strong> <b-form-input v-model="risposta.predefinito"></b-form-input
              ></b-col>
              <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-center"
                ><div style="height: 20px;"></div>
                <b-button variant="outline-danger" @click="onRemove(risposta.id)"><b-icon icon="trash"></b-icon>{{ getLabelTraduora("paziente.questionari.lblBtnDeleteAnswer") }}</b-button></b-col
              >
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row v-if="domanda.tipoRisposta === 'checkbox' && domanda.questionarioDomandaRispostaBeans[0]">
        <b-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4" v-for="risposta in domanda.questionarioDomandaRispostaBeans" :key="risposta.id">
          <b-card>
            <b-row>
              <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
                ><strong>{{ getLabelTraduora("paziente.questionari.lblBtnAnswerOption") }}:</strong> <b-form-input v-model="risposta.predefinito"></b-form-input
              ></b-col>
              <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-center"
                ><div style="height: 20px;"></div>
                <b-button variant="outline-danger" @click="onRemove(risposta.id)"><b-icon icon="trash"></b-icon>{{ getLabelTraduora("paziente.questionari.lblBtnDeleteAnswer") }}</b-button></b-col
              >
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-center"
          ><div style="height: 20px;"></div>
          <b-button variant="outline-danger" @click="onRemoveDomanda(domanda)"><b-icon icon="trash"></b-icon>{{ getLabelTraduora("paziente.questionari.lblBtnDeleteQuestion") }}</b-button></b-col
        ></b-row
      >
    </b-card>
    <hr />
  </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../UtilityMixin";

export default {
  mixins: [UtilityMixin],
  props: {
    domanda: {
      Type: Object,
      require: true,
    },
    idQuestionario: {
      Type: String,
      require: true,
    },
  },
  computed: {},
  data() {
    return {
      jsonData: [],
      showModalLoading: false,
      pathResource: "/questionari",
      lock: false,
      currentPage: 1,
      perPage: 10,
      opzioniTipoDomanda: ["select", "text", "checkbox"],
      opzioniTipoRispostaText: ["number", "text", "date"],
    };
  },
  mounted() {
    let me = this;
    addEventListener("salvaDomande", () => {
      me.onSave(me.domanda);
    });
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      me.showmolalloading = true;
      //   let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      //   axios.get(link + me.idEvento).then((response) => {
      //     me.jsonData = response.data.data;
      //   });
    },
    onAdd(tipo) {
      let me = this;
      me.lock = true;
      let newID = me.uuidv4();
      let risposta = { id: newID, label: "", tipo: "", predefinito: "" };
      if (tipo !== "text") {
        risposta.tipo === tipo;
      }
      me.domanda.questionarioDomandaRispostaBeans.push(risposta);
    },
    onSave(domanda) {
      let me = this;
      me.showModalLoading = true;
      let linkPost = process.env.VUE_APP_PATH_API + "/questionaridomande";
      let linkPut = process.env.VUE_APP_PATH_API + "/questionaridomande/" + domanda.id;
      let id = domanda.id;
      if (Number.isInteger(id)) {
        domanda.idQuestionario = me.idQuestionario;
        axios
          .post(linkPost, domanda)
          .then((res) => {
            console.log(res.data.data);
            me.showModalLoading = false;
          })
          .catch();
      } else {
        axios
          .put(linkPut, domanda)
          .then((res) => {
            console.log(res.data.data);
            me.showModalLoading = false;
          })
          .catch();
      }
    },
    onRemoveDomanda(domanda) {
      let evento = new CustomEvent("eliminaDomanda", { detail: domanda });
      dispatchEvent(evento);
    },
    onRemove(id) {
      let me = this;
      for (var i = 0; i < me.domanda.questionarioDomandaRispostaBeans.length; i++) {
        if (me.domanda.questionarioDomandaRispostaBeans[i].id === id) {
          me.domanda.questionarioDomandaRispostaBeans.splice(i, 1);
          i--;
        }
      }
    },
    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16));
    },
    returnLabel(tipo) {
      if (tipo === "text") {
        return "Risposta";
      }
    },
  },
};
</script>
