<template>
  <div>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <strong>{{ getLabelTraduora("patient.afa.lblFinalJudgement") }}</strong>
            <br />
            {{ jsonData.giudizioFinale }}
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <span class="sa-form-label">{{ getLabelTraduora("therapy.lblStatus") }}:</span><br />
            <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblPhysicalExamination") }}: </span> {{ jsonData.firmaEo }} <br />
            <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblElectrocardiogram") }}: </span> {{ jsonData.firmaEcg }} <br />
            <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblFinalJudgement") }}: </span>{{ jsonData.firmaGf }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";

export default {
  mixins: [UtilityMixin],
  props: {
    jsonData: {
      Type: Object,
      require: true,
    },
  },
};
</script>
