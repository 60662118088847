<template>
    <sa-page-layout ref="pgaView" :btnBackVisible="true" :btnEditVisible="true" :data="data" :linkback="linkback" :linkedit="linkedit" :showModalLoading="showModalLoading">
        <template slot="toolbar">
            <div style="float: right">
                <print-component :reportName="reportName" :data="data"></print-component>
            </div>
        </template>
        <template slot="table-filter">
            <div style="max-height: 18rem; overflow: auto;">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col lg="4">
                        <fieldset class="sa-fieldset-intestazione">
                            <!-- <legend>Intestazione:</legend> -->
                            <label>Richiesta N. : </label>
                            <p>{{ data.numeroRichiesta }} del {{ formatDate(data.dataRichiesta) }}</p>
                            <hr />
                            <label>Numero Sacche Richieste : </label>
                            <p>{{ data.numeroSaccheRichieste }}</p>
                            <hr />
                            <label>Gruppo Sanguigno :</label>
                            <p>{{ data.gruppoSanguigno }} {{ onModificaFattoreRh(data.fattoreRh) }}</p>
                            <hr />
                            <label>Tipo Emoderivato : </label>
                            <p>{{ data.tipologia }}</p>
                            <hr />
                        </fieldset>
                    </b-col>
                    <b-col lg="4">
                        <fieldset class="sa-fieldset-intestazione">
                            <label>HB: </label>
                            <p>{{ data.hb }}</p>
                            <hr />
                            <label>PT: </label>
                            <p>{{ data.pt }}</p>
                            <hr />
                            <label>INR: </label>
                            <p>{{ data.inr }}</p>
                            <hr />
                        </fieldset>
                    </b-col>
                    <b-col lg="4">
                        <fieldset class="sa-fieldset-intestazione">
                            <label>APTT: </label>
                            <p>{{ data.aptt }}</p>
                            <hr />
                            <label>PTT: </label>
                            <p>{{ data.pit }}</p>
                            <hr />
                            <label>Stato Attuale: </label>
                            <p>{{ data.statoAttuale }}</p>
                            <hr />
                        </fieldset>
                    </b-col>
                </b-row>
            </div>
        </template>
        <template slot="table-header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6" lg="3">
                    <p>Totale Record: {{ rows }}</p>
                </b-col>
                <b-col cols="6" lg="9">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <b-tabs class="sa-tabs" content-class="mt-3" @activate-tab="onActiveTabs" fill>
                <div class="sa-tab-scroll">
                    <b-tab title="Richieste Scorte">
                        <b-table sticky-header id="richiesteScorteStato" responsive="xl" stacked="xl" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                            <template #cell(index)="data">
                                {{ data.index + 1 }}
                            </template>
                        </b-table>
                    </b-tab>
                </div>
            </b-tabs>
        </template>
        <template slot="table-footer">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>Totale Record: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PrintComponent from "../../utility/components/PrintComponent.vue";
export default {
    components: { SaPageLayout, PrintComponent },
    computed: {
        rows() {
            return this.items.length;
        },
    },
    data() {
        return {
            showModalLoading: false,
            pathResource: "/bancheematichetrasfusionirichiestescorte",
            linkback: "/bancaematicarichiestascorte",
            reportName: "ReportRichiestaScorteSingola",
            linkedit: null,
            search: {},
            elaborazione: false,
            currentPage: 1,
            totalRows: 1,
            perPage: 1500,
            fields: [
                {
                    label: "N.",
                    key: "index",
                    sortable: true,
                    thStyle: "width: auto",
                },
                {
                    label: "Stato",
                    key: "stato",
                    sortable: true,
                    thStyle: "width: auto",
                },
                {
                    label: "Stato Data e Ora",
                    key: "statoDataOra",
                    formatter: (value) => {
                        if (!value) {
                            return "";
                        } else {
                            return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
                        }
                    },
                    thStyle: "width: auto",
                    sortable: true,
                },
                {
                    label: "Note",
                    key: "note",
                    thStyle: "width: auto",
                    sortable: true,
                },
                { key: "actions", label: "", thStyle: "width: 18rem", tdClass: "text-center" },
            ],
            items: [],
            richiesteScorte: [],
            data: {},
        };
    },
    created: function() {},
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/bancaematicarichiestascorte/edit/" + me.id;
        me.loadData();
    },
    update: function() {
        console.log("Update");
    },
    methods: {
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            } else {
                return "---";
            }
        },
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            me.isBusy = true;
            axios
                .get(link + me.id)
                .then((response) => {
                    me.data = response.data.data;
                    me.richiesteScorte = response.data.data.bancaEmaticaTrasfusioneRichiestaScortaBean;
                    me.items = response.data.data.bancaEmaticaTrasfusioneRichiestaScorteStato;
                    me.perPage = me.data.length;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onActiveTabs(newTabIndex) {
            let me = this;
            if (newTabIndex === 1) {
                me.loadData();
            }
        },
        onSubmit() {
            let me = this;
            console.log(me.search);
        },
        onModificaFattoreRh(valore) {
            if (valore === "POSITIVO") {
                return "+";
            } else {
                return "-";
            }
        },
    },
};
</script>
<style lang="css">
/*.sa-tabs {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.sa-tabs .tab-content {
    height: 100%;
    overflow: auto;
}
.sa-fieldset-intestazione legend {
    font-size: 1em;
    font-weight: bold;
    text-decoration: underline;
}
.sa-fieldset-intestazione p {
    margin-bottom: 0.5em;
    line-height: 1.1em;
    font-weight: 800;
}
.sa-fieldset-intestazione label {
    padding-right: 0.3em;
    margin-bottom: 1.5em;
}*/
</style>
