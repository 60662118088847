<template>
    <sa-page-layout :pathResource="pathResource" :data="jsonData" :btnEditVisible="true" :btnBackVisible="true" :linkback="linkback" :linkedit="linkedit">
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <leonida-accettazione-paziente-view-component :jsonData="jsonData"></leonida-accettazione-paziente-view-component>
            </div>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import LeonidaAccettazionePazienteViewComponent from "./LeonidaAccettazionePazienteViewComponent.vue";
export default {
    components: { SaPageLayout, LeonidaAccettazionePazienteViewComponent },
    mounted() {
        let me = this;
        me.id = me.$route.params.id;
        me.linkedit = "/accettazione/edit/" + me.id;
        me.loadData();
    },
    data() {
        return {
            id: "",
            linkback: "/accettazione",
            linkedit: null,
            pathResource: "/accettazionileonida",
            jsonData: {
                accettazioneStruttura: { codiceStruttura: null },
                accettazioneAnagrafica: {
                    codiceFiscale: "",
                    provinciaNascita: null,
                    comuneNascitaCodiceIstat: null,
                    medicoCurante: "",
                    codiceFiscaleMedicoCurante: "",
                    provinciaResidenza: null,
                    comuneResidenzaCodiceIstat: null,
                    provinciaDomicilio: null,
                    comuneDomicilioCodiceIstat: null,
                    capDomicilio: null,
                    capResidenza: null,
                    dataNascita: null,
                    sesso: null,
                    tipoIdentificativo: null,
                    nome: "",
                    cognome: "",
                },
                idPrenotazione: "",
                tipo: "",
            },
        };
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            me.showModalLoading = true;
            axios
                .get(link + me.id)
                .then((response) => {
                    me.showModalLoading = false;
                    me.jsonData = response.data.data;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
    },
};
</script>
