<template>
  <sa-page-layout :showModalLoading="showModalLoading" :btnNewVisible="btnNewVisible" :linkedit="linkedit" :btnRefreshVisible="true" @refresh="onRefresh">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="toolbar-row">
      <paziente-dashboard-component :idPaziente="idPaziente"></paziente-dashboard-component>
    </template>
    <!-- <template slot="toolbar">
      <b-button class="float-sm-right" variant="success btn-toolbar sa-margin-right" size="sm" @click="onAdd"><b-icon icon="plus" variant="secondary"></b-icon>{{ this.$i18n.t("global.lblBtnNew") }}</b-button>
      <b-button class="float-sm-right" variant="info btn-toolbar" size="sm" @click="onRefresh" v-b-modal.modal-center><b-icon icon="arrow-clockwise" variant="secondary" />Refresh</b-button>
    </template> -->
    <template slot="table-header"> </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <b-row>
          <b-col>
            <span>{{ this.$i18n.t("patient.rangeConfig.lblNotes") }}</span>
          </b-col>
        </b-row>
        <hr />
        <!-- <b-row v-for="item in items" :key="item.id">
                    <b-col cols="6">{{ item.misureNome }}</b-col>
                    <b-col cols="6"><input-range-component :precision="0" :scala="2000" :center="1200" :maxValueAlert="maxValueAlert" :maxValueWarning="maxValueWarning" :minValueWarning="minValueWarning" :minValueAlert="minValueAlert"></input-range-component></b-col>
                </b-row> -->
        <ul v-for="item in items" :key="item.id">
          <li v-if="verificaRange(item)">
            <span>
              <strong>{{ getLabelMisure(item.misureNome) }}</strong>
            </span>
            <b-row>
              <b-col cols="1"
                ><span class="sa-form-label">{{ getLabelTraduora("global.lblMaxAllert") }} </span><b-form-input v-if="item.misureNome !== 'lbl.measure.totalSleepTime'" type="number" v-model="item.maxRangeAllert"/>

                <b-form-input v-if="item.misureNome === 'lbl.measure.totalSleepTime'" readonly type="text" :placeholder="formatToHours(item.maxRangeAllert)"></b-form-input
              ></b-col>
              <b-col cols="1"
                ><span class="sa-form-label"> {{ getLabelTraduora("global.lblMaxWarning") }}</span>
                <b-form-input v-if="item.misureNome !== 'lbl.measure.totalSleepTime'" type="number" v-model="item.maxRangeWarning"/><b-form-input
                  v-if="item.misureNome === 'lbl.measure.totalSleepTime'"
                  readonly
                  type="text"
                  :placeholder="formatToHours(item.maxRangeWarning)"
                ></b-form-input
              ></b-col>
              <b-col cols="1"
                ><span class="sa-form-label"> {{ getLabelTraduora("global.lblMinWarning") }} </span
                ><b-form-input v-if="item.misureNome !== 'lbl.measure.totalSleepTime'" type="number" v-model="item.minRangeWarning"/><b-form-input
                  v-if="item.misureNome === 'lbl.measure.totalSleepTime'"
                  readonly
                  type="text"
                  :placeholder="formatToHours(item.minRangeWarning)"
                ></b-form-input
              ></b-col>
              <b-col cols="1"
                ><span class="sa-form-label">{{ getLabelTraduora("global.lblMinAllert") }}</span>
                <b-form-input v-if="item.misureNome !== 'lbl.measure.totalSleepTime'" type="number" v-model="item.minRangeAllert"/><b-form-input
                  v-if="item.misureNome === 'lbl.measure.totalSleepTime'"
                  readonly
                  type="text"
                  :placeholder="formatToHours(item.minRangeAllert)"
                ></b-form-input
              ></b-col>
              <b-col cols="7">
                <input-range-component
                  :index="item.id"
                  :precision="getPrecision(item)"
                  :scala="getScala(item)"
                  :center="getCenter(item)"
                  :maxValueAlert="item.maxRangeAllert"
                  :maxValueWarning="item.maxRangeWarning"
                  :minValueWarning="item.minRangeWarning"
                  :minValueAlert="item.minRangeAllert"
                  @update="onUpdateRange"
                  @updateRangeRight="onUpdateRangeRight"
                ></input-range-component>
              </b-col>
              <b-col cols="1">
                <b-button class="no-text" @click="salva(item)" variant="outline-info"><b-icon icon="check-square"></b-icon> {{ getLabelTraduora("global.lblBtnSave") }}</b-button>
              </b-col>
            </b-row>
            <hr />
          </li>
        </ul>
        <b-row>
          <b-col>
            <span>{{ this.$i18n.t("patient.rangeConfig.lblNotesHabitsActivitiesDiet") }}</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <h3>{{ getLabelTraduora("patient.lifeStyle.lblHabits") }}</h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="3" xs="12" sm="12" md="4" lg="4" xl="4">
            <span
              ><strong>{{ getLabelTraduora("patient.lblAlcohol") }} </strong>
              <a data-toggle="modal" data-target="#collapse-info" href="#collapse-info" @click="showModal('alcohol')" onclick="return false"><b-icon icon="info-circle" font-scale="1"></b-icon></a>
            </span>
            <br />
            <b-form-select v-model="alcool" style="color:green !important; font-weight: bold !important;">
              <option v-for="opt in opzioniAlcool" :value="opt.value" :key="opt.value" @click="salvaAbitudiniDietaAttivita(itemAlcool, opt.value)" class="special">
                <span>{{ opt.text }}</span>
                <span v-if="opt.value === alcool" style="text-align: right !important;"> 🗸 </span>
              </option>
            </b-form-select>
          </b-col>
          <b-col cols="3" xs="12" sm="12" md="4" lg="4" xl="4">
            <span
              ><strong>{{ getLabelTraduora("patient.fisiologica.lblCoffee") }} </strong>
              <a data-toggle="modal" data-target="#collapse-info" href="#collapse-info" @click="showModal('coffee')" onclick="return false"><b-icon icon="info-circle" font-scale="1"></b-icon></a>
            </span>
            <br />
            <b-form-select v-model="caffe" style="color:green !important; font-weight: bold !important;">
              <option v-for="opt in opzioniCaffe" :value="opt.value" :key="opt.value" @click="salvaAbitudiniDietaAttivita(itemCaffe, opt.value)" class="special">
                <span>{{ opt.text }}</span>
                <span v-if="opt.value === caffe" style="text-align: right !important;"> 🗸 </span>
              </option>
            </b-form-select>
          </b-col>
          <b-col cols="3" xs="12" sm="12" md="4" lg="4" xl="4">
            <span
              ><strong>{{ getLabelTraduora("patients.lblSmokeQuantity") }}</strong></span
            >
            <br />
            <b-form-select v-model="sigarette" style="color:green !important; font-weight: bold !important;">
              <option v-for="opt in opzioniSigarette" :value="opt.value" :key="opt.value" @click="salvaAbitudiniDietaAttivita(itemSigarette, opt.value)" class="special">
                <span>{{ opt.text }}</span>
                <span v-if="opt.value === sigarette" style="text-align: right !important;"> 🗸 </span>
              </option>
            </b-form-select>
          </b-col>
        </b-row>
        <!--  -->
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <h3>{{ getLabelTraduora("patient.activities.lblActivities") }}</h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="3" xs="12" sm="12" md="6" lg="6" xl="6">
            <span>
              <strong>{{ getLabelTraduora("patient.activities.lblSessionsQuantity") }}</strong>
            </span>
            <br />
            <b-form-select v-model="attivitaSessioni" style="color:green !important; font-weight: bold !important;">
              <option v-for="opt in opzioniSessioniAttivita" :value="opt.value" :key="opt.value" @click="salvaAbitudiniDietaAttivita(itemNumeroSessioni, opt.value)" class="special">
                <span>{{ opt.text }}</span>
                <span v-if="opt.value === attivitaSessioni" style="text-align: right !important;"> 🗸 </span>
              </option>
            </b-form-select>
          </b-col>
          <b-col cols="3" xs="12" sm="12" md="6" lg="6" xl="6">
            <span>
              <strong>{{ getLabelTraduora("agenda.lblDuration") }}</strong>
            </span>
            <br />
            <b-form-select v-model="durataSessioni" style="color:green !important; font-weight: bold !important;">
              <option v-for="opt in opzioniDurataSessioni" :value="opt.value" :key="opt.value" @click="salvaAbitudiniDietaAttivita(itemDurataSessioni, opt.value)" class="special">
                <span>{{ opt.text }}</span>
                <span v-if="opt.value === durataSessioni" style="text-align: right !important;"> 🗸 </span>
              </option>
            </b-form-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <h3>{{ getLabelTraduora("patient.diet.lblTitle") }}</h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <span>
              <strong>{{ getLabelTraduora("patient.diet.lblBreakfast") }}</strong>
            </span>
            <br />
            <b-form-select v-model="dietaColazione" style="color:green !important; font-weight: bold !important;">
              <option v-for="opt in opzioniDieta" :value="opt.value" :key="opt.value" @click="salvaAbitudiniDietaAttivita(itemColazione, opt.value)" class="special">
                <span>{{ opt.text }}</span>
                <span v-if="opt.value === dietaColazione" style="text-align: right !important;"> 🗸 </span>
              </option>
            </b-form-select>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <span>
              <strong>{{ getLabelTraduora("patient.diet.lblMorningSnack") }}</strong>
            </span>
            <br />
            <b-form-select v-model="dietaSpuntinoMattutino" style="color:green !important; font-weight: bold !important;">
              <option v-for="opt in opzioniDieta" :value="opt.value" :key="opt.value" @click="salvaAbitudiniDietaAttivita(itemSpuntinoMattutino, opt.value)" class="special">
                <span>{{ opt.text }}</span>
                <span v-if="opt.value === dietaSpuntinoMattutino" style="text-align: right !important;"> 🗸 </span>
              </option>
            </b-form-select>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <span>
              <strong>{{ getLabelTraduora("patient.diet.lblLunch") }}</strong>
            </span>
            <br />
            <b-form-select v-model="dietaPranzo" style="color:green !important; font-weight: bold !important;">
              <option v-for="opt in opzioniDieta" :value="opt.value" :key="opt.value" @click="salvaAbitudiniDietaAttivita(itemPranzo, opt.value)" class="special">
                <span>{{ opt.text }}</span>
                <span v-if="opt.value === dietaPranzo" style="text-align: right !important;"> 🗸 </span>
              </option>
            </b-form-select>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <span>
              <strong>{{ getLabelTraduora("patient.diet.lblAfternoonSnack") }}</strong>
            </span>
            <br />
            <b-form-select v-model="dietaSpuntinoPomeridiano" style="color:green !important; font-weight: bold !important;">
              <option v-for="opt in opzioniDieta" :value="opt.value" :key="opt.value" @click="salvaAbitudiniDietaAttivita(itemSpuntinoPomeridiano, opt.value)" class="special">
                <span>{{ opt.text }}</span>
                <span v-if="opt.value === dietaSpuntinoPomeridiano" style="text-align: right !important;"> 🗸 </span>
              </option>
            </b-form-select>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <span>
              <strong>{{ getLabelTraduora("patient.diet.lblDinner") }}</strong>
            </span>
            <br />
            <b-form-select v-model="dietaCena" style="color:green !important; font-weight: bold !important;">
              <option v-for="opt in opzioniDieta" :value="opt.value" :key="opt.value" @click="salvaAbitudiniDietaAttivita(itemCena, opt.value)" class="special">
                <span>{{ opt.text }}</span>
                <span v-if="opt.value === dietaCena" style="text-align: right !important;"> 🗸 </span>
              </option>
            </b-form-select>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <span>
              <strong>{{ getLabelTraduora("patient.diet.lblNightSnack") }}</strong>
            </span>
            <br />
            <b-form-select v-model="dietaSpuntinoSerale" style="color:green !important; font-weight: bold !important;">
              <option v-for="opt in opzioniDieta" :value="opt.value" :key="opt.value" @click="salvaAbitudiniDietaAttivita(itemSpuntinoSerale, opt.value)" class="special">
                <span>{{ opt.text }}</span>
                <span v-if="opt.value === dietaSpuntinoSerale" style="text-align: right !important;"> 🗸 </span>
              </option>
            </b-form-select>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <span>
              <strong>{{ getLabelTraduora("patient.diet.lblSalt") }}</strong>
            </span>

            <br />
            <b-form-select v-model="dietaSale" style="color:green !important; font-weight: bold !important;">
              <option v-for="opt in opzioniSale" :value="opt.value" :key="opt.value" @click="salvaAbitudiniDietaAttivita(itemSale, opt.value)" class="special">
                <span>{{ opt.text }}</span>
                <span v-if="opt.value === dietaSale" style="text-align: right !important;"> 🗸 </span>
              </option>
            </b-form-select>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <span>
              <strong>{{ getLabelTraduora("patient.diet.lblWaterGlasses") }} </strong>
              <a data-toggle="modal" data-target="#collapse-info" href="#collapse-info" @click="showModal('water')" onclick="return false"><b-icon icon="info-circle" font-scale="1"></b-icon></a>
            </span>
            <br />
            <b-form-select v-model="dietaAcqua" style="color:green !important; font-weight: bold !important;">
              <option v-for="opt in opzioniAcqua" :value="opt.value" :key="opt.value" @click="salvaAbitudiniDietaAttivita(itemAcqua, opt.value)" class="special">
                <span>{{ opt.text }}</span>
                <span v-if="opt.value === dietaAcqua" style="text-align: right !important;"> 🗸 </span>
              </option>
            </b-form-select>
          </b-col>
        </b-row>
        <b-modal id="collapse-info" centered :title="titoloModal" size="lg" hide-footer>
          <b-row class="text-justify" style="padding-left:10px; padding-right:10px;"
            ><b-col cols="12"
              ><span>{{ descrizioneModal }}</span></b-col
            >
          </b-row>
          <hr />
          <div style="height: 30px;"></div>
        </b-modal>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import { SaPageLayout } from "../../../template/sa/components/layout/contents";
import PazienteAnagrafica from "./PazienteAnagrafica.vue";
import UtilityMixin from "../../utility/UtilityMixin.js";
import PazienteDashboardComponent from "./PazienteDashboardComponent.vue";
import InputRangeComponent from "../../utility/components/InputRangeComponent.vue";

export default {
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  mixins: [UtilityMixin],
  components: { SaPageLayout, PazienteAnagrafica, PazienteDashboardComponent, InputRangeComponent },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },

    rows() {
      return this.items.length;
    },
    btnNewVisible: {
      get: function() {
        let me = this;
        return UtilityMixin.methods.verificaPermessi(me.utente, "misurerange", 2);
      },
    },
    readOnly: {
      get: function() {
        let me = this;
        return !UtilityMixin.methods.verificaPermessi(me.utente, "misurerange", 2);
      },
    },
    btnHiddenEdit: {
      get: function() {
        let me = this;
        return !UtilityMixin.methods.verificaPermessi(me.utente, "misurerange", 2);
      },
    },
    btnHiddenDelete: {
      get: function() {
        let me = this;
        return !UtilityMixin.methods.verificaPermessi(me.utente, "misurerange", 2);
      },
    },
    linkPatient: {
      get: function() {
        let returnValue = "";
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length > 1) {
              return "/paziente/view/" + this.idPaziente;
            } else {
              if (this.utente.gruppo.menuGruppo.length == 1) {
                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                  return "/paziente/view/" + this.idPaziente;
                } else {
                  return "/paziente/homepaziente";
                }
              }
            }
          }
        }
        return returnValue;
      },
    },

    SliderMinValueAlert: {
      get: function() {
        var val = parseInt(this.minValueAlert);
        return val;
      },
      set: function(val) {
        val = parseInt(val);
        this.minValueAlert = val;
      },
    },
    SliderMaxValueAlert: {
      get: function() {
        var val = parseInt(this.maxValueAlert);
        return val;
      },
      set: function(val) {
        val = parseInt(val);
        this.maxValueAlert = val;
      },
    },
    SliderMinValueWarning: {
      get: function() {
        var val = parseInt(this.minValueWarning);
        return val;
      },
      set: function(val) {
        let value = parseInt(val);
        // console.log(value);
        this.minValueWarning = value;
      },
    },
    SliderMaxValueWarning: {
      get: function() {
        var val = parseInt(this.maxValueWarning);
        return val;
      },
      set: function(val) {
        val = parseInt(val);
        this.maxValueWarning = val;
      },
    },
  },
  data() {
    return {
      maxValueAlert: 0,
      maxValueWarning: 0,
      minValueWarning: 0,
      minValueAlert: 0,
      pathResource: "/pazientemisurerange",
      pagelink: "/paziente/rangeconfiguration",
      linkedit: "/paziente/rangeconfiguration",
      linktipodieta: "",
      perPage: 10,
      currentPage: 1,
      componentKey: 0,
      value: [0, 100],
      min: 0,
      max: 250,
      enableCross: false,
      array: 10,
      note: "",
      filter: null,
      descrizioneModal: "",
      titoloModal: "",
      showModalLoading: false,
      id: "-1",
      filtro: {},
      filterOn: [],
      fields: [
        {
          label: this.$i18n.t("patient.rangeConfig.lblMeasurementName"),
          key: "misureNome",
          thStyle: "width: 3rem",
        },
        {
          label: this.$i18n.t("patient.rangeConfig.lblMinAlertValue"),
          key: "minRangeAllert",
          thStyle: "width: 2rem",
          variant: "danger",
        },
        {
          label: this.$i18n.t("patient.rangeConfig.lblMinWarningValue"),
          key: "minRangeWarning",
          thStyle: "width: 2rem",
          variant: "warning",
        },
        {
          label: "",
          key: "save",
          thStyle: "width: 2rem;",
          tdClass: "text-center",
        },
        //{ class: "sa-table-column-action-single", thStyle: "width: 10rem" ,key: "actions", label: "" },
      ],
      items: [],
      opzioniAlcool: [
        { text: this.getLabelTraduora("patient.rangeConfiguration.lblAbstinent"), value: 0 },
        { text: this.getLabelTraduora("patient.rangeConfiguration.lblUpTo2Unit"), value: 3 },
        { text: this.getLabelTraduora("patient.rangeConfiguration.lblUpTo4Unit"), value: 10 },
        { text: this.getLabelTraduora("patient.rangeConfiguration.lblmore8glasses"), value: 12 },
      ],
      opzioniCaffe: [
        { text: this.getLabelTraduora("patient.rangeConfiguration.lblNoCoffe"), value: 0 },
        { text: this.getLabelTraduora("patient.rangeConfiguration.lbl1or3cup"), value: 3 },
        { text: this.getLabelTraduora("patient.rangeConfiguration.lbl4or8cup"), value: 10 },
        { text: this.getLabelTraduora("patient.rangeConfiguration.lblmore8cup"), value: 12 },
      ],
      opzioniSigarette: [
        { text: this.getLabelTraduora("patient.rangeConfiguration.lblNotSmoker"), value: 0 },
        { text: this.getLabelTraduora("patient.rangeConfiguration.lblUpTo3Cigarettes"), value: 3 },
        { text: this.getLabelTraduora("patient.rangeConfiguration.lblUpTo10Cigarettes"), value: 10 },
        { text: this.getLabelTraduora("patient.rangeConfiguration.lblmore10Cigarettes"), value: 12 },
      ],
      opzioniSessioniAttivita: [
        { text: this.getLabelTraduora("patient.rangeConfiguration.lblNoActivity"), value: 0 },
        { text: this.getLabelTraduora("patient.rangeConfiguration.lbl2to4sessions"), value: 3 },
        { text: this.getLabelTraduora("patient.rangeConfiguration.lbl4to7sessions"), value: 8 },
        { text: this.getLabelTraduora("patient.rangeConfiguration.lblmore7sessions"), value: 10 },
      ],
      opzioniDurataSessioni: [
        { text: this.getLabelTraduora("patient.rangeConfiguration.lblNoActivity"), value: 0 },
        { text: this.getLabelTraduora("patient.rangeConfiguration.lbl5to30minutes"), value: 30 },
        { text: this.getLabelTraduora("patient.rangeConfiguration.lbl31to60minutes"), value: 60 },
        { text: this.getLabelTraduora("patient.rangeConfiguration.lblmore60minutes"), value: 120 },
      ],
      opzioniDieta: [
        { text: this.getLabelTraduora("patient.rangeConfiguration.lblAteNot"), value: 0 },
        { text: this.getLabelTraduora("patient.rangeConfiguration.lblAteLittle"), value: 1 },
        { text: this.getLabelTraduora("patient.rangeConfiguration.lblAteNormal"), value: 2 },
        { text: this.getLabelTraduora("patient.rangeConfiguration.lblAteLot"), value: 3 },
        { text: this.getLabelTraduora("patient.rangeConfiguration.lblAteTooMuch"), value: 4 },
      ],
      opzioniSale: [
        { text: this.getLabelTraduora("patient.lblNo"), value: 0 },
        { text: this.getLabelTraduora("patient.lblYes"), value: 1 },
      ],
      opzioniAcqua: [
        { text: this.getLabelTraduora("patient.rangeConfiguration.lbl1or3glasses"), value: 3 },
        { text: this.getLabelTraduora("patient.rangeConfiguration.lbl4or8glasses"), value: 10 },
        { text: this.getLabelTraduora("patient.rangeConfiguration.lbl8or12glasses"), value: 16 },
        // { text: this.getLabelTraduora("patient.rangeConfiguration.lblmore12glasses"), value: 18 },
      ],
      data: {
        id: "-1",
        misureNome: null,
        idPaziente: null,
        minRangeAllert: 0,
        maxRangeAllert: 0,
        minRangeWarning: 0,
        maxRangeWarning: 0,
        createUser: null,
        createDate: null,
        updateUser: null,
        updateDate: null,
        proprietario: null,
      },
      alcool: 0,
      sigarette: 0,
      caffe: 0,
      attivitaSessioni: 0,
      durataSessioni: 0,
      dietaSale: 0,
      dietaAcqua: 3,
      dietaColazione: 0,
      dietaSpuntinoMattutino: 0,
      dietaPranzo: 0,
      dietaSpuntinoPomeridiano: 0,
      dietaCena: 0,
      dietaSpuntinoSerale: 0,
      itemColazione: { misureNome: "lbl.measure.breakfast", id: "-1" },
      itemSpuntinoMattutino: { misureNome: "lbl.measure.midMorningSnack", id: "-1" },
      itemSpuntinoPomeridiano: { misureNome: "lbl.measure.afternoonSnack", id: "-1" },
      itemSpuntinoSerale: { misureNome: "lbl.measure.eveningSnack", id: "-1" },
      itemPranzo: { misureNome: "lbl.measure.lunch", id: "-1" },
      itemCena: { misureNome: "lbl.measure.dinner", id: "-1" },
      itemSale: { misureNome: "lbl.measure.salt", id: "-1" },
      itemAcqua: { misureNome: "lbl.measure.water", id: "-1" },
      itemSigarette: { misureNome: "lbl.measure.cigarette", id: "-1" },
      itemAlcool: { misureNome: "lbl.measure.alcool", id: "-1" },
      itemCaffe: { misureNome: "lbl.measure.coffe", id: "-1" },
      itemNumeroSessioni: { misureNome: "lbl.measure.numberOfActivity", id: "-1" },
      itemDurataSessioni: { misureNome: "lbl.measure.minuteOfActivity", id: "-1" },
    };
  },
  created: function() {},
  mounted: function() {
    let me = this;
    me.id = this.$route.params.id;
    me.data.idPaziente = this.$route.query.idPaziente;
    me.loadData();
  },
  methods: {
    formatToHours(value) {
      return new Date(value * 1000).toISOString().substr(11, 8);
    },
    convertHours(time) {
      const arr = time.split(":");
      const seconds = arr[0] * 3600 + arr[1] * 60 + +arr[2];
      return seconds;
    },
    stampa(item) {
      console.log(item.id);
    },
    showModal(label) {
      let me = this;
      switch (label) {
        case "water":
          me.titoloModal = me.getLabelTraduora("patient.diet.lblWaterGlasses");
          me.descrizioneModal = me.getLabelTraduora("patient.diet.lblWaterGlassesDescription");
          break;
        case "coffee":
          me.titoloModal = me.getLabelTraduora("patient.fisiologica.lblCoffee");
          me.descrizioneModal = me.getLabelTraduora("patient.diet.lblCoffeeDescription");
          break;
        case "alcohol":
          me.titoloModal = me.getLabelTraduora("patient.lblAlcohol");
          me.descrizioneModal = me.getLabelTraduora("patient.diet.lblAlcoholDescription");
          break;
      }
      this.$bvModal.show("collapse-info");
    },
    async salva(item) {
      let me = this;
      me.showModalLoading = true;

      if (item.createDate === null) {
        item.createDate = Number(new Date());
      }
      item.updateDate = Number(new Date());
      item.minRangeAllert = Number(item.minRangeAllert);
      item.maxRangeAllert = Number(item.maxRangeAllert);
      item.minRangeWarning = Number(item.minRangeWarning);
      item.maxRangeWarning = Number(item.maxRangeWarning);
      // console.log(item);
      if (item.id === "-1") {
        let link = process.env.VUE_APP_PATH_API + me.pathResource;
        await axios
          .post(link, item)
          .then((res) => {
            me.showModalLoading = false;
            console.log(res.data.messaggio);
          })
          .catch((err) => {
            me.showModalLoading = false;
            console.log(err.toString());
          });
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + item.id;
        await axios
          .put(link, item)
          .then((res) => {
            me.showModalLoading = false;
            console.log(res.data.messaggio);
          })
          .catch((err) => {
            me.showModalLoading = false;
            console.log(err.toString());
          });
      }
      //window.location.reload();
    },
    salvaAbitudiniDietaAttivita(item, value) {
      let me = this;
      // console.log(item);
      if (item.id === "-1") {
        item.idPaziente = me.idPaziente;
        // item.ordering = 1;
        // console.log(me.dietaCena);
      }
      me.checkItem(item, value);
      me.salva(item);
    },
    checkItem(item, value) {
      // let me = this;
      let misura = item.misureNome;
      // console.log(misura);
      // let minWarning = 0;
      // let maxWarning = 0;
      // let maxAllert = 0;
      // let minAllert = 0;

      switch (misura) {
        case "lbl.measure.breakfast":
          // value - 2 < -1 ? (minAllert = -1) : (minAllert = value - 2);
          // value - 1 < -1 ? (minWarning = -1) : (minWarning = value - 1);
          // value + 1 > 5 ? (maxAllert = 5) : (maxAllert = value + 1);
          // value + 0 > 5 ? (maxWarning = 5) : (maxWarning = value + 0);

          // console.log("VALUE: " + value);
          item.minRangeWarning = value;
          item.minRangeAllert = value - 1;
          item.maxRangeAllert = value + 1;
          item.maxRangeWarning = value;
          // item.minRangeWarning = minWarning;
          // item.minRangeAllert = minAllert;
          // item.maxRangeAllert = maxAllert;
          // item.maxRangeWarning = maxWarning;
          break;
        case "lbl.measure.dinner":
          // value - 2 < -1 ? (minAllert = -1) : (minAllert = value - 2);
          // value - 1 < -1 ? (minWarning = -1) : (minWarning = value - 1);
          // value + 1 > 5 ? (maxAllert = 5) : (maxAllert = value + 1);
          // value + 0 > 5 ? (maxWarning = 5) : (maxWarning = value + 0);

          // console.log("VALUE: " + value);
          item.minRangeWarning = value;
          item.minRangeAllert = value - 1;
          item.maxRangeAllert = value + 1;
          item.maxRangeWarning = value;
          // item.minRangeWarning = minWarning;
          // item.minRangeAllert = minAllert;
          // item.maxRangeAllert = maxAllert;
          // item.maxRangeWarning = maxWarning;
          break;
        case "lbl.measure.lunch":
          // value - 2 < -1 ? (minAllert = -1) : (minAllert = value - 2);
          // value - 1 < -1 ? (minWarning = -1) : (minWarning = value - 1);
          // value + 1 > 5 ? (maxAllert = 5) : (maxAllert = value + 1);
          // value + 0 > 5 ? (maxWarning = 5) : (maxWarning = value + 0);

          // console.log("VALUE: " + value);
          item.minRangeWarning = value;
          item.minRangeAllert = value - 1;
          item.maxRangeAllert = value + 1;
          item.maxRangeWarning = value;
          // item.minRangeWarning = minWarning;
          // item.minRangeAllert = minAllert;
          // item.maxRangeAllert = maxAllert;
          // item.maxRangeWarning = maxWarning;
          break;
        case "lbl.measure.midMorningSnack":
          // value - 2 < -1 ? (minAllert = -1) : (minAllert = value - 2);
          // value - 1 < -1 ? (minWarning = -1) : (minWarning = value - 1);
          // value + 1 > 5 ? (maxAllert = 5) : (maxAllert = value + 1);
          // value + 0 > 5 ? (maxWarning = 5) : (maxWarning = value + 0);

          // console.log("VALUE: " + value);
          item.minRangeWarning = value;
          item.minRangeAllert = value - 1;
          item.maxRangeAllert = value + 1;
          item.maxRangeWarning = value;
          // item.minRangeWarning = minWarning;
          // item.minRangeAllert = minAllert;
          // item.maxRangeAllert = maxAllert;
          // item.maxRangeWarning = maxWarning;
          break;
        case "lbl.measure.eveningSnack":
          // value - 2 < -1 ? (minAllert = -1) : (minAllert = value - 2);
          // value - 1 < -1 ? (minWarning = -1) : (minWarning = value - 1);
          // value + 1 > 5 ? (maxAllert = 5) : (maxAllert = value + 1);
          // value + 0 > 5 ? (maxWarning = 5) : (maxWarning = value + 0);
          // console.log("VALUE: " + value);
          item.minRangeWarning = value;
          item.minRangeAllert = value - 1;
          item.maxRangeAllert = value + 1;
          item.maxRangeWarning = value;
          // item.minRangeWarning = minWarning;
          // item.minRangeAllert = minAllert;
          // item.maxRangeAllert = maxAllert;
          // item.maxRangeWarning = maxWarning;
          break;
        case "lbl.measure.afternoonSnack":
          // value - 2 < -1 ? (minAllert = -1) : (minAllert = value - 2);
          // value - 1 < -1 ? (minWarning = -1) : (minWarning = value - 1);
          // value + 1 > 5 ? (maxAllert = 5) : (maxAllert = value + 1);
          // value + 0 > 5 ? (maxWarning = 5) : (maxWarning = value + 0);
          item.minRangeWarning = value;
          item.minRangeAllert = value - 1;
          item.maxRangeAllert = value + 1;
          item.maxRangeWarning = value;
          // item.minRangeWarning = minWarning;
          // item.minRangeAllert = minAllert;
          // item.maxRangeAllert = maxAllert;
          // item.maxRangeWarning = maxWarning;
          // console.log(item);
          // console.log(value);
          break;
        case "lbl.measure.salt":
          // console.log(me.dietaSale);
          // value - 1 < -1 ? (minAllert = -1) : (minAllert = value - 1);
          // value - 1 < -1 ? (minWarning = -1) : (minWarning = value - 1);
          // value > 2 ? (maxAllert = 2) : (maxAllert = value);
          // value > 2 ? (maxWarning = 2) : (maxWarning = value);
          // console.log("VALUE: " + value);
          if (value == 0) {
            item.minRangeAllert = 0;
            item.minRangeWarning = 0;
            item.maxRangeWarning = 0;
            item.maxRangeAllert = 0;
          }
          if (value == 1) {
            item.minRangeAllert = 1;
            item.minRangeWarning = 1;
            item.maxRangeWarning = 1;
            item.maxRangeAllert = 1;
          }
          // item.minRangeWarning = minWarning;
          // item.minRangeAllert = minAllert;
          // item.maxRangeAllert = maxAllert;
          // item.maxRangeWarning = maxWarning;
          break;
        case "lbl.measure.water":
          // value - 6 < 0 ? (minAllert = 0) : (minAllert = value - 6);
          // value - 4 < 0 ? (minWarning = 0) : (minWarning = value - 4);
          // value + 4 > 20 ? (maxAllert = 20) : (maxAllert = value + 4);
          // value + 1 > 20 ? (maxWarning = 20) : (maxWarning = value + 1);
          // console.log("VALUE: " + value);
          if (value == 3) {
            item.minRangeAllert = 1;
            item.minRangeWarning = 2;
            item.maxRangeWarning = 4;
            item.maxRangeAllert = 5;
          }
          if (value == 10) {
            item.minRangeAllert = 2;
            item.minRangeWarning = 5;
            item.maxRangeWarning = 8;
            item.maxRangeAllert = 9;
          }
          if (value == 16) {
            item.minRangeAllert = 5;
            item.minRangeWarning = 9;
            item.maxRangeWarning = 12;
            item.maxRangeAllert = 13;
          }
          if (value == 18) {
            item.minRangeAllert = 10;
            item.minRangeWarning = 11;
            item.maxRangeWarning = 999;
            item.maxRangeAllert = 999;
          }
          // item.minRangeWarning = minWarning;
          // item.minRangeAllert = minAllert;
          // item.maxRangeAllert = maxAllert;
          // item.maxRangeWarning = maxWarning;
          break;
        case "lbl.measure.cigarette":
          // value - 5 < -1 ? (minAllert = -1) : (minAllert = value - 5);
          // value - 2 < -1 ? (minWarning = -1) : (minWarning = value - 2);
          // value + 4 > 14 ? (maxAllert = 14) : (maxAllert = value + 4);
          // value + 1 > 14 ? (maxWarning = 14) : (maxWarning = value + 4);
          // console.log("VALUE: " + value);
          if (value == 0) {
            item.minRangeAllert = -1;
            item.minRangeWarning = -1;
            item.maxRangeWarning = 0;
            item.maxRangeAllert = 1;
          }
          if (value == 3) {
            item.minRangeAllert = -1;
            item.minRangeWarning = -1;
            item.maxRangeWarning = 3;
            item.maxRangeAllert = 4;
          }
          if (value == 10) {
            item.minRangeAllert = -1;
            item.minRangeWarning = -1;
            item.maxRangeWarning = 10;
            item.maxRangeAllert = 11;
          }
          if (value == 12) {
            item.minRangeAllert = -1;
            item.minRangeWarning = -1;
            item.maxRangeWarning = 999;
            item.maxRangeAllert = 999;
          }
          // item.minRangeWarning = minWarning;
          // item.minRangeAllert = minAllert;
          // item.maxRangeAllert = maxAllert;
          // item.maxRangeWarning = maxWarning;
          break;
        case "lbl.measure.alcool":
          // value - 5 < -1 ? (minAllert = -1) : (minAllert = value - 5);
          // value - 2 < -1 ? (minWarning = -1) : (minWarning = value - 2);
          // value + 4 > 14 ? (maxAllert = 14) : (maxAllert = value + 4);
          // value + 1 > 14 ? (maxWarning = 14) : (maxWarning = value + 1);
          // console.log("VALUE: " + value);
          if (value == 0) {
            item.minRangeAllert = -1;
            item.minRangeWarning = -1;
            item.maxRangeWarning = 0;
            item.maxRangeAllert = 1;
          }
          if (value == 3) {
            item.minRangeAllert = -1;
            item.minRangeWarning = -1;
            item.maxRangeWarning = 2;
            item.maxRangeAllert = 3;
          }
          if (value == 10) {
            item.minRangeAllert = -1;
            item.minRangeWarning = -1;
            item.maxRangeWarning = 4;
            item.maxRangeAllert = 5;
          }
          if (value == 12) {
            item.minRangeAllert = -1;
            item.minRangeWarning = -1;
            item.maxRangeWarning = 999;
            item.maxRangeAllert = 999;
          }
          // item.minRangeWarning = minWarning;
          // item.minRangeAllert = minAllert;
          // item.maxRangeAllert = maxAllert;
          // item.maxRangeWarning = maxWarning;
          break;
        case "lbl.measure.coffe":
          // value - 5 < -1 ? (minAllert = -1) : (minAllert = value - 5);
          // value - 3 < -1 ? (minWarning = -1) : (minWarning = value - 3);
          // value + 4 > 20 ? (maxAllert = 20) : (maxAllert = value + 4);
          // value + 1 > 20 ? (maxWarning = 20) : (maxWarning = value + 1);

          // console.log("VALUE: " + value);
          if (value == 0) {
            item.minRangeAllert = -1;
            item.minRangeWarning = -1;
            item.maxRangeWarning = 0;
            item.maxRangeAllert = 1;
          }
          if (value == 3) {
            item.minRangeAllert = -1;
            item.minRangeWarning = -1;
            item.maxRangeWarning = 2;
            item.maxRangeAllert = 3;
          }
          if (value == 10) {
            item.minRangeAllert = -1;
            item.minRangeWarning = -1;
            item.maxRangeWarning = 4;
            item.maxRangeAllert = 5;
          }
          if (value == 12) {
            item.minRangeAllert = -1;
            item.minRangeWarning = -1;
            item.maxRangeWarning = 999;
            item.maxRangeAllert = 999;
          }
          // item.minRangeWarning = minWarning;
          // item.minRangeAllert = minAllert;
          // item.maxRangeAllert = maxAllert;
          // item.maxRangeWarning = maxWarning;
          break;
        case "lbl.measure.numberOfActivity":
          // value - 5 < -1 ? (minAllert = -1) : (minAllert = value - 5);
          // value - 3 < -1 ? (minWarning = -1) : (minWarning = value - 3);
          // value + 4 > 16 ? (maxAllert = 10) : (maxAllert = value + 4);
          // value + 1 > 16 ? (maxWarning = 10) : (maxWarning = value + 1);

          // console.log("VALUE: " + value);
          if (value == 0) {
            item.minRangeAllert = -1;
            item.minRangeWarning = -1;
            item.maxRangeWarning = 999;
            item.maxRangeAllert = 999;
          }
          if (value == 3) {
            item.minRangeAllert = 1;
            item.minRangeWarning = 2;
            item.maxRangeWarning = 999;
            item.maxRangeAllert = 999;
          }
          if (value == 8) {
            item.minRangeAllert = 2;
            item.minRangeWarning = 4;
            item.maxRangeWarning = 999;
            item.maxRangeAllert = 999;
          }
          if (value == 10) {
            item.minRangeAllert = 4;
            item.minRangeWarning = 7;
            item.maxRangeWarning = 999;
            item.maxRangeAllert = 999;
          }
          // item.minRangeWarning = minWarning;
          // item.minRangeAllert = minAllert;
          // item.maxRangeAllert = maxAllert;
          // item.maxRangeWarning = maxWarning;
          break;
        case "lbl.measure.minuteOfActivity":
          // value - 30 < -1 ? (minAllert = -1) : (minAllert = value - 30);
          // value - 15 < -1 ? (minWarning = -1) : (minWarning = value - 15);
          // value + 30 > 150 ? (maxAllert = 150) : (maxAllert = value + 30);
          // value + 15 > 150 ? (maxWarning = 150) : (maxWarning = value + 15);

          // console.log("VALUE: " + value);
          if (value == 0) {
            item.minRangeAllert = -1;
            item.minRangeWarning = -1;
            item.maxRangeWarning = 999;
            item.maxRangeAllert = 999;
          }
          if (value == 30) {
            item.minRangeAllert = 6;
            item.minRangeWarning = 15;
            item.maxRangeWarning = 999;
            item.maxRangeAllert = 999;
          }
          if (value == 60) {
            item.minRangeAllert = 16;
            item.minRangeWarning = 30;
            item.maxRangeWarning = 999;
            item.maxRangeAllert = 999;
          }
          if (value == 120) {
            item.minRangeAllert = 31;
            item.minRangeWarning = 60;
            item.maxRangeWarning = 999;
            item.maxRangeAllert = 999;
          }
          // item.minRangeWarning = minWarning;
          // item.minRangeAllert = minAllert;
          // item.maxRangeAllert = maxAllert;
          // item.maxRangeWarning = maxWarning;
          break;
      }
      // console.log("POST MODIFICA");
      // console.log(item);
    },
    cancella(id) {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + id;
      axios
        .delete(link)
        .then(() => {
          me.showModalLoading = false;
          // console.log(response);
          // console.log("Range misurazione " + id + " eliminato correttamente");
        })
        .catch((err) => {
          me.showModalLoading = false;
          console.log(err.toString());
        });
    },
    forceRerender() {
      this.componentKey += 1;
    },
    getLabelMisure(value) {
      let me = this;
      let returnValue = me.getLabelTraduora("patient.card.lblUnknow");
      switch (value) {
        case "lbl.measure.diastolicBloodPressure":
          returnValue = me.getLabelTraduora("patient.card.lblDiastolicBloodPressure");
          break;
        case "lbl.measure.wakeupCount":
          returnValue = me.getLabelTraduora("patient.lifeStyle.lblOutOfBedTime");
          break;
        case "lbl.measure.step":
          returnValue = me.getLabelTraduora("patient.card.lblSteps");
          break;
        case "lbl.measure.systolicBloodPressure":
          returnValue = me.getLabelTraduora("patient.card.lblSystolicBloodPressure");
          break;
        case "lbl.measure.totalSleepTime":
          returnValue = me.getLabelTraduora("patient.lifeStyle.lbltotalSleepTime");
          break;
        case "lbl.measure.weight":
          returnValue = me.getLabelTraduora("patient.card.lblWeight");
          break;
      }
      return returnValue;
    },
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "?idPaziente=" + me.data.idPaziente + "&forPage=100&page=1";
      me.showModalLoading = true;
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.items = [];
          me.items = response.data.data.list;
          // console.log(response.data.data);
          me.showModalLoading = false;
          me.items.forEach((element) => {
            if (element.misureNome === "lbl.measure.breakfast") {
              me.itemColazione = element;
              // console.log(me.itemColazione);
              me.opzioniDieta.forEach((obj) => {
                if (obj.value == me.itemColazione.minRangeWarning && obj.value == me.itemColazione.minRangeWarning) {
                  me.dietaColazione = obj.value;
                }
              });
            }
            if (element.misureNome === "lbl.measure.midMorningSnack") {
              me.itemSpuntinoMattutino = element;
              // console.log(me.itemSpuntinoMattutino);
              me.opzioniDieta.forEach((obj) => {
                if (obj.value == me.itemSpuntinoMattutino.minRangeWarning && obj.value == me.itemSpuntinoMattutino.minRangeWarning) {
                  me.dietaSpuntinoMattutino = obj.value;
                }
              });
            }
            if (element.misureNome === "lbl.measure.eveningSnack") {
              me.itemSpuntinoSerale = element;
              // console.log(me.itemSpuntinoSerale);
              me.opzioniDieta.forEach((obj) => {
                if (obj.value == me.itemSpuntinoSerale.minRangeWarning && obj.value == me.itemSpuntinoSerale.minRangeWarning) {
                  me.dietaSpuntinoSerale = obj.value;
                }
              });
            }
            if (element.misureNome === "lbl.measure.afternoonSnack") {
              me.itemSpuntinoPomeridiano = element;
              // console.log(me.itemSpuntinoPomeridiano);
              me.opzioniDieta.forEach((obj) => {
                if (obj.value == me.itemSpuntinoPomeridiano.minRangeWarning && obj.value == me.itemSpuntinoPomeridiano.minRangeWarning) {
                  me.dietaSpuntinoPomeridiano = obj.value;
                }
              });
            }
            if (element.misureNome === "lbl.measure.lunch") {
              me.itemPranzo = element;
              // console.log(me.itemPranzo);
              me.opzioniDieta.forEach((obj) => {
                if (obj.value == me.itemPranzo.maxRangeWarning && obj.value == me.itemPranzo.minRangeWarning) {
                  me.dietaPranzo = obj.value;
                }
              });
            }
            if (element.misureNome === "lbl.measure.dinner") {
              me.itemCena = element;
              // console.log(me.itemCena);
              me.opzioniDieta.forEach((obj) => {
                if (obj.value == me.itemCena.maxRangeWarning && obj.value == me.itemCena.minRangeWarning) {
                  me.dietaCena = obj.value;
                }
              });
            }
            if (element.misureNome === "lbl.measure.salt") {
              me.itemSale = element;
              // console.log(me.itemSale);
              // me.opzioniSale.forEach((obj) => {
              //   if (obj.value < me.itemSale.maxRangeWarning && obj.value > me.itemSale.minRangeWarning) {
              //     me.dietaSale = obj.value;
              //   }
              // });
              if (me.itemSale.maxRangeWarning == 0) {
                me.dietaSale = 0;
              }
              if (me.itemSale.maxRangeWarning == 1) {
                me.dietaSale = 1;
              }
            }
            if (element.misureNome === "lbl.measure.water") {
              me.itemAcqua = element;
              // console.log(me.itemAcqua);
              // me.opzioniAcqua.forEach((obj) => {
              //   if (obj.value < me.itemAcqua.maxRangeWarning && obj.value > me.itemAcqua.minRangeWarning) {
              //     me.dietaAcqua = obj.value;
              //   }
              // });
              if (me.itemAcqua.maxRangeWarning == 4) {
                me.dietaAcqua = 3;
              }
              if (me.itemAcqua.maxRangeWarning == 9) {
                me.dietaAcqua = 10;
              }
              if (me.itemAcqua.maxRangeWarning == 12) {
                me.dietaAcqua = 16;
              }
              if (me.itemAcqua.maxRangeWarning == 999) {
                me.dietaAcqua = 18;
              }
            }
            if (element.misureNome === "lbl.measure.cigarette") {
              me.itemSigarette = element;
              // console.log(me.itemSigarette);
              // me.opzioniSigarette.forEach((obj) => {
              //   if (obj.value < me.itemSigarette.maxRangeWarning && obj.value > me.itemSigarette.minRangeWarning) {
              //     me.sigarette = obj.value;
              //   }
              // });
              if (me.itemSigarette.maxRangeWarning == 0) {
                me.sigarette = 0;
              }
              if (me.itemSigarette.maxRangeWarning == 3) {
                me.sigarette = 3;
              }
              if (me.itemSigarette.maxRangeWarning == 10) {
                me.sigarette = 10;
              }
              if (me.itemSigarette.maxRangeWarning == 999) {
                me.sigarette = 12;
              }
            }
            if (element.misureNome === "lbl.measure.alcool") {
              me.itemAlcool = element;
              // console.log(me.itemAlcool);
              // me.opzioniAlcool.forEach((obj) => {
              //   if (obj.value < me.itemAlcool.maxRangeWarning && obj.value > me.itemAlcool.minRangeWarning) {
              //     me.alcool = obj.value;
              //   }
              // });
              if (me.itemAlcool.maxRangeWarning == 0) {
                me.alcool = 0;
              }
              if (me.itemAlcool.maxRangeWarning == 2) {
                me.alcool = 3;
              }
              if (me.itemAlcool.maxRangeWarning == 4) {
                me.alcool = 10;
              }
              if (me.itemAlcool.maxRangeWarning == 999) {
                me.alcool = 12;
              }
            }
            if (element.misureNome === "lbl.measure.coffe") {
              me.itemCaffe = element;
              // console.log(me.itemCaffe);
              // me.opzioniCaffe.forEach((obj) => {
              //   if (obj.value < me.itemCaffe.maxRangeWarning && obj.value > me.itemCaffe.minRangeWarning) {
              //     me.caffe = obj.value;
              //   }
              // });
              if (me.itemCaffe.maxRangeWarning == 0) {
                me.caffe = 0;
              }
              if (me.itemCaffe.maxRangeWarning == 2) {
                me.caffe = 3;
              }
              if (me.itemCaffe.maxRangeWarning == 4) {
                me.caffe = 10;
              }
              if (me.itemCaffe.maxRangeWarning == 999) {
                me.caffe = 12;
              }
            }
            if (element.misureNome === "lbl.measure.numberOfActivity") {
              me.itemNumeroSessioni = element;
              // console.log(me.itemNumeroSessioni);
              // me.opzioniSessioniAttivita.forEach((obj) => {
              //   if (obj.value < me.itemNumeroSessioni.maxRangeWarning && obj.value > me.itemNumeroSessioni.minRangeWarning) {
              //     me.attivitaSessioni = obj.value;
              //   }
              // });
              if (me.itemNumeroSessioni.minRangeWarning == -1) {
                me.attivitaSessioni = 0;
              }
              if (me.itemNumeroSessioni.minRangeWarning == 2) {
                me.attivitaSessioni = 3;
              }
              if (me.itemNumeroSessioni.minRangeWarning == 4) {
                me.attivitaSessioni = 8;
              }
              if (me.itemNumeroSessioni.minRangeWarning == 7) {
                me.attivitaSessioni = 10;
              }
            }
            if (element.misureNome === "lbl.measure.minuteOfActivity") {
              me.itemDurataSessioni = element;
              // console.log(me.itemDurataSessioni);
              // me.opzioniDurataSessioni.forEach((obj) => {
              //   if (obj.value < me.itemDurataSessioni.maxRangeWarning && obj.value > me.itemDurataSessioni.minRangeWarning) {
              //     me.durataSessioni = obj.value;
              //   }
              // });
              if (me.itemDurataSessioni.minRangeWarning == -1) {
                me.durataSessioni = 0;
              }
              if (me.itemDurataSessioni.minRangeWarning == 15) {
                me.durataSessioni = 30;
              }
              if (me.itemDurataSessioni.minRangeWarning == 30) {
                me.durataSessioni = 60;
              }
              if (me.itemDurataSessioni.minRangeWarning == 60) {
                me.durataSessioni = 120;
              }
            }
          });
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onReset() {
      let me = this;
      me.filtro = {};
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro.codiceSacca);
      me.loadData();
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    getScala(item) {
      let returnValue = 0;
      switch (item.misureNome) {
        case "lbl.measure.diastolicBloodPressure":
          returnValue = 1400;
          break;
        case "lbl.measure.temperature":
          returnValue = 180;
          break;
        case "lbl.measure.systolicBloodPressure":
          returnValue = 2200;
          break;
        case "lbl.measure.sleepScore":
          returnValue = 2000;
          break;
        case "lbl.measure.totalSleepTime":
          returnValue = 500000;
          break;
        case "lbl.measure.heartPulse":
          returnValue = 2500;
          break;
        case "lbl.measure.step":
          returnValue = 150000;
          break;
        case "lbl.measure.weight":
          returnValue = 1000;
          break;
        case "lbl.measure.wakeupCount":
          returnValue = 100;
          break;

        default:
          returnValue = 0;
      }
      return returnValue;
    },
    getCenter(item) {
      let returnValue = 0;
      switch (item.misureNome) {
        case "lbl.measure.diastolicBloodPressure":
          returnValue = 700;
          break;
        case "lbl.measure.temperature":
          returnValue = 360;
          break;
        case "lbl.measure.systolicBloodPressure":
          returnValue = 1100;
          break;
        case "lbl.measure.sleepScore":
          returnValue = 1000;
          break;
        case "lbl.measure.totalSleepTime":
          returnValue = 270000;
          break;
        case "lbl.measure.heartPulse":
          returnValue = 700;
          break;
        case "lbl.measure.step":
          returnValue = 65000;
          break;
        case "lbl.measure.weight":
          returnValue = 500;
          break;
        case "lbl.measure.wakeupCount":
          returnValue = 50;
          break;

        default:
          returnValue = 0;
      }
      return returnValue;
    },
    getPrecision(item) {
      let returnValue = 0;
      switch (item.misureNome) {
        case "lbl.measure.temperature":
          returnValue = 1;
          break;
        default:
          returnValue = 0;
      }
      return returnValue;
    },
    verificaRange(item) {
      let returnValue = false;
      switch (item.misureNome) {
        case "lbl.measure.diastolicBloodPressure":
          returnValue = true;
          break;
        case "lbl.measure.temperature":
          returnValue = false;
          break;
        case "lbl.measure.systolicBloodPressure":
          returnValue = true;
          break;
        case "lbl.measure.sleepScore":
          returnValue = true;
          break;
        case "lbl.measure.totalSleepTime":
          returnValue = true;
          break;
        case "lbl.measure.heartPulse":
          returnValue = true;
          break;
        case "lbl.measure.step":
          returnValue = true;
          break;
        case "lbl.measure.weight":
          returnValue = true;
          break;
        case "lbl.measure.wakeupCount":
          returnValue = true;
          break;

        default:
          returnValue = false;
      }
      return returnValue;
    },
    onUpdateRange(value, index) {
      this.items.forEach((element) => {
        if (element.id === index) {
          element.maxRangeAllert = parseFloat(value[0]);
          element.maxRangeWarning = parseFloat(value[1]);
          //   element.minRangeWarning = parseFloat(value[0]);
          //   element.minRangeAllert = parseFloat(value[1]);
        }
      });
    },
    onUpdateRangeRight(value, index) {
      this.items.forEach((element) => {
        if (element.id === index) {
          element.minRangeWarning = parseFloat(value[0]);
          element.minRangeAllert = parseFloat(value[1]);
        }
      });
    },
  },
};
</script>

<style scoped>
.centered {
  align-self: center;
}
.body {
  text-align: center !important;
}
.little {
  width: 50% !important;
}

.special {
  font-weight: normal !important;
  color: black !important;
}
</style>
