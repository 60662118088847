import { render, staticRenderFns } from "./ImportTamponiCsvList.vue?vue&type=template&id=4b6ac203&scoped=true&"
import script from "./ImportTamponiCsvList.vue?vue&type=script&lang=js&"
export * from "./ImportTamponiCsvList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b6ac203",
  null
  
)

export default component.exports