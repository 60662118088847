<template>
  <b-card class="mb-4">
    <h4 class="header-title mt-0 mb-3"><i class="mdi mdi-notification-clear-all mr-1"></i> {{ this.$i18n.t("patients.lblDiary") }}</h4>
    <b-card class="scroll"
      ><ul class="timeline2" v-for="item in diario" :key="item.id">
        <li class="event" data-date="25 Maggio 2021 | 10:30">
          <span class="mdi mdi-circle pal"></span>
          <span class="sa-timeline-date">{{ formatDateTime(item.dataEvento) }}</span>
          <h3>
            <a href="#" onclick="return false;" @click="onTipoVisitaClick(item)">{{ getTipoVisita(item.tipoEvento) }}</a>
          </h3>
          <span>{{ item.lastnameUpdater }} {{ item.firstnameUpdater }}</span>
        </li>
      </ul></b-card
    >

    <b-modal ref="mdlEvento" size="xl" :title="mdlTitle">
      <div style="padding: 15px;">
        <component ref="itemRefs" :is="currentTabComponent" v-bind="currentProperties"></component>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      mdlTitle: "",
      currentProperties: { idEvento: "gg" },
      currentTabComponent: null,
      diario: [],
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadDiario();
  },
  methods: {
    getTipoVisita(value) {
      // console.log(value);
      let key = "patients.events.";

      let returnValue = "unknown";
      let check = value == null ? "" : value.toUpperCase();

      if (check.includes("ANAMNESI")) {
        returnValue = this.$i18n.t(key + "lblAnamnesis");
      }
      if (check.includes("FAMILIARE") && check.includes("ANAMNESI")) {
        returnValue = this.$i18n.t(key + "lblFamilyAnamnesis");
      }
      if (check.includes("FOLLOWUP")) {
        returnValue = this.$i18n.t(key + "lblFollowUp");
      }
      if (check.includes("VISITA")) {
        returnValue = this.$i18n.t(key + "lblVisit");
      }
      if (check.includes("DIABETOLOGIA") && check.includes("ANAMNESI")) {
        returnValue = this.$i18n.t(key + "lblDiabetologyAnamnesis");
      }
      if (check.includes("DIABETOLOGIA") && check.includes("FOLLOW UP")) {
        returnValue = this.$i18n.t(key + "lblDiabetologyFollowUp");
      }
      if (check.includes("DIABETOLOGIA") && check.includes("PRIMAVISITA")) {
        returnValue = this.$i18n.t(key + "lblDiabetologyFirstVisit");
      }
      if (check.includes("DIABETOLOGIA") && check.includes("ANAMNESI") && check.includes("FISIOLOGICA")) {
        returnValue = this.$i18n.t(key + "lblDiabetologyPhysologicalAnamnesis");
      }
      if (check.includes("DIABETOLOGIA") && check.includes("ANAMNESI") && check.includes("PATOLOGICA")) {
        returnValue = this.$i18n.t(key + "lblDiabetologyPathologicalAnamnesis");
      }
      if (check.includes("DIABETOLOGIA") && check.includes("ESAMI") && check.includes("LABORATORIO")) {
        returnValue = this.$i18n.t(key + "lblDiabetologyLaboratoryExams");
      }
      if (check.includes("DIABETOLOGIA") && check.includes("DATI") && check.includes("LABORATORIO")) {
        returnValue = this.$i18n.t(key + "lblDiabetologyLaboratoryData");
      }
      if (check.includes("DIABETOLOGIA") && check.includes("CONTROLLI") && check.includes("SPECIALISTICI")) {
        returnValue = this.$i18n.t(key + "lblDiabetologySpecialistChecksAdmission");
      }
      if (check.includes("CARDIOLOGIA") && check.includes("CONTROLLO") && check.includes("ECG")) {
        returnValue = this.$i18n.t(key + "lblCardiologyEcgVisit");
      }
      if (check.includes("CARDIOLOGIA") && check.includes("ESAME") && check.includes("LABORATORIO")) {
        returnValue = this.$i18n.t(key + "lblCardiologyLaboratoryExam");
      }
      if (check.includes("CARDIOLOGIA") && check.includes("ANAMNESI")) {
        returnValue = this.$i18n.t(key + "lblCardiologyAnamnesis");
      }
      if (check.includes("CARDIOLOGIA") && check.includes("ECOCAROTIDI")) {
        returnValue = this.$i18n.t(key + "lblCardiologyEchocarotids");
      }
      if (check.includes("CARDIOLOGIA") && check.includes("ECOSTRESS")) {
        returnValue = this.$i18n.t(key + "lblCardiologyEchostress");
      }
      if (check.includes("CARDIOLOGIA") && check.includes("ELETTROSTIMOLATORE")) {
        returnValue = this.$i18n.t(key + "lblCardiologyElectrostimulation");
      }
      if (check.includes("CARDIOLOGIA") && check.includes("ECOCARDIO") && check.includes("REFERTAZIONE")) {
        returnValue = this.$i18n.t(key + "lblCardiologyEchocardiumReporting");
      }
      if (check.includes("CARDIOLOGIA") && check.includes("ECODOPLER") && check.includes("INFERIORI")) {
        returnValue = this.$i18n.t(key + "lblCardiologyLowerLimbsUltrasoundDoppler");
      }
      if (check.includes("VISITAHH")) {
        returnValue = this.$i18n.t(key + "lblVisitHH");
      }
      if (check.includes("MEDICINADELLOSPORTAFA")) {
        returnValue = this.$i18n.t(key + "lblVisitAFA");
      }
      if (check.includes("MEDICINADELLOSPORTIDONEITA")) {
        returnValue = this.$i18n.t(key + "lblEligibility");
      }
      return returnValue;
    },
    loadDiario() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/pazienteeventi" + "/";
      axios
        .get(link, { params: { idPaziente: me.id } })
        .then((response) => {
          me.diario = response.data.data;
        })
        .catch((e) => {
          console.log("Error: " + e.response.status + " " + link);
        });
    },
    formatDateTime(value) {
      if (value) {
        return moment(new Date(value)).format("DD-MM-YYYY HH:mm");
      } else {
        return "---";
      }
    },
    onTipoVisitaClick(item) {
      let me = this;
      me.currentTabComponent = null;
      me.currentProperties = { idEvento: item.id };
      switch (item.tipoEvento) {
        case "MEDICINADELLOSPORTAFA":
          me.mdlTitle = me.getTipoVisita(item.tipoEvento);
          me.currentTabComponent = () => import("../eventi/medicinadellosport/afa/components/AfaViewComponent");
          break;
        case "diabetologiaprimavisita":
          me.mdlTitle = me.getTipoVisita(item.tipoEvento);
          me.currentTabComponent = () => import("../eventi/diabetologia/components/PrimaVisitaViewComponent.vue");
          break;
        case "diabetologiaanamnesifamiliare":
          me.mdlTitle = me.getTipoVisita(item.tipoEvento);
          me.currentTabComponent = () => import("../eventi/diabetologia/components/AnamnesiFamiliareViewComponent.vue");
          break;
        case "hyperhealthvisitahh":
          me.mdlTitle = me.getTipoVisita(item.tipoEvento);
          me.currentTabComponent = () => import("../eventi/hyperhealth/components/VisitaHhViewComponent.vue");
          break;
        case "MEDICINADELLOSPORTIDONEITA":
          me.mdlTitle = me.getTipoVisita(item.tipoEvento);
          me.currentTabComponent = () => import("../eventi/medicinadellosport/idoneita/components/IdoneitaView.vue");
          break;
      }

      // me.currentTabComponent = () => import("../eventi/diabetologia/components/PrimaVisitaView.vue");
      me.$refs["mdlEvento"].show();
    },
  },
};
</script>

<style scoped>
.scroll {
  max-height: 625px;
  overflow-y: auto;
}
</style>
