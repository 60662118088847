<template>
  <sa-page-layout :btnRefreshVisible="true" :btnNewVisible="true" :showModalLoading="showModalLoading" @refresh="onRefresh" :linkedit="linkedit">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="toolbar-row">
      <paziente-dashboard-component :idPaziente="idPaziente"></paziente-dashboard-component>
    </template>
    <template slot="table-header"> </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <b-tabs content-class="mt-3" class="sa-tab" style="height: 100%;" fill>
          <b-tab active>
            <template #title>
              {{ getLabelTraduora("patient.diet.lblLastDiet") }}
            </template>
            <div style="padding-top: 10px;">
              <paziente-dieta-view-component :id="id"> </paziente-dieta-view-component>
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              {{ getLabelTraduora("patient.diet.lblHistoricalDiet") }}
            </template>
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
              <b-col lg="3">
                <p>{{ getLabelTraduora("ambulatori.lblTotalRecords") }}: {{ rows }}</p>
              </b-col>
              <b-col lg="9">
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
              </b-col>
            </b-row>
            <div style="padding-top: 10px;">
              <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" :filter="filter" responsive stacked="xl" striped hover :items="items" :fields="fields" sort-icon-left head-variant="light" class="sa-b-table">
                  <template #cell(tipo)="{ item }">
                    <router-link class="sa-edit-link" :to="'/paziente/dieta/view/' + item.id + '?idPaziente=' + idPaziente">{{ item.tipo }}</router-link>
                  </template>
                </b-table>
              </div>
            </div>
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
              <b-col lg="3">
                <p>{{ getLabelTraduora("ambulatori.lblTotalRecords") }}: {{ rows }}</p>
              </b-col>
              <b-col lg="9">
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm" @input="changePage(currentPage)"></b-pagination>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </div>
    </template>
    <template slot="table-footer"> </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import { SaPageLayout } from "../../../template/sa/components/layout/contents";
import PazienteAnagrafica from "./PazienteAnagrafica.vue";
import UtilityMixin from "../../utility/UtilityMixin.js";
import PazienteDashboardComponent from "./PazienteDashboardComponent.vue";
import PazienteDietaViewComponent from "./PazienteDietaViewComponent.vue";
export default {
  mixins: [UtilityMixin],
  props: {
    utente: {
      type: Object,
      default: function() {
        return { gruppo: { menuGruppo: [] } };
      },
    },
  },
  components: { SaPageLayout, PazienteAnagrafica, PazienteDashboardComponent, PazienteDietaViewComponent },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    btnNewVisible: {
      get: function() {
        let me = this;
        return UtilityMixin.methods.verificaPermessi(me.utente, "dieta", 2);
      },
    },
    linkPatient: {
      get: function() {
        let returnValue = "";
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length > 1) {
              return "/paziente/view/" + this.idPaziente;
            } else {
              if (this.utente.gruppo.menuGruppo.length == 1) {
                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                  return "/paziente/view/" + this.idPaziente;
                } else {
                  return "/paziente/homepaziente";
                }
              }
            }
          }
        }
        return returnValue;
      },
    },
  },
  data() {
    return {
      pathResource: "/dieta",
      linkedit: "/paziente/dieta",
      linktipodieta: "",
      perPage: 3,
      currentPage: 1,
      rows: 3,
      note: "",
      filter: null,
      showModalLoading: false,
      id: "-1",
      filtro: {},
      filterOn: [],
      fields: [
        {
          label: this.getLabelTraduora("patient.diet.lblStartDate"),
          key: "dataInizio",
          formatter: (value) => {
            if (!value) {
              return "";
            } else {
              return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            }
          },
          thStyle: "width: 2rem",
          sortable: true,
        },
        {
          label: this.getLabelTraduora("patient.diet.lblDietType"),
          key: "tipo",
          sortable: true,
          thStyle: "width: 2rem",
        },
        {
          label: this.getLabelTraduora("therapy.lblEndDate"),
          key: "dataFine",
          sortable: true,
          thStyle: "width: 2rem",
        },
        {
          label: this.getLabelTraduora("patient.diet.lblNote"),
          key: "note",
          sortable: true,
          thStyle: "width: 6rem",
        },
        //{ class: "sa-table-column-action-single", thStyle: "width: 10rem" ,key: "actions", label: "" },
      ],
      items: [],
      data: {},
    };
  },
  created: function() {},
  mounted: function() {
    let me = this;
    me.id = this.$route.params.id;
    me.data.idPaziente = this.$route.query.idPaziente;
    me.loadData();
  },
  methods: {
    changePage(newPage) {
      this.currentPage = newPage;
      this.loadData();
    },
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "?idPaziente=" + me.data.idPaziente + "&forPage=" + me.perPage + "&page=" + me.currentPage;
      me.showModalLoading = false;
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.items = response.data.data.list;
          me.rows = response.data.data.recordsNumber;
          // console.log(response.data.data);
          if (me.currentPage === 1) {
            me.id = me.items[0].id;
          }

          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onReset() {
      let me = this;
      me.filtro = {};
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro.codiceSacca);
      me.loadData();
    },
    onAdd() {
      let me = this;
      me.$router.replace(me.pagelink + "/edit/-1?idPaziente=" + me.idPaziente).catch((err) => {
        err;
      });
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
  },
};
</script>
<style scoped>
.col-lg-12 {
  height: 35px;
}
</style>
