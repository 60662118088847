<template>
  <sa-page-layout :btnEditVisible="true" :btnBackVisible="true" :linkback="linkback" :linkedit="linkedit" :pathResource="pathResource">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <b-row>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <span class="sa-form-label">{{ getLabelTraduora("patient.EcoReporting.lblHeight") }}(cm)</span>
            <br />
            <span class="sa-text-data">{{ jsonData.altezza }}</span>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <span class="sa-form-label">{{ getLabelTraduora("patient.EcoReporting.lblWeight") }}(Kg)</span>
            <br />
            <span class="sa-text-data"> {{ jsonData.peso }}</span>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4"
            ><span class="sa-form-label">BSA (m2)</span>
            <br />
            <span class="sa-text-data"> {{ jsonData.bsa }}</span>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <span class="sa-form-label">BMI (Kg/m2)</span>
            <br />
            <span class="sa-text-data">{{ jsonData.bmi }}</span>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <span class="sa-form-label">PAS (mmHg)</span>
            <br />
            <span class="sa-text-data">{{ jsonData.pas }}</span>
          </b-col>

          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <span class="sa-form-label">PAD (mmHg)</span>
            <br />
            <span class="sa-text-data"> {{ jsonData.pad }}</span>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <span class="sa-form-label">FC (opm)</span>
            <br />
            <span class="sa-text-data">{{ jsonData.fc }}</span>
          </b-col>

          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <span class="sa-form-label">{{ getLabelTraduora("patient.EcoReporting.lblAcousticImpedance") }}</span>
            <br />
            <span class="sa-text-data">{{ getLabelTraduora(jsonData.impedenzaAcustica) }}</span>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <span class="sa-form-label">Videotape</span>
            <br />
            <span class="sa-text-data">{{ jsonData.videotape }}</span>
          </b-col>
        </b-row>
        <hr />
        <h4>Ventricolo Sinistro</h4>
        <b-row>
          <b-col xs="12" sm="12" md="2" lg="3" xl="3">
            <span class="sa-form-label">Vstd (mm)</span>
            <br />
            <span class="sa-text-data">{{ jsonData.vstd }}</span>
          </b-col>

          <b-col xs="12" sm="12" md="2" lg="3" xl="3">
            <span class="sa-form-label">PPtd (mm)</span>
            <br />
            <span class="sa-text-data">{{ jsonData.pptd }}</span>
          </b-col>
          <b-col xs="12" sm="12" md="2" lg="3" xl="3">
            <span class="sa-form-label">LVMha (g/m2,7) </span>
            <br />
            <span class="sa-text-data">{{ jsonData.lvmha }}</span>
          </b-col>
          <b-col xs="12" sm="12" md="2" lg="3" xl="3">
            <span class="sa-form-label">Vsts (mm)</span>
            <br />
            <span class="sa-text-data">{{ jsonData.vsts }}</span>
          </b-col>

          <b-col xs="12" sm="12" md="2" lg="3" xl="3">
            <span class="sa-form-label">EF(T)[%]</span>
            <br />
            <span class="sa-text-data">{{ jsonData.eft }}</span>
          </b-col>

          <b-col xs="12" sm="12" md="2" lg="3" xl="3">
            <span class="sa-form-label">RWT</span>
            <br />
            <span class="sa-text-data"> {{ jsonData.rwt }}</span>
          </b-col>

          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">SIVtd (mm)</span>
            <br />
            <span class="sa-text-data">{{ jsonData.sivtd }}</span>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">SF(T) [%]</span>
            <br />
            <span class="sa-text-data"> {{ jsonData.sft }}</span>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">ESS (cm/d)</span>
            <br />
            <span class="sa-text-data">{{ jsonData.ess }}</span>
          </b-col>
          <b-col xs="12" sm="12" md="2" lg="3" xl="3">
            <span class="sa-form-label">LVMid (P)[g/m2]</span>
            <br />
            <span class="sa-text-data">{{ jsonData.lvmidp }}</span>
          </b-col>
          <b-col xs="12" sm="12" md="2" lg="3" xl="3">
            <span class="sa-form-label">LVV</span>
            <br />
            <span class="sa-text-data"> {{ jsonData.lvv }}</span>
          </b-col>
        </b-row>

        <hr />
        <h4>Segmenti</h4>

        <b-row>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">Setto Ant. Basale</span>
            <br />
            <span class="sa-text-data">{{ getDescrizioneSegmento(jsonData.settoanteriore) }}</span>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">Setto Ant. Medio</span>
            <br />
            <span class="sa-text-data">{{ getDescrizioneSegmento(jsonData.settoanterioreMedio) }} </span>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">Setto Ant. Apice</span>
            <br />
            <span class="sa-text-data">{{ getDescrizioneSegmento(jsonData.apiceSetto) }}</span>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">Par. Ant. Basale</span>
            <br />
            <span class="sa-text-data">{{ getDescrizioneSegmento(jsonData.pareteanteriore) }}</span>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">Par. Ant. Media</span>
            <br />
            <span class="sa-text-data">{{ getDescrizioneSegmento(jsonData.pareteanterioreMedia) }} </span>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">Par. Ant. Apice</span>
            <br />
            <span class="sa-text-data">{{ getDescrizioneSegmento(jsonData.apiceAnt) }}</span>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">Par. Lat. Ant. Basale</span>
            <br />
            <span class="sa-text-data">{{ getDescrizioneSegmento(jsonData.pareteLatAnteriore) }} </span>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">Par. Lat. Ant. Medio</span>
            <br />
            <span class="sa-text-data">{{ getDescrizioneSegmento(jsonData.pareteLatAnterioreMedia) }} </span>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">Par. Lat. Post. Medio</span>
            <br />
            <span class="sa-text-data">{{ getDescrizioneSegmento(jsonData.pareteLatPosterioreMedia) }}</span>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">Par. Inf. Media</span>
            <br />
            <span class="sa-text-data">{{ getDescrizioneSegmento(jsonData.pareteInferioreMedia) }}</span>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">Setto Post. Medio</span>
            <br />
            <span class="sa-text-data">{{ getDescrizioneSegmento(jsonData.settoposterioreMedio) }}</span>
          </b-col>

          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">Par. Lat. Post Apice</span>
            <br />
            <span class="sa-text-data">{{ getDescrizioneSegmento(jsonData.apicePost) }} </span>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">Par. Inf Apice</span>
            <br />
            <span class="sa-text-data">{{ getDescrizioneSegmento(jsonData.apiceInf) }}</span>
          </b-col>

          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">Par. Lat. Post. Basale</span><br /><span class="sa-text-data">{{ getDescrizioneSegmento(jsonData.pareteLatPosteriore) }}</span>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">Par. Inf. Basale</span><br /><span class="sa-text-data"> {{ getDescrizioneSegmento(jsonData.pareteInferiore) }} </span>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">Setto Post Basale</span><br /><span class="sa-text-data">{{ getDescrizioneSegmento(jsonData.settoposteriore) }} </span>
          </b-col>
        </b-row>
        <b-row
          ><b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"
            ><span class="sa-form-label">Totale</span>
            <br />
            <span class="sa-text-data">{{ jsonData.totale }}</span>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"
            ><span class="sa-form-label">IMA</span>
            <br />
            <span class="sa-text-data">{{ jsonData.estensioneDanno }}</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
            ><span class="sa-form-label">Note</span>
            <br />
            <span class="sa-text-data">{{ jsonData.notevs }}</span>
          </b-col>
        </b-row>
        <hr />
        <h4>Ventricolo Destro</h4>
        <b-row
          ><b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3"
            ><span class="sa-form-label">VDtd</span>
            <br />
            <span class="sa-text-data"> {{ jsonData.vdtd }}</span>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">Pressione Sistolica</span>
            <br />
            <span class="sa-text-data">{{ jsonData.pressSist }}</span>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3"
            ><span class="sa-form-label">TAPSE</span>
            <br />
            <span class="sa-text-data">{{ jsonData.tapse }}</span></b-col
          ></b-row
        >
        <b-row
          ><b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
            ><span class="sa-form-label">Note</span>
            <br />
            <span class="sa-text-data">{{ jsonData.notevd }}</span></b-col
          ></b-row
        >
        <hr />
        <h4>Atrio Destro</h4>
        <b-row
          ><b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">VCI i</span>
            <br />
            <span class="sa-text-data"> {{ jsonData.vcii }}</span> </b-col
          ><b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">VCI e</span>
            <br />
            <span class="sa-text-data">{{ jsonData.vcie }}</span>
          </b-col>
          <b-col> </b-col
        ></b-row>
        <b-row
          ><b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <span class="sa-form-label">Descrizione</span>
            <br />
            <span class="sa-text-data">{{ jsonData.descrAtriodestro }}</span>
          </b-col>
        </b-row>
        <hr />
        <h4>Atrio Sinistro</h4>
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">Atrio Sx</span>
            <br />
            <span class="sa-text-data">{{ jsonData.atrioSx }}</span>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">Lavi</span>
            <br />
            <span class="sa-text-data">{{ jsonData.lavi }}</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <span class="sa-form-label">Descrizione</span>
            <br />
            <span class="sa-text-data">{{ jsonData.descrAtriosinistro }}</span>
          </b-col>
        </b-row>
        <hr />
        <h4>Aorta</h4>
        <b-row
          ><b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3"
            ><span class="sa-form-label">Aorta</span>
            <br />
            <span class="sa-text-data">{{ jsonData.aorta }}</span></b-col
          >
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3"
            ><span class="sa-form-label">LVOT</span>
            <br />
            <span class="sa-text-data">{{ jsonData.lvot }}</span></b-col
          >
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3"
            ><span class="sa-form-label">Escrescenza Valvolare</span>
            <br />
            <span class="sa-text-data">{{ jsonData.escval }}</span></b-col
          >
        </b-row>
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8"
            ><span class="sa-form-label">2D</span>
            <br />
            <span class="sa-text-data">{{ jsonData.duedAorta }}</span></b-col
          >
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
            ><span class="sa-form-label">IA</span>
            <br />
            <span class="sa-text-data">{{ jsonData.ia }}</span></b-col
          >
        </b-row>
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
            ><span class="sa-form-label">Doppler</span>
            <br />
            <span class="sa-text-data">{{ jsonData.doppleraorta }}</span></b-col
          >
        </b-row>
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
            ><span class="sa-form-label">Area</span>
            <br />
            <span class="sa-text-data">{{ jsonData.area }}</span></b-col
          >
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
            ><span class="sa-form-label">PGMax</span>
            <br />
            <span class="sa-text-data">{{ jsonData.pgmaxAorta }}</span></b-col
          >
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
            ><span class="sa-form-label">PGMean</span>
            <br />
            <span class="sa-text-data">{{ jsonData.pgmeanAorta }}</span></b-col
          >
        </b-row>
        <hr />
        <h4>Mitrale</h4>
        <b-row
          ><b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
            ><span class="sa-form-label">2D</span>
            <br />
            <span class="sa-text-data">{{ jsonData.mitrale2d }}</span></b-col
          >
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
            ><span class="sa-form-label">IM</span>
            <br />
            <span class="sa-text-data">{{ jsonData.im }}</span></b-col
          >
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
            ><span class="sa-form-label">E/E'</span>
            <br />
            <span class="sa-text-data">{{ jsonData.ee }}</span></b-col
          >
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
            ><span class="sa-form-label">Doppler</span>
            <br />
            <span class="sa-text-data">{{ jsonData.dopplemitrale }}</span></b-col
          ></b-row
        >
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
            <span class="sa-form-label">PFVE</span>
            <br />
            <span class="sa-text-data">{{ jsonData.pfve }}</span>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
            <span class="sa-form-label">PFVA</span>
            <br />
            <span class="sa-text-data">{{ jsonData.pfva }}</span>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
            <span class="sa-form-label">DT</span>
            <br />
            <span class="sa-text-data">{{ jsonData.dt }}</span>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
            <span class="sa-form-label">IRT</span>
            <br />
            <span class="sa-text-data">{{ jsonData.irt }}</span>
          </b-col>

          <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
            <span class="sa-form-label">PGMean</span>
            <br />
            <span class="sa-text-data">{{ jsonData.pgmeanMitrale }}</span>
          </b-col>

          <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
            <span class="sa-form-label">MVA</span>
            <br />
            <span class="sa-text-data">{{ jsonData.mva }}</span>
          </b-col>
        </b-row>
        <hr />
        <h4>Polmonare</h4>
        <b-row
          ><b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <span class="sa-form-label">2D</span>
            <br />
            <span class="sa-text-data">{{ jsonData.polmonare2d }}</span>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <span class="sa-form-label">IP</span>
            <br />
            <span class="sa-text-data">{{ jsonData.ip }}</span>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <span class="sa-form-label">Doppler</span>
            <br />
            <span class="sa-text-data">{{ jsonData.dopplerpolmo }}</span>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <span class="sa-form-label">VMax</span>
            <br />
            <span class="sa-text-data">{{ jsonData.vmaxPolmonare }}</span>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <span class="sa-form-label">PGMax</span>
            <br />
            <span class="sa-text-data">{{ jsonData.pgmaxPolmonare }}</span>
          </b-col>
        </b-row>
        <hr />
        <h4>Doppler Vene Polmonari</h4>
        <b-row
          ><b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">S</span>
            <br />
            <span class="sa-text-data">{{ jsonData.s }}</span>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">D</span>
            <br />
            <span class="sa-text-data">{{ jsonData.d }}</span>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">APV</span>
            <br />
            <span class="sa-text-data">{{ jsonData.apv }}</span>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">APD</span>
            <br />
            <span class="sa-text-data">{{ jsonData.apd }}</span>
          </b-col>
        </b-row>
        <hr />
        <h4>Tricuspide</h4>
        <b-row
          ><b-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
            <span class="sa-form-label">2D</span>
            <br />
            <span class="sa-text-data"> {{ jsonData.tricuspide2d }}</span>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <span class="sa-form-label">IT</span>
            <br />
            <span class="sa-text-data">{{ jsonData.it }}</span>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <span class="sa-form-label">Doppler</span>
            <br />
            <span class="sa-text-data"> {{ jsonData.dopplertricuspide }}</span>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <span class="sa-form-label">VMax</span>
            <br />
            <span class="sa-text-data">{{ jsonData.vmaxTricuspide }}</span>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <span class="sa-form-label">VMaxIT</span>
            <br />
            <span class="sa-text-data">{{ jsonData.vmaxitTricuspide }}</span>
          </b-col>
        </b-row>
        <hr />
        <h4>Pericardio</h4>
        <b-row
          ><b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <span class="sa-form-label">Valore Pericardio</span>
            <br />
            <span class="sa-text-data">{{ jsonData.pericardio }}</span>
          </b-col></b-row
        >
        <b-row
          ><b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
            ><span class="sa-form-label">Conclusioni</span>
            <br />
            <span class="sa-text-data">{{ jsonData.conlusioni }}</span>
          </b-col>
        </b-row>
      </div>
    </template>
  </sa-page-layout>
</template>

<script>
import axios from "axios";
import UtilityMixin from "../../../../../utility/UtilityMixin";
import PazienteAnagrafica from "../../../../components/PazienteAnagrafica.vue";
import SaPageLayout from "../../../../../../template/sa/components/layout/contents/SaPageLayout.vue";

export default {
  mixins: [UtilityMixin],
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  components: {
    PazienteAnagrafica,
    SaPageLayout,
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient: {
      get: function() {
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length == 1) {
              if (this.utente.gruppo.menuGruppo[0].primaryKey === "homestudiomedico") {
                return "/paziente/view/" + this.idPaziente;
              } else if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                return "view/" + this.idPaziente;
              } else {
                return "homepaziente";
              }
            } else {
              return "";
            }
          } else {
            return "";
          }
        } else {
          return "";
        }
      },
    },
  },
  data() {
    return {
      pathResourceSegmenti: "/segmenti",
      jsonData: {},
      id: "-1",
      opzioniSegmenti: [],
      linkback: null,
      linkedit: "",
      pathResource: "/cardiologiaecocardirefertazioni",
      showmolalloading: false,
    };
  },
  created: function() {},
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.linkback = "/paziente/refertazioneecocardio";
    me.linkedit = "/paziente/refertazioneecocardio/edit/" + me.id;
    me.loadDefaultData();
    if (me.id !== "-1") {
      me.loadData();
    }
  },
  methods: {
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        me.showModalLoading = true;
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
        axios.get(link + me.id).then((response) => {
          me.jsonData = response.data.data;
        });

        me.showmolalloading = false;
      }
    },
    loadDefaultData() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceSegmenti;
      axios
        .get(link)
        .then((response) => {
          me.showModalLoading = false;
          response.data.data.forEach((element) => {
            let descrizione = this.getLabelTraduora(element.descrizione);
            me.opzioniSegmenti.push({ id: element.id, descrizione: descrizione });
          });
        })
        .catch((e) => {
          me.showModalLoading = false;
          console.log(e);
        });
    },
    getDescrizioneSegmento(id) {
      let me = this;
      let descrizione = "";
      me.opzioniSegmenti.forEach((element) => {
        if (element.id === id) {
          descrizione = element.descrizione;
        }
      });
      return descrizione;
    },
  },
};
</script>
