<template>
  <sa-page-layout :showModalLoading="showModalLoading" :btnSaveVisible="true" :btnAnnullaVisible="true" :btnBackVisible="false" :linkback="linkback" :pathResource="pathResource" :data="jsonData">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-header">
      <h5 class="sa-text-left">Dati</h5>
    </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <!-- InizioBody  -->
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><h5>{{ getTranslate("patient.cardiology.lblBasal") }}</h5></b-col
          >
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorBasalSeptum") }}</span
            ><br />
            <b-form-select v-model="jsonData.basaleSettoAntBasale" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorMiddleSeptum") }}</span
            ><br />
            <b-form-select v-model="jsonData.basaleSettoAntMedio" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorApexSeptum") }}</span
            ><br />
            <b-form-select v-model="jsonData.basaleSettoAntApice" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorBasalWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.basalePareteAntBasale" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorMiddleWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.basalePareteAntMedia" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorApexWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.basalePareteAntApice" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLateralAnteriorBasalWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.basalePareteLatAntBasale" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLateralAnteriorMiddleWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.basalePareteLatAntMedia" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLateralPosteriorBasalWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.basalePareteLatPostBasale" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLateralPosteriorMiddleWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.basalePareteLatPostMedia" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLateralPosteriorApexWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.basalePareteLatPostApice" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLowerBasalWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.basalePareteInfBasale" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLowerMiddleWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.basalePareteInfMedia" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLowerApexWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.basalePareteInfApice" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPosteriorBasalSeptum") }}</span
            ><br />
            <b-form-select v-model="jsonData.basaleSettoPostBasale" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPosteriorMiddleSeptum") }}</span
            ><br />
            <b-form-select v-model="jsonData.basaleSettoPostMedio" :options="opzioniSegmenti"></b-form-select
          ></b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblTotal") }}</span
            ><br />
            <b-form-input v-model="jsonData.totaleBasale"></b-form-input
          ></b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblDamageExtensionIMA") }}</span
            ><br />
            <b-form-input v-model="jsonData.dannoImaBasale"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPAS") }}</span
            ><br />
            <b-form-input v-model="jsonData.pasBasale" type="number"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPAD") }}</span
            ><br />
            <b-form-input v-model="jsonData.padBasale" type="number"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblFC") }}</span
            ><br />
            <b-form-input v-model="jsonData.fcBasale" type="number"></b-form-input
          ></b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><span>{{ getTranslate("patient.cardiology.lblSymptoms") }}</span
            ><br />
            <b-form-input v-model="jsonData.sintomiBasale"></b-form-input
          ></b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><h5>{{ getTranslate("patient.cardiology.lblVitality") }}</h5></b-col
          >
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorBasalSeptum") }}</span
            ><br />
            <b-form-select v-model="jsonData.vitalitaSettoAntBasale" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorMiddleSeptum") }}</span
            ><br />
            <b-form-select v-model="jsonData.vitalitaSettoAntMedio" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorApexSeptum") }}</span
            ><br />
            <b-form-select v-model="jsonData.vitalitaSettoAntApice" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorBasalWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.vitalitaPareteAntBasale" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorMiddleWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.vitalitaPareteAntMedia" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorApexWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.vitalitaPareteAntApice" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLateralAnteriorBasalWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.vitalitaPareteLatAntBasale" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLateralAnteriorMiddleWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.vitalitaPareteLatAntMedia" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLateralPosteriorBasalWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.vitalitaPareteLatPostBasale" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLateralPosteriorMiddleWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.vitalitaPareteLatPostMedia" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLateralPosteriorApexWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.vitalitaPareteLatPostApice" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLowerBasalWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.vitalitaPareteInfBasale" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLowerMiddleWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.vitalitaPareteInfMedia" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLowerApexWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.vitalitaPareteInfApice" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPosteriorBasalSeptum") }}</span
            ><br />
            <b-form-select v-model="jsonData.vitalitaSettoPostBasale" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPosteriorMiddleSeptum") }}</span
            ><br />
            <b-form-select v-model="jsonData.vitalitaSettoPostMedio" :options="opzioniSegmenti"></b-form-select
          ></b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblTotal") }}</span
            ><br />
            <b-form-input v-model="jsonData.totaleVitalita"></b-form-input
          ></b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblDamageExtensionIMA") }}</span
            ><br />
            <b-form-input v-model="jsonData.dannoImaVitalita"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPAS") }}</span
            ><br />
            <b-form-input v-model="jsonData.pasVitalita" type="number"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPAD") }}</span
            ><br />
            <b-form-input v-model="jsonData.padVitalita" type="number"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblFC") }}</span
            ><br />
            <b-form-input v-model="jsonData.fcVitalita" type="number"></b-form-input
          ></b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><span>{{ getTranslate("patient.cardiology.lblSymptoms") }}</span
            ><br />
            <b-form-input v-model="jsonData.sintomiVitalita"></b-form-input
          ></b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><h5>{{ getTranslate("patient.cardiology.lblIschemia") }}</h5></b-col
          >
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorBasalSeptum") }}</span
            ><br />
            <b-form-select v-model="jsonData.ischemiaSettoAntBasale" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorMiddleSeptum") }}</span
            ><br />
            <b-form-select v-model="jsonData.ischemiaSettoAntMedio" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorApexSeptum") }}</span
            ><br />
            <b-form-select v-model="jsonData.ischemiaSettoAntApice" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorBasalWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.ischemiaPareteAntBasale" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorMiddleWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.ischemiaPareteAntMedia" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorApexWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.ischemiaPareteAntApice" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLateralAnteriorBasalWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.ischemiaPareteLatAntBasale" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLateralAnteriorMiddleWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.ischemiaPareteLatAntMedia" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLateralPosteriorBasalWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.ischemiaPareteLatPostBasale" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLateralPosteriorMiddleWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.ischemiaPareteLatPostMedia" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLateralPosteriorApexWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.ischemiaPareteLatPostApice" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLowerBasalWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.ischemiaPareteInfBasale" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLowerMiddleWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.ischemiaPareteInfMedia" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLowerApexWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.ischemiaPareteInfApice" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPosteriorBasalSeptum") }}</span
            ><br />
            <b-form-select v-model="jsonData.ischemiaSettoPostBasale" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPosteriorMiddleSeptum") }}</span
            ><br />
            <b-form-select v-model="jsonData.ischemiaSettoPostMedio" :options="opzioniSegmenti"></b-form-select
          ></b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblTotal") }}</span
            ><br />
            <b-form-input v-model="jsonData.totaleIschemia"></b-form-input
          ></b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblDamageExtensionIMA") }}</span
            ><br />
            <b-form-input v-model="jsonData.dannoImaIschemia"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPAS") }}</span
            ><br />
            <b-form-input v-model="jsonData.pasIschemia" type="number"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPAD") }}</span
            ><br />
            <b-form-input v-model="jsonData.padIschemia" type="number"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblFC") }}</span
            ><br />
            <b-form-input v-model="jsonData.fcIschemia" type="number"></b-form-input
          ></b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><span>{{ getTranslate("patient.cardiology.lblSymptoms") }}</span
            ><br />
            <b-form-input v-model="jsonData.sintomiIschemia"></b-form-input
          ></b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><span>{{ getTranslate("patient.cardiology.lblConclusions") }}</span
            ><br />
            <b-form-textarea v-model="jsonData.conclusioni" rows="5"></b-form-textarea
          ></b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><span>{{ getTranslate("patient.cardiology.lblNotes") }}</span
            ><br />
            <b-form-textarea v-model="jsonData.note" rows="5"></b-form-textarea
          ></b-col>
        </b-row>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  components: { SaPageLayout, PazienteAnagrafica },
  data() {
    return {
      linkback: null,
      pathResource: "/cardioecostress",
      jsonData: {},
      showModalLoading: false,
      opzioniSegmenti: [
        { text: "Acinetico/a", value: "acinetic" },
        { text: "Aneurismatico/a", value: "aneurysmatic" },
        { text: "Discinetico/a", value: "dyskinetic" },
        { text: "Ipocinetico/a", value: "hypokinetic" },
        { text: "Normocinetico", value: "normokinetic" },
      ],
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient: {
      get: function() {
        if (this.utente.gruppo.menuGruppo) {
          if (this.utente.gruppo.menuGruppo.length > 1) {
            return "/paziente/view/" + this.idPaziente;
          } else {
            if (this.utente.gruppo.menuGruppo.length == 1) {
              if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                return "/paziente/view/" + this.idPaziente;
              } else {
                return "/paziente/homepaziente";
              }
            } else {
              return "";
            }
          }
        } else {
          return "";
        }
      },
    },
  },
  mounted() {
    let me = this;
    me.jsonData.idPaziente = this.$route.query.idPaziente;
    me.jsonData.tipoEvento = "Cardiologia - Anamnesi";
    me.jsonData.idAmbulatorio = sessionStorage.getItem("idAmbulatorio");
    me.id = this.$route.params.id;
    me.linkedit = "/paziente/cardiologia/ecostress/edit/" + me.id + "?idPaziente=" + me.idPaziente;
    if (me.id !== "-1") {
      me.linkback = "/paziente/cardiologia/ecostress/view/" + me.id + "?idPaziente=" + me.idPaziente;
      me.loadData();
    } else {
      me.linkback = "/paziente/cardiologia/ecostress?idPaziente=" + me.idPaziente;
    }
  },

  methods: {
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        });
      }
    },
    getTranslate(text) {
      return this.$i18n.t(text);
    },
  },
};
</script>
