<template>
  <div>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col xs="12" sm="3" md="3" lg="3" xl="3">
            <strong>{{ getLabelTraduora("patient.afa.lblSG") }}</strong
            ><br />
            {{ jsonData.sg }} <br
          /></b-col>
          <b-col xs="12" sm="3" md="3" lg="3" xl="3">
            <strong>{{ getLabelTraduora("patient.afa.lblPh") }}</strong> <br />
            {{ jsonData.ph }} <br
          /></b-col>
          <b-col xs="12" sm="3" md="3" lg="3" xl="3">
            <strong>{{ getLabelTraduora("patient.afa.lblUBJ") }}</strong> <br />
            {{ convertData(jsonData.ubg) }} <br
          /></b-col>
          <b-col xs="12" sm="3" md="3" lg="3" xl="3">
            <strong>{{ getLabelTraduora("patient.afa.lblUBJValue") }}</strong
            ><br />
            {{ jsonData.valoreUbg }} <br
          /></b-col>
          <b-col xs="12" sm="3" md="3" lg="3" xl="3">
            <strong>{{ getLabelTraduora("patient.afa.lblGLU") }}</strong
            ><br />
            {{ convertData(jsonData.glu) }}
          </b-col>
          <b-col xs="12" sm="3" md="3" lg="3" xl="3">
            <strong>{{ getLabelTraduora("patient.afa.lblNIT") }}</strong
            ><br />
            {{ convertData(jsonData.nit) }} <br
          /></b-col>
          <b-col xs="12" sm="3" md="3" lg="3" xl="3">
            <strong>{{ getLabelTraduora("patient.afa.lblLeu") }}</strong> <br />
            {{ convertData(jsonData.leu) }} <br
          /></b-col>
          <b-col xs="12" sm="3" md="3" lg="3" xl="3">
            <strong>{{ getLabelTraduora("patient.afa.lblLeuValue") }}</strong> <br />
            {{ jsonData.valoreLeu }} <br
          /></b-col>
          <b-col xs="12" sm="3" md="3" lg="3" xl="3">
            <strong>{{ getLabelTraduora("patient.afa.lblPRO") }}</strong
            ><br />
            {{ convertData(jsonData.pro) }} <br
          /></b-col>
          <b-col xs="12" sm="3" md="3" lg="3" xl="3">
            <strong>{{ getLabelTraduora("patient.afa.lblPROValue") }}</strong
            ><br />
            {{ jsonData.valorePro }}
          </b-col>
          <b-col xs="12" sm="3" md="3" lg="3" xl="3">
            <strong>{{ getLabelTraduora("patient.afa.lblKET") }}</strong
            ><br />
            {{ convertData(jsonData.ket) }} <br
          /></b-col>
          <b-col xs="12" sm="3" md="3" lg="3" xl="3">
            <strong>{{ getLabelTraduora("patient.afa.lblKETValue") }}</strong
            ><br />
            {{ jsonData.valoreKet }} <br
          /></b-col>
          <b-col xs="12" sm="3" md="3" lg="3" xl="3">
            <strong>{{ getLabelTraduora("patient.afa.lblBIL") }}</strong
            ><br />
            {{ convertData(jsonData.bil) }}
          </b-col>
          <b-col xs="12" sm="3" md="3" lg="3" xl="3">
            <strong>{{ getLabelTraduora("patient.afa.lblEry") }}</strong
            ><br />
            {{ convertData(jsonData.ery) }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <strong>{{ getLabelTraduora("patient.afa.lblReport") }} </strong><br />
            {{ jsonData.refertoUrine }}
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <b-row>
              <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <strong>Stato </strong><br />
                {{ jsonData.firma }}
              </b-col>
            </b-row>
            <hr />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";
export default {
  mixins: [UtilityMixin],
  props: {
    jsonData: {
      Type: Object,
      require: true,
    },
  },
  methods: {
    convertData(data) {
      let returnValue = "error translation";
      if (data == 0) {
        returnValue = this.getLabelTraduora("patient.afa.lblNegative");
      }
      if (data == 1) {
        returnValue = this.getLabelTraduora("patient.afa.lblPositive");
      }
      return returnValue;
    },
  },
};
</script>
