import Leonida from "../";

const routes = [
    { name: "Atleti", path: "/atleti", component: Leonida.AtletiList, meta: { title: "Lista Atleti" } },
    { name: "AtletaView", path: "/atleti/view/:id", component: Leonida.AtletaView, meta: { title: "Atleta View" } },
    { name: "AtletaEdit", path: "/atleti/edit/:id", component: Leonida.AtletaEdit, meta: { title: "Atleta" } },
    { name: "Worklist", path: "/worklist", component: Leonida.WorkList, meta: { title: "WorkList" } },
    { name: "WorklistView", path: "/worklist/view/:id", component: Leonida.WorkListView, meta: { title: "WorkList" } },
    { name: "PendingList", path: "/pendinglist", component: Leonida.PendingList, meta: { title: "Pending List" } },
    { name: "VisitaMedicoSportiva", path: "/worklist/visitamedicosportiva/view/:id", component: Leonida.VisitaMedicoSportivaView, meta: { title: "Visita Medico Sportiva" } },
    { name: "VisitaMedicoSportivaEdit", path: "/worklist/visitamedicosportiva/edit/:id", component: Leonida.VisitaMedicoSportivaEdit, meta: { title: "Visita Medico Sportiva" } },
    { name: "VisiteList", path: "/visite", component: Leonida.VisiteList, meta: { title: "Visite Medico Sportive" } },
    { name: "PrenotazioniList", path: "/prenotazioni", component: Leonida.PrenotazioniList, meta: { title: "Lista Prenotazioni" } },
    { name: "PrenotazioniView", path: "/prenotazioni/view/:id", component: Leonida.PrenotazioniView, meta: { title: "Prenotazione" } },
    { name: "PrenotazioniEdit", path: "/prenotazioni/edit/:id", component: Leonida.PrenotazioniEdit, meta: { title: "Prenotazione" } },
    { name: "LeonidaAccettazionePazienteList", path: "/accettazione", component: Leonida.LeonidaAccettazionePazienteList, meta: { title: "Accettazione" } },
    { name: "LeonidaAccettazionePazienteView", path: "/accettazione/view/:id", component: Leonida.LeonidaAccettazionePazienteView, meta: { title: "Accettazione" } },
    { name: "LeonidaAccettazionePazienteEdit", path: "/accettazione/edit/:id", component: Leonida.LeonidaAccettazionePazienteEdit, meta: { title: "Accettazione" } },
    { name: "AccertamentiSupplementariDettagliEdit", path: "/accertamentisupplementaridettagli/edit/:id", component: Leonida.AccertamentiSupplementatriDettagliEdit, meta: { title: "Accertamenti Supplementari Dettagli" } },
    { name: "LeonidaFederazioniSportiveNazionaliList", path: "/federazionisportivenazionali", component: Leonida.LeonidaFederazioniSportiveNazionaliList, meta: { title: "Federazioni Sportive Nazionali" } },
    { name: "LeonidaFederazioniSportiveNazionaliView", path: "/federazionisportivenazionali/view/:id", component: Leonida.LeonidaFederazioniSportiveNazionaliView, meta: { title: "Federazioni Sportive Nazionali" } },
    { name: "LeonidaFederazioniSportiveNazionaliEdit", path: "/federazionisportivenazionali/edit/:id", component: Leonida.LeonidaFederazioniSportiveNazionaliEdit, meta: { title: "Federazioni Sportive Nazionali" } },
];

export default routes;
