<template>
    <sa-page-view-edit ref="pgaView" :data="data" :pathResource="pathResource" :backVisible="true" :saveVisible="true" :linkback="linkback" :linkedit="linkedit" :showmolalloading="showmolalloading">
        <template slot="body">
            <div>
                <div class="sa-form-section">
                    <h5 class="sa-text-left">{{ this.$i18n.t("clinic.lblClinicRegistry") }}</h5>
                    <b-row>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">{{ this.$i18n.t("clinic.lblStructure") }}</span>
                            <b-form-select v-model="data.codiceStruttura" class="mb-2 mr-sm-2 mb-sm-0" :options="strutturaOption" :value="null"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">{{ this.$i18n.t("clinic.lblBranca") }}</span>
                            <b-form-select v-model="data.codiceBranca" class="mb-2 mr-sm-2 mb-sm-0" :options="brancheOption" :value="null" @change="loadSpecializzazioni(data.codiceBranca)"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">{{ this.$i18n.t("clinic.lblSpecializations") }}</span>
                            <b-form-select v-model="data.specializzazione" class="mb-2 mr-sm-2 mb-sm-0" :options="specializzazioniOption" :value="null"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">{{ this.$i18n.t("clinic.lblName") }}</span>
                            <b-form-input v-model="data.nome" placeholder="Ambulatorio"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">{{ this.$i18n.t("clinic.lblClinicManager") }}</span>
                            <b-form-input v-model="data.responsabile" placeholder="Responsabile Struttura"></b-form-input>
                        </b-col>
                    </b-row>
                </div>
                <div class="sa-form-section">
                    <h5 class="sa-text-left">{{ this.$i18n.t("clinic.lblClinicInformation") }}</h5>
                    <b-row>
                        <b-col cols="10" xs="10" sm="10" md="10" lg="10" xl="10">
                            <span class="sa-form-label">{{ this.$i18n.t("clinic.lblAddress") }}</span>
                            <b-form-input v-model="data.indirizzo" placeholder="Indirizzo"></b-form-input>
                        </b-col>
                        <b-col cols="2" xs="2" sm="2" md="2" lg="2" xl="2">
                            <span class="sa-form-label">{{ this.$i18n.t("clinic.lblStreetNumber") }}</span>
                            <b-form-input v-model="data.numeroCivico" placeholder="Numero Civico"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                            <span class="sa-form-label">{{ this.$i18n.t("clinic.lblProvince") }}</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="data.provincia" class="mb-2 mr-sm-2 mb-sm-0" :options="provinciaOption" :value="null" @change="loadComune(data.provincia)"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                            <span class="sa-form-label">{{ this.$i18n.t("clinic.lblCity") }}</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="data.comune" class="mb-2 mr-sm-2 mb-sm-0" :options="comuneOption" :value="null" @change="loadCap(data.comune)"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                            <span class="sa-form-label">{{ this.$i18n.t("clinic.lblPostalCode") }}</span>
                            <b-form-input v-model="data.cap" placeholder="CAP"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                            <span class="sa-form-label">{{ this.$i18n.t("clinic.lblAsl") }}</span>
                            <b-form-input v-model="data.asl" placeholder="ASL"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                            <span class="sa-form-label">{{ this.$i18n.t("clinic.lblAslCode") }}</span>
                            <b-form-input v-model="data.codiceAsl" placeholder="Codice ASL"></b-form-input>
                        </b-col>
                    </b-row>
                </div>
                <div class="sa-form-section">
                    <h5 class="sa-text-left">{{ this.$i18n.t("clinic.lblContacts") }}</h5>
                    <b-row>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                            <span class="sa-form-label">{{ this.$i18n.t("clinic.lblTelephoneNumber") }}</span>
                            <b-form-input v-model="data.telefono" placeholder="Telefono"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                            <span class="sa-form-label">{{ this.$i18n.t("clinic.lblEmail") }}</span>
                            <b-form-input v-model="data.email" placeholder="Email"></b-form-input>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </template>
    </sa-page-view-edit>
</template>
<script>
import axios from "axios";
//import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { SaPageViewEdit } from "../../../template/sa/components/layout/contents";
export default {
    components: { SaPageViewEdit },
    data() {
        return {
            pathRest: "/ambulatori",
            pathResource: "/ambulatori",
            linkback: "/ambulatori",
            linkProvince: "/province",
            linkComune: "/comuni/provincia",
            linkComuneByIstat: "/comuni/istat",
            linkStrutture: "/strutture",
            linkBranche: "/branche",
            linkSpecializzazioni: "/branchespecializzazione",
            linkedit: null,
            showmolalloading: false,
            id: "-1",
            profili: [],
            strutturaOption: [{ value: null, text: this.$i18n.t("clinic.lblSelectStructure") }],
            specializzazioniOption: [{ value: null, text: this.$i18n.t("clinic.lblSelectSpecialization") }],
            brancheOption: [{ value: null, text: this.$i18n.t("clinic.lblSelectBranca") }],
            provinciaOption: [{ value: null, text: this.$i18n.t("clinic.lblSelectProvince") }],
            comuneOption: [{ value: null, text: this.$i18n.t("clinic.lblSelectTown") }],
            data: { idStruttura: null, codiceStruttura: null, codiceBranca: null, specializzazione: null, comune: null, provincia: null, cap: null },
        };
    },
    computed: {},
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadDefaultData();
    },
    methods: {
        loadDefaultData() {
            let me = this;
            me.loadProvince();
            me.loadStrutture();
            me.loadBranche();
            me.loadData();
            me.loadSpecializzazioni(me.data.codiceBranca);
        },
        loadData() {
            let me = this;
            if (me.id === "-1") {
                me.showmolalloading = false;
            } else {
                me.showmolalloading = true;
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.data = response.data.data;
                    me.loadComune(me.data.provincia);
                    me.loadCap(me.data.comune);
                    me.loadSpecializzazioni(me.data.codiceBranca);
                    me.showmolalloading = false;
                    me.loadComune(me.data.provincia);
                });
            }
        },
        loadProvince() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkProvince;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.provinciaOption.push({ value: element.sigla, text: element.provincia });
                });
            });
        },
        loadComune(sigla) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
            axios.get(link).then((response) => {
                me.comuneOption = [];
                response.data.data.forEach((element) => {
                    me.comuneOption.push({ value: element.codiceIstat, text: element.comune });
                });
            });
        },
        loadCap(comune) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkComuneByIstat + "/" + comune;
            axios.get(link, { params: {} }).then((response) => {
                me.data.cap = response.data.data.cap;
            });
        },
        loadStrutture() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkStrutture;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.strutturaOption.push({ value: element.codiceStruttura, text: element.denominazione });
                });
            });
        },
        loadBranche() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkBranche;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.brancheOption.push({ value: element.codice, text: element.branca });
                });
            });
        },
        loadSpecializzazioni(codiceBranca) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkSpecializzazioni;
            axios.get(link, { params: { codiceStruttura: me.data.codiceStruttura, codiceBranca: codiceBranca } }).then((response) => {
                me.specializzazioniOption = [];
                response.data.data.forEach((element) => {
                    me.specializzazioniOption.push({ value: element.specializzazione, text: element.specializzazione });
                });
            });
        },
        onSave() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            let jsonData = JSON.stringify(me.data);

            me.showmolalloading = true;
            if (me.id == "-1") {
                axios
                    .post(link, jsonData)
                    .then((response) => {
                        me.data = response.data.data;
                        me.showmolalloading = false;
                        me.$router.replace(me.routeLink).catch((err) => {
                            err;
                        });
                    })
                    .catch((error) => {
                        me.showmolalloading = false;
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            } else {
                axios
                    .put(link + "/" + me.id, jsonData)
                    .then((response) => {
                        me.gruppo = response.data.data;
                        me.$refs["sa-modal-loading"].close();
                        me.$router.replace(me.routeLink).catch((err) => {
                            err;
                        });
                    })
                    .catch((error) => {
                        me.$refs["sa-modal-loading"].close();
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            }
        },
    },
};
</script>
