<template>
  <sa-page-layout :data="jsonData" :btnSaveVisible="true" :btnBackVisible="true" :linkback="linkback" :pathResource="pathResource">
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col xs="4" sm="4" md="4" lg="4" xl="4">
            <span>C.F Medico: </span><br />
            <b-form-input v-model="jsonData.codiceFiscaleMedicoTitolare" placeholder="Codice Fiscale Medico Titolare"></b-form-input>
          </b-col>
          <b-col xs="4" sm="4" md="4" lg="4" xl="4">
            <span>C.F Assistito: </span><br />
            <b-form-input v-model="jsonData.codiceFiscaleAssistito" placeholder="Codice Fiscale Assistito"></b-form-input>
          </b-col>
          <b-col xs="4" sm="4" md="4" lg="4" xl="4">
            <span>Data Esecuzione</span><br />
            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataEsecuzione" type="date" placeholder="Data Esecuzione"></date-picker>
          </b-col>
        </b-row>
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col xs="4" sm="4" md="4" lg="4" xl="4">
            <span>Risultato:</span>
            <b-form-select v-model="jsonData.risultato" id="inline-form-custom-select-pref" :options="risultatoOption" :value="null" value-field="value" text-field="text" required></b-form-select>
          </b-col>
          <b-col xs="4" sm="4" md="4" lg="4" xl="4">
            <span>PDF Upload:</span>
            <b-form-file placeholder="Seleziona Referto" id="uploadFile" ref="file-input" class="custom-input" @change="handleFile" type="file" accept=".pdf"></b-form-file>
          </b-col>
          <b-col xs="4" sm="4" md="4" lg="4" xl="4">
            <span>Telefono: </span><br />
            <b-form-input v-model="jsonData.telefono" placeholder="Telefono Assistito"></b-form-input>
          </b-col>
        </b-row>
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col xs="6" sm="6" md="6" lg="6" xl="6">
            <span>Sintomatologia:</span> <br />
            <b-form-select v-model="jsonData.sintomatologia" id="inline-form-custom-select-pref" :options="sintomatologieOptions" :value="null" value-field="descrizione" text-field="descrizione" required></b-form-select>
          </b-col>
          <b-col xs="6" sm="6" md="6" lg="6" xl="6">
            <span>Data inzio Sintomi:</span> <br />
            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataInizioSintomi" type="date" placeholder="Data Inizio Sintomi"></date-picker>
          </b-col>
        </b-row>
        <br />
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col xs="2" sm="2" md="2" lg="2" xl="2">
            <span>Gravidanza:</span> <br />
            <b-form-checkbox v-model="jsonData.soggettoGravidanza" name="checkbox-1" value="Si" unchecked-value="No"> </b-form-checkbox>
          </b-col>
          <b-col xs="10" sm="10" md="10" lg="10" xl="10">
            <span>Data Presumibile Gravidanza:</span> <br />
            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataPresumibileParto" type="date" placeholder="Data Presunta Gravidanza"></date-picker>
          </b-col>
        </b-row>
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col xs="3" sm="3" md="3" lg="3" xl="3">
            <span>Operatore:</span>
            <b-form-select v-model="jsonData.soggettoOperatore" id="inline-form-custom-select-pref" :options="soggettiOperatoriOptions" value-field="descrizione" text-field="descrizione" required></b-form-select>
          </b-col>
          <b-col xs="3" sm="3" md="3" lg="3" xl="3">
            <span>Asl Operatore:</span>
            <b-form-select v-model="jsonData.aslOperatore" id="inline-form-custom-select-pref" :options="aslOperatoriOptions" :value="null" value-field="descrizione" text-field="descrizione" required></b-form-select>
          </b-col>
          <b-col xs="3" sm="3" md="3" lg="3" xl="3">
            <span>CampagnaScreening:</span>
            <b-form-select v-model="jsonData.campagnaScreening" id="inline-form-custom-select-pref" :options="campagnaScreeningoptions" :value="null" value-field="descrizione" text-field="descrizione" required></b-form-select>
          </b-col>
          <b-col xs="3" sm="3" md="3" lg="3" xl="3">
            <span>Soggetto:</span>
            <b-form-select v-model="jsonData.soggetto" id="inline-form-custom-select-pref" :options="soggettiOptions" value-field="descrizione" text-field="descrizione" required></b-form-select>
          </b-col>
        </b-row>
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col xs="4" sm="4" md="4" lg="4" xl="4">
            <span>Data Esitazione Antigenico:</span>
            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataEsitazioneAntigenico" type="date" placeholder="Data Esitazione Antigenico"></date-picker>
          </b-col>
          <b-col xs="4" sm="4" md="4" lg="4" xl="4">
            <span>Ora Esitazione Antigenico:</span>
            <date-picker format="HH:mm" value-type="timestamp" type="time" v-model="jsonData.oraEsitazioneAntigenico" placeholder="Ora Esitazione"></date-picker>
          </b-col>
          <b-col xs="4" sm="4" md="4" lg="4" xl="4">
            <span>Ora Effettuazione:</span>
            <date-picker format="HH:mm" value-type="timestamp" type="time" v-model="jsonData.oraEffettuazione" placeholder="Ora Effettuazione"></date-picker>
          </b-col>
        </b-row>
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col xs="8" sm="8" md="8" lg="8" xl="8">
            <span>Tipologia Test:</span>
            <b-form-input v-model="jsonData.tipologiaTest" :state="verifyStateTipoTest" placeholder="Seleziona Tipologia Test" list="lista-tipologie-test"></b-form-input>
            <datalist id="lista-tipologie-test">
              <option v-for="tipo in tipologieTestOptions" :key="tipo.descrizione">
                {{ tipo.descrizione }}
              </option>
            </datalist>
            <!---<b-form-select v-model="jsonData.tipologiaTest" id="inline-form-custom-select-pref" :options="tipologieTestOptions" :value="null" value-field="id" text-field="descrizione" required></b-form-select>--->
          </b-col>
          <b-col xs="4" sm="4" md="4" lg="4" xl="4">
            <span>Esente Covid:</span>
            <b-form-select v-model="jsonData.esenteCovid" id="inline-form-custom-select-pref" :options="esenteCovidOption" :value="null" value-field="text" text-field="text" required></b-form-select>
          </b-col>
        </b-row>
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col xs="3" sm="3" md="3" lg="3" xl="3">
            <span>Istituto:</span>
            <b-form-input v-model="jsonData.istituto" placeholder="Instituto"></b-form-input>
          </b-col>
          <b-col xs="3" sm="3" md="3" lg="3" xl="3">
            <span>Nome Struttura:</span>
            <b-form-input v-model="jsonData.nomeStruttura" placeholder="Nome Struttura"></b-form-input>
          </b-col>
          <b-col xs="3" sm="3" md="3" lg="3" xl="3">
            <span>Generazione Antigenico:</span>
            <b-form-input v-model="jsonData.generazioneAntigenico" placeholder="Instituto"></b-form-input>
          </b-col>
          <b-col xs="3" sm="3" md="3" lg="3" xl="3">
            <span>Metodi Cautilizzata:</span>
            <b-form-input v-model="jsonData.metodiCautilizzata" placeholder="Metodi Cautilizzata"></b-form-input>
          </b-col>
        </b-row>
      </div>
    </template>
  </sa-page-layout>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import axios from "axios";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
  components: { SaPageLayout, DatePicker },
  data() {
    return {
      pathResource: "/gestioneantigenici",
      pathResourceAslOperatori: "/gestioneantigeniciasloperatori",
      pathResourceSintomatologie: "/gestioneantigenicisintomatologie",
      pathResourceCampagnaScreening: "/gestioneantigenicicampagnascreening",
      pathResourceSoggettoOperatori: "/gestioneantigenicisoggettooperatori",
      pathResourceSoggetti: "/gestioneantigenicisoggetti",
      pathResourceTipologiaTest: "/gestioneantigenicitipologietampone",
      tipologieTestOptions: [],

      aslOperatoriOptions: [{ id: null, descrizione: "- Seleziona ASL -" }],
      campagnaScreeningoptions: [{ id: null, descrizione: "- Seleziona Campagna Screening -" }],
      soggettiOperatoriOptions: [{ id: null, descrizione: "- Seleziona Soggetto Operatore -" }],
      soggettiOptions: [{ id: null, descrizione: "- Seleziona Soggetto -" }],
      esenteCovidOption: [
        { value: null, text: "- Seleziona Esente -" },
        { value: "SI", text: "Si" },
        { value: "NO", text: "No" },
      ],
      sintomatologieOptions: [{ value: null, descrizione: "- Seleziona Sintomatologia -" }],
      risultatoOption: [
        { value: null, text: "- Seleziona Esito -" },
        { value: "N", text: "Negativo" },
        { value: "P", text: "Positivo" },
        { value: "NV", text: "Non Rilevato" },
      ],
      id: "-1",
      linkback: null,
      jsonData: { sintomatologia: null, risultato: null, esenteCovid: null, soggettoOperatore: null, soggetto: null, aslOperatore: null, campagnaScreening: null },
    };
  },

  computed: {
    verifyStateTipoTest() {
      let me = this;
      let validita = false;
      me.tipologieTestOptions.forEach((element) => {
        if (element.descrizione == me.jsonData.tipologiaTest) {
          return (validita = true);
        }
      });
      return validita;
    },
  },

  mounted: function() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
    me.loadAslOperatori();
    me.loadCampagnaScreening();
    me.loadSoggettoOperatore();
    me.loadSoggetti();
    me.loadSintomatologie();
    me.loadTipologieTest();
    me.linkback = me.id == "-1" ? "/gestionetamponiantigenici" : "/gestionetamponiantigenici/view/" + me.id;
  },

  methods: {
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        me.showModalLoading = true;
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
        console.log(link);
        axios.get(link).then((response) => {
          me.jsonData = response.data.data[0];
        });
      }
    },
    /*METHODS UPLOAD FILE*/

    handleFile(e) {
      let me = this;
      const selectedFile = e.target.files[0];
      me.createBase64(selectedFile);
    },

    createBase64(fileObject) {
      let me = this;
      const reader = new FileReader();
      reader.onload = (e) => {
        me.jsonData.pdfReferto = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },

    /*FINE METHODS UPLOAD FILE*/
    /*LOAD SELECT*/
    loadAslOperatori() {
      let me = this;

      let link = process.env.VUE_APP_PATH_API + me.pathResourceAslOperatori;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.aslOperatoriOptions.push({ value: element.descrizione, descrizione: element.descrizione });
        });
      });
    },
    loadCampagnaScreening() {
      let me = this;

      let link = process.env.VUE_APP_PATH_API + me.pathResourceCampagnaScreening;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.campagnaScreeningoptions.push({ value: element.descrizione, descrizione: element.descrizione });
        });
      });
    },
    loadSoggettoOperatore() {
      let me = this;

      let link = process.env.VUE_APP_PATH_API + me.pathResourceSoggettoOperatori;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.soggettiOperatoriOptions.push({ value: element.descrizione, descrizione: element.descrizione });
        });
      });
    },
    loadSoggetti() {
      let me = this;

      let link = process.env.VUE_APP_PATH_API + me.pathResourceSoggetti;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.soggettiOptions.push({ value: element.descrizione, descrizione: element.descrizione });
        });
      });
    },
    loadSintomatologie() {
      let me = this;

      let link = process.env.VUE_APP_PATH_API + me.pathResourceSintomatologie;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.sintomatologieOptions.push({ value: element.descrizione, descrizione: element.descrizione });
        });
      });
    },
    loadTipologieTest() {
      let me = this;

      let link = process.env.VUE_APP_PATH_API + me.pathResourceTipologiaTest;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.tipologieTestOptions.push({ value: element.descrizione, descrizione: element.descrizione });
        });
      });
    },

    /*END LOAD SELECT*/
  },
};
</script>
