<template>
    <sa-page-layout :data="data" :pathResource="pathResource" :btnAnnullaVisible="true" :btnSaveVisible="true" :linkback="linkback" :linkedit="linkedit" :showModalLoading="showModalLoading">
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <div>
                    <b-row>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Codice provetta pretrasfusionale</span>
                            <b-form-input v-model="data.codiceProvettaPretrasfusionale" placeholder="Codice provetta pretrasfusionale"></b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">N° sacche richieste</span>
                            <b-form-input v-model="data.numeroSaccheRichieste" placeholder="N° sacche richieste"></b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Grado urgenza</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="data.gradoUrgenza" class="mb-2 mr-sm-2 mb-sm-0" :options="gradiUrgenzeOption" :value="null"></b-form-select>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Codice diagnosi</span>
                            <b-form-input v-model="data.codiceDiagnosi" placeholder="Codice diagnosi"></b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Gruppo Sanguigno</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="data.gruppoSanguigno" class="mb-2 mr-sm-2 mb-sm-0" :options="gruppoSanguignoOption" :value="null"></b-form-select>
                        </b-col>

                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Fattore RH</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="data.fattoreRh" class="mb-2 mr-sm-2 mb-sm-0" :options="fattoreRhOption" :value="null"></b-form-select>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Provetta pretrasfusionale</span>
                            <b-form-input v-model="data.provettaPretrasfusionale" placeholder="Provetta pretrasfusionale"></b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Codice reparto</span>
                            <b-form-input v-model="data.codiceReparto" placeholder="Codice reparto"></b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Reparto</span>
                            <b-form-input v-model="data.reparto" placeholder="Reparto"></b-form-input>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Matricola operatore</span>
                            <b-form-input v-model="data.matricolaOperatore" placeholder="Matricola operatore"></b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Matricola medico</span>
                            <b-form-input v-model="data.matricolaMedico" placeholder="Matricola medico"></b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Motivo richiesta</span>
                            <b-form-input v-model="data.motivoRichiesta" placeholder="Motivo richiesta"></b-form-input>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">APTT</span>
                            <b-form-input v-model="data.aptt" placeholder="APTT"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">HB</span>
                            <b-form-input v-model="data.hb" placeholder="HB"></b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">INR</span>
                            <b-form-input v-model="data.inr" placeholder="INR"></b-form-input>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">PLT</span>
                            <b-form-input v-model="data.plt" placeholder="PLT"></b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">PT</span>
                            <b-form-input v-model="data.pt" placeholder="PT"></b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Tipologia</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="data.tipologia" class="mb-2 mr-sm-2 mb-sm-0" :options="tipologiaOption" :value="null"></b-form-select>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Codice struttura</span>
                            <b-form-input v-model="data.codiceStruttura" placeholder="Codice struttura"></b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Stato Attuale</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="data.statoAttuale" class="mb-2 mr-sm-2 mb-sm-0" :options="statoOption" :value="null"></b-form-select>
                        </b-col>

                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Data Richiesta</span>
                            <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="data.dataRichiesta" type="datetime" placeholder="Data Richiesta"></date-picker>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Data di Scadenza</span>
                            <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="data.statoAttualeDataOra" type="datetime" placeholder="Data Scadenza"></date-picker>
                        </b-col>
                    </b-row>
                </div>

                <div>
                    <h5>Dati Paziente</h5>

                    <b-row>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Nome</span>
                            <b-form-input v-model="data.nomePaziente" placeholder="Nome"></b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Cognome</span>
                            <b-form-input v-model="data.cognomePaziente" placeholder="Cognome"></b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Data di Nascita</span>
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.dataNascitaPaziente" type="datetime" placeholder="Data di Nascita"></date-picker>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Provincia di Nascita</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="data.descrizioneProvincia" class="mb-2 mr-sm-2 mb-sm-0" :options="provinceNascitaPazienteOption" :value="null" @change="loadComuni(data.descrizioneProvincia)"></b-form-select>
                        </b-col>

                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Comune di Nascita</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="data.luogoNascitaPaziente" class="mb-2 mr-sm-2 mb-sm-0" :options="luogoNascitaPazienteOption" :value="null"></b-form-select>
                        </b-col>

                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Sesso</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="data.sessoPaziente" class="mb-2 mr-sm-2 mb-sm-0" :options="sessoOption" :value="null"></b-form-select>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Cartella Clinica</span>
                            <b-form-input v-model="data.cartellaClinica" placeholder="Codice struttura"></b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Data di Ricovero</span>
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.dataRicovero" type="datetime" placeholder="Data Ricovero"></date-picker>
                        </b-col>

                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Parti/Aborti Pregressi</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="data.partiAbortiPregressi" class="mb-2 mr-sm-2 mb-sm-0" :options="selezioneOption" :value="null"></b-form-select>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Data Ultimo Parto/Aborto</span>
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.dataUltimoAbortoParto" type="datetime" placeholder="Data Ultimo Parto/Aborto"></date-picker>
                        </b-col>

                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Trasfussioni Pregresse</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="data.trasfusioniPregresse" class="mb-2 mr-sm-2 mb-sm-0" :options="selezioneOption" :value="null"></b-form-select>
                        </b-col>

                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Data Ultima Trasfusione</span>
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.dataUltimaTrasfusione" type="datetime" placeholder="Data Ricovero"></date-picker>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Reazioni Trasfusionali</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="data.reazioniTrasfusionali" class="mb-2 mr-sm-2 mb-sm-0" :options="selezioneOption" :value="null"></b-form-select>
                        </b-col>

                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Data Ultima Reazione Trasfusionale</span>
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.dataUltimaReazioneTrasfusionale" type="datetime" placeholder="Data Ultima Reazione Trasfusionale"></date-picker>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </template>
    </sa-page-layout>
</template>

<script>
import axios from "axios";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { DatePicker, SaPageLayout },
    data() {
        return {
            pathResource: "/bancaematicatrasfusionirichiestereparti",
            pathRESTRequest: "/bancaematica",
            linkstato: "/bancaematicatrasfusionirichiesterepartistatitipi",
            linkgrupposanguigno: "/bancheematichegruppisanguigni",
            linktipologie: "/bancheematichetipiemoderivatitipologie",
            linkfattorerh: "/bancaematicafattorirh",
            pathResourceAggiornaStato: "/aggiornaStato",
            linkedit: null,
            linkProvince: "/province",
            linkComune: "/comuni/provincia",
            linkGradiUrgenze: "/bancaematicagradiurgenze",
            linkback: null,
            showModalLoading: false,
            id: "-1",
            profili: [],
            queryJsonData: {},
            statoOption: [{ value: null, text: "--Seleziona Valore--" }],
            gruppoSanguignoOption: [{ value: null, text: "--Seleziona Valore--" }],
            fattoreRhOption: [{ value: null, text: "--Seleziona Valore--" }],
            tabellaRichieste: [],
            rigaSelezionata: null,
            tipologiaRichiesta: null,
            pathRestRichiesteScorte: "/bancheematichetrasfusionirichiestescorte",
            data: { statoAttuale: null, gruppoSanguigno: null, fattoreRh: null, luogoNascitaPaziente: null, sessoPaziente: null, tipologia: null, trasfusioniPregresse: null, reazioniTrasfusionali: null, partiAbortiPregressi: null, gradoUrgenza: null },
            provinceNascitaPazienteOption: [{ value: null, text: "--Seleziona Provincia--" }],
            luogoNascitaPazienteOption: [{ value: null, text: "--Seleziona Comune--" }],
            tipologiaOption: [{ value: null, text: "--Seleziona Valore--" }],
            sessoOption: [
                { value: null, text: "-Sel. Sesso-" },
                { value: "M", text: "Maschio" },
                { value: "F", text: "Femmina" },
            ],
            selezioneOption: [
                { value: null, text: "-Seleziona Risposta-" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
            ],
            gradiUrgenzeOption: [{ value: null, text: "-Seleziona Livello di Urgenza-" }],
        };
    },
    computed: {},
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        me.queryJsonData = this.$route.query.defaultData;
        if (me.id === "-1") {
            me.linkback = "/bancaematicarichiestereparto";
        } else {
            me.linkback = "/bancaematicarichiestereparto/view/" + me.id;
        }
        me.loadDefaultData();
        if (this.$route.query.defaultData) {
            me.setDataFromQueryJson();
        }
    },
    methods: {
        loadDefaultData() {
            let me = this;
            me.loadData();
            me.loadProvince();
            me.loadFattoreRh();
            me.loadGruppoSanguigno();
            me.loadTipologia();
            me.loadGradiUrgenze();
            me.loadStato();
        },
        loadData() {
            let me = this;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.data = response.data.data;
                    me.loadComuni(me.data.descrizioneProvincia);
                    me.showModalLoading = false;
                });
            }
        },
        loadStato() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkstato;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.statoOption.push({ value: element.valore, text: element.valore });
                });
            });
        },
        loadProvince() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkProvince;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.provinceNascitaPazienteOption.push({ value: element.sigla, text: element.provincia });
                });
            });
        },
        loadComuni() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + me.data.descrizioneProvincia;
            axios.get(link).then((response) => {
                me.luogoNascitaPazienteOption = [];
                response.data.data.forEach((element) => {
                    me.luogoNascitaPazienteOption.push({ value: element.codiceIstat, text: element.comune });
                });
            });
        },
        loadGruppoSanguigno() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkgrupposanguigno;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.gruppoSanguignoOption.push({ value: element.valore, text: element.valore });
                });
            });
        },
        loadGradiUrgenze() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkGradiUrgenze;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.gradiUrgenzeOption.push({ value: element.valore, text: element.valore });
                });
            });
        },
        loadFattoreRh() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkfattorerh;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.fattoreRhOption.push({ value: element.valore, text: element.valore });
                });
            });
        },
        loadTipologia() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linktipologie;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.tipologiaOption.push({ value: element.tipologia, text: element.tipologia });
                });
            });
        },
        onModificaFattoreRh(valore) {
            if (valore === "POSITIVO") {
                return "+";
            } else {
                return "-";
            }
        },
        onShow() {
            let me = this;
            me.tabellaRichieste = [];
        },
        onRowSelected(items) {
            if (items.length > 0) {
                this.rigaSelezionata = items[0];
            }
        },
        onOk() {
            let me = this;
            me.data.codiceProvettaPretrasfusionale = me.rigaSelezionata.codiceProvettaPretrasfusionale;
            me.data.hb = me.rigaSelezionata.hb;
            me.data.inr = me.rigaSelezionata.inr;
            me.data.pt = me.rigaSelezionata.pt;
            me.data.pit = me.rigaSelezionata.pit;
            me.data.aptt = me.rigaSelezionata.aptt;
            me.data.gruppoSanguigno = me.rigaSelezionata.gruppoSanguigno;
            me.data.codiceSacca = me.rigaSelezionata.codiceSacca;
            me.data.fattoreRh = me.rigaSelezionata.fattoreRh;
        },
        setDataFromQueryJson() {
            let me = this;
            me.queryJsonData = JSON.parse(me.queryJsonData);
            console.log(me.queryJsonData);
            me.data.codiceReparto = me.queryJsonData.codreparto;
            me.data.reparto = me.queryJsonData.reparto;
            me.data.matricolaMedico = me.queryJsonData.matricolamedico;
            me.data.matricolaOperatore = me.queryJsonData.matricolaoperatore;
            me.data.nomePaziente = me.queryJsonData.nome;
            me.data.cognomePaziente = me.queryJsonData.cognome;
            me.data.dataNascitaPaziente = moment(me.queryJsonData.datanascita, "YYYY-MM-DD")
                .toDate()
                .getTime();
            me.data.descrizioneProvincia = me.queryJsonData.provincianascita;
            console.log(me.data.descrizioneProvincia);
            me.loadComuni(me.data.descrizioneProvincia);
            me.data.luogoNascitaPaziente = me.queryJsonData.comunenascita;
            console.log(me.data.luogoNascitaPaziente);
            if (me.queryJsonData.sesso !== "M" || me.queryJsonData.sesso !== "F") {
                me.data.sessoPaziente = null;
            }
            me.data.sessoPaziente = me.queryJsonData.sesso;

            me.data.cartellaClinica = me.queryJsonData.cartellaclinica;
            me.data.dataRicovero = moment(me.queryJsonData.dataricovero, "YYYY-MM-DD")
                .toDate()
                .getTime();
            if (me.queryJsonData.partiabortipreg !== "SI" || me.queryJsonData.partiabortipreg !== "NO") {
                me.data.partiAbortiPregressi = null;
            }
            me.data.partiAbortiPregressi = me.queryJsonData.partiabortipreg;

            me.data.dataUltimoAbortoParto = moment(me.queryJsonData.dataultimoabortoparto, "YYYY-MM-DD")
                .toDate()
                .getTime();
        },
        onClick() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathRestRichiesteScorte + "/";
            axios
                .get(link, { params: { tipologia: me.tipologiaRichiesta } })
                .then((response) => {
                    me.tabellaRichieste = response.data.data;
                    me.showModalLoading = false;
                })
                .catch((error) => {
                    me.showModalLoading = false;
                    me.$bvModal
                        .msgBoxOk(error.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxTwo = value;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
    },
};
</script>
