<template>
    <div style="margin-top: 10px">
        <b-row style="margin-bottom: 10px;">
            <b-col>
                <b-button variant="outline-primary sa-margin-right float-sm-right" size="sm" @click="onClickAggiungiEsame"><b-icon icon="plus"></b-icon>Aggiungi</b-button>
            </b-col>
        </b-row>
        <div class="b-table-sticky-header">
            <b-table sticky-header ref="table" responsive stacked="xl" striped hover :items="items" :fields="fieldsRichiesta" sort-icon-left head-variant="light" class="sa-b-table">
                <template v-slot:cell(dataRichiesta)="{ item }">
                    <router-link class="sa-edit-link" :to="'/accertamentisupplementaridettagli/edit/' + item.id + '?idPaziente=' + idPaziente">{{ formatDateTime(item.dataRichiesta) }}</router-link>
                </template>
                <template #cell(action)="row">
                    <b-button size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDeleteRiga(row.item)">
                        <b-icon icon="trash"></b-icon>
                    </b-button>
                </template>
            </b-table>
        </div>
        <div>
            <b-modal ref="mdlAggiungiEsame" id="mdlAggiungiEsame" title="Aggiungi Esame" size="xl" @ok="onOkMdlAggiungiEsame">
                <div class="sa-form-section">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <strong>Motivazione </strong><br />
                            <b-form-textarea v-model="data.motivazione" no-resize></b-form-textarea>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <strong>Data/Ora richiesta </strong><br />
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.dataRichiesta" type="timestamp" placeholder="Data Documento"></date-picker>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <strong>Esame Richiesto</strong><br />
                            <b-input-group>
                                <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="nuovaRiga.tipoEsame" :options="tipoEsameOptions" :value="null" value-field="tipoEsame" text-field="descrizione"></b-form-select>
                                <b-button size="sm" variant="btn btn-outline-info no-text" @click="onClickAggiungiRiga()">
                                    <b-icon icon="plus"></b-icon>
                                </b-button>
                            </b-input-group>
                        </b-col>
                    </b-row>
                    <div class="b-table-sticky-header" style="margin-top: 10px">
                        <b-table sticky-header ref="table" responsive stacked="xl" striped hover :items="data.visitaEsameSupplementare" :fields="fieldsRichiestaEsami" sort-icon-left head-variant="light" class="sa-b-table">
                            <template v-slot:cell(tipoEsame)="{ item }">
                                <a v-b-modal.mdlAggiungiEsame href="#" onclick="return false">{{ item.tipoEsame }} </a>
                            </template>
                        </b-table>
                    </div>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <strong>Conclusioni </strong><br />
                            <b-form-textarea v-model="data.conclusioni" no-resize></b-form-textarea>
                        </b-col>
                    </b-row>
                </div>
            </b-modal>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import axios from "axios";
import DatePicker from "vue2-datepicker";
export default {
    components: { DatePicker },
    props: {
        data: {
            Type: Object,
            default: function() {
                return {
                    motivazione: "",
                    dataRichiesta: Date.now(),
                    conclusioni: "",
                    idVisita: "",
                    visitaEsameSupplementare: [],
                };
            },
        },
    },
    mounted() {
        let me = this;
        me.data.idVisita = this.$route.params.id;
        me.idPaziente = this.$route.query.idPaziente;
    },
    data() {
        return {
            pathResource: "/visiterichiestaesamisupplementari",
            nuovaRiga: { tipoEsame: null, pdf: null, updateDate: null, referto: "", statoAttuale: "", statoAttualeDataOra: Date.now(), note: "" },
            // visitaEsameSupplementare: [],
            items: [],
            fieldsRichiesta: [
                {
                    label: "Richiesta",
                    key: "dataRichiesta",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD/MM/YYYY HH:mm");
                        } else {
                            return "";
                        }
                    },
                    sortable: true,
                },
                {
                    label: "Stato Attuale",
                    key: "statoAttuale",
                    thStyle: "width: 8rem",
                    sortable: true,
                },
                {
                    label: "Stato Attuale Data/Ora",
                    key: "statoAttualeDataOra",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD/MM/YYYY HH:mm");
                        } else {
                            return "";
                        }
                    },
                    thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: "",
                    key: "action",
                    thStyle: "width: 5rem",
                },
            ],
            fieldsRichiestaEsami: [
                {
                    label: "Esame",
                    key: "tipoEsame",
                    sortable: true,
                },
                {
                    label: "Data/Ora richiesta",
                    key: "updateDate",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD/MM/YYYY HH:mm");
                        } else {
                            return "";
                        }
                    },
                    thStyle: "width: 5rem",
                    sortable: true,
                },
            ],
            tipoEsameOptions: [
                { tipoEsame: null, descrizione: "-Seleziona Esame-" },
                { tipoEsame: "ECOCARDIOGRAFIA COLOR-DOPPLER", descrizione: "ECOCARDIOGRAFIA COLOR-DOPPLER" },
                { tipoEsame: "ECG DINAMICO DELLE 24H SECONDO HOLTER CON SEDUTA DI ALLENAMENTO", descrizione: "ECG DINAMICO DELLE 24H SECONDO HOLTER CON SEDUTA DI ALLENAMENTO" },
                { tipoEsame: "ECG DA SFORZO AL CICLOERGOMETRO", descrizione: "ECG DA SFORZO AL CICLOERGOMETRO" },
                { tipoEsame: "RISONANZA MAGNETICA CARDIACA", descrizione: "RISONANZA MAGNETICA CARDIACA" },
                { tipoEsame: "RISONANZA MAGNETICA CARDIACA CON MDC", descrizione: "RISONANZA MAGNETICA CARDIACA CON MDC" },
                { tipoEsame: "STUDIO ELETTROFISIOLOGICO", descrizione: "STUDIO ELETTROFISIOLOGICO" },
            ],
        };
    },
    methods: {
        onClickAggiungiEsame() {
            let me = this;
            me.nuovaRiga = { tipoEsame: null, pdf: null, updateDate: null, referto: "", statoAttuale: "", statoAttualeDataOra: Date.now(), note: "" };
            me.$refs.mdlAggiungiEsame.show();
        },
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "?idVisita=" + me.data.idVisita;
            axios
                .get(link)
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data;
                })
                .catch(() => {});
        },
        onOkMdlAggiungiEsame() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            let jsonData = JSON.stringify(me.data);
            axios
                .post(link, jsonData)
                .then(() => {
                    // me.data = response.data.data;
                    me.loadData();
                })
                .catch((error) => {
                    me.$refs["sa-modal-loading"].close();
                    this.$bvModal.msgBoxOk(error.response.data.messaggio, {
                        title: [this.$i18n.t("agenda.lblEditTitle")],
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    });
                })
                .then((value) => {
                    this.botTwo = value;
                })
                .catch(() => {});
        },
        formatDateTime(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            } else {
                return "---";
            }
        },
        onDeleteRiga(row) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + row.id;
            axios
                .delete(link)
                .then(() => {
                    me.loadData();
                })
                .catch((e) => {
                    alert(e);
                });
        },
        onClickAggiungiRiga() {
            let me = this;
            me.data.visitaEsameSupplementare.push(me.nuovaRiga);
            me.nuovaRiga = { tipoEsame: null, pdf: null, updateDate: null, referto: "", statoAttuale: "", statoAttualeDataOra: Date.now(), note: "" };
            console.log(me.data.visitaEsameSupplementare);
        },
    },
};
</script>
