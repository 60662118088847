<template>
  <div class="sa-page">
    <div class="content-header">
      <b-row>
        <b-col>
          <div style="float: left; margin-top: 0rem; width: 100%;">
            <span style="margin-left: 0.5rem; float: left;"> </span>
          </div>
          <template class="toolbar-title">
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
          </template>
        </b-col>
        <b-col class="sa-col-toolbar">
          <div style="float: right">
            <b-button variant="success btn-toolbar float-sm-right sa-margin-right" size="sm" @click="onAssocia"><b-icon icon="plus"></b-icon>{{ this.$i18n.t("patient.btnAssociate") }}</b-button>
            <b-button class="float-sm-right " variant="info btn-toolbar" size="sm" @click="onRefresh"><b-icon icon="arrow-clockwise"></b-icon>Refresh</b-button>
          </div>
        </b-col>
      </b-row>
      <template class="toolbar-row">
        <paziente-dashboard-component :idPaziente="idPaziente"></paziente-dashboard-component>
      </template>
    </div>
    <b-card>
      <div class="content">
        <div class="sa-table">
          <template class="table-header"></template>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                  <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                  <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <template class="table-body">
            <b-table :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" striped responsive="sm">
              <template #cell(kitId)="row">
                {{ row.item.kitId.toUpperCase() }}
              </template>
              <template #cell(hubName)="row">
                {{ row.item.hubName.toUpperCase() }}
              </template>
              <template #cell(show_details)="row">
                <b-button size="sm" @click="row.toggleDetails" class="mr-2"> {{ row.detailsShowing ? "Hide" : "Show" }} Details</b-button>
              </template>
              <template #cell(delete)="row">
                <b-button variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" size="sm" @click="onDeleteItem(row.item)"><b-icon icon="trash"></b-icon></b-button>
              </template>
              <template #row-details="row">
                <b-card>
                  <div class="container-fluid">
                    <b-row class="mb-4">
                      <b-col cols="4" sm="4"
                        ><b>Temperature :</b><br />
                        {{ row.item.temperatureName.toUpperCase() }}</b-col
                      >
                      <b-col cols="4" sm="4"
                        ><b>Glucose :</b><br />
                        {{ row.item.glucoseName.toUpperCase() }}</b-col
                      >
                      <b-col cols="4" sm="4"
                        ><b>Ecg :</b><br />
                        {{ row.item.ecgName.toUpperCase() }}</b-col
                      >
                    </b-row>
                    <hr />
                    <b-row class="mb-2">
                      <b-col cols="4" sm="4"
                        ><b>Spo2 :</b><br />
                        {{ row.item.spo2Name.toUpperCase() }}</b-col
                      >
                      <b-col cols="4" sm="4"
                        ><b>Pressure :</b><br />
                        {{ row.item.pressureName.toUpperCase() }}</b-col
                      >
                      <b-col cols="4" sm="4"
                        ><b>Glucose :</b><br />
                        {{ row.item.braceletName.toUpperCase() }}</b-col
                      >
                    </b-row>
                  </div>
                </b-card>
              </template>
            </b-table>
          </template>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import PazienteAnagrafica from "./PazienteAnagrafica.vue";
import axios from "axios";
import PazienteDashboardComponent from "./PazienteDashboardComponent.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  components: {
    PazienteAnagrafica,
    PazienteDashboardComponent,
  },
  mounted() {
    let me = this;
    me.loadData();
  },
  data() {
    return {
      linkedit: "/paziente/hassistokits",
      pathResource: "/hassistokits/patient",
      pathResourceKit: "/hassistokits/dissociatekit",
      fields: [
        {
          label: "",
          key: "index",
          thStyle: "width: 1rem",
          tdClass: "text-center",
        },
        {
          label: "Kit",
          key: "kitId",
        },
        {
          label: "Hub Name",
          key: "hubName",
        },
        {
          label: "",
          key: "show_details",
          tdClass: "text-center",
        },
        {
          label: "",
          key: "delete",
          tdClass: "text-center",
        },
      ],
      items: [],
      perPage: 10,
      currentPage: 1,
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    rows() {
      return this.items.length;
    },
    linkPatient: {
      get: function() {
        let returnValue = "";
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length > 1) {
              return "/paziente/view/" + this.idPaziente;
            } else {
              if (this.utente.gruppo.menuGruppo.length == 1) {
                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                  return "/paziente/view/" + this.idPaziente;
                } else {
                  return "/paziente/homepaziente";
                }
              }
            }
          }
        }
        return returnValue;
      },
    },
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "?idPaziente=";
      axios.get(link + me.idPaziente).then((response) => {
        me.items = response.data.data;
        // console.log(me.items);
      });
    },
    onDeleteItem(item) {
      let me = this;
      this.$bvModal
        .msgBoxConfirm("Sei sicuro di procedere alla disassociazione del Kit?", {
          title: "Conferma disassociazione",
          buttonSize: "sm",
          okVariant: "secondary",
          cancelVariant: "danger",
          okTitle: "SI",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            me.deleteItem(item);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteItem(item) {
      let me = this;
      console.log(item);
      let link = process.env.VUE_APP_PATH_API + me.pathResourceKit;
      axios
        .put(link, { kitId: item.kitId })
        .then(() => {
          me.modalShow = false;
          me.loadData();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onRefresh() {
      let me = this;
      console.log(me.items);
      if (me.items !== []) {
        me.loadData();
      } else {
        console.log("gia riempito");
      }
    },
    onAssocia() {
      let me = this;
      me.$router.replace(me.linkedit + "/edit/-1").catch((err) => {
        err;
      });
    },
  },
};
</script>
