<template>
  <div>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblFamilyAnamnesis") }}</span>
        <b-form-textarea rows="4" max-rows="9" v-model="data.anamnesiFamiliare" :disabled="!edit"></b-form-textarea>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblPhysiologicalAnamnesis") }}</span>
        <b-form-textarea rows="4" max-rows="9" v-model="data.anamnesiFisiologica" :disabled="!edit"></b-form-textarea>
      </b-col>
    </b-row>
    <b-row
      ><b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"
        ><span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblPathologicalAnamnesis") }}</span> <b-form-textarea rows="4" max-rows="9" v-model="data.anamnesiPatologica" :disabled="!edit"></b-form-textarea></b-col
      ><b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblSurgicalInterventions") }}</span>
        <b-form-textarea rows="4" max-rows="9" v-model="data.interventiChirurgici" :disabled="!edit"></b-form-textarea></b-col
    ></b-row>
    <b-row
      ><b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblInjuries") }}</span>
        <b-form-textarea rows="4" max-rows="9" v-model="data.infortuni" :disabled="!edit"></b-form-textarea
      ></b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblSportAnamnesis") }}</span>
        <b-form-textarea rows="4" max-rows="9" v-model="data.anamnesiSportiva" :disabled="!edit"></b-form-textarea
      ></b-col>
    </b-row>
  </div>
</template>

<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";

export default {
  mixins: [UtilityMixin],
  props: ["data", "edit"],
  data() {
    return {};
  },
};
</script>
