<template>
  <sa-page-layout :showModalLoading="showModalLoading" :btnEditVisible="true" :btnBackVisible="true" :linkedit="linkedit" :linkback="linkback" :pathResource="pathResource" :data="jsonData">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-header">
      <!-- <h5 class="sa-text-left">{{ this.$i18n.t("pazienti.egds.lblData") }}</h5> -->
    </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <div class="sa-form-section">
          <b-row>
            <b-col>
              <b-tabs content-class="mt-3" align="center" active-nav-item-class="font-weight-bold text-uppercase text-danger" justified>
                <b-tab :title="titleTabDati" active>
                  <b-row>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <strong>{{ this.$i18n.t("pazienti.egds.lblInstrument") }}</strong>
                      <br />
                      {{ jsonData.strumento }}
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <strong>{{ this.$i18n.t("pazienti.egds.lblWashingDisinfection") }}</strong>
                      <br />
                      {{ jsonData.lavaggioDisinfezione }}
                    </b-col>
                  </b-row>
                  <hr />
                  <b-row>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <strong>{{ this.$i18n.t("pazienti.egds.lblTherapiesInPlace") }}</strong>
                      <br />
                      {{ jsonData.terapieInAtto }}
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <strong>{{ this.$i18n.t("pazienti.egds.lblIndications") }}</strong>
                      <br />
                      {{ jsonData.indicazione }}
                    </b-col>
                  </b-row>
                  <!-- <hr style="border-top: 1px solid #8fb8c7;" /> -->
                  <hr />
                  <b-row>
                    <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                      <strong>{{ this.$i18n.t("pazienti.egds.lblEsophagus") }}</strong>
                      <br />
                      {{ jsonData.esofago }}
                    </b-col>
                  </b-row>
                  <hr />
                  <b-row>
                    <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                      <strong>{{ this.$i18n.t("pazienti.egds.lblStomach") }}</strong>
                      <br />
                      {{ jsonData.stomaco }}
                    </b-col>
                  </b-row>
                  <hr />
                  <b-row>
                    <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                      <strong>{{ this.$i18n.t("pazienti.egds.lblDuodenumFasting") }}</strong>
                      <br />
                      {{ jsonData.duodenoDigiuno }}
                    </b-col>
                  </b-row>
                  <hr />
                  <b-row>
                    <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                      <strong>{{ this.$i18n.t("pazienti.egds.lblConclusions") }}</strong>
                      <br />
                      {{ jsonData.conclusioni }}
                    </b-col>
                  </b-row>
                  <hr />
                  <b-row>
                    <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                      <strong>{{ this.$i18n.t("pazienti.egds.lblHpTest") }}</strong>
                      <br />
                      {{ jsonData.helicobacterPilori }}
                    </b-col>
                  </b-row>
                </b-tab>
                <b-tab :title="titleTabImg"><immagini-component></immagini-component></b-tab>
                <b-tab :title="titleTabAllegati">Tab Allegati </b-tab>
              </b-tabs>
            </b-col>
          </b-row>
        </div>
      </div>

      <hr />
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../../components/PazienteAnagrafica.vue";
import ImmaginiComponent from "../../../components/ImmaginiComponent.vue";
export default {
  components: { SaPageLayout, PazienteAnagrafica, ImmaginiComponent },
  props: {
    utente: {
      type: Object,
      default: function() {
        return { gruppo: { menuGruppo: [] } };
      },
    },
  },
  data() {
    return {
      titleTabAllegati: this.$i18n.t("pazienti.egds.Allegati"),
      titleTabImg: this.$i18n.t("pazienti.egds.Image"),
      titleTabDati: this.$i18n.t("pazienti.egds.lblData"),
      linkedit: null,
      linkback: null,
      pathResource: "/gastroegds",
      jsonData: {},
      showModalLoading: false,
    };
  },
  mounted() {
    let me = this;
    me.jsonData.idPaziente = this.$route.query.idPaziente;
    me.jsonData.tipoEvento = "Gastroenterologia - EGDS";
    me.id = this.$route.params.id;
    me.linkedit = "/paziente/gastroenterologia/egds/edit/" + me.id + "?idPaziente=" + me.idPaziente;
    me.linkback = "/paziente/gastroenterologia/egds?idPaziente=" + me.idPaziente;
    me.loadData();
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient: {
      get: function() {
        if (this.utente.gruppo.menuGruppo) {
          if (this.utente.gruppo.menuGruppo.length > 1) {
            return "/paziente/view/" + this.idPaziente;
          } else {
            if (this.utente.gruppo.menuGruppo.length == 1) {
              if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                return "/paziente/view/" + this.idPaziente;
              } else {
                return "/paziente/homepaziente";
              }
            } else {
              return "";
            }
          }
        } else {
          return "";
        }
      },
    },
  },
  methods: {
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        });
      }
    },
  },
};
</script>
