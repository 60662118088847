<template>
    <div style="margin-top: 10px">
        <div class="b-table-sticky-header">
            <b-table sticky-header ref="table" responsive stacked="xl" striped hover :items="items" :fields="fieldsRichiesta" sort-icon-left head-variant="light" class="sa-b-table">
                <template v-slot:cell(dataRichiesta)="{ item }">
                    <router-link class="sa-edit-link" :to="'/accertamentisupplementaridettagli/edit/' + item.id + '?idPaziente=' + idPaziente">{{ formatDateTime(item.dataRichiesta) }}</router-link>
                </template>
            </b-table>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import axios from "axios";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    props: {
        data: {
            Type: Object,
            default: function() {
                return {
                    motivazione: "",
                    dataRichiesta: Date.now(),
                    conclusioni: "",
                    idVisita: "",
                    visitaEsameSupplementare: [],
                };
            },
        },
    },
    mounted() {
        let me = this;
        me.data.idVisita = this.$route.params.id;
        me.idPaziente = this.$route.query.idPaziente;
    },
    data() {
        return {
            currentPage: 1,
            perPage: 10,
            pathResource: "/visiterichiestaesamisupplementari",
            items: [],
            fieldsRichiesta: [
                {
                    label: "Richiesta",
                    key: "dataRichiesta",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD/MM/YYYY HH:mm");
                        } else {
                            return "";
                        }
                    },
                    sortable: true,
                },
                {
                    label: "Stato Attuale",
                    key: "statoAttuale",
                    thStyle: "width: 8rem",
                    sortable: true,
                },
                {
                    label: "Stato Attuale Data/Ora",
                    key: "statoAttualeDataOra",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD/MM/YYYY HH:mm");
                        } else {
                            return "";
                        }
                    },
                    thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 5rem",
                },
            ],
        };
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "?idVisita=" + me.data.idVisita;
            axios
                .get(link)
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data;
                })
                .catch(() => {});
        },
    },
};
</script>
