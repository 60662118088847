<template>
  <div>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"
        ><span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblDateVisit") }}</span> <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.dataEvento" :disabled="!edit" type="date"></date-picker></b-col
      ><b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"
        ><span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblNextVisit") }}</span>
        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.prossimaVisita" :disabled="!edit" type="date"></date-picker>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblSportPracticed") }}</span>
        <b-form-input v-model="data.sportPraticato" :disabled="!edit"></b-form-input
      ></b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblHourNextVisit") }}</span>
        <date-picker format="HH:mm" value-type="timestamp" v-model="data.oraAppuntamento" :disabled="!edit" type="time"></date-picker
      ></b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblFollowingVisit") }}</span>
        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.visitaSuccessiva" :disabled="!edit" type="date"></date-picker
      ></b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblYearsNumber") }}</span>
        <b-form-input v-model="data.numeroAnni" :disabled="!edit" type="number"></b-form-input
      ></b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblWeeklyWorkouts") }}</span>
        <b-form-input v-model="data.allenamentiSettimana" :disabled="!edit" type="number"></b-form-input
      ></b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblHoursWorkout") }}</span>
        <b-form-input v-model="data.oreAllenamento" :disabled="!edit" type="number"></b-form-input
      ></b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblSpeciality") }}</span>
        <b-form-input v-model="data.specialita" :disabled="!edit"></b-form-input
      ></b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblCorporation") }}</span>
        <b-form-input v-model="data.societa" :disabled="!edit"></b-form-input
      ></b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblFederation") }}</span>
        <b-form-input v-model="data.federazione" :disabled="!edit"></b-form-input
      ></b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblLHB") }}</span>
        <b-form-input v-model="data.aslNew" :disabled="!edit"></b-form-input
      ></b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblOtherSports") }}</span>
        <b-form-input v-model="data.altriSport" :disabled="!edit"></b-form-input
      ></b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblMenarche") }}</span>
        <b-form-input v-model="data.menarca" :disabled="!edit"></b-form-input
      ></b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblAntitetanus") }}</span>
        <b-form-input v-model="data.antitetanica" :disabled="!edit"></b-form-input
      ></b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblSmoking") }}</span>
        <b-form-input v-model="data.fumo" :disabled="!edit"></b-form-input
      ></b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblAlcohol") }}</span>
        <b-form-input v-model="data.alcool" :disabled="!edit"></b-form-input
      ></b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblCoffee") }}</span>
        <b-form-input v-model="data.caffe" :disabled="!edit"></b-form-input
      ></b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblNarcoticSubstances") }}</span>
        <b-form-input v-model="data.sostenzeStupefacenti" :disabled="!edit"></b-form-input
      ></b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblMilitary") }}</span>
        <b-form-input v-model="data.militare" :disabled="!edit"></b-form-input
      ></b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblMilitaryMotif") }}</span>
        <b-form-input v-model="data.militareMotivo" :disabled="!edit"></b-form-input
      ></b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"></b-col>
    </b-row>
    <div style="height: 30px;"></div>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"
        ><span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblIdentityDocument") }}</span> <b-form-input v-model="data.documentoIdentitaTipo" :disabled="!edit"></b-form-input>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblIdentityDocumentReleaser") }}</span
        ><b-form-input v-model="data.documentoIdentitaRilasciatoDa" :disabled="!edit"></b-form-input>
      </b-col> </b-row
    ><b-row>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"
        ><span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblIdentityDocumentNumber") }}</span> <b-form-input v-model="data.documentoIdentitaNumero" :disabled="!edit"></b-form-input>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblIdentityDocumentDateRelease") }}</span>
        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.documentoIdentitaRilasciatoIl" :disabled="!edit" type="date"></date-picker
      ></b-col>
    </b-row>
    <b-form-group> </b-form-group>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import UtilityMixin from "../../../../../utility/UtilityMixin";

export default {
  mixins: [UtilityMixin],
  props: ["data", "edit"],
  components: { DatePicker },
};
</script>
