<template>
    <div class="login-box">
        <div class="login-logo">
            <img src="../../assets/images/logo_login.png" alt="Logo" class="sa-logo-esteso" />
        </div>
        <!-- /.login-logo -->
        <div class="card">
            <div class="card-body login-card-body sa-box-login">
                <p class="login-box-msg">{{ $t("login.welcome") }}</p>
                <form @submit.prevent="onSubmit()">
                    <label for="emailaddress">{{ $t("login.lblUsername") }}</label>
                    <div class="input-group mb-3">
                        <b-input class="form-control" :placeholder="$t('login.plcUsername')" name="client_id" v-model="form.client_id" autocomplete="off" />
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <font-awesome-icon icon="user" />
                            </div>
                        </div>
                    </div>
                    <label for="emailaddress">{{ $t("login.lblPassword") }}</label>

                    <div class="input-group mb-3">
                        <b-input type="password" class="form-control" :placeholder="$t('login.plcPassword')" name="client_secret" v-model="form.client_secret" />
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <font-awesome-icon icon="lock" />
                                <!--<span class="fas fa-lock"></span>-->
                            </div>
                        </div>
                    </div>
                    <label for="emailaddress">{{ $t("login.lblLanguage") }}</label>
                    <div class="input-group mb-3 ">
                        <span style="margin-top: 5px" class="align-middle"><country-flag :country="getFlag()" size="normal" class="align-middle"/></span>
                        <b-form-select @change="onChange" v-model="locale" :options="locales" class="mb-2"></b-form-select>
                    </div>
                    <br />
                    <div class="row">
                        <!-- /.col -->
                        <div class="col-4"></div>
                        <!-- /.col -->
                    </div>
                    <div class="social-auth-links text-center mb-3">
                        <b-button type="submit" v-b-modal.modal-loading variant="btn btn-login btn-block"> <font-awesome-icon icon="sign-in-alt" />&nbsp;&nbsp;&nbsp;Accedi </b-button>
                    </div>
                </form>
                <!-- /.social-auth-links -->
                <p class="mb-1" style="text-align: center;"><font-awesome-icon icon="lock" /> <a class="sa-lnk" v-b-modal.modal-prevent-closing>Reset Password</a></p>
                <!--<p class="mb-0">
            <a href="register.html" class="text-center">Register a new membership</a>
        </p>-->
            </div>
            <!-- /.login-card-body -->
            <!-- <div>
                <p style="text-align: center; margin-top: 20px;">Per problemi di accesso mandare una email a: <a href="mailto:credenziali.refertidip@aocardarelli.it" class="sa-gesan-link">credenziali.refertidip@aocardarelli.it</a></p>
                <p style="text-align: center; margin-top: 20px;">2021 © Sorveglianza Sanitaria powered by <a href="https://www.gesan.it" target="_blank" class="sa-gesan-link">Gesan S.r.l.</a></p>
            </div> -->
            <div>
                <p style="text-align: center; margin-top: 20px;">2021 © Hyper Health powered by <a href="https://www.gesan.it" target="_blank" class="sa-gesan-link">Gesan S.r.l.</a></p>
            </div>
            <!-- <div>
                <p style="text-align: center; margin-top: 20px;">2022 © Leonida powered by <a href="https://www.gesan.it" target="_blank" class="sa-gesan-link">Gesan S.r.l.</a></p>
            </div> -->
        </div>
        <sa-modal-loading ref="sa-modal-loading" />

        <div>
            <b-modal id="modal-prevent-closing" ref="modal" title="Inserisci il tuo indirizzo email" @show="resetModal" @hidden="resetModal" @ok="onOk">
                <form ref="form" @submit.stop.prevent="onResetPasswordSubmit">
                    <b-form-group label="Email" label-for="email-input" invalid-feedback="L'indirizzo email è obbligatorio" :state="emailState">
                        <b-form-input placeholder="Email" id="email-input" v-model="email" :state="emailState" required> </b-form-input>
                    </b-form-group>
                </form>
            </b-modal>
        </div>
        <div>
            <b-modal ref="mdlCambiaPassword" no-close-on-backdrop centered :title="titolo" hide-footer>
                <form ref="form" @submit.prevent="onCambiaPasswordSubmit">
                    <b-form-group label="Vecchia Password" label-for="email-input" invalid-feedback="L'indirizzo email è obbligatorio">
                        <b-form-input placeholder="Vecchia Password" type="password" id="email-input" v-model="nuoveCredenziali.oldPassword" required> </b-form-input>
                    </b-form-group>
                    <b-form-group label="Nuova Password" label-for="email-input" invalid-feedback="L'indirizzo email è obbligatorio">
                        <b-form-input placeholder="Nuova Password" type="password" id="email-input" v-model="nuoveCredenziali.newPassword" required> </b-form-input>
                    </b-form-group>
                    <b-form-group label="Verifica Password" label-for="email-input" invalid-feedback="L'indirizzo email è obbligatorio">
                        <b-form-input placeholder="Verifica Password" type="password" id="email-input" v-model="nuoveCredenziali.verificaPassword" required> </b-form-input>
                    </b-form-group>
                    <b-row>
                        <b-col cols="12" sm="12" class="sa-text-right"><b-button size="sm" variant="outline-secondary" type="submit"> Aggiorna Password </b-button></b-col>
                    </b-row>
                </form>
            </b-modal>
        </div>
    </div>

    <!-- /.login-box -->
</template>

<script>
import qs from "qs";
import axios from "axios";
import router from "../../router";
import { SaModalLoading } from "../../components/modal";
import AuthBg from "../../assets/images/sfondo.jpg";

export default {
    components: { SaModalLoading },
    meta: { bgImage: AuthBg },
    data() {
        return {
            locale: this.$i18n.locale,
            flag: this.$i18n.locale,
            locales: [
                { value: "en", text: "English (en)" },
                { value: "it", text: "Italiano (it)" },
                { value: "hr", text: "Hrvatski (hr)" },
                { value: "sv", text: "Swedish (sv)" },
                { value: "tr", text: "Turkish (tr)" },
            ],
            pathResource: "/users/resetpassword",
            form: {
                client_id: "",
                client_secret: "",
            },
            show: true,
            emailState: false,
            email: "",
            nuoveCredenziali: {},
            titolo: "",
        };
    },
    mounted() {
        document.body.classList.add("hold-transition");
        document.body.classList.add("login-page");
        document.body.style.backgroundImage = "url(" + AuthBg + ")";
    },
    destroyed() {
        document.body.classList.remove("hold-transition");
        document.body.classList.remove("login-page");
    },
    methods: {
        getFlag() {
            let me = this;

            if (me.locale === "en") {
                return "gb";
            } else if (me.locale === "sv") {
                return "se";
            } else {
                return me.locale;
            }
        },
        onChange(lang) {
            this.$router.push({ path: this.$route.path, query: { ...this.$route.query, language: lang } });
            this.$router.go();
        },
        onSubmit() {
            let me = this;
            let unencodedToken = me.form.client_id + ":" + me.form.client_secret;
            let encodedToken = "Basic " + btoa(unencodedToken);
            const config = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: encodedToken,
                },
            };
            let uri = process.env.VUE_APP_PATH_API + "/token";
            axios
                .post(
                    uri,
                    qs.stringify({
                        grant_type: "client_credentials",
                        client_id: me.form.client_id,
                        client_secret: me.form.client_secret,
                    }),
                    config
                )
                .then((response) => {
                    me.$refs["sa-modal-loading"].close();
                    localStorage.setItem("loginData", JSON.stringify(response.data));
                    router.push({ name: "Home", query: { language: me.locale, authToken: response.data.access_token } });
                })
                .catch((error) => {
                    me.$refs["sa-modal-loading"].close();
                    if (error.response.data.data) {
                        let tipoErrore = error.response.data.data.tipoErrore;
                        switch (tipoErrore) {
                            case "PASSWORD_EXPIRED":
                                me.titolo = "Password Scaduta";
                                me.nuoveCredenziali.username = me.form.client_id;
                                me.$refs.mdlCambiaPassword.show();
                                break;
                            case "PASSWORD_TEMPORANY":
                                me.nuoveCredenziali.username = me.form.client_id;
                                me.titolo = "Password Temporanea, si prega di cambiarla";
                                me.$refs.mdlCambiaPassword.show();
                                break;
                            case "GENERATE_PASSWORD":
                                this.$bvModal
                                    .msgBoxOk("E' stata inviata alla sua email una Password Temporanea", {
                                        title: this.$i18n.t("login.lblAttention"),
                                        size: "sm",
                                        okVariant: "outline-danger",
                                        headerClass: "sa-msg-header-danger",
                                        footerClass: "p-2 border-top-0",
                                        centered: true,
                                    })
                                    .then((value) => {
                                        this.boxTwo = value;
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                    });
                                break;
                        }
                    } else {
                        let messaggio = this.$i18n.t("login.lblUnknownError");
                        if (error.response.data.messaggio) {
                            messaggio = error.response.data.messaggio;
                        }
                        this.$bvModal
                            .msgBoxOk(messaggio, {
                                title: this.$i18n.t("login.lblAttention"),
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                });
        },

        checkFormValidity() {
            const valid = this.$refs.form.checkValidity();
            this.emailState = valid;
            return valid;
        },
        resetModal() {
            this.email = "";
            this.emailState = null;
        },
        onOk(bvModalEvt) {
            bvModalEvt.preventDefault();
            if (!this.checkFormValidity()) {
                return;
            }
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "?email=" + me.email;
            axios
                .put(link, {})
                .then((response) => {
                    me.$refs["sa-modal-loading"].close();
                    console.log(response.data.messaggio);
                    this.$bvModal
                        .msgBoxOk(response.data.messaggio, {
                            title: "INFO",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxTwo = value;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                    this.$bvModal.hide("modal-prevent-closing");
                })
                .catch((error) => {
                    console.log("ERROR");
                    console.log(error.response.data.messaggio);
                    me.$refs["sa-modal-loading"].close();
                    this.$bvModal.msgBoxOk(error.response.data.messaggio, {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    });
                });
        },
        onResetPasswordSubmit() {
            //this.submittedEmail.push(this.email)
            this.$nextTick(() => {
                this.$bvModal.hide("modal-prevent-closing");
            });
        },
        onCambiaPasswordSubmit() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + "/users/cambiapassword";
            axios.defaults.headers["Content-Type"] = "application/json";
            axios
                .put(link, JSON.stringify(me.nuoveCredenziali))
                .then(() => {
                    this.$bvToast.toast("Operazione avvenuta correttamente!", {
                        title: "",
                        variant: "success",
                        solid: true,
                    });
                    me.$refs.mdlCambiaPassword.hide();
                })
                .catch((error) => {
                    me.showmolalloading = false;
                    this.$bvModal
                        .msgBoxOk(error.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxTwo = value;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
    },
};
</script>
<style lang="css">
a {
    /*color: #ac1f3a !important;*/
    text-decoration: none !important;
    /* background-color: transparent !important; */
    font-weight: 600;
}

.login-page {
    /*background-image: url("../../assets/images/sfondo.jpg");*/
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.login-page,
.register-page {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100vh;
    -ms-flex-pack: center;
    justify-content: center;
}

.login-box,
.register-box {
    width: 468px;
}

.sa-logo-esteso {
    width: 100%;
}
.card {
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
    margin-bottom: 1rem;
}

.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
}

.sa-lnk {
    color: #8fb8c7;
}

.btn-login.btn:focus,
.btn-login.btn.focus {
    outline: 0;
    box-shadow: none;
}
.sa-box-login {
    box-shadow: 0 8px 10px 1px rgba(153, 153, 153, 0.14), 0 3px 14px 2px rgba(221, 220, 220, 0.12), 0 5px 5px -3px rgba(247, 247, 247, 0.3);
    /*box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.3);*/
    border-radius: 0.5rem;
}

@media only screen and (min-width: 0) {
    .login-box,
    .register-box {
        width: 268px;
    }
}

@media only screen and (min-width: 768px) {
    .login-box,
    .register-box {
        width: 368px;
    }
}
</style>
