<template>
    <b-modal :static="true" ref="modal-loading" id="modal-loading" hide-footer hide-header no-close-on-esc no-close-on-backdrop>
        <div class="text-center">
            <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner" variant="info"></b-spinner>
        </div>

        <p class="my-4" style="text-align: center">Caricamento dati in corso...</p>
    </b-modal>
</template>

<script>
export default {
    name: "ModalLoading",
    data() {
        return {};
    },
    methods: {
        close() {
            let me = this;
            me.$refs["modal-loading"].hide();
        },
        show() {
            let me = this;
            me.$refs["modal-loading"].show();
        },
    },
};
</script>

<style></style>
