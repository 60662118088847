<template>
  <sa-page-layout :btnSaveVisible="true" :btnAnnullaVisible="true" :linkback="linkback" :pathResource="pathResource" :data="data" :showModalLoading="showModalLoading">
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <div class="sa-form-section">
          <h5 class="sa-text-left">{{ this.$i18n.t("patients.lblPatientRegistry") }}</h5>
          <b-row>
            <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
              <span class="sa-form-label">{{ this.$i18n.t("patients.lblLastname") }}</span
              ><span style="color: red;">*</span>
              <b-form-input v-model="data.cognome" required :state="data.cognome !== '' ? true : false"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
              <span class="sa-form-label">{{ this.$i18n.t("patients.lblName") }}</span
              ><span style="color: red;">*</span>
              <b-form-input v-model="data.nome" required :state="data.nome !== '' ? true : false"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
              <span class="sa-form-label">{{ this.$i18n.t("patients.lblFiscalCode") }}</span
              ><span style="color: red;">*</span>
              <b-input-group class="mb-3">
                <!-- <b-form-input maxlength="16" class="text-uppercase" v-model="data.codiceFiscale" @keypress="onKeyPress"></b-form-input> -->
                <b-form-input maxlength="16" class="text-uppercase" v-model="data.identificativo" required :state="data.identificativo.length > 15 ? true : false"></b-form-input>
                <!-- <b-button size="sm" variant="" @click="onClick"> <b-icon icon="search"></b-icon> </b-button> -->
              </b-input-group>
            </b-col>
            <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
              <span class="sa-form-label">{{ this.$i18n.t("patients.lblBirthDate") }}</span
              ><span style="color: red;">*</span>
              <div id="date-picker-1" class="red-border">
                <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="data.dataNascita" type="date" required @change="check(data.dataNascita, 'date-picker-1')"></date-picker>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
              <span class="sa-form-label">{{ this.$i18n.t("patients.lblProvince") }}</span
              ><span style="color: red;">*</span>
              <b-form-select id="inline-form-custom-select-pref" v-model="data.provinciaNascita" class="mb-2 mr-sm-2 mb-sm-0" :options="provinciaNascitaOption" :value="null" @change="loadComuneNascita(data.provinciaNascita)" required :state="data.provinciaNascita !== null ? true : false">
                <b-form-select-option :value="null" disabled>{{ selectOptionProvincia }}</b-form-select-option>
              </b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
              <span class="sa-form-label">{{ this.$i18n.t("patients.lblCity") }}</span
              ><span style="color: red;">*</span>
              <b-form-select id="inline-form-custom-select-pref" v-model="data.comuneNascitaCodiceIstat" class="mb-2 mr-sm-2 mb-sm-0" :options="comuneNascitaOption" :value="null" required :state="data.comuneNascitaCodiceIstat !== null ? true : false">
                <template #first>
                  <b-form-select-option :value="null" disabled>{{ selectOptionComune }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
              <span class="sa-form-label">{{ this.$i18n.t("patients.lblGender") }}</span
              ><span style="color: red;">*</span>
              <b-form-select id="inline-form-custom-select-pref" v-model="data.sesso" class="mb-2 mr-sm-2 mb-sm-0" :options="sessoOption" :value="null" required :state="data.sesso !== null ? true : false">
                <template #first>
                  <b-form-select-option :value="null" disabled>{{ selectOptionSesso }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
              <span class="sa-form-label">{{ this.$i18n.t("patients.lblBloodGroup") }}</span>
              <b-form-select id="inline-form-custom-select-pref" v-model="data.gruppoSanguigno" class="mb-2 mr-sm-2 mb-sm-0" :value="null" :options="gruppoSanguignoOption" required>
                <template #first>
                  <b-form-select-option :value="null" disabled>{{ selectOptionGruppoSanguigno }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
          </b-row>
        </div>
        <div class="sa-form-section">
          <h5 class="sa-text-left">{{ this.$i18n.t("patients.lblResidence") }}</h5>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <span class="sa-form-label">{{ this.$i18n.t("patients.lblAddress") }}</span
              ><span style="color: red;">*</span>
              <b-form-input v-model="data.indirizzoResidenza" required :state="data.indirizzoResidenza !== '' ? true : false"></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
              <span class="sa-form-label">{{ this.$i18n.t("patients.lblProvince") }}</span
              ><span style="color: red;">*</span>
              <b-form-select id="inline-form-custom-select-pref" v-model="data.provinciaResidenza" class="mb-2 mr-sm-2 mb-sm-0" :options="provinciaResidenzaOption" :value="null" @change="loadComuneResidenza(data.provinciaResidenza)" required :state="data.provinciaResidenza !== null ? true : false">
                <template #first>
                  <b-form-select-option :value="null" disabled>{{ selectOptionProvincia }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
              <span class="sa-form-label">{{ this.$i18n.t("patients.lblCity") }}</span
              ><span style="color: red;">*</span>
              <b-form-select id="inline-form-custom-select-pref" v-model="data.comuneResidenzaCodiceIstat" class="mb-2 mr-sm-2 mb-sm-0" :options="comuneResidenzaOption" :value="null" value-field="codiceIstat" text-field="comune" @change="setCapResidenza" required :state="data.comuneResidenzaCodiceIstat !== null ? true : false">
                <template #first>
                  <b-form-select-option :value="null" disabled>{{ selectOptionComune }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
              <span class="sa-form-label">{{ this.$i18n.t("patients.lblPostalCode") }}</span
              ><span style="color: red;">*</span>
              <b-form-input v-model="data.capResidenza" required :state="data.capResidenza !== null ? true : false"></b-form-input>
            </b-col>
          </b-row>
        </div>
        <div class="sa-form-section">
          <b-form-checkbox v-model="isDomicilioUgualeResidenza" name="check-button" switch>
            <h5 class="sa-text-left domicilio-residenza-checkbox">{{ this.$i18n.t("patient.lblIsDomicilioEqualResidenza") }}</h5>
          </b-form-checkbox>
        </div>
        <div class="sa-form-section" v-if="!isDomicilioUgualeResidenza">
          <h6 class="sa-text-left">{{ this.$i18n.t("patients.lblDomicile") }}</h6>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <span class="sa-form-label">{{ this.$i18n.t("patients.lblAddress") }}</span
              ><span style="color: red;">*</span>
              <b-form-input v-model="data.indirizzoDomicilio" :state="data.indirizzoDomicilio !== null && data.indirizzoDomicilio !== '' ? true : false"></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
              <span class="sa-form-label">{{ this.$i18n.t("patients.lblProvince") }}</span
              ><span style="color: red;">*</span>
              <b-form-select id="inline-form-custom-select-pref" v-model="data.provinciaDomicilio" class="mb-2 mr-sm-2 mb-sm-0" :options="provinciaDomicilioOption" @change="loadComuneDomicilio(data.provinciaDomicilio)" :state="data.provinciaDomicilio !== null ? true : false">
                <template #first>
                  <b-form-select-option :value="null" disabled>{{ selectOptionProvincia }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
              <span class="sa-form-label">{{ this.$i18n.t("patients.lblCity") }}</span
              ><span style="color: red;">*</span>
              <b-form-select id="inline-form-custom-select-pref" v-model="data.comuneDomicilioCodiceIstat" class="mb-2 mr-sm-2 mb-sm-0" :options="comuneDomicilioOption" :value="null" value-field="codiceIstat" text-field="comune" @change="setCapDomicilio" :state="data.comuneDomicilioCodiceIstat !== null ? true : false">
                <template #first>
                  <b-form-select-option :value="null" disabled>{{ selectOptionComune }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
              <span class="sa-form-label">{{ this.$i18n.t("patients.lblPostalCode") }}</span
              ><span style="color: red;">*</span>
              <b-form-input v-model="data.capDomicilio" :state="data.capDomicilio !== null ? true : false"></b-form-input>
            </b-col>
          </b-row>
        </div>
        <div class="sa-form-section">
          <h5 class="sa-text-left">{{ this.$i18n.t("patients.lblContacts") }}</h5>
          <b-row>
            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
              <span class="sa-form-label">{{ this.$i18n.t("patients.lblTelephoneNumber") }}</span
              ><span style="color: red;">*</span>
              <b-form-input v-model="data.telefono" required :state="data.telefono !== '' ? true : false"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
              <span class="sa-form-label">{{ this.$i18n.t("patients.lblEmail") }}</span
              ><span style="color: red;">*</span>
              <b-form-input v-model="data.email" required :state="data.email !== '' ? true : false"></b-form-input>
            </b-col>
          </b-row>
        </div>
        <!-- <div class="sa-form-section">
                    <h5 class="sa-text-left">{{ this.$i18n.t("patients.lblDoctor") }}</h5>
                    <b-row>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                            <span class="sa-form-label">{{ this.$i18n.t("patients.lblDoctor") }}</span>
                            <b-form-input v-model="data.medicoCurante"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                            <span class="sa-form-label">{{ this.$i18n.t("patients.lblFiscalCode") }}</span>
                            <b-form-input v-model="data.codiceFiscaleMedicoCurante" class="text-uppercase"></b-form-input>
                        </b-col>
                    </b-row>
                </div> -->
        <div class="sa-form-section">
          <h5 class="sa-text-left">{{ this.$i18n.t("patients.lblNote") }}</h5>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <!-- <span class="sa-form-label">{{ this.$i18n.t("patients.lblNote") }}</span> -->
              <b-form-textarea v-model="data.note" no-resize :state="data.nome !== null ? true : false"></b-form-textarea>
            </b-col>
          </b-row>
        </div>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
  components: { DatePicker, SaPageLayout },
  data() {
    return {
      selectOptionGruppoSanguigno: this.$i18n.t("patients.selectGruopBlood"),
      selectOptionSesso: this.$i18n.t("patients.selectGender"),
      selectOptionComune: this.$i18n.t("patients.selectCounties"),
      selectOptionProvincia: this.$i18n.t("patients.selectCity"),

      pathResource: "/pazienti",
      linkback: null,
      linkProvince: "/province",
      linkComune: "/comuni/provincia",
      linkComuneByIstat: "/comuni/istat",
      linkGruppiSangue: "/pazientigruppisanguigno",
      showModalLoading: false,
      id: "-1",
      profili: [],
      sessoOption: [
        { value: "M", text: "Maschio" },
        { value: "F", text: "Femmina" },
      ],
      isDomicilioUgualeResidenza: false,
      provinciaNascitaOption: [],
      provinciaDomicilioOption: [],
      provinciaResidenzaOption: [],
      comuneNascitaOption: [],
      comuneDomicilioOption: [],
      comuneResidenzaOption: [],
      gruppoSanguignoOption: [],
      data: {
        cognome: "",
        nome: "",
        identificativo: "",
        indirizzoResidenza: "",
        indirizzoDomicilio: "",
        note: "",
        sesso: null,
        comuneDomicilioCodiceIstat: null,
        provinciaDomicilio: null,
        comuneNascitaCodiceIstat: null,
        provinciaNascita: null,
        comuneResidenzaCodiceIstat: null,
        provinciaResidenza: null,
        capResidenza: null,
        capDomicilio: null,
        gruppoSanguigno: null,
        telefono: "",
        email: "",
      },
    };
  },
  computed: {
    dataScadenzaUtenza: {
      get: function() {
        return new Date(this.data.scadenzaUtenza);
      },
      set: function(value) {
        this.data.scadenzaUtenza = moment(String(value)).valueOf();
      },
    },
  },
  mounted: function() {
    let me = this;
    me.id = this.$route.params.id;
    if (me.id === "-1") {
      me.linkback = "/pazienti/";
    } else {
      me.linkback = "/paziente/view/" + me.id;
    }

    me.loadDefaultData();
  },
  watch: {
    isDomicilioUgualeResidenza(value) {
      let me = this;
      if (value) {
        me.data.indirizzoDomicilio = me.data.indirizzoResidenza;
        me.data.comuneDomicilioCodiceIstat = me.data.comuneResidenzaCodiceIstat;
        me.data.provinciaDomicilio = me.data.provinciaResidenza;
        me.data.capDomicilio = me.data.capResidenza;
        console.log(me.data);
      } else if (!value) {
        (me.data.indirizzoDomicilio = null), (me.data.comuneDomicilioCodiceIstat = null), (me.data.provinciaDomicilio = null), (me.data.capDomicilio = null);
      }
      console.log(me.data);
    },
  },

  methods: {
    check: function(input, id) {
      console.log(typeof input);
      if (typeof input == "number") {
        document.getElementById(id).classList.remove("red-border");
        document.getElementById(id).classList.add("green-border");
      } else {
        document.getElementById(id).classList.remove("green-border");
        document.getElementById(id).classList.add("red-border");
      }
    },
    loadDefaultData() {
      let me = this;
      me.loadProvinceNascita();
      me.loadProvinceDomicilio();
      me.loadProvinceResidenza();
      me.loadGruppiSangue();
      me.loadData();
    },
    loadData() {
      let me = this;

      //console.log(me.id);
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        me.showModalLoading = true;
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
        axios.get(link + me.id).then((response) => {
          me.data = response.data.data;
          me.loadComuneNascita(me.data.provinciaNascita);
          me.loadComuneResidenza(me.data.provinciaResidenza);
          me.loadComuneDomicilio(me.data.provinciaDomicilio);
          me.showModalLoading = false;
        });
      }
    },
    loadProvinceNascita() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkProvince;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.provinciaNascitaOption.push({ value: element.sigla, text: element.provincia });
        });
      });
    },
    loadGruppiSangue() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkGruppiSangue;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.gruppoSanguignoOption.push({ value: element.descrizione, text: element.descrizione });
        });
      });
    },
    loadProvinceDomicilio() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkProvince;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.provinciaDomicilioOption.push({ value: element.sigla, text: element.provincia });
        });
      });
    },
    loadProvinceResidenza() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkProvince;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.provinciaResidenzaOption.push({ value: element.sigla, text: element.provincia });
        });
      });
    },
    loadComuneNascita(sigla) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
      axios.get(link).then((response) => {
        me.comuneNascitaOption = [];
        response.data.data.forEach((element) => {
          me.comuneNascitaOption.push({ value: element.codiceIstat, text: element.comune });
        });
      });
    },
    loadComuneDomicilio(sigla) {
      // let me = this;
      // let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
      // axios.get(link).then((response) => {
      //     me.comuneDomicilioOption = response.data.data;
      //     me.comuneDomicilioOption.unshift({ codiceIstat: null, comune: "- Seleziona Comune -" });

      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
      axios.get(link).then((response) => {
        me.comuneDomicilioOption = response.data.data;
      });
    },
    setCapDomicilio(item) {
      let me = this;
      me.comuneDomicilioOption.forEach((comuneDomicilio) => {
        if (comuneDomicilio.codiceIstat === item) {
          me.data.capDomicilio = comuneDomicilio.cap;
        }
      });
    },
    loadComuneResidenza(sigla) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
      axios.get(link).then((response) => {
        me.comuneResidenzaOption = response.data.data;
      });
    },
    setCapResidenza(item) {
      let me = this;
      me.comuneResidenzaOption.forEach((comuneResidenza) => {
        if (comuneResidenza.codiceIstat === item) {
          me.data.capResidenza = comuneResidenza.cap;
        }
      });
    },
    // onKeyPress(e) {
    //     let me = this;
    //     if (e.keyCode === 13) {
    //         me.onClick();
    //     }
    // },

    // onClick() {
    //     let me = this;
    //     me.showModalLoading = true;
    //     if (me.data.codiceFiscale.trim().length === 0) {
    //         me.showModalLoading = false;
    //     } else {
    //         me.data.codiceFiscale = me.data.codiceFiscale.toUpperCase();
    //         let link = process.env.VUE_APP_PATH_API + "/utility";
    //         axios
    //             .get(link, { params: { codiceFiscale: me.data.codiceFiscale } })
    //             .then((response) => {
    //                 me.data.nome = response.data.data.nome;
    //                 me.data.cognome = response.data.data.cognome;
    //                 me.data.dataNascita = moment(response.data.data.dataNascita, "DD/MM/YYYY")
    //                     .toDate()
    //                     .getTime();
    //                 me.data.provinciaNascita = response.data.data.provinciaNascita;
    //                 me.data.comuneNascitaCodiceIstat = response.data.data.codiceIstatComuneNascita;
    //                 me.data.sesso = response.data.data.sesso;
    //                 me.data.indirizzoResidenza = response.data.data.indirizzoResidenza;
    //                 me.data.provinciaResidenza = response.data.data.provinciaResidenza;
    //                 me.data.comuneResidenzaCodiceIstat = response.data.data.codiceIstatComuneResidenza;
    //                 me.setCapResidenza(response.data.data.codiceIstatComuneResidenza);
    //                 me.data.indirizzoDomicilio = response.data.data.indirizzoResidenza;
    //                 me.data.provinciaDomicilio = response.data.data.provinciaResidenza;
    //                 me.data.comuneDomicilioCodiceIstat = response.data.data.codiceIstatComuneResidenza;
    //                 me.setCapDomicilio(response.data.data.codiceIstatComuneResidenza);
    //                 me.data.codiceFiscaleMedicoCurante = response.data.data.codiceFiscaleMedico;
    //                 me.data.medicoCurante = response.data.data.medico;
    //                 me.showModalLoading = false;
    //             })
    //             .catch(() => {
    //                 me.showModalLoading = false;
    //             });
    //     }
    // },
  },
};
</script>
<style scoped>
.red-border {
  border: 1px solid #dc3545;
  border-radius: 5px;
}
.green-border {
  border: 1px solid #28a745;
  border-radius: 5px;
}
.domicilio-residenza-checkbox {
  margin-top: 0.2rem;
}
</style>
