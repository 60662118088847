<template>
    <sa-page-layout :pathResource="pathResource" :linkback="linkback" :data="jsonData" :showModalLoading="showModalLoading" :btnSaveVisible="true" :btnAnnullaVisible="true">
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <div class="sa-form-section" v-if="this.$route.query.idPrenotazione">
                    <h5>Prenotazione</h5>
                    <prenotazioni-view-component :jsonData="prenotazione"></prenotazioni-view-component>
                </div>
                <div class="sa-form-section">
                    <h5 class="sa-text-left">Dati Struttura</h5>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                            <span class="sa-form-label">Struttura</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.accettazioneStruttura.codiceStruttura" class="mb-2 mr-sm-2 mb-sm-0" :options="struttureOptions" :value="null" value-field="codiceStruttura" text-field="denominazione"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                            <span class="sa-form-label">Data Preotazione</span>
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataPrenotazione" type="date" placeholder="Data Prenotazione"></date-picker>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                            <span class="sa-form-label">Numero Cartella</span>
                            <b-form-input v-model="jsonData.codiceCartella" placeholder="Numero Cartella"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                            <span class="sa-form-label">Numero Certificato</span>
                            <b-form-input v-model="jsonData.numeroCertificato" placeholder="Numero Certificato"></b-form-input>
                        </b-col>
                    </b-row>
                    <h5 class="sa-text-left">{{ this.$i18n.t("patients.lblPatientRegistry") }}</h5>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                            <span class="sa-form-label">{{ this.$i18n.t("patients.lblLastname") }}</span>
                            <b-form-input v-model="jsonData.accettazioneAnagrafica.cognome" placeholder="Cognome"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                            <span class="sa-form-label">{{ this.$i18n.t("patients.lblName") }}</span>
                            <b-form-input v-model="jsonData.accettazioneAnagrafica.nome" placeholder="Nome"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                            <span class="sa-form-label">{{ this.$i18n.t("patients.lblBirthDate") }}</span>
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.accettazioneAnagrafica.dataNascita" type="date" placeholder="Data Nascita"></date-picker>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                            <span class="sa-form-label">{{ this.$i18n.t("patients.lblGender") }}</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.accettazioneAnagrafica.sesso" class="mb-2 mr-sm-2 mb-sm-0" :options="sessoOption" :value="null"></b-form-select>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                            <span class="sa-form-label">{{ this.$i18n.t("patients.lblIdentificationType") }}</span>
                            <b-form-select v-model="jsonData.accettazioneAnagrafica.tipoIdentificativo" class="mb-2 mr-sm-2 mb-sm-0" :options="tipoIdentificativoOptions" :value="null"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                            <span class="sa-form-label">Codice Fiscale</span>
                            <b-input-group class="mb-3">
                                <!-- <b-form-input maxlength="16" class="text-uppercase" @keypress="onKeyPress" v-model="jsonData.accettazioneAnagrafica.codiceFiscale" placeholder="Codice Fiscale"></b-form-input> -->
                                <b-form-input maxlength="16" class="text-uppercase" @keypress="onKeyPress" v-model="jsonData.accettazioneAnagrafica.codiceFiscale" placeholder="Codice Fiscale"></b-form-input>
                                <b-button :disabled="disabledAccept(!jsonData.accettazioneAnagrafica.tipoIdentificativo)" size="sm" variant="" @click="onSearchAnagraficaVisitaClick"> <b-icon icon="search"></b-icon> </b-button>
                            </b-input-group>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                            <span class="sa-form-label">{{ this.$i18n.t("patients.lblProvince") }}</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.accettazioneAnagrafica.provinciaNascita" class="mb-2 mr-sm-2 mb-sm-0" :options="provinceNascitaOptions" :value="null" value-field="sigla" text-field="provincia" @change="onProvinciaNascitaChange"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                            <span class="sa-form-label">{{ this.$i18n.t("patients.lblCity") }}</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.accettazioneAnagrafica.comuneNascitaCodiceIstat" class="mb-2 mr-sm-2 mb-sm-0" :options="comuniNascitaOptions" :value="null" value-field="codiceIstat" text-field="comune"></b-form-select>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                            <span class="sa-form-label">Numero Tessera Sanitaria</span>
                            <b-form-input v-model="jsonData.numeroTesseraSanitaria" placeholder="Numero Tessera Sanitaria"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                            <span class="sa-form-label">Documento d'identita'</span>
                            <!-- <b-form-input v-model="jsonData.documentoIdentita" placeholder="Documento d'identita'"></b-form-input> -->
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.documentoIdentita" class="mb-2 mr-sm-2 mb-sm-0" :options="documentiIdentitaOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="2">
                            <span class="sa-form-label">Numero Documento</span>
                            <b-form-input v-model="jsonData.numeroDocumento" placeholder="Numero Documento"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="2">
                            <span class="sa-form-label">Gruppo Sanguigno</span>
                            <b-form-select v-model="jsonData.gruppoSanguigno" class="mb-2 mr-sm-2 mb-sm-0" :options="gruppoSanguignoOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="2">
                            <span class="sa-form-label">Fattore RH</span>
                            <b-form-select v-model="jsonData.fattoreRh" class="mb-2 mr-sm-2 mb-sm-0" :options="fattoreRhOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                            <span class="sa-form-label">Tipo Atleta</span>
                            <b-form-select v-model="jsonData.tipoAtleta" class="mb-2 mr-sm-2 mb-sm-0" :options="tipiAtletaOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                            <span class="sa-form-label">Sport</span>
                            <b-form-select v-model="jsonData.sport" class="mb-2 mr-sm-2 mb-sm-0" :options="sportOptions" :value="null" value-field="codiceSport" text-field="sport"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                            <span class="sa-form-label">Federazione/Ente</span>
                            <b-form-input v-model="jsonData.federazioneEnte" placeholder="Federazione/Ente"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                            <span class="sa-form-label">Societa' Sportiva</span>
                            <b-form-input v-model="jsonData.societaSportiva" placeholder="Società Sportiva"></b-form-input>
                        </b-col>
                    </b-row>
                </div>
                <div class="sa-form-section">
                    <h5 class="sa-text-left">{{ this.$i18n.t("patients.lblResidence") }}</h5>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <span class="sa-form-label">{{ this.$i18n.t("patients.lblAddress") }}</span>
                            <b-form-input v-model="jsonData.accettazioneAnagrafica.indirizzoResidenza" placeholder="Indirizzo"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <span class="sa-form-label">{{ this.$i18n.t("patients.lblProvince") }}</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.accettazioneAnagrafica.provinciaResidenza" class="mb-2 mr-sm-2 mb-sm-0" :options="provinceResidenzaOptions" :value="null" value-field="sigla" text-field="provincia" @change="onProvinciaResidenzaChange"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <span class="sa-form-label">{{ this.$i18n.t("patients.lblCity") }}</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.accettazioneAnagrafica.comuneResidenzaCodiceIstat" class="mb-2 mr-sm-2 mb-sm-0" :options="comuniResidenzaOptions" :value="null" value-field="codiceIstat" text-field="comune" @input="onComuneResidenzaInput"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <span class="sa-form-label">{{ this.$i18n.t("patients.lblPostalCode") }}</span>
                            <b-form-input v-model="jsonData.accettazioneAnagrafica.capResidenza" placeholder="CAP Comune di residenza:"></b-form-input>
                        </b-col>
                    </b-row>
                </div>
                <div class="sa-form-section">
                    <h5 class="sa-text-left">{{ this.$i18n.t("patients.lblContacts") }}</h5>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <span class="sa-form-label">{{ this.$i18n.t("patients.lblTelephoneNumber") }}</span>
                            <b-form-input v-model="jsonData.accettazioneAnagrafica.telefono" placeholder="Telefono"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <span class="sa-form-label">{{ this.$i18n.t("patients.lblEmail") }}</span>
                            <b-form-input v-model="jsonData.accettazioneAnagrafica.email" placeholder="Email"></b-form-input>
                        </b-col>
                    </b-row>
                </div>
                <div class="sa-form-section">
                    <h5>Medico Esecutore</h5>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                            <span class="sa-form-label"> Nome e Cognome</span>
                            <b-form-input v-model="jsonData.nomeMedicoEsecutore" placeholder="Medico Curante"></b-form-input>
                        </b-col>
                    </b-row>
                </div>
                <div class="sa-form-section">
                    <h5>Altri Dati</h5>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                            <span class="sa-form-label">Da riepilogare</span>
                            <b-form-checkbox v-model="jsonData.daRiepilogare"></b-form-checkbox>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                            <span class="sa-form-label">Posizione Ticket</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.posizioneTicket" class="mb-2 mr-sm-2 mb-sm-0" :options="posizioneTicketOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                            <span class="sa-form-label">Provenienza Medico</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.provenienzaMedico" class="mb-2 mr-sm-2 mb-sm-0" :options="provenienzaMedicoOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                            <span class="sa-form-label">Classe Priorita'</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.classePriorita" class="mb-2 mr-sm-2 mb-sm-0" :options="classePrioritaOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Asl Riepilogo</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.aslRiepilogo" class="mb-2 mr-sm-2 mb-sm-0" :options="aslRiepilogoOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Asl Paziente</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.aslPaziente" class="mb-2 mr-sm-2 mb-sm-0" :options="aslPazienteOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Regime Erogazione</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.regimeErogazione" class="mb-2 mr-sm-2 mb-sm-0" :options="regimeErogazioneOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                            <span class="sa-form-label">Tariffa Base</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.tariffaBase" class="mb-2 mr-sm-2 mb-sm-0" :options="tariffaBaseOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                            <span class="sa-form-label">Profilo Ticket</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.profiloTicket" class="mb-2 mr-sm-2 mb-sm-0" :options="profiloTicketOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                            <span class="sa-form-label">Tipo Prestazione</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.tipoPrestazione" class="mb-2 mr-sm-2 mb-sm-0" :options="tipoPrestazioneOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                            <span class="sa-form-label">Diagnosi</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.diagnosi" class="mb-2 mr-sm-2 mb-sm-0" :options="diagnosiOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                    </b-row>
                </div>
                <div class="sa-form-section">
                    <h5 class="sa-text-left">{{ this.$i18n.t("patients.lblNote") }}</h5>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-textarea v-model="jsonData.accettazioneAnagrafica.note" placeholder="Note" no-resize></b-form-textarea>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PrenotazioniViewComponent from "./PrenotazioniViewComponent.vue";
export default {
    components: { SaPageLayout, DatePicker, PrenotazioniViewComponent },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        if (me.id === "-1") {
            me.linkback = "/accettazione";
        } else {
            me.linkback = "/accettazione/view/" + me.id;
        }
        if (this.$route.query.idPrenotazione) {
            me.idPrenotazione = this.$route.query.idPrenotazione;
            me.loadPrenotazione();
        }
        if (me.id !== "-1") {
            me.loadData();
            me.loadDefaultData();
        } else {
            me.loadDefaultData();
        }
    },
    data() {
        return {
            pathResource: "/accettazionileonida",
            pathResourcePrenotazione: "/prenotazioni",
            linkProvince: "/province",
            linkComune: "/comuni/provincia",
            linkStrutture: "/strutture",
            linkDocumentiIdentita: "/documentiidentita",
            linkTipoIdentificativo: "/anagraficaidentificativo",
            linkSport: "/tipisport",
            linkAsl: "/asl",
            linkback: null,
            showModalLoading: false,
            jsonData: {
                accettazioneStruttura: { codiceStruttura: null },
                accettazioneAnagrafica: {
                    codiceFiscale: "",
                    provinciaNascita: null,
                    comuneNascitaCodiceIstat: null,
                    medicoCurante: "",
                    codiceFiscaleMedicoCurante: "",
                    provinciaResidenza: null,
                    comuneResidenzaCodiceIstat: null,
                    provinciaDomicilio: "",
                    comuneDomicilioCodiceIstat: "",
                    capDomicilio: "",
                    capResidenza: null,
                    dataNascita: null,
                    sesso: null,
                    tipoIdentificativo: "CF",
                    indirizzoResidenza: "",
                    indirizzoDomicilio: "",
                },
                idPrenotazione: "",
                tipo: "",
                gruppoSanguigno: null,
                codiceCartella: "",
                numeroCertificato: "",
                societaSportiva: "",
                documentoIdentita: null,
                federazioneEnte: "",
                nomeMedicoEsecutore: "",
                numeroDocumento: "",
                numeroTesseraSanitaria: "",
                dataPrenotazione: null,
                sport: null,
                daRiepilogare: false,
                posizioneTicket: null,
                provenienzaMedico: null,
                classePriorita: null,
                aslRiepilogo: null,
                aslPaziente: null,
                regimeErogazione: null,
                tariffaBase: null,
                profiloTicket: null,
                tipoPrestazione: null,
                diagnosi: null,
                tipoAtleta: null,
                fattoreRh: null,
            },
            sessoOption: [
                { value: null, text: "-Sel. Sesso-" },
                { value: "M", text: "Maschio" },
                { value: "F", text: "Femmina" },
            ],
            gruppoSanguignoOptions: [
                { value: null, text: "-Sel. Gruppo Sanguigno-" },
                { value: "A", text: "A" },
                { value: "B", text: "B" },
                { value: "0", text: "0" },
                { value: "AB", text: "AB" },
            ],
            fattoreRhOptions: [
                { value: null, text: "-Sel. Fattore RH-" },
                { value: "POSITIVO", text: "POSITIVO" },
                { value: "NEGATIVO", text: "NEGATIVO" },
            ],
            prenotazione: {},
            provinceNascitaOptions: [{ sigla: null, provincia: "-Seleziona Provincia-" }],
            provinceResidenzaOptions: [{ sigla: null, provincia: "-Seleziona Provincia-" }],
            provinceDomicilioOptions: [{ sigla: null, provincia: "-Seleziona Provincia-" }],
            comuniNascitaOptions: [{ codiceIstat: null, comune: "-Seleziona Comune-" }],
            comuniResidenzaOptions: [{ codiceIstat: null, comune: "-Seleziona Comune-" }],
            comuniDomicilioOptions: [{ codiceIstat: null, comune: "-Seleziona Comune-" }],
            struttureOptions: [{ value: null, text: "-Struttura-" }],
            tipoIdentificativoOptions: [{ value: null, text: "-Seleziona Identificativo-" }],
            documentiIdentitaOptions: [{ value: null, text: "-Seleziona Documento-" }],
            sportOptions: [],
            posizioneTicketOptions: [{ value: null, text: "-Seleziona Posizione Ticket-" }],
            provenienzaMedicoOptions: [{ value: null, text: "-Seleziona Provenienza Medico-" }],
            classePrioritaOptions: [{ value: null, text: "-Seleziona Classe di Priorita'-" }],
            aslRiepilogoOptions: [{ value: null, text: "-Seleziona Asl Riepilogo-" }],
            aslPazienteOptions: [{ value: null, text: "-Seleziona Asl Paziente-" }],
            regimeErogazioneOptions: [{ value: null, text: "-Seleziona Regime Erogazione-" }],
            tariffaBaseOptions: [{ value: null, text: "-Seleziona Tariffa Base-" }],
            profiloTicketOptions: [{ value: null, text: "-Seleziona Profilo Ticket-" }],
            tipoPrestazioneOptions: [{ value: null, text: "-Seleziona Tipo Prestazione-" }],
            diagnosiOptions: [{ value: null, text: "-Seleziona Diagnosi-" }],
            tipiAtletaOptions: [
                { value: null, text: "-Seleziona Valore-" },
                { value: "AGONISTA", text: "AGONISTA" },
                { value: "NON AGONISTA", text: "NON AGONISTA" },
            ],
        };
    },
    methods: {
        loadDefaultData() {
            let me = this;
            me.loadProvince();
            me.loadComuniNascita(me.jsonData.accettazioneAnagrafica.provinciaNascita);
            me.loadComuniResidenza(me.jsonData.accettazioneAnagrafica.provinciaResidenza, me.jsonData.accettazioneAnagrafica.comuneResidenzaCodiceIstat);
            // me.loadComuniDomicilio(me.jsonData.accettazioneAnagrafica.provinciaDomicilio, me.jsonData.accettazioneAnagrafica.comuneDomicilioCodiceIstat);
            me.loadStrutture();
            me.loadDocumentiIdentita();
            me.loadTipoIdentificativo();
            me.loadSport();
        },
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY");
            } else {
                return "---";
            }
        },
        disabledAccept() {
            let me = this;
            if (me.jsonData.accettazioneAnagrafica.tipoIdentificativo === "CF") {
                return false;
            } else {
                return true;
            }
        },
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonData = response.data.data;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        loadPrenotazione() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourcePrenotazione + "/" + me.idPrenotazione;
            me.showModalLoading = true;
            axios
                .get(link)
                .then((response) => {
                    me.prenotazione = response.data.data;
                    me.jsonData.accettazioneAnagrafica.nome = me.prenotazione.nome;
                    me.jsonData.accettazioneAnagrafica.cognome = me.prenotazione.cognome;
                    me.jsonData.accettazioneAnagrafica.dataNascita = me.prenotazione.dataNascita;
                    me.jsonData.accettazioneAnagrafica.email = me.prenotazione.email;
                    me.jsonData.accettazioneAnagrafica.telefono = me.prenotazione.telefono;
                    me.jsonData.idPrenotazione = me.prenotazione.id;
                    me.jsonData.dataPrenotazione = me.prenotazione.dataPrenotazione;
                    me.jsonData.accettazioneAnagrafica.note = me.prenotazione.noteAggiuntive;
                    me.jsonData.sport = me.prenotazione.sport;
                    me.jsonData.tipoAtleta = me.prenotazione.tipoAtleta;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        loadStrutture() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkStrutture;
            axios.get(link).then((response) => {
                me.struttureOptions = response.data.data;
                me.struttureOptions.unshift({ codiceStruttura: null, denominazione: "-Seleziona Struttura-" });
                if (me.struttureOptions.length === 2) {
                    me.jsonData.accettazioneStruttura.codiceStruttura = response.data.data[1].codiceStruttura;
                }
            });
        },
        loadTipoIdentificativo() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkTipoIdentificativo;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.tipoIdentificativoOptions.push({ value: element.codice, text: element.descrizione });
                });
            });
        },
        loadDocumentiIdentita() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkDocumentiIdentita;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.documentiIdentitaOptions.push({ value: element.tipoDocumento, text: element.tipoDocumento });
                });
            });
        },
        // onKeyPress(e) {
        //     let me = this;
        //     if (e.keyCode === 13) {
        //         me.onSearchAnagraficaClick();
        //     }
        // },
        onKeyPress(e) {
            let me = this;
            if (e.keyCode === 13) {
                me.onSearchAnagraficaVisitaClick();
            }
        },
        loadProvince() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkProvince;
            axios.get(link).then((response) => {
                me.provinceNascitaOptions = response.data.data;
                me.provinceNascitaOptions.unshift({ sigla: null, provincia: "- Seleziona Provincia-" });
                response.data.data.forEach((element) => {
                    // me.provinceDomicilioOptions.push(element);
                    me.provinceResidenzaOptions.push(element);
                });
            });
        },
        onProvinciaNascitaChange(value) {
            let me = this;
            me.provinceNascitaOptions.forEach((provincia) => {
                if (provincia.sigla === value) {
                    me.setDatiNascita(provincia.sigla);
                }
            });
        },
        setDatiNascita(siglaProvincia, codiceIstatComuneNascita = null) {
            let me = this;
            me.jsonData.accettazioneAnagrafica.comuneNascitaCodiceIstat = codiceIstatComuneNascita;
            me.provinceNascitaOptions.forEach((provincia) => {
                if (provincia.sigla === siglaProvincia) {
                    me.jsonData.accettazioneAnagrafica.provinciaNascita = provincia.sigla;
                    me.loadComuniNascita(provincia.sigla);
                }
            });
        },
        loadComuniNascita(sigla) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
            axios.get(link).then((response) => {
                me.comuniNascitaOptions = [];
                me.comuniNascitaOptions = response.data.data;
                me.comuniNascitaOptions.unshift({ codiceIstat: null, comune: "-Seleziona Comune-" });
            });
        },
        onProvinciaResidenzaChange(value) {
            let me = this;
            me.provinceResidenzaOptions.forEach((provincia) => {
                if (provincia.sigla === value) {
                    me.loadAsl(provincia.regione);
                    me.setDatiResidenza(provincia.sigla);
                }
            });
        },
        setDatiResidenza(siglaProvincia, codiceIstatComuneResidenza = null) {
            let me = this;
            me.provinceResidenzaOptions.forEach((provincia) => {
                if (provincia.sigla === siglaProvincia) {
                    me.jsonData.accettazioneAnagrafica.provinciaResidenza = provincia.sigla;
                    me.loadComuniResidenza(provincia.sigla, codiceIstatComuneResidenza);
                }
            });
        },
        loadComuniResidenza(sigla, codiceIstatComuneResidenza = null) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
            axios.get(link, { params: { provincia: sigla } }).then((response) => {
                me.comuniResidenzaOptions = response.data.data;
                me.comuniResidenzaOptions.unshift({ codiceIstat: null, comune: "-Seleziona Comune-" });
                me.jsonData.accettazioneAnagrafica.comuneResidenzaCodiceIstat = codiceIstatComuneResidenza;
            });
        },
        onComuneResidenzaInput(value) {
            let me = this;
            me.comuniResidenzaOptions.forEach((comune) => {
                if (comune.codiceIstat === value) {
                    me.jsonData.accettazioneAnagrafica.capResidenza = comune.cap;
                }
            });
        },
        loadAsl(value) {
            let me = this;
            me.aslRiepilogoOptions = [];
            me.aslPazienteOptions = [];
            let regione = value + "0";
            while (regione.charAt(0) === "0") {
                regione = regione.substring(1);
            }
            let link = process.env.VUE_APP_PATH_API + me.linkAsl + "/regione?codiceRegione=" + regione;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.aslRiepilogoOptions.unshift({ value: element.denominazione, text: element.denominazione });
                    me.aslPazienteOptions.unshift({ value: element.denominazione, text: element.denominazione });
                });
            });
        },
        onProvinciaDomicilioChange(value) {
            let me = this;
            me.jsonData.accettazioneAnagrafica.comuneDomicilioCodiceIstat = null;
            me.provinceDomicilioOptions.forEach((provincia) => {
                if (provincia.sigla === value) {
                    me.loadComuniDomicilio(provincia.sigla);
                }
            });
        },
        setDatiDomicilio(siglaProvincia, codiceIstatComuneDomicilio = null) {
            let me = this;
            me.provinceDomicilioOptions.forEach((provincia) => {
                if (provincia.sigla === siglaProvincia) {
                    me.jsonData.accettazioneAnagrafica.provinciaDomicilio = provincia.sigla;
                    me.loadComuniDomicilio(provincia.sigla, codiceIstatComuneDomicilio);
                }
            });
        },
        loadComuniDomicilio(sigla, codiceIstatComuneDomicilio = null) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
            axios.get(link).then((response) => {
                me.comuniDomicilioOptions = response.data.data;
                me.comuniDomicilioOptions.unshift({ codiceIstat: null, comune: "-Seleziona Comune-" });
                me.jsonData.accettazioneAnagrafica.comuneDomicilioCodiceIstat = codiceIstatComuneDomicilio;
            });
        },
        onComuneDomicilioInput(value) {
            let me = this;
            me.jsonData.accettazioneAnagrafica.capDomicilio = "";
            me.comuniDomicilioOptions.forEach((comune) => {
                if (comune.codiceIstat === value) {
                    me.jsonData.accettazioneAnagrafica.capDomicilio = comune.cap;
                }
            });
        },
        loadSport() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkSport;
            axios.get(link).then((response) => {
                me.sportOptions = response.data.data;
                me.sportOptions.unshift({ codiceSport: null, sport: "-Seleziona Sport-" });
            });
        },
        onSearchAnagraficaVisitaClick() {
            let me = this;
            if (me.jsonData.accettazioneAnagrafica.codiceFiscale.trim().length === 0) {
                me.showmolalloading = false;
            } else {
                me.jsonData.accettazioneAnagrafica.codiceFiscale = me.jsonData.accettazioneAnagrafica.codiceFiscale.toUpperCase();
                let link = process.env.VUE_APP_PATH_API + "/visite/codicefiscale/" + me.jsonData.accettazioneAnagrafica.codiceFiscale;
                axios
                    .get(link)
                    .then((response) => {
                        me.jsonData.accettazioneAnagrafica.nome = response.data.data.nome;
                        me.jsonData.accettazioneAnagrafica.cognome = response.data.data.cognome;
                        me.jsonData.accettazioneAnagrafica.dataNascita = moment(response.data.data.dataNascita)
                            .toDate()
                            .getTime();
                        me.setDatiNascita(response.data.data.provinciaNascitaSigla, response.data.data.comuneNascitaCodiceIstat);
                        me.jsonData.accettazioneAnagrafica.sesso = response.data.data.sesso;
                        me.jsonData.accettazioneAnagrafica.indirizzoResidenza = response.data.data.atleta.indirizzoResidenza;
                        me.jsonData.accettazioneAnagrafica.indirizzoDomicilio = response.data.data.atleta.indirizzoResidenza;
                        me.jsonData.accettazioneAnagrafica.comuneResidenzaCodiceIstat = response.data.data.atleta.comuneResidenzaCodiceIstat;
                        me.jsonData.accettazioneAnagrafica.capResidenza = response.data.data.atleta.capResidenza;
                        me.setDatiResidenza(response.data.data.provinciaResidenzaSigla, response.data.data.comuneResidenzaCodiceIstat);
                        me.jsonData.accettazioneAnagrafica.provinciaDomicilio = response.data.data.provinciaResidenza;
                        me.jsonData.accettazioneAnagrafica.comuneDomicilioCodiceIstat = response.data.data.atleta.comuneResidenzaCodiceIstat;
                        me.jsonData.accettazioneAnagrafica.capDomicilio = me.jsonData.accettazioneAnagrafica.capResidenza;
                        me.jsonData.accettazioneAnagrafica.medicoCurante = response.data.data.atleta.medicoCurante;
                        me.jsonData.accettazioneAnagrafica.codiceFiscaleMedicoCurante = response.data.data.atleta.codiceFiscaleMedicoCurante;
                        me.jsonData.sport = response.data.data.sport;
                        me.jsonData.tipoAtleta = response.data.data.tipoAttleta;
                    })
                    .catch(() => {
                        me.showmolalloading = false;
                    });
            }
        },

        // onSearchAnagraficaClick() {
        //     let me = this;
        //     me.showmolalloading = true;
        //     if (me.jsonData.accettazioneAnagrafica.codiceFiscale.trim().length === 0) {
        //         me.showmolalloading = false;
        //     } else {
        //         me.jsonData.accettazioneAnagrafica.codiceFiscale = me.jsonData.accettazioneAnagrafica.codiceFiscale.toUpperCase();
        //         let link = process.env.VUE_APP_PATH_API + "/utility";
        //         axios
        //             .get(link, { params: { codiceFiscale: me.jsonData.accettazioneAnagrafica.codiceFiscale } })
        //             .then((response) => {
        //                 me.jsonData.accettazioneAnagrafica.nome = response.data.data.nome;
        //                 me.jsonData.accettazioneAnagrafica.cognome = response.data.data.cognome;
        //                 me.jsonData.accettazioneAnagrafica.dataNascita = moment(response.data.data.dataNascita, "DD/MM/YYYY")
        //                     .toDate()
        //                     .getTime();
        //                 me.setDatiNascita(response.data.data.provinciaNascita, response.data.data.codiceIstatComuneNascita);
        //                 me.jsonData.accettazioneAnagrafica.sesso = response.data.data.sesso;

        //                 me.jsonData.accettazioneAnagrafica.indirizzoResidenza = response.data.data.indirizzoResidenza;
        //                 me.jsonData.accettazioneAnagrafica.indirizzoDomicilio = response.data.data.indirizzoResidenza;
        //                 me.setDatiResidenza(response.data.data.provinciaResidenza, response.data.data.codiceIstatComuneResidenza);
        //                 // me.setDatiDomicilio(response.data.data.provinciaResidenza, response.data.data.codiceIstatComuneResidenza);
        //                 me.jsonData.accettazioneAnagrafica.provinciaDomicilio = response.data.data.provinciaResidenza;
        //                 me.jsonData.accettazioneAnagrafica.comuneDomicilioCodiceIstat = response.data.data.codiceIstatComuneResidenza;
        //                 me.jsonData.accettazioneAnagrafica.capDomicilio = me.jsonData.accettazioneAnagrafica.capResidenza;
        //                 me.jsonData.accettazioneAnagrafica.codiceFiscaleMedicoCurante = response.data.data.codiceFiscaleMedico;
        //                 me.jsonData.accettazioneAnagrafica.medicoCurante = response.data.data.medico;
        //                 me.showmolalloading = false;
        //             })
        //             .catch(() => {
        //                 me.showmolalloading = false;
        //             });
        //     }
        // },
    },
    watch: {
        "jsonData.accettazioneAnagrafica.capResidenza": {
            immediate: true,
            handler(value) {
                let me = this;
                if (value) {
                    me.jsonData.accettazioneAnagrafica.capDomicilio = value;
                }
            },
        },
        "jsonData.accettazioneAnagrafica.provinciaNascita": {
            immediate: true,
            handler(value) {
                let me = this;
                if (value) {
                    me.loadComuniNascita(value);
                }
            },
        },
        "jsonData.accettazioneAnagrafica.provinciaResidenza": {
            immediate: true,
            handler(value) {
                let me = this;
                if (value) {
                    me.jsonData.accettazioneAnagrafica.provinciaDomicilio = value;
                    me.loadComuniResidenza(value, me.jsonData.accettazioneAnagrafica.comuneResidenzaCodiceIstat);
                }
            },
        },
        "jsonData.accettazioneAnagrafica.comuneResidenzaCodiceIstat": {
            immediate: true,
            handler(value) {
                let me = this;
                if (value) {
                    me.jsonData.accettazioneAnagrafica.comuneDomicilioCodiceIstat = value;
                }
            },
        },
        "jsonData.accettazioneAnagrafica.indirizzoResidenza": {
            immediate: true,
            handler(value) {
                let me = this;
                if (value) {
                    me.jsonData.accettazioneAnagrafica.indirizzoDomicilio = value;
                }
            },
        },
    },
};
</script>
