<template>
  <div>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblHandgripRight") }}</strong>
            <br />
            {{ jsonData.handgripDestra }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblHandgripLeft") }}</strong>
            <br />
            {{ jsonData.handgripSinistra }}
          </b-col>
          <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblPerpendicularDeviation") }}</strong>
            <br />
            {{ jsonData.deviazionePerpendicolare }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblStraightDeviation") }}</strong>
            <br />
            {{ jsonData.deviazioneRetta }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblFlexRight") }}</strong>
            <br />
            {{ jsonData.flessibilitaDestra }}
          </b-col>
          <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblFlexLeft") }}</strong>
            <br />
            {{ jsonData.flessibilitaSinistra }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblChairTest") }}</strong>
            <br />
            {{ jsonData.chairTest }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblMets") }}</strong>
            <br />
            {{ jsonData.mets }}
          </b-col>
          <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblStressDuration") }}</strong>
            <br />
            {{ jsonData.durataSforzo }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
            <strong>{{ getLabelTraduora("patient.afa.lblRecoveryDuration") }}</strong>
            <br />
            {{ jsonData.durataRecupero }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
            <strong>{{ getLabelTraduora("patient.afa.lblQMCI") }}</strong>
            <br />
            {{ jsonData.qmci }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
  </div>
</template>
<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";

export default {
  mixins: [UtilityMixin],
  props: {
    jsonData: {
      Type: Object,
      require: true,
    },
  },
};
</script>
