<template>
  <sa-page-layout :showModalLoading="showModalLoading" :btnSaveVisible="true" :btnAnnullaVisible="true" :linkback="linkback" :pathResource="pathResource" :data="jsonData">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <b-row>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <span class="sa-form-label">{{ getLabelTraduora("patient.EcoReporting.lblHeight") }}(cm)</span>
            <b-form-input v-model="jsonData.altezza"></b-form-input>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <span class="sa-form-label">{{ getLabelTraduora("patient.EcoReporting.lblWeight") }}(Kg)</span>
            <b-form-input v-model="jsonData.peso"></b-form-input>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <span class="sa-form-label">BSA (m2)</span>
            <b-form-input v-model="jsonData.bsa"></b-form-input>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <span class="sa-form-label">BMI (Kg/m2)</span>
            <b-form-input v-model="jsonData.bmi"></b-form-input>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <span class="sa-form-label">PAS (mmHg)</span>
            <b-form-input v-model="jsonData.pas"> </b-form-input>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <span class="sa-form-label">PAD (mmHg)</span>
            <b-form-input v-model="jsonData.pad"></b-form-input>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <span class="sa-form-label">FC (opm)</span>
            <b-form-input v-model="jsonData.fc"></b-form-input>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4"
            ><span class="sa-form-label">{{ getLabelTraduora("patient.EcoReporting.lblAcousticImpedance") }}</span>
            <b-form-select v-model="jsonData.impedenzaAcustica" :options="optionsImpedenzaAcustica">
              <template #first>
                <b-form-select-option :value="null" disabled>{{ selectOption }}</b-form-select-option>
              </template>
            </b-form-select></b-col
          >
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <span class="sa-form-label">Videotape</span>
            <b-form-input v-model="jsonData.videotape"></b-form-input>
          </b-col>
        </b-row>
        <hr />
        <h4>Ventricolo Sinistro</h4>
        <b-row>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4"> <span class="sa-form-label">Vstd (mm)</span><b-form-input v-model="jsonData.vstd"></b-form-input> </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4"> <span class="sa-form-label">PPtd (mm)</span><b-form-input v-model="jsonData.pptd"></b-form-input> </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4"> <span class="sa-form-label">LVMha (g/m2,7)</span><b-form-input v-model="jsonData.lvmha"></b-form-input></b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4"><span class="sa-form-label">Vsts (mm)</span><b-form-input v-model="jsonData.vsts"></b-form-input> </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4"><span class="sa-form-label">EF(T)[%]</span><b-form-input v-model="jsonData.eft"></b-form-input> </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4"><span class="sa-form-label">RWT</span><b-form-input v-model="jsonData.rwt"></b-form-input> </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4"><span class="sa-form-label">SIVtd (mm)</span><b-form-input v-model="jsonData.sivtd"></b-form-input> </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4"><span class="sa-form-label">SF(T) [%]</span><b-form-input v-model="jsonData.sft"></b-form-input> </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4"><span class="sa-form-label">ESS (cm/d)</span><b-form-input v-model="jsonData.ess"></b-form-input> </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4"><span class="sa-form-label">LVMid (P)[g/m2]</span><b-form-input v-model="jsonData.lvmidp"></b-form-input> </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4"><span class="sa-form-label">LVV</span><b-form-input v-model="jsonData.lvv"></b-form-input> </b-col>
        </b-row>
        <hr />
        <h4>Segmenti</h4>
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">Setto Ant. Basale</span>
            <b-form-select v-model="jsonData.settoanteriore" :options="opzioniSegmenti" value-field="id" text-field="descrizione">
              <template #first>
                <b-form-select-option :value="null" disabled>{{ selectOption }}</b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">Setto Ant. Medio</span>
            <b-form-select v-model="jsonData.settoanterioreMedio" :options="opzioniSegmenti" value-field="id" text-field="descrizione">
              <template #first>
                <b-form-select-option :value="null" disabled>{{ selectOption }}</b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">Setto Ant. Apice</span>
            <b-form-select v-model="jsonData.apiceSetto" :options="opzioniSegmenti" value-field="id" text-field="descrizione">
              <template #first>
                <b-form-select-option :value="null" disabled>{{ selectOption }}</b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">Par. Ant. Basale</span>
            <b-form-select v-model="jsonData.pareteanteriore" :options="opzioniSegmenti" value-field="id" text-field="descrizione">
              <template #first>
                <b-form-select-option :value="null" disabled>{{ selectOption }}</b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">Par. Ant. Media</span>
            <b-form-select v-model="jsonData.pareteanterioreMedia" :options="opzioniSegmenti" value-field="id" text-field="descrizione">
              <template #first>
                <b-form-select-option :value="null" disabled>{{ selectOption }}</b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">Par. Ant. Apice</span
            ><b-form-select v-model="jsonData.apiceAnt" :options="opzioniSegmenti" value-field="id" text-field="descrizione">
              <template #first>
                <b-form-select-option :value="null" disabled>{{ selectOption }}</b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">Par. Lat. Ant. Basale</span
            ><b-form-select v-model="jsonData.pareteLatAnteriore" :options="opzioniSegmenti" value-field="id" text-field="descrizione">
              <template #first>
                <b-form-select-option :value="null" disabled>{{ selectOption }}</b-form-select-option>
              </template>
            </b-form-select>
          </b-col>

          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">Par. Lat. Ant. Medio</span>
            <b-form-select v-model="jsonData.pareteLatAnterioreMedia" :options="opzioniSegmenti" value-field="id" text-field="descrizione">
              <template #first>
                <b-form-select-option :value="null" disabled>{{ selectOption }}</b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">Par. Lat. Post. Basale</span>
            <b-form-select v-model="jsonData.pareteLatPosteriore" :options="opzioniSegmenti" value-field="id" text-field="descrizione">
              <template #first>
                <b-form-select-option :value="null" disabled>{{ selectOption }}</b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">Par. Lat. Post. Medio</span>
            <b-form-select v-model="jsonData.pareteLatPosterioreMedia" :options="opzioniSegmenti" value-field="id" text-field="descrizione">
              <template #first>
                <b-form-select-option :value="null" disabled>{{ selectOption }}</b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">Par. Lat. Post Apice</span>
            <b-form-select v-model="jsonData.apicePost" :options="opzioniSegmenti" value-field="id" text-field="descrizione">
              <template #first>
                <b-form-select-option :value="null" disabled>{{ selectOption }}</b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">Par. Inf. Basale</span>
            <b-form-select v-model="jsonData.pareteInferiore" :options="opzioniSegmenti" value-field="id" text-field="descrizione">
              <template #first>
                <b-form-select-option :value="null" disabled>{{ selectOption }}</b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">Par. Inf. Media</span>
            <b-form-select v-model="jsonData.pareteInferioreMedia" :options="opzioniSegmenti" value-field="id" text-field="descrizione">
              <template #first>
                <b-form-select-option :value="null" disabled>{{ selectOption }}</b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">Parete Inf Apice</span>
            <b-form-select v-model="jsonData.apiceInf" :options="opzioniSegmenti" value-field="id" text-field="descrizione">
              <template #first>
                <b-form-select-option :value="null" disabled>{{ selectOption }}</b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">Setto Post Basale</span>
            <b-form-select v-model="jsonData.settoposteriore" :options="opzioniSegmenti" value-field="id" text-field="descrizione">
              <template #first>
                <b-form-select-option :value="null" disabled>{{ selectOption }}</b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <span class="sa-form-label">Setto Post. Medio</span>
            <b-form-select v-model="jsonData.settoposterioreMedio" :options="opzioniSegmenti" value-field="id" text-field="descrizione">
              <template #first>
                <b-form-select-option :value="null" disabled>{{ selectOption }}</b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
        </b-row>
        <br />
        <b-row
          ><b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3"> <span class="sa-form-label">Totale</span><b-form-input v-model="jsonData.totale"> </b-form-input> </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3"> <span class="sa-form-label">IMA</span><b-form-input v-model="jsonData.estensioneDanno"> </b-form-input> </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"><span class="sa-form-label">Note</span><b-form-textarea v-model="jsonData.notevs" rows="4" max-rows="10"></b-form-textarea></b-col>
        </b-row>
        <hr />
        <h4>Ventricolo Destro</h4>
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3"><span class="sa-form-label">VDtd</span><b-form-input v-model="jsonData.vdtd"></b-form-input></b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3"><span class="sa-form-label">Pressione Sistolica</span><b-form-input v-model="jsonData.pressSist"></b-form-input></b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3"><span class="sa-form-label">TAPSE</span><b-form-input v-model="jsonData.tapse"></b-form-input></b-col
        ></b-row>
        <b-row
          ><b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"><span class="sa-form-label">Note</span><b-form-textarea v-model="jsonData.notevd" rows="4" max-rows="10"></b-form-textarea></b-col
        ></b-row>
        <hr />
        <h4>Atrio Destro</h4>
        <b-row
          ><b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3"><span class="sa-form-label">VCI i</span><b-form-input v-model="jsonData.vcii"></b-form-input></b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3"><span span class="sa-form-label">VCI e</span><b-form-input v-model="jsonData.vcie"></b-form-input></b-col>
          <b-col></b-col>
        </b-row>
        <b-row
          ><b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"><span class="sa-form-label">Descrizione</span><b-form-textarea v-model="jsonData.descrAtriodestro" rows="4" max-rows="10"></b-form-textarea></b-col
        ></b-row>
        <hr />
        <h4>Atrio Sinistro</h4>
        <b-row
          ><b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3"><span class="sa-form-label">Atrio Sx</span><b-form-input v-model="jsonData.atrioSx"></b-form-input></b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3"><span span class="sa-form-label">Lavi</span><b-form-input v-model="jsonData.lavi"></b-form-input></b-col
        ></b-row>
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"><span class="sa-form-label">Descrizione</span><b-form-textarea v-model="jsonData.descrAtriosinistro" rows="4" max-rows="10"></b-form-textarea></b-col>
        </b-row>
        <hr />
        <h4>Aorta</h4>
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"><span class="sa-form-label">Aorta</span><b-form-input v-model="jsonData.aorta"></b-form-input></b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"><span class="sa-form-label">LVOT</span><b-form-input v-model="jsonData.lvot"></b-form-input></b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"><span class="sa-form-label">Escrescenza Valvolare</span><b-form-input v-model="jsonData.escval"></b-form-input></b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"><span class="sa-form-label">2D</span><b-form-input v-model="jsonData.duedAorta"></b-form-input></b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <span class="sa-form-label">IA</span
            ><b-form-select v-model="jsonData.ia" :options="optionsIaImItIp">
              <template #first>
                <b-form-select-option :value="null" disabled>{{ selectOption }}</b-form-select-option>
              </template>
            </b-form-select></b-col
          >
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"><span class="sa-form-label">Doppler</span><b-form-textarea v-model="jsonData.doppleraorta" rows="4" max-rows="10"></b-form-textarea></b-col
          ><b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"><span class="sa-form-label">Area</span><b-form-input v-model="jsonData.area"></b-form-input></b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"><span class="sa-form-label">PGMax</span><b-form-input v-model="jsonData.pgmaxAorta"></b-form-input></b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"><span class="sa-form-label">PGMean</span><b-form-input v-model="jsonData.pgmeanAorta"></b-form-input></b-col
        ></b-row>
        <hr />
        <h4>Mitrale</h4>
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"><span class="sa-form-label">2D</span><b-form-input v-model="jsonData.mitrale2d"></b-form-input></b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <span class="sa-form-label">IM</span
            ><b-form-select v-model="jsonData.im" :options="optionsIaImItIp">
              <template #first>
                <b-form-select-option :value="null" disabled>{{ selectOption }}</b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"><span class="sa-form-label">E/E'</span><b-form-input v-model="jsonData.ee"></b-form-input></b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"><span class="sa-form-label">Doppler</span><b-form-textarea v-model="jsonData.dopplemitrale" rows="4" max-rows="10"></b-form-textarea></b-col>
        </b-row>
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"><span class="sa-form-label">PFVE</span><b-form-input v-model="jsonData.pfve"></b-form-input></b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"><span class="sa-form-label">PFVA</span><b-form-input v-model="jsonData.pfva"></b-form-input></b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"><span class="sa-form-label">DT</span><b-form-input v-model="jsonData.dt"></b-form-input></b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"> <span class="sa-form-label">IRT</span><b-form-input v-model="jsonData.irt"></b-form-input></b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"> <span class="sa-form-label">PGMean</span><b-form-input v-model="jsonData.pgmeanMitrale"></b-form-input></b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"> <span class="sa-form-label">MVA</span><b-form-input v-model="jsonData.mva"></b-form-input> </b-col>
        </b-row>
        <hr />
        <h4>Polmonare</h4>
        <b-row
          ><b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"><span class="sa-form-label">2D</span><b-form-input v-model="jsonData.polmonare2d"></b-form-input></b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <span class="sa-form-label">IP</span>
            <b-form-select v-model="jsonData.ip" :options="optionsIaImItIp">
              <template #first>
                <b-form-select-option :value="null" disabled>{{ selectOption }}</b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"><span class="sa-form-label">Doppler</span><b-form-textarea v-model="jsonData.dopplerpolmo" rows="4" max-rows="10"></b-form-textarea></b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"><span class="sa-form-label">VMax</span><b-form-input v-model="jsonData.vmaxPolmonare"></b-form-input> </b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"><span class="sa-form-label">PGMax</span><b-form-input v-model="jsonData.pgmaxPolmonare"></b-form-input> </b-col
        ></b-row>
        <hr />
        <h4>Doppler Vene Polmonari</h4>
        <b-row
          ><b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3"><span class="sa-form-label">S</span><b-form-input v-model="jsonData.s"></b-form-input> </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3"><span class="sa-form-label">D</span><b-form-input v-model="jsonData.d"></b-form-input></b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3"><span class="sa-form-label">APV</span><b-form-input v-model="jsonData.apv"></b-form-input> </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3"><span class="sa-form-label">APD</span><b-form-input v-model="jsonData.apd"></b-form-input> </b-col>
        </b-row>
        <hr />

        <h4>Tricuspide</h4>
        <b-row
          ><b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"><span class="sa-form-label">2D</span><b-form-input v-model="jsonData.tricuspide2d"></b-form-input></b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <span class="sa-form-label">IT</span>
            <b-form-select v-model="jsonData.it" :options="optionsIaImItIp">
              <template #first>
                <b-form-select-option :value="null" disabled>{{ selectOption }}</b-form-select-option>
              </template>
            </b-form-select></b-col
          >
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"><span class="sa-form-label">Doppler</span><b-form-textarea v-model="jsonData.dopplertricuspide" rows="4" max-rows="10"></b-form-textarea></b-col
          ><b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"><span class="sa-form-label">VMax</span><b-form-input v-model="jsonData.vmaxTricuspide"></b-form-input> </b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"><span class="sa-form-label">VMaxIT</span><b-form-input v-model="jsonData.vmaxitTricuspide"></b-form-input> </b-col
        ></b-row>
        <hr />
        <h4>Pericardio</h4>
        <b-row
          ><b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
            ><span class="sa-form-label">{{ getLabelTraduora("patient.EcoReporting.lblPericardiumValue") }} </span><b-form-input v-model="jsonData.pericardio"></b-form-input> </b-col
        ></b-row>
        <b-row
          ><b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"><span class="sa-form-label">Conclusioni</span><b-form-textarea v-model="jsonData.conlusioni" rows="4" max-rows="10"></b-form-textarea></b-col
        ></b-row>
      </div>
    </template>
  </sa-page-layout>
</template>

<script>
import axios from "axios";
import UtilityMixin from "../../../../../utility/UtilityMixin";
import PazienteAnagrafica from "../../../../components/PazienteAnagrafica.vue";
import SaPageLayout from "../../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
  mixins: [UtilityMixin],
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  components: { PazienteAnagrafica, SaPageLayout },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient: {
      get: function() {
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length == 1) {
              if (this.utente.gruppo.menuGruppo[0].primaryKey === "homestudiomedico") {
                return "/paziente/view/" + this.idPaziente;
              } else if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                return "view/" + this.idPaziente;
              } else {
                return "homepaziente";
              }
            } else {
              return "";
            }
          } else {
            return "";
          }
        } else {
          return "";
        }
      },
    },
  },
  data() {
    return {
      selectOption: this.getLabelTraduora("patient.ecoreferting.lblSelected"),
      pathResource: "/cardiologiaecocardirefertazioni",
      pathResourceSegmenti: "/segmenti",
      data: {},
      id: "-1",
      linkback: null,
      jsonData: {
        impedenzaAcustica: null,
        /**/
        vstd: null,
        vsts: null,
        sivtd: null,
        pptd: null,
        eft: null,
        sft: null,
        lvmha: null,
        rwt: null,
        ess: null,
        lvmidp: null,
        lvv: null,
        /**/
        settoanteriore: null,
        settoanterioreMedio: null,
        pareteanteriore: null,
        pareteanterioreMedia: null,
        apiceSetto: null,
        apiceAnt: null,
        apiceInf: null,
        apicePost: null,
        pareteLatAnteriore: null,
        pareteLatAnterioreMedia: null,
        pareteLatPosteriore: null,
        pareteLatPosterioreMedia: null,
        pareteInferiore: null,
        pareteInferioreMedia: null,
        settoposterioreMedio: null,
        settoposteriore: null,
        /**/
        it: null,
        ip: null,
        im: null,
        ia: null,
      },
      opzioniSegmenti: [],
      optionsImpedenzaAcustica: [
        { text: this.getLabelTraduora("patient.ecoreporting.slcValueImpendaAcusticaNormale"), value: this.getLabelTraduora("patient.ecoreporting.slcValueImpendaAcusticaNormale") },
        { text: this.getLabelTraduora("patient.ecoreporting.slcValueImpendaAcusticaAlta"), value: this.getLabelTraduora("patient.ecoreporting.slcValueImpendaAcusticaAlta") },
      ],
      optionsIaImItIp: [
        { text: "++++/4", value: "++++/4" },
        { text: "+++/4", value: "+++/4" },
        { text: "++/4", value: "++/4" },
        { text: "+/4", value: "+/4" },
      ],

      showModalLoading: false,
    };
  },
  created: function() {},
  mounted() {
    let me = this;
    me.jsonData.idAmbulatorio = sessionStorage.getItem("idAmbulatorio");
    me.linkback = "/paziente/refertazioneecocardio?idPaziente=" + me.idPaziente;
    me.id = this.$route.params.id;
    me.loadData();
    me.loadDefaultData();
  },
  methods: {
    loadDefaultData() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceSegmenti;
      axios
        .get(link)
        .then((response) => {
          me.showModalLoading = false;
          response.data.data.forEach((element) => {
            let descrizione = this.getLabelTraduora(element.descrizione);
            me.opzioniSegmenti.push({ id: element.id, descrizione: descrizione });
          });
        })
        .catch((e) => {
          me.showModalLoading = false;
          console.log(e);
        });
    },

    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.jsonData.idPaziente = me.idPaziente;
        me.showModalLoading = false;
      } else {
        me.showModalLoading = true;
        me.jsonData.idPaziente = me.idPaziente;
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
        axios.get(link + me.id).then((response) => {
          me.jsonData = response.data.data;
        });
      }
      me.showModalLoading = false;
    },
    onSave() {
      let me = this;
      if (me.jsonData.id === "-1" || me.jsonData.id === null || me.jsonData.id === undefined) {
        let link = process.env.VUE_APP_PATH_API + me.pathResource;
        axios
          .post(link, JSON.stringify(me.data))
          .then((response) => {
            me.showModalLoading = false;
            me.gruppo = response.jsonData.data;
            me.$router.replace(me.linkback + "?idPaziente=" + me.idPaziente).catch((err) => {
              err;
            });
          })
          .catch((error) => {
            me.showModalLoading = false;
            this.$bvModal
              .msgBoxOk(error.response.data.messaggio, {
                title: "ATTENZIONE",
                size: "sm",
                okVariant: "outline-danger",
                headerClass: "sa-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then((value) => {
                this.boxTwo = value;
              })
              .catch((err) => {
                console.log(err);
              });
          });
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.jsonData.id;
        axios
          .put(link, JSON.stringify(me.data))
          .then(() => {
            //me.linkback = me.linkback + "/view/" + response.jsonData.jsonData.id;
            me.$router.replace(me.linkback).catch((err) => {
              err;
            });
          })
          .catch((error) => {
            this.$bvModal
              .msgBoxOk(error.response.data.messaggio, {
                title: "ATTENZIONE",
                size: "sm",
                okVariant: "outline-danger",
                headerClass: "sa-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then((value) => {
                console.log(value);
              })
              .catch((err) => {
                console.log(err);
              });
          });
      }
    },
  },
};
</script>
