<template>
  <sa-page-layout :showModalLoading="showModalLoading" :btnEditVisible="true" :btnBackVisible="true" :linkback="linkback" :pathResource="pathResource" :linkedit="linkedit" :data="jsonData">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <div class="sa-form-section">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <strong>Referto </strong><br />
              {{ jsonData.referto }}
            </b-col>
          </b-row>
        </div>
      </div>
      <hr />
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../../components/PazienteAnagrafica.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function() {
        return { gruppo: { menuGruppo: [] } };
      },
    },
  },
  components: { SaPageLayout, PazienteAnagrafica },

  data() {
    return {
      showModalLoading: false,
      id: "-1",
      linkback: null,
      linkedit: null,
      pathResource: "/gastrovisitagastroenterologica",
      jsonData: {},
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient: {
      get: function() {
        let returnValue = "";
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length > 1) {
              return "/paziente/view/" + this.idPaziente;
            } else {
              if (this.utente.gruppo.menuGruppo.length == 1) {
                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                  return "/paziente/view/" + this.idPaziente;
                } else {
                  return "/paziente/homepaziente";
                }
              }
            }
          }
        }
        return returnValue;
      },
    },
  },
  mounted() {
    let me = this;
    me.jsonData.idPaziente = this.$route.query.idPaziente;
    me.jsonData.tipoEvento = "Gastroenterologia - Visita Gastroenterologica";
    me.id = this.$route.params.id;
    me.linkedit = "/paziente/gastroenterologia/visitagastroenterologica/edit/" + me.id + "?idPaziente=" + me.idPaziente;
    if (me.id !== "-1") {
      me.linkback = "/paziente/gastroenterologia/visitagastroenterologica" + "?idPaziente=" + me.idPaziente;
    } else {
      me.linkback = "/paziente/gastroenterologia/visitagastroenterologica?idPaziente=" + me.idPaziente;
    }
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        });
      }
    },
  },
};
</script>
