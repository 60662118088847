<template>
  <div>
    <b-card :header="domanda.domanda" header-bg-variant="light" border-variant="light">
      <strong>Tipo Risposta:</strong> {{ domanda.tipoRisposta }}
      <br />
      <strong v-if="domanda.tipoRisposta === 'select'">Opzioni:</strong>
      <b-row>
        <b-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4" v-for="risposta in domanda.questionarioDomandaRispostaBeans" :key="risposta.id">
          <strong v-if="domanda.tipoRisposta === 'select'">{{ risposta.label }}.</strong>
          <strong v-if="domanda.tipoRisposta === 'text'">{{ risposta.label }}:</strong>
          {{ risposta.predefinito }}
        </b-col>
      </b-row>
    </b-card>
    <hr />
  </div>
</template>
<script>
// import axios from "axios";
export default {
  props: {
    domanda: {
      Type: Object,
      require: true,
    },
  },
  computed: {},
  data() {
    return {
      jsonData: [],
      pathResource: "/questionari",
      currentPage: 1,
      perPage: 10,
      fields: [
        {
          label: "N°",
          key: "idQuestionario",
          thStyle: "width: 8rem",
          sortable: true,
        },
        {
          label: "Tipo Domanda",
          key: "tipo",
          thStyle: "width: 5rem",
          sortable: true,
        },
      ],
    };
  },
  mounted() {
    let me = this;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      me.showmolalloading = true;
      //   let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      //   axios.get(link + me.idEvento).then((response) => {
      //     me.jsonData = response.data.data;
      //   });
    },
  },
};
</script>
