<template>
  <div>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
            <strong>{{ getLabelTraduora("patient.afa.lblWeight") }}</strong>
            <br />
            {{ jsonData.peso }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
            <strong>{{ getLabelTraduora("patient.afa.lblHeight") }}</strong>
            <br />
            {{ jsonData.altezza }}
          </b-col>
          <b-col cols="12" xs="12" sm="6" md="6" lg="3" xl="3">
            <strong>{{ getLabelTraduora("patient.afa.lblBMI") }}</strong>
            <br />
            {{ jsonData.bmi }}
          </b-col>
          <b-col cols="12" xs="12" sm="6" md="6" lg="3" xl="3">
            <strong>{{ getLabelTraduora("patient.afa.lblBSA") }}</strong>
            <br />
            {{ jsonData.bsa }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblTBW") }}</strong>
            <br />
            {{ jsonData.tbw }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblLeanMass") }}</strong>
            <br />
            {{ jsonData.massaMagra }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblECW") }}</strong>
            <br />
            {{ jsonData.ecw }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblFatMass") }}</strong>
            <br />
            {{ jsonData.massaGrassa }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblBasalMetabolism") }}</strong>
            <br />
            {{ jsonData.metabolismoBasale }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblMuscleMass") }}</strong>
            <br />
            {{ jsonData.massaMuscolare }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
  </div>
</template>
<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";

export default {
  mixins: [UtilityMixin],
  props: {
    jsonData: {
      Type: Object,
      require: true,
    },
  },
};
</script>
