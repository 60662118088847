<template>
    <sa-page-layout ref="pgaView" :backVisible="true" :editVisible="false" :titolo="titolo" :showModalLoading="showModalLoading">
        <template slot="table-filter">
            <b-row>
                <b-col sm="12" md="12" lg="6" class="my-1">
                    <b-input-group prepend="Data" class="mt-3">
                        <date-picker format="DD-MM-YYYY" v-model="dataPrestazione" value-type="timestamp" type="datetime" placeholder="Data Riferimento" class="sa-input-group" @change="onDataRiferimentoContext"></date-picker>
                        <b-input-group-append>
                            <b-button @click="onScaricaExcell">Scarica Excel</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
            </b-row>
        </template>
        <template slot="table-header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>Totale Record: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div style="display: flex; height: 100%;flex-direction: column;">
                <div style="max-height: 10rem"></div>
                <div style="height: 100%">
                    <div class="b-table-sticky-header">
                        <!-- <b-table sticky-header ref="table" :filter="filter" stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table"> -->
                        <b-table sticky-header ref="table" id="tblTamponi" :filter="filter" :filterIncludedFields="filterOn" sort-by="oraPrestazione" :sort-desc="true" stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table"> </b-table>
                    </div>
                </div>
            </div>
        </template>
        <template slot="table-footer">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>Totale Record: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
    </sa-page-layout>
</template>

<script>
import axios from "axios";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { SaPageLayout, DatePicker },
    computed: {
        rows() {
            return this.items.length;
        },
    },
    data() {
        return {
            showModalLoading: false,
            titolo: "Generazione file Protezione Civile",
            dataPrestazione: null,
            perPage: 100,
            pathRESTRequest: "/tamponi/protezionecivile",
            currentPage: 1,
            filter: null,
            filterOn: [],
            fields: [
                {
                    label: "Codice Fiscale",
                    key: "codiceFiscale",
                    sortable: true,
                },
                {
                    label: "Data",
                    key: "dataPrestazione",
                    sortable: true,
                    thStyle: "width: 7rem",
                },
                {
                    label: "Ora",
                    key: "oraPrestazione",
                    sortable: true,
                    thStyle: "width: 6rem",
                },
                {
                    label: "Stato",
                    key: "statoAttuale",
                    sortable: true,
                    thStyle: "width: 7rem",
                },
            ],
            items: [],
        };
    },
    created: function() {
        this.loadData();
    },
    methods: {
        loadData() {},
        onDataRiferimentoContext() {
            let me = this;
            let dataRiferimento = moment(me.dataPrestazione).format("YYYY-MM-DD");
            let link = process.env.VUE_APP_PATH_API + me.pathRESTRequest + "?dataPrestazione=" + dataRiferimento;
            // me.dataPrestazione = dataRiferimento;
            axios.get(link).then((response) => {
                me.items = response.data.data;
            });
        },
        onScaricaExcell() {
            let me = this;
            if (me.dataPrestazione) {
                let dataRiferimento = moment(me.dataPrestazione).format("YYYY-MM-DD");
                window.open(process.env.VUE_APP_PATH_API + "/tamponi/potezioneciviledownload" + "?dataPrestazione=" + dataRiferimento);
            }
            //windows.open(process.env.VUE_APP_PATH_API + me.pathRESTRequest + "?dataPrestazione=" + dataRiferimento);
        },
    },
};
</script>

<style>
.sa-input-group.mx-datepicker {
    display: flex;
    flex: 1 1 0%;
}
.sa-input-group .mx-input-wrapper {
    width: 100%;
}
.sa-input-group .mx-input {
    height: 100%;
}
</style>
