<template>
  <sa-page-layout :showModalLoading="showModalLoading" :btnSaveVisible="true" :btnAnnullaVisible="true" :btnBackVisible="false" :linkback="linkback" :pathResource="pathResource" :data="jsonData">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-header">
      <h5 class="sa-text-left">{{ getTranslate("patient.cardiology.lblData") }}</h5>
    </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <!-- InizioBody  -->
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><h5>{{ getTranslate("patient.cardiology.lblGeneralData") }}</h5></b-col
          >
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblFirstImplantDate") }}</span
            ><br />
            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataPrimoImpianto" type="date" placeholder="Data"></date-picker>
          </b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblFirstImplantDescription") }}</span
            ><br />
            <b-form-input v-model="jsonData.modello1" type="text"></b-form-input
          ></b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblNewImplantSubstitutionDate") }}</span
            ><br />
            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataSostituzione" type="date" placeholder="Data"></date-picker>
          </b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblnewImplantDescription") }}</span
            ><br />
            <b-form-input v-model="jsonData.descrizioneNuovoImpianto" type="text"></b-form-input
          ></b-col>
          <b-col xs="12" sm="8" md="8" lg="8" xl="8"
            ><span>{{ getTranslate("patient.cardiology.lblnewImplantStimulationSystem") }}</span
            ><br />
            <b-form-input v-model="jsonData.sistemaStimolazione" type="text"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblnewImplantBaseFrequency") }}</span
            ><br />
            <b-form-input v-model="jsonData.frequenzaBase" type="number"></b-form-input
          ></b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblnewImplantModel") }}</span
            ><br />
            <b-form-input v-model="jsonData.modelloNuovoImpianto" type="text"></b-form-input
          ></b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblnewImplantSerialNumber") }}</span
            ><br />
            <b-form-input v-model="jsonData.serialeNuovoImpianto" type="text"></b-form-input
          ></b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblnewImplantType") }}</span
            ><br />
            <b-form-input v-model="jsonData.tipoNuovoImpianto" type="text"></b-form-input
          ></b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><h5>{{ getTranslate("patient.cardiology.lblAtrialCatheter") }}</h5></b-col
          >
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblCatheterConstructor") }}</span
            ><br />
            <b-form-input v-model="jsonData.catetereAtrialeCostruttore" type="text"></b-form-input
          ></b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblCatheterModel") }}</span
            ><br />
            <b-form-input v-model="jsonData.catetereAtrialeModello" type="text"></b-form-input
          ></b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblCatheterImplantDate") }}</span
            ><br />
            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataCatetereAtriale" type="date" placeholder="Data"></date-picker>
          </b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblCatheterType") }}</span
            ><br />
            <b-form-input v-model="jsonData.catetereAtrialeTipo" type="text"></b-form-input
          ></b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblCatheterSerialNumber") }}</span
            ><br />
            <b-form-input v-model="jsonData.catetereAtrialeNumeroSeriale" type="text"></b-form-input
          ></b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><h5>{{ getTranslate("patient.cardiology.lblRightVentricularCatheter") }}</h5></b-col
          >
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblCatheterConstructor") }}</span
            ><br />
            <b-form-input v-model="jsonData.catetereVentricolareDxCostruttore" type="text"></b-form-input
          ></b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblCatheterModel") }}</span
            ><br />
            <b-form-input v-model="jsonData.catetereVentricolareDxModello" type="text"></b-form-input
          ></b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblCatheterImplantDate") }}</span
            ><br />
            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataCatetereVentricolareDx" type="date" placeholder="Data"></date-picker>
          </b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblCatheterType") }}</span
            ><br />
            <b-form-input v-model="jsonData.catetereVentricolareDxTipo" type="text"></b-form-input
          ></b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblCatheterSerialNumber") }}</span
            ><br />
            <b-form-input v-model="jsonData.catetereVentricolareDxNumeroSeriale" type="text"></b-form-input
          ></b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><h5>{{ getTranslate("patient.cardiology.lblLeftVentricularCatheter") }}</h5></b-col
          >
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblCatheterConstructor") }}</span
            ><br />
            <b-form-input v-model="jsonData.catetereVentricolareSxCostruttore" type="text"></b-form-input
          ></b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblCatheterModel") }}</span
            ><br />
            <b-form-input v-model="jsonData.catetereVentricolareSxModello" type="text"></b-form-input
          ></b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblCatheterImplantDate") }}</span
            ><br />
            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataCatetereVentricolareSx" type="date" placeholder="Data"></date-picker>
          </b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblCatheterType") }}</span
            ><br />
            <b-form-input v-model="jsonData.catetereVentricolareSxTipo" type="text"></b-form-input
          ></b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblCatheterSerialNumber") }}</span
            ><br />
            <b-form-input v-model="jsonData.catetereVentricolareSxNumeroSeriale" type="text"></b-form-input
          ></b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><h5>{{ getTranslate("patient.cardiology.lblElectricalParameters") }}</h5></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblRightVentricularThreshold") }}</span
            ><br />
            <b-form-input v-model="jsonData.sogliaVentricolareDx" type="number"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLeftVentricularThreshold") }}</span
            ><br />
            <b-form-input v-model="jsonData.sogliaVentricolareSx" type="number"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAtrialThreshold") }}</span
            ><br />
            <b-form-input v-model="jsonData.sogliaAtriale" type="number"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblRightVentricularFlowing") }}</span
            ><br />
            <b-form-input v-model="jsonData.correnteVentricolareDx" type="number"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLeftVentricularFlowing") }}</span
            ><br />
            <b-form-input v-model="jsonData.correnteVentricolareSx" type="number"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAtrialFlowing") }}</span
            ><br />
            <b-form-input v-model="jsonData.correnteAtrial" type="number"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblRightVentricularImpedance") }}</span
            ><br />
            <b-form-input v-model="jsonData.impedenzaVentricolareDx" type="number"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblleftVentricularImpedance") }}</span
            ><br />
            <b-form-input v-model="jsonData.impedenzaVentricolareSx" type="number"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAtrialImpedance") }}</span
            ><br />
            <b-form-input v-model="jsonData.impedenzaAtriale" type="number"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblRightVentricularWaveR") }}</span
            ><br />
            <b-form-input v-model="jsonData.OndaRVentricolareDx" type="number"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLeftVentricularWaveR") }}</span
            ><br />
            <b-form-input v-model="jsonData.OndaRVentricolareSx" type="number"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAtrialWaveR") }}</span
            ><br />
            <b-form-input v-model="jsonData.OndaRAtriale" type="number"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblShockImpedance") }}</span
            ><br />
            <b-form-input v-model="jsonData.shockImpedenza" type="number"></b-form-input
          ></b-col>
        </b-row>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
import DatePicker from "vue2-datepicker";
export default {
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  components: { SaPageLayout, PazienteAnagrafica, DatePicker },
  data() {
    return {
      linkback: null,
      pathResource: "/cardiologiaelettrostimolatori",
      jsonData: {},
      showModalLoading: false,
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient: {
      get: function() {
        if (this.utente.gruppo.menuGruppo) {
          if (this.utente.gruppo.menuGruppo.length > 1) {
            return "/paziente/view/" + this.idPaziente;
          } else {
            if (this.utente.gruppo.menuGruppo.length == 1) {
              if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                return "/paziente/view/" + this.idPaziente;
              } else {
                return "/paziente/homepaziente";
              }
            } else {
              return "";
            }
          }
        } else {
          return "";
        }
      },
    },
  },
  mounted() {
    let me = this;
    me.jsonData.idPaziente = this.$route.query.idPaziente;
    me.jsonData.tipoEvento = "Cardiologia - Anamnesi";
    me.jsonData.idAmbulatorio = sessionStorage.getItem("idAmbulatorio");
    me.id = this.$route.params.id;
    me.linkedit = "/paziente/cardiologia/elettrostimolatore/edit/" + me.id + "?idPaziente=" + me.idPaziente;
    if (me.id !== "-1") {
      me.linkback = "/paziente/cardiologia/elettrostimolatore/view/" + me.id + "?idPaziente=" + me.idPaziente;
      me.loadData();
    } else {
      me.linkback = "/paziente/cardiologia/elettrostimolatore?idPaziente=" + me.idPaziente;
    }
  },

  methods: {
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        });
      }
    },
    getTranslate(text) {
      return this.$i18n.t(text);
    },
  },
};
</script>
