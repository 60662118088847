<template>
    <sa-page-layout :btnBackVisible="true" :btnEditVisible="false" :linkedit="linkedit" :linkback="linkback" :data="jsonData" :pathResource="pathResource">
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <div class="sa-form-section">
                    <h4>Anagrafica</h4>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <strong>Nome</strong><br />
                            {{ jsonData.nome }}
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <strong>Cognome</strong><br />
                            {{ jsonData.cognome }}
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <strong>Codice Fiscale</strong><br />
                            {{ jsonData.identificativo }}
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <strong>Data Nascita</strong><br />
                            {{ formatDate(jsonData.dataNascita) }}
                        </b-col>
                    </b-row>
                </div>
                <hr />
                <div class="sa-form-section">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <strong>Sesso</strong><br />
                            {{ jsonData.sesso }}
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <strong>Provincia di nascita</strong><br />
                            {{ jsonData.provinciaNascita }}
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <strong>Comune Nascita</strong><br />
                            {{ jsonData.comuneNascita }}
                        </b-col>
                    </b-row>
                </div>
                <hr />
                <div class="sa-form-section">
                    <h4>Residenza</h4>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <strong>Indirizzo</strong><br />
                            {{ jsonData.indirizzoResidenza }}
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <strong>Provincia Residenza</strong><br />
                            {{ jsonData.provinciaResidenza }}
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <strong>Comune Residenza</strong><br />
                            {{ jsonData.comuneResidenza }}
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <strong>Cap Residenza</strong><br />
                            {{ jsonData.capResidenza }}
                        </b-col>
                    </b-row>
                </div>
                <hr />
                <div class="sa-form-section">
                    <h4>Contatti</h4>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <strong>Telefono</strong><br />
                            {{ jsonData.telefono }}
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <strong>Email</strong><br />
                            {{ jsonData.email }}
                        </b-col>
                    </b-row>
                </div>
                <hr />
                <div class="sa-form-section">
                    <h4>Note</h4>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            {{ jsonData.note }}
                        </b-col>
                    </b-row>
                </div>
            </div>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import "vue2-datepicker/index.css";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { SaPageLayout },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/atleti/edit/" + me.id;
        me.loadData();
    },
    data() {
        return {
            showmolalloading: false,
            linkback: "/atleti",
            linkedit: null,
            pathResource: "/atleti",
            jsonData: {},
        };
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
            me.showmolalloading = true;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.showmolalloading = false;
                })
                .catch(() => {
                    me.showmolalloading = false;
                });
        },
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD/MM/YYYY");
            } else {
                return "---";
            }
        },
    },
};
</script>
