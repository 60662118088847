<template>
  <div style="padding-top: 10px;">
    <div v-if="show" class="b-table-sticky-header" style="padding-top: 10px;">
      <b-table sticky-header ref="tableActive" stacked="xl" striped hover :items="elements" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
        <template v-for="field in fields" #[`cell(${field.key})`]="{item}">
          <div v-if="field.key == 'farmaco'" :key="field.key">
            <router-link class="sa-edit-link" :to="'/paziente/terapia/view/' + item.id + '?idPaziente=' + idPaziente">{{ item.farmaco }}</router-link>
            <div class="sa-sub-information-link">
              <span>{{ getLabelTraduora(item.posologia) }} - {{ getLabelTraduora("therapy.lblFrequency") }}: {{ getLabelTraduora(item.descrizioneFrequenza) }}</span>
            </div>
          </div>
          <div v-else-if="isIntervallo(field.key)" :key="field.key">
            <paziente-terapia-slot-component @onClickSomministrazione="onClickSomministrazione" :dataRiferimento="dataRiferimento" :somministrazioneKey="field.key" :statiSomministrazioneOptions="statiSomministrazioneOptions" :somministrazione="item" @update="onRefresh"></paziente-terapia-slot-component>
          </div>
          <!-- <div v-else-if="isIntervallo(field.key)" :key="field.key">
                        <font-awesome-icon v-if="getSomministrazione(field.key, item)" :class="getColor(field.key, item)" class="sa-icon-large" icon="pills">{{ item.id }}</font-awesome-icon>
                        <div style="display: block">{{ getDataSomministrazione(field.key, item) }}</div>
                    </div> -->
          <div v-else-if="field.key == 'iterazioniFarmaco'" :key="field.key">
            <b-button :disabled="item.statoDescrizione !== 'paziente.terapia.lblATTIVA'" v-b-modal.modalSomministrazione size="sm" variant="btn btn-purple waves-effect waves-light width-sm no-text" @click="onOpenModal(item, item.index, $event.target)">
              <b-icon icon="pencil"></b-icon>
            </b-button>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import PazienteTerapiaSlotComponent from "./PazienteTerapiaSlotComponent.vue";
import UtilityMixin from "../../../utility/UtilityMixin.js";
export default {
  mixins: [UtilityMixin],
  components: { PazienteTerapiaSlotComponent },
  props: {
    elements: {
      type: Array[Object],
    },
    dataRiferimento: {
      type: Date,
    },
    data: {
      type: Object,
    },
  },
  created() {
    let me = this;
    me.setTabellaAttivi();
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    rows() {
      return this.elements.length;
    },
  },
  // watch: {
  //     elements() {
  //         let me = this;
  //         me.setTabellaAttivi();
  //     },
  // },
  data() {
    return {
      show: true,
      perPage: 10,
      currentPage: 1,
      statiSomministrazioneOptions: [],
      // elements: [],
      fields: [
        {
          label: this.$i18n.t("therapy.lblDrug"),
          key: "farmaco",
          thStyle: "width: 15rem",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    // showComponent() {
    //     this.show = true;
    // },
    // hideComponent() {
    //     this.show = false;
    // },
    // destroy() {
    //     this.$destroy();
    // },
    isIntervallo(key) {
      let returnValue = false;
      let regex = /^\d\d-\d\d/;
      if (regex.test(key)) {
        returnValue = true;
      }
      return returnValue;
    },
    setTabellaAttivi() {
      let me = this;
      let orariSomministrazione = [];
      me.fields = [];
      me.fields.push({
        label: this.$i18n.t("therapy.lblDrug"),
        key: "farmaco",
        thStyle: "width: 15rem",
        sortable: true,
      });
      me.elements.forEach((element) => {
        element.orarioSomministrazione.forEach((oraSomministrazione) => {
          orariSomministrazione.push(parseInt(oraSomministrazione));
        });
      });
      orariSomministrazione = orariSomministrazione.sort(function(a, b) {
        return a - b;
      });
      orariSomministrazione.forEach((element) => {
        let labelStart = element.toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        });
        let labelEnd = (element + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        });
        me.fields.push({
          label: labelStart + " - " + labelEnd,
          key: labelStart + "-" + labelEnd,
          sortable: false,
          thStyle: "width: 5rem",
          tdClass: "text-center",
          thClass: "text-center",
        });
      });
      // this.$refs.childComponent.hideComponent();
      // this.$refs.childComponent.showComponent();
      // PazienteTerapiaStatoComponent.methods.setSlot();
    },
    getSomministrazione(intervallo, item) {
      let me = this;
      let returnValue = false;
      let strDate = moment(me.dataRiferimento.getTime()).format("yyyy-MM-DD");
      let arrayItervallo = intervallo.split("-");
      let dataInizio = moment(strDate + " " + arrayItervallo[0] + ":00", "yyyy-MM-DD HH:mm");
      let dataFine = moment(strDate + " " + arrayItervallo[1] + ":00", "yyyy-MM-DD HH:mm");
      if (item.slotSomministrazioni) {
        item.slotSomministrazioni.forEach((slot) => {
          if (slot.ora >= dataInizio.toDate().getTime() && slot.ora < dataFine.toDate().getTime()) {
            returnValue = true;
          }
        });
        return returnValue;
      }
    },
    getDataSomministrazione(intervallo, item) {
      let me = this;
      let returnValue = "";
      let slot = me.getSlot(intervallo, item);
      returnValue = slot ? moment(slot.ora).format("HH:mm") : "---";

      return returnValue;
    },
    getColor(intervallo, item) {
      let me = this;
      let returnValue = "secondary";
      let slot = me.getSlot(intervallo, item);
      returnValue = slot.stateColor;

      switch (returnValue) {
        case "blue":
          returnValue = "blue";
          break;
        case "green":
          returnValue = "success";
          break;
        case "red":
          returnValue = "danger";
          break;
        default:
          returnValue = "secondary";
          break;
      }

      return returnValue;
    },
    getSlot(intervallo, item) {
      let me = this;
      let returnValue = null;
      let strDate = moment(me.dataRiferimento.getTime()).format("yyyy-MM-DD");
      let arrayItervallo = intervallo.split("-");
      let dataInizio = moment(strDate + " " + arrayItervallo[0] + ":00", "yyyy-MM-DD HH:mm");
      let dataFine = moment(strDate + " " + arrayItervallo[1] + ":00", "yyyy-MM-DD HH:mm");
      item.slotSomministrazioni.forEach((slot) => {
        if (slot.ora >= dataInizio.toDate().getTime() && slot.ora < dataFine.toDate().getTime()) {
          returnValue = slot;
        }
      });
      return returnValue;
    },
    onClickSomministrazione() {
      console.log("onClickSomministrazione");
    },
    onRefresh() {
      let me = this;
      me.$emit("update");
    },
  },
};
</script>
<style scoped>
.col-lg-12 {
  height: 35px;
}
.danger {
  color: red;
}
.success {
  color: green;
}
.blue {
  color: rgb(0, 30, 128);
}
.warning {
  color: #ffc107;
}
</style>
