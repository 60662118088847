<template>
    <sa-page-layout toggleableDimension="1355px" :showModalLoading="showModalLoading" :data="jsonData" :pathResource="pathResource">
        <template slot="toolbar-title">
            <paziente-anagrafica-leonida :data="jsonData"></paziente-anagrafica-leonida>
        </template>
        <template slot="toolbar">
            <b-button v-if="true" :hidden="hidden" variant="primary btn-toolbar sa-margin-right float-sm-right" size="sm" @click="onEdit"><b-icon icon="pencil"></b-icon>Modifica</b-button>
            <b-button v-if="edit" variant="success btn-toolbar sa-margin-right float-sm-right" size="sm" :disabled="btnSalvaDisabled" @click="onSave"><b-icon icon="check2"></b-icon>Salva</b-button>
            <b-button v-if="edit" :hidden="hiddenAnnulla" variant="danger btn-toolbar sa-margin-right float-sm-right" size="sm" @click="onAnnullaModifiche"><b-icon icon="x"></b-icon>Annulla</b-button>
            <firma-component class="float-sm-right primary" v-if="edit" :idEvento="jsonData.id" :pathRestFirma="pathRestFirma" :pathRestValidation="pathRestValidation" :data="jsonDataValidation" :disabled="btnFirmaDisabled" @update="onFirmaUpdate"></firma-component>
        </template>
        <template slot="table-body">
            <b-tabs content-class="mt-3" class="sa-tab" style="height: 100%;" fill @input="onTabInput">
                <b-tab active>
                    <template #title>
                        <span :class="getColorDatiGenerali()"><b-icon :icon="getIconDatiGenerali()"></b-icon>&nbsp;Dati Generali</span>
                    </template>
                    <div class="sa-tab-scroll">
                        <dati-generali-edit-component v-if="editDg" :data="jsonData"></dati-generali-edit-component>
                        <dati-generali-view-component v-else :data="jsonData"></dati-generali-view-component>
                    </div>
                </b-tab>
                <b-tab :disabled="tabellaDisabilitata">
                    <template #title>
                        <span :class="getColorAnamnesi()"><b-icon :icon="getIconAnamnesi()"></b-icon>&nbsp;Anamnesi</span>
                    </template>
                    <div class="sa-tab-scroll">
                        <anamnesi-edit v-if="editAnamnesi" :data="jsonData.visitaAnamnesi"></anamnesi-edit>
                        <anamnesi-view-component v-else :data="jsonData.visitaAnamnesi"></anamnesi-view-component>
                    </div>
                </b-tab>
                <b-tab :disabled="tabellaDisabilitata">
                    <template #title>
                        <span :class="getColorEsameObiettivo()"><b-icon :icon="getIconEsameObiettivo()"></b-icon>&nbsp;Esame Obiettivo</span>
                    </template>
                    <div class="sa-tab-scroll">
                        <esame-obiettivo-edit v-if="editEo" :data="jsonData.visitaEsameObbiettivo"></esame-obiettivo-edit>
                        <esame-obiettivo-view-component v-else :data="jsonData.visitaEsameObbiettivo"></esame-obiettivo-view-component>
                    </div>
                </b-tab>
                <b-tab :disabled="tabellaDisabilitata">
                    <template #title>
                        <span :class="getColorEsameUrine()"><b-icon :icon="getIconEsameUrine()"></b-icon>&nbsp;Urine</span>
                    </template>
                    <div>
                        <urine-edit-component v-if="editUrine" :data="jsonData.visitaEsameUrine"></urine-edit-component>
                        <urine-view-component v-else :data="jsonData.visitaEsameUrine"></urine-view-component>
                    </div>
                </b-tab>
                <b-tab :disabled="tabellaDisabilitata">
                    <template #title>
                        <span :class="getColorEsameSpirografia()"><b-icon :icon="getIconEsameSpirografia()"></b-icon>&nbsp;Spirografia</span>
                    </template>
                    <div class="sa-tab-scroll">
                        <spirografia-edit-component v-if="editSpirografia" :data="jsonData.visitaSpirografia"></spirografia-edit-component>
                        <spirografia-view-component v-else :data="jsonData.visitaSpirografia"></spirografia-view-component>
                    </div>
                </b-tab>
                <b-tab :disabled="tabellaDisabilitata">
                    <template #title>
                        <span :class="getColorEsameEcg()"><b-icon :icon="getIconEsameEcg()"></b-icon>&nbsp;E.C.G.</span>
                    </template>
                    <div class="sa-tab-scroll">
                        <ecg-edit v-if="editEcg" :data="jsonData.visitaEcg"></ecg-edit>
                        <ecg-view-component v-else :data="jsonData.visitaEcg"></ecg-view-component>
                    </div>
                </b-tab>
                <b-tab :disabled="tabellaDisabilitata">
                    <template #title>
                        <span :class="getColoreEsameSpecialisticiIntegrativi()"><b-icon :icon="getIconEsamiSpecialisticiIntegrativi()"></b-icon>&nbsp;Es. Spec. Int.</span>
                    </template>
                    <div class="sa-tab-scroll">
                        <esami-specialistici-integrativi-edit-component v-if="editEsi" :data="jsonData.visitaEsamiSpecialisticiIntegrativi"></esami-specialistici-integrativi-edit-component>
                        <esami-specialistici-integrativi-view-component v-else :data="jsonData.visitaEsamiSpecialisticiIntegrativi"></esami-specialistici-integrativi-view-component>
                    </div>
                </b-tab>
                <b-tab :disabled="tabellaDisabilitata">
                    <template #title>
                        <span :class="getColorAccertamentiSupplementari()"><b-icon :icon="getIconAccertamentiSupplementari()"></b-icon>&nbsp;Accertamenti Supplementari</span>
                    </template>
                    <accertamenti-supplementari-edit-component ref="accertamentiSupplementari" v-if="editAs"></accertamenti-supplementari-edit-component>
                    <accertamenti-supplementari-view-component ref="accertamentiSupplementari" v-else></accertamenti-supplementari-view-component>
                </b-tab>
                <b-tab :disabled="tabellaDisabilitata">
                    <template #title>
                        <span :class="getColoreGiudizioConclusivo()"><b-icon :icon="getIconGiudizioConclusivo()"></b-icon>&nbsp;Giudiz.Concl.</span>
                    </template>
                    <giudizio-conclusivo-edit-component v-if="editGf" :data="jsonData"></giudizio-conclusivo-edit-component>
                    <giudizio-cocnlusivo-view-component v-else :data="jsonData"></giudizio-cocnlusivo-view-component>
                </b-tab>
            </b-tabs>
        </template>
    </sa-page-layout>
</template>
<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import UtilityMixin from "../../utility/UtilityMixin.js";
import axios from "axios";
import moment from "moment";
import AnamnesiViewComponent from "./AnamnesiViewComponent.vue";
import EsameObiettivoViewComponent from "./EsameObiettivoViewComponent.vue";
import UrineViewComponent from "./UrineViewComponent.vue";
import SpirografiaViewComponent from "./SpirografiaViewComponent.vue";
import EcgViewComponent from "./EcgViewComponent.vue";
import AnamnesiEdit from "./AnamnesiEdit.vue";
import EsameObiettivoEdit from "./EsameObiettivoEdit.vue";
import PazienteAnagraficaLeonida from "./PazienteAnagraficaLeonida.vue";
import UrineEditComponent from "./UrineEditComponent.vue";
import SpirografiaEditComponent from "./SpirografiaEditComponent.vue";
import EcgEdit from "./EcgEdit.vue";
import GiudizioCocnlusivoViewComponent from "./GiudizioCocnlusivoViewComponent.vue";
import GiudizioConclusivoEditComponent from "./GiudizioConclusivoEditComponent.vue";
import EsamiSpecialisticiIntegrativiEditComponent from "./EsamiSpecialisticiIntegrativiEditComponent.vue";
import EsamiSpecialisticiIntegrativiViewComponent from "./EsamiSpecialisticiIntegrativiViewComponent.vue";
import DatiGeneraliViewComponent from "./DatiGeneraliViewComponent.vue";
import DatiGeneraliEditComponent from "./DatiGeneraliEditComponent.vue";
import FirmaComponent from "../../paziente/eventi/components/FirmaComponent.vue";
// import AnagraficaComponent from "../../anagrafica/components/AnagraficaComponent.vue";
// import DatePicker from "vue2-datepicker";
import AccertamentiSupplementariEditComponent from "./AccertamentiSupplementariEditComponent.vue";
import AccertamentiSupplementariViewComponent from "./AccertamentiSupplementariViewComponent.vue";
export default {
    mixins: [UtilityMixin],
    components: {
        SaPageLayout,
        AnamnesiViewComponent,
        EsameObiettivoViewComponent,
        UrineViewComponent,
        SpirografiaViewComponent,
        EcgViewComponent,
        AnamnesiEdit,
        EsameObiettivoEdit,
        PazienteAnagraficaLeonida,
        UrineEditComponent,
        SpirografiaEditComponent,
        EcgEdit,
        GiudizioCocnlusivoViewComponent,
        GiudizioConclusivoEditComponent,
        EsamiSpecialisticiIntegrativiEditComponent,
        EsamiSpecialisticiIntegrativiViewComponent,
        DatiGeneraliViewComponent,
        DatiGeneraliEditComponent,
        FirmaComponent,
        // AnagraficaComponent,
        // DatePicker,
        AccertamentiSupplementariEditComponent,
        AccertamentiSupplementariViewComponent,
    },
    data() {
        return {
            id: null,
            idPaziente: null,
            showModalLoading: false,
            linkback: null,
            edit: false,
            editDg: false,
            editAnamnesi: false,
            editEo: false,
            editUrine: false,
            editSpirografia: false,
            editEcg: false,
            editAs: false,
            editEsi: false,
            editGf: false,
            hidden: false,
            hiddenAnnulla: false,
            tabellaDisabilitata: false,
            pathResource: "/visite",
            pathResourceSalvataggio: "",
            pathResourceAgenda: "/agende",
            pathResourceAgendaDisponibilita: "/agendadisponibilita",
            pathRestFirma: null,
            indexTabAttiva: null,
            pathRestValidation: "/validation",
            btnFirmaDisabled: false,
            btnSalvaDisabled: false,
            jsonDataValidation: {},
            jsonData: {
                atleta: {
                    tipo: "PATIENT",
                    identificativo: null,
                    tipoIdentificativo: null,
                    cognome: null,
                    nome: null,
                    dataNascita: null,
                    comuneNascitaCodiceIstat: null,
                    comuneResidenzaCodiceIstat: null,
                    comuneDomicilioCodiceIstat: null,
                    indirizzoResidenza: null,
                    indirizzoDomicilio: null,
                    note: null,
                    medicoCurante: null,
                    codiceFiscaleMedicoCurante: null,
                    capDomicilio: null,
                    capResidenza: null,
                    telefono: null,
                    email: null,
                    sesso: null,
                    codiceStruttura: null,
                    comuneResidenza: null,
                    comuneNascita: null,
                    provinciaResidenza: null,
                    provinciaNascita: null,
                },
                tipoAttleta: null,
                statoAttuale: "",
                visitaEsameUrine: { id: null, firma: null },
                visitaAnamnesi: { id: null },
                visitaEsameObbiettivo: { id: null },
                visitaSpirografia: { id: null, firma: null },
                visitaEcg: { id: null, firma: null },
                visitaEsamiSpecialisticiIntegrativi: { id: null, firma: null },
            },
            dataAgenda: {},
            agendeOptions: [{ id: null, titolo: "-Seleziona Agenda-" }],
            disponibilitaOptions: [{ id: null, dalleOre: "-Seleziona Orario-" }],
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.idPaziente = this.$route.query.idPaziente;
        me.linkback = "/worklist";
        me.loadData();
    },
    methods: {
        /*DATI GENERALI*/
        getColorDatiGenerali() {
            let me = this;
            let colore = me.jsonData.firma ? "text-success" : "text-danger";
            return colore;
        },
        getIconDatiGenerali() {
            let me = this;
            let icon = me.jsonData.firma ? "check2" : "exclamation-triangle";
            return icon;
        },
        /*ANAMNESI*/
        getIconAnamnesi() {
            // let icon = "check2";"exclamation-triangle";
            let me = this;
            let icon = me.jsonData.visitaAnamnesi.firma ? "check2" : "exclamation-triangle";

            return icon;
        },
        getColorAnamnesi() {
            let me = this;
            let colore = me.jsonData.visitaAnamnesi.firma ? "text-success" : "text-danger";
            return colore;
        },

        /*ESAME OBIETTIVO */
        getIconEsameObiettivo() {
            //let icon = "exclamation-triangle";ù
            let me = this;
            let icon = me.jsonData.visitaEsameObbiettivo.firma ? "check2" : "exclamation-triangle";
            return icon;
        },

        getColorEsameObiettivo() {
            let me = this;
            let colore = me.jsonData.visitaEsameObbiettivo.firma ? "text-success" : "text-danger";

            return colore;
        },
        /*URINE*/

        getIconEsameUrine() {
            //let icon = "check2";
            let me = this;
            let icon = me.jsonData.visitaEsameUrine.firma ? "check2" : "exclamation-triangle";
            return icon;
        },

        getColorEsameUrine() {
            let me = this;
            let colore = me.jsonData.visitaEsameUrine.firma ? "text-success" : "text-danger";

            return colore;
        },
        /*SPIROGRAFIA*/
        getIconEsameSpirografia() {
            let me = this;
            let icon = me.jsonData.visitaSpirografia.firma ? "check2" : "exclamation-triangle";
            return icon;
        },
        getColorEsameSpirografia() {
            let me = this;
            let colore = me.jsonData.visitaSpirografia.firma ? "text-success" : "text-danger";
            return colore;
        },
        /*ECG */

        getIconEsameEcg() {
            let me = this;
            let icon = me.jsonData.visitaEcg.firma ? "check2" : "exclamation-triangle";
            return icon;
        },
        getColorEsameEcg() {
            let me = this;
            let colore = me.jsonData.visitaEcg.firma ? "text-success" : "text-danger";

            return colore;
        },
        /*SpecialisticiIntegrativi*/
        getIconEsamiSpecialisticiIntegrativi() {
            // let icon = "check2";
            let icon = "exclamation-triangle";
            return icon;
        },
        getColoreEsameSpecialisticiIntegrativi() {
            let me = this;
            let colore = me.jsonData.statoAttuale === "COMPLETO" ? "text-success" : "text-danger";

            return colore;
        },
        /*Accertamenti Supplementari*/
        getIconAccertamentiSupplementari() {
            let me = this;
            let icon = me.jsonData.visitaEsamiSpecialisticiIntegrativi.firma ? "check2" : "exclamation-triangle";
            return icon;
        },

        getColorAccertamentiSupplementari() {
            let me = this;
            let colore = me.jsonData.visitaEsamiSpecialisticiIntegrativi.firma ? "text-success" : "text-danger";
            return colore;
        },

        /*Giudizio Conclusivo*/
        getColoreGiudizioConclusivo() {
            let me = this;
            let colore = me.jsonData.firma ? "text-success" : "text-danger";
            return colore;
        },

        getIconGiudizioConclusivo() {
            let me = this;
            let icon = me.jsonData.firma ? "check2" : "exclamation-triangle";
            return icon;
        },
        editFirma() {
            let me = this;
            if (me.edit) {
                me.editAnamnesi = me.jsonData.visitaAnamnesi.firma !== null ? false : true;
                me.editEo = me.jsonData.visitaEsameObbiettivo.firma !== null ? false : true;
                me.editUrine = me.jsonData.visitaEsameUrine.firma !== null ? false : true;
                me.editSpirografia = me.jsonData.visitaSpirografia.firma !== null ? false : true;
                me.editEcg = me.jsonData.visitaEcg.firma !== null ? false : true;
                me.editAs = me.jsonData.visitaEsamiSpecialisticiIntegrativi.firma !== null ? false : true;
            }
        },
        onEdit() {
            let me = this;
            me.loadData();
            me.edit = true;
            me.hidden = true;
            me.editDg = true;
            me.editFirma();
            me.editEsi = true;
            me.editAs = true;
            me.editGf = true;
            if (me.jsonData.firma !== null) {
                me.editDg = false;
                me.editAnamnesi = false;
                me.editEo = false;
                me.editUrine = false;
                me.editSpirografia = false;
                me.editEcg = false;
                me.editEsi = false;
                me.editAs = false;
                me.editGf = false;
                me.edit = false;
                me.hidden = false;
                this.$bvModal
                    .msgBoxOk("Impossibile effettuare modifiche, documento già validato", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        this.boxOne = value;
                    });
            }
        },
        onAnnullaModifiche() {
            let me = this;
            me.edit = false;
            me.hidden = false;
            me.editDg = false;
            me.editAnamnesi = false;
            me.editEo = false;
            me.editUrine = false;
            me.editSpirografia = false;
            me.editEcg = false;
            me.editEsi = false;
            me.editAs = false;
            me.editGf = false;
            me.loadData();
        },
        loadData() {
            let me = this;
            if (me.id === "-1") {
                me.jsonData.statoAttuale = "";
                me.loadAgende();
                me.showModalLoading = false;
            } else {
                me.showModalLoading = true;
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.jsonData = response.data.data;
                    me.showModalLoading = false;
                    me.loadAgende();
                    me.onTabInput(me.indexTabAttiva);
                    me.editFirma();
                    // me.setFormatStato();
                });
            }
        },
        loadAgende() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceAgenda;
            me.showModalLoading = true;
            axios
                .get(link)
                .then((response) => {
                    me.dataAgenda = response.data.data;
                    me.agendeOptions = me.dataAgenda;
                    me.agendeOptions.unshift({ id: null, titolo: "-Seleziona Agenda-" });
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onInputVisita(value) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceAgendaDisponibilita;
            console.log(link);
            axios
                .get(link, {
                    params: {
                        idAgenda: value,
                        periodo: "G",
                        riferimento: moment(me.jsonData.dataVisita).format("YYYY-MM-DD"),
                        codicePrestazione: me.jsonData.tipoVisita,
                    },
                })
                .then((response) => {
                    console.log(response.data.data);
                    if (response.data.data.length > 0) {
                        me.disponibilitaOptions = response.data.data[0].disponibilita;
                        me.disponibilitaOptions.unshift({ id: null, dalleOre: "-Seleziona Orario-" });
                    }
                });
        },
        onTabInput(index) {
            let me = this;
            me.indexTabAttiva = index;
            if (me.id === "-1") {
                let me = this;
                me.edit = true;
                me.hidden = true;
                me.btnFirmaDisabled = true;
                me.editDg = true;
                me.hiddenAnnulla = true;
                me.tabellaDisabilitata = true;
            } else {
                switch (index) {
                    case 0:
                        me.btnSalvaDisabled = false;
                        me.pathRestFirma = null;
                        me.btnFirmaDisabled = true;
                        me.pathResourceSalvataggio = "/visite";
                        me.jsonDataSalvataggio = me.jsonData;
                        break;
                    case 1:
                        me.btnSalvaDisabled = false;
                        me.pathRestFirma = "/certificatianamnesi";
                        me.jsonDataValidation = me.jsonData.visitaAnamnesi;
                        me.pathResourceSalvataggio = "/certificatianamnesi";
                        me.jsonDataSalvataggio = me.jsonData.visitaAnamnesi;
                        if (me.jsonDataValidation.firma === null || me.jsonDataValidation.firma === "") {
                            me.btnFirmaDisabled = false;
                        } else {
                            me.btnFirmaDisabled = true;
                        }

                        break;
                    case 2:
                        me.btnSalvaDisabled = false;
                        me.pathRestFirma = "/certificatiesameobbiettivo";
                        me.jsonDataValidation = me.jsonData.visitaEsameObbiettivo;
                        me.pathResourceSalvataggio = "/certificatiesameobbiettivo";
                        me.jsonDataSalvataggio = me.jsonData.visitaEsameObbiettivo;
                        if (me.jsonDataValidation.firma === null || me.jsonDataValidation.firma === "") {
                            me.btnFirmaDisabled = false;
                        } else {
                            me.btnFirmaDisabled = true;
                        }
                        break;
                    case 3:
                        me.btnSalvaDisabled = false;
                        me.pathRestFirma = "/visiteesamiurine";
                        me.jsonDataValidation = me.jsonData.visitaEsameUrine;
                        me.pathResourceSalvataggio = "/visiteesamiurine";
                        me.jsonDataSalvataggio = me.jsonData.visitaEsameUrine;
                        if (me.jsonDataValidation.firma === null || me.jsonDataValidation.firma === "") {
                            me.btnFirmaDisabled = false;
                        } else {
                            me.btnFirmaDisabled = true;
                        }
                        break;
                    case 4:
                        me.btnSalvaDisabled = false;
                        me.pathRestFirma = "/visitespirografie";
                        me.jsonDataValidation = me.jsonData.visitaSpirografia;
                        me.pathResourceSalvataggio = "/visitespirografie";
                        me.jsonDataSalvataggio = me.jsonData.visitaSpirografia;
                        if (me.jsonDataValidation.firma === null || me.jsonDataValidation.firma === "") {
                            me.btnFirmaDisabled = false;
                        } else {
                            me.btnFirmaDisabled = true;
                        }
                        break;
                    case 5:
                        me.btnSalvaDisabled = false;
                        me.pathRestFirma = "/visiteecg";
                        me.jsonDataValidation = me.jsonData.visitaEcg;
                        me.pathResourceSalvataggio = "/visiteecg";
                        me.jsonDataSalvataggio = me.jsonData.visitaEcg;
                        if (me.jsonDataValidation.firma === null || me.jsonDataValidation.firma === "") {
                            me.btnFirmaDisabled = false;
                        } else {
                            me.btnFirmaDisabled = true;
                        }
                        break;
                    case 6:
                        me.btnSalvaDisabled = false;
                        me.pathRestFirma = "/visitaesamispecialisticiintegrativi";
                        me.jsonDataValidation = me.jsonData.visitaEsamiSpecialisticiIntegrativi;
                        me.pathResourceSalvataggio = "/visitaesamispecialisticiintegrativi";
                        me.jsonDataSalvataggio = me.jsonData.visitaEsamiSpecialisticiIntegrativi;
                        if (me.jsonDataValidation.firma === null || me.jsonDataValidation.firma === "") {
                            me.btnFirmaDisabled = false;
                        } else {
                            me.btnFirmaDisabled = true;
                        }
                        break;
                    case 7:
                        me.pathRestFirma = null;
                        me.btnFirmaDisabled = true;
                        me.btnSalvaDisabled = true;
                        me.$refs.accertamentiSupplementari.loadData();
                        break;
                    case 8:
                        me.btnSalvaDisabled = false;
                        me.pathRestFirma = "/visite";
                        me.jsonDataValidation = me.jsonData;
                        me.pathResourceSalvataggio = "/visite";
                        me.jsonDataSalvataggio = me.jsonData;
                        if (me.jsonDataValidation.firma === null || me.jsonDataValidation.firma === "") {
                            me.btnFirmaDisabled = false;
                        } else {
                            me.btnFirmaDisabled = true;
                        }
                        break;
                }
            }
        },
        onSave() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceSalvataggio;
            if (me.jsonDataSalvataggio.id === "-1" || me.jsonDataSalvataggio.id === null || me.jsonDataSalvataggio.id === undefined) {
                me.jsonDataSalvataggio.id = me.jsonData.id;
                axios
                    .post(link, JSON.stringify(me.jsonDataSalvataggio))
                    .then((response) => {
                        me.id = response.data.data.id;
                        me.showModalLoading = false;
                        me.onAnnullaModifiche();
                    })
                    .catch((error) => {
                        me.showModalLoading = false;
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            } else {
                link = link + "/" + me.jsonDataSalvataggio.id;
                axios
                    .put(link, JSON.stringify(me.jsonDataSalvataggio))
                    .then(() => {
                        me.showModalLoading = false;
                        me.onAnnullaModifiche();
                    })
                    .catch((error) => {
                        console.log(error);
                        me.showModalLoading = false;
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                console.log(value);
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            }
        },
        onFirmaUpdate() {
            let me = this;
            me.loadData();
        },
    },
};
</script>
