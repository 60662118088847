<template>
  <sa-page-layout :pathResource="pathResource" :showModalLoading="showModalLoading" :linkedit="linkedit">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="toolbar">
      <b-button class="float-sm-right " variant="outline-primary btn-toolbar sa-margin-right" size="sm" v-b-modal.assegnaQuestionario
        ><b-icon icon="plus"></b-icon>{{ this.$i18n.t("paziente.questionari.lblBtnAssignToPatient") }}</b-button
      ></template
    >
    <template slot="table-header">
      <b-row>
        <b-col lg="3">
          <p>{{ this.$i18n.t("clinic.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" responsive stacked="xl" striped hover :items="items" :fields="fields" sort-icon-left head-variant="light" class="sa-b-table">
          <template v-slot:cell(descrizioneQuestionario)="{ item }">
            <router-link class="sa-edit-link" :to="'/paziente/compilazionequestionari/view/' + item.id">{{ item.descrizioneQuestionario }} </router-link>
          </template>
          <template #cell(actions)="row">
            <b-button size="sm" variant="btn btn-danger waves-effect waves-light width-sm no-text" @click="onRemoveQuestionario(0, row.item.id)">
              <b-icon icon="trash"></b-icon>
            </b-button>
          </template>
        </b-table>
      </div>
    </template>
    <template slot="table-footer">
      <b-row>
        <b-col lg="3">
          <p>{{ this.$i18n.t("clinic.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
      <b-modal id="assegnaQuestionario" scrollable title="" size="xl">
        <b-row>
          <b-col lg="3">
            <p>{{ this.$i18n.t("clinic.lblTotalRecords") }}: {{ rowsQuestionari }}</p>
          </b-col>
          <b-col lg="9">
            <b-pagination v-model="currentPageQuestionari" :total-rows="rowsQuestionari" :per-page="perPageQuestionari" align="right" size="sm"></b-pagination>
          </b-col>
        </b-row>
        <b-table sticky-header ref="table" responsive stacked="xl" striped hover :items="questionari" :fields="fields" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(actions)="row">
            <b-button size="sm" variant="btn btn-success waves-effect waves-light width-sm no-text" @click="addQuestionario(row.item.id)" v-show="!questionarioAssegnato(row.item.id)">
              <b-icon icon="plus"></b-icon>
            </b-button>
            <b-button size="sm" variant="btn btn-danger waves-effect waves-light width-sm no-text" @click="onRemoveQuestionario(1, row.item.id)" v-show="questionarioAssegnato(row.item.id)">
              <b-icon icon="trash"></b-icon>
            </b-button>
          </template>
        </b-table>
        <template #modal-footer> <div style="hieght:100px"></div> </template>
      </b-modal>
    </template>
  </sa-page-layout>
</template>
<script>
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
import UtilityMixin from "../../../../utility/UtilityMixin";
import moment from "moment";
import axios from "axios";
export default {
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  components: {
    SaPageLayout,
    PazienteAnagrafica,
  },
  mixins: [UtilityMixin],
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    rows() {
      return this.items.length;
    },
    rowsQuestionari() {
      return this.questionari.length;
    },
    btnNewVisible: {
      get: function() {
        let me = this;
        return UtilityMixin.methods.verificaPermessi(me.utente, "questionari", 2);
      },
    },
    linkPatient: {
      get: function() {
        let returnValue = "";
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length > 1) {
              return "/paziente/view/" + this.idPaziente;
            } else {
              if (this.utente.gruppo.menuGruppo.length == 1) {
                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                  return "/paziente/view/" + this.idPaziente;
                } else {
                  return "/paziente/homepaziente";
                }
              }
            }
          }
        }
        return returnValue;
      },
    },
  },
  data() {
    return {
      pathResource: "/pazientequestionario",
      pathQuestionari: "/questionario",
      linkedit: "/paziente/compilazionequestionari",
      pagelink: "",
      perPage: 10,
      currentPage: 1,
      perPageQuestionari: 10,
      currentPageQuestionari: 1,
      questionari: [],
      note: "",
      pdf: null,
      filter: null,
      showModalLoading: false,
      id: "-1",
      filtro: {},
      fields: [
        {
          label: "Descrizione",
          key: "descrizioneQuestionario",
          sortable: false,
          thStyle: "width: 3rem",
        },
        {
          label: "Data Associazione",
          key: "createDate",
          formatter: (value) => {
            if (!value) {
              return "";
            } else {
              return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            }
          },
          thStyle: "width: 3rem",
          sortable: true,
        },
        {
          label: "Assegnazione",
          key: "actions",
          sortable: false,
          thStyle: "width: 3rem",
        },
        //{ class: "sa-table-column-action-single", thStyle: "width: 10rem" ,key: "actions", label: "" },
      ],
      items: [],
      data: {},
    };
  },
  created: function() {
    let me = this;
    me.id = this.$route.params.id;
    me.data.idPaziente = this.$route.query.idPaziente;
    me.loadData();
  },
  mounted: function() {},
  methods: {
    questionarioAssegnato(id) {
      let me = this;
      let returnValue = false;
      me.items.forEach((element) => {
        if (element.idQuestionario === id) {
          returnValue = true;
        }
      });
      console.log(returnValue);
      return returnValue;
    },
    addQuestionario(id) {
      let me = this;
      let linkAssocia = process.env.VUE_APP_PATH_API + me.pathResource;
      let obj = {
        idPaziente: me.idPaziente,
        idQuestionario: id,
      };
      axios
        .post(linkAssocia, obj)
        .then(() => {
          me.loadData();
        })
        .catch((e) => {
          alert(e.messaggio);
        });
    },
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "?idPaziente=" + me.idPaziente;
      me.showModalLoading = true;
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.items = [];
          me.items = response.data.data;
          console.log(me.items);
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });

      let link2 = process.env.VUE_APP_PATH_API + me.pathQuestionari;

      axios
        .get(link2, { params: me.filtro })
        .then((response) => {
          me.questionari = [];
          me.questionari = response.data.data;
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    onRemoveQuestionario(lista, id) {
      let me = this;
      if (lista === 1) {
        me.items.forEach((element) => {
          if (element.idQuestionario === id) {
            id = element.id;
          }
        });
      }
      let linkDelete = process.env.VUE_APP_PATH_API + this.pathResource + "/" + id;
      console;
      axios
        .delete(linkDelete)
        .then(() => {
          this.loadData();
        })
        .catch((e) => {
          alert(e);
        });
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onClick(item) {
      let me = this;
      me.pdf = "data:application/pdf;base64," + item.pdf;
    },
    // onAdd() {
    //   let me = this;
    //   me.$router.replace(me.pathQuestionari + "/edit/-1?idPaziente=" + me.idPaziente).catch((err) => {
    //     err;
    //   });
    // },
  },
};
</script>
