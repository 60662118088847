<template>
  <sa-page-layout :showModalLoading="showModalLoading" :btnEditVisible="true" :btnAnnullaVisible="false" :btnBackVisible="true" :linkback="linkback" :pathResource="pathResource" :data="jsonData">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-header">
      <h5 class="sa-text-left">Dati</h5>
    </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <!-- InizioBody  -->
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><h5>{{ getTranslate("patient.cardiology.lblGeneralData") }}</h5></b-col
          >
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblWeight") }}</span
            ><br />
            {{ jsonData.peso }}
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblHeight") }}</span
            ><br />
            {{ jsonData.altezza }}
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblBMI") }}</span
            ><br />
            {{ jsonData.bmi }}
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblBSA") }}</span
            ><br />
            {{ jsonData.bsa }}
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblBloodPression") }}</span
            ><br />
            <b-row
              ><b-col xs="12" sm="6" md="6" lg="6" xl="6">{{ jsonData.pas }}</b-col
              ><b-col xs="12" sm="6" md="6" lg="6" xl="6">{{ jsonData.pad }}</b-col></b-row
            >
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblFC") }}</span
            ><br />
            {{ jsonData.fc }}
          </b-col>
        </b-row>
        <b-row
          ><b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><h5>{{ getTranslate("patient.cardiology.lblCommonCarotid") }}</h5></b-col
          ></b-row
        >
        <b-row
          ><b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><h5>{{ getTranslate("patient.cardiology.lblBulbCarotid") }}</h5></b-col
          ></b-row
        >
        <b-row
          ><b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><h5>{{ getTranslate("patient.cardiology.lblInternalCarotid") }}</h5></b-col
          ></b-row
        >
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><h5>{{ getTranslate("patient.cardiology.lblReportNotes") }}</h5></b-col
          >
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><span>{{ getTranslate("patient.cardiology.lblNotes") }}</span>
            <br />
            {{ jsonData.noteLibere }}
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><span>{{ getTranslate("patient.cardiology.lblComment") }}</span>
            <br />
            {{ jsonData.commento }}
          </b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><h5>{{ getTranslate("patient.cardiology.lblCardiovascularRiskFactors") }}</h5></b-col
          >
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblHypertension") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.ipertensione" switch :checked="jsonData.ipertensione"></b-form-checkbox>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblDyslipidemia") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.dislipidemia" switch :checked="jsonData.dislipidemia"></b-form-checkbox>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblDiabetes") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.diabete" switch :checked="jsonData.diabete"></b-form-checkbox>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblObesità") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.obesita" switch :checked="jsonData.obesita"></b-form-checkbox>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblSmoking") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.fumo" switch :checked="jsonData.fumo"></b-form-checkbox>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblCerebrovascularAccidentsFamiliarity") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.familCerebrovascolari" switch :checked="jsonData.familCerebrovascolari"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblIschemicHeartDiseaseFamiliarity") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.familCardiopatiaIsch" switch :checked="jsonData.familCardiopatiaIsch"></b-form-checkbox>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblHypertensionFamiliarity") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.familIpertensione" switch :checked="jsonData.familIpertensione"></b-form-checkbox>
          </b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><h5>{{ getTranslate("patient.cardiology.lblConclusions") }}</h5></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblNormExamination") }}</span
            ><br />
            <span :class="setColor(jsonData.esameNellaNorma)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.esameNellaNorma)"></b-icon></span>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblMildAtherosclerosis") }}</span
            ><br />
            <span :class="setColor(jsonData.aterosclerosiLieve)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.aterosclerosiLieve)"></b-icon></span>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblModerateAtherosclerosis") }}</span
            ><br />
            <span :class="setColor(jsonData.aterosclerosiModerata)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.aterosclerosiModerata)"></b-icon></span>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblSevereAtherosclerosis") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.aterosclerosiSevera" switch :checked="jsonData.aterosclerosiSevera"></b-form-checkbox>
            <span :class="setColor(jsonData.aterosclerosiSevera)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.aterosclerosiSevera)"></b-icon></span>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblCommonCarotidStenosisRight") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.stenosiCcDx" switch :checked="jsonData.stenosiCcDx"></b-form-checkbox>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblCommonCarotidStenosisLeft") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.stenosiCcSx" switch :checked="jsonData.stenosiCcSx"></b-form-checkbox>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblBulbCarotidStenosisRight") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.stenosiBDx" switch :checked="jsonData.stenosiBDx"></b-form-checkbox>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblBulbCarotidStenosisLeft") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.stenosiBSx" switch :checked="jsonData.stenosiBSx"></b-form-checkbox>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblInternalCarotidStenosisRight") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.stenosiCiDx" switch :checked="stenosiCiDx"></b-form-checkbox>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblInternalCarotidStenosisLeft") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.stenosiCiSx" switch :checked="jsonData.stenosiCiSx"></b-form-checkbox>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblSignificantNon-hemodynamicAtherosclerosis ") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.aterosclerosiNonEmodin" switch :checked="jsonData.aterosclerosiNonEmodin"></b-form-checkbox>
          </b-col>
        </b-row>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  components: { SaPageLayout, PazienteAnagrafica },
  data() {
    return {
      linkback: null,
      pathResource: "/cardiologiaecocarotidi",
      jsonData: {},
      showModalLoading: false,
      fontScale: 2,
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient: {
      get: function() {
        if (this.utente.gruppo.menuGruppo) {
          if (this.utente.gruppo.menuGruppo.length > 1) {
            return "/paziente/view/" + this.idPaziente;
          } else {
            if (this.utente.gruppo.menuGruppo.length == 1) {
              if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                return "/paziente/view/" + this.idPaziente;
              } else {
                return "/paziente/homepaziente";
              }
            } else {
              return "";
            }
          }
        } else {
          return "";
        }
      },
    },
  },
  mounted() {
    let me = this;
    me.jsonData.idPaziente = this.$route.query.idPaziente;
    me.jsonData.tipoEvento = "Cardiologia - Anamnesi";
    me.id = this.$route.params.id;
    me.linkedit = "/paziente/cardiologia/ecocarotidi/edit/" + me.id + "?idPaziente=" + me.idPaziente;
    if (me.id !== "-1") {
      me.linkback = "/paziente/cardiologia/ecocarotidi?idPaziente=" + me.idPaziente;
      me.loadData();
    } else {
      me.linkback = "/paziente/cardiologia/ecocarotidi?idPaziente=" + me.idPaziente;
    }
  },

  methods: {
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        });
      }
    },
    getTranslate(text) {
      return this.$i18n.t(text);
    },
    setColor(value) {
      let color = value == true ? "text-success" : "text-danger";
      return color;
    },
    setIcon(value) {
      let icon = value === true ? "check" : "x";
      return icon;
    },
  },
};
</script>
