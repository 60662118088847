<template>
  <div>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <strong>{{ getLabelTraduora("patient.afa.lblCV") }}</strong
            ><br />
            {{ jsonData.cv }} <br /><br />
            <strong>{{ getLabelTraduora("patient.afa.lblCVF") }}</strong> <br />
            {{ jsonData.cvf }} <br /><br />
            <strong>{{ getLabelTraduora("patient.afa.lblUBJVEMS") }}</strong
            ><br />
            {{ jsonData.vems }} <br /><br />
            <strong>{{ getLabelTraduora("patient.afa.lblVemsCvf") }}</strong
            ><br />
            {{ jsonData.vemsCvf }} <br /><br />
            <strong>{{ getLabelTraduora("patient.afa.lblMVV") }}</strong
            ><br />
            {{ jsonData.mvv }}
          </b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <strong>{{ getLabelTraduora("patient.afa.lblCVNormal") }}</strong
            ><br />
            {{ jsonData.normaleCv }} <br /><br />
            <strong>{{ getLabelTraduora("patient.afa.lblCVFNormal") }}</strong> <br />
            {{ jsonData.normaleCvf }} <br /><br />
            <strong>{{ getLabelTraduora("patient.afa.lblUBJVEMSNormal") }}</strong
            ><br />
            {{ jsonData.normaleVems }} <br /><br />
            <strong>{{ getLabelTraduora("patient.afa.lblVemsCvfNormale") }}</strong
            ><br />
            {{ jsonData.normaleIndiceTiffeneau }} <br /><br />
            <strong>{{ getLabelTraduora("patient.afa.lblMVVNormale") }}</strong
            ><br />
            {{ jsonData.normaleMvv }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <strong>{{ getLabelTraduora("patient.afa.lblReport") }} </strong><br />
            {{ jsonData.refertoUrine }}
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <b-row>
              <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <strong>{{ getLabelTraduora("therapy.lblStatus") }} </strong><br />
                {{ jsonData.firma }}
              </b-col>
            </b-row>
            <hr />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";
export default {
  mixins: [UtilityMixin],
  props: {
    jsonData: {
      Type: Object,
      require: true,
    },
  },
};
</script>
