import Vue from "vue";
import VueRouter from "vue-router";
import Main from "../app/pages/Main.vue";
import Login from "../app/pages/Login.vue";
import Paziente from "../app/paziente";
import PazienteSic from "../app/paziente/pazientesic";
import PazienteSicRouter from "../app/paziente/pazientesic/router";
import Pazienti from "../app/pazienti";
import PazienteTerapia from "../app/paziente/terapia";
import MedicinaDelloSport from "../app/paziente/eventi/medicinadellosport";
import NotFound from "../app/pages/NotFound.vue";
import $i18n from "../i18n";
import axios from "axios";
import MainRouter from "../app/pages/router";
import StruttureRouter from "../app/strutture/router";
import FatturazioneRouter from "../app/fatturazione/router";
import UsersRouter from "../app/utenti/router";
import TamponiRouter from "../app/tamponi/router";
import GestioneTamponiRouter from "../app/gestioneantigenici/router";
import TamponiAntigeniciRouter from "../app/tamponiantigenici/router";
import TaskManagerRouter from "../app/taskmanager/router";
import AccettazioneRouter from "../app/accettazione/router";
import AgendeRouter from "../app/agende/router";
import AmbulatoriRouter from "../app/ambulatori/router";
import BancaEmaticaRouter from "../app/bancaematica/router";
import SmsRouter from "../app/sms/router";
import SorveglianzaRefertiRouter from "../app/sorveglianzareferti/router";
import PazientiRouter from "../app/pazienti/router";
import LeonidaRouter from "../app/leonida/router";
import HassistoIntegratorRouter from "../app/hassistointegrator/router";
import CaduceoRouter from "../app/caduceo/router";
import UtilityRouter from "../app/utility/router";
import TamponiAlchymiaRouter from "../app/tamponialchymia/router";
import ImportTamponiCsvRouter from "../app/importtamponicsv/router";

import PazienteAllegatiRouter from "../app/paziente/allegati/router";

import PazienteDiabetologiaRouter from "../app/paziente/eventi/diabetologia/router";

import Questionari from "../app/utility/questionari";

import PazienteGastroRouter from "../app/paziente/eventi/gastroenterologia/router";

import PazienteCardioRouter from "../app/paziente/eventi/cardiologia/router";

import PazienteHyperHealthEventiRouter from "../app/paziente/eventi/hyperhealth/router";

import PazienteCompilazioneQuestionariRouter from "../app/paziente/eventi/compilazione-questionari/router";

import PazienteMedicinaSport from "../app/paziente/eventi/medicinadellosport/router";

Vue.use(VueRouter);

let mainChildren = MainRouter.concat(
  FatturazioneRouter,
  UsersRouter,
  TamponiRouter,
  TamponiAntigeniciRouter,
  TaskManagerRouter,
  AccettazioneRouter,
  AgendeRouter,
  AmbulatoriRouter,
  BancaEmaticaRouter,
  SmsRouter,
  SorveglianzaRefertiRouter,
  PazientiRouter,
  LeonidaRouter,
  CaduceoRouter,
  HassistoIntegratorRouter,
  UtilityRouter,
  StruttureRouter,
  PazienteSicRouter,
  GestioneTamponiRouter,
  TamponiAlchymiaRouter,
  ImportTamponiCsvRouter
);

mainChildren.push({ path: "*", component: NotFound });

let patientMain = [
  { path: "/", redirect: { name: "HomePaziente" } },
  { name: "PazienteView", path: "/paziente/view/:id", component: Paziente.PazienteView, meta: { title: "pazienti.lblTitle" } },
  { name: "PazienteEdit", path: "/paziente/edit/:id", component: Pazienti.PazienteEdit, meta: { title: "pazienti.lblTitle" } },
  { name: "PazienteDashboard", path: "/paziente/dashboard", component: Paziente.PazienteDashboard, meta: { title: "pazienti.lblTitle" } },

  { name: "PazienteAnamnesiList", path: "/paziente/anamnesi", component: Paziente.PazienteAnamnesiList, meta: { title: "pazienti.lblTitle" } },
  { name: "PazienteAnamnesiEdit", path: "/paziente/anamnesi/edit/:id", component: Paziente.PazienteAnamnesiEdit, meta: { title: "pazienti.lblTitle" } },
  { name: "PazienteAnamnesiView", path: "/paziente/anamnesi/view/:id", component: Paziente.PazienteAnamnesiView, meta: { title: "pazienti.lblTitle" } },
  //{ name: "TeleconsultoList", path: "/teleconsulto", component: Teleconsulto.TeleconsultoList, meta: { title: "teleconsulto.lblTitle" } },
  { name: "PazienteTeleconsultiList", path: "/paziente/teleconsulti", component: Paziente.PazienteTeleconsultiList, meta: { title: "teleconsulto.lblTitle" } },
  { name: "PazienteTeleconsultiView", path: "/paziente/teleconsulti/view/:id", component: Paziente.PazienteTeleconsultoView, meta: { title: "teleconsulto.lblTitle" } },
  { name: "PazienteTeleconsultoEdit", path: "/paziente/teleconsulti/edit/:id", component: Paziente.PazienteTeleconsultoEdit, meta: { title: "teleconsulto.lblTitle" } },
  { name: "PazienteDietaList", path: "/paziente/diete", component: Paziente.PazienteDietaList, meta: { title: "patient.diet.lblTitle" } },
  { name: "PazienteDietaView", path: "/paziente/dieta/view/:id", component: Paziente.PazienteDietaView, meta: { title: "patient.diet.lblTitle" } },
  { name: "PazienteDietaEdit", path: "/paziente/dieta/edit/:id", component: Paziente.PazienteDietaEdit, meta: { title: "patient.diet.lblTitle" } },
  { name: "PazienteTeleconsulto", path: "/paziente/teleconsultoassisto/:id", component: Paziente.PazienteTeleconsulto, meta: { title: "pazienti.lblTitle" } },
  { name: "PazienteTeleconsulto", path: "/paziente/teleconsultohassisto/:id", component: Paziente.PazienteTeleconsultoHassisto, meta: { title: "pazienti.lblTitle" } },
  { name: "PazienteTeleconsulto", path: "/paziente/teleconsulti/:id", component: Paziente.PazienteTeleconsulto, meta: { title: "pazienti.lblTitle" } },

  { name: "PazienteTerapiaList", path: "/paziente/terapia", component: PazienteTerapia.PazienteTerapiaList, meta: { title: "therapy.lblTitle" } },
  { name: "PazienteTerapiaView", path: "/paziente/terapia/view/:id", component: PazienteTerapia.PazienteTerapiaView, meta: { title: "therapy.lblTitle" } },
  { name: "PazienteTerapiaEdit", path: "/paziente/terapia/edit/:id", component: PazienteTerapia.PazienteTerapiaEdit, meta: { title: "therapy.lblTitle" } },

  { name: "AnalisiRischiList", path: "/paziente/analisirischi", component: Paziente.AnalisiRischiList, meta: { title: "riskAnalysis.lblTitle" } },
  { name: "AnalisiRischiView", path: "/paziente/analisirischi/view/:id", component: Paziente.AnalisiRischiView, meta: { title: "riskAnalysis.lblTitle" } },
  { name: "AnalisiRischiEdit", path: "/paziente/analisirischi/edit/:id", component: Paziente.AnalisiRischiEdit, meta: { title: "riskAnalysis.lblTitle" } },

  // { name: "PazienteEcocardioList", path: "/paziente/ecocardio", component: Paziente.RefertazioneEcocardioList, meta: { title: "therapy.lblTitle" } },
  // { name: "PazienteEcocardioView", path: "/paziente/ecocardio/view/:id", component: Paziente.RefertazioneEcocardioView, meta: { title: "therapy.lblTitle" } },
  // { name: "PazienteEcocardioEdit", path: "/paziente/ecocardio/edit/:id", component: Paziente.RefertazioneEcocardioEdit, meta: { title: "therapy.lblTitle" } },

  { name: "PazienteQuestionariList", path: "/questionari", component: Questionari.PazienteQuestionariList, meta: { title: "patient.survey.lblTitle" } },
  { name: "PazienteQuestionariEdit", path: "/questionari/edit/:id", component: Questionari.PazienteQuestionariEdit, meta: { title: "patient.survey.lblTitle" } },
  { name: "PazienteQuestionariView", path: "/questionari/view/:id", component: Questionari.PazienteQuestionariView, meta: { title: "patient.survey.lblTitle" } },

  { name: "PazienteLinkUtili", path: "/paziente/linkutili", component: Paziente.PazienteLinkUtili, meta: { title: "patient.menu.link" } },
  { name: "", path: "/paziente/rangeconfiguration", component: Paziente.RangeConfiguration, meta: { title: "menu.pazienti.rangeConfiguration" } },
  { name: "Edit", path: "/paziente/rangeconfiguration/edit/:id", component: Paziente.RangeConfigurationEdit, meta: { title: "menu.pazienti.rangeConfiguration" } },
  /*PazienteDevice*/

  { name: "PazienteDeviceList", path: "/paziente/hassistokits", component: Paziente.PazienteDeviceList, meta: { title: "patient.menu.lblKits" } },
  { name: "PazienteDeviceEdit", path: "/paziente/hassistokits/edit/:id", component: Paziente.PazienteDeviceEdit, meta: { title: "kit da assegnare" } },

  { name: "PazienteLifeStyle", path: "/paziente/lifestyle", component: Paziente.PazienteLifeStyle, meta: { title: "patient.lifestyle.lblTitle" } },
  { name: "PazienteHome", path: "/paziente/homepaziente", component: Paziente.PazienteHome, meta: { title: "home.lblTitle" } },

  /*MEDICINA DELLO SPORT */
  { name: "AfaEdit", path: "/paziente/medicinadellosportafa/edit/:id", component: MedicinaDelloSport.AfaEdit, meta: { title: "patient.afa.lblTitle" } },
  { name: "AfaList", path: "/paziente/medicinadellosportafa/", component: MedicinaDelloSport.AfaList, meta: { title: "patient.afa.lblTitle" } },
  { name: "AfaView", path: "/paziente/medicinadellosportafa/view/:id", component: MedicinaDelloSport.AfaView, meta: { title: "patient.afa.lblTitle" } },

  // /*DIABETOLOGIA */
  // { name: "DiabetologiaPrimaVisitaEdit", path: "/paziente/diabetologiaprimavisita/edit/:id", component: Diabetologia.PrimaVisitaEdit, meta: { title: "Diabetologia - Prima Visita" } },
  // { name: "DiabetologiaPrimaVisitaList", path: "/paziente/diabetologiaprimavisita/", component: Diabetologia.PrimaVisitaList, meta: { title: "Diabetologia - Prima Visita" } },
  // { name: "DiabetologiaPrimaVisitaView", path: "/paziente/diabetologiaprimavisita/view/:id", component: Diabetologia.PrimaVisitaView, meta: { title: "Diabetologia - Prima Visita" } },

  /*PAZIENTI SIC*/
  { name: "PazienteSicView", path: "/paziente/sic/view/:id", component: PazienteSic.PazienteSic, meta: { title: "pazienti.lblTitle" } },

  { name: "PazienteSicIntolleranzeList", path: "/paziente/sic/intolleranze", component: PazienteSic.PazienteSicIntolleranzeList, meta: { title: "pazienti.lblTitle" } },
  { name: "PazienteSicIntolleranzeView", path: "/paziente/sic/intolleranze/view/:id", component: PazienteSic.PazienteSicIntolleranzeView, meta: { title: "pazienti.lblTitle" } },
  { name: "PazienteSicIntolleranzeEdit", path: "/paziente/sic/intolleranze/edit/:id", component: PazienteSic.PazienteSicIntolleranzeEdit, meta: { title: "pazienti.lblTitle" } },

  { name: "PazienteSicAllergieList", path: "/paziente/sic/allergie", component: PazienteSic.PazienteSicAllergieList, meta: { title: "pazienti.lblTitle" } },
  { name: "PazienteSicAllergieView", path: "/paziente/sic/allergie/view/:id", component: PazienteSic.PazienteSicAllergieView, meta: { title: "pazienti.lblTitle" } },
  { name: "PazienteSicAllergieEdit", path: "/paziente/sic/allergie/edit/:id", component: PazienteSic.PazienteSicAllergieEdit, meta: { title: "pazienti.lblTitle" } },
];

let patientChildren = patientMain.concat(
  PazienteAllegatiRouter,
  PazienteDiabetologiaRouter,
  PazienteHyperHealthEventiRouter,
  PazienteGastroRouter,
  PazienteCardioRouter,
  PazienteCompilazioneQuestionariRouter,
  PazienteMedicinaSport
);

patientChildren.push({ path: "/paziente/*", component: NotFound });

const routes = [
  {
    name: "Login",
    path: "/login",
    component: Login,
    hidden: true,
  },
  {
    name: "Paziente",
    path: "/paziente",
    component: Paziente.Paziente,
    children: patientChildren,
  },
  {
    path: "/",
    component: Main,
    children: mainChildren,
  },
  { path: "/installation", redirect: { name: "Installation" } },
  { path: "*", redirect: { name: "Login" } },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  axios.defaults.params = { language: to.query.language };
  if (to.query.language) {
    $i18n.locale = to.query.language;
  }

  if (to.query.language === "it") {
    import("vue2-datepicker/locale/it");
  } else if (to.query.language === "tr") {
    import("vue2-datepicker/locale/tr");
  } else {
    import("vue2-datepicker/locale/en");
  }

  if (axios.defaults.params.language === undefined || axios.defaults.params.language === null) {
    axios.defaults.params = { language: "it" };
  }
  let loginData = { access_token: to.query.authToken }; //JSON.parse(localStorage.getItem("loginData"));
  if (!to.query.authToken) {
    loginData = { access_token: from.query.authToken };
  }
  let accessToken = loginData == null ? undefined : loginData.access_token;

  if (to.path == "/login") {
    next();
  } else if (to.path == "/installation") {
    next();
  } else if (!to.query.authToken && accessToken) {
    if (to.path.includes("/paziente")) {
      if (from.query.idPaziente) {
        if (!to.path.includes("/paziente/edit") && !to.path.includes("/paziente/view")) {
          to.query.idPaziente = from.query.idPaziente;
        }
      }
    }
    to.query.language = $i18n.locale;
    to.query.authToken = accessToken;
    next({ path: to.path, query: to.query });
    //next({ path: to.path, query: { language: $i18n.locale, authToken: accessToken } });
  } else {
    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    axios.defaults.headers["Content-Type"] = "application/json";
    //        axios.defaults.params = { language: $i18n.locale };
    if (to.name == "Login") {
      next();
    } else if (loginData === null || accessToken === undefined) {
      next("/login");
    } else {
      let link = process.env.VUE_APP_PATH_API + "/validate";
      axios
        .get(link)
        .then(() => {
          next();
          if (document.body.classList.contains("sidebar-closed")) {
            document.body.classList.remove("sidebar-open");
            document.body.classList.add("sidebar-collapse");
          }
        })
        .catch(() => {
          localStorage.clear();
          next("Login");
        });
    }
  }
});

export default router;
