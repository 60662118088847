<template>
  <sa-page-layout :btnNewVisible="true" :pathResource="pathResource" :showModalLoading="showModalLoading" :linkedit="linkedit">
    <template slot="toolbar">
      <!-- <print-component :reportName="reportName" :data="items"></print-component> -->
      <print-component :reportName="reportName" :data="items" v-if="btnPrintDisabled"></print-component>
    </template>
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="toolbar-row">
      <paziente-dashboard-component :idPaziente="idPaziente"></paziente-dashboard-component>
    </template>
    <template slot="table-header"> </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <b-tabs content-class="mt-3" class="sa-tab" style="height: 100%;" fill @input="onTabInput">
          <b-tab active>
            <template #title>
              {{ getLabelTraduora("patient.afa.lblLastVisit") }}
            </template>
            <div style="padding-top: 10px;">
              <visita-hh-view-component :idEvento="id" :key="componentKey"> </visita-hh-view-component>
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              {{ getLabelTraduora("patient.afa.lblHistoricalVisit") }}
            </template>
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
              <b-col lg="3">
                <p>{{ this.$i18n.t("ambulatori.lblTotalRecords") }}: {{ rows }}</p>
              </b-col>
              <b-col lg="9">
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
              </b-col>
            </b-row>
            <div style="padding-top: 10px;">
              <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" :filter="filter" responsive stacked="xl" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                  <template #cell(dataEvento)="{ item }">
                    <router-link class="sa-edit-link" :to="'/paziente/hyperhealtheventi/visitahh/view/' + item.id + '?idPaziente=' + idPaziente">{{ formatDate(item.dataEvento) }}</router-link>
                  </template>
                  <template #cell(actions)="row">
                    <b-button size="sm" variant="btn btn-purple waves-effect  waves-light width-sm no-text" style="margin-right:3px" @click="onStampa(row.item)">
                      <b-icon icon="printer"></b-icon>
                    </b-button>
                  </template>
                </b-table>
              </div>
            </div>
            <div style="margin-top: 200px;">
              <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                  <p>{{ this.$i18n.t("ambulatori.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                  <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </template>
    <template slot="table-footer">
      <b-modal ref="mdlStampaReferto" size="xl" style="height: 100%;">
        <b-embed type="iframe" :src="stampaReport" allowfullscreen></b-embed>
      </b-modal>
    </template>
  </sa-page-layout>
</template>
<script>
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
import UtilityMixin from "../../../../utility/UtilityMixin";
import VisitaHhViewComponent from "./VisitaHhViewComponent.vue";
import moment from "moment";
import axios from "axios";
import PazienteDashboardComponent from "../../../components/PazienteDashboardComponent.vue";
import PrintComponent from "../../../../utility/components/PrintComponent.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function() {
        return { gruppo: { menuGruppo: [] } };
      },
    },
  },
  components: { SaPageLayout, PazienteAnagrafica, VisitaHhViewComponent, PazienteDashboardComponent, PrintComponent },
  mixins: [UtilityMixin],
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    rows() {
      return this.items.length;
    },
    linkPatient: {
      get: function() {
        let returnValue = "";
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length > 1) {
              return "/paziente/view/" + this.idPaziente;
            } else {
              if (this.utente.gruppo.menuGruppo.length == 1) {
                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                  return "/paziente/view/" + this.idPaziente;
                } else {
                  return "/paziente/homepaziente";
                }
              }
            }
          }
        }
        return returnValue;
      },
    },
  },
  data() {
    return {
      pathResource: "/pazienteeventivisite",
      filter: null,
      showModalLoading: false,
      id: "-1",
      componentKey: 0,
      linkedit: "/paziente/hyperhealtheventi/visitahh",
      pathResourceStampa: "/print/createreport",
      stampaReport: null,
      reportNameStoricoVisite: "ReportVisita",
      reportName: "ReportVisita",
      btnPrintDisabled: false,
      currentPage: 1,
      perPage: 10,
      items: [],
      fields: [
        {
          label: "Data Evento",
          key: "dataEvento",
          sortable: true,
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 3rem",
        },
      ],
    };
  },
  mounted() {
    let me = this;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "?idPaziente=" + me.idPaziente;
      me.showModalLoading = true;
      axios
        .get(link)
        .then((response) => {
          console.log(response);
          me.items = response.data.data;
          if (me.items[0]) {
            me.id = me.items[0].id;
          }
          me.componentKey++;
          me.showModalLoading = false;
        })
        .catch((e) => {
          console.log(e);
          me.showModalLoading = false;
        });
    },
    onStampa(row) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + row.id;
      axios.get(link).then((response) => {
        let printData = response.data.data;
        let linkReport = process.env.VUE_APP_PATH_API + me.pathResourceStampa;
        axios.post(linkReport, { reportName: me.reportNameStoricoVisite, data: printData }).then((response) => {
          me.$refs.mdlStampaReferto.show();
          me.stampaReport = "data:application/pdf;base64," + response.data.base64;
        });
      });
    },
    formatDate(value) {
      if (value) {
        return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
      } else {
        return "---";
      }
    },
    onTabInput(index) {
      let me = this;
      if (index === 1) {
        me.btnPrintDisabled = false;
      } else {
        me.btnPrintDisabled = true;
      }
    },
  },
};
</script>
