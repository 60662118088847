<template>
    <div>
        <b-row>
            <b-col md="4">
                <span class="sa-form-label">{{ this.$i18n.t("therapy.lblStatus") }}</span>
                <b-form-select v-model="data.idStato">
                    <option v-for="option in statiSomministrazioneOptions" :key="option.id" :value="option.id">
                        {{ getLabelTraduora(option.descrizione) }}
                    </option>
                </b-form-select>
                <!-- <b-form-select id="inline-form-custom-select-pref" v-model="data.idStato" class="mb-2 mr-sm-2 mb-sm-0" :text-field="getLabelTraduora(descrizione)" value-field="id" :options="statiSomministrazioneOptions" :value="null"></b-form-select> -->
            </b-col>
            <b-col md="4">
                <span class="sa-form-label">{{ this.$i18n.t("therapy.lblDateOfAdministration") }}</span>
                <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="data.statoDataOra" type="datetime" placeholder="Data Somministrazione"></date-picker>
            </b-col>
            <b-col md="4">
                <span class="sa-form-label">{{ this.$i18n.t("therapy.lblQuantity") }}</span>
                <template>
                    <b-form-spinbutton id="sb-step" v-model="data.quantita" min="0" max="1000000000" step="0.50"></b-form-spinbutton>
                </template>
            </b-col>
            <b-col md="12">
                <span class="sa-form-label">{{ this.$i18n.t("therapy.lblNote") }}</span>
                <b-form-textarea v-model="data.note" type="search" placeholder="Note" rows="3" no-resize></b-form-textarea>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <div class="sa-padding-right text-right col">
                <b-button type="button" variant="secondary" @click="onCancelModal">{{ this.$i18n.t("global.lblBtnCancel") }}</b-button>
                <b-button type="button" variant="primary" @click="onSomministra">{{ this.$i18n.t("paziente.terapia.lblBtnSomministra") }}</b-button>
            </div>
        </b-row>
    </div>
</template>

<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
    props: {
        data: {
            type: Object,
            default: function() {
                return {};
            },
        },
    },
    mixins: [UtilityMixin],
    mounted() {
        let me = this;
        me.id = me.$root.id;
        me.loadStatoSomministrazione();
    },
    components: { DatePicker },
    data() {
        return {
            pathResourcesSomministrazioneStato: "/pazienteterapiesomministrazionistati",
            pathResourcesSomministrazione: "/pazienteterapiesomministrazioni",
            id: "-1",
            statiSomministrazioneOptions: [],
        };
    },
    methods: {
        loadStatoSomministrazione() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourcesSomministrazioneStato;
            me.showModalLoading = true;
            axios
                .get(link)
                .then((response) => {
                    me.showModalLoading = false;
                    if (response.data.data) {
                        me.statiSomministrazioneOptions = response.data.data;
                    }
                })
                .catch((e) => {
                    me.showModalLoading = false;
                    console.log("Error: " + e + " " + link);
                });
        },
        onCancelModal() {
            let me = this;
            me.$emit("closeModal");
        },
        onSomministra() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourcesSomministrazione + "?idPaziente=" + me.data.idPaziente;
            me.showModalLoading = true;
            let jsonData = JSON.stringify(me.data);
            axios
                .post(link, jsonData)
                .then(() => {
                    me.showModalLoading = false;
                    me.$emit("closeModal", true);
                })
                .catch((e) => {
                    me.showModalLoading = false;
                    console.log("Errore:" + e);
                });
        },
    },
};
</script>

<style></style>
