<template>
    <div>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <strong>Nome</strong><br />
                        {{ jsonData.nome }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <strong>Cognome</strong><br />
                        {{ jsonData.cognome }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <strong>Data Nascita</strong><br />
                        {{ formatDate(jsonData.dataNascita) }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <strong>Tipo Atleta</strong><br />
                        {{ jsonData.tipoAtleta }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <strong>Sport</strong><br />
                        {{ jsonData.sport }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <strong>Tipo Visita</strong><br />
                        {{ jsonData.tipoVisita }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <strong>Data Prenotazione</strong><br />
                        {{ formatDate(jsonData.dataPrenotazione) }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <strong>Data Visita</strong><br />
                        {{ formatDate(jsonData.dataVisita) }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <strong>Orario Visita</strong><br />
                        {{ jsonData.oraVisita }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <strong>Data Scadenza Certificato</strong><br />
                        {{ formatDate(jsonData.scadenzaCertificato) }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <strong>Telefono</strong><br />
                        {{ jsonData.telefono }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <strong>Email</strong><br />
                        {{ jsonData.email }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <strong>Note</strong><br />
                        {{ jsonData.noteAggiuntive }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
    </div>
</template>
<script>
import moment from "moment";
export default {
    props: {
        jsonData: {
            Type: Object,
            default: function() {
                return {
                    nome: "",
                    cognome: "",
                    dataNascita: null,
                    sport: "",
                    tipoVisita: "",
                    noteAggiuntive: "",
                    tipoAtleta: "",
                    dataVisita: null,
                    oraVisita: "",
                    telefono: "",
                    email: "",
                    scadenzaCertificato: null,
                    dataPrenotazione: null,
                };
            },
        },
    },
    data() {
        return {};
    },
    methods: {
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY");
            } else {
                return "---";
            }
        },
    },
};
</script>
