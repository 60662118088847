<template>
  <b-card>
    <b-card-body>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblFinalJudgement") }}</span> <b-form-textarea rows="4" max-rows="9" v-model="data.giudizioFinale" :disabled="!edit"></b-form-textarea>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <span class="sa-form-label">{{ getLabelTraduora("therapy.lblStatus") }}:</span><br />
          <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblPhysicalExamination") }}: </span> {{ data.firmaEo }} <br />
          <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblElectrocardiogram") }}: </span> {{ data.firmaEcg }} <br />
          <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblFinalJudgement") }}: </span>{{ data.firmaGf }}
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";

export default {
  mixins: [UtilityMixin],
  props: ["data", "edit"],
  data() {
    return {};
  },
};
</script>
