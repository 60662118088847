import SorveglianzaReferti from "../";

const routes = [
    { name: "SorveglianzaRefertiList", path: "/sorveglianzareferti", component: SorveglianzaReferti.SorveglianzaRefertiList, meta: { label: "Lista Sorveglianza Referti" } },
    { name: "SorveglianzaRefertiView", path: "/sorveglianzareferti/view/:id", component: SorveglianzaReferti.SorveglianzaRefertiView, meta: { label: "View Sorveglianza Referti" } },
    { name: "SorveglianzaRefertiUtentiList", path: "/sorveglianzarefertiutenti", component: SorveglianzaReferti.SorveglianzaRefertiUtentiList, meta: { label: "Lista Sorveglianza Referti Utenti" } },
    { name: "SorveglianzaRefertiUtentiView", path: "/sorveglianzarefertiutenti/view/:id", component: SorveglianzaReferti.SorveglianzaRefertiUtentiView, meta: { label: "View Sorveglianza Referti" } },
    { name: "SorveglianzaRefertiUtentiEdit", path: "/sorveglianzarefertiutenti/edit/:id", component: SorveglianzaReferti.SorveglianzaRefertiUtentiEdit, meta: { label: "Modifica Sorveglianza Rreferti Utenti" } },
];

export default routes;
