<template>
  <div class="sa-component-patient-info">
    <b-row>
      <b-col>
        <img :src="patientAvatar" class="rounded-circle avatar-toolbar img-thumbnail float-left mr-3" alt="profile-image" />
        <h4 class="m-0">
          <router-link v-if="patientLink" class="sa-edit-link" :to="linkPatient">{{ dataValue.cognome }} {{ dataValue.nome }}</router-link>
          <span v-else class="">{{ dataValue.cognome }} {{ dataValue.nome }}</span>
        </h4>
        <p class="text-muted">
          <i>
            {{ dataValue.codiceFiscale }}
          </i>

          <br />
          <span
            ><b>{{ getTraduzione("patients.lblBirthDate") }}: </b> {{ formatDate(dataValue.dataNascita) }} </span
          ><span>
            <b>{{ getTraduzione("patient.afa.lblYearsNumber") }}:</b> {{ calcoloEta(dataValue.dataNascita) }}
          </span>
        </p>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import axios from "axios";
import female from "@/assets/images/female.jpg";
import male from "@/assets/images/male.jpg";
import moment from "moment";
export default {
  name: "PazienteAnagrafica",
  components: {},
  props: {
    linkPatient: {
      type: String,
      required: true,
      default: function() {
        return null;
      },
    },
    idPaziente: {
      type: String,
      default: function() {
        return null;
      },
    },
    patientLink: {
      type: Boolean,
      default: function() {
        return true;
      },
    },
    data: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  data() {
    return {
      pathResource: "/pazienti",
      patientData: { id: null },
    };
  },
  computed: {
    patientAvatar: {
      get: function() {
        let me = this;
        if (me.dataValue.sesso === "M") {
          return male;
        } else {
          return female;
        }
      },
    },
    dataValue() {
      let me = this;
      if (me.data === null) {
        return me.patientData;
      }

      return me.data;
    },
  },
  watch: {
    idPaziente: {
      handler: function(newValue) {
        if (newValue !== null) {
          this.loadData();
        }
      },
      immediate: true,
    },
  },
  methods: {
    formatDate(value) {
      if (value) {
        return moment(new Date(value)).format("DD-MM-YYYY");
      } else {
        return "---";
      }
    },
    calcoloEta(value) {
      if (value) {
        let today = new Date();
        let birthDate = new Date(value);
        let age = today.getFullYear() - birthDate.getFullYear();
        let ageMonth = today.getMonth() - birthDate.getMonth();
        let ageDays = today.getDate() - birthDate.getDate();
        if (ageMonth < 0 || (ageMonth === 0 && today.getDate() < birthDate.getDate())) {
          age--;
          ageMonth = 12 + ageMonth;
        }

        if (ageDays < 0) {
          ageMonth--;
          ageDays = birthDate.getDate() - today.getDate();
        }
        return age + " " + this.getTraduzione('patient.lblYears') + " " + ageMonth + " "+this.getTraduzione('patient.lblMonth')+" " + ageDays + " "+this.getTraduzione('patient.lblDays');
      } else {
        return "---";
      }
    },
    calcoloEtaInt(value) {
      if (value) {
        let today = new Date();
        let birthDate = new Date(value);
        let age = today.getFullYear() - birthDate.getFullYear();
        let ageMonth = today.getMonth() - birthDate.getMonth();
        let ageDays = today.getDate() - birthDate.getDate();
        if (ageMonth < 0 || (ageMonth === 0 && today.getDate() < birthDate.getDate())) {
          age--;
          ageMonth = 12;
        }

        if (ageDays < 0) {
          ageMonth--;
          ageDays = birthDate.getDate() - today.getDate();
        }
        this.$emit("getEtaPaziente", age);
        return age;
      } else {
        return 0;
      }
    },
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      axios
        .get(link + me.idPaziente)
        .then((response) => {
          me.patientData = response.data.data;
          me.$emit("getAnagraficaPaziente", me.patientData);
          me.emitData();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getTraduzione(label) {
      return this.$i18n.t(label);
    },
    emitData() {
      let me = this;
      let jsonData = {};
      jsonData.birthdate = me.patientData.dataNascita;
      jsonData.sex = me.patientData.sesso;
      jsonData.age = me.calcoloEtaInt(me.patientData.dataNascita);

      // Dispatch/Trigger/Fire the event
      //document.dispatchEvent(event);
    },
  },
};
</script>
