<template>
  <sa-page-layout :showModalLoading="showModalLoading" :btnSaveVisible="true" :btnAnnullaVisible="true" :btnBackVisible="false" :linkback="linkback" :pathResource="pathResource" :data="jsonData">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-header">
      <h5 class="sa-text-left">Dati</h5>
    </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <!-- InizioBody  -->
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><h5>{{ getTranslate("patient.cardiology.lblBasal") }}</h5></b-col
          >
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorBasalSeptum") }}</span
            ><br />
            <span>{{ jsonData.basaleSettoAntBasale }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorMiddleSeptum") }}</span
            ><br />
            <span>{{ jsonData.basaleSettoAntMedio }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorApexSeptum") }}</span
            ><br />
            <span>{{ jsonData.basaleSettoAntApice }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorBasalWall") }}</span
            ><br />
            <span>{{ jsonData.basalePareteAntBasale }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorMiddleWall") }}</span
            ><br />
            <span>{{ jsonData.basalePareteAntMedia }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorApexWall") }}</span
            ><br />
            <span>{{ jsonData.basalePareteAntApice }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLateralAnteriorBasalWall") }}</span
            ><br />
            <span>{{ jsonData.basalePareteLatAntBasale }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLateralAnteriorMiddleWall") }}</span
            ><br />
            <span>{{ jsonData.basalePareteLatAntMedia }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLateralPosteriorBasalWall") }}</span
            ><br />
            <span>{{ jsonData.basalePareteLatPostBasale }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLateralPosteriorMiddleWall") }}</span
            ><br />
            <span>{{ jsonData.basalePareteLatPostMedia }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLateralPosteriorApexWall") }}</span
            ><br />
            <span>{{ jsonData.basalePareteLatPostApice }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLowerBasalWall") }}</span
            ><br />
            <span>{{ jsonData.basalePareteInfBasale }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLowerMiddleWall") }}</span
            ><br />
            <span>{{ jsonData.basalePareteInfMedia }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLowerApexWall") }}</span
            ><br />
            <span>{{ jsonData.basalePareteInfApice }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPosteriorBasalSeptum") }}</span
            ><br />
            <span>{{ jsonData.basaleSettoPostBasale }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPosteriorMiddleSeptum") }}</span
            ><br />
            <span>{{ jsonData.basaleSettoPostMedio }}</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblTotal") }}</span
            ><br />
            <span>{{ jsonData.totaleBasale }}</span>
          </b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblDamageExtensionIMA") }}</span
            ><br />
            <span>{{ jsonData.dannoImaBasale }}</span>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPAS") }}</span
            ><br />
            <span>{{ jsonData.pasBasale }}</span>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPAD") }}</span
            ><br />
            <span>{{ jsonData.padBasale }}</span>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblFC") }}</span
            ><br />
            <span>{{ jsonData.fcBasale }}</span>
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><span>{{ getTranslate("patient.cardiology.lblSymptoms") }}</span
            ><br />
            <span>{{ jsonData.sintomiBasale }}</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><h5>{{ getTranslate("patient.cardiology.lblBasal") }}</h5></b-col
          >
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorBasalSeptum") }}</span
            ><br />
            <span>{{ jsonData.vitalitaSettoAntBasale }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorMiddleSeptum") }}</span
            ><br />
            <span>{{ jsonData.vitalitaSettoAntMedio }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorApexSeptum") }}</span
            ><br />
            <span>{{ jsonData.vitalitaSettoAntApice }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorBasalWall") }}</span
            ><br />
            <span>{{ jsonData.vitalitaPareteAntBasale }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorMiddleWall") }}</span
            ><br />
            <span>{{ jsonData.vitalitaPareteAntMedia }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorApexWall") }}</span
            ><br />
            <span>{{ jsonData.vitalitaPareteAntApice }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLateralAnteriorBasalWall") }}</span
            ><br />
            <span>{{ jsonData.vitalitaPareteLatAntBasale }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLateralAnteriorMiddleWall") }}</span
            ><br />
            <span>{{ jsonData.vitalitaPareteLatAntMedia }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLateralPosteriorBasalWall") }}</span
            ><br />
            <span>{{ jsonData.vitalitaPareteLatPostBasale }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLateralPosteriorMiddleWall") }}</span
            ><br />
            <span>{{ jsonData.vitalitaPareteLatPostMedia }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLateralPosteriorApexWall") }}</span
            ><br />
            <span>{{ jsonData.vitalitaPareteLatPostApice }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLowerBasalWall") }}</span
            ><br />
            <span>{{ jsonData.vitalitaPareteInfBasale }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLowerMiddleWall") }}</span
            ><br />
            <span>{{ jsonData.vitalitaPareteInfMedia }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLowerApexWall") }}</span
            ><br />
            <span>{{ jsonData.vitalitaPareteInfApice }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPosteriorBasalSeptum") }}</span
            ><br />
            <span>{{ jsonData.vitalitaSettoPostBasale }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPosteriorMiddleSeptum") }}</span
            ><br />
            <span>{{ jsonData.vitalitaSettoPostMedio }}</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblTotal") }}</span
            ><br />
            <span>{{ jsonData.totaleVitalita }}</span>
          </b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblDamageExtensionIMA") }}</span
            ><br />
            <span>{{ jsonData.dannoImaVitalita }}</span>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPAS") }}</span
            ><br />
            <span>{{ jsonData.pasVitalita }}</span>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPAD") }}</span
            ><br />
            <span>{{ jsonData.padVitalita }}</span>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblFC") }}</span
            ><br />
            <span>{{ jsonData.fcVitalita }}</span>
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><span>{{ getTranslate("patient.cardiology.lblSymptoms") }}</span
            ><br />
            <span>{{ jsonData.sintomiVitalita }}</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><h5>{{ getTranslate("patient.cardiology.lblBasal") }}</h5></b-col
          >
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorBasalSeptum") }}</span
            ><br />
            <span>{{ jsonData.ischemiaSettoAntBasale }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorMiddleSeptum") }}</span
            ><br />
            <span>{{ jsonData.ischemiaSettoAntMedio }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorApexSeptum") }}</span
            ><br />
            <span>{{ jsonData.ischemiaSettoAntApice }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorBasalWall") }}</span
            ><br />
            <span>{{ jsonData.ischemiaPareteAntBasale }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorMiddleWall") }}</span
            ><br />
            <span>{{ jsonData.ischemiaPareteAntMedia }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorApexWall") }}</span
            ><br />
            <span>{{ jsonData.ischemiaPareteAntApice }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLateralAnteriorBasalWall") }}</span
            ><br />
            <span>{{ jsonData.ischemiaPareteLatAntBasale }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLateralAnteriorMiddleWall") }}</span
            ><br />
            <span>{{ jsonData.ischemiaPareteLatAntMedia }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLateralPosteriorBasalWall") }}</span
            ><br />
            <span>{{ jsonData.ischemiaPareteLatPostBasale }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLateralPosteriorMiddleWall") }}</span
            ><br />
            <span>{{ jsonData.ischemiaPareteLatPostMedia }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLateralPosteriorApexWall") }}</span
            ><br />
            <span>{{ jsonData.ischemiaPareteLatPostApice }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLowerBasalWall") }}</span
            ><br />
            <span>{{ jsonData.ischemiaPareteInfBasale }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLowerMiddleWall") }}</span
            ><br />
            <span>{{ jsonData.ischemiaPareteInfMedia }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLowerApexWall") }}</span
            ><br />
            <span>{{ jsonData.ischemiaPareteInfApice }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPosteriorBasalSeptum") }}</span
            ><br />
            <span>{{ jsonData.ischemiaSettoPostBasale }}</span></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPosteriorMiddleSeptum") }}</span
            ><br />
            <span>{{ jsonData.ischemiaSettoPostMedio }}</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblTotal") }}</span
            ><br />
            <span>{{ jsonData.totaleIschemia }}</span>
          </b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblDamageExtensionIMA") }}</span
            ><br />
            <span>{{ jsonData.dannoImaIschemia }}</span>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPAS") }}</span
            ><br />
            <span>{{ jsonData.pasIschemia }}</span>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPAD") }}</span
            ><br />
            <span>{{ jsonData.padIschemia }}</span>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblFC") }}</span
            ><br />
            <span>{{ jsonData.fcIschemia }}</span>
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><span>{{ getTranslate("patient.cardiology.lblSymptoms") }}</span
            ><br />
            <span>{{ jsonData.sintomiIschemia }}</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><span>{{ getTranslate("patient.cardiology.lblConclusions") }}</span
            ><br />
            <span>{{ jsonData.conclusioni }}</span></b-col
          >
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><span>{{ getTranslate("patient.cardiology.lblNotes") }}</span
            ><br />
            <span>{{ jsonData.note }}</span></b-col
          >
        </b-row>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  components: { SaPageLayout, PazienteAnagrafica },
  data() {
    return {
      linkback: null,
      pathResource: "/cardioecostress",
      jsonData: {},
      showModalLoading: false,
      opzioniSegmenti: [
        { text: "Acinetico/a", value: "acinetic" },
        { text: "Aneurismatico/a", value: "aneurysmatic" },
        { text: "Discinetico/a", value: "dyskinetic" },
        { text: "Ipocinetico/a", value: "hypokinetic" },
        { text: "Normocinetico", value: "normokinetic" },
      ],
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient: {
      get: function() {
        if (this.utente.gruppo.menuGruppo) {
          if (this.utente.gruppo.menuGruppo.length > 1) {
            return "/paziente/view/" + this.idPaziente;
          } else {
            if (this.utente.gruppo.menuGruppo.length == 1) {
              if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                return "/paziente/view/" + this.idPaziente;
              } else {
                return "/paziente/homepaziente";
              }
            } else {
              return "";
            }
          }
        } else {
          return "";
        }
      },
    },
  },
  mounted() {
    let me = this;
    me.jsonData.idPaziente = this.$route.query.idPaziente;
    me.jsonData.tipoEvento = "Cardiologia - Anamnesi";
    me.id = this.$route.params.id;
    me.linkedit = "/paziente/cardiologia/ecostress/edit/" + me.id + "?idPaziente=" + me.idPaziente;
    if (me.id !== "-1") {
      me.linkback = "/paziente/cardiologia/ecostress/view/" + me.id + "?idPaziente=" + me.idPaziente;
      me.loadData();
    } else {
      me.linkback = "/paziente/cardiologia/ecostress?idPaziente=" + me.idPaziente;
    }
  },

  methods: {
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        });
      }
    },
    getTranslate(text) {
      return this.$i18n.t(text);
    },
  },
};
</script>
