<template>
  <sa-page-layout :showModalLoading="showModalLoading" :btnEditVisible="true" :linkedit="linkEdit" :linkback="linkback" :btnBackVisible="true">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-header">
      <h3>{{ getLabelTraduora("patient.afa.lblEligibility") }}</h3>
    </template>
    <template slot="table-body">
      <b-tabs content-class="mt-3" class="sa-tab" fill>
        <b-tab :title="getLabelTraduora('patient.afa.lblSportsMedicalEvaluationForm')">
          <div class="sa-tab-scroll">
            <idoneita-scheda-valutazione-medico-sportiva-view-component :jsonData="jsonData"></idoneita-scheda-valutazione-medico-sportiva-view-component>
          </div>
        </b-tab>
        <b-tab :title="getLabelTraduora('patient.afa.lblAnamnesis')">
          <idoneita-anamnesi-view-component :jsonData="jsonData"></idoneita-anamnesi-view-component>
        </b-tab>
        <b-tab>
          <template #title>
            <span :class="getColorEsameObiettivo()"><b-icon :icon="getIconEsameObiettivo()"></b-icon>&nbsp;{{ getLabelTraduora("patient.afa.lblPhysicalExamination") }}</span>
          </template>
          <div class="sa-tab-scroll">
            <idoneita-esame-obiettivo-view-component :jsonData="jsonData"></idoneita-esame-obiettivo-view-component>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <span :class="getColorElettrocardiogramma()"><b-icon :icon="getIconElettrocardiogramma()"></b-icon>&nbsp;{{ getLabelTraduora("patient.afa.lblElectrocardiogram") }}</span>
          </template>
          <idoneita-elettrocardiogramma-view-component :jsonData="jsonData"></idoneita-elettrocardiogramma-view-component>
        </b-tab>
        <b-tab :title="getLabelTraduora('patient.afa.lblUrines')">
          <idoneita-urine-view-component :jsonData="jsonData"></idoneita-urine-view-component>
        </b-tab>
        <b-tab :title="getLabelTraduora('patient.afa.lblSpirography')" :edit="edit">
          <div class="sa-tab-scroll">
            <idoneita-spirografia-view-component :jsonData="jsonData"></idoneita-spirografia-view-component>
          </div>
        </b-tab>
        <b-tab :title="getLabelTraduora('patient.afa.lblOtherExams')">
          <idoneita-altri-esami :data="jsonData" :edit="edit"></idoneita-altri-esami>
        </b-tab>
        <b-tab>
          <template #title>
            <span :class="getColorGiudizioFinale()"><b-icon :icon="getIconGiudizioFinale()"></b-icon>&nbsp;{{ getLabelTraduora("patient.afa.lblFinalJudgement") }}</span>
          </template>
          <idoneita-gudizio-finale-view-component :jsonData="jsonData"></idoneita-gudizio-finale-view-component>
        </b-tab>
      </b-tabs>
    </template>
  </sa-page-layout>
</template>

<script>
import axios from "axios";
import { SaPageLayout } from "../../../../../../template/sa/components/layout/contents";
import PazienteAnagrafica from "../../../../components/PazienteAnagrafica.vue";
import UtilityMixin from "../../../../../utility/UtilityMixin";
import IdoneitaAltriEsami from "./IdoneitaAltriEsami.vue";
import IdoneitaSchedaValutazioneMedicoSportivaViewComponent from "./IdoneitaSchedaValutazioneMedicoSportivaViewComponent.vue";
import IdoneitaAnamnesiViewComponent from "./IdoneitaAnamnesiViewComponent.vue";
import IdoneitaEsameObiettivoViewComponent from "./IdoneitaEsameObiettivoViewComponent.vue";
import IdoneitaElettrocardiogrammaViewComponent from "./IdoneitaElettrocardiogrammaViewComponent.vue";
import IdoneitaUrineViewComponent from "./IdoneitaUrineViewComponent.vue";
import IdoneitaSpirografiaViewComponent from "./IdoneitaSpirografiaViewComponent.vue";
import IdoneitaGudizioFinaleViewComponent from "./IdoneitaGiudizioFinaleViewComponent.vue";

export default {
  mixins: [UtilityMixin],
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  components: {
    SaPageLayout,
    PazienteAnagrafica,
    IdoneitaAltriEsami,
    IdoneitaSchedaValutazioneMedicoSportivaViewComponent,
    IdoneitaAnamnesiViewComponent,
    IdoneitaEsameObiettivoViewComponent,
    IdoneitaElettrocardiogrammaViewComponent,
    IdoneitaUrineViewComponent,
    IdoneitaSpirografiaViewComponent,
    IdoneitaGudizioFinaleViewComponent,
  },
  data() {
    return {
      id: null,
      jsonData: { idPaziente: null },
      pathRest: "/medicinadellosportidoneita",
      linkEdit: null,
      linkback: null,
      edit: false,
      showModalLoading: false,
    };
  },
  computed: {
    idPaziente: {
      get: function() {
        return this.jsonData.idPaziente;
      },
      set: function(newValue) {
        this.jsonData.idPaziente = newValue;
        this.linkEdit = "/paziente/medicinadellosportidoneita/edit/" + this.id + "?idPaziente=" + newValue;
      },
    },
    linkPatient: {
      get: function() {
        if (this.utente.gruppo.menuGruppo) {
          if (this.utente.gruppo.menuGruppo.length > 1) {
            return "view/" + this.idPaziente;
          } else {
            if (this.utente.gruppo.menuGruppo.length == 1) {
              if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                return "view/" + this.idPaziente;
              } else {
                return "homepaziente";
              }
            } else {
              return "";
            }
          }
        } else {
          return "";
        }
      },
    },
  },
  created: function() {},
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.linkback = "/paziente/medicinadellosportidoneita?idPaziente=" + me.idPaziente;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathRest + "/";
      axios
        .get(link + me.id)
        .then((response) => {
          me.jsonData = response.data.data;
          me.idPaziente = me.jsonData.idPaziente;
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    getColorEsameObiettivo() {
      let me = this;
      let colore = me.jsonData.firmaEo != null ? "text-success" : "text-danger";
      return colore;
    },
    getColorElettrocardiogramma() {
      let me = this;
      let colore = me.jsonData.firmaEcg != null ? "text-success" : "text-danger";
      return colore;
    },
    getColorGiudizioFinale() {
      let me = this;
      let colore = me.jsonData.firmaGf != null ? "text-success" : "text-danger";
      return colore;
    },

    getIconEsameObiettivo() {
      let me = this;
      let icon = me.jsonData.firmaEo != null ? "check2" : "exclamation-triangle";
      return icon;
    },
    getIconElettrocardiogramma() {
      let me = this;
      let icon = me.jsonData.firmaEcg != null ? "check2" : "exclamation-triangle";
      return icon;
    },
    getIconGiudizioFinale() {
      let me = this;
      let icon = me.jsonData.firmaGf != null ? "check2" : "exclamation-triangle";
      return icon;
    },
  },
};
</script>
