<template>
  <sa-page-layout :showModalLoading="showModalLoading" :pathResource="pathResource" :btnNewVisible="true" :linkedit="linkedit" @refresh="onRefresh" :btnRefreshVisible="true">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="toolbar-row">
      <paziente-dashboard-component :idPaziente="idPaziente"></paziente-dashboard-component>
    </template>
    <template slot="table-body">
      <b-tabs ref="tbsTerapia" content-class="mt-3" class="sa-tab" style="height: 100%;" fill lazy @input="onTabTerapiaInput">
        <b-tab>
          <template #title>
            <div>{{ getLabelTraduora("therapy.lblLastTherapy") }}</div>
          </template>
          <div class="sa-tab-scroll">
            <b-row>
              <b-col cols="4" class="text-left">
                <b-button class="mb-2 btn" @click="onBack()"><font-awesome-icon icon="chevron-left"/></b-button>
              </b-col>
              <b-col cols="4" class="text-center">
                <a style="font-size: 20px" v-b-modal.modalChangeDateOfTherapy>{{ formatDate(dataRiferimento) }}</a>
              </b-col>
              <b-col cols="4" class="text-right">
                <b-button class="mb-2 btn" @click="onForward()"><font-awesome-icon icon="chevron-right"/></b-button>
              </b-col>
            </b-row>
            <paziente-terapia-component :key="keyTerapiaAttiva" :elements="elements" :dataRiferimento="dataRiferimento" @update="onRefresh"></paziente-terapia-component>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <div>{{ getLabelTraduora("therapy.lblHistoricalTherapies") }}</div>
          </template>
          <div class="sa-tab-scroll">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
              <b-col lg="3">
                <p>{{ this.$i18n.t("ambulatori.lblTotalRecords") }}: {{ rows }}</p>
              </b-col>
              <b-col lg="9">
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
              </b-col>
            </b-row>
            <div>
              <div class="b-table-sticky-header" style="padding-top: 10px;">
                <b-table sticky-header ref="table" :filter="filter" responsive stacked="xl" striped hover :items="storicoTerapie" :fields="fieldsStorico" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                  <template v-slot:cell(farmaco)="{ item }">
                    <router-link class="sa-edit-link" :to="'/paziente/terapia/view/' + item.id + '?idPaziente=' + idPaziente">{{ item.farmaco }}</router-link>
                  </template>
                  <template v-slot:cell(descrizioneFrequenza)="{ item }"> {{ getLabelTraduora(item.descrizioneFrequenza) }} </template>
                  <template v-slot:cell(posologia)="{ item }">
                    <span>{{ getLabelTraduora(item.posologia) }}</span>
                  </template>
                  <template v-slot:cell(statoDescrizione)="{ item }">
                    <span>{{ getLabelTraduora(item.statoDescrizione) }}</span>
                  </template>
                  <template #cell(actions)="row">
                    <b-button :disabled="row.item.statoDescrizione !== 'paziente.terapia.lblATTIVA'" v-b-modal.modalSomministrazione size="sm" variant="btn btn-purple waves-effect waves-light width-sm no-text" @click="onOpenModal(row.item, row.index, $event.target)">
                      <b-icon icon="pencil"></b-icon>
                    </b-button>
                  </template>
                  <template #cell(iterazioniFarmaco)="row">
                    <b-button
                      :disabled="isInterazioneDisabled(row.item)"
                      v-b-modal.modalIterazioniFarmaco
                      :style="'background-color: ' + returnColor(row.item.iterazioneFarmacoColore) + ' !important; border-color: ' + returnColor(row.item.iterazioneFarmacoColore) + ' !important;'"
                      @click="
                        onOpenModal(row.item, row.index, $event.target);
                        showIterazione(row.item.iterazioneFarmaco);
                      "
                      ><font-awesome-icon icon="pills"></font-awesome-icon></b-button
                  ></template>
                </b-table>
              </div>
              <hr />
              <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                  <p>Totale Record: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                  <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </template>
  </sa-page-layout>
</template>

<script>
import axios from "axios";
import moment from "moment";
import UtilityMixin from "../../../utility/UtilityMixin.js";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../components/PazienteAnagrafica.vue";
import PazienteDashboardComponent from "../../components/PazienteDashboardComponent.vue";
import PazienteTerapiaComponent from "./PazienteTerapiaComponent.vue";
export default {
  mixins: [UtilityMixin],
  components: { PazienteAnagrafica, PazienteDashboardComponent, PazienteTerapiaComponent, SaPageLayout },
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      filter: null,
      pathResource: "/pazientiterapia",
      pathResourceDataRiferimento: "/pazientiterapia/datariferimento",
      linkedit: "/paziente/terapia",
      showModalLoading: false,
      dataRiferimento: new Date(),
      elements: [],
      storicoTerapie: [],
      keyTerapiaAttiva: 0,
      tabIndex: 0,
      fieldsStorico: [
        {
          label: this.$i18n.t("therapy.lblDrug"),
          key: "farmaco",
          thStyle: "width: 15rem text-center",
          tdStyle: "text-center",
          sortable: true,
        },
        {
          label: this.$i18n.t("therapy.lblQuantity"),
          key: "quantita",
          thStyle: "width: 5rem text-align:center",
          tdStyle: "text-right",
        },
        {
          label: this.$i18n.t("therapy.lblFrequency"),
          key: "descrizioneFrequenza",
          tdStyle: "text-align:center",
          thStyle: "width: 14rem text-center",
        },
        {
          label: this.$i18n.t("therapy.lblPosology"),
          key: "posologia",
          thStyle: "width: 14rem",
        },
        {
          label: this.$i18n.t("therapy.lblStatus"),
          key: "statoDescrizione",
          thStyle: "width: 14rem",
        },
        {
          label: this.$i18n.t("therapy.lblNote"),
          key: "note",
          thStyle: "width: 14rem text-right",
        },

        {
          label: this.$i18n.t("therapy.lblStartDate"),
          key: "dataInizio",
          thStyle: "width: 14rem",
          sortable: true,
          formatter: (value) => {
            return moment(new Date(value)).format("DD-MM-YYYY");
          },
        },
        {
          label: this.$i18n.t("therapy.lblInteractions"),
          key: "iterazioniFarmaco",
          thStyle: "width: 14rem",
        },
      ],
    };
  },
  watch: {
    dataRiferimento() {
      let me = this;
      me.loadData();
    },
  },

    created() {
    let me = this;
    me.id = this.$route.params.id;
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    rows() {
      return this.storicoTerapie.length;
    },
    linkPatient: {
      get: function() {
        let returnValue = "";
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length > 1) {
              return "/paziente/view/" + this.idPaziente;
            } else {
              if (this.utente.gruppo.menuGruppo.length == 1) {
                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                  return "/paziente/view/" + this.idPaziente;
                } else {
                  return "/paziente/homepaziente";
                }
              }
            }
          }
        }
        return returnValue;
      },
    },
  },
  mounted: function() {
    let me = this;
    me.id = this.$route.params.id;
    console.log(me.id)
    me.loadDataStorico();
    // me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      let dataRiferimento = me.dataRiferimento.getTime();
      let link = process.env.VUE_APP_PATH_API + me.pathResourceDataRiferimento + "?idPaziente=" + me.idPaziente + "&dataRiferimento=" + dataRiferimento;

      me.showModalLoading = true;
      axios
        .get(link)
        .then((response) => {
          me.elements = response.data.data;
          me.showModalLoading = false;
          me.keyTerapiaAttiva = me.keyTerapiaAttiva + 1; //moment(new Date(dataRiferimento)).format("YYYYMMDDHHmm");
          // setInterval(() => this.$refs.childComponent.destroy(), 3000);
        })
        .catch((e) => {
          console.log(e);
          me.showModalLoading = false;
        });
    },
    loadDataStorico() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "?idPaziente=" + me.idPaziente;
      me.isBusy = true;
      me.showModalLoading = true;
      axios
        .get(link)
        .then((response) => {
          me.showModalLoading = false;
          me.storicoTerapie = response.data.data;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    returnColor(color) {
      if (color !== null || color !== undefined || color !== "") {
        return color;
      } else {
        return "#dbdbdb";
      }
    },
    isInterazioneDisabled(items) {
      return items.iterazioneFarmacoColore ? false : true;
    },
    onRefresh() {
      let me = this;
      if (me.tabIndex == 0) {
        me.loadData();
      } else {
        me.loadDataStorico();
      }
    },
    onTabTerapiaInput(index) {
      let me = this;
      me.tabIndex = index;
      switch (index) {
        case 0:
          me.loadData();
          break;
      }
    },
    onForward() {
      let me = this;
      me.setDataRiferimento(+1);
    },
    onBack() {
      let me = this;
      me.setDataRiferimento(-1);
    },
    setDataRiferimento(value) {
      let me = this;
      me.dataRiferimento = new Date(me.dataRiferimento.setDate(me.dataRiferimento.getDate() + value));
    },
  },
};
</script>

<style></style>
