<template>
  <sa-page-layout :btnBackVisible="true" :btnEditVisible="true" :btnRefreshVisible="true" @refresh="onRefresh" :linkback="linkback" :linkedit="linkedit" :pathResource="pathResource" :showModalLoading="showModalLoading">
    <template slot="toolbar">
      <b-button class="float-sm-right " variant="outline-success btn-toolbar sa-margin-right" size="sm" v-b-modal.modalAssociaUtente><b-icon icon="people-fill"></b-icon>Associa Utente</b-button>
    </template>
    <template slot="table-filter">
      <div>
        <b-row>
          <b-col xs="12" sm="6" md="6" lg="4" xl="3">
            <strong class="sa-form-label">{{ this.$i18n.t("clinic.lblStructure") }}</strong>
            <br />
            <span class="sa-text-data">{{ jsonData.nome }}</span>
          </b-col>
          <b-col xs="12" sm="6" md="6" lg="4" xl="3">
            <strong class="sa-form-label">{{ this.$i18n.t("clinic.lblBranca") }}</strong>
            <br />
            <span class="sa-text-data">{{ jsonData.branca }}</span>
          </b-col>
          <b-col xs="12" sm="6" md="6" lg="4" xl="3">
            <strong class="sa-form-label">{{ this.$i18n.t("clinic.lblSpecializations") }}</strong>
            <br />
            <span class="sa-text-data">{{ jsonData.specializzazione }}</span>
          </b-col>
          <b-col xs="12" sm="6" md="6" lg="4" xl="3">
            <strong class="sa-form-label">{{ this.$i18n.t("clinic.lblName") }}</strong>
            <br />
            <span class="sa-text-data">{{ jsonData.nome }}</span>
          </b-col>
          <b-col xs="12" sm="6" md="6" lg="4" xl="3">
            <strong class="sa-form-label">{{ this.$i18n.t("clinic.lblClinicManager") }}</strong>
            <br />
            <span class="sa-text-data">{{ jsonData.responsabile }}</span>
          </b-col>
          <b-col xs="12" sm="6" md="6" lg="4" xl="3">
            <strong class="sa-form-label">{{ this.$i18n.t("clinic.lblAddress") }} </strong>
            <br />
            <span class="sa-text-data">{{ jsonData.indirizzo }}</span>
          </b-col>
          <b-col xs="12" sm="6" md="6" lg="4" xl="3">
            <strong class="sa-form-label"> {{ this.$i18n.t("clinic.lblStreetNumber") }} </strong>
            <br />
            <span class="sa-text-data">{{ jsonData.numeroCivico }}</span>
          </b-col>
          <b-col xs="12" sm="6" md="6" lg="4" xl="3">
            <strong class="sa-form-label"> {{ this.$i18n.t("clinic.lblProvince") }} </strong>
            <br />
            <span class="sa-text-data">{{ jsonData.provincia }}</span>
          </b-col>
          <b-col xs="12" sm="6" md="6" lg="4" xl="3">
            <strong class="sa-form-label"> {{ this.$i18n.t("clinic.lblCity") }} </strong>
            <br />
            <span class="sa-text-data">{{ jsonData.comune }}</span>
          </b-col>
          <b-col xs="12" sm="6" md="6" lg="4" xl="3">
            <strong class="sa-form-label">{{ this.$i18n.t("clinic.lblPostalCode") }}</strong>
            <br />
            <span class="sa-text-data">{{ jsonData.cap }}</span>
          </b-col>
          <b-col xs="12" sm="6" md="6" lg="4" xl="3">
            <strong class="sa-form-label"> {{ this.$i18n.t("clinic.lblAsl") }} </strong>
            <br />
            <span class="sa-text-data">{{ jsonData.asl }}</span>
          </b-col>
          <b-col xs="12" sm="6" md="6" lg="4" xl="3">
            <strong class="sa-form-label"> {{ this.$i18n.t("clinic.lblAslCode") }} </strong>
            <br />
            <span class="sa-text-data">{{ jsonData.codiceAsl }}</span>
          </b-col>
          <b-col xs="12" sm="6" md="6" lg="4" xl="3">
            <strong class="sa-form-label"> {{ this.$i18n.t("clinic.lblTelephoneNumber") }} </strong>
            <br />
            <span class="sa-text-data">{{ jsonData.telefono }}</span>
          </b-col>
          <b-col xs="12" sm="6" md="6" lg="4" xl="3">
            <strong class="sa-form-label"> {{ this.$i18n.t("clinic.lblEmail") }} </strong>
            <br />
            <span class="sa-text-data">{{ jsonData.email }}</span>
          </b-col>
        </b-row>
      </div>
    </template>
    <template slot="table-header">
      <h6>Utenti Associati all'Ambulatorio</h6>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(firstname)="row">
            <p>{{ row.item.lastname }} , {{ row.item.firstname }}</p>
          </template>
          <template #cell(actions)="row">
            <b-button variant="outline-danger" @click="onDeleteAssociazione(row.item)"><b-icon icon="person-x-fill" aria-hidden="true"></b-icon>Dissocia</b-button>
          </template>
        </b-table>
      </div>

      <b-modal size="lg" ref="modalAssocia" id="modalAssociaUtente" title="Associa Utente" @show="onShow()" style="max-height; 200px">
        <b-row>
          <b-col cols="4">
            <strong>Username : </strong>
            <b-form-input v-model="filtroModal.username"></b-form-input>
          </b-col>
          <b-col cols="4">
            <strong>Tipologia Profilo : </strong>
            <b-form-select v-model="filtroModal.profilo" :options="profiliModalOptions" :value="null" value-field="value" text-field="text"> </b-form-select>
          </b-col>
          <b-col cols="1">
            <b-button variant="outline-info" style="margin-top:20px;" @click="onFiltra">Filtra</b-button>
          </b-col>
          <b-col cols="1">
            <b-button variant="outline-danger" style="margin-top:20px; margin-left:5px;" @click="onResetFiltro">Reset</b-button>
          </b-col>
        </b-row>
        <br />
        <div class="b-table-sticky-header">
          <b-table sticky-header :busy="isBusy" ref="table" selectable stacked="lg" striped hover :items="itemsUtenti" :fields="fieldsModal" sort-icon-left head-variant="light" class="sa-b-table" @row-selected="onRowSelected">
            <template #table-busy>
              <div class="text-center text-info my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Caricamento...</strong>
              </div>
            </template>
            <template #cell(selected)="{ rowSelected }">
              <template v-if="rowSelected">
                <span aria-hidden="true">&check;</span>
                <span class="sr-only">Selected</span>
              </template>
              <template v-else>
                <span aria-hidden="true">&nbsp;</span>
                <span class="sr-only">Not selected</span>
              </template>
            </template>
            <template #cell(firstname)="row">
              <p>{{ row.item.lastname }} , {{ row.item.firstname }}</p>
            </template>
          </b-table>
        </div>

        <template #modal-footer="{ok}">
          <b-button size="sm" variant="outline-danger" @click="ok()"> <b-icon icon="x"></b-icon>Annulla</b-button>
          <b-button size="sm" variant="outline-success" @click="onAssocia()"> <b-icon icon="person-check-fill"></b-icon>Associa</b-button>
        </template>
      </b-modal>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
//import moment from "moment";
//import { SaPageViewEdit } from "../../../template/sa/components/layout/contents";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
  components: { SaPageLayout },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  mixins: [UtilityMixin],
  data() {
    return {
      isBusy: false,
      linkback: null,
      showModalLoading: false,
      perPage: 10,
      currentPage: 1,
      filter: null,
      pathResource: "/ambulatoriutenti",
      pathRest: "/ambulatori",
      pathResourceUtenti: "/users",
      pathResourceGruppiUtenti: "/gruppi",
      linkedit: null,
      pathRESTRequest: "/ambulatorio",
      filtroModal: {
        profilo: "",
        username: "",
      },
      selected: [],
      profiliModalOptions: [],
      filterOn: [],
      jsonData: {},
      items: [],
      itemsUtenti: [],
      fields: [
        {
          label: "Username",
          key: "username",
          sortable: true,
          thStyle: "width: 12rem",
        },
        {
          label: this.getLabelTraduora("patients.lblNominative"),
          key: "firstname",
          sortable: true,
          thStyle: "width: 15rem",
        },
        {
          label: this.getLabelTraduora("ambulatory.tipologyProfile"),
          key: "profilo",
          sortable: true,
        },
        {
          label: "",
          key: "actions",
          tdClass: "text-right",
        },
      ],
      fieldsModal: [
        {
          label: "",
          key: "selected",
          thStyle: "width: 2.5rem",
          tdClass: "text-center",
        },
        {
          label: "Username",
          key: "username",
          sortable: true,
          thStyle: "width: 8rem",
        },
        {
          label: this.getLabelTraduora("patients.lblNominative"),
          key: "firstname",
          sortable: true,
          thStyle: "width: 8rem",
        },
        {
          label: this.getLabelTraduora("ambulatory.tipologyProfile"),
          key: "profilo",
          sortable: true,
          thStyle: "width: 8rem",
        },
      ],
    };
  },
  created: function() {},
  mounted: function() {
    let me = this;
    me.id = this.$route.params.id;
    me.linkedit = "/ambulatorio/edit/" + me.id;
    me.linkback = "/ambulatori";
    me.loadData();
    me.loadDefaultData();
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathRest + "/";
      axios.get(link + me.id).then((response) => {
        me.jsonData = response.data.data;
        me.showModalLoading = false;
        me.loadDataTableAssociati();
      });
    },
    loadDataTableAssociati() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/utenti/";
      axios.get(link, { params: { idAmbulatorio: me.jsonData.id } }).then((response) => {
        me.showModalLoading = false;
        me.items = response.data.data;
      });
    },
    loadDataUtenze() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceUtenti;
      axios.get(link).then((response) => {
        me.isBusy = false;
        me.showModalLoading = false;
        me.itemsUtenti = response.data.data;
      });
    },
    loadDefaultData() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceGruppiUtenti;
      axios
        .get(link)
        .then((response) => {
          response.data.data.forEach((element) => {
            me.profiliModalOptions.push({ text: element.nome, value: element.nome });
          });
        })
        .finally(() => {
          me.profiliModalOptions.unshift({ text: this.getLabelTraduora("patient.ecoreferting.lblSelected"), value: "" });
        });
    },

    onRowSelected(items) {
      this.selected = items;
    },
    onAssocia() {
      let me = this;
      for (let i = 0; i < me.selected.length; i++) {
        let obj = { id: null, idAmbulatorio: me.jsonData.id, idUtente: me.selected[i].id };
        me.associaUtenteAmbulatorio(obj);
      }
    },
    associaUtenteAmbulatorio(obj) {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      axios
        .post(link, { idAmbulatorio: obj.idAmbulatorio, idUtente: obj.idUtente })
        .then((response) => {
          me.$refs["modalAssocia"].hide();
          response.data.data;
          me.showModalLoading = false;
          me.loadData();
          console.log(me.jsonData);
          this.$bvToast.toast(`${this.getLabelTraduora("ambulatory.toastAssociateUser") + " " + me.jsonData.nome}`, {
            title: this.getLabelTraduora("ambulatory.toastAssociateUserSuccess"),
            solid: true,
            variant: "success",
            autoHideDelay: 1000,
            appendToast: true,
          });
        })
        .catch((e) => {
          console.log(e);
          me.showModalLoading = false;
        });
    },
    ok() {},
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onDeleteAssociazione(item) {
      let me = this;
      this.$bvModal
        .msgBoxConfirm(this.getLabelTraduora("ambulatory.modelMessageConfirmDeleteAssociate"), {
          title: this.getLabelTraduora("ambulatory.modelMessageDeleteAssociate"),
          buttonSize: "sm",
          okVariant: "secondary",
          cancelVariant: "danger",
          okTitle: "SI",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            me.deleteAssociazione(item);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteAssociazione(item) {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      axios
        .delete(link + item.id)
        .then((response) => {
          console.log(response);
          me.showModalLoading = false;
          me.loadData();
        })
        .catch((error) => {
          console.log(error);
          me.showModalLoading = false;
        });
    },
    onShow() {
      let me = this;
      me.loadDataUtenze();
    },
    onResetFiltro() {
      let me = this;
      me.filtroModal.profilo = "";
      me.filtroModal.username = "";
      me.loadDataUtenze();
    },
    onFiltra() {
      let me = this;
      me.showModalLoading = true;
      console.log(me.filtroModal.profilo);
      let link = process.env.VUE_APP_PATH_API + me.pathResourceUtenti + "?user=" + me.filtroModal.username + "&profilo=" + me.filtroModal.profilo;
      console.log(link);
      axios.get(link).then((response) => {
        me.showModalLoading = false;
        me.itemsUtenti = response.data.data;
      });
    },
  },
};
</script>
