<template>
  <div>
    <b-row>
      <b-col cols="12" xs="14" sm="4" md="4" lg="4" xl="12">
        <strong>{{ this.$i18n.t("patient.afa.lblCertificateType") }}</strong>
        <b-form-select v-model="data.tipoCertificato" :options="opzioniTipoCertificato"></b-form-select>
      </b-col>
      <b-col cols="12" xs="4" sm="4" md="4" lg="4" xl="12">
        <strong>{{ this.$i18n.t("patient.afa.lblSportPracticed") }}</strong
        >: {{ data.sportPraticato }}
      </b-col>
      <b-col cols="12" xs="4" sm="4" md="4" lg="4" xl="12">
        <strong>{{ this.$i18n.t("patient.afa.lblEligibilityJudgement") }}</strong>
        <b-form-select v-model="data.giudizioIdoneita" :options="opzioniGiudizioIdoneita"></b-form-select>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <strong>{{ this.$i18n.t("patient.afa.lblFinalJudgement") }}</strong
        ><b-form-textarea rows="4" max-rows="9" v-model="data.giudizioFinale" :disabled="!edit"></b-form-textarea>
      </b-col>
      <b-col cols="12" xs="14" sm="4" md="4" lg="4" xl="12">
        <strong>{{ this.$i18n.t("patient.afa.lblPlace") }}</strong
        ><b-form-input v-model="data.luogo"></b-form-input>
      </b-col>
      <b-col cols="12" xs="4" sm="4" md="4" lg="4" xl="12">
        <strong>{{ this.$i18n.t("patient.afa.lblEligibilityPeriod") }}</strong>
        <b-form-select v-model="data.periodoIdoneita" :options="opzioniPeriodoIdoneita"></b-form-select>
      </b-col>
      <b-col cols="12" xs="4" sm="4" md="4" lg="4" xl="12">
        <strong>{{ this.$i18n.t("sogei.lblEndingValidityDate") }}</strong>
        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.dataScadenza" :disabled="!edit" type="datetime"></date-picker>
      </b-col>
      <b-col cols="12" xs="4" sm="4" md="4" lg="4" xl="12">
        <strong>{{ this.$i18n.t("patient.afa.lblCertificateNumber") }}</strong>
        <b-form-input v-model="data.numeroCertificato"></b-form-input>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <span class="sa-form-label">{{ this.$i18n.t("therapy.lblStatus") }} </span><br /><br />
        <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblPhysicalExamination") }} : </span> {{ data.firmaEo }} <br /><br />
        <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblElectrocardiogram") }} : </span> {{ data.firmaEcg }} <br /><br />
        <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblFinalJudgement") }} : </span>{{ data.firmaGf }}
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
export default {
  props: ["data", "edit"],
  components: { DatePicker },
  data() {
    return {
      opzioniPeriodoIdoneita: [],
      opzioniGiudizioIdoneita: [],
      opzioniTipoCertificato: [],
    };
  },
};
</script>
