import CardioAnamnesiList from "./components/CardioAnamnesiList.vue";
import CardioAnamnesiEdit from "./components/CardioAnamnesiEdit.vue";
import CardioAnamnesiView from "./components/CardioAnamnesiView.vue";
import CardioRefertazioneList from "./components/CardioRefertazioneList.vue";
import CardioRefertazioneEdit from "./components/CardioRefertazioneEdit.vue";
import CardioRefertazioneView from "./components/CardioRefertazioneView.vue";
import CardioEcostressList from "./components/CardioEcostressList.vue";
import CardioEcostressView from "./components/CardioEcostressView.vue";
import CardioEcostressEdit from "./components/CardioEcostressEdit.vue";
import CardioEcodopplerArtiInferioriList from "./components/CardioEcodopplerArtiInferioriList.vue";
import CardioEcodopplerArtiInferioriView from "./components/CardioEcodopplerArtiInferioriView.vue";
import CardioEcodopplerArtiInferioriEdit from "./components/CardioEcodopplerArtiInferioriEdit.vue";
import CardioVisitaControlloEcgEdit from "./components/CardioVisitaControlloEcgEdit.vue";
import CardioVisitaControlloEcgList from "./components/CardioVisitaControlloEcgList.vue";
import CardioVisitaControlloEcgView from "./components/CardioVisitaControlloEcgView.vue";
import CardioEcocarotidiEdit from "./components/CardioEcocarotidiEdit.vue";
import CardioEcocarotidiList from "./components/CardioEcocarotidiList.vue";
import CardioEcocarotidiView from "./components/CardioEcocarotidiView.vue";
import CardioElettrostimolatoreEdit from "./components/CardioElettrostimolatoreEdit.vue";
import CardioElettrostimolatoreList from "./components/CardioElettrostimolatoreList.vue";
import CardioElettrostimolatoreView from "./components/CardioElettrostimolatoreView.vue";

export default {
  CardioAnamnesiList,
  CardioAnamnesiEdit,
  CardioAnamnesiView,
  CardioRefertazioneList,
  CardioRefertazioneEdit,
  CardioRefertazioneView,
  CardioEcostressList,
  CardioEcostressView,
  CardioEcostressEdit,
  CardioEcodopplerArtiInferioriList,
  CardioEcodopplerArtiInferioriView,
  CardioEcodopplerArtiInferioriEdit,
  CardioVisitaControlloEcgEdit,
  CardioVisitaControlloEcgList,
  CardioVisitaControlloEcgView,
  CardioEcocarotidiEdit,
  CardioEcocarotidiList,
  CardioEcocarotidiView,
  CardioElettrostimolatoreEdit,
  CardioElettrostimolatoreList,
  CardioElettrostimolatoreView,
};
