<template>
    <div style="float: left">
        <b-button class="float-sm-left btn-toolbar sa-btn-icon-text" variant="outline-secondary sa-margin-left" size="sm" @click="onFirma" :disabled="disabled">
            <b-icon icon="vector-pen"></b-icon>
            Firma
        </b-button>
        <b-modal ref="mdlFirma" title="Firma" @hidden="onHidden" @ok="onOk">
            <form ref="form" @submit.stop.prevent="onFirmaSubmit">
                <b-form-group label="Password" label-for="name-input" invalid-feedback="Name is required" :state="passwordState">
                    <b-form-input type="password" v-model="password" :state="passwordState" required placeholder="Password"></b-form-input>
                </b-form-group>
            </form>
        </b-modal>
    </div>
</template>
<script>
import axios from "axios";
export default {
    props: {
        idEvento: { type: String, require: true },
        pathRestFirma: { type: String, require: true },
        pathRestValidation: { type: String, require: true },
        disabled: { type: Boolean, require: true, default: false },
        fieldName: { type: String, require: true },
        data: { Type: Object, require: true },
    },
    data() {
        return {
            password: "",
            passwordState: null,
        };
    },
    methods: {
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity();
            this.passwordState = valid;
            return valid;
        },
        onFirma() {
            let me = this;
            if (me.idEvento === "-1" || me.data.id === null) {
                this.$bvModal
                    .msgBoxOk("Salvare prima di effettuare la validazione.", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        this.boxOne = value;
                    });
            } else {
                let jsonData = JSON.stringify(me.data);
                let link = process.env.VUE_APP_PATH_API + me.pathRestFirma + me.pathRestValidation;
                axios
                    .post(link, jsonData)
                    .then(() => {
                        me.$refs["mdlFirma"].show();
                    })
                    .catch((e) => {
                        console.log(e.response.status);
                        let message = "";
                        if (e.response.data.messaggio) {
                            message = e.response.data.messaggio;
                        } else {
                            message = e.response.status;
                        }
                        this.$bvModal.msgBoxOk(message, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        });
                    });
            }
        },
        onFirmaSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return;
            }
            let key = btoa(btoa(this.password));

            let firmaData = { idEvento: this.idEvento, key: key, fieldName: this.fieldName };
            let jsonData = JSON.stringify(firmaData);
            console.log(firmaData);
            let link = process.env.VUE_APP_PATH_API + this.pathRestFirma + "/firma/" + this.idEvento;
            axios
                .put(link, jsonData)
                .then((response) => {
                    let me = this;
                    console.log(response);
                    me.$emit("update");
                    this.$bvToast.toast(response.data.messaggio, {
                        title: "Firma effettuata",
                        variant: "success",
                        solid: true,
                        noCloseButton: true,
                        autoHideDelay: 2000,
                    });
                })
                .catch((err) => {
                    this.$bvModal
                        .msgBoxOk(err.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxOne = value;
                        });
                });

            this.$nextTick(() => {
                this.$refs["mdlFirma"].hide();
            });
        },
        onHidden() {
            this.passwordState = null;
            this.password = "";
        },
        onOk(bvModalEvt) {
            let me = this;
            bvModalEvt.preventDefault();
            me.onFirmaSubmit();
        },
    },
};
</script>
