<template>
    <div>
        <div v-if="orientation === 'Vertical'">
            <b-card class="mb-2">
                <b-row>
                    <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                        <div>
                            <div :class="'sa-card-border-' + getPressureRange()">
                                <b-card-header :class="'card-header-' + getMisuraRange('lbl.measure.systolicBloodPressure')">
                                    <b-row align-v="start">
                                        <b-col cols="8" align-v="start" class="testo2" align-content="center">
                                            <b-row>
                                                <b-col cols="2"><font-awesome-icon icon="tachometer-alt" size="lg"></font-awesome-icon></b-col>
                                                <b-col cols="10"
                                                    ><span> {{ this.$i18n.t("patient.card.lblPressure") }}</span>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                        <b-col class="testo2" cols="2">
                                            <h5 class="testo2" @click="onDashboard"><b-icon icon="file-earmark-bar-graph"></b-icon></h5>
                                        </b-col>
                                    </b-row>
                                </b-card-header>

                                <b-card-body class="text-center">
                                    <b-row
                                        ><b-col>
                                            <b-card-sub-title class="sottotesto">{{ getDate("lbl.measure.systolicBloodPressure") }}</b-card-sub-title>
                                            <!-- <b-card-sub-title class="sottotesto">22-12-2021</b-card-sub-title> -->
                                        </b-col>
                                    </b-row>
                                </b-card-body>

                                <b-card-footer :class="'text-center card-footer-' + getMisuraRange('lbl.measure.systolicBloodPressure')">
                                    <b-card-text>
                                        <b-row align-content="center"
                                            ><b-col align-content="center" class="testo2">
                                                <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('lbl.measure.systolicBloodPressure') + ' badge-pill'">{{ getMisuraValue("lbl.measure.systolicBloodPressure") }}</span>
                                                <!-- <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('Systolic Blood Pressure (mmHg)') + ' badge-pill'">126</span> -->
                                            </b-col>
                                            <b-col align-content="center" class="testo2">
                                                <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('lbl.measure.diastolicBloodPressure') + ' badge-pill'">{{ getMisuraValue("lbl.measure.diastolicBloodPressure") }}</span>
                                                <!-- <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('Diastolic Blood Pressure (mmHg)') + ' badge-pill'">79</span> -->
                                            </b-col></b-row
                                        ><b-row class="text-center">
                                            <b-col class="sottotesto">{{ this.$i18n.t("patient.card.lblSystolic") }}</b-col>
                                            <b-col class="sottotesto">{{ this.$i18n.t("patient.card.lblDiastolic") }}</b-col>
                                        </b-row>
                                        <!-- <b-row class="text-center">
              <b-col class="testo2">{{ getAvarage("Systolic Blood Pressure (mmHg)") }} / {{ getAvarage("Diastolic Blood Pressure (mmHg)") }}</b-col>
              <b-col class="testo2">{{ getLastDay("Systolic Blood Pressure (mmHg)") }} / {{ getLastDay("Diastolic Blood Pressure (mmHg)") }}</b-col>
              <b-col class="testo2">{{ getLastWeek("Systolic Blood Pressure (mmHg)") }} / {{ getLastWeek("Diastolic Blood Pressure (mmHg)") }}</b-col>
            </b-row>
            <b-row class="text-center">
              <b-col class="sottotesto">Average</b-col>
              <b-col class="sottotesto">Last Day</b-col>
              <b-col class="sottotesto">Last week</b-col>
            </b-row> -->
                                    </b-card-text>
                                </b-card-footer>
                            </div>
                        </div></b-col
                    >
                    <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                        <div>
                            <div :class="'sa-card-border-' + getSleepRange()" style="weight: 0 auto;">
                                <b-card-header :class="'card-header-' + getSleepRange()">
                                    <b-row align-v="start">
                                        <b-col cols="2"></b-col>
                                        <b-col cols="8" align-v="start" class="testo2" align-content="center"
                                            ><b-row
                                                ><b-col cols="2"><font-awesome-icon icon="bed" size="lg"></font-awesome-icon></b-col>
                                                <b-col cols="10">
                                                    <span> {{ checkName("lbl.measure.sleepEfficiency") }}</span>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                        <b-col class="testo2" cols="2"
                                            ><h5 class="testo2" @click="onDashboard"><b-icon icon="file-earmark-bar-graph"></b-icon></h5
                                        ></b-col>
                                    </b-row>
                                </b-card-header>

                                <b-card-body class="text-center">
                                    <b-row>
                                        <b-col>
                                            <b-card-sub-title class="sottotesto">{{ getDate("lbl.measure.totalSleepTime") }}</b-card-sub-title>
                                            <!-- <b-card-sub-title class="sottotesto">22-12-2021</b-card-sub-title> -->
                                        </b-col>
                                    </b-row>
                                </b-card-body>

                                <b-card-footer :class="'text-center card-footer-' + getSleepRange()">
                                    <b-card-text>
                                        <b-row align-content="center">
                                            <b-col align-content="center" class="testo2">
                                                <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('lbl.measure.totalSleepTime') + ' badge-pill'">{{ convertHour(getMisuraValue("lbl.measure.totalSleepTime")) }}</span>
                                                <!-- <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('total_sleep_time') + ' badge-pill'">08:22</span> -->
                                            </b-col>
                                            <b-col align-content="center" class="testo2">
                                                <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('lbl.measure.wakeupCount') + ' badge-pill'">{{ getMisuraValue("lbl.measure.wakeupCount") }}</span>
                                                <!-- <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('wakeupcount') + ' badge-pill'">0</span> -->
                                            </b-col>
                                        </b-row>
                                        <b-row class="text-center">
                                            <b-col class="sottotesto">{{ this.$i18n.t("patient.card.lblHours") }}</b-col>
                                            <b-col class="sottotesto">{{ this.$i18n.t("patient.card.lblInterruptions") }}</b-col>
                                        </b-row>
                                    </b-card-text>
                                </b-card-footer>
                            </div>
                        </div>
                    </b-col>
                    <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                        <div>
                            <div :class="'sa-card-border-' + getMisuraRange('lbl.measure.step')">
                                <b-card-header :class="'card-header-' + getMisuraRange('lbl.measure.step')">
                                    <b-row align-v="start">
                                        <b-col cols="2"></b-col>
                                        <b-col cols="8" align-v="start" class="testo2" align-content="center"
                                            ><b-row
                                                ><b-col cols="2"><font-awesome-icon icon="walking" size="lg"></font-awesome-icon></b-col>
                                                <b-col cols="10"
                                                    ><span> {{ checkName("lbl.measure.step") }}</span></b-col
                                                >
                                            </b-row>
                                        </b-col>
                                        <b-col class="testo2" cols="2"
                                            ><h5 class="testo2" @click="onDashboard"><b-icon icon="file-earmark-bar-graph"></b-icon></h5
                                        ></b-col>
                                    </b-row>
                                </b-card-header>
                                <b-card-body class="text-center">
                                    <b-row
                                        ><b-col>
                                            <b-card-sub-title class="sottotesto">{{ getDate("lbl.measure.step") }}</b-card-sub-title>
                                            <!-- <b-card-sub-title class="sottotesto">22-12-2021</b-card-sub-title> -->
                                        </b-col></b-row
                                    >
                                </b-card-body>
                                <b-card-footer :class="'text-center card-footer-' + getMisuraRange('lbl.measure.step')">
                                    <b-card-text>
                                        <b-row align-content="center"
                                            ><b-col align-content="center" class="testo2">
                                                <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('lbl.measure.step') + ' badge-pill'">{{ getMisuraValue("lbl.measure.step") }}</span>
                                                <!-- <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('steps') + ' badge-pill'">4352</span> -->
                                            </b-col></b-row
                                        ><b-row class="text-center">
                                            <b-col class="sottotesto">{{ this.$i18n.t("patient.card.lblNum") }}</b-col>
                                        </b-row>
                                    </b-card-text>
                                </b-card-footer>
                            </div>
                        </div>
                    </b-col>
                    <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                        <div>
                            <div :class="'sa-card-border-' + getMisuraRange('lbl.measure.weight')">
                                <b-card-header :class="'card-header-' + getMisuraRange('lbl.measure.weight')">
                                    <b-row align-v="start">
                                        <b-col cols="2"></b-col>
                                        <b-col cols="8" align-v="start" class="testo2" align-content="center">
                                            <b-row>
                                                <b-col cols="2"><font-awesome-icon icon="weight" size="lg"></font-awesome-icon></b-col>
                                                <b-col cols="10">
                                                    <span> {{ checkName("lbl.measure.weight") }}</span>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                        <b-col class="testo2" cols="2"
                                            ><h5 class="testo2" @click="onDashboard"><b-icon icon="file-earmark-bar-graph"></b-icon></h5>
                                        </b-col>
                                    </b-row>
                                </b-card-header>
                                <b-card-body class="text-center">
                                    <b-row>
                                        <b-col>
                                            <b-card-sub-title class="sottotesto">{{ getDate("lbl.measure.weight") }}</b-card-sub-title>
                                            <!-- <b-card-sub-title class="sottotesto">22-12-2021</b-card-sub-title> -->
                                        </b-col>
                                    </b-row>
                                </b-card-body>
                                <b-card-footer :class="'text-center card-footer-' + getMisuraRange('lbl.measure.weight')">
                                    <b-card-text>
                                        <b-row align-content="center"
                                            ><b-col align-content="center" class="testo2">
                                                <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('lbl.measure.weight') + ' badge-pill'">{{ getMisuraValue("lbl.measure.weight") }}</span>
                                                <!-- <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('Weight (kg)') + ' badge-pill'">72</span> -->
                                            </b-col></b-row
                                        ><b-row class="text-center">
                                            <b-col class="sottotesto">{{ this.$i18n.t("patient.card.lblNum") }}</b-col>
                                        </b-row>
                                    </b-card-text>
                                </b-card-footer>
                            </div>
                        </div>
                    </b-col>
                    <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                        <div>
                            <div :class="'sa-card-border-' + getMisuraRange('RiskCalculationResult')">
                                <b-card-header :class="'card-header-' + getMisuraRange('RiskCalculationResult')">
                                    <b-row align-v="start">
                                        <b-col cols="2"></b-col>
                                        <b-col cols="8" align-v="start" class="testo2" align-content="center"
                                            ><b-row
                                                ><b-col cols="2"><font-awesome-icon icon="heart-broken" size="lg"></font-awesome-icon></b-col>
                                                <b-col cols="10"
                                                    ><span> {{ checkName("RiskCalculationResult") }}</span></b-col
                                                >
                                            </b-row>
                                        </b-col>
                                        <b-col class="testo2" cols="2"
                                            ><h5 class="testo2" @click="onDashboard"><b-icon icon="file-earmark-bar-graph"></b-icon></h5
                                        ></b-col>
                                    </b-row>
                                </b-card-header>
                                <b-card-body class="text-center">
                                    <b-row
                                        ><b-col>
                                            <b-card-sub-title class="sottotesto">{{ getDate("RiskCalculationResult") }}</b-card-sub-title>
                                            <!-- <b-card-sub-title class="sottotesto">22-12-2021</b-card-sub-title> -->
                                        </b-col></b-row
                                    >
                                </b-card-body>
                                <b-card-footer :class="'text-center card-footer-' + getMisuraRange('RiskCalculationResult')">
                                    <b-card-text>
                                        <b-row align-content="center"
                                            ><b-col align-content="center" class="testo2">
                                                <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('RiskCalculationResult') + ' badge-pill'">{{ getMisuraValue("RiskCalculationResult") }}</span>
                                                <!-- <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('RiskCalculationResult') + ' badge-pill'">1</span> -->
                                            </b-col></b-row
                                        ><b-row class="text-center">
                                            <b-col class="sottotesto">{{ this.$i18n.t("patient.card.lblNum") }}</b-col>
                                        </b-row>
                                    </b-card-text>
                                </b-card-footer>
                            </div>
                        </div>
                    </b-col>
                    <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                        <div>
                            <div :class="'sa-card-border-' + getMisuraRange('Adherence')">
                                <b-card-header :class="'card-header-' + getMisuraRange('Adherence')">
                                    <b-row align-v="start">
                                        <b-col cols="2"></b-col>
                                        <b-col cols="8" align-v="start" class="testo2" align-content="center"
                                            ><b-row
                                                ><b-col cols="2"><font-awesome-icon icon="pills" size="lg"></font-awesome-icon></b-col>
                                                <b-col cols="10"
                                                    ><span> {{ checkName("Adherence") }}</span></b-col
                                                >
                                            </b-row>
                                        </b-col>
                                        <b-col class="testo2" cols="2"
                                            ><h5 class="testo2" @click="onDashboard"><b-icon icon="file-earmark-bar-graph"></b-icon></h5
                                        ></b-col>
                                    </b-row>
                                </b-card-header>
                                <b-card-body class="text-center">
                                    <b-row
                                        ><b-col>
                                            <b-card-sub-title class="sottotesto">{{ getDate("Adherence") }}</b-card-sub-title>
                                        </b-col></b-row
                                    >
                                </b-card-body>
                                <b-card-footer :class="'text-center card-footer-' + getMisuraRange('Adherence')">
                                    <b-card-text>
                                        <b-row align-content="center"
                                            ><b-col align-content="center" class="testo2">
                                                <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('Adherence') + ' badge-pill'">{{ getMisuraValue("Adherence") }}</span>
                                            </b-col></b-row
                                        ><b-row class="text-center">
                                            <b-col class="sottotesto">{{ this.$i18n.t("patient.card.lblNum") }}</b-col>
                                        </b-row>
                                    </b-card-text>
                                </b-card-footer>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-card>

            <!--<b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <div :class="'sa-card-border-' + getHrRange() + ''" align="center">
                        <div :key="'card-body text-warning'">
                            <b-row>
                                <b-col cols="2">
                                    <h5 :class="'card-title text-warning'" @click="onDashboard"><img :src="checkimage('hr_min')" width="35" height="35" alt="" /></h5>
                                </b-col>
                                <b-col cols="10">
                                    <p style="margin-top: 3px; margin-left: 10px; font-size: 12px;">{{ checkName("hr") }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12">
                                    <p style="margin-top: 3px; margini-left: 10px; font-size: 12px">{{ getDate("hr_max") }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="6">
                                    {{ this.$i18n.t("patient.card.lblMin") }}: <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('hr_min') + ' badge-pill'">{{ getMisuraValue("hr_min") }}</span>
                                </b-col>
                                <b-col cols="6">
                                    {{ this.$i18n.t("patient.card.lblMax") }}: <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('hr_max') + ' badge-pill'">{{ getMisuraValue("hr_max") }}</span>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </b-col>
            </b-row>-->
        </div>
        <div v-else>
            <b-row>
                <b-col cols="12" xs="12" sm="6" md="4" lg="4" xl="2" style="max-height: 200px;">
                    <div :class="'sa-card-border-' + getPressureRange()">
                        <b-card-header :class="'card-header-' + getMisuraRange('lbl.measure.systolicBloodPressure')" style="max-height: 75px;">
                            <b-row align-v="start">
                                <b-col cols="2" class="text-center" align-v="midle">
                                    <h5 class="sa-card-title-icon"><font-awesome-icon icon="tachometer-alt" size="lg"></font-awesome-icon></h5>
                                </b-col>
                                <b-col cols="8" class="text-center" align-v="start">
                                    <h5 class="sa-card-title">
                                        <span> {{ this.$i18n.t("patient.card.lblPressure") }}</span>
                                    </h5>
                                </b-col>
                                <b-col cols="2" class="text-center" align-v="midle">
                                    <h5 class="sa-card-title-icon"><b-icon icon="file-earmark-bar-graph"></b-icon></h5>
                                </b-col>
                            </b-row>
                        </b-card-header>
                        <b-card-body class="text-center">
                            <b-row>
                                <b-col>
                                    <b-card-sub-title class="sottotesto">{{ getDate("lbl.measure.systolicBloodPressure") }}</b-card-sub-title>
                                    <!-- <b-card-sub-title class="sottotesto">22-12-2021</b-card-sub-title> -->
                                </b-col>
                            </b-row>
                        </b-card-body>
                        <b-card-footer :class="'text-center card-footer-' + getMisuraRange('lbl.measure.systolicBloodPressure')" style="max-height: 75px;">
                            <b-card-text>
                                <b-row align-content="center"
                                    ><b-col align-content="center" class="testo2">
                                        <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('lbl.measure.systolicBloodPressure') + ' badge-pill'">{{ getMisuraValue("lbl.measure.systolicBloodPressure") }}</span>
                                        <!-- <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('Systolic Blood Pressure (mmHg)') + ' badge-pill'">126</span> -->
                                    </b-col>
                                    <b-col align-content="center" class="testo2">
                                        <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('lbl.measure.diastolicBloodPressure') + ' badge-pill'">{{ getMisuraValue("lbl.measure.diastolicBloodPressure") }}</span>
                                        <!-- <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('Diastolic Blood Pressure (mmHg)') + ' badge-pill'">79</span> -->
                                    </b-col>
                                </b-row>
                                <b-row class="text-center">
                                    <b-col class="sottotesto">{{ this.$i18n.t("patient.card.lblSystolic") }}</b-col>
                                    <b-col class="sottotesto">{{ this.$i18n.t("patient.card.lblDiastolic") }}</b-col>
                                </b-row>
                            </b-card-text>
                        </b-card-footer>
                    </div>
                </b-col>
                <b-col cols="12" xs="12" sm="6" md="4" lg="4" xl="2" style="max-height: 200px;">
                    <div :class="'sa-card-border-' + getSleepRange()">
                        <b-card-header :class="'card-header-' + getSleepRange()" style="max-height: 75px;">
                            <b-row align-v="start">
                                <b-col cols="2" class="text-center" align-v="midle">
                                    <h5 class="sa-card-title-icon"><font-awesome-icon icon="bed" size="lg"></font-awesome-icon></h5>
                                </b-col>
                                <b-col cols="8" class="text-center" align-v="start">
                                    <h5 class="sa-card-title">
                                        <span> {{ checkName("lbl.measure.sleepEfficiency") }}</span>
                                    </h5>
                                </b-col>
                                <b-col cols="2" class="text-center" align-v="midle">
                                    <h5 class="sa-card-title-icon"><b-icon icon="file-earmark-bar-graph"></b-icon></h5>
                                </b-col>
                            </b-row>
                        </b-card-header>
                        <b-card-body class="text-center">
                            <b-row>
                                <b-col>
                                    <b-card-sub-title class="sottotesto">{{ getDate("lbl.measure.totalSleepTime") }}</b-card-sub-title>
                                </b-col>
                            </b-row>
                        </b-card-body>
                        <b-card-footer :class="'text-center card-footer-' + getSleepRange()" style="max-height: 75px;">
                            <b-card-text>
                                <b-row align-content="center"
                                    ><b-col align-content="center" class="testo2">
                                        <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('lbl.measure.totalSleepTime') + ' badge-pill'">{{ convertHour(getMisuraValue("lbl.measure.totalSleepTime")) }}</span>
                                        <!-- <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('total_sleep_time') + ' badge-pill'">08:22</span> -->
                                    </b-col>
                                    <b-col align-content="center" class="testo2">
                                        <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('lbl.measure.wakeupCount') + ' badge-pill'">{{ getMisuraValue("lbl.measure.wakeupCount") }}</span>
                                        <!-- <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('wakeupcount') + ' badge-pill'">0</span> -->
                                    </b-col>
                                </b-row>
                                <b-row class="text-center">
                                    <b-col class="sottotesto">{{ this.$i18n.t("patient.card.lblHours") }}</b-col>
                                    <b-col class="sottotesto">{{ this.$i18n.t("patient.card.lblInterruptions") }}</b-col>
                                </b-row>
                            </b-card-text>
                        </b-card-footer>
                    </div>
                </b-col>
                <!--<b-col cols="12" xs="12" sm="6" md="4" lg="4" xl="2">
                    <div :class="'sa-card-border-' + getHrRange() + ''" align="center">
                        <div :key="'card-body text-warning'">
                            <b-row>
                                <b-col cols="2">
                                    <h5 :class="'card-title text-warning'" @click="onDashboard"><img :src="checkimage('hr_min')" width="35" height="35" alt="" /></h5>
                                </b-col>
                                <b-col cols="10">
                                    <p style="margin-top: 3px; margin-left: 10px; font-size: 12px; align: center;">{{ checkName("hr") }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12">
                                    <p style="margin-top: 3px; margini-left: 15px; font-size: 12px">{{ getDate("hr_min") }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="6">
                                    {{ this.$i18n.t("patient.card.lblMin") }}: <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('hr_min') + ' badge-pill'">{{ getMisuraValue("hr_min") }}</span>
                                </b-col>
                                <b-col cols="6">
                                    {{ this.$i18n.t("patient.card.lblMax") }}: <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('hr_max') + ' badge-pill'">{{ getMisuraValue("hr_max") }}</span>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </b-col>-->
                <b-col cols="12" xs="12" sm="6" md="4" lg="4" xl="2" style="max-height: 200px;">
                    <div :class="'sa-card-border-' + getMisuraRange('lbl.measure.step')">
                        <b-card-header :class="'card-header-' + getMisuraRange('lbl.measure.step')" style="max-height: 75px;">
                            <b-row align-v="start">
                                <b-col cols="2" class="text-center" align-v="midle">
                                    <h5 class="sa-card-title-icon"><font-awesome-icon icon="walking" size="lg"></font-awesome-icon></h5>
                                </b-col>
                                <b-col cols="8" class="text-center" align-v="start">
                                    <h5 class="sa-card-title">
                                        <span> {{ checkName("lbl.measure.step") }}</span>
                                    </h5>
                                </b-col>
                                <b-col cols="2" class="text-center" align-v="midle">
                                    <h5 class="sa-card-title-icon"><b-icon icon="file-earmark-bar-graph"></b-icon></h5>
                                </b-col>
                            </b-row>
                            <!-- <b-row align-v="start">
                                <b-col cols="1"></b-col>
                                <b-col cols="10" align-v="start" class="testo2" align-content="center"
                                    ><b-row
                                        ><b-col cols="2"><font-awesome-icon icon="walking" size="lg"></font-awesome-icon></b-col>
                                        <b-col cols="10"
                                            ><span> {{ checkName("steps") }}</span></b-col
                                        >
                                    </b-row>
                                </b-col>
                                <b-col class="testo2" cols="1"
                                    ><h5 class="testo2" @click="onDashboard"><b-icon icon="file-earmark-bar-graph"></b-icon></h5
                                ></b-col>
                            </b-row> -->
                        </b-card-header>
                        <b-card-body class="text-center">
                            <b-row>
                                <b-col>
                                    <b-card-sub-title class="sottotesto">{{ getDate("lbl.measure.step") }}</b-card-sub-title>
                                    <!-- <b-card-sub-title class="sottotesto">22-12-2021</b-card-sub-title> -->
                                </b-col>
                            </b-row>
                        </b-card-body>
                        <b-card-footer :class="'text-center card-footer-' + getMisuraRange('lbl.measure.step')" style="max-height: 75px;">
                            <b-card-text>
                                <b-row align-content="center"
                                    ><b-col align-content="center" class="testo2">
                                        <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('lbl.measure.step') + ' badge-pill'">{{ getMisuraValue("lbl.measure.step") }}</span>
                                        <!-- <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('steps') + ' badge-pill'">4352</span> -->
                                    </b-col></b-row
                                ><b-row class="text-center">
                                    <b-col class="sottotesto">{{ this.$i18n.t("patient.card.lblNum") }}</b-col>
                                </b-row>
                            </b-card-text>
                        </b-card-footer>
                    </div>
                </b-col>
                <b-col cols="12" xs="12" sm="6" md="4" lg="4" xl="2" style="max-height: 200px;">
                    <div :class="'sa-card-border-' + getMisuraRange('lbl.measure.weight')">
                        <b-card-header :class="'card-header-' + getMisuraRange('lbl.measure.weight')" style="max-height: 75px;">
                            <b-row align-v="start">
                                <b-col cols="2" class="text-center" align-v="midle">
                                    <h5 class="sa-card-title-icon"><font-awesome-icon icon="weight" size="lg"></font-awesome-icon></h5>
                                </b-col>
                                <b-col cols="8" class="text-center" align-v="start">
                                    <h5 class="sa-card-title">
                                        <span> {{ checkName("lbl.measure.weight") }}</span>
                                    </h5>
                                </b-col>
                                <b-col cols="2" class="text-center" align-v="midle">
                                    <h5 class="sa-card-title-icon"><b-icon icon="file-earmark-bar-graph"></b-icon></h5>
                                </b-col>
                            </b-row>
                            <!-- <b-row align-v="start">
                                <b-col cols="1"></b-col>
                                <b-col cols="10" align-v="start" class="testo2" align-content="center"
                                    ><b-row
                                        ><b-col cols="2"><font-awesome-icon icon="weight" size="lg"></font-awesome-icon></b-col>
                                        <b-col cols="10"
                                            ><span> {{ checkName("Weight (kg)") }}</span></b-col
                                        >
                                    </b-row>
                                </b-col>
                                <b-col class="testo2" cols="1"
                                    ><h5 class="testo2" @click="onDashboard"><b-icon icon="file-earmark-bar-graph"></b-icon></h5
                                ></b-col>
                            </b-row> -->
                        </b-card-header>
                        <b-card-body class="text-center">
                            <b-row>
                                <b-col>
                                    <b-card-sub-title class="sottotesto">{{ getDate("lbl.measure.weight") }}</b-card-sub-title>
                                    <!-- <b-card-sub-title class="sottotesto">22-12-2021</b-card-sub-title> -->
                                </b-col>
                            </b-row>
                        </b-card-body>
                        <b-card-footer :class="'text-center card-footer-' + getMisuraRange('lbl.measure.weight')" style="max-height: 75px;">
                            <b-card-text>
                                <b-row align-content="center"
                                    ><b-col align-content="center" class="testo2">
                                        <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('lbl.measure.weight') + ' badge-pill'">{{ getMisuraValue("lbl.measure.weight") }}</span>
                                        <!-- <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('Weight (kg)') + ' badge-pill'">72</span> -->
                                    </b-col></b-row
                                ><b-row class="text-center">
                                    <b-col class="sottotesto">{{ this.$i18n.t("patient.card.lblNum") }}</b-col>
                                </b-row>
                            </b-card-text>
                        </b-card-footer>
                    </div>
                </b-col>
                <b-col cols="12" xs="12" sm="6" md="4" lg="4" xl="2" style="max-height: 200px;">
                    <div :class="'sa-card-border-' + getMisuraRange('RiskCalculationResult')">
                        <b-card-header :class="'card-header-' + getMisuraRange('RiskCalculationResult')" style="max-height: 75px;">
                            <b-row align-v="start">
                                <b-col cols="2" class="text-center" align-v="midle">
                                    <h5 class="sa-card-title-icon"><font-awesome-icon icon="heart-broken" size="lg"></font-awesome-icon></h5>
                                </b-col>
                                <b-col cols="8" class="text-center" align-v="start">
                                    <h5 class="sa-card-title">
                                        <span> {{ checkName("RiskCalculationResult") }}</span>
                                    </h5>
                                </b-col>
                                <b-col cols="2" class="text-center" align-v="midle">
                                    <h5 class="sa-card-title-icon"><b-icon icon="file-earmark-bar-graph"></b-icon></h5>
                                </b-col>
                            </b-row>
                            <!-- <b-row align-v="start">
                                <b-col cols="1"></b-col>
                                <b-col cols="10" align-v="start" class="testo2" align-content="center"
                                    ><b-row
                                        ><b-col cols="2"><font-awesome-icon icon="heart-broken" size="lg"></font-awesome-icon></b-col>
                                        <b-col cols="10"
                                            ><span> {{ checkName("RiskCalculationResult") }}</span></b-col
                                        >
                                    </b-row>
                                </b-col>
                                <b-col class="testo2" cols="1"
                                    ><h5 class="testo2" @click="onDashboard"><b-icon icon="file-earmark-bar-graph"></b-icon></h5
                                ></b-col>
                            </b-row> -->
                        </b-card-header>
                        <b-card-body class="text-center">
                            <b-row>
                                <b-col>
                                    <b-card-sub-title class="sottotesto">{{ getDate("RiskCalculationResult") }}</b-card-sub-title>
                                    <!-- <b-card-sub-title class="sottotesto">22-12-2021</b-card-sub-title> -->
                                </b-col>
                            </b-row>
                        </b-card-body>
                        <b-card-footer :class="'text-center card-footer-' + getMisuraRange('RiskCalculationResult')" style="max-height: 75px;">
                            <b-card-text>
                                <b-row align-content="center"
                                    ><b-col align-content="center" class="testo2">
                                        <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('RiskCalculationResult') + ' badge-pill'">{{ getMisuraValue("RiskCalculationResult") }}</span>
                                        <!-- <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('RiskCalculationResult') + ' badge-pill'">1</span> -->
                                    </b-col></b-row
                                ><b-row class="text-center">
                                    <b-col class="sottotesto">{{ this.$i18n.t("patient.card.lblNum") }}</b-col>
                                </b-row>
                            </b-card-text>
                        </b-card-footer>
                    </div>
                </b-col>
                <b-col cols="12" xs="12" sm="6" md="4" lg="4" xl="2" style="max-height: 200px;">
                    <div :class="'sa-card-border-' + getMisuraRange('Adherence')">
                        <b-card-header :class="'card-header-' + getMisuraRange('Adherence')" style="max-height: 75px;">
                            <b-row align-v="start">
                                <b-col cols="2" class="text-center" align-v="midle">
                                    <h5 class="sa-card-title-icon"><font-awesome-icon icon="pills" size="lg"></font-awesome-icon></h5>
                                </b-col>
                                <b-col cols="8" class="text-center" align-v="start">
                                    <h5 class="sa-card-title">
                                        <span> {{ checkName("Adherence") }}</span>
                                    </h5>
                                </b-col>
                                <b-col cols="2" class="text-center" align-v="midle">
                                    <h5 class="sa-card-title-icon"><b-icon icon="file-earmark-bar-graph"></b-icon></h5>
                                </b-col>
                            </b-row>
                            <!-- <b-row align-v="start">
                                <b-col cols="1"></b-col>
                                <b-col cols="10" align-v="start" class="testo2" align-content="center"
                                    ><b-row
                                        ><b-col cols="2"><font-awesome-icon icon="pills" size="lg"></font-awesome-icon></b-col>
                                        <b-col cols="10"
                                            ><span> {{ checkName("Adherence") }}</span></b-col
                                        >
                                    </b-row>
                                </b-col>
                                <b-col class="testo2" cols="1"
                                    ><h5 class="testo2" @click="onDashboard"><b-icon icon="file-earmark-bar-graph"></b-icon></h5
                                ></b-col>
                            </b-row> -->
                        </b-card-header>
                        <b-card-body class="text-center">
                            <b-row>
                                <b-col>
                                    <b-card-sub-title class="sottotesto">{{ getDate("Adherence") }}</b-card-sub-title>
                                </b-col>
                            </b-row>
                        </b-card-body>
                        <b-card-footer :class="'text-center card-footer-' + getMisuraRange('Adherence')" style="max-height: 75px;">
                            <b-card-text>
                                <b-row align-content="center"
                                    ><b-col align-content="center" class="testo2">
                                        <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('Adherence') + ' badge-pill'">{{ getMisuraValue("Adherence") }}</span>
                                    </b-col></b-row
                                ><b-row class="text-center">
                                    <b-col class="sottotesto">{{ this.$i18n.t("patient.card.lblNum") }}</b-col>
                                </b-row>
                            </b-card-text>
                        </b-card-footer>
                    </div>
                </b-col>
                <!--<b-col cols="12" xs="12" sm="2" md="2" lg="2" xl="2" v-for="item in misure" v-bind:key="item.id">
            <b-card :class="'sa-card-border-' + checkclass(item.range)" align="center">
                <p>{{ item.measurename }}</p>
            </b-card>
        </b-col>-->
            </b-row>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
export default {
    props: {
        idPaziente: { type: String },
        orientation: {
            type: String,
            default: function() {
                return "";
            },
        },
    },
    data() {
        return {
            linkMeasurement: "/lastmeasure",
            misure: [],
            passi: null,
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.query.idPaziente;
        me.loadMeasurement();
    },
    methods: {
        convertHour(time) {
            let returnValue = "---";
            try {
                returnValue = new Date(time * 1000).toISOString().substr(12, 4);
            } catch {
                returnValue = "---";
            }
            return returnValue;
        },
        getAvarage(label) {
            let me = this;
            let returnValue = "---";
            let somma = 0;
            let media = 0;

            me.misure.forEach((element) => {
                if (element.measurename === label) {
                    somma = somma + Number(element.value);
                    media++;
                }
            });

            if (media !== 0) {
                returnValue = somma / media;
            }
            console.log(somma + "    " + media);
            return returnValue;
        },
        getLastDay(label) {
            console.log(label);
        },
        getLastWeek(label) {
            console.log(label);
        },
        getMisuraValue(label) {
            let me = this;
            let returnValue = "---";
            if (label === "Adherence") {
                returnValue = "70%";
            } else {
                me.misure.forEach((element) => {
                    if (element.measurename === label) {
                        returnValue = element.value;
                    }
                });
            }

            return returnValue;
        },
        getMisuraRange(label) {
            let me = this;
            let returnValue = "NORMAL";
            if (label === "Adherence") {
                returnValue = "warning";
            } else if (label === "RiskCalculationResult") {
                me.misure.forEach((element) => {
                    if (element.measurename === label) {
                        if (element.value <= 1) {
                            returnValue = "success";
                        } else if (element.value > 1 && element.value <= 3) {
                            returnValue = "warning";
                        } else {
                            returnValue = "alert";
                        }
                    }
                });
            } else {
                // console.log("OK " + label + " ");
                me.misure.forEach((element) => {
                    if (element.measurename === label) {
                        returnValue = element.range === null ? "NORMAL" : element.range;
                    }
                });
            }
            switch (returnValue.toLowerCase()) {
                case "normal":
                    returnValue = "success";
                    break;
                case "warning":
                    returnValue = "warning";
                    break;
                case "success":
                    returnValue = "success";
                    break;
                case "alert":
                    returnValue = "alert";
                    break;
                default:
                    returnValue = "secondary";
                    break;
            }
            returnValue = returnValue === "NORMAL" ? "success" : returnValue;
            // console.log(label + " " + returnValue);
            return returnValue.toLowerCase();
        },
        getPressureRange() {
            let me = this;
            let returnValue = "success";
            me.misure.forEach((element) => {
                if (element.measurename === "lbl.measure.systolicBloodPressure" && returnValue.toLowerCase() !== "alert") {
                    if (element.measurename === "lbl.measure.systolicBloodPressure" && returnValue.toLowerCase() !== "warning") {
                        returnValue = element.range;
                    }
                }
                if (element.measurename === "lbl.measure.diastolicBloodPressure" && returnValue.toLowerCase() !== "alert") {
                    if (element.measurename === "lbl.measure.diastolicBloodPressure" && returnValue.toLowerCase() !== "warning") {
                        returnValue = element.range;
                    }
                }
            });

            returnValue = returnValue === "NORMAL" ? "success" : returnValue;
            return returnValue.toLowerCase();
        },
        getSleepRange() {
            let me = this;
            let returnValue = "success";
            me.misure.forEach((element) => {
                // console.log(element.measurename + " " + returnValue + " " + element.range);
                if (element.measurename === "lbl.measure.totalSleepTime" && returnValue.toLowerCase() !== "alert") {
                    if (element.measurename === "lbl.measure.totalSleepTime" && returnValue.toLowerCase() !== "warning") {
                        returnValue = element.range;
                    }
                }
                if (element.measurename === "lbl.measure.wakeupCount" && returnValue.toLowerCase() !== "alert") {
                    if (element.measurename === "lbl.measure.wakeupCount" && returnValue.toLowerCase() !== "warning") {
                        returnValue = element.range;
                    }
                }
            });

            switch (returnValue.toLowerCase()) {
                case "normal":
                    returnValue = "success";
                    break;
                case "alert":
                    returnValue = "alert";
                    break;
                case "warning":
                    returnValue = "warning";
                    break;
                case "success":
                    returnValue = "success";
                    break;
                default:
                    returnValue = "secondary";
                    break;
            }
            returnValue = returnValue.toLowerCase() === "normal" ? "success" : returnValue;

            return returnValue.toLowerCase();
        },
        getHrRange() {
            let me = this;
            let returnValue = "success";
            me.misure.forEach((element) => {
                if (element.measurename === "hr_min" && returnValue !== "alert") {
                    returnValue = element.range;
                }
                if (element.measurename === "hr_max" && returnValue !== "alert") {
                    returnValue = element.range;
                }
            });

            returnValue = returnValue === "NORMAL" ? "success" : returnValue;

            return returnValue.toLowerCase();
        },
        loadMeasurement() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + "/hyperhealth" + me.linkMeasurement + "?idPaziente=" + me.idPaziente;
            axios
                .get(link)
                .then((response) => {
                    //   console.log(response.data.data);
                    let risposta = response.data.data;
                    // console.log(risposta);
                    risposta.forEach((element) => {
                        if (me.checkName(element.measurename) !== "unknown") {
                            me.misure.push(element);
                        }
                    });
                })
                .catch((e) => {
                    console.log("Error: " + e.response + " " + link);
                });
        },
        returnlabel() {
            let label = this.$i18n.t("patient.rangeConfig.lblDetectedOn");
            return label;
        },
        onDashboard() {
            let me = this;
            console.log(me.idPaziente);
            me.$router.replace("/paziente/dashboard?idPaziente=" + me.idPaziente).catch((err) => {
                err;
            });
        },
        checkclass(range) {
            switch (range) {
                case "WARNING":
                    return "warning";
                case "NORMAL":
                    return "success";
                case "ALERT":
                    return "alert";
                default:
                    return "secondary";
            }
        },
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY");
            } else {
                return "---";
            }
        },
        checkName(name) {
            let label = "";
            switch (name) {
                case "lbl.measure.sleepEfficiency":
                    label = this.$i18n.t("patient.card.lblSleepEfficiency");
                    break;
                case "lbl.measure.hrAverage":
                    label = this.$i18n.t("patient.card.lblHr");
                    break;
                case "lbl.measure.hrMin":
                    label = this.$i18n.t("patient.card.lblHrMin");
                    break;
                case "lbl.measure.hrMax":
                    label = this.$i18n.t("patient.card.lblHrMax");
                    break;
                case "lbl.measure.systolicBloodPressure":
                    label = this.$i18n.t("patient.card.lblSystolicBloodPressure");
                    break;
                case "lbl.measure.diastolicBloodPressure":
                    label = this.$i18n.t("patient.card.lblDiastolicBloodPressure");
                    break;
                case "delete-unlink-update":
                    label = this.$i18n.t("patient.card.lblDeleted");
                    break;
                case "lbl.measure.weight":
                    label = this.$i18n.t("patient.card.lblWeight");
                    break;
                case "lbl.measure.step":
                    label = this.$i18n.t("patient.card.lblSteps");
                    break;
                case "lbl.measure.wakeupCount":
                    label = this.$i18n.t("patient.card.lblWakeupcount");
                    break;
                case "lbl.measure.totalSleepTime":
                    label = this.$i18n.t("patient.card.lblTotalSleepTime");
                    break;
                case "lbl.measure.totalCalories":
                    label = this.$i18n.t("patient.card.lblTotalSleepTime");
                    break;
                case "RiskCalculationResult":
                    label = this.$i18n.t("patient.card.lblRiskCalculationResult");
                    break;
                case "Adherence":
                    label = this.$i18n.t("patient.card.lblAdherence");
                    break;
                default:
                    label = "unknown";
                    break;
            }
            return label;
        },
        getDate(name) {
            let me = this;
            let returnValue = "---";
            if (name === "Adherence") {
                returnValue = "22-12-2021";
            } else {
                me.misure.forEach((element) => {
                    if (element.measurename === name) {
                        returnValue = moment(element.istant).format("DD-MM-YYYY");
                    }
                });
            }

            return this.$i18n.t("global.lblDate") + ": " + returnValue;
        },
        checkimage(name) {
            let image = "";
            switch (name) {
                case "lbl.measure.hrAverage":
                    image = "battiti2.png";
                    break;
                case "lbl.measure.totalCalories":
                    image = "calorie.png";
                    break;
                case "lbl.measure.calories":
                    image = "calorie.png";
                    break;
                case "lbl.measure.active":
                    image = "camminare.png";
                    break;
                case "lbl.measure.totalSleepTime":
                    image = "sonno.png";
                    break;
                case "lbl.measure.sleepEfficiency":
                    image = "sonno.png";
                    break;
                case "lbl.measure.hrMin":
                    image = "battiti2.png";
                    break;
                case "lbl.measure.hrMax":
                    image = "battiti2.png";
                    break;
                case "lbl.measure.elevation":
                    image = "scale.png";
                    break;
                // case "lbl.measure.wakeupCount":
                //   image = "veglia_notturna.png";
                //   break;
                case "Skin Temperature (celsius)":
                    image = "temperatura.png";
                    break;
                case "lbl.measure.boneMass":
                    image = "massa_ossea.png";
                    break;
                case "Body Temperature (celsius)":
                    image = "temperatura_corporea.png";
                    break;
                case "lbl.measure.temperature":
                    image = "temperatura.png";
                    break;
                case "lbl.measure.heartPulse":
                    image = "battiti.png";
                    break;
                case "lbl.measure.spo2":
                    image = "ossigeno.png";
                    break;
                // case "Heart Pulse (bpm) - only for BPM devices":
                //   image = "battiti.png";
                //   break;
                case "lbl.measure.systolicBloodPressure":
                    image = "pressione_sis.png";
                    break;
                case "lbl.measure.diastolicBloodPressure":
                    image = "pressione_sis2.png";
                    break;
                case "delete-unlink-update":
                    image = "noimage.png";
                    break;
                case "lbl.measure.sleepScore":
                    image = "sonno.png";
                    break;
                case "lbl.measure.wakeupCount":
                    image = "sonno.png";
                    break;
                case "lbl.measure.weight":
                    image = "bilancia.png";
                    break;
                case "lbl.measure.fatFreeMass":
                    image = "massa_magra.png";
                    break;
                case "lbl.measure.height":
                    image = "altezza.png";
                    break;
                case "lbl.measure.fatMassWeight":
                    image = "massa_grassa.png";
                    break;
                case "lbl.measure.distance":
                    image = "distanza.png";
                    break;
                case "lbl.measure.fatRatio":
                    image = "grasso.png";
                    break;
                case "lbl.measure.step":
                    image = "camminare.png";
                    break;
                case "lbl.measure.hydration":
                    image = "idratazione.png";
                    break;
                case "lbl.measure.muscleMass":
                    image = "massa_muscolare.png";
                    break;
                case "RiskCalculationResult":
                    image = "rischio.png";
                    break;
                case "Adherence":
                    image = "pillole.png";
                    break;
                default:
                    image = "noimage.png";
                    break;
            }
            try {
                return require("../../../assets/images/icons/measurement/" + image);
            } catch (Exeption) {
                return require("../../../assets/images/icons/measurement/noimage.png");
            }
        },
    },
};
</script>

<style scoped>
.card-header-secondary,
.card-header-success,
.card-header-warning,
.card-header-alert {
    padding: 0rem 0rem !important;
}
.card-footer-secondary,
.card-footer-success,
.card-footer-warning,
.card-footer-alert {
    padding: 0rem 0rem !important;
}
.card-header-secondary {
    /* padding: 0.75rem 1.25rem !important; */
    background-color: rgba(0, 0, 0, 0.03) !important;
    border-top: 0 solid rgba(0, 0, 0, 0.125) !important;
}
.card-header-success {
    /* padding: 0rem 0rem !important; */
    background-color: rgba(16, 196, 105, 0.125) !important;
    border-top: 0 solid rgba(16, 196, 105, 0.25) !important;
}

.card-header-warning {
    /* padding: 0.75rem 1.25rem !important; */
    background-color: rgba(255, 193, 7, 0.125) !important;
    border-top: 0 solid rgba(255, 193, 7, 0.25) !important;
}

.card-header-alert {
    /* padding: 0.75rem 1.25rem !important; */
    background-color: rgba(255, 91, 91, 0.125) !important;
    border-top: 0 solid rgba(255, 91, 91, 0.25) !important;
}

.card-footer-secondary {
    /* padding: 0.75rem 1.25rem !important; */
    background-color: rgba(0, 0, 0, 0.03) !important;
    border-top: 0 solid rgba(0, 0, 0, 0.125) !important;
}

.card-footer-success {
    /* padding: 0.75rem 1.25rem !important; */
    background-color: rgba(16, 196, 105, 0.125) !important;
    border-top: 0 solid rgba(16, 196, 105, 0.25) !important;
}
.card-footer-warning {
    /* padding: 0.75rem 1.25rem !important; */
    background-color: rgba(255, 193, 7, 0.125) !important;
    border-top: 0 solid rgba(255, 193, 7, 0.25) !important;
}

.card-footer-alert {
    /* padding: 0.75rem 1.25rem !important; */
    background-color: rgba(255, 91, 91, 0.125) !important;
    border-top: 0 solid rgba(255, 91, 91, 0.25) !important;
}

.sa-card-border-alert {
    border-color: #ff5b5b !important;
    min-height: 100px;
}
.sa-card-border-warning {
    border-color: #ffc107 !important;
    min-height: 100px;
}
.sa-card-border-success {
    border: 2px solid !important;
    border-color: #10c469 !important;

    min-height: 100px;
}
.sa-card-border-secondary {
    border: 2px solid !important;
    border-color: #6c757d !important;
    min-height: 100px;
}
.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0.5rem;
}
.sa-text-misura-value {
    font-size: 1rem;
}
.testo {
    font-size: 18px;
    color: #ac1c3c;
}
.sottotesto {
    font-size: 12px;
}

.testo2 {
    font-size: 14px;
    color: #ac1c3c;
    height: 100%;
}

.sa-card-title {
    font-size: 12px;
    color: #ac1c3c;
    height: 100%;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sa-card-title-icon {
    font-size: 14px;
    color: #ac1c3c;
    height: 100%;
}
.sa-card-title-icon svg {
    font-size: 16px;
}

.hr {
    height: 1px;
    margin: 3px;
    background-color: #ccc;
    border: none;
}

h3 {
    margin: 40px 0 0;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
a {
    color: #42b983;
}
</style>
