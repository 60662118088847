<template>
  <sa-page-layout :showModalLoading="showModalLoading" :btnNewVisible="true" @refresh="onRefresh" :btnRefreshVisible="true" :linkedit="linkedit">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-header">
      <h3>{{ this.$i18n.t("patient.afa.lblEligibility") }}</h3>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" :filter="filter" responsive stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template v-slot:cell(dataEvento)="{ item }">
            <router-link class="sa-edit-link" :to="'/paziente/medicinadellosportidoneita/view/' + item.id">{{ formatDateTime(item.dataEvento) }}</router-link>
          </template>
          <template #cell(actions)>
            <!--<b-button size="sm" variant="btn btn-primary waves-effect  waves-light width-sm no-text" style="margin-right:3px" @click="onEdit">
                            <b-icon icon="display"></b-icon>
                        </b-button>-->
            <b-button size="sm" variant="btn btn-purple waves-effect  waves-light width-sm no-text" style="margin-right:3px" @click="onStampa">
              <b-icon icon="printer"></b-icon>
            </b-button>
          </template>
        </b-table>
      </div>
    </template>
  </sa-page-layout>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { SaPageLayout } from "../../../../../../template/sa/components/layout/contents";
import PazienteAnagrafica from "../../../../components/PazienteAnagrafica.vue";
import UtilityMixin from "../../../../../utility/UtilityMixin.js";
import EventiMixin from "../../../mixins/EventiMixins.js";
export default {
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  mixins: [UtilityMixin, EventiMixin],
  components: {
    SaPageLayout,
    PazienteAnagrafica,
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient() {
      return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      linkedit: "/paziente/medicinadellosportidoneita",
      filter: {},
      showModalLoading: false,
      fields: [
        {
          label: this.getLabelTraduora("patient.events.lblEventDate"),
          key: "dataEvento",
          sortable: true,
          formatter: (value) => {
            return moment(new Date(value)).format("DD-MM-YYYY");
          },
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 4rem",
        },
      ],
      items: [],
      pathRest: "/medicinadellosportidoneita",
    };
  },
  created() {},
  mounted() {
    let me = this;

    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathRest + "?idPaziente=";
      axios
        .get(link + me.idPaziente, { params: me.filtro })
        .then((response) => {
          me.items = [];
          me.items = response.data.data;
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onStampa() {},
  },
};
</script>
