<template>
  <sa-page-layout :showModalLoading="showModalLoading" :btnSaveVisible="true" :btnAnnullaVisible="true" :btnBackVisible="false" :linkback="linkback" :pathResource="pathResource" :data="jsonData">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <strong>{{ this.$i18n.t("patients.lblMother") }}</strong>
            <br />
            <b-form-select v-model="jsonData.madre" :options="statoGenitoreOptions"></b-form-select>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <strong>{{ this.$i18n.t("patients.lblFather") }}</strong>
            <br />
            <b-form-select v-model="jsonData.padre" :options="statoGenitoreOptions"></b-form-select>
          </b-col>
        </b-row>
        <b-row
          ><b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <strong>{{ this.$i18n.t("patients.lblBrothers") }}</strong>
            <br />
            <b-form-checkbox v-model="jsonData.fratelli" switch :checked="jsonData.fratelli"></b-form-checkbox>
          </b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <strong>{{ this.$i18n.t("patients.lblNumberBrothers") }}</strong>
            <br />
            <b-form-input type="number" v-model="jsonData.numeroFratelli"></b-form-input>
          </b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <strong>{{ this.$i18n.t("patients.lblSisters") }}</strong>
            <br />
            <b-form-checkbox v-model="jsonData.sorelle" switch :checked="jsonData.sorelle"></b-form-checkbox>
          </b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <strong>{{ this.$i18n.t("patients.lblNumberSisters") }}</strong>
            <br />
            <b-form-input type="number" v-model="jsonData.numeroSorelle"></b-form-input>
          </b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <strong>{{ this.$i18n.t("patients.lblSpouse") }}</strong>
            <br />
            <b-form-checkbox v-model="jsonData.coniuge" switch :checked="jsonData.coniuge"></b-form-checkbox>
          </b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <strong>{{ this.$i18n.t("patients.lblSpouseStatus") }}</strong>
            <br />
            <b-form-select v-model="jsonData.coniugeSino" :options="statoGenitoreOptions"></b-form-select> </b-col
          ><b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <strong>{{ this.$i18n.t("patients.lblSons") }}</strong>
            <br />
            <b-form-checkbox v-model="jsonData.figli" switch :checked="jsonData.figli"></b-form-checkbox>
          </b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <strong>{{ this.$i18n.t("patients.lblNumberOfChildren") }}</strong>
            <br />
            <b-form-input type="number" v-model="jsonData.numeroFigli"></b-form-input>
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12">
            <strong>{{ this.$i18n.t("patients.lblOther") }}</strong>
            <br />
            <b-form-textarea type="text" no-resize v-model="jsonData.altro"></b-form-textarea>
          </b-col>
        </b-row>
        <b-row
          ><b-col xs="12" sm="12" md="12" lg="12" xl="12">
            <strong
              ><h4>{{ this.$i18n.t("patients.lblDiseasesPresent") }}:</h4></strong
            >
            <br
          /></b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <strong>{{ this.$i18n.t("patients.lblCalculosisOfTheGallbladder") }}</strong>
            <br />
            <b-form-select v-model="jsonData.calcolosiDellaColecisti" :options="malattieOptions"></b-form-select> </b-col
          ><b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <strong>{{ this.$i18n.t("patients.lblGastricUlcer") }}</strong>
            <br />
            <b-form-select v-model="jsonData.ulceraGastrica" :options="malattieOptions"></b-form-select> </b-col
          ><b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <strong>{{ this.$i18n.t("patients.lblDuodenalUlcer") }}</strong>
            <br />
            <b-form-select v-model="jsonData.ulceraDuodenale" :options="malattieOptions"></b-form-select> </b-col
          ><b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <strong>{{ this.$i18n.t("patients.lblStomachCarcinoma") }}</strong>
            <br />
            <b-form-select v-model="jsonData.carcinomaDelloStomaco" :options="malattieOptions"></b-form-select> </b-col
          ><b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <strong>{{ this.$i18n.t("patients.lblGastricLymphoma") }}</strong>
            <br />
            <b-form-select v-model="jsonData.linfomaGastrico" :options="malattieOptions"></b-form-select> </b-col
          ><b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <strong>{{ this.$i18n.t("patients.lblRefluxEsophagitis") }}</strong>
            <br />
            <b-form-select v-model="jsonData.esofagiteDaReflusso" :options="malattieOptions"></b-form-select> </b-col
          ><b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <strong>{{ this.$i18n.t("patients.lblColonCancer") }}</strong>
            <br />
            <b-form-select v-model="jsonData.carcinomaColon" :options="malattieOptions"></b-form-select> </b-col
          ><b-col xs="12" sm="12" md="12" lg="12" xl="12">
            <strong>{{ this.$i18n.t("patients.lblOther") }}</strong>
            <br />
            <b-form-textarea type="text" no-resize v-model="jsonData.altraMalattia"></b-form-textarea> </b-col
        ></b-row>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
export default {
  components: { SaPageLayout, PazienteAnagrafica },
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  data() {
    return {
      linkback: null,
      pathResource: "/gastroanamnesifamiliare",
      jsonData: {},
      showModalLoading: false,
      statoGenitoreOptions: [
        { text: this.$i18n.t("patient.lblNotDetected"), value: this.$i18n.t("patient.lblNotDetected") }, //NON RILEVATO
        { text: this.$i18n.t("patient.lblLiving"), value: this.$i18n.t("patient.lblLiving") }, //VIVENTE
        { text: this.$i18n.t("patient.lblDeceased"), value: this.$i18n.t("patient.lblDeceased") }, //DECEDUTO
      ],
      malattieOptions: [
        { text: this.$i18n.t("patient.lblNotDetected"), value: this.$i18n.t("patient.lblNotDetected") }, //NON RILEVATO
        { text: this.$i18n.t("patient.lblYes"), value: this.$i18n.t("patient.lblYes") }, //SI
        { text: this.$i18n.t("patient.lblNo"), value: this.$i18n.t("patient.lblNo") }, //NO
      ],
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient: {
      get: function() {
        let returnValue = "";
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length > 1) {
              return "/paziente/view/" + this.idPaziente;
            } else {
              if (this.utente.gruppo.menuGruppo.length == 1) {
                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                  return "/paziente/view/" + this.idPaziente;
                } else {
                  return "/paziente/homepaziente";
                }
              }
            }
          }
        }
        return returnValue;
      },
    },
  },
  mounted() {
    let me = this;
    me.jsonData.idPaziente = this.$route.query.idPaziente;
    me.jsonData.tipoEvento = "Gastroenterologia - Anamnesi Familiare";
    me.jsonData.idAmbulatorio = sessionStorage.getItem("idAmbulatorio");
    me.id = this.$route.params.id;
    me.loadData();
    me.linkback = "/paziente/gastroenterologia/anamnesifamiliare/view/" + me.id + "?idPaziente=" + me.idPaziente;
    if (me.id !== "-1") {
      me.linkback = "/paziente/gastroenterologia/anamnesifamiliare/view/" + me.id + "?idPaziente=" + me.idPaziente;
    } else {
      me.linkback = "/paziente/gastroenterologia/anamnesifamiliare?idPaziente=" + me.idPaziente;
    }
  },
  methods: {
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        });
      }
    },
  },
};
</script>
