<template>
  <sa-page-layout :showModalLoading="showModalLoading" :btnSaveVisible="true" :btnAnnullaVisible="true" :btnBackVisible="false" :linkback="linkback" :pathResource="pathResource" :data="jsonData">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-header">
      <h5 class="sa-text-left">Dati</h5>
    </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <!-- InizioBody  -->
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><h5>{{ getTranslate("patient.cardiology.lblGeneralData") }}</h5></b-col
          >
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblWeight") }}</span
            ><br />
            <b-form-input v-model="jsonData.peso" type="number"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblHeight") }}</span
            ><br />
            <b-form-input v-model="jsonData.altezza" type="number"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblBMI") }}</span
            ><br />
            <b-form-input v-model="jsonData.bmi" type="number"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblBSA") }}</span
            ><br />
            <b-form-input v-model="jsonData.bsa" type="number"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblBloodPression") }}</span
            ><br />
            <b-row
              ><b-col xs="12" sm="6" md="6" lg="6" xl="6"><b-form-input v-model="jsonData.pas" type="number" placeholder="Sys"></b-form-input></b-col
              ><b-col xs="12" sm="6" md="6" lg="6" xl="6"><b-form-input v-model="jsonData.pad" type="number" placeholder="Dias"></b-form-input></b-col
            ></b-row>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblFC") }}</span
            ><br />
            <b-form-input v-model="jsonData.fc" type="number"></b-form-input
          ></b-col>
        </b-row>
        <b-row
          ><b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><h5>{{ getTranslate("patient.cardiology.lblCommonCarotid") }}</h5></b-col
          ></b-row
        >
        <b-row
          ><b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><h5>{{ getTranslate("patient.cardiology.lblBulbCarotid") }}</h5></b-col
          ></b-row
        >
        <b-row
          ><b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><h5>{{ getTranslate("patient.cardiology.lblInternalCarotid") }}</h5></b-col
          ></b-row
        >
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><h5>{{ getTranslate("patient.cardiology.lblReportNotes") }}</h5></b-col
          >
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><span>{{ getTranslate("patient.cardiology.lblNotes") }}</span>
            <br />
            <b-form-textarea v-model="jsonData.noteLibere" rows="6"></b-form-textarea
          ></b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><span>{{ getTranslate("patient.cardiology.lblComment") }}</span>
            <br />
            <b-form-textarea v-model="jsonData.commento" rows="6"></b-form-textarea
          ></b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><h5>{{ getTranslate("patient.cardiology.lblCardiovascularRiskFactors") }}</h5></b-col
          >
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblHypertension") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.ipertensione" switch :checked="jsonData.ipertensione"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblDyslipidemia") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.dislipidemia" switch :checked="jsonData.dislipidemia"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblDiabetes") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.diabete" switch :checked="jsonData.diabete"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblObesità") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.obesita" switch :checked="jsonData.obesita"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblSmoking") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.fumo" switch :checked="jsonData.fumo"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblCerebrovascularAccidentsFamiliarity") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.familCerebrovascolari" switch :checked="jsonData.familCerebrovascolari"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblIschemicHeartDiseaseFamiliarity") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.familCardiopatiaIsch" switch :checked="jsonData.familCardiopatiaIsch"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblHypertensionFamiliarity") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.familIpertensione" switch :checked="jsonData.familIpertensione"></b-form-checkbox
          ></b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><h5>{{ getTranslate("patient.cardiology.lblConclusions") }}</h5></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblNormExamination") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.esameNellaNorma" switch :checked="jsonData.esameNellaNorma"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblMildAtherosclerosis") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.aterosclerosiLieve" switch :checked="jsonData.aterosclerosiLieve"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblModerateAtherosclerosis") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.aterosclerosiModerata" switch :checked="jsonData.aterosclerosiModerata"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblSevereAtherosclerosis") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.aterosclerosiSevera" switch :checked="jsonData.aterosclerosiSevera"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblCommonCarotidStenosisRight") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.stenosiCcDx" switch :checked="jsonData.stenosiCcDx"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblCommonCarotidStenosisLeft") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.stenosiCcSx" switch :checked="jsonData.stenosiCcSx"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblBulbCarotidStenosisRight") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.stenosiBDx" switch :checked="jsonData.stenosiBDx"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblBulbCarotidStenosisLeft") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.stenosiBSx" switch :checked="jsonData.stenosiSDx"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblInternalCarotidStenosisRight") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.stenosiCiDx" switch :checked="jsonData.stenosiCiDx"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblInternalCarotidStenosisLeft") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.stenosiCiSx" switch :checked="jsonData.stenosiCiSx"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblSignificantNon-hemodynamicAtherosclerosis ") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.aterosclerosiNonEmodin" switch :checked="jsonData.aterosclerosiNonEmodin"></b-form-checkbox
          ></b-col>
        </b-row>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  components: { SaPageLayout, PazienteAnagrafica },
  data() {
    return {
      linkback: null,
      pathResource: "/cardiologiaecocarotidi",
      jsonData: {},
      showModalLoading: false,
      opzioniMalattia: [
        { text: "No", value: "no" },
        { text: "Si", value: "yes" },
        { text: "Ictus -- sopravvissuti", value: "Ictus -- sopravvissuti" },
        { text: "Ictus -- non sopravvissuti", value: "Ictus -- non sopravvissuti" },
        { text: "TIA", value: "TIA" },
        { text: "Vivente", value: "Vivente" },
        { text: "Deceduto", value: "Deceduto" },
      ],
      opzioniDislipidemia: [
        { text: "No", value: "no" },
        { text: "Ipertriglicemica", value: "Ipertriglicemica" },
        { text: "Ipercolesterolemica", value: "Ipercolesterolemica" },
        { text: "Mista", value: "Mista" },
      ],
      opzioniSede1: [
        { text: "Anteriore", value: "anteriore" },
        { text: "Inferiore", value: "inferiore" },
        { text: "Laterale", value: "laterale" },
        { text: "Apicale", value: "apicale" },
        { text: "Settale", value: "settale" },
      ],
      opzioniSede2: [
        { text: "Anteriore", value: "anteriore" },
        { text: "Laterale", value: "laterale" },
        { text: "Apicale", value: "apicale" },
        { text: "Settale", value: "settale" },
      ],
      opzioniSiNo: [
        { text: "Sì", value: true },
        { text: "No", value: false },
      ],
      opzioniLavoro: [
        { text: "-", value: "-" },
        { text: "Sedentaria", value: "sedentaria" },
        { text: "Moderata", value: "moderata" },
        { text: "Stressante", value: "stressante" },
        { text: "Turnista", value: "turnista" },
      ],
      opzioniStudio: [
        { text: "Nessuno", value: "nessuno" },
        { text: "Scuola Elementare", value: "scuola-elementare" },
        { text: "Scuola Media", value: "scuola-media" },
        { text: "Scuola Superiore", value: "scuola-superiore" },
        { text: "Diploma Accademico", value: "diploma-accademico" },
        { text: "Laurea", value: "laurea" },
      ],
      opzioniAlcohol: [
        { text: "<1/2 Lt", value: "<1/2 Lt" },
        { text: "1/2 Lt", value: "1/2 Lt" },
        { text: "1-2 Lt", value: "1-2 Lt" },
        { text: ">2 Lt", value: ">2 Lt" },
      ],
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient: {
      get: function() {
        if (this.utente.gruppo.menuGruppo) {
          if (this.utente.gruppo.menuGruppo.length > 1) {
            return "/paziente/view/" + this.idPaziente;
          } else {
            if (this.utente.gruppo.menuGruppo.length == 1) {
              if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                return "/paziente/view/" + this.idPaziente;
              } else {
                return "/paziente/homepaziente";
              }
            } else {
              return "";
            }
          }
        } else {
          return "";
        }
      },
    },
  },
  mounted() {
    let me = this;
    me.jsonData.idPaziente = this.$route.query.idPaziente;
    me.jsonData.tipoEvento = "Cardiologia - Anamnesi";
    me.id = this.$route.params.id;
    me.jsonData.idAmbulatorio = sessionStorage.getItem("idAmbulatorio");

    me.linkedit = "/paziente/cardiologia/ecocarotidi/edit/" + me.id + "?idPaziente=" + me.idPaziente;
    if (me.id !== "-1") {
      me.linkback = "/paziente/cardiologia/ecocarotidi/view/" + me.id + "?idPaziente=" + me.idPaziente;
      me.loadData();
    } else {
      me.linkback = "/paziente/cardiologia/ecocarotidi?idPaziente=" + me.idPaziente;
    }
  },

  methods: {
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        });
      }
    },
    getTranslate(text) {
      return this.$i18n.t(text);
    },
  },
};
</script>
