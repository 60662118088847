<template>
    <sa-page-layout :btnRefreshVisible="true" :btnNewVisible="true" :linkedit="linkedit" @refresh="onRefresh">
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col sm="6">
                        <b-form-input v-model="filtro.nome" type="search" id="nome" :placeholder="this.$i18n.t('anagrafica.lblFirstName')"></b-form-input>
                    </b-col>
                    <b-col sm="6">
                        <b-form-input v-model="filtro.cognome" type="search" id="cognome" :placeholder="this.$i18n.t('anagrafica.lblLastName')"></b-form-input>
                    </b-col>
                </b-row>
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col sm="">
                        <b-form-input v-model="filtro.codiceFiscale" type="search" id="codiceFiscale" :placeholder="this.$i18n.t('patients.lblFiscalCode')"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">{{ this.$i18n.t("patients.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("patients.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>
        <template slot="table-header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" id="tbl" responsive stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(nominativo)="{ item }">
                        {{item.nome}} {{item.cognome}}
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from '../../../template/sa/components/layout/contents/SaPageLayout.vue';
export default {
    components: { SaPageLayout },
    computed: {
 
               rows() {
            return this.items.length;
        },
    },
    data() {
        return {
            showmolalloading: false,
            linkedit: "/patientaccess",
            pathResource: "/pazienteaccesso",
            currentPage: 1,
            perPage: 20,
            fields: [
                {
                    label:  this.$i18n.t("patients.lblNominative"),
                    key: "nominativo",
                    sortable: true,
                    thStyle: "width: 50rem",
                },
                {
                    label: this.$i18n.t("patients.lblFiscalCode"),
                    key: "codiceFiscale",
                    sortable: true,
                    thStyle: "width: 50rem",
                },
                {
                    label: this.$i18n.t(" login.lblUsername"),
                    key: "username",
                    sortable: true,
                    thStyle: "width: 50rem",
                },
                { class: "sa-table-column-action-single", key: "actions", label: "" },
            ],
            items: [],
            filtro: {},
        };
    },
    mounted() {
        let me = this;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.showmolalloading = true;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data;
                    me.showmolalloading = false;
                })
                .catch(function(error) {
                    me.showmolalloading = false;
                    me.$bvToast.toast(error.response.data.messaggio, {
                        title: "Sorveglianza Covid Admin",
                        variant: "danger",
                        solid: true,
                    });
                });
        },
        
        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        onReset() {
            let me = this;
            me.filtro = {};
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },

        onRefresh() {
            let me = this;
            me.loadData();
        },
    },
};
</script>
