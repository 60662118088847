import Home from "../Home.vue";
import HomeStudioMedico from "../HomeStudioMedico.vue";

const routes = [
    { path: "/", redirect: { name: "Home" } },
    { name: "Home", path: "/home", component: Home, meta: { title: "home.lblTitle" } },
    { name: "Home Studio Medico", path: "/homestudiomedico", component: HomeStudioMedico, meta: { title: "home.lblTitle" } },
];

export default routes;
