import Cardiologia from "../";

const routes = [
  { name: "AnamnesiList", path: "/paziente/cardiologia/anamnesi/", component: Cardiologia.CardioAnamnesiList, meta: { title: "Anamnesi Cardiologia" } },
  { name: "AnamnesiView", path: "/paziente/cardiologia/anamnesi/view/:id", component: Cardiologia.CardioAnamnesiView, meta: { title: "Anamnesi Cardiologia" } },
  { name: "AnamnesiEdit", path: "/paziente/cardiologia/anamnesi/edit/:id", component: Cardiologia.CardioAnamnesiEdit, meta: { title: "Anamnesi Cardiologia" } },

  { name: "RefertazioneList", path: "/paziente/cardiologia/refertazione/", component: Cardiologia.CardioRefertazioneList, meta: { title: "Refertazione Cardiologia" } },
  { name: "RefertazioneView", path: "/paziente/cardiologia/refertazione/view/:id", component: Cardiologia.CardioRefertazioneView, meta: { title: "Refertazione Cardiologia" } },
  { name: "RefertazioneEdit", path: "/paziente/cardiologia/refertazione/edit/:id", component: Cardiologia.CardioRefertazioneEdit, meta: { title: "Refertazione Cardiologia" } },

  { name: "EcostressList", path: "/paziente/cardiologia/ecostress/", component: Cardiologia.CardioEcostressList, meta: { title: "Ecostress" } },
  { name: "EcostressView", path: "/paziente/cardiologia/ecostress/view/:id", component: Cardiologia.CardioEcostressView, meta: { title: "Ecostress" } },
  { name: "EcostressEdit", path: "/paziente/cardiologia/ecostress/edit/:id", component: Cardiologia.CardioEcostressEdit, meta: { title: "Ecostress" } },

  { name: "EcodopplerArtiInferioriList", path: "/paziente/cardiologia/dopplerartiinferiori/", component: Cardiologia.CardioEcodopplerArtiInferioriList, meta: { title: "Ecodoppler Arti Inferiori" } },
  { name: "EcodopplerArtiInferioriView", path: "/paziente/cardiologia/dopplerartiinferiori/view/:id", component: Cardiologia.CardioEcodopplerArtiInferioriView, meta: { title: "Ecodoppler Arti Inferiori" } },
  { name: "EcodopplerArtiInferioriEdit", path: "/paziente/cardiologia/dopplerartiinferiori/edit/:id", component: Cardiologia.CardioEcodopplerArtiInferioriEdit, meta: { title: "Ecodoppler Arti Inferiori" } },

  { name: "VisitaControlloEcgList", path: "/paziente/cardiologia/visitacontrolloecg/", component: Cardiologia.CardioVisitaControlloEcgList, meta: { title: "Visita Controllo Ecg" } },
  { name: "VisitaControlloEcgView", path: "/paziente/cardiologia/visitacontrolloecg/view/:id", component: Cardiologia.CardioVisitaControlloEcgView, meta: { title: "Visita Controllo Ecg" } },
  { name: "VisitaControlloEcgEdit", path: "/paziente/cardiologia/visitacontrolloecg/edit/:id", component: Cardiologia.CardioVisitaControlloEcgEdit, meta: { title: "Visita Controllo Ecg" } },

  { name: "EcocarotidiList", path: "/paziente/cardiologia/ecocarotidi/", component: Cardiologia.CardioEcocarotidiList, meta: { title: "Ecocarotidi" } },
  { name: "EcocarotidiView", path: "/paziente/cardiologia/ecocarotidi/view/:id", component: Cardiologia.CardioEcocarotidiView, meta: { title: "Ecocarotidi" } },
  { name: "EcocarotidiEdit", path: "/paziente/cardiologia/ecocarotidi/edit/:id", component: Cardiologia.CardioEcocarotidiEdit, meta: { title: "Ecocarotidi" } },

  { name: "ElettrostimolatoreList", path: "/paziente/cardiologia/elettrostimolatore/", component: Cardiologia.CardioElettrostimolatoreList, meta: { title: "Elettrostimolatore" } },
  { name: "ElettrostimolatoreView", path: "/paziente/cardiologia/elettrostimolatore/view/:id", component: Cardiologia.CardioElettrostimolatoreView, meta: { title: "Elettrostimolatore" } },
  { name: "ElettrostimolatoreEdit", path: "/paziente/cardiologia/elettrostimolatore/edit/:id", component: Cardiologia.CardioElettrostimolatoreEdit, meta: { title: "Elettrostimolatore" } },
];
export default routes;
