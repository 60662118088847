<template>
  <div>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblCV") }}</span>
            <b-form-input type="number" v-model="data.cv" step="0.01"></b-form-input><br />
            <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblCVF") }}</span>
            <b-form-input type="number" v-model="data.cvf" step="0.01"></b-form-input><br />
            <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblVEMS") }}</span>
            <b-form-input type="number" v-model="data.vems" step="0.01"></b-form-input><br />
            <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblVemsCvf") }}</span>
            <b-form-input type="number" v-model="data.vemsCvf" step="0.01"></b-form-input><br />
            <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblMVV") }}</span>
            <b-form-input type="number" v-model="data.mvv" step="0.01"></b-form-input><br />
          </b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblCVNormal") }}</span>
            <b-form-input type="number" v-model="data.normaleCv" step="0.01"></b-form-input><br />
            <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblCVFNormal") }}</span>
            <b-form-input type="number" v-model="data.normaleCvf" step="0.01"></b-form-input><br />
            <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblVEMSNormal") }}</span>
            <b-form-input type="number" v-model="data.normaleVems" step="0.01"></b-form-input><br />
            <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblVemsCvfNormal") }}</span>
            <b-form-input type="number" v-model="data.normaleIndiceTiffeneau" step="0.01"></b-form-input><br />

            <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblMVVNormal") }}</span>
            <b-form-input type="number" v-model="data.normaleMvv" step="0.01"></b-form-input><br />
          </b-col>
        </b-row>
        <hr />
      </b-col>
    </b-row>
    <br />
    <h4>{{ getLabelTraduora("pazienti.egds.lblConclusions") }}</h4>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-form-textarea v-model="data.conclusioni" type="text" no-resize></b-form-textarea>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" style="height:100px">
            <strong>{{ getLabelTraduora("therapy.lblStatus") }} </strong><br />
            {{ data.firma }}
          </b-col>
        </b-row>
        <hr />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";
export default {
  mixins: [UtilityMixin],
  props: ["data"],
};
</script>
