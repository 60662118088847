<template>
  <sa-page-layout :data="jsonData" :btnEditVisible="true" :btnBackVisible="true" :linkback="linkback" :pathResource="pathResource" :linkedit="linkedit">
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col xs="4" sm="4" md="4" lg="4" xl="4">
            <span>C.F Medico: </span><br />
            {{ jsonData.codiceFiscaleMedicoTitolare }}
          </b-col>
          <b-col xs="4" sm="4" md="4" lg="4" xl="4">
            <span>C.F Assistito: </span><br />
            {{ jsonData.codiceFiscaleAssistito }}
          </b-col>
          <b-col xs="4" sm="4" md="4" lg="4" xl="4">
            <span>Data Esecuzione</span><br />
            {{ formatDate(jsonData.dataEsecuzione) }}
          </b-col>
        </b-row>
        <br />
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col xs="4" sm="4" md="4" lg="4" xl="4">
            <span>Risultato:</span>
            {{ jsonData.risultato }}
          </b-col>
          <b-col xs="4" sm="4" md="4" lg="4" xl="4">
            <span>Telefono: </span><br />
            {{ jsonData.telefono }}
          </b-col>
        </b-row>
        <br />
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col xs="6" sm="6" md="6" lg="6" xl="6">
            <span>Sintomatologia:</span> <br />
            {{ jsonData.sintomatologia }}
          </b-col>
          <b-col xs="6" sm="6" md="6" lg="6" xl="6">
            <span>Data inzio Sintomi:</span> <br />
            {{ formatDate(jsonData.dataInizioSintomi) }}
          </b-col>
        </b-row>
        <br />
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col xs="2" sm="2" md="2" lg="2" xl="2">
            <span>Gravidanza:</span> <br />
            {{ jsonData.soggettoGravidanza }}
          </b-col>
          <b-col xs="10" sm="10" md="10" lg="10" xl="10">
            <span>Data Presumibile Gravidanza:</span> <br />
            {{ formatDate(jsonData.dataPresumibileParto) }}
          </b-col>
        </b-row>
        <br />
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col xs="4" sm="4" md="4" lg="4" xl="4">
            <span>Operatore:</span>
            {{ jsonData.soggettoOperatore }}
          </b-col>
          <b-col xs="4" sm="4" md="4" lg="4" xl="4">
            <span>Asl Operatore:</span>
            {{ jsonData.aslOperatore }}
          </b-col>
          <b-col xs="4" sm="4" md="4" lg="4" xl="4">
            <span>CampagnaScreening:</span>
            {{ jsonData.campagnaScreening }}
          </b-col>
        </b-row>
        <br />
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col xs="4" sm="4" md="4" lg="4" xl="4">
            <span>Soggetto:</span>
            {{ jsonData.soggetto }}
          </b-col>
          <b-col xs="4" sm="4" md="4" lg="4" xl="4">
            <span>Istituto:</span>
            {{ jsonData.istituto }}
          </b-col>
          <b-col xs="4" sm="4" md="4" lg="4" xl="4">
            <span>Nome Struttura:</span>
            {{ jsonData.nomeStruttura }}
          </b-col>
        </b-row>
        <br />
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col xs="4" sm="4" md="4" lg="4" xl="4">
            <span>Generazione Antigenico:</span>
            {{ jsonData.generazioneAntigenico }}
          </b-col>
          <b-col xs="4" sm="4" md="4" lg="4" xl="4">
            <span>Metodi Cautilizzata:</span>
            {{ jsonData.metodiCautilizzata }}
          </b-col>
          <b-col xs="4" sm="4" md="4" lg="4" xl="4">
            <span>Ora Effettuazione:</span>
            {{ formatOra(jsonData.oraEffettuazione) }}
          </b-col>
        </b-row>
        <br />
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col xs="4" sm="4" md="4" lg="4" xl="4">
            <span>Tipologia Test:</span>
            {{ jsonData.tipologiaTest }}
          </b-col>
          <b-col xs="4" sm="4" md="4" lg="4" xl="4">
            <span>Esente Covid:</span>
            {{ jsonData.esenteCovid }}
          </b-col>
        </b-row>
        <br />
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col xs="6" sm="6" md="6" lg="6" xl="6">
            <span>Data Esitazione Antigenico:</span>
            {{ formatDate(jsonData.dataEsitazioneAntigenico) }}
          </b-col>
          <b-col xs="6" sm="6" md="6" lg="6" xl="6">
            <span>Ora Esitazione Antigenico:</span>
            {{ formatOra(jsonData.oraEsitazioneAntigenico) }}
          </b-col>
        </b-row>
      </div>
    </template>
  </sa-page-layout>
</template>

<script>
import moment from "moment";
import UtilityMixin from "../../utility/UtilityMixin";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import axios from "axios";

export default {
  components: { SaPageLayout },
  mixins: [UtilityMixin],
  data() {
    return {
      id: "-1",
      pathResource: "/gestioneantigenici",
      linkedit: null,
      linkback: null,
      jsonData: {},
    };
  },
  mounted: function() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
    me.linkedit = "/gestionetamponiantigenici/edit/" + me.id;
    me.linkback = "/gestionetamponiantigenici?" + me.id;
  },
  methods: {
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        me.showModalLoading = true;
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
        console.log(link);
        axios.get(link + me.id).then((response) => {
          me.jsonData = response.data.data;
          console.log(response.data.data);
        });
      }
    },

    formatDate(value) {
      if (value) {
        return moment(new Date(value)).format("YYYY/MM/GG");
      } else {
        return "---";
      }
    },
    formatOra(value) {
      if (value) {
        return moment(new Date(value)).format("HH:mm");
      } else {
        return "---";
      }
    },
  },
};
</script>
<style>
.sa-text-right {
  text-align: right;
}
@media only screen and (max-width: 767px) {
  .sa-text-right {
    text-align: left;
  }
}
.card {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  margin-bottom: 1rem;
}
</style>
