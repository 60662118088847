<template>
    <div>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                        <strong>Struttura</strong><br />
                        {{ jsonData.accettazioneStruttura.denominazione }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                        <strong>Data Prenotazione</strong><br />
                        {{ formatDate(jsonData.dataPrenotazione) }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                        <strong>Numero Cartella</strong><br />
                        {{ jsonData.codiceCartella }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                        <strong>Numero Certificato</strong><br />
                        {{ jsonData.numeroCertificato }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                        <strong>Cognome</strong><br />
                        {{ jsonData.accettazioneAnagrafica.cognome }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                        <strong>Nome</strong><br />
                        {{ jsonData.accettazioneAnagrafica.nome }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                        <strong>Data di Nascita</strong><br />
                        {{ formatDate(jsonData.accettazioneAnagrafica.dataNascita) }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                        <strong>Sesso</strong><br />
                        {{ jsonData.accettazioneAnagrafica.sesso }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <strong>Codice Fiscale</strong><br />
                        {{ jsonData.accettazioneAnagrafica.codiceFiscale }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <strong>Provincia</strong><br />
                        {{ jsonData.accettazioneAnagrafica.provinciaNascita }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <strong>Comune</strong><br />
                        {{ jsonData.comuneNascita }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                        <strong>Numero Tessera Sanitaria</strong><br />
                        {{ jsonData.numeroTesseraSanitaria }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                        <strong>Documento D'Identità</strong><br />
                        {{ jsonData.documentoIdentita }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                        <strong>Comune</strong><br />
                        {{ jsonData.numeroDocumento }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                        <strong>Comune</strong><br />
                        {{ jsonData.gruppoSanguigno }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <strong>Federazione/Ente</strong><br />
                        {{ jsonData.federazioneEnte }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <strong>Società Sportiva</strong><br />
                        {{ jsonData.societaSportiva }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                        <strong>Indirizzo Residenza</strong><br />
                        {{ jsonData.accettazioneAnagrafica.indirizzoResidenza }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                        <strong>Provincia Residenza</strong><br />
                        {{ jsonData.accettazioneAnagrafica.provinciaResidenza }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                        <strong>Comune Residenza</strong><br />
                        {{ jsonData.comuneResidenza }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                        <strong>Cap Residenza</strong><br />
                        {{ jsonData.accettazioneAnagrafica.capResidenza }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <strong>Telefono</strong><br />
                        {{ jsonData.accettazioneAnagrafica.telefono }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <strong>Email</strong><br />
                        {{ jsonData.accettazioneAnagrafica.email }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <strong>Medico Esecutore</strong><br />
                        {{ jsonData.nomeMedicoEsecutore }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <strong>Note</strong><br />
                        {{ jsonData.accettazioneAnagrafica.note }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
    </div>
</template>
<script>
import moment from "moment";
export default {
    props: {
        jsonData: {
            Type: Object,
            default: function() {
                return {
                    accettazioneStruttura: { codiceStruttura: null },
                    accettazioneAnagrafica: {
                        codiceFiscale: "",
                        provinciaNascita: null,
                        comuneNascitaCodiceIstat: null,
                        medicoCurante: "",
                        codiceFiscaleMedicoCurante: "",
                        provinciaResidenza: null,
                        comuneResidenzaCodiceIstat: null,
                        provinciaDomicilio: null,
                        comuneDomicilioCodiceIstat: null,
                        capDomicilio: null,
                        capResidenza: null,
                        dataNascita: null,
                        sesso: null,
                        tipoIdentificativo: null,
                        nome: "",
                        cognome: "",
                    },
                    idPrenotazione: "",
                    tipo: "",
                };
            },
        },
    },
    methods: {
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY");
            } else {
                return "---";
            }
        },
    },
};
</script>
