<template>
  <div class="sa-page">
    <div class="content-header">
      <b-row>
        <b-col>
          <div style="float: left; margin-top: 0rem; width: 100%;">
            <span style="margin-left: 0.5rem; float: left;"> </span>
            <slot name="toolbar-title"> </slot>
          </div>
        </b-col>
        <b-col class="sa-col-toolbar">
          <div style="float: right">
            <b-button variant="success btn-toolbar float-sm-right sa-margin-right" size="sm" @click="onSave"><b-icon icon="check2"></b-icon>Salva</b-button>
            <b-button variant="danger btn-toolbar sa-margin-right float-sm-right" size="sm" @click="onBack"><b-icon icon="x"></b-icon>Annulla</b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-card>
      <div class="content">
        <div class="sa-table">
          <div class="sa-table-header"><slot name="table-header"> </slot></div>
          <div class="sa-table-body">
            <slot v-if="this.id !== '-1'" name="table-body">
              <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                  <span>Kit</span>
                  <b-form-input v-model="jsonData.kitId" id="inline-form-custom-select-pref" v-if="this.id !== '-1'" readonly></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                  <span>Hub</span>
                  <b-form-select v-model="jsonData.hubUUID" id="inline-form-custom-select-pref" :options="hubsOptions" value-field="hubUUID" text-field="hubName" :value="null"></b-form-select>
                </b-col>
                <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                  <span>Dispositivi Temperature</span>
                  <b-form-select v-model="jsonData.temperatureMAC" id="inline-form-custom-select-pref" :options="temperatureOptions" value-field="deviceMAC" text-field="deviceName" :value="null"></b-form-select>
                </b-col>
                <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                  <span>Dispositivi Alphamed</span>
                  <b-form-select v-model="jsonData.spo2MAC" id="inline-form-custom-select-pref" :options="spo2Options" value-field="deviceMAC" text-field="deviceName" :value="null"></b-form-select>
                </b-col>
              </b-row>

              <b-row>
                <b-col xs="6" sm="6" md="6" lg="6" xl="6">
                  <span>Dispositivi Glicemici</span>
                  <b-form-select v-model="jsonData.glucoseMAC" id="inline-form-custom-select-pref" value-field="deviceMAC" text-field="deviceName" :options="glucoseOptions" :value="null"></b-form-select>
                </b-col>
                <b-col xs="6" sm="6" md="6" lg="6" xl="6">
                  <span>Bracciale</span>
                  <b-form-select v-model="jsonData.braceletMAC" id="inline-form-custom-select-pref" value-field="deviceMAC" text-field="deviceName" :options="braceletOptions" :value="null"></b-form-select>
                </b-col>
              </b-row>

              <b-row>
                <b-col xs="6" sm="6" md="6" lg="6" xl="6">
                  <span>Pulse</span>
                  <b-form-select v-model="jsonData.ecgMAC" id="inline-form-custom-select-pref" value-field="deviceMAC" text-field="deviceName" :options="ecgOptions" :value="null"></b-form-select>
                </b-col>
                <b-col xs="6" sm="6" md="6" lg="6" xl="6">
                  <span>Pressure</span>
                  <b-form-select v-model="jsonData.pressureMAC" id="inline-form-custom-select-pref" value-field="deviceMAC" text-field="deviceName" :options="pressureOptions" :value="null"></b-form-select>
                </b-col>
              </b-row>
              <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                  <span>Url</span>
                  <b-form-input v-model="jsonData.url" id="inline-form-custom-select-pref" value-field="deviceMAC" text-field="deviceName" :options="ecgOptions" :value="null"></b-form-input>
                </b-col>
              </b-row>
            </slot>

            <slot name="table-body" v-else>
              <b-row>
                <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                  <span>Nome</span>
                  <b-form-input v-model="jsonData.kitId" id="inline-form-custom-select-pref" :placeholder="getTitle('patient.configuration.kitName')"> </b-form-input>
                </b-col>
                <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                  <span>userPermission</span>
                  <!-- <b-form-input v-model="jsonData.userPermission" id="inline-form-custom-select-pref"> </b-form-input> -->
                  <b-form-select v-model="jsonData.userPermission" :options="usernameOptions" text-field="text" value-field="value" required></b-form-select>
                </b-col>
              </b-row>
            </slot>
          </div>
          <!--<div class="sa-table-footer" style="height: 30px"><slot name="table-footer"></slot></div>-->
          <div class="sa-table-footer"><slot name="table-footer"></slot></div>
        </div>
      </div>
    </b-card>
    <sa-modal-loading ref="sa-modal-loading" />
  </div>
</template>
<script>
import axios from "axios";
import { SaModalLoading } from "../../../components/modal";
export default {
  components: { SaModalLoading },
  computed: {},
  mounted: function() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
    // console.log(me.id);
    if (me.id !== "-1") {
      me.loadOptions();
      me.loadHubs();
    }
    // me.loadKits();
  },

  data() {
    return {
      usernameOptions: [{ text: "Gesan", value: "gesan" }],
      pathResource: "/hassistokits",
      linkback: "/hassistokit",
      pathAllDevice: "/hassistodevices/alldevices",
      pathHubs: "/hassistohubs",
      id: "-1",

      // kitOptions: [{ value: null, text: "--- Seleziona un Kit da Associare ---" }],

      temperatureOptions: [{ deviceMAC: "N/A", deviceName: "-Seleziona Dispositivo-" }],
      spo2Options: [{ deviceMAC: "N/A", deviceName: "-Seleziona Dispositivo-" }],
      ecgOptions: [{ deviceMAC: "N/A", deviceName: "-Seleziona Dispositivo-" }],
      glucoseOptions: [{ deviceMAC: "N/A", deviceName: "-Seleziona Dispositivo-" }],
      braceletOptions: [{ deviceMAC: "N/A", deviceName: "-Seleziona Dispositivo-" }],
      pressureOptions: [{ deviceMAC: "N/A", deviceName: "-Seleziona Dispositivo-" }],
      hubsOptions: [{ hubUUID: "N/A", hubName: "-Seleziona Box-" }],

      jsonData: { userPermission: "gesan" },
      // jsonData: { kitId: null, patientId: null, friendlyName: null, hubMAC: null, hubName: null, hubUUID: null, temperatureMAC: null, ecgMAC: null, glucoseMAC: null, spo2MAC: null, pressureMAC: null, braceletMAC: null },
    };
  },

  methods: {
    onBack() {
      let me = this;
      me.$router.replace(me.linkback).catch((err) => {
        err;
      });
    },
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
        axios
          .get(link + me.id)
          .then((response) => {
            me.jsonData = response.data.data;
            if (me.id != "-1" && me.id != null) {
              let obj = { hubUUID: response.data.data.hubUUID, hubName: response.data.data.hubName };
              obj.hubName = obj.hubName + "(" + obj.hubUUID + ")";
              me.hubsOptions.push(obj);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },

    loadOptions() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathAllDevice;
      axios
        .get(link)
        .then((response) => {
          response.data.data.forEach((element) => {
            if (element.kitId === this.id) {
              switch (element.deviceType) {
                case "t1":
                  element.deviceName = element.deviceName + "(" + element.deviceMAC + ")";
                  me.temperatureOptions.push(element);
                  break;
                case "p1":
                  element.deviceName = element.deviceName + "(" + element.deviceMAC + ")";
                  me.spo2Options.push(element);
                  break;
                case "s1":
                  element.deviceName = element.deviceName + "(" + element.deviceMAC + ")";
                  me.pressureOptions.push(element);
                  break;
                case "b1":
                  element.deviceName = element.deviceName + "(" + element.deviceMAC + ")";
                  me.braceletOptions.push(element);
                  break;
                case "e1" || "e2":
                  element.deviceName = element.deviceName + "(" + element.deviceMAC + ")";
                  me.ecgOptions.push(element);
                  break;
                case "g1":
                  element.deviceName = element.deviceName + "(" + element.deviceMAC + ")";
                  me.glucoseOptions.push(element);
                  break;
              }
            } else if (element.kitId === null) {
              switch (element.deviceType) {
                case "t1":
                  element.deviceName = element.deviceName + "(" + element.deviceMAC + ")";
                  me.temperatureOptions.push(element);
                  break;
                case "p1":
                  element.deviceName = element.deviceName + "(" + element.deviceMAC + ")";
                  me.spo2Options.push(element);
                  break;
                case "s1":
                  element.deviceName = element.deviceName + "(" + element.deviceMAC + ")";
                  me.pressureOptions.push(element);
                  break;
                case "b1":
                  element.deviceName = element.deviceName + "(" + element.deviceMAC + ")";
                  me.braceletOptions.push(element);
                  break;
                case "e1" || "e2":
                  element.deviceName = element.deviceName + "(" + element.deviceMAC + ")";
                  me.ecgOptions.push(element);
                  break;
                case "g1":
                  element.deviceName = element.deviceName + "(" + element.deviceMAC + ")";
                  me.glucoseOptions.push(element);
                  break;
              }
            }
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getTitle(value) {
      return this.$i18n.t(value);
    },
    onSave() {
      let me = this;
      if (me.id === "-1") {
        me.showModalLoading = false;
        let link = process.env.VUE_APP_PATH_API + me.pathResource;
        // console.log(link);
        // console.log(jsonData);
        me.jsonData.kitName = me.jsonData.kitId;

        let jsonData = JSON.stringify(this.jsonData);
        axios
          .post(link, jsonData)
          .then((response) => {
            console.log(response);
            me.jsonData = response.data.data;
            me.showModalLoading = false;
            me.$router.replace(me.linkback).catch((err) => {
              err;
            });
          })
          .catch((error) => {
            this.$bvModal
              .msgBoxOk(error.response.data.messaggio, {
                title: "ATTENZIONE",
                size: "sm",
                okVariant: "outline-danger",
                headerClass: "sa-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then((value) => {
                this.boxTwo = value;
              })
              .catch((err) => {
                console.log(err);
              });
          });
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
        console.log(link);
        let jsonData = JSON.stringify(this.jsonData);
        axios
          .put(link, jsonData)
          .then((response) => {
            console.log(response);
            me.jsonData = response.data.data;
            me.showModalLoading = false;
            me.$router.replace(me.linkback).catch((err) => {
              err;
            });
          })
          .catch((error) => {
            me.showmolalloading = false;
            this.$bvModal
              .msgBoxOk(error.response.data.messaggio, {
                title: "ATTENZIONE",
                size: "sm",
                okVariant: "outline-danger",
                headerClass: "sa-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then((value) => {
                this.boxTwo = value;
              })
              .catch((err) => {
                console.log(err);
              });
          });
      }
    },
    loadHubs() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathHubs;
      axios
        .get(link)
        .then((response) => {
          response.data.data.forEach((element) => {
            element.hubName = element.hubName + "(" + element.hubUUID + ")";
            me.hubsOptions.push(element);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // loadKits() {
    //   let me = this;
    //   let link = process.env.VUE_APP_PATH_API + me.pathRestKit;
    //   axios.get(link).then((response) => {
    //     console.log(response.data.data);
    //     response.data.data.forEach((element) => {
    //       me.jsonData = element;
    //       console.log(element);
    //     });
    //   });
    // },
  },
};
</script>
