<template>
  <div>
    <b-card-header header-bg-variant="light" border-variant="light">
      <b-row
        ><b-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
          <strong> {{ domanda.domanda }}</strong>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
          <b-button class="float-sm-right " variant="success btn-toolbar sa-margin-right" size="sm" @click="apriModal(domanda.idDomanda)"
            ><b-icon icon="play"></b-icon>{{ this.$i18n.t("paziente.questionari.lblBtnAnswerQuestion") }}</b-button
          ></b-col
        ></b-row
      ><strong></strong
    ></b-card-header>
    <b-card-body border-variant="light">
      <b-row>
        <b-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4" v-for="(risposta, index) in domanda.rispostePaziente" :key="risposta.id">
          <div v-if="risposta.tipo === '' && index < 1">
            <br />
            <div style="color:green; font-weight: bold">{{ risposta.valore }}</div>
          </div>
          <div v-if="risposta.tipo !== ''">
            <br />
            <div style="color:green; font-weight: bold" v-if="risposta.tipo !== 'date'">{{ risposta.valore }}</div>
            <div style="color:green; font-weight: bold" v-if="risposta.tipo === 'date'">{{ formatDate(risposta.valore) }}</div>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
    <hr />
    <b-modal :id="domanda.idDomanda" scrollable size="xl">
      <paziente-compilazione-questionari-edit-answer :domanda="domandaModal" :id-paziente-questionario="idEvento" :risposte="risposte"></paziente-compilazione-questionari-edit-answer>

      <template #modal-footer="{ salvaAnswer }">
        <b-button size="sm" variant="success" @click="salvaRisposte()" :id="salvaAnswer">
          {{ traduci("global.lblBtnSave") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import moment from "moment";
import axios from "axios";
import PazienteCompilazioneQuestionariEditAnswer from "./PazienteCompilazioneQuestionariEditAnswer.vue";

export default {
  props: {
    domanda: {
      Type: Object,
      require: true,
    },
  },
  components: { PazienteCompilazioneQuestionariEditAnswer },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    idEvento() {
      return this.$route.params.id;
    },
  },
  data() {
    return {
      jsonData: [],
      pathResource: "/questionari",
      currentPage: 1,
      perPage: 10,
      risposte: [],
      domandaModal: null,
      fields: [
        {
          label: "N°",
          key: "idQuestionario",
          thStyle: "width: 8rem",
          sortable: true,
        },
        {
          label: "Tipo Domanda",
          key: "tipo",
          thStyle: "width: 5rem",
          sortable: true,
        },
      ],
    };
  },
  created: function() {
    let me = this;
    me.loadData();
  },
  mounted() {},
  methods: {
    loadData() {
      let me = this;
      console.log(me.domanda);
      me.showmolalloading = true;
      //   let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      //   axios.get(link + me.idEvento).then((response) => {
      //     me.jsonData = response.data.data;
      //   });
    },
    formatDate(value) {
      if (value) {
        return moment(new Date(value)).format("DD-MM-YYYY");
      } else {
        return "---";
      }
    },
    salvaRisposte: function() {
      let me = this;
      me.showModalLoading = true;
      let linkRisposte = process.env.VUE_APP_PATH_API + "/pazientequestionariodomandarisposta";

      axios
        .post(linkRisposte, me.risposte)
        .then((res) => {
          console.log(res);
          alert(me.traduci("paziente.questionario.lblSuccess"));
        })
        .catch((e) => alert(e));
      let event = new CustomEvent("loadData");
      document.dispatchEvent(event);
      this.$bvModal.hide("avviaQuestionario");

      me.showModalLoading = false;
    },
    traduci(label) {
      return this.$i18n.t(label);
    },
    apriModal(id) {
      let link = process.env.VUE_APP_PATH_API + "/questionaridomande/" + id;
      axios
        .get(link)
        .then((res) => {
          this.domandaModal = res.data.data;
        })
        .catch((e) => {
          alert(e.messaggio);
        });
      this.$bvModal.show(id);
    },
  },
};
</script>
