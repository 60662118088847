<template>
  <div>
    <b-row>
      <b-col cols="12" xs="5" sm="5" md="5" lg="5" xl="5"
        ><span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblDateVisit") }}</span>
        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.dataAppuntamento" :disabled="!edit" type="datetime"></date-picker></b-col
      ><b-col cols="12" xs="2" sm="2" md="2" lg="2" xl="2"> </b-col
      ><b-col cols="12" xs="5" sm="5" md="5" lg="5" xl="5">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblSportPracticed") }}</span>
        <b-form-input v-model="data.sportPraticato" :disabled="!edit"></b-form-input></b-col
      ><b-col cols="12" xs="5" sm="5" md="5" lg="5" xl="5">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblFollowingVisit") }}</span>
        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.visitaSuccessiva" :disabled="!edit" type="datetime"></date-picker></b-col
      ><b-col cols="12" xs="2" sm="2" md="2" lg="2" xl="2"> </b-col
      ><b-col cols="12" xs="5" sm="5" md="5" lg="5" xl="5">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblYearsNumber") }}</span>
        <b-form-input v-model="data.numeroAnni" :disabled="!edit"></b-form-input></b-col
      ><b-col cols="12" xs="5" sm="5" md="5" lg="5" xl="5">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblSpeciality") }}</span>
        <b-form-input v-model="data.specialita" :disabled="!edit"></b-form-input></b-col
      ><b-col cols="12" xs="2" sm="2" md="2" lg="2" xl="2"> </b-col
      ><b-col cols="12" xs="5" sm="5" md="5" lg="5" xl="5">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblFederation") }}</span>
        <b-form-input v-model="data.federazione" :disabled="!edit"></b-form-input></b-col
      ><b-col cols="12" xs="5" sm="5" md="5" lg="5" xl="5">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblOtherSports") }}</span>
        <b-form-input v-model="data.altriSport" :disabled="!edit"></b-form-input></b-col
      ><b-col cols="12" xs="2" sm="2" md="2" lg="2" xl="2"> </b-col
      ><b-col cols="12" xs="5" sm="5" md="5" lg="5" xl="5"
        ><span class="sa-form-label">{{ getLabelTraduora("patients.lblFirstExamination") }}</span>
        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.primaVisita" :disabled="!edit" type="datetime"></date-picker></b-col
      ><b-col cols="12" xs="5" sm="5" md="5" lg="5" xl="5">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblAntitetanus") }}</span>
        <b-form-select v-model="data.antitetanica" :disabled="!edit" :options="opzioniYesNo"></b-form-select></b-col
      ><b-col cols="12" xs="2" sm="2" md="2" lg="2" xl="2"> </b-col
      ><b-col cols="12" xs="5" sm="5" md="5" lg="5" xl="5">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblHourVisit") }}</span>
        <b-form-timepicker v-model="data.oraAppuntamento" :disabled="!edit"></b-form-timepicker></b-col
      ><b-col cols="12" xs="5" sm="5" md="5" lg="5" xl="5">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblWeeklyWorkouts") }}</span
        ><b-form-input v-model="data.allenamentiSettimana" :disabled="!edit"></b-form-input
      ></b-col>
      <b-col cols="12" xs="2" sm="2" md="2" lg="2" xl="2"> </b-col
      ><b-col cols="12" xs="5" sm="5" md="5" lg="5" xl="5">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblHoursWorkout") }}</span>
        <b-form-input v-model="data.oreAllenamento" :disabled="!edit"></b-form-input></b-col
      ><b-col cols="12" xs="5" sm="5" md="5" lg="5" xl="5">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblCorporation") }}</span>
        <b-form-input v-model="data.societa" :disabled="!edit"></b-form-input></b-col
      ><b-col cols="12" xs="2" sm="2" md="2" lg="2" xl="2"> </b-col
      ><b-col cols="12" xs="5" sm="5" md="5" lg="5" xl="5">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblLHB") }}</span>
        <b-form-input v-model="data.aslNew" :disabled="!edit"></b-form-input></b-col
      ><b-col cols="12" xs="5" sm="5" md="5" lg="5" xl="5">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblMenarche") }}</span>
        <b-form-input v-model="data.menarca" :disabled="!edit"></b-form-input></b-col
      ><b-col cols="12" xs="2" sm="2" md="2" lg="2" xl="2"> </b-col
      ><b-col cols="12" xs="5" sm="5" md="5" lg="5" xl="5">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblSmoking") }}</span>
        <b-form-select v-model="data.fumo" :disabled="!edit" :options="opzioniFumo"></b-form-select></b-col
      ><b-col cols="12" xs="5" sm="5" md="5" lg="5" xl="5">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblAlcohol") }}</span>
        <b-form-select v-model="data.alcool" :disabled="!edit" :options="opzioniAlcool"></b-form-select></b-col
      ><b-col cols="12" xs="2" sm="2" md="2" lg="2" xl="2"> </b-col
      ><b-col cols="12" xs="5" sm="5" md="5" lg="5" xl="5">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblNarcoticSubstances") }}</span>
        <b-form-select v-model="data.sostenzeStupefacenti" :disabled="!edit" :options="opzioniYesNo"></b-form-select> </b-col
      ><b-col cols="12" xs="5" sm="5" md="5" lg="5" xl="5">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblCoffee") }}</span>
        <b-form-select v-model="data.caffe" :disabled="!edit" :options="opzioniCaffe"></b-form-select></b-col
      ><b-col cols="12" xs="2" sm="2" md="2" lg="2" xl="2"> </b-col
      ><b-col cols="12" xs="5" sm="5" md="5" lg="5" xl="5">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblMilitary") }}</span>
        <b-form-select v-model="data.militare" :disabled="!edit" :options="opzioniYesNo"></b-form-select></b-col
      ><b-col cols="12" xs="5" sm="5" md="5" lg="5" xl="5">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblMilitaryMotif") }}</span>
        <b-form-input v-model="data.militareMotivo" :disabled="!edit"></b-form-input>
      </b-col>
    </b-row>
    <div style="height: 30px;"></div>
    <b-row>
      <b-col
        ><span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblIdentityDocument") }}</span>
        <b-form-select v-model="data.documentoIdentitaTipo" :disabled="!edit" :options="opzioniCartaID"></b-form-select>
      </b-col>
      <b-col>
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblIdentityDocumentNumber") }}</span> <b-form-input v-model="data.documentoIdentitaNumero" :disabled="!edit"></b-form-input>
      </b-col>
      <b-col>
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblIdentityDocumentReleaser") }}</span
        ><b-form-input v-model="data.documentoIdentitaRilasciatoDa" :disabled="!edit"></b-form-input>
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblIdentityDocumentDateRelease") }}</span>
        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.documentoIdentitaRilasciatoIl" :disabled="!edit" type="datetime"></date-picker>
      </b-col>
    </b-row>
    <b-form-group> </b-form-group>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import UtilityMixin from "../../../../../utility/UtilityMixin";
export default {
  mixins: [UtilityMixin],
  props: ["data", "edit"],
  components: { DatePicker },
  data() {
    return {
      opzioniYesNo: [
        { text: "patient.lblNo", value: 0 },
        { text: "patient.lblYes", value: 1 },
      ],
      opzioniCartaID: [
        { text: "patient.afa.lblIdentityCard", value: "CI" },
        { text: "patient.afa.lblPassport", value: "PASS" },
      ],
      opzioniAlcool: [
        { text: "patient.afa.lblAbstinent", value: 0 },
        { text: "patient.afa.lblLessHalfLiter", value: 1 },
        { text: "patient.afa.lblLessOneLiter", value: 2 },
        { text: "patient.afa.lblLessTwoLiter", value: 3 },
        { text: "patient.afa.lblMoreTwoLiter", value: 4 },
      ],
      opzioniFumo: [
        { text: "patient.afa.lblNotSmoker", value: 0 },
        { text: "patient.afa.lblUpTo10Cigarettes", value: 1 },
        { text: "patient.afa.lblUpTo20Cigarettes", value: 2 },
        { text: "patient.afa.lblmore20Cigarettes", value: 3 },
      ],
      opzioniCaffe: [
        { text: "patient.afa.lbl0Cups", value: 0 },
        { text: "patient.afa.lbl1Cup", value: 1 },
        { text: "patient.afa.lbl2Cups", value: 2 },
        { text: "patient.afa.lbl3Cups", value: 3 },
        { text: "patient.afa.lblMore3Cups", value: 4 },
      ],
    };
  },
};
</script>
