import Tamponi from "../";

const routes = [
    { name: "Tamponi", path: "/tamponi", component: Tamponi.TamponiList },
    { name: "TamponiEdit", path: "/tamponi/edit/:id", component: Tamponi.TamponiEdit },
    { name: "TamponiView", path: "/tamponi/view/:id", component: Tamponi.TamponiView },
    { name: "FileProtezioneCivile", path: "/fileprotezionecivile", component: Tamponi.FileProtezioneCivile },
];

export default routes;
