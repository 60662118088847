<template>
  <div>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblTrofism") }}</span>
        <b-form-input v-model="data.trofismo" :disabled="!edit"></b-form-input>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblBody") }}</span>
        <b-form-input type="number" v-model="data.corpo" :disabled="!edit"></b-form-input>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblWaist") }}</span>
        <b-form-input type="number" v-model="data.vita" :disabled="!edit"></b-form-input>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblWeight") }}</span>
        <b-form-input type="number" v-model="data.peso" :disabled="!edit"></b-form-input>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblHeight") }}</span>
        <b-form-input type="number" v-model="data.altezza" :disabled="!edit"></b-form-input>
      </b-col>
    </b-row>
    <b-row
      ><b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblBMI") }}</span>
        <b-form-input type="number" v-model="data.bmi" :disabled="!edit"></b-form-input
      ></b-col>
      <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblBSA") }}</span>
        <b-form-input type="number" v-model="data.bsa" :disabled="!edit"></b-form-input
      ></b-col>
      <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblGlycemia") }}</span> <b-form-input type="number" v-model="data.glicemia" :disabled="!edit"></b-form-input
      ></b-col>
      <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblPAMax") }}</span> <b-form-input type="number" v-model="data.paMax" :disabled="!edit"></b-form-input></b-col
    ></b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblPAMin") }}</span>
        <b-form-input type="number" v-model="data.paMin" :disabled="!edit"></b-form-input>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblCholesterol") }}</span>
        <b-form-input type="number" v-model="data.colesterolo" :disabled="!edit"></b-form-input>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblDiabetes") }}</span>
        <b-form-input v-model="data.diabete" :disabled="!edit"></b-form-input>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblBloodGroup") }}</span>
        <b-form-input v-model="data.gruppoSanguigno" :disabled="!edit"></b-form-input>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblRHFactor") }}</span>
        <b-form-input v-model="data.fattoreRh" :disabled="!edit"></b-form-input>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblCirculatorySystem") }}</span>
        <b-form-textarea rows="4" max-rows="9" v-model="data.apparatoCardiocircolatorio" :disabled="!edit"></b-form-textarea
      ></b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblRespiratorySystem") }}</span>
        <b-form-textarea rows="4" max-rows="9" v-model="data.apparatoRespiratorio" :disabled="!edit"></b-form-textarea
      ></b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblLocomotorSystem") }}</span>
        <b-form-textarea rows="4" max-rows="9" v-model="data.apparatoLocomotore" :disabled="!edit"></b-form-textarea
      ></b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblAbdomenGeneralOrgans") }}</span>
        <b-form-textarea rows="4" max-rows="9" v-model="data.addomeOrgGen" :disabled="!edit"></b-form-textarea>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblLimbs") }}</span> <b-form-textarea rows="4" max-rows="9" v-model="data.arti" :disabled="!edit"></b-form-textarea>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblNaturalSightRightEye") }}</span>
        <b-form-input type="number" v-model="data.vistaNOd" :disabled="!edit" step="0.05"></b-form-input>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblNaturalSightLeftEye") }}</span>
        <b-form-input type="number" v-model="data.vistaNOs" :disabled="!edit" step="0.05"></b-form-input>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblCurrentSightRightEye") }}</span>
        <b-form-input type="number" v-model="data.vistaCOd" :disabled="!edit" step="0.05"></b-form-input> </b-col
      ><b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblCurrentSightLeftEye") }}</span>
        <b-form-input type="number" v-model="data.vistaCOs" :disabled="!edit" step="0.05"></b-form-input
      ></b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblCromaticSense") }}</span>
        <b-form-textarea rows="4" max-rows="9" v-model="data.sensoCromatico" :disabled="!edit"></b-form-textarea>
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblHearingSense") }}</span>
        <b-form-textarea rows="4" max-rows="9" v-model="data.udito" :disabled="!edit"></b-form-textarea>
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblExaminationConclusion") }}</span>
        <b-form-textarea rows="4" max-rows="9" v-model="data.conclusioniEsObiettivo" :disabled="!edit"></b-form-textarea>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <span class="sa-form-label">{{ getLabelTraduora("therapy.lblStatus") }}</span
        ><br />
        {{ data.firmaEo }}
      </b-col>
    </b-row>
  </div>
</template>

<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";

export default {
  mixins: [UtilityMixin],
  props: ["data", "edit"],
  data() {
    return {};
  },
};
</script>
