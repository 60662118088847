<template>
  <sa-page-layout :btnSaveVisible="true" :btnAnnullaVisible="true" :linkback="linkback" :pathResource="pathResource" :data="jsonData" :showModalLoading="showModalLoading">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente" @getEtaPaziente="getEtaPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <b-row>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.diabetology.lblHeight") }}</strong
            ><span style="color: red;">*</span>
            <br />
            <b-form-input v-model="jsonData.altezza" type="number" @input="onCalcoloBmi(jsonData.altezza, jsonData.peso)" required :state="jsonData.altezza !== null ? true : false"></b-form-input>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.diabetology.lblWeight") }}</strong>
            <br />
            <b-form-input v-model="jsonData.peso" type="number" @input="onCalcoloBmi(jsonData.altezza, jsonData.peso)"></b-form-input>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <strong>B.M.I</strong>
            <br />
            <b-form-input v-model="jsonData.bmi" type="number" readonly></b-form-input>
          </b-col>
        </b-row>
        <hr />

        <b-row>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <strong> {{ getLabelTraduora("patient.diabetology.lblIdealWeight") }}</strong>
            <br />
            <b-form-input type="number" v-model="jsonData.pesoIdeale"></b-form-input>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.diabetology.lblIdealWeightAbove") }}</strong>
            <br />
            <b-form-input type="number" v-model="jsonData.pesoPesoIdealeSopra"></b-form-input>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.diabetology.lblIdealWeightBelow") }} </strong>
            <br />
            <b-form-input type="number" v-model="jsonData.pesoPesoIdealeSotto"></b-form-input>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <strong> Spo2 </strong>
            <br />
            <div>
              <b-input-group class="mb-3">
                <b-form-input v-model="jsonData.spo2"></b-form-input>
                <b-input-group-append>
                  <b-button size="sm" text="Button" variant="secondary" v-b-modal.modalSpo2><b-icon icon="search"></b-icon></b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <strong> {{ getLabelTraduora("patient.diabetology.lblSystolicPressure") }}</strong>
            <br />
            <b-input-group class="mb-3">
              <b-form-input v-model="jsonData.pressioneSistolica"></b-form-input>
              <b-input-group-append>
                <b-button size="sm" text="Button" variant="secondary" v-b-modal.modalPressione><b-icon icon="search"></b-icon></b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <strong> {{ getLabelTraduora("patient.diabetology.lblDiastolicBloodPressure") }}</strong>
            <br />
            <b-input-group class="mb-3">
              <b-form-input v-model="jsonData.pressioneDiastolica"></b-form-input>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <strong> {{ getLabelTraduora("patient.diabetology.lblHeartRate") }}</strong>
            <br />
            <b-input-group class="mb-3">
              <b-form-input v-model="jsonData.frequenzaCardiaca"></b-form-input>
              <b-input-group-append>
                <b-button size="sm" text="Button" variant="secondary" v-b-modal.modalFrequenza><b-icon icon="search"></b-icon></b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <strong> {{ getLabelTraduora("patient.diabetology.lblECG") }}</strong>
            <br />
            <b-input-group class="mb-3">
              <b-form-input v-model="jsonData.ecg"></b-form-input>
              <b-input-group-append>
                <b-button size="sm" text="Button" variant="secondary" v-b-modal.modalEcg><b-icon icon="search"></b-icon></b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.diabetology.lblGlucose") }}</strong>
            <br />
            <b-input-group class="mb-3">
              <b-form-input v-model="jsonData.glucosio"></b-form-input>
              <b-input-group-append>
                <b-button size="sm" text="Button" variant="secondary" v-b-modal.modalGlucosio><b-icon icon="search"></b-icon></b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <strong> {{ getLabelTraduora("patient.diabetology.lblSkinAndAnnexes") }}</strong>
            <br />
            <b-form-textarea v-model="jsonData.cuteEdAnnessi" no-resize></b-form-textarea>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.diabetology.lblRespiratorySystem") }}</strong>
            <br />
            <b-form-textarea v-model="jsonData.apparatoRespiratorio" no-resize></b-form-textarea>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.diabetology.lblDigestiveSystem") }}</strong>
            <br />
            <b-form-textarea v-model="jsonData.apparatoDigerente" no-resize></b-form-textarea>
          </b-col>
        </b-row>

        <hr />
        <b-row>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patients.lblUrogenitalApparatus") }} </strong>
            <br />
            <b-form-textarea v-model="jsonData.apparatoUrogenitale" no-resize></b-form-textarea>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.diabetology.lblArticularMuscleSystem") }} </strong>
            <br />
            <b-form-textarea v-model="jsonData.apparatoMuscoloArticolare" no-resize></b-form-textarea>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patients.diabetology.lblCardiovascularSystem") }} </strong>
            <br />
            <b-form-textarea v-model="jsonData.sistemaCardiovascolare" no-resize> </b-form-textarea>
          </b-col>
        </b-row>
        <hr />

        <b-row>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <strong>{{ getLabelTraduora("patients.lblNervousSystem") }}</strong>
            <br />
            <b-form-textarea v-model="jsonData.sistemaNervoso" no-resize></b-form-textarea>
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <strong> {{ getLabelTraduora("patients.lblFoot") }}</strong>
            <br />
            <b-form-textarea v-model="jsonData.piede" no-resize></b-form-textarea>
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="43">
            <strong> {{ getLabelTraduora("patients.lblSubcutaneous") }}</strong>
            <br />
            <b-form-textarea v-model="jsonData.sottocutaneo" no-resize></b-form-textarea>
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <strong> {{ getLabelTraduora("patients.lblMouthTeeth") }}</strong>
            <br />
            <b-form-textarea v-model="jsonData.boccaDenti" no-resize></b-form-textarea>
          </b-col>
        </b-row>
        <hr />

        <b-row>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patients.lblPatientAge") }}</strong>
            <br />
            <b-form-input v-model="jsonData.eta" type="text" disabled></b-form-input>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.lblCigarette") }}</strong>
            <br />
            <b-form-checkbox v-model="jsonData.fumatore" name="check-button" size="lg"></b-form-checkbox>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.lblAlcohol") }}</strong>
            <br />
            <b-form-checkbox v-model="jsonData.alcool" name="check-button" size="lg"></b-form-checkbox>
          </b-col>
        </b-row>
        <hr />

        <b-row>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patients.lblCholesterol") }}</strong>
            <br />
            <b-form-input v-model="jsonData.colesteroloHdl" type="number"></b-form-input>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patients.lblTotalCholesterol") }}</strong>
            <br />
            <b-form-input v-model="jsonData.colesteroloTotale" type="number"></b-form-input>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.lblChronicDiseases") }}</strong>
            <br />
            <b-form-input v-model="jsonData.malattieCroniche" type="number" no-resize></b-form-input>
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12">
            <strong>{{ getLabelTraduora("pazienti.egds.lblConclusions") }}</strong>
            <br />
            <b-form-textarea v-model="jsonData.conclusioni" rows="4" no-resize></b-form-textarea>
          </b-col>
        </b-row>
        <hr />
      </div>
    </template>
    <template slot="table-footer">
      <b-modal id="modalPressione" title="Diastolica" size="l" hide-footer v-model="showmodalPressione">
        <b-table :select-mode="'single'" selectable @row-selected="onRowSelected" sticky-header ref="table" stacked="l" striped hover :items="misurazioniPressione" :fields="fieldsPressione" sort-icon-left head-variant="light" class="sa-b-table"> </b-table>
        <template #cell(selected)="{ rowSelected }">
          <template v-if="rowSelected">
            <span aria-hidden="true">&check;</span>
            <span class="sr-only">{{ getLabelTraduora("agenda.lblSelected") }}</span>
          </template>
          <template v-else>
            <span aria-hidden="true">&nbsp;</span>
            <span class="sr-only">{{ getLabelTraduora("patient.lblNotSelected") }}</span>
          </template>
        </template>
        <div class="sa-padding-right text-right col">
          <b-button
            size="sm"
            variant="danger"
            @click="
              showmodalPressione = false;
              selected = [];
            "
          >
            {{ getLabelTraduora("global.lblBtnCancel") }}
          </b-button>
          <b-button size="sm" variant="secondary" @click="onAggiungiDs" :disabled="!selected[0]">
            OK
          </b-button>
        </div>
      </b-modal>
      <b-modal id="modalFrequenza" title="Frequenza Cardiaca" size="lg" hide-footer v-model="showModalFrequenza">
        <b-table :select-mode="'single'" selectable @row-selected="onRowSelected" sticky-header ref="table" stacked="l" striped hover :items="misurazioneCardiaca" :fields="fieldsFrequenza" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">&check;</span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>
        </b-table>
        <div class="sa-padding-right text-right col">
          <b-button
            size="sm"
            variant="danger"
            @click="
              showModalFrequenza = false;
              selected = [];
            "
          >
            {{ getLabelTraduora("global.lblBtnCancel") }}
          </b-button>
          <b-button size="sm" variant="secondary" @click="onAggiungiFrequenza" :disabled="!selected[0]">
            OK
          </b-button>
        </div>
      </b-modal>
      <b-modal id="modalSpo2" title="SPO2" size="l" hide-footer v-model="showmodalSpo2">
        <b-table :select-mode="'single'" selectable @row-selected="onRowSelected" sticky-header ref="table" stacked="l" striped hover :items="misurazioneSpo2" :fields="fieldsSpo2" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">&check;</span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>
        </b-table>
        <hr />
        <b-row>
          <div class="sa-padding-right text-right col">
            <b-button
              size="sm"
              variant="danger"
              @click="
                showmodalSpo2 = false;
                selected = [];
              "
            >
              {{ getLabelTraduora("global.lblBtnCancel") }}
            </b-button>
            <b-button size="sm" variant="secondary" @click="onAggiungiSpo" :disabled="!selected[0]">
              OK
            </b-button>
          </div>
        </b-row>
      </b-modal>
      <b-modal id="modalEcg" title="ECG" size="l" hide-footer v-model="showmodalEcg">
        <b-table :select-mode="'single'" selectable @row-selected="onRowSelected" sticky-header ref="table" stacked="l" striped hover :items="misurazioneEcg" :fields="fieldsEcg" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">&check;</span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>
        </b-table>
        <hr />
        <b-row>
          <div class="sa-padding-right text-right col">
            <b-button
              size="sm"
              variant="danger"
              @click="
                showmodalEcg = false;
                selected = [];
              "
            >
              {{ getLabelTraduora("global.lblBtnCancel") }}
            </b-button>
            <b-button size="sm" variant="secondary" @click="onAggiungiEcg" :disabled="!selected[0]">
              OK
            </b-button>
          </div>
        </b-row>
      </b-modal>
      <b-modal id="modalGlucosio" title="Glucosio" size="l" hide-footer v-model="showmodalGlucosio">
        <b-table :select-mode="'single'" selectable @row-selected="onRowSelected" sticky-header ref="table" stacked="lg" striped hover :items="misurazioneGlucosio" :fields="fieldsGlucosio" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">&check;</span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>
        </b-table>
        <hr />
        <b-row>
          <div class="sa-padding-right text-right col">
            <b-button
              size="sm"
              variant="danger"
              @click="
                showmodalGlucosio = false;
                selected = [];
              "
            >
              {{ getLabelTraduora("global.lblBtnCancel") }}
            </b-button>
            <b-button size="sm" variant="secondary" @click="onAggiungiGlucosio" :disabled="!selected[0]">
              OK
            </b-button>
          </div>
        </b-row>
      </b-modal>
    </template>
  </sa-page-layout>
</template>
<script>
import moment from "moment";
import axios from "axios";
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
import UtilityMixin from "../../../../utility/UtilityMixin";
export default {
  components: { SaPageLayout, PazienteAnagrafica },
  mixins: [UtilityMixin],
  props: {
    utente: {
      type: Object,
      default: function() {
        return { gruppo: { menuGruppo: [] } };
      },
    },
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    language() {
      return this.$route.query.language;
    },
    linkPatient: {
      get: function() {
        let returnValue = "";
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length > 1) {
              return "/paziente/view/" + this.idPaziente;
            } else {
              if (this.utente.gruppo.menuGruppo.length == 1) {
                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                  return "/paziente/view/" + this.idPaziente;
                } else {
                  return "/paziente/homepaziente";
                }
              }
            }
          }
        }
        return returnValue;
      },
    },
  },
  mounted() {
    let me = this;
    me.jsonData.idPaziente = this.$route.query.idPaziente;
    me.jsonData.idAmbulatorio = sessionStorage.getItem("idAmbulatorio");
    me.jsonData.language = this.language;
    me.jsonData.tipoEvento = "hyperhealthvisitahh";
    me.id = this.$route.params.id;
    if (me.id === "-1") {
      me.linkback = "/paziente/hyperhealtheventi/visitehh/";
    } else {
      me.linkback = "/paziente/hyperhealtheventi/visitahh/view/" + me.id;
    }
    me.jsonData.idAmbulatorio = sessionStorage.getItem("idAmbulatorio");
    me.loadData();
    me.getFumatoreAlcool();
    me.loadMisurazioni();
  },
  data() {
    return {
      linkback: null,
      pathResource: "/pazienteeventivisite",
      pathMisurazioni: "/pazientemisure",
      id: null,
      jsonData: { eta: "", altezza: null, peso: null, language: this.language },
      showModalLoading: false,
      showModalFrequenza: false,
      showmodalSpo2: false,
      showmodalEcg: false,
      showmodalPressione: false,
      showmodalGlucosio: false,
      isDisabled: false,
      currentPage: 1,
      perPage: 10,
      profili: [],
      misurazioneCardiaca: [],
      misurazioniPressione: [],
      misurazioneSpo2: [],
      misurazioneEcg: [],
      misurazioneGlucosio: [],
      provenienzaOption: [{ value: null, text: null }],
      dataInizio: new Date(),
      selected: {},
      fieldsFrequenza: [
        {
          label: "",
          key: "selected",
          selection: true,
          thStyle: "width: 1rem",
        },
        {
          label: "Frequenza Cardiaca",
          key: "value",
          sortable: true,
        },
        {
          label: "Data Esecuzione",
          key: "istant",
          sortable: true,
          formatter: (value) => {
            return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
          },
        },
        {
          label: "",
          key: "action",
          thStyle: "width: 4rem",
        },
      ],
      fieldsSpo2: [
        {
          label: "",
          key: "selected",
          selection: true,
          thStyle: "width: 1rem",
        },
        {
          label: "Valore SPO2",
          key: "value",
          sortable: true,
        },
        {
          label: "Data Esecuzione",
          key: "istant",
          sortable: true,
          formatter: (value) => {
            return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
          },
        },
      ],
      fieldsEcg: [
        {
          label: "",
          key: "selected",
          selection: true,
          thStyle: "width: 1rem",
        },
        {
          label: "Valore ECG",
          key: "value",
          sortable: true,
        },
        {
          label: "Data Esecuzione",
          key: "istant",
          sortable: true,
          formatter: (value) => {
            return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
          },
        },
      ],
      fieldsGlucosio: [
        {
          label: "",
          key: "selected",
          selection: true,
          thStyle: "width: 1rem",
        },
        {
          label: "Valore Glucosio",
          key: "value",
          sortable: true,
        },
        {
          label: "Data Esecuzione",
          key: "istant",
          sortable: true,
          formatter: (value) => {
            return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
          },
        },
      ],
      fieldsPressione: [
        {
          label: "",
          key: "selected",
          selection: true,
          thStyle: "width: 1rem",
        },
        {
          label: "data evento",
          key: "data",
          sortable: true,
        },
        {
          label: "pressureMax",
          key: "pressioneMax",
          sortable: true,
        },
        {
          label: "pressureMin",
          key: "pressioneMin",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    getEtaPaziente(value) {
      let me = this;
      me.jsonData.eta = value;
    },
    onRowSelected(items) {
      let me = this;
      if (items.length > 0) {
        me.selected = items;
      }
    },
    getFumatoreAlcool() {
      let me = this;
      let dataAl = moment(new Date()).valueOf();
      let dataDal = dataAl - 5184000000;
      let al = moment(dataAl).format("yyyy-MM-DD HH:mm:ss");
      let dal = moment(dataDal).format("yyyy-MM-DD HH:mm:ss");
      // console.log(al);
      // console.log(dal);
      let link = process.env.VUE_APP_PATH_API + "/hyperhealth?forPage=20&page=1&idPaziente=" + me.idPaziente + "&dal=" + dal + "&idMisura=habits&al=" + al + "&order=desc";
      if (me.id === "-1") {
        axios.get(link).then((res) => {
          if (res.data.data.list[0]) {
            let lista = res.data.data.list;
            let sigarette = 0;
            let alcool = 0;
            lista.forEach((element) => {
              if (element.measurename === "Sigarette") {
                sigarette = sigarette + Number(element.value);
              }
              if (element.measurename === "Alcool") {
                alcool = alcool + Number(element.value);
              }
            });
            // console.log("sigarette: " + sigarette);
            // console.log("alcool: " + alcool);
            if (sigarette > 0) {
              me.jsonData.fumatore = true;
            }
            if (alcool > 0) {
              me.jsonData.alcool = true;
            }
          } else {
            me.jsonData.fumatore = false;
            me.jsonData.alcool = false;
          }
        });
      }
    },
    onCalcoloBmi(altezza, peso) {
      let me = this;
      me.jsonData.bmi = (peso / ((altezza / 100) * (altezza / 100))).toFixed(2);
    },
    formatDate(value) {
      if (value) {
        return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
      } else {
        return "---";
      }
    },
    onAggiungiGlucosio() {
      let me = this;
      me.jsonData.glucosio = me.selected[0].value;
      me.selected = [];
      me.showmodalGlucosio = false;
    },
    onAggiungiFrequenza() {
      let me = this;
      me.jsonData.frequenzaCardiaca = me.selected[0].value;
      me.showModalFrequenza = false;
    },
    onAggiungiDs() {
      let me = this;
      me.jsonData.pressioneSistolica = me.selected[0].pressioneMax;
      me.jsonData.pressioneDiastolica = me.selected[0].pressioneMin;
      me.showmodalPressione = false;
    },
    onAggiungiSpo() {
      let me = this;
      me.jsonData.spo2 = me.selected[0].value;
      me.showmodalSpo2 = false;
    },
    onAggiungiEcg() {
      let me = this;
      if (me.selected[0].value !== null) {
        me.jsonData.ecg = me.selected[0].value;
        me.showmodalEcg = false;
      } else {
        me.isDisabled = true;
      }
    },
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
        axios.get(link + me.id).then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        });
      }
    },
    loadMisurazioni() {
      let me = this;
      let data = UtilityMixin.methods.formatDateTimeCustom(me.dataInizio, "YYYY-MM-DD HH:mm:ss");
      let link = process.env.VUE_APP_PATH_API + me.pathMisurazioni + "?idPaziente=" + me.idPaziente + "&dal=" + data;
      me.showModalLoading = false;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          if (element.measurename == "pressureMax" || element.measurename == "pressureMin") {
            //  if(element.istant)
            me.addPressure(element);
            //me.misurazioniPressione.push(element);
          } else if (element.measurename == "heartRate") {
            me.misurazioneCardiaca.push(element);
          } else if (element.measurename == "pulse") {
            me.misurazioneCardiaca.push(element);
          } else if (element.measurename == "spo2") {
            me.misurazioneSpo2.push(element);
          } else if (element.measurename == "diagnosis") {
            me.misurazioneEcg.push(element);
          } else if (element.measurename == "glucose") {
            me.misurazioneGlucosio.push(element);
          }
        });
        me.showModalLoading = false;
      });
    },
    addPressure(misura) {
      let me = this;
      let dataMisura = me.formatDate(misura.istant);
      let present = false;
      me.misurazioniPressione.forEach((misurazione) => {
        if (misurazione.data === dataMisura) {
          present = true;
          if (misura.measurename === "pressureMax") {
            misurazione.pressioneMax = misura.value;
          }
          if (misura.measurename === "pressureMin") {
            misurazione.pressioneMin = misura.value;
          }
        }
      });

      if (!present) {
        let misurazione = { data: dataMisura };
        if (misura.measurename === "pressureMax") {
          misurazione.pressioneMax = misura.value;
        }
        if (misura.measurename === "pressureMin") {
          misurazione.pressioneMin = misura.value;
        }
        me.misurazioniPressione.push(misurazione);
      }
    },
  },
};
</script>
