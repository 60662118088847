<template>
  <div>
    <h4 v-if="idMeasure === 'diet'">{{ getLabelTraduora("patient.diet.lblDietLastAnswer") }}:</h4>
    <h4 v-if="idMeasure === 'habits'">{{ getLabelTraduora("patient.lifeStyle.lblHabits") }}:</h4>
    <h4 v-if="idMeasure === 'step'">{{ getLabelTraduora("patient.lifeStyle.lblActivities") }}</h4>

    <br />
    <b-row cols="12">
      <b-col class="text-center" cols="12" xs="12" sm="2" md="2" lg="2" xl="2">
        <b-button class="mb-2 btn" @click="back()"><font-awesome-icon icon="chevron-left"/></b-button>
      </b-col>
      <b-col class="text-center" cols="12" xs="12" sm="8" md="8" lg="8" xl="8"
        ><strong>{{ formatDate(dateDataViewer) }}</strong></b-col
      >
      <b-col class="text-center" cols="12" xs="12" sm="2" md="2" lg="2" xl="2"
        ><b-button class="mb-2 btn" @click="forward()"><font-awesome-icon icon="chevron-right"/></b-button
      ></b-col>
    </b-row>
    <b-card class="mb-4">
      <p v-if="dataViewer.length === 0">{{ getLabelTraduora("patient.diet.lblNoAnswer") }}</p>
      <b-form-group v-if="dataViewer.length !== 0">
        <b-row class="text-left" cols="12" v-for="item in dataViewer" :key="item.id">
          <b-col cols="12" v-if="item.measurename === 'lbl.measure.breakfast'"
            ><span :class="'number-' + item.range"> <b v-html="returnQuantita('patient.diet.lblBreakfast', item.value, item.range)"></b></span>
          </b-col>
          <b-col cols="12" v-if="item.measurename === 'lbl.measure.midMorningSnack'">
            <span :class="'number-' + item.range"> <b v-html="returnQuantita('patient.diet.lblMorningSnack', item.value, item.range)"></b></span
          ></b-col>
          <b-col cols="12" v-if="item.measurename === 'lbl.measure.lunch'"
            ><span :class="'number-' + item.range"><b v-html="returnQuantita('patient.diet.lblLunch', item.value, item.range)"></b></span
          ></b-col>
          <b-col cols="12" v-if="item.measurename === 'lbl.measure.afternoonSnack'"
            ><span :class="'number-' + item.range"><b v-html="returnQuantita('patient.diet.lblAfternoonSnack', item.value, item.range)"></b></span
          ></b-col>
          <b-col cols="12" v-if="item.measurename === 'lbl.measure.dinner'"
            ><span :class="'number-' + item.range"><b v-html="returnQuantita('patient.diet.lblDinner', item.value, item.range)"></b></span
          ></b-col>
          <b-col cols="12" v-if="item.measurename === 'lbl.measure.eveningSnack'"
            ><span :class="'number-' + item.range"><b v-html="returnQuantita('patient.diet.lblNightSnack', item.value, item.range)"></b></span
          ></b-col>
          <b-col cols="12" v-if="item.measurename === 'lbl.measure.salt'"
            ><span :class="'number-' + item.range"><b v-html="cambiaColor('patient.diet.lblvarSalt', item.value, item.range)"></b></span></b-col
          ><b-col cols="12" v-if="item.measurename === 'lbl.measure.water'"
            ><span :class="'number-' + item.range"><b v-html="cambiaColor('patient.diet.lblvarWater', item.value, item.range)"></b></span
          ></b-col>
          <b-col cols="12" v-if="item.measurename === 'lbl.measure.coffe'"
            ><span :class="'number-' + item.range"><b v-html="cambiaColor('patient.lifeStyle.lblvarCoffee', item.value, item.range)"></b></span></b-col
          ><b-col cols="12" v-if="item.measurename === 'lbl.measure.cigarette'"
            ><span :class="'number-' + item.range"><b v-html="cambiaColor('patient.lifeStyle.lblvarCigarettes', item.value, item.range)"></b></span></b-col
          ><b-col cols="12" v-if="item.measurename === 'lbl.measure.alcool'"
            ><span :class="'number-' + item.range"><b v-html="cambiaColor('patient.lifeStyle.lblvarAlcohol', item.value, item.range)"></b></span></b-col
          ><b-col cols="12" v-if="item.measurename === 'lbl.measure.numberOfActivity'"
            ><span :class="'number-' + item.range"><b v-html="cambiaColor('patient.lifeStyle.lblvarActivitiesNumber', item.value, item.range)"></b></span></b-col
          ><b-col cols="12" v-if="item.measurename === 'lbl.measure.minuteOfActivity'"
            ><span :class="'number-' + item.range"><b v-html="cambiaColor('patient.lifeStyle.lblvarActivitiesMinutes', item.value, item.range)"></b></span
          ></b-col>
        </b-row>
      </b-form-group>
    </b-card>
  </div>
</template>

<script>
import UtilityMixin from "../../utility/UtilityMixin";
import axios from "axios";
import moment from "moment";

export default {
  mixins: [UtilityMixin],
  props: {
    idMeasure: {
      type: String,
      required: true,
    },
    idPaziente: {
      type: String,
      required: true,
    },
    forPage: {
      type: Number,
      required: true,
    },
  },
  created() {},
  mounted() {
    let me = this;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      me.dataViewer = [];
      me.dateDataViewer = null;

      let link = process.env.VUE_APP_PATH_API + "/pazientemisure/idmeasure?idPaziente=" + me.idPaziente + "&forPage=" + me.forPage + "&page=" + me.page + "&idMeasure=" + me.idMeasure;
      axios
        .get(link)
        .then((res) => {
          if (me.idMeasure == "diet") {
            res.data.data.forEach((element) => {
              switch (element.measurename) {
                case "lbl.measure.breakfast":
                  me.dataViewer[0] = element;
                  break;
                case "lbl.measure.midMorningSnack":
                  me.dataViewer[1] = element;
                  break;
                case "lbl.measure.lunch":
                  me.dataViewer[2] = element;
                  break;
                case "lbl.measure.afternoonSnack":
                  me.dataViewer[3] = element;
                  break;
                case "lbl.measure.dinner":
                  me.dataViewer[4] = element;
                  break;
                case "lbl.measure.eveningSnack":
                  me.dataViewer[5] = element;
                  break;
                case "lbl.measure.salt":
                  me.dataViewer[6] = element;

                  break;
                case "lbl.measure.water":
                  me.dataViewer[7] = element;
                  break;
              }
            });
            // console.log(me.dataViewer);
          } else {
            me.dataViewer = res.data.data;
            // console.log(me.dataViewer);
          }

          try {
            me.dateDataViewer = me.dataViewer[0].istant;
          } catch {
            me.dateDataViewer = "---";
          }

          //   me.dataViewer.forEach((element) => {
          //     // console.log(element.id);
          //     me.colorRangeNumbers(element);
          //   });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    cambiaColor(label, value, range) {
      console.log(label + " --> " + range);
      // console.log("value: " + value);
      if (label === "patient.diet.lblvarSalt") {
        // console.log("value: " + value);
        switch (value) {
          case "0":
            value = this.getLabelTraduora("patient.lblNo");
            break;
          case "1":
            value = this.getLabelTraduora("patient.lblYes");
        }
        // console.log("new value: " + value);
      }

      if (range == "NORMAL") {
        let x = this.traduciConValue(label, '<span style="color:#10c469;">' + value + "</span>");
        return x;
      } else if (range == "WARNING") {
        let x = this.traduciConValue(label, '<span style="color:#ffc107;">' + value + "</span>");
        return x;
      } else if (range == "ALERT") {
        let x = this.traduciConValue(label, '<span style="color:#ff5b5b;">' + value + "</span>");
        return x;
      } else {
        let x = this.traduciConValue(label, '<span style="color:black;">' + value + "</span>");
        return x;
      }
    },
    returnQuantita(label, value, range) {
      let me = this;
      let variable = me.getLabelTraduora(label);
      // console.log(range);
      let returnValue = "";
      if (value === "0") {
        if (range === "NORMAL") {
          returnValue = '<span style="color:#10c469;">' + me.traduciConValue("patient.diet.lblvarNothing", variable) + "</span>";
        } else if (range === "WARNING") {
          returnValue = '<span style="color:#ffc107;">' + me.traduciConValue("patient.diet.lblvarNothing", variable) + "</span>";
        } else if (range === "ALERT") {
          returnValue = '<span style="color:#ff5b5b;">' + me.traduciConValue("patient.diet.lblvarNothing", variable) + "</span>";
        } else {
          returnValue = '<span style="color:black;">' + me.traduciConValue("patient.diet.lblvarNothing", variable) + "</span>";
        }
      }
      if (value === "1") {
        if (range === "NORMAL") {
          returnValue = '<span style="color:#10c469;">' + me.traduciConValue("patient.diet.lblvarLittle", variable) + "</span>";
        } else if (range === "WARNING") {
          returnValue = '<span style="color:#ffc107;">' + me.traduciConValue("patient.diet.lblvarLittle", variable) + "</span>";
        } else if (range === "ALERT") {
          returnValue = '<span style="color:#ff5b5b;">' + me.traduciConValue("patient.diet.lblvarLittle", variable) + "</span>";
        } else {
          returnValue = '<span style="color:black;">' + me.traduciConValue("patient.diet.lblvarLittle", variable) + "</span>";
        }
      }
      if (value === "2") {
        if (range === "NORMAL") {
          returnValue = '<span style="color:#10c469;">' + me.traduciConValue("patient.diet.lblvarNormal", variable) + "</span>";
        } else if (range === "WARNING") {
          returnValue = '<span style="color:#ffc107;">' + me.traduciConValue("patient.diet.lblvarNormal", variable) + "</span>";
        } else if (range === "ALERT") {
          returnValue = '<span style="color:#ff5b5b;">' + me.traduciConValue("patient.diet.lblvarNormal", variable) + "</span>";
        } else {
          returnValue = '<span style="color:black;">' + me.traduciConValue("patient.diet.lblvarNormal", variable) + "</span>";
        }
      }
      if (value === "3") {
        if (range === "NORMAL") {
          returnValue = '<span style="color:#10c469;">' + me.traduciConValue("patient.diet.lblvarALot", variable) + "</span>";
        } else if (range === "WARNING") {
          returnValue = '<span style="color:#ffc107;">' + me.traduciConValue("patient.diet.lblvarALot", variable) + "</span>";
        } else if (range === "ALERT") {
          returnValue = '<span style="color:#ff5b5b;">' + me.traduciConValue("patient.diet.lblvarALot", variable) + "</span>";
        } else {
          returnValue = '<span style="color:black;">' + me.traduciConValue("patient.diet.lblvarALot", variable) + "</span>";
        }
      }
      if (value === "4") {
        if (range === "NORMAL") {
          returnValue = '<span style="color:#10c469;">' + me.traduciConValue("patient.diet.lblvarTooMuch", variable) + "</span>";
        } else if (range === "WARNING") {
          returnValue = '<span style="color:#ffc107;">' + me.traduciConValue("patient.diet.lblvarTooMuch", variable) + "</span>";
        } else if (range === "ALERT") {
          returnValue = '<span style="color:#ff5b5b;">' + me.traduciConValue("patient.diet.lblvarTooMuch", variable) + "</span>";
        } else {
          returnValue = '<span style="color:black;">' + me.traduciConValue("patient.diet.lblvarTooMuch", variable) + "</span>";
        }
      }
      return returnValue;
    },
    // colorRangeNumbers(element) {
    //   console.log("ID element:" + element.id);
    //   let inputText = document.getElementById(element.id);
    //   console.log(inputText);
    //   let innerHTML = inputText.innerHTML;
    //   let index = innerHTML.indexOf(element.value);
    //   if (index >= 0) {
    //     if (element.range === "NORMAL") {
    //       innerHTML = innerHTML.substring(0, index) + "<span style='color: green;'>" + innerHTML.substring(index, index + element.value.length) + "</span>" + innerHTML.substring(index + element.value.length);
    //     } else if (element.range === "WARNING") {
    //       innerHTML = innerHTML.substring(0, index) + "<span style='color: yellow;'>" + innerHTML.substring(index, index + element.value.length) + "</span>" + innerHTML.substring(index + element.value.length);
    //     } else if (element.range === "ALERT") {
    //       innerHTML = innerHTML.substring(0, index) + "<span style='color: red;'>" + innerHTML.substring(index, index + element.value.length) + "</span>" + innerHTML.substring(index + element.value.length);
    //     } else {
    //       innerHTML = innerHTML.substring(0, index) + "<span style='color: black;'>" + innerHTML.substring(index, index + element.value.length) + "</span>" + innerHTML.substring(index + element.value.length);
    //     }

    //     inputText.innerHTML = innerHTML;
    //   }
    // },
    formatDate(value) {
      if (value !== "---") {
        return moment(new Date(value)).format("DD-MM-YYYY");
      } else {
        return "---";
      }
    },
    back() {
      if (this.dateDataViewer !== "---") {
        this.page = this.page + 1;
        this.loadData();
      }
    },
    forward() {
      if (this.page > 1) {
        this.page = this.page - 1;
        this.loadData();
      }
    },
  },
  data() {
    return {
      page: 1,
      dataViewer: [],
      dateDataViewer: null,
    };
  },
};
</script>
