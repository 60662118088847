<template>
    <sa-page-layout :titolo="titolo" :showModalLoading="showModalLoading" :btnNewVisible="false" :btnBackVisible="true" :linkback="linkback">
        <template slot="toolbar">
            <b-button class="float-sm-right sa-btn-header sa-btn-icon-text" variant="light sa-margin-right" size="sm" @click="onRefresh" v-b-modal.modal-center><b-icon icon="arrow-clockwise" variant="secondary" />Refresh</b-button>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="tblImportTamponiAlchymiaDettaglio" id="tblImportTamponiAlchymiaDettaglio" :filter="filtro" stacked="lg" striped hover :items="items" :fields="fields" current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template #cell(stato)="{ item }">
                        <b-badge style="font-size: 100%!important;" :variant="getStato(item.stato)">{{ item.stato }}</b-badge>
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>Totale Record: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
    </sa-page-layout>
</template>

<script>
import axios from "axios";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { SaPageLayout },
    data() {
        return {
            pathResource: "/importtamponialchymiadettaglio",
            linkback: "/importtamponialchymia",
            titolo: "Import Tamponi Dettaglio",
            showModalLoading: false,
            currentPage: 1,
            perPage: 100,
            rows: 0,
            totalPage: 0,
            filtro: {},
            items: [],
            fields: [
                {
                    label: "Cognome",
                    key: "cdue",
                    sortable: true,
                    thStyle: "width: 18rem",
                },
                {
                    label: "Nome",
                    key: "cuno",
                    sortable: true,
                    thStyle: "width: 18rem",
                },
                {
                    label: "Codice Fiscale",
                    key: "ctre",
                    sortable: true,
                    thStyle: "width: 18rem",
                },
                {
                    label: "Stato",
                    key: "stato",
                    sortable: true,
                    thStyle: "width: 9rem",
                },
                {
                    label: "Note",
                    key: "note",
                    sortable: true,
                },
            ],
        };
    },
    watch: {
        currentPage() {
            let me = this;
            me.loadData();
        },
    },
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.showModalLoading = true;
            axios
                .get(link, { params: { idTamponiAlchymia: me.id, forPage: this.perPage, page: this.currentPage } })
                .then((response) => {
                    me.items = [];
                    me.rows = response.data.data.recordsNumber;
                    me.totalPage = (me.rows / me.perPage) >> 0;
                    me.items = response.data.data.list;
                    me.showModalLoading = false;
                })
                .catch(function(error) {
                    me.showModalLoading = false;
                    me.$bvToast.toast(error.response.data.messaggio, {
                        title: "Sorveglianza Covid Admin",
                        variant: "danger",
                        solid: true,
                    });
                });
        },
        getStato(value) {
            let returnValue = "secondary";
            switch (value) {
                case "CARICATO":
                    returnValue = "success";
                    break;
                case "SCARTATO":
                    returnValue = "danger";
                    break;
            }
            return returnValue;
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
    },
};
</script>

<style scoped></style>
