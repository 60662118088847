<template>
    <sa-page-layout :btnBackVisible="true" :linkback="linkback" :pathResource="pathResource" :showModalLoading="showModalLoading">
      <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col sm="6">
                        <b-form-input v-model="filtro.nome" type="search" id="nome" :placeholder="this.$i18n.t('anagrafica.lblFirstName')"></b-form-input>
                    </b-col>
                    <b-col sm="6">
                        <b-form-input v-model="filtro.cognome" type="search" id="cognome" :placeholder="this.$i18n.t('anagrafica.lblLastName')"></b-form-input>
                    </b-col>
                </b-row>
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col sm="">
                        <b-form-input v-model="filtro.codiceFiscale" type="search" id="codiceFiscale" :placeholder="this.$i18n.t('patients.lblFiscalCode')"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">{{ this.$i18n.t("patients.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("patients.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>  
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" id="tbl" responsive stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table" @row-clicked="onRowClickHandler">
                    <template v-slot:cell(nominativo)="{ item }"> {{ item.nome }} {{ item.cognome }} </template>
                </b-table>
            </div>
            <b-modal id="mdlModalUtente" ref="mdlModalUtente" title="Utente" size="lg" @ok="onOkModal">
                <div>
                    <b-row>
                        <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                            <label for="">{{this.$i18n.t("anagrafica.lblFirstName")}}</label>
                            <b-form-input v-model="users.firstname" readonly></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                            <label for="">{{this.$i18n.t("anagrafica.lblLastName")}}</label>
                            <b-form-input v-model="users.lastname" readonly></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                            <label for="">{{this.$i18n.t("anagrafica.lblEmail")}}</label>
                            <b-form-input v-model="users.email"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                            <label for="">{{this.$i18n.t("login.lblUsername")}}</label>
                            <b-form-input v-model="users.username" trim></b-form-input>
                        </b-col>
                    </b-row>
                </div>
            </b-modal>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from '../../../template/sa/components/layout/contents/SaPageLayout.vue';
export default {
    components: { SaPageLayout },
    data() {
        return {
            pathResource: "/pazienteaccesso/patientuser",
            linkback: "/patientaccess",
            showModalLoading: false,
            currentPage: 1,
            perPage: 20,
            fields: [
                {
                    label: this.$i18n.t("anagrafica.lblFirstName"),
                    key: "nome",
                    sortable: true,
                    thStyle: "width: 50rem",
                },
                {
                    label: this.$i18n.t("anagrafica.lblLastName"),
                    key: "cognome",
                    sortable: true,
                    thStyle: "width: 50rem",
                },
                {
                    label: this.$i18n.t("patients.lblFiscalCode"),
                    key: "codiceFiscale",
                    sortable: true,
                    thStyle: "width: 50rem",
                },
                {
                    label: this.$i18n.t("anagrafica.lblEmail"),
                    key: "email",
                    sortable: true,
                    thStyle: "width: 50rem",
                },
            ],
            items: [{ nome: "", cognome: "", codiceFiscale: "", username: "" }],
            users: { firstname: "", lastname: "", email: "", username: "", id: "" },
            filtro: {},
        };
    },
    computed: {
        rows() {
            return this.items.length;
        },
    },
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadDefaultData();
    },
    methods: {
        loadDefaultData() {
            let me = this;
            me.loadData();
        },
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.showModalLoading = true;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(function(error) {
                    me.showModalLoading = false;
                    me.$bvToast.toast(error.response.data.messaggio, {
                        title: "Sorveglianza Covid Admin",
                        variant: "danger",
                        solid: true,
                    });
                });
        },
        onRowClickHandler(item) {
            let me = this;
            me.users.firstname = item.nome;
            me.users.lastname = item.cognome;
            me.users.username = item.nome.toLowerCase().replace(" ", "") + "." + item.cognome.toLowerCase().replace(" ", "");
            me.users.email = item.email;
            me.users.id = item.id;
            me.$refs["mdlModalUtente"].show();
        },
        onOkModal() {
            let me = this;
            console.log(me.users);
            let jsonData = JSON.stringify(me.users);
            let link = process.env.VUE_APP_PATH_API + "/patientaccess";
            me.showModalLoading = true;
            axios
                .post(link, jsonData)
                .then((response) => {
                    me.showModalLoading = false;
                    console.log(response);
                })
                .catch((error) => {
                    me.showModalLoading = false;
                    this.$bvModal
                        .msgBoxOk(error.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxTwo = value;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        onReset() {
            let me = this;
            me.filtro = {};
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
    },
};
</script>
