<template>
  <div>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.afa.lblDateVisit") }}</strong>
            <br />
            {{ formatDate(jsonData.dataAppuntamento) }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.afa.lblSportPracticed") }}</strong>
            <br />
            {{ jsonData.sportPraticato }}
          </b-col>
          <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.afa.lblFollowingVisit") }}</strong>
            <br />
            {{ formatDate(jsonData.visitaSuccessiva) }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.afa.lblWeeklyWorkouts") }}</strong>
            <br />
            {{ jsonData.allenamentiSettimana }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.afa.lblSpeciality") }}</strong>
            <br />
            {{ jsonData.specialita }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.afa.lblFederation") }}</strong>
            <br />
            {{ jsonData.federazione }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.afa.lblOtherSports") }}</strong>
            <br />
            {{ jsonData.altriSport }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.afa.lblAntitetanus") }}</strong>
            <br />
            {{ jsonData.antitetanica }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.afa.lblAlcohol") }}</strong>
            <br />
            {{ jsonData.alcool }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.afa.lblNarcoticSubstances") }}</strong>
            <br />
            {{ convertData("default", jsonData.sostenzeStupefacenti) }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patients.lblFirstExamination") }}</strong>
            <br />
            {{ formatDate(jsonData.primaVisita) }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.afa.lblHourVisit") }}</strong>
            <br />
            {{ jsonData.oraAppuntamento }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.afa.lblYearsNumber") }}</strong>
            <br />
            {{ jsonData.numeroAnni }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.afa.lblHoursWorkout") }}</strong>
            <br />
            {{ formatTime(jsonData.oreAllenamento) }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.afa.lblCorporation") }}</strong>
            <br />
            {{ jsonData.societa }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.afa.lblLHB") }}</strong>
            <br />
            {{ jsonData.aslNew }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.afa.lblMenarche") }}</strong>
            <br />
            {{ jsonData.menarca }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.afa.lblSmoking") }}</strong>
            <br />
            {{ convertData("fumo", jsonData.fumo) }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.afa.lblCoffee") }}</strong>
            <br />
            {{ convertData("caffe", jsonData.caffe) }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.afa.lblMilitary") }}</strong>
            <br />
            {{ convertData("default", jsonData.militare) }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.afa.lblMilitaryMotif") }}</strong>
            <br />
            {{ jsonData.militareMotivo }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
            <strong>{{ this.$i18n.t("patient.afa.lblIdentityDocument") }}</strong>
            <br />
            {{ convertData("cartaID", jsonData.documentoIdentitaTipo) }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
            <strong>{{ this.$i18n.t("patient.afa.lblIdentityDocumentNumber") }}</strong>
            <br />
            {{ jsonData.documentoIdentitaNumero }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
            <strong>{{ this.$i18n.t("patient.afa.lblIdentityDocumentReleaser") }}</strong>
            <br />
            {{ jsonData.documentoIdentitaRilasciatoDa }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
            <strong>{{ this.$i18n.t("patient.afa.lblIdentityDocumentDateRelease") }}</strong>
            <br />
            {{ formatDate(jsonData.documentoIdentitaRilasciatoIl) }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
  </div>
</template>
<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";
export default {
  mixins: [UtilityMixin],
  props: {
    jsonData: { Type: Object, require: true },
  },
  methods: {
    convertData(label, data) {
      let returnValue = "error translation";
      switch (label) {
        case "alcool":
          if (data == 0) {
            returnValue = this.getLabelTraduora("patient.afa.lblAbstinent");
          } else if (data == 1) {
            returnValue = this.getLabelTraduora("patient.afa.lblAbstinent");
          } else if (data == 2) {
            returnValue = this.getLabelTraduora("patient.afa.lblAbstinent");
          } else if (data == 3) {
            returnValue = this.getLabelTraduora("patient.afa.lblAbstinent");
          } else if (data == 4) {
            returnValue = this.getLabelTraduora("patient.afa.lblAbstinent");
          }
          break;
        case "fumo":
          if (data == 0) {
            returnValue = this.getLabelTraduora("patient.afa.lblNotSmoker");
          } else if (data == 1) {
            returnValue = this.getLabelTraduora("patient.afa.lblUpTo10Cigarettes");
          } else if (data == 2) {
            returnValue = this.getLabelTraduora("patient.afa.lblUpTo20Cigarettes");
          } else if (data == 3) {
            returnValue = this.getLabelTraduora("patient.afa.lblmore20Cigarettes");
          }
          break;
        case "caffe":
          if (data == 0) {
            returnValue = this.getLabelTraduora("patient.afa.lbl0Cups");
          } else if (data == 1) {
            returnValue = this.getLabelTraduora("patient.afa.lbl1Cup");
          } else if (data == 2) {
            returnValue = this.getLabelTraduora("patient.afa.lbl2Cups");
          } else if (data == 3) {
            returnValue = this.getLabelTraduora("patient.afa.lbl3Cups");
          } else if (data == 4) {
            returnValue = this.getLabelTraduora("patient.afa.lblMore3Cups");
          }
          break;
        case "cartaID":
          if (data == "CI") {
            returnValue = this.getLabelTraduora("patient.afa.lblIdentityCard");
          } else if (data == "PASS") {
            returnValue = this.getLabelTraduora("patient.afa.lblPassport");
          }
          break;
        case "default":
          if (data == 0) {
            returnValue = this.getLabelTraduora("patient.lblNo");
          }
          if (data == 1) {
            returnValue = this.getLabelTraduora("patient.lblYes");
          }
      }
      return returnValue;
    },
  },
};
</script>
