<template>
    <sa-page-view-edit ref="pgaView" :data="data" :pathResource="pathResource" :backVisible="true" :saveVisible="false" :titolo="data.username" :linkback="linkback" :linkedit="linkedit" :showmolalloading="showmolalloading">
        <template slot="body">
            <b-row>
                <b-col cols="12" sm="2" class="sa-text-right">Cognome:</b-col>
                <b-col cols="12" sm="4">
                    <span class="sa-text-data">{{data.lastname}}</span>
                </b-col>
                <b-col cols="12" md="2" class="sa-text-right">Nome:</b-col>
                <b-col cols="12" md="4">
                    <span class="sa-text-data">{{data.firstname}}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" md="2" class="sa-text-right">Username:</b-col>
                <b-col cols="12" md="4">
                   <span class="sa-text-data">{{data.username}}</span>
                </b-col>
                <b-col cols="12" md="2" class="sa-text-right">E-Mail:</b-col>
                <b-col cols="12" md="4">
                    <span class="sa-text-data">{{data.email}}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" md="2" class="sa-text-right">Codice Fiscale:</b-col>
                <b-col cols="12" md="4">
                    <span class="sa-text-data">{{data.codiceFiscale}}</span>
                </b-col>
                <b-col cols="12" md="2" class="sa-text-right">Scadenza:</b-col>
                <b-col cols="12" md="4">
                    <span class="sa-text-data">{{formatDate(data.scadenzaUtenza)}}</span>
                </b-col>
            </b-row>
        </template>
    </sa-page-view-edit>
</template>
<script>
import moment from "moment";
import axios from "axios";
import { SaPageViewEdit } from "../../../template/sa/components/layout/contents";
export default {
    components: { SaPageViewEdit },
    data() {
        return {
            pathResource: "/sorveglianzarefertiutenti",
            linkback: "/sorveglianzarefertiutenti",
            linkedit: null,
            showmolalloading: false,
            id: "-1",
            profili: [],
            verificaPassword: "",
            data: {  },
            
        };
    },
    computed: {
        dataScadenzaUtenza: {
            get: function() {
                return new Date(this.data.scadenzaUtenza);
            },
            set: function(value) {
                this.data.scadenzaUtenza = moment(String(value)).valueOf();
            },
        },
    },
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadDefaultData();
    },
    methods: {
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            } else {
                return "---";
            }
        },
        loadDefaultData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            axios.get(link + "/" + me.id).then((response) => {
                me.data = response.data.data;
            });
        },
        loadData() {
            let me = this;

            //console.log(me.id);
            if (me.id === "-1") {
                me.showmolalloading = false;
            } else {
                me.showmolalloading = true;
                let link = process.env.VUE_APP_PATH_API + me.pathResource;
                axios.get(link + me.id).then((response) => {
                    me.data = response.data.data;
                    me.showmolalloading = false;
                });
            }
        },
    },
};
</script>