import MedicinaDelloSport from "../";

const routes = [
  { name: "VisitaAfaList", path: "/paziente/medicinadellosportafa", component: MedicinaDelloSport.AfaList, meta: { title: "Visita AFA" } },
  { name: "VisitaAfaView", path: "/paziente/medicinadellosportafa/view/:id", component: MedicinaDelloSport.AfaView, meta: { title: "Visita AFA" } },
  { name: "VisitaAfaEdit", path: "/paziente/medicinadellosportafa/edit/:id", component: MedicinaDelloSport.AfaEdit, meta: { title: "Visita AFA" } },
  { name: "IdoneitaList", path: "/paziente/medicinadellosportidoneita", component: MedicinaDelloSport.IdoneitaList, meta: { title: "Certificati Idoneità" } },
  { name: "IdoneitaView", path: "/paziente/medicinadellosportidoneita/view/:id", component: MedicinaDelloSport.IdoneitaView, meta: { title: " Certificato Idoneità" } },
  { name: "IdoneitaEdit", path: "/paziente/medicinadellosportidoneita/edit/:id", component: MedicinaDelloSport.IdoneitaEdit, meta: { title: "Certificato Idoneità" } },
  { name: "RefertazioneEcocardioList", path: "/paziente/refertazioneecocardio", component: MedicinaDelloSport.RefertazioneEcocardioList, meta: { title: "Refertazione Ecocardio" } },
  { name: "RefertazioneEcocardioView", path: "/paziente/refertazioneecocardio/view/:id", component: MedicinaDelloSport.RefertazioneEcocardioView, meta: { title: "Refertazione Ecocardio" } },
  { name: "RefertazioneEcocardioEdit", path: "/paziente/refertazioneecocardio/edit/:id", component: MedicinaDelloSport.RefertazioneEcocardioEdit, meta: { title: "Refertazione Ecocardio" } },
];
export default routes;
