<template>
  <sa-page-layout :btnAnnullaVisible="true" :linkback="linkback" :linkedit="linkedit">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-body">
      <div class="mb-4">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <strong>{{ getLabelTraduora("paziente.questionari.lblDescription") }}:</strong>
            {{ jsonData.descrizioneQuestionario }}</b-col
          >
          <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
            <strong>{{ getLabelTraduora("paziente.questionari.lblCreateDate") }}:</strong>
            {{ formatDate(jsonData.create_date) }}
          </b-col>
          <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
            <strong>{{ getLabelTraduora("paziente.questionari.lblTotalQuestions") }}:</strong>
            {{ jsonData.questionarioDomandaBeans.length }}
          </b-col>
          <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
            <b-button class="float-sm-right " variant="success btn-toolbar sa-margin-right" size="sm" v-b-modal.avviaQuestionario
              ><b-icon icon="play"></b-icon>{{ this.$i18n.t("paziente.questionari.lblBtnStartAnswer") }}</b-button
            >
          </b-col>

          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <strong>{{ getLabelTraduora("paziente.questionari.lblNotes") }}:</strong>
            {{ jsonData.testo }}</b-col
          >
        </b-row>
      </div>
      <hr />
      <div class="sa-tab-scroll">
        <div v-for="item in jsonQuestionarioPaziente" :key="item.id">
          <paziente-compilazione-questionari-view-component :domanda="item"></paziente-compilazione-questionari-view-component>
        </div>
      </div>
      <b-modal id="avviaQuestionario" scrollable :title="jsonData.descrizioneQuestionario" size="xl">
        <div v-for="item in domande" :key="item.id">
          <paziente-compilazione-questionari-edit-answer :domanda="item" :id-paziente-questionario="idEvento" :risposte="risposte"></paziente-compilazione-questionari-edit-answer>
        </div>
        <template #modal-footer="{ salvaAnswer }">
          <b-button size="sm" variant="success" @click="salvaRisposte()" :id="salvaAnswer">
            {{ getLabelTraduora("global.lblBtnSave") }}
          </b-button>
        </template>
      </b-modal>
    </template>
  </sa-page-layout>
</template>
<script>
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
import PazienteCompilazioneQuestionariViewComponent from "./PazienteCompilazioneQuestionariViewComponent.vue";
import PazienteCompilazioneQuestionariEditAnswer from "./PazienteCompilazioneQuestionariEditAnswer.vue";
import UtilityMixin from "../../../../utility/UtilityMixin";
import axios from "axios";

import moment from "moment";
export default {
  mixins: [UtilityMixin],
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  components: {
    SaPageLayout,
    PazienteAnagrafica,
    PazienteCompilazioneQuestionariViewComponent,
    PazienteCompilazioneQuestionariEditAnswer,
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    idEvento() {
      return this.$route.params.id;
    },
    linkPatient: {
      get: function() {
        let returnValue = "";
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length > 1) {
              return "/paziente/view/" + this.idPaziente;
            } else {
              if (this.utente.gruppo.menuGruppo.length == 1) {
                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                  return "/paziente/view/" + this.idPaziente;
                } else {
                  return "/paziente/homepaziente";
                }
              }
            }
          }
        }
        return returnValue;
      },
    },
  },
  data() {
    return {
      linkback: "/paziente/compilazionequestionari",
      linkedit: "paziente/compilazionequestionari/edit/",
      pathResource: "/pazientequestionario",
      pathQuestionario: "/questionario",
      domande: [],
      risposte: [],
      jsonData: null,
      jsonQuestionarioPaziente: null,
    };
  },
  mounted() {},
  created: function() {
    let me = this;
    me.linkedit = me.linkedit + me.idEvento;
    me.loadData();
    document.addEventListener("loadData", function() {
      me.loadData();
    });
  },

  methods: {
    async loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.idEvento;
      await axios
        .get(link)
        .then((res) => {
          me.jsonQuestionarioPaziente = res.data.data;
        })
        .catch();
      let linkQuestionario = process.env.VUE_APP_PATH_API + me.pathQuestionario + "/" + me.jsonQuestionarioPaziente[0].idQuestionario;

      await axios.get(linkQuestionario).then((res) => {
        me.jsonData = res.data.data;
        me.domande = me.jsonData.questionarioDomandaBeans;
      });

      me.showModalLoading = false;
    },

    formatDate(data) {
      return moment(new Date(data)).format("DD-MM-YYYY HH:mm:ss");
    },
    salvaRisposte: function() {
      let me = this;
      me.showModalLoading = true;
      let linkRisposte = process.env.VUE_APP_PATH_API + "/pazientequestionariodomandarisposta";

      axios
        .post(linkRisposte, me.risposte)
        .then((res) => {
          console.log(res);
          alert(me.getLabelTraduora("paziente.questionario.lblSuccess"));
        })
        .catch((e) => alert(e));
      this.$bvModal.hide("avviaQuestionario");

      me.showModalLoading = false;
    },
  },
};
</script>
