<template>
  <sa-page-layout :btnNewVisible="true" :pathResource="pathResource" :showModalLoading="showModalLoading" :linkedit="linkedit">
    <!-- <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template> -->
    <template slot="table-header">
      <b-row>
        <b-col lg="3">
          <p>{{ this.$i18n.t("clinic.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" responsive stacked="xl" striped hover :items="items" :fields="fields" sort-icon-left head-variant="light" class="sa-b-table">
          <template v-slot:cell(descrizioneQuestionario)="{ item }">
            <router-link class="sa-edit-link" :to="'/questionari/view/' + item.id">{{ item.descrizioneQuestionario }} </router-link>
          </template>
          <template #cell(actions)="row">
            <b-button size="sm" variant="btn btn-danger waves-effect waves-light width-sm no-text" @click="onRemoveQuestionario(row.item.id)">
              <b-icon icon="trash"></b-icon>
            </b-button>
          </template>
        </b-table>
      </div>
    </template>
    <template slot="table-footer">
      <b-row>
        <b-col lg="3">
          <p>{{ this.$i18n.t("clinic.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
  </sa-page-layout>
</template>
<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
// import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
import UtilityMixin from "../../UtilityMixin";
import moment from "moment";
import axios from "axios";
export default {
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  components: {
    SaPageLayout,
    // PazienteAnagrafica
  },
  mixins: [UtilityMixin],
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    rows() {
      return this.items.length;
    },
    btnNewVisible: {
      get: function() {
        let me = this;
        return UtilityMixin.methods.verificaPermessi(me.utente, "questionari", 2);
      },
    },
    // linkPatient: {
    //   get: function() {
    //     let returnValue = "";
    //     if (this.utente.gruppo) {
    //       if (this.utente.gruppo.menuGruppo) {
    //         if (this.utente.gruppo.menuGruppo.length > 1) {
    //           return "/paziente/view/" + this.idPaziente;
    //         } else {
    //           if (this.utente.gruppo.menuGruppo.length == 1) {
    //             if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
    //               return "/paziente/view/" + this.idPaziente;
    //             } else {
    //               return "/paziente/homepaziente";
    //             }
    //           }
    //         }
    //       }
    //     }
    //     return returnValue;
    //   },
    // },
  },
  data() {
    return {
      pathResource: "/questionario",
      // pathRESTRequest: "/paziente/questionari",
      linkedit: "/questionari",
      pagelink: "",
      perPage: 10,
      currentPage: 1,
      note: "",
      pdf: null,
      filter: null,
      showModalLoading: false,
      id: "-1",
      filtro: {},
      fields: [
        {
          label: "Descrizione",
          key: "descrizioneQuestionario",
          sortable: false,
          thStyle: "width: 3rem",
        },
        {
          label: "Data Creazione",
          key: "createDate",
          formatter: (value) => {
            if (!value) {
              return "";
            } else {
              return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            }
          },
          thStyle: "width: 3rem",
          sortable: true,
        },
        {
          label: "",
          key: "actions",
          sortable: false,
          thStyle: "width: 3rem",
        },
        //{ class: "sa-table-column-action-single", thStyle: "width: 10rem" ,key: "actions", label: "" },
      ],
      items: [],
      data: {},
    };
  },
  created: function() {
    let me = this;
    me.id = this.$route.params.id;
    me.data.idPaziente = this.$route.query.idPaziente;
    me.loadData();
  },
  mounted: function() {},
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      me.showModalLoading = true;
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.items = [];
          me.items = response.data.data;
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    onRemoveQuestionario(id) {
      let linkDelete = process.env.VUE_APP_PATH_API + this.pathResource + "/" + id;
      axios
        .delete(linkDelete)
        .then(() => {
          this.loadData();
        })
        .catch((e) => {
          alert(e.messaggio);
        });
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onClick(item) {
      let me = this;
      me.pdf = "data:application/pdf;base64," + item.pdf;
    },
    // onAdd() {
    //   let me = this;
    //   me.$router.replace(me.pathRESTRequest + "/edit/-1?idPaziente=" + me.idPaziente).catch((err) => {
    //     err;
    //   });
    // },
  },
};
</script>
