import EsameObiettivoView from "./components/EsameObiettivoView.vue";
import AnamnesiView from "./components/AnamnesiView.vue";
import AtletiList from "./components/AtletiList.vue";
import AtletaView from "./components/AtletaView.vue";
import WorkList from "./components/WorkList.vue";
import AtletaEdit from "./components/AtletaEdit.vue";
import PendingList from "./components/PendingList.vue";
import VisitaMedicoSportivaView from "./components/VisitaMedicoSportivaView.vue";
import VisitaMedicoSportivaEdit from "./components/VisitaMedicoSportivaEdit.vue";
import VisiteList from "./components/VisiteList.vue";
import PrenotazioniList from "./components/PrenotazioniList.vue";
import PrenotazioniView from "./components/PrenotazioniView.vue";
import PrenotazioniEdit from "./components/PrenotazioniEdit.vue";
import LeonidaAccettazionePazienteList from "./components/LeonidaAccettazionePazienteList.vue";
import LeonidaAccettazionePazienteView from "./components/LeonidaAccettazionePazienteView.vue";
import LeonidaAccettazionePazienteEdit from "./components/LeonidaAccettazionePazienteEdit.vue";
import AccertamentiSupplementatriDettagliEdit from "./components/AccertamentiSupplementariDettagliEdit.vue";
import LeonidaFederazioniSportiveNazionaliList from "./components/LeonidaFederazioniSportiveNazionaliList.vue";
import LeonidaFederazioniSportiveNazionaliView from "./components/LeonidaFederazioniSportiveNazionaliView.vue";
import LeonidaFederazioniSportiveNazionaliEdit from "./components/LeonidaFederazioniSportiveNazionaliEdit.vue";

export default {
    EsameObiettivoView,
    AnamnesiView,
    AtletiList,
    AtletaView,
    AtletaEdit,
    WorkList,
    PendingList,
    VisitaMedicoSportivaView,
    VisitaMedicoSportivaEdit,
    VisiteList,
    PrenotazioniList,
    PrenotazioniView,
    PrenotazioniEdit,
    LeonidaAccettazionePazienteList,
    LeonidaAccettazionePazienteView,
    LeonidaAccettazionePazienteEdit,
    AccertamentiSupplementatriDettagliEdit,
    LeonidaFederazioniSportiveNazionaliList,
    LeonidaFederazioniSportiveNazionaliView,
    LeonidaFederazioniSportiveNazionaliEdit,
};
