<template>
    <sa-page-layout :pathResource="pathResource" :showmolalloading="showmolalloading">
        <template slot="toolbar"></template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <ambulatori-list-component></ambulatori-list-component>
            </div>
        </template>
    </sa-page-layout>
</template>

<script>
import logo from "../../assets/images/logo.png";
import AmbulatoriListComponent from "../studiomedico/components/StudioMedicoAmbulatoriListComponent.vue";
import SaPageLayout from "../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { AmbulatoriListComponent, SaPageLayout },
    created() {},
    data() {
        return {
            showmolalloading: false,
            pathResource: "/ambulatoriutenti",
            logo,
        };
    },
};
</script>
<style lang="css">
.sa-logo-home img {
    width: 100%;
    max-width: 35rem;
}
</style>
>
