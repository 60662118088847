<template>
    <sa-page-layout ref="pgaView" :data="data" :pathResource="pathResource" :btnAnnullaVisible="true" :btnSaveVisible="true" :linkback="linkback" :linkedit="linkedit" :showModalLoading="showModalLoading">
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <div class="sa-form-section">
                    <h5 class="sa-text-left">Richiesta Scorte</h5>
                    <b-row>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Numero Sacche Richieste</span>
                            <b-form-input v-model="data.numeroSaccheRichieste" placeholder="Richiesta"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Data Richiesta</span>
                            <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="data.dataRichiesta" type="datetime" placeholder="Data Richiesta"></date-picker>
                        </b-col>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Tipologia</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="data.tipologia" class="mb-2 mr-sm-2 mb-sm-0" :options="tipologiaOption" :value="null"></b-form-select>
                        </b-col>
                    </b-row>
                </div>
                <div class="sa-form-section">
                    <b-row>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Gruppo Sanguigno</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="data.gruppoSanguigno" class="mb-2 mr-sm-2 mb-sm-0" :options="gruppoSanguignoOption" :value="null"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Fattore RH</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="data.fattoreRh" class="mb-2 mr-sm-2 mb-sm-0" :options="fattoreRhOption" :value="null"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Matricola Medico</span>
                            <b-form-input v-model="data.matricolaMedico" placeholder="Matricola Medico"></b-form-input>
                        </b-col>
                    </b-row>
                </div>
                <div class="sa-form-section">
                    <b-row>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Matricola Operatore</span>
                            <b-form-input v-model="data.matricolaOperatore" placeholder="Matricola Operatore"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">HB</span>
                            <b-form-input v-model="data.hb" placeholder="HB"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">INR</span>
                            <b-form-input v-model="data.inr" placeholder="INR"></b-form-input>
                        </b-col>
                    </b-row>
                </div>
                <div class="sa-form-section">
                    <b-row>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">PT</span>
                            <b-form-input v-model="data.pt" placeholder="PT"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">PTT</span>
                            <b-form-input v-model="data.pit" placeholder="PTT"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">APTT</span>
                            <b-form-input v-model="data.aptt" placeholder="APTT"></b-form-input>
                        </b-col>
                    </b-row>
                </div>
                <div class="sa-form-selection">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <span class="sa-form-label">Motivo Richiesta</span>
                            <b-form-textarea id="textarea-no-resize" v-model="data.motivoRichiesta" placeholder="Inserisci Note" rows="3" no-resize></b-form-textarea>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </template>
        <template slot="footer">
            <b-modal id="ricercaRichiesta" title="Ricerca Richiesta" @show="onShow" @ok="onOk">
                <b-input-group class="mb-3">
                    <b-form-input v-model="numeroRichiesta" placeholder="Richieste"></b-form-input>
                    <b-button size="sm" variant="" v-b-modal.ricercaRichiesta @click="onClick">
                        <b-icon icon="search"></b-icon>
                    </b-button>
                </b-input-group>
                <b-table id="tabellaRichieste" :items="tabellaRichieste" :fields="richiesteFields" selectable select-mode="single" @row-selected="onRowSelected"> </b-table>
            </b-modal>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { DatePicker, SaPageLayout },
    data() {
        return {
            pathResource: "/bancheematichetrasfusionirichiestescorte",
            pathRESTRequest: "/bancaematica",
            linkstato: "/bancheematichetrasfusionirichiestescortestatitipi",
            linkgrupposanguigno: "/bancheematichegruppisanguigni",
            linkfattorerh: "/bancaematicafattorirh",
            linktipologie: "/bancheematichetipiemoderivatitipologie",
            pathResourceAggiornaStato: "/aggiornaStato",
            linkedit: null,
            linkback: null,
            showModalLoading: false,
            id: "-1",
            profili: [],
            statoOption: [{ value: null, text: "--Seleziona Valore--" }],
            gruppoSanguignoOption: [{ value: null, text: "--Seleziona Valore--" }],
            fattoreRhOption: [{ value: null, text: "--Seleziona Valore--" }],
            tipologiaOption: [{ value: null, text: "--Seleziona Valore--" }],
            tabellaRichieste: [],
            rigaSelezionata: null,
            numeroRichiesta: null,
            pathRestRichiesteScorte: "/bancheematichetrasfusionirichiestescorte",
            data: { statoAttuale: null, gruppoSanguigno: null, fattoreRh: null, numeroRichiesta: null, tipologia: null },
            richiesteFields: [
                {
                    label: "Numero Richiesta",
                    key: "numeroRichiesta",
                    sortable: true,
                    thStyle: "width: 7rem",
                },
                {
                    label: "Matricola Medico",
                    key: "matricolaMedico",
                    sortable: true,
                    thStyle: "width: 7rem",
                },
                {
                    label: "Gruppo Sanguigno",
                    key: "gruppoSanguigno",
                    sortable: true,
                    thStyle: "width: 7rem",
                },
            ],
        };
    },
    computed: {},
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        if (me.id === "-1") {
            me.linkback = "/bancaematicarichiestascorte";
        } else {
            me.linkback = "/bancaematicarichiestascorte/view/" + me.id;
        }
        me.loadDefaultData();
    },
    methods: {
        loadDefaultData() {
            let me = this;
            me.loadData();
            me.loadFattoreRh();
            me.loadGruppoSanguigno();
            me.loadStato();
            me.loadTipologia();
        },
        loadData() {
            let me = this;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                //me.showModalLoading = true;
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.data = response.data.data;
                    me.showModalLoading = false;
                });
            }
        },
        loadStato() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkstato;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.statoOption.push({ value: element.valore, text: element.descrizione });
                });
            });
        },
        loadGruppoSanguigno() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkgrupposanguigno;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.gruppoSanguignoOption.push({ value: element.valore, text: element.valore });
                });
            });
        },
        loadTipologia() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linktipologie;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.tipologiaOption.push({ value: element.tipologia, text: element.tipologia });
                });
            });
        },
        loadFattoreRh() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkfattorerh;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.fattoreRhOption.push({ value: element.valore, text: element.valore });
                });
            });
        },
        onModificaFattoreRh(valore) {
            if (valore === "POSITIVO") {
                return "+";
            } else {
                return "-";
            }
        },
        onShow() {
            let me = this;
            me.tabellaRichieste = [];
        },
        onRowSelected(items) {
            if (items.length > 0) {
                this.rigaSelezionata = items[0];
            }
        },
        onOk() {
            let me = this;
            me.data.numeroRichiesta = me.rigaSelezionata.numeroRichiesta;
            me.data.hb = me.rigaSelezionata.hb;
            me.data.inr = me.rigaSelezionata.inr;
            me.data.pt = me.rigaSelezionata.pt;
            me.data.pit = me.rigaSelezionata.pit;
            me.data.aptt = me.rigaSelezionata.aptt;
            me.data.gruppoSanguigno = me.rigaSelezionata.gruppoSanguigno;
            me.data.codiceSacca = me.rigaSelezionata.codiceSacca;
            me.data.fattoreRh = me.rigaSelezionata.fattoreRh;
        },
        onClick() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathRestRichiesteScorte + "/";
            axios
                .get(link, { params: { numeroRichiesta: me.numeroRichiesta } })
                .then((response) => {
                    me.tabellaRichieste = response.data.data;
                    me.showModalLoading = false;
                })
                .catch((error) => {
                    me.showModalLoading = false;
                    me.$bvModal
                        .msgBoxOk(error.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxTwo = value;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
        onSave() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            let jsonData = JSON.stringify(me.data);

            me.showModalLoading = true;
            if (me.id == "-1") {
                axios
                    .post(link, jsonData)
                    .then((response) => {
                        me.data = response.data.data;
                        me.showModalLoading = false;
                        me.$router.replace(me.routeLink).catch((err) => {
                            err;
                        });
                    })
                    .catch((error) => {
                        me.showModalLoading = false;
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            } else {
                axios
                    .put(link + "/" + me.id, jsonData)
                    .then((response) => {
                        me.gruppo = response.data.data;
                        me.$refs["sa-modal-loading"].close();
                        me.$router.replace(me.routeLink).catch((err) => {
                            err;
                        });
                    })
                    .catch((error) => {
                        me.$refs["sa-modal-loading"].close();
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            }
        },
    },
};
</script>
