<template>
  <div class="sa-agenda-container">
    <div class="sa-agenda-view ">
      <div class="sa-panel-grid">
        <div class="sa-agenda-day-row" v-for="giorno in disponibilita" :key="giorno.giorno">
          <div class="sa-agenda-day sa-agenda-mobile-grid-cell ">
            <div class="sa-agenda-day-date">
              <div class="sa-agenda-day-date-number" v-if="giorno.giorno">{{ getDayByDate(giorno.giorno) }}</div>
              <div class="sa-agenda-day-date-text" v-if="giorno.giorno">
                <div>{{ getWeekDayByDate(giorno.giorno) }}</div>
                <div>{{ getMonthByDate(giorno.giorno) }} {{ getYearByDate(giorno.giorno) }}</div>
              </div>
            </div>
            <div class="sa-agenda-day-events">
              <table class="sa-agenda-table-events">
                <tr v-for="(disponibilita, i) in giorno.disponibilita" :key="getIndexDisponibilita(disponibilita, i)">
                  <td class="sa-agenda-td-intervallo">{{ disponibilita.dalleOre }} - {{ disponibilita.alleOre }}</td>
                  <td class="sa-agenda-td-descrizione">
                    <agenda-slot
                      :idAgenda="idAgenda"
                      :costo="costo"
                      :codicePrestazione="codicePrestazione"
                      :descrizionePrestazione="descrizionePrestazione"
                      :index="getIndexDisponibilita(disponibilita, i)"
                      :slotDisponibilita="disponibilita"
                      @update="updateData"
                    ></agenda-slot>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import AgendaSlot from "./AgendaSlot.vue";
export default {
  components: { AgendaSlot },
  data() {
    return {
      items: [],
    };
  },
  props: {
    disponibilita: Array,
    slotDisponibilita: {
      type: Object,
      defualt: function() {
        return { appuntamento: { identificativoAppuntamento: "" } };
      },
    },
    codicePrestazione: String,
    descrizionePrestazione: String,
    costo: Number,
    idAgenda: String,
  },
  methods: {
    updateData() {
      let me = this;
      me.$emit("update");
    },
    getIndexDisponibilita(disponibilita, index) {
      return "agenda_" + disponibilita.dataRiferimento + "_" + index;
    },
    getIndex(index) {
      return "agenda_" + index;
    },
    getDayByDate(value) {
      let date = moment(value, "YYYY-MM-DD");
      return date.format("DD");
    },
    getWeekDayByDate(value) {
      let date = moment(value, "YYYY-MM-DD");
      return date.format("dddd");
    },
    getMonthByDate(value) {
      let date = moment(value, "YYYY-MM-DD");
      return date.format("MMM");
    },
    getYearByDate(value) {
      let date = moment(value, "YYYY-MM-DD");
      return date.format("YYYY");
    },
    descrizione(value) {
      let descrizione;
      if (value.appuntamento) {
        descrizione = value.titolo + " (" + value.codicePrestazione + "-" + value.descrizionePrestazione + ")";
      } else {
        descrizione = "Disponibile";
      }
      return descrizione;
    },
  },
};
</script>
<style lang="css">
.sa-agenda-view {
  display: inline-flex;
  flex: 1 0 100%;
  align-items: stretch;
  max-width: 100%;
  font-size: 14px;
}
.sa-panel-grid {
  width: 100%;
  flex-direction: column;
  overflow-y: scroll;
}
.sa-agenda-day-row {
  display: flex;
  flex-direction: row;
  background-color: transparent;
  width: 100%;
  min-height: 165px;
  border-bottom: 1px solid #dddddd;
}
.sa-agenda-day {
  display: flex;
  flex-grow: 1;
  flex: 1 1 100%;
  padding: 0.5em 0;
  align-items: flex-start;
  color: #606060;
}

.sa-agenda-day-date {
  display: flex;
  flex-flow: row nowrap;
  flex: 0 0 10em;
  padding: 0 0.5em;
  align-items: center;
  height: 2.6em;
  margin-top: 0.2em;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}
.sa-agenda-day-date-number {
  margin: 0 0.1em 0 0;
  font-size: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
}
.sa-agenda-day-date-text {
  box-sizing: border-box;
  transition: none;
  white-space: nowrap;
}
.sa-agenda-day-events {
  flex: 1;
  flex-flow: column;
  align-items: flex-start;
  box-sizing: border-box;
}
.sa-agenda-td-intervallo {
  text-align: left;
  vertical-align: top;
  width: 6.5em;
}
.sa-agenda-td-descrizione {
  min-width: 12em;
  color: white;
  text-align: left;
  padding: 0.3em;
  font-size: 0.9em;
  height: 2em;
}
.sa-agenda-td-descrizione-content {
  border-radius: 0.3rem;
  background-color: #27ca37;
  height: 100%;
  vertical-align: middle;
  display: table-cell;
  padding: 0.3rem;
  white-space: nowrap;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sa-agenda-table-events {
  width: 100%;
  table-layout: fixed;
}

.sa-agenda-table-events td {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/*.sa-agenda-td-descrizione:focus-visible {
    border: none;
}*/
/*

.sa-agenda-day-row {
    height: 165px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    position: absolute;
    min-width: 100%;
    transition: background-color 0.2s;
    left: 0;
    overflow: hidden;
    border-bottom: 1px solid #e9eaeb;
    contain: layout;
    box-sizing: border-box;
}
.sa-agenda-day {
}

.sa-agenda-day-date-number {
    margin: 0 0.1em 0 0;
    font-size: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
}
.sa-agenda-day-date-text {
    box-sizing: border-box;
}*/

@media (max-width: 700px) {
  .sa-agenda-mobile-grid-cell {
    font-size: 0.8em;
  }
}
</style>
