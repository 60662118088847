<template>
  <sa-page-layout :btnAnnullaVisible="true" :btnEditVisible="true" :linkback="linkback" :linkedit="linkedit">
    <!-- <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template> -->
    <template slot="table-body">
      <div class="mb-4">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <strong>{{ getLabelTraduora("paziente.questionari.lblDescription") }}:</strong>
            {{ jsonData.descrizioneQuestionario }}</b-col
          >
          <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
            <strong>{{ getLabelTraduora("paziente.questionari.lblCreateDate") }}:</strong>
            {{ formatDate(jsonData.create_date) }}
          </b-col>
          <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
            <strong>{{ getLabelTraduora("paziente.questionari.lblTotalQuestions") }}:</strong>
            {{ jsonData.questionarioDomandaBeans.length }}
          </b-col>

          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <strong>{{ getLabelTraduora("paziente.questionari.lblNotes") }}:</strong>
            {{ jsonData.testo }}</b-col
          >
        </b-row>
      </div>
      <hr />
      <div class="sa-tab-scroll">
        <div v-for="item in domande" :key="item.id">
          <paziente-questionari-view-component :domanda="item"></paziente-questionari-view-component>
        </div>
      </div>
      <b-modal id="avviaQuestionario" scrollable :title="jsonData.descrizioneQuestionario" size="xl">
        <div v-for="item in domande" :key="item.id">
          <paziente-questionari-edit-answer :domanda="item"></paziente-questionari-edit-answer>
        </div>
        <template #modal-footer="{ salvaRisposte }">
          <b-button size="sm" variant="success" @click="salvaRisposte()">
            {{ getLabelTraduora("global.lblBtnSave") }}
          </b-button>
        </template>
      </b-modal>
      <b-modal id="assegnaPaziente" scrollable :title="jsonData.descrizioneQuestionario" size="xl">
        <div v-for="item in domande" :key="item.id">
          <paziente-questionari-edit-answer :domanda="item"></paziente-questionari-edit-answer>
        </div>
        <template #modal-footer="{ salvaRisposte }">
          <b-button size="sm" variant="success" @click="salvaRisposte()">
            {{ getLabelTraduora("global.lblBtnSave") }}
          </b-button>
        </template>
      </b-modal>
    </template>
  </sa-page-layout>
</template>
<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
// import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
import PazienteQuestionariViewComponent from "./PazienteQuestionariViewComponent.vue";
import PazienteQuestionariEditAnswer from "./PazienteQuestionariEditAnswer.vue";
import axios from "axios";
import UtilityMixin from "../../UtilityMixin";

import moment from "moment";
export default {
  mixins: [UtilityMixin],
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  components: {
    SaPageLayout,
    // PazienteAnagrafica,
    PazienteQuestionariViewComponent,
    PazienteQuestionariEditAnswer,
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    idEvento() {
      return this.$route.params.id;
    },
    // linkPatient: {
    //   get: function() {
    //     let returnValue = "";
    //     if (this.utente.gruppo) {
    //       if (this.utente.gruppo.menuGruppo) {
    //         if (this.utente.gruppo.menuGruppo.length > 1) {
    //           return "/paziente/view/" + this.idPaziente;
    //         } else {
    //           if (this.utente.gruppo.menuGruppo.length == 1) {
    //             if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
    //               return "/paziente/view/" + this.idPaziente;
    //             } else {
    //               return "/paziente/homepaziente";
    //             }
    //           }
    //         }
    //       }
    //     }
    //     return returnValue;
    //   },
    // },
  },
  data() {
    return {
      linkback: "/questionari",
      linkedit: "/questionari/edit/",
      pathResource: "/questionario",
      domande: [],
      jsonData: null,
    };
  },
  mounted() {
    let me = this;
    me.linkedit = me.linkedit + me.idEvento;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.idEvento;
      axios
        .get(link)
        .then((res) => {
          me.jsonData = res.data.data;

          me.domande = me.jsonData.questionarioDomandaBeans;
          console.log(me.jsonData);
          me.showModalLoading = false;
        })
        .catch();
    },

    formatDate(data) {
      return moment(new Date(data)).format("DD-MM-YYYY HH:mm:ss");
    },
    salvaRisposte() {},
  },
};
</script>
