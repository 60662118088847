<template>
  <sa-page-layout :showModalLoading="showModalLoading" :btnSaveVisible="true" :btnAnnullaVisible="true" :btnBackVisible="false" :linkback="linkback" :pathResource="pathResource" :data="jsonData">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-header">
      <h5 class="sa-text-left">Dati</h5>
    </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <!-- InizioBody  -->
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><h5>{{ getTranslate("patient.cardiology.lblBasicData") }}</h5></b-col
          >
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblWeight") }}</span
            ><br />
            <b-form-input v-model="jsonData.peso" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblHeight") }}</span
            ><br />
            <b-form-input v-model="jsonData.altezza" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblBMI") }}</span
            ><br />
            <b-form-input v-model="jsonData.bmi" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblBSA") }}</span
            ><br />
            <b-form-input v-model="jsonData.bsa" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPAS") }}</span
            ><br />
            <b-form-input v-model="jsonData.pas" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblFC") }}</span
            ><br />
            <b-form-input v-model="jsonData.fc" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblRightLowerLimbSystolic") }}</span
            ><br />
            <b-form-input v-model="jsonData.artoInferioreDxPas" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblRightLW") }}</span
            ><br />
            <b-form-input v-model="jsonData.lwDestro" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLeftLowerLimbSystolic") }}</span
            ><br />
            <b-form-input v-model="jsonData.artoInferioreSxPas" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLeftLW") }}</span
            ><br />
            <b-form-input v-model="jsonData.lwSinistro" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><h5>{{ getTranslate("patient.cardiology.lblLeftLowerLimbECD") }}</h5></b-col
          ><b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><h5>{{ getTranslate("patient.cardiology.lblRightLowerLimbECD") }}</h5></b-col
          ><b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><h5>{{ getTranslate("patient.cardiology.lblReportNotes") }}</h5></b-col
          ><b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblProtocol") }}</span
            ><br />
            <b-form-input v-model="jsonData.protocollo" type="text"></b-form-input></b-col
          ><b-col xs="12" sm="6" md="6" lg="6" xl="6"
            ><span>{{ getTranslate("patient.cardiology.lblVideotape") }}</span
            ><br />
            <b-form-input v-model="jsonData.videotape" type="text"></b-form-input></b-col
          ><b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><h5>{{ getTranslate("patient.cardiology.lblCardiovascularRiskFactors") }}</h5></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblHypertension") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.ipertensione" switch :checked="jsonData.ipertensione"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblDyslipidemia") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.dislipidemia" switch :checked="jsonData.dislipidemia"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblDiabetes") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.diabete" switch :checked="jsonData.diabete"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblSmoking") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.fumo" switch :checked="jsonData.fumo"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblObesity") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.obesita" switch :checked="jsonData.obesita"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblHypertensionFamiliarity") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.ipertensioneFamiliarita" switch :checked="jsonData.ipertensioneFamiliarita"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblIschemicHeartDiseaseFamiliarity") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.cardiopatiaIschemicaFamiliarita" switch :checked="jsonData.cardiopatiaIschemicaFamiliarita"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblCardiovascularAccidentsFamiliarity") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.accidentiCerebrovascolariFamiliarita" switch :checked="jsonData.accidentiCerebrovascolariFamiliarita"></b-form-checkbox
          ></b-col>
        </b-row>
        <b-row
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAiC75") }}</span
            ><br />
            <b-form-input v-model="jsonData.aic75" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPASC") }}</span
            ><br />
            <b-form-input v-model="jsonData.pasc" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPADC") }}</span
            ><br />
            <b-form-input v-model="jsonData.padc" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPPC") }}</span
            ><br />
            <b-form-input v-model="jsonData.ppc" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><span>{{ getTranslate("patient.cardiology.lblNotes") }}</span
            ><br />
            <b-form-input v-model="jsonData.note" type="text"></b-form-input></b-col
          ><b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><span>{{ getTranslate("patient.cardiology.lblComments") }}</span
            ><br />
            <b-form-input v-model="jsonData.commento" type="text"></b-form-input></b-col
          ><b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><h5>{{ getTranslate("patient.cardiology.lblConclusions") }}</h5></b-col
          >
        </b-row>
        <b-row>
          <b-col xs="12" sm="3" md="3" lg="3" xl="3"
            ><span>{{ getTranslate("patient.cardiology.lblExamInTheNorm") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.esameNorma" switch :checked="jsonData.esameNorma"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="3" md="12" lg="3" xl="3"
            ><span>{{ getTranslate("patient.cardiology.lblSignificantStenosis") }}</span
            ><br />
            <b-form-input v-model="jsonData.esameNormaStenosi" type="text"></b-form-input></b-col
          ><b-col xs="12" sm="3" md="3" lg="3" xl="3"
            ><span>{{ getTranslate("patient.cardiology.lblLeft") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.esameNormaSx" switch :checked="jsonData.esameNormaSx"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="3" md="3" lg="3" xl="3"
            ><span>{{ getTranslate("patient.cardiology.lblRight") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.esameNormaDx" switch :checked="jsonData.esameNormaDx"></b-form-checkbox
          ></b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="3" md="3" lg="3" xl="3"
            ><span>{{ getTranslate("patient.cardiology.lblMildAtherosclerosis") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.aterosclerosiLieve" switch :checked="jsonData.aterosclerosiLieve"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="3" md="12" lg="3" xl="3"
            ><span>{{ getTranslate("patient.cardiology.lblSignificantStenosis") }}</span
            ><br />
            <b-form-input v-model="jsonData.aterosclerosiLieveStenosi" type="text"></b-form-input></b-col
          ><b-col xs="12" sm="3" md="3" lg="3" xl="3"
            ><span>{{ getTranslate("patient.cardiology.lblLeft") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.aterosclerosiLieveSx" switch :checked="jsonData.aterosclerosiLieveSx"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="3" md="3" lg="3" xl="3"
            ><span>{{ getTranslate("patient.cardiology.lblRight") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.aterosclerosiLieveDx" switch :checked="jsonData.aterosclerosiLieveDx"></b-form-checkbox
          ></b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="3" md="3" lg="3" xl="3"
            ><span>{{ getTranslate("patient.cardiology.lblModerateAtherosclerosis") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.aterosclerosiModerata" switch :checked="jsonData.aterosclerosiModerata"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="3" md="12" lg="3" xl="3"
            ><span>{{ getTranslate("patient.cardiology.lblSignificantStenosis") }}</span
            ><br />
            <b-form-input v-model="jsonData.aterosclerosiModerataStenosi" type="text"></b-form-input></b-col
          ><b-col xs="12" sm="3" md="3" lg="3" xl="3"
            ><span>{{ getTranslate("patient.cardiology.lblLeft") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.aterosclerosiModerataSx" switch :checked="jsonData.aterosclerosiModerataSx"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="3" md="3" lg="3" xl="3"
            ><span>{{ getTranslate("patient.cardiology.lblRight") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.aterosclerosiModerataDx" switch :checked="jsonData.aterosclerosiModerataDx"></b-form-checkbox
          ></b-col>
        </b-row>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  components: { SaPageLayout, PazienteAnagrafica },
  data() {
    return {
      linkback: null,
      pathResource: "/cardiodopplerartiinferiori",
      jsonData: {},
      showModalLoading: false,
      opzioniSegmenti: [
        { text: "Acinetico/a", value: "acinetic" },
        { text: "Aneurismatico/a", value: "aneurysmatic" },
        { text: "Discinetico/a", value: "dyskinetic" },
        { text: "Ipocinetico/a", value: "hypokinetic" },
        { text: "Normocinetico", value: "normokinetic" },
      ],
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient: {
      get: function() {
        if (this.utente.gruppo.menuGruppo) {
          if (this.utente.gruppo.menuGruppo.length > 1) {
            return "/paziente/view/" + this.idPaziente;
          } else {
            if (this.utente.gruppo.menuGruppo.length == 1) {
              if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                return "/paziente/view/" + this.idPaziente;
              } else {
                return "/paziente/homepaziente";
              }
            } else {
              return "";
            }
          }
        } else {
          return "";
        }
      },
    },
  },
  mounted() {
    let me = this;
    me.jsonData.idPaziente = this.$route.query.idPaziente;
    me.jsonData.tipoEvento = "Cardiologia - Ecodoppler Arti Inferiori";
    me.jsonData.idAmbulatorio = sessionStorage.getItem("idAmbulatorio");

    me.id = this.$route.params.id;
    me.linkedit = "/paziente/cardiologia/dopplerartiinferiori/edit/" + me.id + "?idPaziente=" + me.idPaziente;
    if (me.id !== "-1") {
      me.linkback = "/paziente/cardiologia/dopplerartiinferiori/view/" + me.id + "?idPaziente=" + me.idPaziente;
      me.loadData();
    } else {
      me.linkback = "/paziente/cardiologia/dopplerartiinferiori?idPaziente=" + me.idPaziente;
    }
  },

  methods: {
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        });
      }
    },
    getTranslate(text) {
      return this.$i18n.t(text);
    },
  },
};
</script>
