<template>
    <sa-page-layout :titolo="titolo" :showModalLoading="showModalLoading">
        <template slot="toolbar">
            <b-button class="float-sm-right " variant="success btn-toolbar sa-margin-right" size="sm" @click="onAdd"><b-icon icon="plus"></b-icon>Nuovo</b-button>
            <b-button class="float-sm-right sa-btn-header sa-btn-icon-text" variant="light sa-margin-right" size="sm" @click="onRefresh" v-b-modal.modal-center><b-icon icon="arrow-clockwise" variant="secondary" />Refresh</b-button>
        </template>
        <template slot="table-filter"> </template>
        <template slot="table-header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>Totale Record: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <b-tabs content-class="mt-3" style="height: 100%;">
                <b-tab title="Elenco Parametri" style="height: 100%; padding-bottom: 60px" active>
                    <div class="b-table-sticky-header">
                        <b-table class="sa-b-table" ref="table" id="tblParams" :filter="filter" stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light">
                            <template #cell(nome)="{ item }">
                                <router-link class="sa-edit-link" :to="'/configparameter/edit/' + item.id">{{ item.nome }}</router-link>
                            </template>
                        </b-table>
                    </div>
                </b-tab>
                <b-tab title="Parametri Caricati" class="sa-tab">
                    <div class="sa-tab-scroll">
                        <b-row>
                            <b-col cols="12" md="12">
                                <pre>Parametri in Memoria</pre>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" md="12">
                                <pre style="display: inline-block; width: 100%; white-space: pre-wrap;">{{ loadedParameter }}</pre>
                            </b-col>
                        </b-row>
                    </div>
                </b-tab>
            </b-tabs>
        </template>
        <template slot="table-footer">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>Totale Record: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { SaPageLayout },
    computed: {
        rows() {
            return this.items.length;
        },
    },
    data() {
        return {
            titolo: "Parametri",
            showModalLoading: false,
            currentPage: 1,
            perPage: 30,
            filter: "",
            isBusy: false,
            pathRest: "/configparameter",
            fields: [
                {
                    label: "Nome",
                    key: "nome",
                    sortable: true,
                    thStyle: "width: 12rem",
                },
                {
                    label: "Parametro",
                    key: "parametro",
                    sortable: true,
                },
                {
                    label: "Note",
                    key: "note",
                    sortable: true,
                },
                {
                    label: "Tipo",
                    key: "tipo",
                    sortable: true,
                    thStyle: "width: 6rem",
                },
                { class: "sa-table-column-action-single", key: "actions", label: "", thStyle: "width: 8rem" },
            ],
            items: [],
            loadedParameter: {},
            data: [],
            filtro: {},
        };
    },
    created: function() {},
    mounted: function() {
        this.loadData();
    },
    update: function() {
        console.log("Update");
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathRest;
            me.isBusy = true;
            me.showModalLoading = true;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(function(error) {
                    me.showModalLoading = false;
                    me.$bvToast.toast(error.response.data.messaggio, {
                        title: "Sorveglianza Covid Admin",
                        variant: "danger",
                        solid: true,
                    });
                });

            axios
                .get(link + "/loaded", { params: me.filtro })
                .then((response) => {
                    me.itemsLoaded = [];
                    me.loadedParameter = response.data.data;
                    // me.itemsLoaded = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(function(error) {
                    me.showModalLoading = false;
                    me.$bvToast.toast(error.response.data.messaggio, {
                        title: "Sorveglianza Covid Admin",
                        variant: "danger",
                        solid: true,
                    });
                });
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onAdd() {
            let me = this;
            me.$router.replace(me.pathRest + "/edit/-1").catch((err) => {
                err;
            });
        },
        onSubmit: function() {
            let me = this;
            me.loadData();
            return false;
        },
    },
};
</script>
