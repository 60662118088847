<template>
  <sa-page-layout :btnAnnullaVisible="true" :btnSaveVisible="true" :data="data" :linkback="linkback" :linkedit="linkedit" :pathResource="pathResource" :showModalLoading="showModalLoading">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="toolbar-row">
      <paziente-dashboard-component :idPaziente="idPaziente"></paziente-dashboard-component>
    </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <b-card class="mb-4">
              <b-card-body class="text-center">
                <b-row>
                  <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <h4 class="text-left">
                      {{ getLabelTraduora("patient.diet.lblDietType") }}
                      <span style="color: red;">*</span
                      ><a data-toggle="modal" data-target="#collapse-info" href="#collapse-info" @click="showModal()" onclick="return false"><b-icon icon="info-circle" font-scale="1"></b-icon></a>
                    </h4>
                    <div>
                      <b-form-select v-model="data.tipo" value-field="value" text-field="text" :options="tipiDieta" @change="setDescription(data.tipo)" :state="statoRisposta"></b-form-select>
                    </div>
                    <b-modal id="collapse-info" centered :title="nomeDieta" size="lg" v-if="data.descrizione !== null" hide-footer>
                      <b-row class="text-justify" style="padding-left:10px; padding-right:10px;"
                        ><b-col cols="12"
                          ><span>{{ data.descrizione }}</span></b-col
                        >
                      </b-row>
                      <hr />
                      <div style="height: 30px;"></div>
                    </b-modal>
                    <b-modal id="collapse-edit" centered :title="nomeDieta" size="lg" v-if="data.descrizione !== null">
                      <b-form-textarea v-model="data.descrizione" rows="9"></b-form-textarea>
                    </b-modal>
                  </b-col>
                  <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <h4 class="text-left">{{ getLabelTraduora("patient.diet.lblStartDate") }} <span style="color: red;">*</span></h4>
                    <div class="red-border" id="date-picker-1">
                      <date-picker
                        @change="check(data.dataInizio, 'date-picker-1')"
                        format="DD-MM-YYYY"
                        value-type="timestamp"
                        v-model="data.dataInizio"
                        type="datetime"
                        :placeholder="getLabelTraduora('patient.diet.lblStartDate')" 
                        :disabled-date="(date) => date < new Date() - 86400000"
                      ></date-picker></div
                  ></b-col>
                  <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <h4 class="text-left">{{ getLabelTraduora("therapy.lblEndDate") }} </h4>
                    <div id="date-picker-2">
                      <date-picker
                        format="DD-MM-YYYY"
                        @change="check(data.dataFine, 'date-picker-2')"
                        value-type="timestamp"
                        v-model="data.dataFine"
                        type="datetime"
                        :placeholder="getLabelTraduora('therapy.lblEndDate')"
                        :disabled-date="(date) => date < new Date() - 86400000"
                      ></date-picker></div
                  ></b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <h4 class="text-left">{{ getLabelTraduora("patient.diet.lblNote") }}</h4>
                    <b-form-textarea v-model="data.note" :placeholder="getLabelTraduora('patient.diet.lblNote')" no-resize></b-form-textarea>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <b-card class="mb-4">
              <span><b>{{getLabelTraduora("patient.diet.lblDietRoutine")}}</b> </span>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="4">
            <b-card>
              <b-card-title style="width: 100%">
                <b-row>
                  <b-col cols="4">
                    <label for="">
                      <img src="@/assets/images/users/breakfast.svg" alt="" width="85px /" />
                    </label>
                  </b-col>
                  <b-col cols="8">
                    <label for="">
                      <h4 class="sa-card-title-position">{{ getLabelTraduora("patient.diet.lblBreakfast") }}</h4>
                    </label>
                  </b-col>
                </b-row>
              </b-card-title>
              <b-card-body>
                <b-row v-for="(item, index) in breakfastData" :key="index">
                  <b-col cols="4">
                    <label>
                      <p class="text-muted">{{ item.pietanza }}</p>
                    </label>
                  </b-col>
                  <b-col cols="3">
                    <strong>{{ getLabelTraduora("patient.diet.lblQuantity") }}</strong> {{ item.quantita }} ({{ item.unitaMisura }})
                  </b-col>
                  <b-col cols="3">
                    <strong>{{ getLabelTraduora("patient.diet.lblKcal") }}:</strong> {{ item.kiloCal }}
                  </b-col>
                  <b-col cols="2">
                    <b-button id="btnDelete" size="sm" variant="btn btn-outline-danger no-text" @click="onDelete('BREAKFAST', item)"><b-icon icon="trash"></b-icon></b-button>
                  </b-col>
                </b-row>
              </b-card-body>
              <b-button id="btnBreakfast" class="btn btn-block btn--md btn-light waves-effect waves-light btn-card-bottom" @click="onAddNewDish('BREAKFAST')"
                ><b-icon icon="plus" variant="primary"></b-icon>{{ getLabelTraduora("global.lblAdd") }}</b-button
              >
            </b-card>
          </b-col>

          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="4">
            <b-card>
              <b-card-title style="width: 100%">
                <b-row>
                  <b-col cols="4">
                    <label for="">
                      <img src="@/assets/images/users/apple.svg" alt="" width="85px /" style="float:right" />
                    </label>
                  </b-col>
                  <b-col cols="8">
                    <label for="">
                      <h4 class="sa-card-title-position">{{ getLabelTraduora("patient.diet.lblMorningSnack") }}</h4>
                    </label>
                  </b-col>
                </b-row>
              </b-card-title>
              <b-card-body>
                <b-row v-for="(item, index) in morningSnackData" :key="index">
                  <b-col cols="4">
                    <label>
                      <p class="text-muted">{{ item.pietanza }}</p>
                    </label>
                  </b-col>
                  <b-col cols="3">
                    <strong>{{ getLabelTraduora("patient.diet.lblQuantity") }}:</strong> {{ item.quantita }} ({{ item.unitaMisura }})</b-col
                  >
                  <b-col cols="3">
                    <strong>{{ getLabelTraduora("patient.diet.lblKcal") }}:</strong> {{ item.kiloCal }}
                  </b-col>
                  <b-col cols="2">
                    <b-button id="btnDelete" size="sm" variant="btn btn-outline-danger no-text" @click="onDelete('MORNINGSNACK', item)"><b-icon icon="trash"></b-icon></b-button>
                  </b-col>
                </b-row>
              </b-card-body>
              <b-button id="btnMorningSnack" class="btn btn-block btn--md btn-light waves-effect waves-light btn-card-bottom" @click="onAddNewDish('MORNINGSNACK')"
                ><b-icon icon="plus" variant="primary"></b-icon>{{ getLabelTraduora("global.lblAdd") }}</b-button
              >
            </b-card>
          </b-col>

          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="4">
            <b-card>
              <b-card-title style="width: 100%">
                <b-row>
                  <b-col cols="4">
                    <label for="">
                      <img src="@/assets/images/users/lunch-bag.svg" alt="" width="85px /" />
                    </label>
                  </b-col>
                  <b-col cols="8">
                    <label for="">
                      <h4 class="sa-card-title-position">{{ getLabelTraduora("patient.diet.lblLunch") }}</h4>
                    </label>
                  </b-col>
                </b-row>
              </b-card-title>
              <b-card-body>
                <b-row v-for="(item, index) in lunchData" :key="index">
                  <b-col cols="4">
                    <label>
                      <p class="text-muted">{{ item.pietanza }}</p>
                    </label>
                  </b-col>
                  <b-col cols="3">
                    <strong>{{ getLabelTraduora("patient.diet.lblQuantity") }}:</strong> {{ item.quantita }} ({{ item.unitaMisura }})</b-col
                  >
                  <b-col cols="3">
                    <strong>{{ getLabelTraduora("patient.diet.lblKcal") }}:</strong> {{ item.kiloCal }}
                  </b-col>
                  <b-col cols="2">
                    <b-button id="btnDelete" size="sm" variant="btn btn-outline-danger no-text" @click="onDelete('LUNCH', item)"><b-icon icon="trash"></b-icon></b-button>
                  </b-col>
                </b-row>
              </b-card-body>
              <b-button id="btnLunch" class="btn btn-block btn--md btn-light waves-effect waves-light btn-card-bottom" @click="onAddNewDish('LUNCH')"
                ><b-icon icon="plus" variant="primary"></b-icon>{{ getLabelTraduora("global.lblAdd") }}</b-button
              >
            </b-card>
          </b-col>

          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="4">
            <b-card>
              <b-card-title style="width: 100%">
                <b-row>
                  <b-col cols="4">
                    <label for="">
                      <img src="@/assets/images/users/energy-food.svg" alt="" width="85px /" />
                    </label>
                  </b-col>
                  <b-col cols="8">
                    <label for="">
                      <h4 class="sa-card-title-position">{{ getLabelTraduora("patient.diet.lblAfternoonSnack") }}</h4>
                    </label>
                  </b-col>
                </b-row>
              </b-card-title>
              <b-card-body>
                <b-row v-for="(item, index) in afternoonSnackData" :key="index">
                  <b-col cols="4">
                    <label>
                      <p class="text-muted">{{ item.pietanza }}</p>
                    </label>
                  </b-col>
                  <b-col cols="3">
                    <strong>{{ getLabelTraduora("patient.diet.lblQuantity") }}:</strong> {{ item.quantita }} ({{ item.unitaMisura }})</b-col
                  >
                  <b-col cols="3">
                    <strong>{{ getLabelTraduora("patient.diet.lblKcal") }}:</strong> {{ item.kiloCal }}
                  </b-col>
                  <b-col cols="2">
                    <b-button id="btnDelete" size="sm" variant="btn btn-outline-danger no-text" @click="onDelete('AFTERNOONSNACK', item)"><b-icon icon="trash"></b-icon></b-button>
                  </b-col>
                </b-row>
              </b-card-body>
              <b-button id="btnAfternoonSnack" class="btn btn-block btn--md btn-light waves-effect waves-light btn-card-bottom" @click="onAddNewDish('AFTERNOONSNACK')"
                ><b-icon icon="plus" variant="primary"></b-icon>{{ getLabelTraduora("global.lblAdd") }}</b-button
              >
            </b-card>
          </b-col>

          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="4">
            <b-card>
              <b-card-title style="width: 100%">
                <b-row>
                  <b-col cols="4">
                    <label for="">
                      <img src="@/assets/images/users/dinner.svg" alt="" width="85px /" />
                    </label>
                  </b-col>
                  <b-col cols="8">
                    <label for="">
                      <h4 class="sa-card-title-position">{{ getLabelTraduora("patient.diet.lblDinner") }}</h4>
                    </label>
                  </b-col>
                </b-row>
              </b-card-title>
              <b-card-body>
                <b-row v-for="(item, index) in dinnerData" :key="index">
                  <b-col cols="4">
                    <label>
                      <p class="text-muted">{{ item.pietanza }}</p>
                    </label>
                  </b-col>
                  <b-col cols="3">
                    <strong>{{ getLabelTraduora("patient.diet.lblQuantity") }}:</strong> {{ item.quantita }} ({{ item.unitaMisura }})</b-col
                  >
                  <b-col cols="3">
                    <strong>{{ getLabelTraduora("patient.diet.lblKcal") }}:</strong> {{ item.kiloCal }}
                  </b-col>
                  <b-col cols="2">
                    <b-button id="btnDelete" size="sm" variant="btn btn-outline-danger no-text" @click="onDelete('DINNER', item)"><b-icon icon="trash"></b-icon></b-button>
                  </b-col>
                </b-row>
              </b-card-body>
              <b-button id="btnDinner" class="btn btn-block btn--md btn-light waves-effect waves-light btn-card-bottom" @click="onAddNewDish('DINNER')"
                ><b-icon icon="plus" variant="primary"></b-icon>{{ getLabelTraduora("global.lblAdd") }}</b-button
              >
            </b-card>
          </b-col>

          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="4">
            <b-card>
              <b-card-title style="width: 100%">
                <b-row>
                  <b-col cols="4">
                    <label for="">
                      <img src="@/assets/images/users/fruits.svg" alt="" width="85px /" />
                    </label>
                  </b-col>
                  <b-col cols="8">
                    <label for="">
                      <h4 class="sa-card-title-position">{{ getLabelTraduora("patient.diet.lblNightSnack") }}</h4>
                    </label>
                  </b-col>
                </b-row>
              </b-card-title>
              <b-card-body>
                <b-row v-for="(item, index) in nightSnackData" :key="index">
                  <b-col cols="4">
                    <label>
                      <p class="text-muted">{{ item.pietanza }}</p>
                    </label>
                  </b-col>
                  <b-col cols="3">
                    <strong>{{ getLabelTraduora("patient.diet.lblQuantity") }}:</strong> {{ item.quantita }} ({{ item.unitaMisura }})</b-col
                  >
                  <b-col cols="3">
                    <strong>{{ getLabelTraduora("patient.diet.lblKcal") }}:</strong> {{ item.kiloCal }}
                  </b-col>
                  <b-col cols="2">
                    <b-button id="btnDelete" size="sm" variant="btn btn-outline-danger no-text" @click="onDelete('NIGHTSNACK', item)"><b-icon icon="trash"></b-icon></b-button>
                  </b-col>
                </b-row>
              </b-card-body>
              <b-button id="btnNightSnack" class="btn btn-block btn--md btn-light waves-effect waves-light btn-card-bottom" @click="onAddNewDish('NIGHTSNACK')"
                ><b-icon icon="plus" variant="primary"></b-icon>{{ getLabelTraduora("global.lblAdd") }}</b-button
              >
            </b-card>
          </b-col>

          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="4">
            <b-card>
              <b-card-title style="width: 100%">
                <b-row>
                  <b-col cols="4">
                    <label for="">
                      <img src="@/assets/images/users/salt.svg" alt="" width="85px /" />
                    </label>
                  </b-col>
                  <b-col cols="8">
                    <label for="">
                      <h4 class="sa-card-title-position">{{ getLabelTraduora("patient.diet.lblSalt") }}</h4>
                    </label>
                  </b-col>
                </b-row>
              </b-card-title>
              <b-card-body>
                <b-row v-for="(item, index) in saltData" :key="index">
                  <b-col cols="4">
                    <label>
                      <p class="text-muted">{{ item.pietanza }}</p>
                    </label>
                  </b-col>
                  <b-col cols="3">
                    <strong>{{ getLabelTraduora("patient.diet.lblQuantity") }}:</strong> {{ item.quantita }} ({{ item.unitaMisura }})</b-col
                  >
                  <b-col cols="3">
                    <strong>{{ getLabelTraduora("patient.diet.lblKcal") }}:</strong> {{ item.kiloCal }}
                  </b-col>
                  <b-col cols="2">
                    <b-button id="btnDelete" size="sm" variant="btn btn-outline-danger no-text" @click="onDelete('SALT', item)"><b-icon icon="trash"></b-icon></b-button>
                  </b-col>
                </b-row>
              </b-card-body>
              <b-button id="btnSalt" class="btn btn-block btn--md btn-light waves-effect waves-light btn-card-bottom" @click="onAddNewDish('SALT')"
                ><b-icon icon="plus" variant="primary"></b-icon>{{ getLabelTraduora("global.lblAdd") }}</b-button
              >
            </b-card>
          </b-col>
        </b-row>
      </div>
      <div>
        <b-modal id="mdlAggiungiPietanza" ref="mdlAggiungiPietanza" @ok="onOk">
          <b-row>
            <b-col>
              <h5 class="text-center">{{ getPasto(nuovaPietanza.pasto) }}</h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <span class="sa-form-label text-center">{{ getLabelTraduora("patient.diet.lblDish") }}</span>
              <b-form-input :value="nuovaPietanza.pietanza" v-model="nuovaPietanza.pietanza" placeholder="Pietanza"></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-form-label">{{ getLabelTraduora("patient.diet.lblQuantity") }}</span>
              <b-form-input type="number" v-model="nuovaPietanza.quantita" placeholder="Quantità"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <span class="sa-form-label">{{ getLabelTraduora("patient.diet.lblUnitOfMeasure") }}</span>
              <b-form-input v-model="nuovaPietanza.unitaMisura" placeholder="Unità di Misura"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-form-label">{{ getLabelTraduora("patient.diet.lblKcal") }}</span>
              <b-form-input type="number" v-model="nuovaPietanza.kiloCal" placeholder="Kcal"></b-form-input>
            </b-col>
          </b-row>
        </b-modal>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import PazienteAnagrafica from "./PazienteAnagrafica.vue";
import DatePicker from "vue2-datepicker";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteDashboardComponent from "./PazienteDashboardComponent.vue";
import UtilityMixin from "../../utility/UtilityMixin.js";

export default {
  mixins: [UtilityMixin],
  components: { PazienteAnagrafica, DatePicker, SaPageLayout, PazienteDashboardComponent },
  data() {
    return {
      showModalLoading: false,
      linkback: "/paziente/diete",
      linkedit: "",
      pathResource: "/dieta",
      statoRisposta: false,
      nuovaPietanza: { pasto: "", pietanza: "", quantita: "", unitaMisura: "", kiloCal: { type: Number } },
      breakfastData: [],
      morningSnackData: [],
      lunchData: [],
      descrizioneCustom: null,
      afternoonSnackData: [],
      dinnerData: [],
      nightSnackData: [],
      saltData: [],
      arraySelezionato: [],
      data: { pazienteDietaDettaglio: [] },
      nomeDieta: null,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: true,
      },
      tipiDieta: [],
    };
  },
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient: {
      get: function() {
        let returnValue = "";
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length > 1) {
              return "/paziente/view/" + this.idPaziente;
            } else {
              if (this.utente.gruppo.menuGruppo.length == 1) {
                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                  return "/paziente/view/" + this.idPaziente;
                } else {
                  return "/paziente/homepaziente";
                }
              }
            }
          }
        }
        return returnValue;
      },
    },
  },
  mounted: function() {
    let me = this;
    me.id = this.$route.params.id;
    me.linkback = "/paziente/diete?idPaziente=" + me.idPaziente;
    me.data.idPaziente = this.$route.query.idPaziente;
    if (!me.data.tipo) {
      me.data.tipo = null;
    }
    me.getTipiDieta();
    me.loadData();
  },
  methods: {
    check: function(input, id) {
      console.log(typeof input);
      if (typeof input == "number") {
        document.getElementById(id).classList.remove("red-border");
        document.getElementById(id).classList.add("green-border");
      } else {
        document.getElementById(id).classList.remove("green-border");
        document.getElementById(id).classList.add("red-border");
      }
    },
    disabledDates() {
      return new Date(Date.now() - 8640000);
    },
    showModal() {
      let me = this;
      this.getTipoDieta(me.data.tipo);
      if (me.data.tipo === "OTHER") {
        me.$bvModal.show("collapse-edit");
      } else {
        me.$bvModal.show("collapse-info");
      }
    },
    getTipiDieta() {
      let me = this;
      me.tipiDieta = [];
      let link = process.env.VUE_APP_PATH_API + "/pazientedietatipi";
      axios
        .get(link)
        .then((res) => {
          let arrayDiete = res.data.data;
          arrayDiete.forEach((element) => {
            let obj = { text: this.getLabelTraduora(element.nomeDieta), value: element.dieta, description: this.getLabelTraduora(element.descrizione) };
            me.tipiDieta.push(obj);
          });
        })
        .catch();
    },
    setDescription(tipo) {
      let me = this;
      if (me.descrizioneCustom === null) {
        me.descrizioneCustom = me.data.descrizione;
        // console.log(me.descrizioneCustom);
      }
      try {
        me.tipiDieta.forEach((item) => {
          if (tipo !== "OTHER") {
            if (tipo === item.value) {
              me.data.descrizione = item.description;
            }
          } else {
            me.data.descrizione = me.descrizioneCustom;
          }

          me.statoRisposta = true;
        });
      } catch (err) {
        console.log(err);
      }
    },
    getPasto(value) {
      let returnValue = "";
      switch (value) {
        case "BREAKFAST":
          returnValue = this.getLabelTraduora("patient.diet.lblBreakfast");
          break;
        case "MORNINGSNACK":
          returnValue = this.getLabelTraduora("patient.diet.lblMorningSnack");
          break;
        case "LUNCH":
          returnValue = this.getLabelTraduora("patient.diet.lblLunch");
          break;
        case "AFTERNOONSNACK":
          returnValue = this.getLabelTraduora("patient.diet.lblAfternoonSnack");
          break;
        case "DINNER":
          returnValue = this.getLabelTraduora("patient.diet.lblDinner");
          break;
        case "NIGHTSNACK":
          returnValue = this.getLabelTraduora("patient.diet.lblNightSnack");
          break;
        case "SALT":
          returnValue = this.getLabelTraduora("patient.diet.lblSalt");
          break;
      }
      return returnValue;
    },
    getTipoDieta(tipo) {
      let me = this;
      me.tipiDieta.forEach((element) => {
        if (element.value === tipo) {
          me.nomeDieta = null;
          me.nomeDieta = element.text;
        }
      });
    },
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        me.showModalLoading = true;
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
        axios.get(link + me.id).then((response) => {
          me.data = response.data.data;
          me.setDescription(me.data.tipo);
          me.getTipoDieta(me.data.tipo);
          me.data.pazienteDietaDettaglio.forEach((element) => {
            switch (element.pasto) {
              case "BREAKFAST":
                me.breakfastData.push(element);
                break;
              case "MORNINGSNACK":
                me.morningSnackData.push(element);
                break;
              case "LUNCH":
                me.lunchData.push(element);
                break;
              case "AFTERNOONSNACK":
                me.afternoonSnackData.push(element);
                break;
              case "DINNER":
                me.dinnerData.push(element);
                break;
              case "NIGHTSNACK":
                me.nightSnackData.push(element);
                break;
              case "SALT":
                me.saltData.push(element);
                break;
            }
          });
          me.showModalLoading = false;
        });
      }
    },
    onOk() {
      let me = this;
      let flag = true;
      me.arraySelezionato.forEach((element) => {
        if (element.pietanza === me.nuovaPietanza.pietanza) {
          flag = false;
          element.quantita = parseInt(element.quantita) + parseInt(me.nuovaPietanza.quantita);
          element.kiloCal = parseInt(element.kiloCal) + parseInt(me.nuovaPietanza.kiloCal);
          return;
        }
      });
      if (flag) {
        me.arraySelezionato.push(me.nuovaPietanza);
      }
      me.updateData();
    },
    onDelete(pasto, dettaglio) {
      let me = this;
      me.setSelectedArray(pasto);
      let deleteIndex = -1;
      me.arraySelezionato.forEach((element, index) => {
        if (element.pietanza === dettaglio.pietanza) {
          deleteIndex = index;
        }
      });
      me.arraySelezionato.splice(deleteIndex, 1);
      switch (pasto) {
        case "BREAKFAST":
          me.breakfastData = me.arraySelezionato;
          console.log(me.breakfastData);
          break;
        case "MORNINGSNACK":
          me.morningSnackData = me.arraySelezionato;
          console.log(me.morningSnackData);
          break;
        case "LUNCH":
          me.lunchData = me.arraySelezionato;
          console.log(me.lunchData);
          break;
        case "AFTERNOONSNACK":
          me.afternoonSnackData = me.arraySelezionato;
          console.log(me.afternoonSnackData);
          break;
        case "DINNER":
          me.dinnerData = me.arraySelezionato;
          console.log(me.dinnerData);
          break;
        case "NIGHTSNACK":
          me.nightSnackData = me.arraySelezionato;
          console.log(me.nightSnackData);
          break;
        case "SALT":
          me.saltData = me.arraySelezionato;
          console.log(me.saltData);
          break;
      }
      me.updateData();
    },
    onAddNewDish(value) {
      let me = this;
      me.nuovaPietanza = {};
      me.setSelectedArray(value);
      me.nuovaPietanza.pasto = value;
      me.$refs.mdlAggiungiPietanza.show();
      me.updateData();
    },

    setSelectedArray(key) {
      let me = this;
      switch (key) {
        case "BREAKFAST":
          me.arraySelezionato = me.breakfastData;
          break;
        case "MORNINGSNACK":
          me.arraySelezionato = me.morningSnackData;
          break;
        case "LUNCH":
          me.arraySelezionato = me.lunchData;
          break;
        case "AFTERNOONSNACK":
          me.arraySelezionato = me.afternoonSnackData;
          break;
        case "DINNER":
          me.arraySelezionato = me.dinnerData;
          break;
        case "NIGHTSNACK":
          me.arraySelezionato = me.nightSnackData;
          break;
        case "SALT":
          me.arraySelezionato = me.saltData;
          break;
      }
    },
    updateData() {
      let me = this;
      me.data.pazienteDietaDettaglio = [];
      me.breakfastData.forEach((element) => {
        me.data.pazienteDietaDettaglio.push(element);
      });
      me.morningSnackData.forEach((element) => {
        me.data.pazienteDietaDettaglio.push(element);
      });
      me.lunchData.forEach((element) => {
        me.data.pazienteDietaDettaglio.push(element);
      });
      me.afternoonSnackData.forEach((element) => {
        me.data.pazienteDietaDettaglio.push(element);
      });
      me.dinnerData.forEach((element) => {
        me.data.pazienteDietaDettaglio.push(element);
      });
      me.nightSnackData.forEach((element) => {
        me.data.pazienteDietaDettaglio.push(element);
      });
      me.saltData.forEach((element) => {
        me.data.pazienteDietaDettaglio.push(element);
      });
      // console.log(me.data.pazienteDietaDettaglio);
    },
  },
};
</script>
<style lang="css" scoped>
.btn-card-bottom {
  position: relative;
  top: 30px;
}
.sa-card-title-position {
  margin-top: 40px;
  margin-left: 5px;
}
.red-border {
  border: 1px solid #dc3545;
  border-radius: 5px;
}
.green-border {
  border: 1px solid #28a745;
  border-radius: 5px;
}
</style>
