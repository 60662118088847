<template>
    <sa-page-view-edit ref="pgaView" :data="data" :pathResource="pathResource" :backVisible="true" :saveVisible="true" :titolo="data.username" :linkback="linkback" :linkedit="linkedit" :showmolalloading="showmolalloading">
        
        <template slot="body">
            <b-row>
                <b-col cols="12" sm="2" class="sa-text-right">Cognome:</b-col>
                <b-col cols="12" sm="4">
                    <b-form-input v-model="data.lastname" placeholder="Cognome"></b-form-input>
                </b-col>
                <b-col cols="12" md="2" class="sa-text-right">Nome:</b-col>
                <b-col cols="12" md="4">
                    <b-form-input v-model="data.firstname" placeholder="Nome"></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" md="2" class="sa-text-right">Username:</b-col>
                <b-col cols="12" md="4">
                    <b-form-input v-model="data.username" placeholder="Username"></b-form-input>
                </b-col>
                <b-col cols="12" md="2" class="sa-text-right">E-Mail:</b-col>
                <b-col cols="12" md="4">
                    <b-form-input type="email" :state="null" v-model="data.email" placeholder="E-Mail"></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" md="2" class="sa-text-right" >Codice Fiscale:</b-col>
                <b-col cols="12" md="4">
                    <b-form-input maxlength="16" v-model="data.codiceFiscale" placeholder="Codice Fiscale"></b-form-input>
                </b-col>
                <b-col cols="12" md="2" class="sa-text-right">Scadenza:</b-col>
                <b-col cols="12" md="4">
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.scadenzaUtenza" type="date"></date-picker>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" md="2" class="sa-text-right">Password:</b-col>
                <b-col cols="12" md="4">
                    <b-form-input type="password" v-model="data.password" placeholder="Password"></b-form-input>
                </b-col>
                <b-col cols="12" md="2" class="sa-text-right">Verifica Password:</b-col>
                <b-col cols="12" md="4">
                    <b-form-input type="password" v-model="verificaPassword" placeholder="Verifica Password"></b-form-input>
                </b-col>
            </b-row>
        </template>
    </sa-page-view-edit>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import { SaPageViewEdit } from "../../../template/sa/components/layout/contents";
export default {
    components: { SaPageViewEdit, DatePicker },
    data() {
        return {
            pathResource: "/sorveglianzarefertiutenti",
            linkback: "/sorveglianzarefertiutenti",
            linkedit: null,
            showmolalloading: false,
            id: "-1",
            profili: [],
            verificaPassword: "",
            data: { },
        };
    },
    computed: {
        dataScadenzaUtenza: {
            get: function() {
                return new Date(this.data.scadenzaUtenza);
            },
            set: function(value) {
                this.data.scadenzaUtenza = moment(String(value)).valueOf();
            },
        },
    },
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadDefaultData();
    },
    methods: {
        loadDefaultData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.profili.push({ value: element.nome, text: element.nome });
                });
                me.loadData();
            });
        },
        loadData() {
            let me = this;

            //console.log(me.id);
            if (me.id === "-1") {
                me.showmolalloading = false;
            } else {
                me.showmolalloading = true;
                let link = process.env.VUE_APP_PATH_API + me.pathResource;
                axios.get(link + me.id).then((response) => {
                    me.data = response.data.data;
                    me.showmolalloading = false;
                });
            }
        },
        onSave() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            let jsonData = JSON.stringify(me.data);

            me.showmolalloading = true;
            if (me.id == "-1") {
                axios
                    .post(link, jsonData)
                    .then((response) => {
                        me.data = response.data.data;
                        me.showmolalloading = false;
                        me.$router.replace(me.routeLink).catch((err) => {
                            err;
                        });
                    })
                    .catch((error) => {
                        me.showmolalloading = false;
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            } else {
                axios
                    .put(link + "/" + me.id, jsonData)
                    .then((response) => {
                        me.gruppo = response.data.data;
                        me.$refs["sa-modal-loading"].close();
                        me.$router.replace(me.routeLink).catch((err) => {
                            err;
                        });
                    })
                    .catch((error) => {
                        me.$refs["sa-modal-loading"].close();
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            }
        },
    },
};
</script>