<template>
    <div>
        <div class="sa-form-section">
            <b-row>
                <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                    <span class="sa-form-label">Anamnesi Familiare</span>
                    <b-form-textarea v-model="data.anamnesiFamiliare" no-resize></b-form-textarea>
                    <b-input-group style="margin-top: 5px">
                        <b-form-select id="inline-form-custom-select-pref" class="mb-2 mr-sm-2 mb-sm-0" :options="anamnesiFamiliareOptions" :value="null" value-field="descrizione" text-field="descrizione" @input="onInputAnamnesiFamiliare($event)"></b-form-select>
                        <b-button variant="outline-secondary" style="margin-right: 5px;" size="sm" @click="onClickAggiungiDescrizione(data.anamnesiFamiliare, linkAnamnesiFamiliare)"><b-icon icon="plus"></b-icon></b-button>
                        <b-button variant="outline-secondary" size="sm" @click="onDeleteDescrizione(data.anamnesiFamiliare, linkAnamnesiFamiliare, anamnesiFamiliareOptions)"><b-icon icon="trash"></b-icon></b-button>
                    </b-input-group>
                </b-col>
                <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                    <span class="sa-form-label">Anamnesi Fisiologica</span>
                    <b-form-textarea v-model="data.anamnesiFisiologica" no-resize></b-form-textarea>
                    <b-input-group style="margin-top: 5px">
                        <b-form-select id="inline-form-custom-select-pref" class="mb-2 mr-sm-2 mb-sm-0" :options="anamnesiFisiologicaOptions" :value="null" value-field="descrizione" text-field="descrizione" @input="onInputAnamnesiFisiologica($event)"></b-form-select>
                        <b-button variant="outline-secondary" style="margin-right: 5px;" size="sm" @click="onClickAggiungiDescrizione(data.anamnesiFisiologica, linkAnamnesiFisiologica)"><b-icon icon="plus"></b-icon></b-button>
                        <b-button variant="outline-secondary" size="sm" @click="onDeleteDescrizione(data.anamnesiFisiologica, linkAnamnesiFisiologica, anamnesiFisiologicaOptions)"><b-icon icon="trash"></b-icon></b-button>
                    </b-input-group>
                </b-col>
            </b-row>
        </div>
        <div class="sa-form-section">
            <b-row>
                <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                    <span class="sa-form-label">Fumo</span>
                    <b-form-select id="inline-form-custom-select-pref" v-model="data.fumo" class="mb-2 mr-sm-2 mb-sm-0" :options="tipiFumoOptions" :value="null" value-field="descrizione" text-field="descrizione"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                    <span class="sa-form-label">Alcool</span>
                    <b-form-select id="inline-form-custom-select-pref" v-model="data.alcool" class="mb-2 mr-sm-2 mb-sm-0" :options="alcoolOptions" :value="null" value-field="descrizione" text-field="descrizione"></b-form-select>
                </b-col>
            </b-row>
        </div>
        <div class="sa-form-section">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <span class="sa-form-label">Anamnesi Patologica malattie sofferte</span>
                    <b-form-textarea v-model="data.anamnesiPatologicaMalattieSofferte" no-resize></b-form-textarea>
                    <b-input-group style="margin-top: 5px">
                        <b-form-select id="inline-form-custom-select-pref" class="mb-2 mr-sm-2 mb-sm-0" :options="anamnesiPatologicaMalattieSofferteOptions" :value="null" value-field="descrizione" text-field="descrizione" @input="onInputAnamnesiPatologicaMalattieSofferte($event)"></b-form-select>
                        <b-button variant="outline-secondary" style="margin-right: 5px;" size="sm" @click="onClickAggiungiDescrizione(data.anamnesiPatologicaMalattieSofferte, linkAnamnesiPatologicaMalattieSofferte)"><b-icon icon="plus"></b-icon></b-button>
                        <b-button variant="outline-secondary" size="sm" @click="onDeleteDescrizione(data.anamnesiPatologicaMalattieSofferte, linkAnamnesiPatologicaMalattieSofferte, anamnesiPatologicaMalattieSofferteOptions)"><b-icon icon="trash"></b-icon></b-button>
                    </b-input-group>

                    <span class="sa-form-label">Interventi Chirurgici</span>
                    <b-form-textarea v-model="data.interventiChirurgici" no-resize></b-form-textarea>
                    <b-input-group style="margin-top: 5px">
                        <b-form-select id="inline-form-custom-select-pref" class="mb-2 mr-sm-2 mb-sm-0" :options="anamnesiInterventiChirurgiciOptions" :value="null" value-field="descrizione" text-field="descrizione" @input="onInputAnamnesiInterventiChirurgici($event)"></b-form-select>
                        <b-button variant="outline-secondary" style="margin-right: 5px;" size="sm" @click="onClickAggiungiDescrizione(data.interventiChirurgici, linkAnamnesiInterventiChirurgici)"><b-icon icon="plus"></b-icon></b-button>
                        <b-button variant="outline-secondary" size="sm" @click="onDeleteDescrizione(data.interventiChirurgici, linkAnamnesiInterventiChirurgici, anamnesiInterventiChirurgiciOptions)"><b-icon icon="trash"></b-icon></b-button>
                    </b-input-group>

                    <span class="sa-form-label">Infortuni</span>
                    <b-form-textarea v-model="data.infortuni" no-resize></b-form-textarea>
                    <b-input-group style="margin-top: 5px">
                        <b-form-select id="inline-form-custom-select-pref" class="mb-2 mr-sm-2 mb-sm-0" :options="anamnesiInfortuniOptions" :value="null" value-field="descrizione" text-field="descrizione" @input="onInputAnamnesiInfortuni($event)"></b-form-select>
                        <b-button variant="outline-secondary" style="margin-right: 5px;" size="sm" @click="onClickAggiungiDescrizione(data.infortuni, linkAnamnesiInfortuni)"><b-icon icon="plus"></b-icon></b-button>
                        <b-button variant="outline-secondary" size="sm" @click="onDeleteDescrizione(data.infortuni, linkAnamnesiInfortuni, anamnesiInfortuniOptions)"><b-icon icon="trash"></b-icon></b-button>
                    </b-input-group>
                </b-col>
            </b-row>
        </div>
        <div class="sa-form-section">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <strong>Stato </strong><br />
                            {{ data.firma }}
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </div>
    </div>
</template>
<script>
import axios from "axios";
export default {
    props: {
        data: {
            Type: Object,
            default: function() {
                return {
                    anamnesiFamiliare: "",
                    anamnesiFisiologica: "",
                    fumo: null,
                    alcool: null,
                    anamnesiPatologicaMalattieSofferte: "",
                    interventiChirurgici: "",
                    infortuni: "",
                };
            },
        },
    },
    components: {},
    data() {
        return {
            linkTipiFumo: "/tipifumo",
            linkAlcool: "/alcool",
            linkAnamnesiFamiliare: "/anamnesifamiliare",
            linkAnamnesiFisiologica: "/anamnesifisiologica",
            linkAnamnesiPatologicaMalattieSofferte: "/anamnesipatologicamalattiesofferte",
            linkAnamnesiInterventiChirurgici: "/anamnesiinterventichirurgici",
            linkAnamnesiInfortuni: "/anamnesiinfortuni",
            tipiFumoOptions: [],
            alcoolOptions: [],
            anamnesiFamiliareOptions: [],
            anamnesiFisiologicaOptions: [],
            anamnesiPatologicaMalattieSofferteOptions: [],
            anamnesiInterventiChirurgiciOptions: [],
            anamnesiInfortuniOptions: [],
        };
    },
    computed: {},
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadDefaultData();
    },
    methods: {
        loadDefaultData() {
            let me = this;
            me.loadTipiFumo();
            me.loadAlcool();
            me.loadAnamnesiFamiliare();
            me.loadAnamnesiFisiologica();
            me.loadAnamnesiPatologicaMalattieSofferte();
            me.loadAnamnesiInterventiChirurgici();
            me.loadAnamnesiInfortuni();
        },
        loadTipiFumo() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkTipiFumo;
            axios.get(link).then((response) => {
                me.tipiFumoOptions = response.data.data;
            });
        },
        loadAlcool() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkAlcool;
            axios.get(link).then((response) => {
                me.alcoolOptions = response.data.data;
            });
        },
        loadAnamnesiFamiliare() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkAnamnesiFamiliare;
            axios.get(link).then((response) => {
                me.anamnesiFamiliareOptions = response.data.data;
            });
        },
        onInputAnamnesiFamiliare(value) {
            let me = this;
            me.data.anamnesiFamiliare = value;
        },
        loadAnamnesiFisiologica() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkAnamnesiFisiologica;
            axios.get(link).then((response) => {
                me.anamnesiFisiologicaOptions = response.data.data;
            });
        },
        onInputAnamnesiFisiologica(value) {
            let me = this;
            me.data.anamnesiFisiologica = value;
        },
        loadAnamnesiPatologicaMalattieSofferte() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkAnamnesiPatologicaMalattieSofferte;
            axios.get(link).then((response) => {
                me.anamnesiPatologicaMalattieSofferteOptions = response.data.data;
            });
        },
        onInputAnamnesiPatologicaMalattieSofferte(value) {
            let me = this;
            me.data.anamnesiPatologicaMalattieSofferte = value;
        },
        loadAnamnesiInterventiChirurgici() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkAnamnesiInterventiChirurgici;
            axios.get(link).then((response) => {
                me.anamnesiInterventiChirurgiciOptions = response.data.data;
            });
        },
        onInputAnamnesiInterventiChirurgici(value) {
            let me = this;
            me.data.interventiChirurgici = value;
        },
        loadAnamnesiInfortuni() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkAnamnesiInfortuni;
            axios.get(link).then((response) => {
                me.anamnesiInfortuniOptions = response.data.data;
            });
        },
        onInputAnamnesiInfortuni(value) {
            let me = this;
            me.data.infortuni = value;
        },

        onClickAggiungiDescrizione(descrizione, path) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + path;
            let jsonData = { descrizione: descrizione };
            axios
                .post(link, JSON.stringify(jsonData))
                .then((response) => {
                    this.$bvModal.msgBoxOk(response.data.messaggio, {
                        title: "",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    });
                    me.loadDefaultData();
                })
                .catch((e) => {
                    // console.log(e);
                    this.$bvModal
                        .msgBoxOk(e.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxTwo = value;
                        });
                });
        },

        onDeleteDescrizione(descrizione, path, array) {
            let me = this;
            this.$bvModal
                .msgBoxConfirm("Sei sicuro di procedere alla cancellazione?", {
                    title: "Conferma Cancellazione",
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "secondary",
                    cancelVariant: "danger",
                    okTitle: "SI",
                    cancelTitle: "NO",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        let link = process.env.VUE_APP_PATH_API + path;
                        array.forEach((element) => {
                            if (element.descrizione === descrizione) {
                                axios.delete(link + "/" + element.id).then((response) => {
                                    this.$bvModal.msgBoxOk(response.data.messaggio, {
                                        title: "",
                                        size: "sm",
                                        okVariant: "outline-danger",
                                        headerClass: "sa-msg-header-danger",
                                        footerClass: "p-2 border-top-0",
                                        centered: true,
                                    });
                                    me.loadDefaultData();
                                });
                            } else if (descrizione === null || descrizione === "") {
                                this.$bvModal.msgBoxOk("Nessun elemento da cancellare.", {
                                    title: "Attenzione!",
                                    Text: "Nessun valore da cancellare!",
                                    size: "sm",
                                    okVariant: "outline-danger",
                                    headerClass: "sa-msg-header-danger",
                                    footerClass: "p-2 border-top-0",
                                    centered: true,
                                });
                            }
                        });
                    }
                })
                .catch((e) => {
                    this.$bvModal
                        .msgBoxOk(e.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxTwo = value;
                        });
                });
        },
    },
};
</script>
