<template>
  <div class="sa-form-section">
    <b-row>
      <b-col xs="12" sm="3" md="3" lg="3" xl="3">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblSG") }}</span> <br />
        <b-form-input v-model="data.sg" tipe="number" step="0.01"></b-form-input
      ></b-col>
      <b-col xs="12" sm="12" md="3" lg="3" xl="3">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblPh") }}</span> <br />
        <b-form-input v-model="data.ph" tipe="number" step="0.01"></b-form-input
      ></b-col>
      <b-col xs="12" sm="12" md="3" lg="3" xl="3">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblUBJValue") }}</span
        ><br />
        <b-form-input v-model="data.valoreUbg" tipe="number" step="0.01"></b-form-input
      ></b-col>
      <b-col xs="12" sm="12" md="3" lg="3" xl="3">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblUBJ") }}</span
        ><br />
        <b-form-select v-model="data.ubg" no-resize :options="opzioniUrine"></b-form-select
      ></b-col>
      <b-col xs="12" sm="12" md="3" lg="3" xl="3">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblGLU") }}</span
        ><br />
        <b-form-select v-model="data.glu" no-resize :options="opzioniUrine"></b-form-select
      ></b-col>

      <b-col xs="12" sm="12" md="3" lg="3" xl="3">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblNIT") }}</span
        ><br />
        <b-form-select v-model="data.nit" no-resize :options="opzioniUrine"></b-form-select
      ></b-col>
      <b-col xs="12" sm="12" md="3" lg="3" xl="3">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblLeu") }}</span
        ><br />
        <b-form-select v-model="data.leu" no-resize :options="opzioniUrine"></b-form-select></b-col
      ><b-col xs="12" sm="12" md="3" lg="3" xl="3">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblLeuValue") }}</span
        ><br />
        <b-form-input v-model="data.valoreLeu" tipe="number" step="0.01"></b-form-input
      ></b-col>
      <b-col xs="12" sm="12" md="3" lg="3" xl="3">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblPRO") }}</span
        ><br />
        <b-form-select v-model="data.pro" no-resize :options="opzioniUrine"></b-form-select></b-col
      ><b-col xs="12" sm="12" md="3" lg="3" xl="3">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblPROValue") }}</span
        ><br />
        <b-form-input v-model="data.valorePro" tipe="number" step="0.01"></b-form-input>
      </b-col>

      <b-col xs="12" sm="12" md="3" lg="3" xl="3">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblKET") }}</span
        ><br />
        <b-form-select v-model="data.ket" no-resize :options="opzioniUrine"></b-form-select></b-col
      ><b-col xs="12" sm="12" md="3" lg="3" xl="3">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblKETValue") }}</span
        ><br />
        <b-form-input v-model="data.valoreKet" tipe="number" step="0.01"></b-form-input
      ></b-col>
      <b-col xs="12" sm="12" md="3" lg="3" xl="3">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblERY") }}</span
        ><br />
        <b-form-select v-model="data.ery" no-resize :options="opzioniUrine"></b-form-select
      ></b-col>
      <b-col xs="12" sm="12" md="3" lg="3" xl="3">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblBIL") }}</span
        ><br />
        <b-form-select v-model="data.bil" no-resize :options="opzioniUrine"></b-form-select>
      </b-col>
    </b-row>
    <b-row>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblReport") }}</span
        ><br />
        <b-form-textarea v-model="data.refertoUrine" no-resize></b-form-textarea>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <strong>{{ getLabelTraduora("therapy.lblStatus") }} </strong><br />
            {{ data.firma }}
          </b-col>
        </b-row>
        <hr />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";
export default {
  mixins: [UtilityMixin],
  props: ["data"],
  data() {
    return {
      opzioniUrine: [
        { text: this.getLabelTraduora("patient.afa.lblNegative"), value: 0 },
        { text: this.getLabelTraduora("patient.afa.lblPositive"), value: 1 },
      ],
    };
  },
};
</script>
