<template>
    <sa-page-layout :btnNewVisible="true" :btnRefreshVisible="true" :pathResource="pathResource" :linkedit="linkedit" :showModalLoading="showModalLoading" @refresh="onRefresh">
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col sm="6">
                        <b-form-input v-model="filtro.nome" type="search" id="nome" placeholder="Nome"></b-form-input>
                    </b-col>
                    <b-col sm="6">
                        <b-form-input v-model="filtro.cognome" type="search" id="cognome" placeholder="Cognome"></b-form-input>
                    </b-col>
                </b-row>
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col sm="6">
                        <b-form-input v-model="filtro.sport" type="search" id="sport" placeholder="Sport"></b-form-input>
                    </b-col>
                    <b-col sm="6">
                        <b-form-input v-model="filtro.tipoVisita" type="search" id="tipoVisita" placeholder="Tipo Visita"></b-form-input>
                    </b-col>
                    <b-col sm="6">
                        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.dataPrenotazione" type="datetime" placeholder="Data Prenotazione"></date-picker>
                    </b-col>
                </b-row>
                <b-row style="margin-top: 10px; margin-bottom: 10px;">
                    <b-col class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("patients.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>
        <template slot="table-header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" stacked="xl" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template #cell(index)="item">
                        {{ item.index + 1 }}
                    </template>
                    <template v-slot:cell(nominativo)="{ item }">
                        <router-link class="sa-edit-link" :to="'/prenotazioni/view/' + item.id">{{ item.nome }} {{ item.cognome }}</router-link>
                    </template>
                    <template #cell(actions)="row">
                        <b-button class="mr-1 ml-1 no-text" size="sm" v-if="btnAccettazioneVisible" :disabled="row.item.statoAttuale !== 'PRENOTATO'" variant="outline-success width-sm no-text" @click="onClickAccettazione(row.item)">
                            <i class="fas fa-check"></i>
                        </b-button>
                        <b-button class="mr-1 ml-1 no-text" size="sm" variant="outline-secondary width-sm no-text" @click="onClickArchiviaPrenotazione(row.item)">
                            <i class="fas fa-archive"></i>
                        </b-button>
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
    </sa-page-layout>
</template>
<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import axios from "axios";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    components: { SaPageLayout, DatePicker },
    props: {
        utente: {
            type: Object,
            default: function() {
                return { gruppo: { menuGruppo: [] } };
            },
        },
    },
    computed: {
        rows() {
            return this.items.length;
        },
        btnAccettazioneVisible: {
            get: function() {
                let me = this;
                return UtilityMixin.methods.verificaPermessi(me.utente, "accettazioneleonida", 1);
            },
        },
    },
    mounted() {
        let me = this;
        me.loadData();
    },
    data() {
        return {
            pathResource: "/prenotazioni",
            linkedit: "/prenotazioni",
            linkAccettazione: "/accettazione/edit/-1",
            showModalLoading: false,
            disabilitato: false,
            currentPage: 1,
            perPage: 10,
            filtro: {},
            items: [],
            fields: [
                {
                    label: "",
                    key: "index",
                    thStyle: "width: 3rem",
                },
                {
                    label: "Nominativo",
                    key: "nominativo",
                    sortable: true,
                },
                {
                    label: "Data Nascita",
                    key: "dataNascita",
                    formatter: (value) => {
                        return value ? moment(new Date(value)).format("DD/MM/YYYY") : "";
                    },
                    thStyle: "width: 7rem",
                    sortable: true,
                },
                {
                    label: "Sport",
                    key: "sport",
                    thStyle: "width: 7rem",
                    sortable: true,
                },
                {
                    label: "Tipo Visita",
                    key: "tipoVisita",
                    thStyle: "width: 7rem",
                    sortable: true,
                },
                {
                    label: "Data Prenotazione",
                    key: "dataPrenotazione",
                    formatter: (value) => {
                        return value ? moment(new Date(value)).format("DD/MM/YYYY") : "";
                    },
                    thStyle: "width: 9rem",
                    sortable: true,
                },
                {
                    label: "Note",
                    key: "noteAggiuntive",
                    sortable: true,
                },

                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 7rem",
                },
            ],
        };
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.showmolalloading = true;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data;
                    me.items.forEach((element) => {
                        if (!me.isMaggiorenne(element.dataNascita)) element._rowVariant = "danger";
                    });
                    me.showmolalloading = false;
                })
                .catch(() => {
                    me.showmolalloading = false;
                });
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onClickAccettazione(item) {
            let me = this;
            me.$router.replace(me.linkAccettazione + "?idPrenotazione=" + item.id).catch((err) => {
                err;
            });
        },
        onClickArchiviaPrenotazione(row) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/archivia/" + row.id;
            let jsonData = JSON.stringify(row);
            axios
                .put(link, jsonData)
                .then((response) => {
                    me.items.push(response.data.data);
                    me.loadData();
                })
                .catch((error) => {
                    me.$refs["sa-modal-loading"].close();
                    this.$bvModal.msgBoxOk(error.response.data.messaggio, {
                        title: [this.$i18n.t("agenda.lblEditTitle")],
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    });
                })
                .then((value) => {
                    this.botTwo = value;
                });
        },
        onReset() {
            let me = this;
            me.filtro = {};
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },

        isMaggiorenne(value) {
            let returnValue = false;
            if (value) {
                let today = new Date();
                let birthDate = new Date(value);
                let age = today.getFullYear() - birthDate.getFullYear();
                let ageMonth = today.getMonth() - birthDate.getMonth();
                let ageDays = today.getDate() - birthDate.getDate();
                if (ageMonth < 0 || (ageMonth === 0 && today.getDate() < birthDate.getDate())) {
                    age--;
                    ageMonth = 12;
                }
                if (ageDays < 0) {
                    ageMonth--;
                    ageDays = birthDate.getDate() - today.getDate();
                }
                if (age >= 18) {
                    returnValue = true;
                }
            }
            return returnValue;
        },
    },
};
</script>
