<template>
  <sa-page-layout :pathResource="pathResource" :showmolalloading="showmolalloading" :linkback="linkback" :btnRefreshVisible="true" :linkedit="linkedit" :btnNewVisible="true">
    <template slot="table-filter">
      <b-form @submit.prevent="onSubmit">
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col xs="6" sm="6" md="6" lg="6" xl="6">
            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.dataDal" type="datetime" placeholder="Data Dal"></date-picker>
          </b-col>
          <b-col xs="6" sm="6" md="6" lg="6" xl="6">
            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.dataAl" type="datetime" placeholder="Data Al"></date-picker>
          </b-col>
        </b-row>
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col xs="6" sm="6" md="6" lg="6" xl="6">
            <b-form-input v-model="filtro.codiceFiscale" type="search" id="codiceFiscale" placeholder="Codice Fiscale"></b-form-input>
          </b-col>
          <b-col xs="6" sm="6" md="6" lg="6" xl="6">
            <b-form-select v-model="filtro.stato" :options="options"></b-form-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="sa-padding-right text-right">
            <b-button type="submit" variant="info">Cerca</b-button>
            <b-button type="reset" v-on:click="onReset" variant="danger">Resetta Filtro</b-button>
          </b-col>
        </b-row>
      </b-form>
      <div>
        <b-row class="sa-text-bold">Legenda</b-row>
        <b-row>
          <b-button variant="info" class="m-2"
            >P: <b-badge variant="danger"> Positivo<span class="sr-only">unread messages</span></b-badge></b-button
          >
          <b-button variant="info" class="m-2"
            >N: <b-badge variant="success"> Negativo<span class="sr-only">unread messages</span></b-badge></b-button
          >
          <b-button variant="info" class="m-2"
            >NV: <b-badge variant="secondary"> Non valido<span class="sr-only">unread messages</span></b-badge></b-button
          >
        </b-row>
      </div>
    </template>
    <template slot="table-header">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>Totale Record: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" :filter="filter" responsive stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template v-slot:cell(codiceFiscaleAssistito)="{ item }">
            <router-link class="sa-edit-link" :to="'/gestionetamponiantigenici/view/' + item.id">{{ item.codiceFiscaleAssistito }}</router-link>
          </template>
          <template class="text-center" v-slot:cell(actions)="row">
            <b-button :disabled="disabledAccept(row.item.stato != 'INVIATO')" size="sm" class="mr-1" variant="outline-secondary" @click="onInviaDati(row.item, row.index, $event.target)">
              <font-awesome-icon size="lg" icon="paper-plane" tabindex="-1" />
              Invia Sinfonia
            </b-button>
          </template>
          <template class="text-center" v-slot:cell(pdf)="row">
            <b-button size="sm" class="mr-1" variant="outline-secondary" @click="onClickPdf(row)" v-b-modal.pdfmodel>
              <font-awesome-icon size="lg" icon="file-pdf" tabindex="1" />
              Referto
            </b-button>
            <pdf-viewer :pdf="pdf"></pdf-viewer>
          </template>
        </b-table>
      </div>
    </template>
    <template slot="table-footer">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>Totale Record: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
  </sa-page-layout>
</template>
<script>
import PdfViewer from "../../utility/components/PdfViewer.vue";
import axios from "axios";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
  components: { SaPageLayout, DatePicker, PdfViewer },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  data() {
    return {
      showmolalloading: false,
      perPage: 10,
      pathResource: "/gestioneantigenici",
      currentPage: 1,
      linkedit: "/gestionetamponiantigenici",
      linkback: "/gestionetamponiantigenici",
      filter: null,
      pdf: null,
      filtro: {},
      filterOn: [],
      options: [
        { value: null, text: "Stato" },
        { value: "ERROR", text: "ERROR" },
        { value: "INVIATO", text: "INVIATO" },
      ],
      fields: [
        {
          label: "Codice Fiscale",
          key: "codiceFiscaleAssistito",
          sortable: true,
        },
        {
          label: "Risultato",
          key: "risultato",
          sortable: true,
        },
        {
          label: "Codice Fiscale Medico",
          key: "codiceFiscaleMedicoTitolare",
          sortable: true,
        },
        {
          label: "Stato",
          key: "statoAttuale",
          sortable: true,
        },
        {
          label: "Data",
          key: "dataEsecuzione",
          sortable: true,
          formatter: (value) => {
            return moment(value).format("DD-MM-YYYY");
          },
        },
        { key: "actions", label: "", thStyle: "width: 10rem", tdClass: "text-center" },
        { key: "pdf", label: "", thStyle: "width: 10rem", tdClass: "text-center" },
      ],
      items: [],
    };
  },
  created: function() {
    let me = this;
    let sessionStorage = window.sessionStorage;
    me.filtro = sessionStorage.getItem("filtro") == null ? {} : JSON.parse(sessionStorage.getItem("filtro"));
    me.loadData({ codiceFiscale: "", stato: "" });
  },
  methods: {
    disabledAccept(value) {
      if (value) {
        return false;
      } else {
        return true;
      }
    },
    loadData() {
      let me = this;
      me.showmolalloading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.items = response.data.data;
          me.showmolalloading = false;
          for (let element in me.items) {
            if (me.items[element]["statoAttuale"] === "ERROR") {
              me.items[element]["_cellVariants"] = { statoAttuale: "danger" };
            } else if (me.items[element]["statoAttuale"] === "INVIATO") {
              me.items[element]["_cellVariants"] = { statoAttuale: "success" };
            } else {
              me.items[element]["_cellVariants"] = {};
            }
          }
        })
        .catch(() => {
          me.showmolalloading = false;
        });
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onReset() {
      let me = this;
      me.filtro = {};
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onInviaDati(items) {
      let me = this;
      me.showmolalloading = true;
      let link = process.env.VUE_APP_PATH_API + "/tamponiantigenici/inviasinfonia/" + items.id;
      axios
        .put(link)
        .then(() => {
          me.loadData();
          me.showmolalloading = false;
        })
        .catch((error) => {
          me.showmolalloading = false;
          this.$bvToast.toast(error.response.statusText + " (" + error.response.status + ") ", {
            title: "Error",
            variant: "danger",
            solid: true,
            toaster: "b-toaster-bottom-center",
          });
        });
    },
    onClickPdf(row) {
      let me = this;
      me.pdf = row.item.pdfReferto;
    },
  },
};
</script>
