<template>
    <sa-page-view-edit ref="pgaView" :backVisible="true" :editVisible="false" :titolo="data.titolo" :linkback="pagelink" :linkedit="linkedit" :showmolalloading="showmolalloading">
        <template slot="btn-left"> </template>
        <template slot="filter">
            <b-row>
                <b-col cols="12" sm="2" class="sa-text-right">Tipo:</b-col>
                <b-col cols="12" sm="4" class="sa-text-left">
                    <span class="sa-text-data">{{ data.tipoReferto }}</span>
                </b-col>
                <b-col cols="12" sm="2" class="sa-text-right">Data:</b-col>
                <b-col cols="12" sm="4" class="sa-text-left">
                    <span class="sa-text-data">{{ formatDate(data.dataOra) }}</span>
                </b-col>
            </b-row>
        </template>
        <template slot="header"> </template>
        <template slot="body">
            <b-embed type="iframe" :src="referto" allowfullscreen></b-embed>
        </template>
        <template slot="footer"> </template>
    </sa-page-view-edit>
</template>
<script>
import axios from "axios";
import moment from "moment";
import { SaPageViewEdit } from "../../../template/sa/components/layout/contents";
export default {
    components: { SaPageViewEdit },

    data() {
        return {
            task: { nome: "" },
            showmolalloading: false,
            pagelink: "/sorveglianzareferti",
            linkedit: null,
            elaborazione: false,
            currentPage: 1,
            totalRows: 1,
            perPage: 100,
            filter: "",
            pathResource: "/sorveglianzareferti",
            items: [],
            data: {},
            ricevente: { id: "-1", idInvioSms: null },
            fileCsv: { primaRigaIntestazione: true, file: null },
            stato: { log: "" },
            filtro: {},
            referto: null,
        };
    },
    created: function() {},
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    update: function() {
        console.log("Update");
    },
    methods: {
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            } else {
                return "---";
            }
        },
        loadData() {
            let me = this;
            me.showmolalloading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            axios.get(link + me.id).then((response) => {
                me.data = response.data.data;
                me.referto = "data:application/pdf;base64," + me.data.referto;
                me.showmolalloading = false;
            });
        },

        onRefreshDettatio() {
            let me = this;
            me.loadDataDetails();
        },
    },
};
</script>
