<template>
  <b-card>
    <b-card-body>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
          ><span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblHandgripRight") }}</span
          ><b-form-input type="number" step="0.01" v-model="data.handgripDestra" :disabled="!edit"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
          ><span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblHandgripLeft") }}</span
          ><b-form-input type="number" step="0.01" v-model="data.handgripSinistra" :disabled="!edit"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
          ><span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblPerpendicularDeviation") }}</span
          ><b-form-input type="number" step="0.01" v-model="data.deviazionePerpendicolare" :disabled="!edit"></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
          ><span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblStraightDeviation") }}</span
          ><b-form-input type="number" step="0.01" v-model="data.deviazioneRetta" :disabled="!edit"></b-form-input
        ></b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
          ><span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblFlexRight") }}</span
          ><b-form-input type="number" step="0.01" v-model="data.flessibilitaDestra" :disabled="!edit"></b-form-input
        ></b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
          ><span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblFlexLeft") }}</span
          ><b-form-input type="number" step="0.01" v-model="data.flessibilitaSinistra" :disabled="!edit"></b-form-input
        ></b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
          ><span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblChairTest") }}</span
          ><b-form-input type="number" step="0.01" v-model="data.chairTest" :disabled="!edit"></b-form-input
        ></b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
          ><span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblMets") }}</span
          ><b-form-input type="number" step="0.01" v-model="data.mets" :disabled="!edit"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblStressDuration") }}</span
          ><b-form-input type="number" step="0.01" v-model="data.durataSforzo" :disabled="!edit"></b-form-input
        ></b-col>
      </b-row>
      <b-row
        ><b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblRecoveryDuration") }}</span
          ><b-form-input type="number" step="0.01" v-model="data.durataRecupero" :disabled="!edit"></b-form-input
        ></b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
          ><span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblQMCI") }}</span
          ><b-form-input type="number" step="0.01" v-model="data.qmci" :disabled="!edit"></b-form-input
        ></b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"></b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";

export default {
  mixins: [UtilityMixin],
  props: ["data", "edit"],
  data() {
    return {};
  },
};
</script>
