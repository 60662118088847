<template>
  <div>
    <b-card :header="domanda.domanda" header-bg-variant="light" border-variant="light">
      <!-- <strong>Tipo Risposta:</strong>
      {{ domanda.tipoRisposta }}
      <br /> -->
      <b-row v-if="domanda.tipoRisposta === 'text'">
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-for="risposta in domanda.questionarioDomandaRispostaBeans" :key="risposta.id">
          <strong v-if="risposta.tipo === 'text' || risposta.tipo === 'number'">{{ risposta.label }}:</strong>
          <div v-if="risposta.tipo === 'text' || risposta.tipo === 'number'"><b-form-input :type="risposta.tipo" v-model="selected" @change="onRisposta(risposta)"></b-form-input></div>
          <div v-if="risposta.tipo === 'date'"><date-picker v-model="selected" @change="onRisposta(risposta)"></date-picker></div>
          <div v-if="risposta.tipo === 'select'">
            <b-form-radio :value="risposta.predefinito" v-model="selected" @change="onRisposta(risposta)">{{ risposta.predefinito }}</b-form-radio>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="domanda.tipoRisposta === 'select'">
        <strong v-if="domanda.tipoRisposta === 'select'">Opzioni:</strong>
        <b-form-select v-model="selected" :options="opzioni" @input="onRispostaSelect(domanda)"></b-form-select>
      </b-row>
    </b-card>
    <hr />
  </div>
</template>
<script>
import DatePicker from "vue2-datepicker";

export default {
  props: {
    domanda: {
      Type: Object,
      require: true,
    },
    risposte: {
      Type: Array,
      require: true,
    },
    idPazienteQuestionario: {
      Type: String,
      required: true,
    },
  },
  components: { DatePicker },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    idEvento() {
      return this.$route.params.id;
    },
  },
  data() {
    return {
      jsonData: [],
      pathResource: "/questionari",
      currentPage: 1,
      selected: "",
      opzioni: [],
      perPage: 10,
      scelta: null,
    };
  },
  created: function() {
    let me = this;
    me.loadData();
  },
  mounted() {},
  methods: {
    loadData() {
      let me = this;
      me.showmolalloading = true;
      //   let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      //   axios.get(link + me.idEvento).then((response) => {
      //     me.jsonData = response.data.data;
      //   });
      let opt = me.domanda.questionarioDomandaRispostaBeans;
      me.buildSelect(opt);
    },
    buildSelect(array) {
      array.forEach((element) => {
        this.opzioni.push(element.predefinito);
      });
    },
    onRispostaSelect(domanda) {
      let me = this;
      try {
        let obj = {
          idQuestionarioDomanda: domanda.id,
          idQuestionarioRisposta: "",
          valore: me.selected,
          idPaziente: me.idPaziente,
          idPazienteQuestionario: me.idPazienteQuestionario,
        };
        let edit = false;
        let check = domanda.questionarioDomandaRispostaBeans;
        check.forEach((element) => {
          console.log(element.predefinito);
          if (element.predefinito === me.selected) {
            obj.idQuestionarioRisposta = element.id;
          }
        });
        me.risposte.forEach((element) => {
          if (element.idQuestionarioRisposta === obj.idQuestionarioRisposta) {
            element = obj;
            edit = true;
          }
        });
        if (edit === false) {
          me.risposte.push(obj);
        }
      } catch (e) {
        alert(e);
      }
      console.log(me.risposte);
    },
    onRisposta(risposta) {
      let me = this;
      let obj = {
        idQuestionarioDomanda: me.domanda.id,
        idQuestionarioRisposta: risposta.id,
        valore: me.selected,
        idPaziente: me.idPaziente,
        idPazienteQuestionario: me.idPazienteQuestionario,
      };
      let edit = false;
      me.risposte.forEach((element) => {
        if (element.idQuestionarioRisposta === obj.idQuestionarioRisposta) {
          element = obj;
          edit = true;
        }
      });
      if (edit === false) {
        me.risposte.push(obj);
      }
      console.log(me.risposte);
    },
  },
};
</script>
