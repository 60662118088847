<template>
  <div>
    <b-card :header="domanda.domanda" header-bg-variant="light" border-variant="light">
      <!-- <strong>Tipo Risposta:</strong>
      {{ domanda.tipoRisposta }}
      <br /> -->
      <b-row v-if="domanda.tipoRisposta === 'text'">
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-for="risposta in domanda.questionarioDomandaRispostaBeans" :key="risposta.id">
          <strong v-if="risposta.tipo === 'text'">{{ risposta.label }}:</strong>
          <div v-if="risposta.tipo === 'text'"><b-form-input :type="risposta.tipo" v-model="risposta.predefinito"></b-form-input></div>
          <div v-if="risposta.tipo === 'select'">
            <b-form-radio :value="risposta.predefinito" v-model="selected">{{ risposta.predefinito }}</b-form-radio>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="domanda.tipoRisposta === 'select'">
        <strong v-if="domanda.tipoRisposta === 'select'">Opzioni:</strong>
        <b-form-select v-model="scelta" :options="opzioni"></b-form-select>
      </b-row>
    </b-card>
    <hr />
  </div>
</template>
<script>
// import axios from "axios";
export default {
  props: {
    domanda: {
      Type: Object,
      require: true,
    },
  },
  computed: {},
  data() {
    return {
      jsonData: [],
      pathResource: "/questionari",
      currentPage: 1,
      selected: "",
      opzioni: [],
      perPage: 10,
      scelta: null,
    };
  },
  mounted() {
    let me = this;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      me.showmolalloading = true;
      //   let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      //   axios.get(link + me.idEvento).then((response) => {
      //     me.jsonData = response.data.data;
      //   });
      let opt = me.domanda.questionarioDomandaRispostaBeans;
      me.buildSelect(opt);
    },
    buildSelect(array) {
      array.forEach((element) => {
        this.opzioni.push(element.predefinito);
      });
    },
  },
};
</script>
