<template>
  <sa-page-layout :showModalLoading="showModalLoading" :btnBackVisible="true" :btnEditVisible="true" :linkedit="linkedit" :linkback="linkback" :pathResource="pathResource" :data="jsonData">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-body">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <strong>{{ this.$i18n.t("patient.fisiologica.lblConcomitantDiseases") }}</strong
              ><br />
              {{ jsonData.malattieConcomitanti }}
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <strong>{{ this.$i18n.t("patient.fisiologica.lblPreviousSurgicalInterventions") }}</strong
              ><br />
              {{ jsonData.pregressiInterventi }}
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <strong>{{ this.$i18n.t("patient.fisiologica.lblSmoke") }}</strong
              ><br />
              {{ jsonData.fumo }}
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <strong>{{ this.$i18n.t("patient.fisiologica.lblSmokeQuantity") }}</strong
              ><br />
              {{ jsonData.quantitaFumo }}
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <strong>{{ this.$i18n.t("patient.fisiologica.lblAlcoholicBeverages") }}</strong
              ><br />
              {{ jsonData.bevandeAlcoliche }}
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <strong>{{ this.$i18n.t("patient.fisiologica.lblAlcoholicBeveragesQuantity") }}</strong
              ><br />
              {{ jsonData.quantitaBevandeAlcoliche }}
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <strong>{{ this.$i18n.t("patient.fisiologica.lblCoffee") }}</strong
              ><br />
              {{ jsonData.caffe }}
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <strong>{{ this.$i18n.t("patient.fisiologica.lblCoffeeQuantity") }}</strong
              ><br />
              {{ jsonData.quantitaCaffe }}
            </b-col>
          </b-row>
          <hr />
        </b-col>
      </b-row>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
export default {
  components: { SaPageLayout, PazienteAnagrafica },
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient: {
      get: function() {
        let returnValue = "";
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length > 1) {
              return "/paziente/view/" + this.idPaziente;
            } else {
              if (this.utente.gruppo.menuGruppo.length == 1) {
                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                  return "/paziente/view/" + this.idPaziente;
                } else {
                  return "/paziente/homepaziente";
                }
              }
            }
          }
        }
        return returnValue;
      },
    },
  },
  mounted() {
    let me = this;
    me.jsonData.idPaziente = this.$route.query.idPaziente;
    me.jsonData.tipoEvento = "Gastroenterologia - Anamnesi Patologica";
    me.id = this.$route.params.id;
    me.linkedit = "/paziente/gastroenterologia/anamnesifisiologica/edit/" + me.id;
    me.linkback = "/paziente/gastroenterologia/anamnesifisiologica?idPaziente=" + me.idPaziente;
    me.loadData();
  },
  data() {
    return {
      linkback: null,
      pathResource: "/gastroanamnesifisiologica",
      jsonData: {},
      linkedit: null,
      showModalLoading: false,
    };
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        });
      }
    },
  },
};
</script>
