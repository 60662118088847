<template>
  <sa-page-layout :showModalLoading="showModalLoading" :btnSaveVisible="true" :btnAnnullaVisible="true" :btnBackVisible="false" :linkback="linkback" :pathResource="pathResource" :data="jsonData">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-header">
      <h5 class="sa-text-left">Dati</h5>
    </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <!-- InizioBody  -->
        <b-row
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblHeight") }}</span
            ><br />
            <b-form-input v-model="jsonData.altezza" type="number"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblWeight") }}</span
            ><br />
            <b-form-input v-model="jsonData.peso" type="number"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblBSA") }}</span
            ><br />
            <b-form-input v-model="jsonData.bsa" type="number"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblBMI") }}</span
            ><br />
            <b-form-input v-model="jsonData.bmi" type="number"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPAS") }}</span
            ><br />
            <b-form-input v-model="jsonData.pas" type="number"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPAD") }}</span
            ><br />
            <b-form-input v-model="jsonData.pad" type="number"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPC") }}</span
            ><br />
            <b-form-input v-model="jsonData.pc" type="number"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAcousticImpedance") }}</span
            ><br />
            <b-form-select v-model="jsonData.impedenzaAcustica" :options="opzioniImpedenza"></b-form-select
          ></b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><hr />
            <h5>{{ getTranslate("patient.cardiology.lblLeftVentricle") }}</h5></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblVStd") }}</span
            ><br />
            <b-form-input v-model="jsonData.vstd" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblVSts") }}</span
            ><br />
            <b-form-input v-model="jsonData.vsts" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblSIVtd") }}</span
            ><br />
            <b-form-input v-model="jsonData.sivtd" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPPtd") }}</span
            ><br />
            <b-form-input v-model="jsonData.pptd" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lbleft") }}</span
            ><br />
            <b-form-input v-model="jsonData.eft" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblsft") }}</span
            ><br />
            <b-form-input v-model="jsonData.sft" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLVMha") }}</span
            ><br />
            <b-form-input v-model="jsonData.lvmha" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblRWT") }}</span
            ><br />
            <b-form-input v-model="jsonData.rwt" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblESS") }}</span
            ><br />
            <b-form-input v-model="jsonData.ess" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLVMid") }}</span
            ><br />
            <b-form-input v-model="jsonData.lvmid" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLVV") }}</span
            ><br />
            <b-form-input v-model="jsonData.lvv" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><hr />
            <h5>{{ getTranslate("patient.cardiology.lblSegments") }}</h5></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorBasalSeptum") }}</span
            ><br />
            <b-form-select v-model="jsonData.settoAntBasale" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorMiddleSeptum") }}</span
            ><br />
            <b-form-select v-model="jsonData.settoAntMedio" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorApexSeptum") }}</span
            ><br />
            <b-form-select v-model="jsonData.settoAntApice" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorBasalWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.pareteAntBasale" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorMiddleWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.pareteAntMedia" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAnteriorApexWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.pareteAntApice" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLateralAnteriorBasalWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.pareteLatAntBasale" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLateralAnteriorMiddleWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.pareteLatAntMedia" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLateralPosteriorBasalWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.pareteLatPostBasale" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLateralPosteriorMiddleWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.pareteLatPostMedia" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLateralPosteriorApexWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.pareteLatPostApice" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLowerBasalWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.pareteInfBasale" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLowerMiddleWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.pareteInfMedia" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLowerApexWall") }}</span
            ><br />
            <b-form-select v-model="jsonData.pareteInfApice" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPosteriorBasalSeptum") }}</span
            ><br />
            <b-form-select v-model="jsonData.settoPostBasale" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPosteriorMiddleSeptum") }}</span
            ><br />
            <b-form-select v-model="jsonData.settoPostMedio" :options="opzioniSegmenti"></b-form-select></b-col
          ><b-col xs="0" sm="12" md="12" lg="12" xl="12"></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblTotal") }}</span
            ><br />
            <b-form-input v-model="jsonData.totale" type="text"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblIMA") }}</span
            ><br />
            <b-form-input v-model="jsonData.ima" type="text"></b-form-input></b-col
          ><b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><span>{{ getTranslate("patient.cardiology.lblNotes") }}</span
            ><br />
            <b-form-textarea v-model="jsonData.noteSegmenti" rows="3" no-resize></b-form-textarea></b-col
          ><b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><hr />
            <h5>{{ getTranslate("patient.cardiology.lblRightVentricle") }}</h5></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblVDtd") }}</span
            ><br />
            <b-form-input v-model="jsonData.vdtd" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.diabetology.lblSystolicPressure") }}</span
            ><br />
            <b-form-input v-model="jsonData.pressioneSistolica" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblTAPSE") }}</span
            ><br />
            <b-form-input v-model="jsonData.tapse" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><span>{{ getTranslate("patient.cardiology.lblNotes") }}</span
            ><br />
            <b-form-textarea v-model="jsonData.noteVentDx" rows="3" no-resize></b-form-textarea></b-col
          ><b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><hr />
            <h5>{{ getTranslate("patient.cardiology.lblRightAtrium") }}</h5></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblVCIi") }}</span
            ><br />
            <b-form-input v-model="jsonData.vcii" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblVCIe") }}</span
            ><br />
            <b-form-input v-model="jsonData.vcie" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><span>{{ getTranslate("patient.cardiology.lblDesription") }}</span
            ><br />
            <b-form-textarea v-model="jsonData.descrizioneAtrioDx" rows="3" no-resize></b-form-textarea></b-col
          ><b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><hr />
            <h5>{{ getTranslate("patient.cardiology.lblLeftAtrium") }}</h5></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLeftAtrium") }}</span
            ><br />
            <b-form-input v-model="jsonData.atrioSx" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLavi") }}</span
            ><br />
            <b-form-input v-model="jsonData.lavi" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><span>{{ getTranslate("patient.cardiology.lblDesription") }}</span
            ><br />
            <b-form-textarea v-model="jsonData.descrizioneAtrioSx" rows="3" no-resize></b-form-textarea></b-col
          ><b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><hr />
            <h5>{{ getTranslate("patient.cardiology.lblAorta") }}</h5></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAorta") }}</span
            ><br />
            <b-form-input v-model="jsonData.aorta" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLVOT") }}</span
            ><br />
            <b-form-input v-model="jsonData.lvot" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="8" md="8" lg="8" xl="8"
            ><span>{{ getTranslate("patient.cardiology.lbl2D") }}</span
            ><br />
            <b-form-input v-model="jsonData.dueDAorta" type="text"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblIA") }}</span
            ><br />
            <b-form-select v-model="jsonData.ia" :options="opzioniPlus4"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAortaArea") }}</span
            ><br />
            <b-form-input v-model="jsonData.areaAorta" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPGMax") }}</span
            ><br />
            <b-form-input v-model="jsonData.pgMaxAorta" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPGMean") }}</span
            ><br />
            <b-form-input v-model="jsonData.pgMeanAorta" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><span>{{ getTranslate("patient.cardiology.lblDoppler") }}</span
            ><br />
            <b-form-textarea v-model="jsonData.dopplerAorta" rows="3" no-resize></b-form-textarea></b-col
          ><b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><hr />
            <h5>{{ getTranslate("patient.cardiology.lblMitral") }}</h5></b-col
          ><b-col xs="12" sm="8" md="8" lg="8" xl="8"
            ><span>{{ getTranslate("patient.cardiology.lbl2D") }}</span
            ><br />
            <b-form-input v-model="jsonData.dueDMitrale" type="text"></b-form-input></b-col
          ><b-col xs="12" sm="2" md="2" lg="2" xl="2"
            ><span>{{ getTranslate("patient.cardiology.lblIM") }}</span
            ><br />
            <b-form-select v-model="jsonData.im" :options="opzioniPlus4"></b-form-select></b-col
          ><b-col xs="12" sm="2" md="2" lg="2" xl="2"
            ><span>{{ getTranslate("patient.cardiology.lblEE") }}</span
            ><br />
            <b-form-input v-model="jsonData.ee" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPFVE") }}</span
            ><br />
            <b-form-input v-model="jsonData.pfve" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPFVA") }}</span
            ><br />
            <b-form-input v-model="jsonData.pfva" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblDT") }}</span
            ><br />
            <b-form-input v-model="jsonData.dt" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblIRT") }}</span
            ><br />
            <b-form-input v-model="jsonData.irt" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPGMean") }}</span
            ><br />
            <b-form-input v-model="jsonData.pgMeanMitrale" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblMVA") }}</span
            ><br />
            <b-form-input v-model="jsonData.mva" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><span>{{ getTranslate("patient.cardiology.lblDoppler") }}</span
            ><br />
            <b-form-textarea v-model="jsonData.dopplerMitrale" rows="3" no-resize></b-form-textarea></b-col
          ><b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><hr />
            <h5>{{ getTranslate("patient.cardiology.lblPulmonary") }}</h5></b-col
          ><b-col xs="12" sm="8" md="8" lg="8" xl="8"
            ><span>{{ getTranslate("patient.cardiology.lbl2D") }}</span
            ><br />
            <b-form-input v-model="jsonData.dueDPolmonare" type="text"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblIM") }}</span
            ><br />
            <b-form-select v-model="jsonData.ip" :options="opzioniPlus4"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblVMax") }}</span
            ><br />
            <b-form-input v-model="jsonData.vMaxPomonare" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPGMax") }}</span
            ><br />
            <b-form-input v-model="jsonData.pgMaxPolmonare" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><span>{{ getTranslate("patient.cardiology.lblDoppler") }}</span
            ><br />
            <b-form-textarea v-model="jsonData.dopplerPolmonare" rows="3" no-resize></b-form-textarea></b-col
          ><b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><hr />
            <h5>{{ getTranslate("patient.cardiology.lblPulmonaryVeinsDoppler") }}</h5></b-col
          ><b-col xs="12" sm="3" md="3" lg="3" xl="3"
            ><span>{{ getTranslate("patient.cardiology.lblS") }}</span
            ><br />
            <b-form-input v-model="jsonData.s" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="3" md="3" lg="3" xl="3"
            ><span>{{ getTranslate("patient.cardiology.lblD") }}</span
            ><br />
            <b-form-input v-model="jsonData.d" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="3" md="3" lg="3" xl="3"
            ><span>{{ getTranslate("patient.cardiology.lblAPV") }}</span
            ><br />
            <b-form-input v-model="jsonData.apv" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="3" md="3" lg="3" xl="3"
            ><span>{{ getTranslate("patient.cardiology.lblAPD") }}</span
            ><br />
            <b-form-input v-model="jsonData.apd" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><hr />
            <h5>{{ getTranslate("patient.cardiology.lblTricuspid") }}</h5></b-col
          ><b-col xs="12" sm="8" md="8" lg="8" xl="8"
            ><span>{{ getTranslate("patient.cardiology.lbl2D") }}</span
            ><br />
            <b-form-input v-model="jsonData.dueDTricuspide" type="text"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblIT") }}</span
            ><br />
            <b-form-select v-model="jsonData.it" :options="opzioniPlus4"></b-form-select></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblVMax") }}</span
            ><br />
            <b-form-input v-model="jsonData.vMaxTricuspide" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblVMaxIT") }}</span
            ><br />
            <b-form-input v-model="jsonData.vMaxIt" type="number"></b-form-input></b-col
          ><b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><span>{{ getTranslate("patient.cardiology.lblDoppler") }}</span
            ><br />
            <b-form-textarea v-model="jsonData.dopplerTricuspide" rows="3" no-resize></b-form-textarea></b-col
          ><b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><hr />
            <h5>{{ getTranslate("patient.cardiology.lblPericardium") }}</h5></b-col
          ><b-col xs="12" sm="12" md="12" lg="12" xl="12"> <b-form-textarea v-model="jsonData.pericardio" rows="3" no-resize></b-form-textarea></b-col
          ><b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><hr />
            <h5>{{ getTranslate("patient.cardiology.lblConclusions") }}</h5></b-col
          ><b-col xs="12" sm="12" md="12" lg="12" xl="12"> <b-form-textarea v-model="jsonData.conclusioni" rows="3" no-resize></b-form-textarea></b-col
        ></b-row>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  components: { SaPageLayout, PazienteAnagrafica },
  data() {
    return {
      linkback: null,
      pathResource: "/cardiorefertazione",
      jsonData: {},
      showModalLoading: false,
      opzioniMalattia: [
        { text: "No", value: "no" },
        { text: "Si", value: "yes" },
        { text: "Ictus -- sopravvissuti", value: "Ictus -- sopravvissuti" },
        { text: "Ictus -- non sopravvissuti", value: "Ictus -- non sopravvissuti" },
        { text: "TIA", value: "TIA" },
        { text: "Vivente", value: "Vivente" },
        { text: "Deceduto", value: "Deceduto" },
      ],
      opzioniDislipidemia: [
        { text: "No", value: "no" },
        { text: "Ipertriglicemica", value: "Ipertriglicemica" },
        { text: "Ipercolesterolemica", value: "Ipercolesterolemica" },
        { text: "Mista", value: "Mista" },
      ],
      opzioniSede1: [
        { text: "Anteriore", value: "anteriore" },
        { text: "Inferiore", value: "inferiore" },
        { text: "Laterale", value: "laterale" },
        { text: "Apicale", value: "apicale" },
        { text: "Settale", value: "settale" },
      ],
      opzioniSede2: [
        { text: "Anteriore", value: "anteriore" },
        { text: "Laterale", value: "laterale" },
        { text: "Apicale", value: "apicale" },
        { text: "Settale", value: "settale" },
      ],
      opzioniSiNo: [
        { text: "Sì", value: true },
        { text: "No", value: false },
      ],
      opzioniImpedenza: [
        { text: "Normale", value: "normal" },
        { text: "Alta", value: "high" },
      ],
      opzioniLavoro: [
        { text: "-", value: "-" },
        { text: "Sedentaria", value: "sedentaria" },
        { text: "Moderata", value: "moderata" },
        { text: "Stressante", value: "stressante" },
        { text: "Turnista", value: "turnista" },
      ],
      opzioniStudio: [
        { text: "Nessuno", value: "nessuno" },
        { text: "Scuola Elementare", value: "scuola-elementare" },
        { text: "Scuola Media", value: "scuola-media" },
        { text: "Scuola Superiore", value: "scuola-superiore" },
        { text: "Diploma Accademico", value: "diploma-accademico" },
        { text: "Laurea", value: "laurea" },
      ],
      opzioniAlcohol: [
        { text: "<1/2 Lt", value: "<1/2 Lt" },
        { text: "1/2 Lt", value: "1/2 Lt" },
        { text: "1-2 Lt", value: "1-2 Lt" },
        { text: ">2 Lt", value: ">2 Lt" },
      ],
      opzioniSegmenti: [
        { text: "Acinetico/a", value: "akinetic" },
        { text: "Aneurismatico/a", value: "aneurismal" },
        { text: "Discinetico/a", value: "dyskinetic" },
        { text: "Ipocintico/a", value: "hypokinetic" },
        { text: "Normocinetico/a", value: "normokinetic" },
      ],
      opzioniPlus4: [
        { text: "++++/4", value: "++++/4" },
        { text: "+++/4", value: "+++/4" },
        { text: "++/4", value: "++/4" },
        { text: "+/4", value: "+/4" },
      ],
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient: {
      get: function() {
        if (this.utente.gruppo.menuGruppo) {
          if (this.utente.gruppo.menuGruppo.length > 1) {
            return "/paziente/view/" + this.idPaziente;
          } else {
            if (this.utente.gruppo.menuGruppo.length == 1) {
              if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                return "/paziente/view/" + this.idPaziente;
              } else {
                return "/paziente/homepaziente";
              }
            } else {
              return "";
            }
          }
        } else {
          return "";
        }
      },
    },
  },
  mounted() {
    let me = this;
    me.jsonData.idPaziente = this.$route.query.idPaziente;
    me.jsonData.tipoEvento = "Cardiologia - Anamnesi";
    me.id = this.$route.params.id;
    me.linkedit = "/paziente/cardiologia/refertazione/edit/" + me.id + "?idPaziente=" + me.idPaziente;
    if (me.id !== "-1") {
      me.linkback = "/paziente/cardiologia/refertazione/view/" + me.id + "?idPaziente=" + me.idPaziente;
      me.loadData();
    } else {
      me.linkback = "/paziente/cardiologia/refertazione?idPaziente=" + me.idPaziente;
    }
  },

  methods: {
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        });
      }
    },
    getTranslate(text) {
      return this.$i18n.t(text);
    },
  },
};
</script>
