<template>
  <b-card>
    <b-card-body>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <p>
            <b>{{ getLabelTraduora("patient.afa.lblWeight") }}:</b> {{ data.peso }}
          </p>
        </b-col>

        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <p>
            <b>{{ getLabelTraduora("patient.afa.lblHeight") }}:</b>
            {{ data.altezza }}
          </p>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <p>
            <b>{{ getLabelTraduora("patient.afa.lblBMI") }}:</b> {{ data.bmi }}
          </p>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <p>
            <b>{{ getLabelTraduora("patient.afa.lblBSA") }}:</b> {{ data.bsa }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblTBW") }}</span>
          <b-form-input type="number" step="0.01" v-model="data.tbw" :disabled="!edit"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblECW") }}</span>
          <b-form-input type="number" step="0.01" v-model="data.ecw" :disabled="!edit"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblBasalMetabolism") }}</span>
          <b-form-input type="number" step="0.01" v-model="data.metabolismoBasale" :disabled="!edit"></b-form-input>
        </b-col>
      </b-row>
      <b-row
        ><b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblLeanMass") }}</span>
          <b-form-input type="number" step="0.01" v-model="data.massaMagra" :disabled="!edit"></b-form-input
        ></b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblFatMass") }}</span>
          <b-form-input type="number" step="0.01" v-model="data.massaGrassa" :disabled="!edit"></b-form-input
        ></b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblMuscleMass") }}</span>
          <b-form-input type="number" step="0.01" v-model="data.massaMuscolare" :disabled="!edit"></b-form-input></b-col
      ></b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";

export default {
  mixins: [UtilityMixin],
  props: ["data", "edit"],
  data() {
    return {};
  },
};
</script>
