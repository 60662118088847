<template>
  <div>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.afa.lblRestingHR") }}</strong>
            <br />
            {{ jsonData.hr1 }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.afa.lblAfterStressHR") }}</strong>
            <br />
            {{ jsonData.hr2 }}
          </b-col>
          <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.afa.lblRestingPR") }}</strong>
            <br />
            {{ jsonData.pr1 }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.afa.lblAfterStressPR") }}</strong>
            <br />
            {{ jsonData.pr2 }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.afa.lblRestingQT") }}</strong>
            <br />
            {{ jsonData.qt1 }}
          </b-col>
          <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.afa.lblAfterStressQT") }}</strong>
            <br />
            {{ jsonData.qt2 }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.afa.lblRestingQTC") }}</strong
            ><br />
            {{ jsonData.qtcRiposo }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.afa.lblPulsation") }}</strong
            ><br />
            {{ jsonData.hr3 }}
          </b-col>
          <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.afa.lblIri") }}</strong>
            <br />
            {{ jsonData.iri }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
            <strong>{{ this.$i18n.t("patient.afa.lblIriJudgement") }}</strong>
            <br />
            {{ jsonData.giudizioIri }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
            <strong>{{ this.$i18n.t("patient.afa.lblReport") }}</strong
            ><br />
            {{ jsonData.referto }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <strong>Stato</strong><br />
            {{ jsonData.firmaEcg }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
  </div>
</template>
<script>
export default {
  props: {
    jsonData: {
      Type: Object,
      require: true,
    },
  },
};
</script>
