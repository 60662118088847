<template>
  <div>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblDateVisit sss") }}</strong>
            <br />
            {{ formatDate(jsonData.dataEvento) }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblSportPracticed") }}</strong>
            <br />
            {{ jsonData.sportPraticato }}
          </b-col>
          <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblFollowingVisit") }}</strong>
            <br />
            {{ formatDate(jsonData.visitaSuccessiva) }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblWeeklyWorkouts") }}</strong>
            <br />
            {{ jsonData.allenamentiSettimana }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblSpeciality") }}</strong>
            <br />
            {{ jsonData.specialita }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblFederation") }}</strong>
            <br />
            {{ jsonData.federazione }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblOtherSports") }}</strong>
            <br />
            {{ jsonData.altriSport }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblAntitetanus") }}</strong>
            <br />
            {{ jsonData.antitetanica }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblAlcohol") }}</strong>
            <br />
            {{ jsonData.alcool }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblNarcoticSubstances") }}</strong>
            <br />
            {{ jsonData.sostenzeStupefacenti }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblNextVisit") }}</strong>
            <br />
            {{ formatDate(jsonData.prossimaVisita) }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblHourNextVisit") }}</strong>
            <br />
            {{ jsonData.oraAppuntamento }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblYearsNumber") }}</strong>
            <br />
            {{ jsonData.numeroAnni }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblHoursWorkout") }}</strong>
            <br />
            {{ formatTime(jsonData.oreAllenamento) }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblCorporation") }}</strong>
            <br />
            {{ jsonData.societa }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblLHB") }}</strong>
            <br />
            {{ jsonData.aslNew }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblMenarche") }}</strong>
            <br />
            {{ jsonData.menarca }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblSmoking") }}</strong>
            <br />
            {{ jsonData.fumo }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblCoffee") }}</strong>
            <br />
            {{ jsonData.caffe }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblMilitary") }}</strong>
            <br />
            {{ jsonData.militare }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblMilitaryMotif") }}</strong>
            <br />
            {{ jsonData.militareMotivo }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
            <strong>{{ getLabelTraduora("patient.afa.lblIdentityDocument") }}</strong>
            <br />
            {{ jsonData.documentoIdentitaTipo }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
            <strong>{{ getLabelTraduora("patient.afa.lblIdentityDocumentNumber") }}</strong>
            <br />
            {{ jsonData.documentoIdentitaNumero }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
            <strong>{{ getLabelTraduora("patient.afa.lblIdentityDocumentReleaser") }}</strong>
            <br />
            {{ jsonData.documentoIdentitaRilasciatoDa }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
            <strong>{{ getLabelTraduora("patient.afa.lblIdentityDocumentDateRelease") }}</strong>
            <br />
            {{ formatDate(jsonData.documentoIdentitaRilasciatoIl) }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
  </div>
</template>
<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";
export default {
  mixins: [UtilityMixin],
  props: {
    jsonData: { Type: Object, require: true },
  },
};
</script>
