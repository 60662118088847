var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sa-page-layout',{ref:"pgaView",attrs:{"btnBackVisible":true,"editVisible":false,"titolo":_vm.data.nomeCsv,"linkback":_vm.pagelink,"linkedit":_vm.linkedit,"showModalLoading":_vm.showModalLoading}},[_c('template',{slot:"table-filter"},[_c('div',{staticStyle:{"max-height":"18rem","overflow":"auto","scrollbar-color":"#cacaca transparent","scrollbar-width":"thin"}},[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.tamponeStato,"fields":_vm.fieldsStatoTampone,"head-variant":"light","sort-icon-left":""},scopedSlots:_vm._u([{key:"cell(stato)",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',{staticClass:"sa-text-bold"},[_vm._v(_vm._s(item.stato))])])]}},{key:"cell(log)",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',[_vm._v(_vm._s(item.log))])])]}},{key:"cell(jsonTampone)",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',[_vm._v(_vm._s(item.jsonTampone))])])]}}])})],1)]),_c('template',{slot:"table-body"},[_c('div',{staticStyle:{"display":"flex","height":"100%","flex-direction":"column"}},[_c('div',{staticStyle:{"max-height":"10rem"}}),_c('div',{staticStyle:{"height":"100%"}},[_c('div',{staticClass:"b-table-sticky-header"},[_c('b-table',{attrs:{"responsive":"","striped":"","hover":"","items":_vm.datiDettaglioTampone,"fields":_vm.fieldsTamponi,"head-variant":"light","sort-icon-left":""},scopedSlots:_vm._u([{key:"cell(key)",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',{staticClass:"sa-text-bold"},[_vm._v(_vm._s(item.key))])])]}},{key:"cell(value)",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',[_vm._v(_vm._s(item.value))])])]}}])})],1)])])]),_c('template',{slot:"footer"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }