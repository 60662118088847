<template>
  <sa-page-layout :btnNewVisible="true" :btnRefreshVisible="true" :linkedit="linkedit" @refresh="onRefresh" :pathResource="pathResource" :showModalLoading="showModalLoading">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-header">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ getLabelTraduora("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" :filter="filter" responsive stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template v-slot:cell(id)="{ item }">
            <router-link class="sa-edit-link" :to="'/paziente/refertazioneecocardio/view/' + item.id"> {{ item.id }} </router-link>
          </template>
          <template v-slot:cell(actions)="row">
            <b-button size="sm" class="mr-1" variant="secondary" @click="onAvviaEcocardio(row.item, row.index, $event.target)"> <b-icon icon="display" /> Avvia </b-button>
          </template>
        </b-table>
      </div>
    </template>
    <template slot="table-footer">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ getLabelTraduora("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import SaPageLayout from "../../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../../components/PazienteAnagrafica.vue";
import UtilityMixin from "../../../../../utility/UtilityMixin";
export default {
  mixins: [UtilityMixin],
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  components: { SaPageLayout, PazienteAnagrafica },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    rows() {
      try {
        return this.items.length;
      } catch {
        return 1;
      }
    },
    linkPatient: {
      get: function() {
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length == 1) {
              if (this.utente.gruppo.menuGruppo[0].primaryKey === "homestudiomedico") {
                return "/paziente/view/" + this.idPaziente;
              } else if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                return "view/" + this.idPaziente;
              } else {
                return "homepaziente";
              }
            } else {
              return "";
            }
          } else {
            return "";
          }
        } else {
          return "";
        }
      },
    },
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      filter: null,
      id: "-1",
      pathResource: "/cardiologiaecocardirefertazioni",
      pagelink: "/paziente/refertazioneecocardio",
      linkedit: "/paziente/refertazioneecocardio",
      filtro: {},
      showModalLoading: false,
      fields: [
        {
          label: "ID",
          key: "id",
          sortable: true,
          //thStyle: "width: 7rem",
        },
        {
          label: "Codice Medico",
          key: "codiceMedicoPresente",
          sortable: true,
        },
        {
          label: "Data Creazione",
          key: "createDate",
          sortable: true,
          formatter: (value) => {
            return moment(value).format("DD-MM-YYYY");
          },
        },
        { key: "actions", label: "", thStyle: "width: 8rem" },
      ],
      items: [],
    };
  },
  created: function() {},
  mounted() {
    let me = this;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "?idPaziente=";
      axios
        .get(link + me.idPaziente, { params: me.filtro })
        .then((response) => {
          me.items = [];
          me.items = response.data.data;
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onReset() {
      let me = this;
      me.filtro = {};
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onAvviaEcocardio(item) {
      let me = this;
      // me.$router.replace("/paziente/teleconsulti/" + item.id + "?idPaziente=" + this.idPaziente).catch((err) => {
      //     err;
      // });
      me.$router.replace("/paziente/ecocardio/" + item.id + "?idPaziente=" + this.idPaziente).catch((err) => {
        err;
      });
    },
  },
};
</script>
