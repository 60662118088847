<template>
    <div>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                        <strong>Trofismo </strong><br />
                        {{ data.trofismo }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                        <strong>Peso(kg) </strong><br />
                        {{ data.peso }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                        <strong>Altezza(cm) </strong><br />
                        {{ data.altezza }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                        <strong>B.M.I </strong><br />
                        {{ data.bmi }}
                    </b-col>
                </b-row>
                <hr />
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                        <strong>Apparato Locomotore </strong><br />
                        {{ data.apparatoLocomotore }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                        <strong>Torace </strong><br />
                        {{ data.torace }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                        <strong>Apparato Respiratorio </strong><br />
                        {{ data.apparatoRespiratorio }}
                    </b-col>
                </b-row>
                <hr />
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                        <strong>Apparato Cardiocircolatorio </strong><br />
                        {{ data.apparatoCardiocircolatorio }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                        <strong>P.A. riposo </strong><br />
                        {{ data.pressioneArteriosaRiposoSistolica }} / {{ data.pressioneArteriosaRiposoDiastolica }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                        <strong>Addome </strong><br />
                        {{ data.addome }}
                    </b-col>
                </b-row>
                <hr />
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                        <strong>Organi genitali </strong><br />
                        {{ data.organiGenitali }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                        <strong>Arti </strong><br />
                        {{ data.arti }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                        <strong>Acuità visiva naturale </strong><br />
                        O.D. {{ data.acuitaVisivaNaturaleOcchioDestro }}/10 O.S. {{ data.acuitaVisivaNaturaleOcchioSinistro }}/10
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                        <strong>Corretta </strong><br />
                        O.D. {{ data.correttaOcchioDestro }}/10 O.S. {{ data.correttaOcchioSinistro }}/10
                    </b-col>
                </b-row>
                <hr />
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                        <strong>Senso Cormatico </strong><br />
                        {{ data.sensoCromatico }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                        <strong>Obbligo lenti correttive </strong><br />
                        <b-icon v-if="data.obbligoLentiCorrettive" class="sa-size-icon-15" icon="check2"></b-icon>
                        <b-icon v-else icon="exclamation-triangle" variant="danger"></b-icon>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                        <strong>Udito </strong><br />
                        {{ data.udito }}
                    </b-col>
                </b-row>
                <hr />
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                        <strong>Conclusioni esame obiettivo </strong><br />
                        {{ data.conclusioniEsameObiettivo }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                        <strong>Note su scheda di valutazione </strong><br />
                        {{ data.noteSuSchedaDiValutazione }}
                    </b-col>
                </b-row>
                <hr />
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <strong>Stato </strong><br />
                        {{ data.firma }}
                    </b-col>
                </b-row>
                <hr />
            </b-col>
        </b-row>
    </div>
</template>
<script>
export default {
    components: {},
    props: {
        data: {
            Type: Object,
            default: function() {
                return {
                    trofismo: "",
                    peso: null,
                    altezza: null,
                    apparatoLocomotore: "",
                    torace: "",
                    apparatoRespiratorio: "",
                    apparatoCardiocircolatorio: "",
                    pressioneArteriosaRiposoSistolica: null,
                    pressioneArteriosaRiposoDiastolica: null,
                    addome: "",
                    organiGenitali: "",
                    arti: "",
                    acuitaVisivaNaturaleOcchioDestro: null,
                    acuitaVisivaNaturaleOcchioSinistro: null,
                    correttaOcchioDestro: null,
                    correttaOcchioSinistro: null,
                    sensoCromatico: "",
                    obbligoLentiCorrettive: null,
                    udito: "",
                    conclusioniEsameObiettivo: "",
                    noteSuSchedaDiValutazione: "",
                    firma: "",
                    bmi: "",
                };
            },
        },
    },
};
</script>
