<template>
  <div>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblFamilyAnamnesis") }}</strong
            ><br />
            {{ jsonData.anamnesiFamiliare }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblPathologicalAnamnesis") }}</strong
            ><br />
            {{ jsonData.anamnesiPatologica }}
          </b-col>
          <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblInjuries") }}</strong
            ><br />
            {{ jsonData.infortuni }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblPhysiologicalAnamnesis") }}</strong
            ><br />
            {{ jsonData.anamnesiFisiologica }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblSurgicalInterventions") }}</strong
            ><br />
            {{ jsonData.interventiChirurgici }}
          </b-col>
          <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
            <strong>{{ getLabelTraduora("patient.afa.lblSportAnamnesis") }}</strong
            ><br />
            {{ jsonData.anamnesiSportiva }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
  </div>
</template>
<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";

export default {
  mixins: [UtilityMixin],
  props: {
    jsonData: {
      Type: Object,
      require: true,
    },
  },
};
</script>
