<template>
    <div>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                        <strong>Anamnesi Familiare </strong><br />
                        {{ data.anamnesiFamiliare }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                        <strong>Anamnesi Fisiologica </strong><br />
                        {{ data.anamnesiFisiologica }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                        <strong>Fumo </strong><br />
                        {{ data.fumo }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                        <strong>Alcool </strong><br />
                        {{ data.alcool }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                        <strong>Anamnesi Patologica malettie sofferte </strong><br />
                        {{ data.anamnesiPatologicaMalattieSofferte }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                        <strong>Interventi Chirurgici </strong><br />
                        {{ data.interventiChirurgici }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <strong>Infortuni </strong><br />
                        {{ data.infortuni }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <strong>Stato </strong><br />
                        {{ data.firma }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        data: {
            Type: Object,
            default: function() {
                return {
                    anamnesiFamiliare: "",
                    anamnesiFisiologica: "",
                    fumo: "",
                    alcool: "",
                    anamnesiPatologicaMalattieSofferte: "",
                    interventiChirurgici: "",
                    infortuni: "",
                };
            },
        },
    },
    data() {
        return {
            pathRestFirma: "/certificatianamnesi",
        };
    },
};
</script>
