<template>
    <sa-page-layout :btnAnnullaVisible="true" :btnSaveVisible="true" :linkback="linkback" :data="jsonData" :pathResource="pathResource" :showModalLoading="showModalLoading">
        <template slot="toolbar">
            <b-button v-if="!edit" variant="outline-secondary sa-margin-right float-sm-right" @click="onClickAgenda" size="sm"> <b-icon icon="calendar"></b-icon> Cambia Prenotazione</b-button>
        </template>
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <div class="sa-form-section">
                    <b-row>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <strong>Nome</strong>
                            <b-form-input v-model="jsonData.nome" placeholder="Nome"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <strong>Cognome</strong>
                            <b-input-group class="mb-3">
                                <b-form-input v-model="jsonData.cognome" placeholder="Cogome"></b-form-input>
                                <b-button size="sm" variant="" @click="onClickRicercaCognome" v-b-modal.mdlRicercaAnagrafica> <b-icon icon="search"></b-icon> </b-button>
                            </b-input-group>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <strong>Data Nascita</strong>
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataNascita" type="timestamp" placeholder="Data Nascita"></date-picker>
                        </b-col>
                    </b-row>
                </div>
                <div class="sa-form-section">
                    <b-row>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <strong>Tipo Atleta</strong>
                            <b-form-select v-model="jsonData.tipoAtleta" :options="tipiAtletaOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <strong>Sport</strong>
                            <b-form-select v-model="jsonData.sport" :options="sportOptions" :value="null" value-field="codiceSport" text-field="sport" @input="onInputTipoSport($event)"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <strong>Tipo Visita</strong>
                            <b-form-input v-model="jsonData.tipoVisita" placeholder="Tipo Visita"></b-form-input>
                        </b-col>
                    </b-row>
                </div>
                <div class="sa-form-section">
                    <b-row v-if="edit">
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <strong>Data Prenotazione</strong>
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" :disabled-date="notBefore" v-model="jsonData.dataPrenotazione" type="timestamp" placeholder="Data Prenotazione"></date-picker>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <strong>Data Visita</strong>
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" :disabled-date="notBefore" v-model="jsonData.dataVisita" type="timestamp" placeholder="Data Visita" @change="onChangeDataVisita"></date-picker>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <strong>Agenda</strong>
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.idAgenda" :options="agendeOptions" :value="null" value-field="id" text-field="titolo" @input="onInputAgenda($event)" :disabled="isAgendaDisabled"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <strong>Orario Visita</strong>
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.oraVisita" :options="disponibilitaOptions" :value="null" value-field="dalleOre" text-field="text" @change="onChangeOrarioVisita($event)"></b-form-select>
                        </b-col>
                    </b-row>
                    <b-row v-else>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-row>
                                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                    <strong>Data Prenotazione</strong><br />
                                    {{ formatDate(jsonData.dataPrenotazione) }}
                                </b-col>
                                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                    <strong>Data Visita</strong><br />
                                    {{ formatDate(jsonData.dataVisita) }}
                                </b-col>
                                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                    <strong>Orario Visita</strong><br />
                                    {{ jsonData.oraVisita }}
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </div>

                <div class="sa-form-section">
                    <b-row>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <strong>Data Scadenza Certificato</strong>
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.scadenzaCertificato" type="timestamp" placeholder="Data Scadenza Certificato"></date-picker>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <strong>Telefono</strong>
                            <b-form-input v-model="jsonData.telefono" placeholder="Telefono"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <strong>Email</strong>
                            <b-form-input v-model="jsonData.email" placeholder="Email"></b-form-input>
                        </b-col>
                    </b-row>
                </div>
                <div class="sa-form-section">
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <strong>Note Aggiuntive</strong>
                            <b-form-textarea v-model="jsonData.noteAggiuntive" no-resize></b-form-textarea>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </template>
        <template slot="table-footer">
            <b-modal ref="mdlRicercaAnagrafica" id="mdlRicercaAnagrafica" size="xl" style="height: 100%;" @show="onShow" @ok="onOk" @cancel="jsonData.cognome = ''">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-input-group class="mb-3">
                            <b-form-input @keypress="onKeyPress" v-model="jsonData.cognome" placeholder="Cogome"></b-form-input>
                            <b-button size="sm" variant="" @click="onSearchAnagraficaClick"> <b-icon icon="search"></b-icon> </b-button>
                        </b-input-group>
                    </b-col>
                </b-row>
                <div class="b-table-sticky-header">
                    <b-table sticky-header ref="table" stacked="xl" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table" selectable select-mode="single" @row-selected="onRowSelected">
                        <template #cell(index)="item">
                            {{ item.index + 1 }}
                        </template>
                        <template v-slot:cell(nominativo)="{ item }"> {{ item.nome }} {{ item.cognome }} </template>
                    </b-table>
                </div>
            </b-modal>
        </template>
    </sa-page-layout>
</template>
<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import DatePicker from "vue2-datepicker";
import axios from "axios";
import moment from "moment";
export default {
    components: { SaPageLayout, DatePicker },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        if (me.id === "-1") {
            me.linkback = "/prenotazioni";
        } else {
            me.linkback = "/prenotazioni/view/" + me.id;
        }
        me.loadData();
        me.loadAgende();
        me.loadTipiSport();
    },
    data() {
        return {
            showModalLoading: false,
            pathResource: "/prenotazioni",
            id: "",
            linkAgenda: "/agende/view/",
            linkback: null,
            linkSport: "/tipisport",
            pathResourceAgenda: "/agende",
            pathResourceAgendaDisponibilita: "/agendadisponibilita",
            edit: true,
            isAgendaDisabled: true,
            currentPage: 1,
            perPage: 10,
            items: [],
            rigaSelezionata: {},
            sportOptions: [],
            dataAgenda: {},
            agendeOptions: [{ id: null, titolo: "-Seleziona Agenda-" }],
            disponibilitaOptions: [{ dalleOre: null, text: "-Seleziona Orario Visita-" }],
            tipiAtletaOptions: [
                { value: null, text: "-Seleziona Valore-" },
                { value: "AGONISTA", text: "AGONISTA" },
                { value: "NON AGONISTA", text: "NON AGONISTA" },
                { value: "AGONISTA PRIVATO", text: "AGONISTA PRIVATO" },
            ],
            jsonData: { nome: "", cognome: "", dataNascita: null, sport: null, tipoVisita: "", noteAggiuntive: "", idAgenda: null, dataVisita: null, oraVisita: null, oraFineVisita: "", tipoAtleta: null, dataPrenotazione: Date.now(), scadenzaCertificato: null, telefono: "", email: "" },
            fields: [
                {
                    label: "Nominativo",
                    key: "nominativo",
                    sortable: true,
                },
                {
                    label: "Data Nascita",
                    key: "dataNascita",
                    formatter: (value) => {
                        return value ? moment(new Date(value)).format("DD/MM/YYYY") : "";
                    },
                    thStyle: "width: 10rem",
                    sortable: true,
                },
                {
                    label: "Telefono",
                    key: "telefono",
                    sortable: true,
                    thStyle: "width: 10rem",
                },
            ],
        };
    },
    methods: {
        loadData() {
            let me = this;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                me.showModalLoading = true;
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.jsonData = response.data.data;
                    me.edit = false;
                    me.showModalLoading = false;
                });
            }
        },
        loadTipiSport() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkSport;
            axios.get(link).then((response) => {
                me.sportOptions = response.data.data;
                me.sportOptions.unshift({ codiceSport: null, sport: "-Seleziona Sport-" });
            });
        },
        onInputTipoSport(value) {
            let me = this;
            me.sportOptions.forEach((sportSelezionato) => {
                if (sportSelezionato.codiceSport === value) {
                    me.jsonData.tipoVisita = sportSelezionato.tipoTar;
                }
            });
        },
        loadAgende() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceAgenda;
            me.showModalLoading = true;
            axios
                .get(link)
                .then((response) => {
                    me.dataAgenda = response.data.data;
                    me.agendeOptions = me.dataAgenda;
                    me.agendeOptions.unshift({ id: null, titolo: "-Seleziona Agenda-" });
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onInputAgenda(value) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceAgendaDisponibilita;
            console.log(link);
            axios
                .get(link, {
                    params: {
                        idAgenda: value,
                        periodo: "G",
                        riferimento: moment(me.jsonData.dataVisita).format("YYYY-MM-DD"),
                        codicePrestazione: me.jsonData.tipoVisita,
                    },
                })
                .then((response) => {
                    if (response.data.data !== null) {
                        if (response.data.data.length > 0) {
                            response.data.data[0].disponibilita.forEach((element) => {
                                if (!element.appuntamento) {
                                    me.disponibilitaOptions.push({
                                        dalleOre: element.dalleOre,
                                        text: element.dalleOre + " - " + element.alleOre,
                                    });
                                }
                            });
                        }
                    }
                })
                .catch((err) => {
                    this.$bvModal.msgBoxOk(err.response.data.messaggio, {
                        title: [this.$i18n.t("agenda.lblAttenction")],
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    });
                });
        },
        onClickAgenda() {
            let me = this;
            me.$router.replace(me.linkAgenda + me.jsonData.idAgenda).catch((err) => {
                err;
            });
        },
        onChangeOrarioVisita(value) {
            let me = this;
            me.disponibilitaOptions.forEach((element) => {
                if (element.dalleOre === value) {
                    let x = element.text.split(" - ");
                    me.jsonData.oraFineVisita = x[1];
                    console.log(me.jsonData.oraFineVisita);
                }
            });
        },
        onChangeDataVisita() {
            let me = this;
            me.jsonData.oraVisita = null;
        },
        onKeyPress(e) {
            let me = this;
            if (e.keyCode === 13) {
                me.onSearchAnagraficaClick();
            }
        },
        onClickRicercaCognome() {
            let me = this;
            me.$refs.mdlRicercaAnagrafica.show();
            me.onSearchAnagraficaClick();
        },
        notBefore: function(date) {
            return date < Date.now() - 86400000;
        },
        onShow() {
            let me = this;
            me.items = [];
        },
        onSearchAnagraficaClick() {
            let me = this;
            me.jsonData.cognome = me.jsonData.cognome.toUpperCase();
            let link = process.env.VUE_APP_PATH_API + "/anagrafica";
            axios
                .get(link, { params: { cognome: me.jsonData.cognome } })
                .then((response) => {
                    me.items = [];
                    response.data.data.forEach((element) => {
                        me.items.push(element);
                    });
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onRowSelected(items) {
            let me = this;
            if (items.length > 0) {
                me.rigaSelezionata = items[0];
            }
        },
        onOk() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + "/visite/ultimavisita";
            axios
                .get(link, { params: { idAtleta: me.rigaSelezionata.id } })
                .then((response) => {
                    me.jsonData.nome = me.rigaSelezionata.nome;
                    me.jsonData.cognome = me.rigaSelezionata.cognome;
                    me.jsonData.dataNascita = me.rigaSelezionata.dataNascita;
                    me.jsonData.telefono = me.rigaSelezionata.telefono;
                    me.jsonData.email = me.rigaSelezionata.email;
                    me.jsonData.tipoAtleta = response.data.data.tipoAttleta;
                    me.jsonData.sport = response.data.data.sport;
                    me.jsonData.tipoVisita = response.data.data.tipoVisita;
                    me.jsonData.scadenzaCertificato = response.data.data.dataScadenza;
                })
                .catch(() => {
                    me.showModalLoading = false;
                    me.jsonData.nome = me.rigaSelezionata.nome;
                    me.jsonData.cognome = me.rigaSelezionata.cognome;
                    me.jsonData.dataNascita = me.rigaSelezionata.dataNascita;
                    me.jsonData.telefono = me.rigaSelezionata.telefono;
                    me.jsonData.email = me.rigaSelezionata.email;
                });
        },
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY");
            } else {
                return "---";
            }
        },
    },
    watch: {
        "jsonData.sport": {
            immediate: true,
            handler(value) {
                let me = this;
                if (value === null) {
                    me.jsonData.scadenzaCertificato = null;
                }
            },
        },
        "jsonData.dataVisita": {
            immediate: true,
            handler(value) {
                let me = this;
                if (value === null) {
                    me.isAgendaDisabled = true;
                } else {
                    me.isAgendaDisabled = false;
                }
            },
        },
    },
};
</script>
