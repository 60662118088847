<template>
    <div>
        <a v-if="isSomministrato()" :id="getSlotId()" href="#" onclick="return false;"><font-awesome-icon v-if="isSomministrazione()" :class="getColor()" class="sa-icon-large" icon="pills"></font-awesome-icon> </a>
        <a v-else :id="getSlotId()" href="#" onclick="return false;"><font-awesome-icon v-if="isSomministrazione()" @click="onOpenModalSomministrazione()" :class="getColor()" class="sa-icon-large" icon="pills"></font-awesome-icon> </a>
        <div style="display: block">{{ getDataSomministrazione() }}</div>

        <b-popover v-if="isSomministrato()" :target="getSlotId()" triggers="focus" ref="popover">
            <template #title>
                <b-button class="close" aria-label="Close" @click="onClosePopover">
                    <span class="d-inline-block" aria-hidden="true">&times;</span>
                </b-button>
                Somministrazione
            </template>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label for="quantita" class="sa-form-label">{{ this.$i18n.t("therapy.lblQuantity") }}</label>
                    <br />
                    <span>{{ slot == null ? "" : slot.somministrato.quantitaAssunta }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-form-label">{{ this.$i18n.t("therapy.lblDateOfAdministration") }}</label>
                    <br />
                    <span>{{ slot == null ? "" : formatDateTime(slot.somministrato.statoDataOra) }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-form-label">{{ this.$i18n.t("therapy.lblNote") }}</label>
                    <br />
                    <span>{{ slot == null ? "" : slot.somministrato.note }}</span>
                </b-col>
            </b-row>
        </b-popover>
        <b-modal v-model="showHideModel" :id="id" size="lg" hide-footer>
            <paziente-terapia-modal :data="datiSomministrazione" :slotSomministrazione="slot" @closeModal="closeModal"></paziente-terapia-modal>
        </b-modal>
    </div>
</template>

<script>
// import axios from "axios";
import moment from "moment";
import PazienteTerapiaModal from "../components/PazienteTerapiaModalSomministrazioneComponent.vue";
import UtilityMixin from "../../../utility/UtilityMixin.js";
export default {
    mixins: [UtilityMixin],
    props: {
        somministrazioneKey: { type: String },
        somministrazione: { type: Object },
        dataRiferimento: {
            type: Date,
            default: function() {
                return new Date();
            },
        },
        statiSomministrazioneOptions: { type: Array },
    },
    components: { PazienteTerapiaModal },
    data() {
        return {
            disabled: true,
            id: "-1",
            showHideModel: false,
            slot: null,
            datiSomministrazione: { idStato: 1, statoDataOra: new Date().getTime(), quantita: this.somministrazione.quantita, idPaziente: this.somministrazione.idPaziente, idTerapia: this.somministrazione.id },
        };
    },
    created() {
        let me = this;
        me.setSlot();
    },
    mounted() {
        let me = this;
        me.id = me.somministrazioneKey;
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        color(color) {
            return color;
        },
    },
    methods: {
        getSlotId() {
            let me = this;
            let returnValue = me.slot ? moment(new Date(me.slot.ora)).format("YYYYMMDDHHmm") : "";
            return returnValue;
        },
        setSlot() {
            let me = this;
            if (me.somministrazione.slotSomministrazioni) {
                me.somministrazione.slotSomministrazioni.forEach((element) => {
                    if (element.etichetta == me.somministrazioneKey) {
                        me.slot = element;
                    }
                });
            }
        },
        getDataRiferimentoSomministrazione(value) {
            if (value) {
                return moment(new Date(value)).format("YYYYMMDDHH");
            } else {
                return "---";
            }
        },
        onOpenModalSomministrazione() {
            let me = this;
            me.datiSomministrazione.riferimentoSomministrazione = me.getDataRiferimentoSomministrazione(me.slot.ora);
            me.showHideModel = true;
        },
        closeModal(reload) {
            let me = this;
            me.showHideModel = false;
            if (reload) {
                me.$emit("update");
            }
        },
        isSomministrazione() {
            let me = this;

            let returnValue = false;
            let dataRiferimento = me.dataRiferimento.getTime();
            let strDate = moment(dataRiferimento).format("yyyy-MM-DD");
            let arrayItervallo = me.somministrazioneKey.split("-");
            let dataInizio = moment(strDate + " " + arrayItervallo[0] + ":00", "yyyy-MM-DD HH:mm");
            let dataFine = moment(strDate + " " + arrayItervallo[1] + ":00", "yyyy-MM-DD HH:mm");
            if (me.somministrazione.slotSomministrazioni) {
                me.somministrazione.slotSomministrazioni.forEach((element) => {
                    if (element.ora >= dataInizio.toDate().getTime() && element.ora < dataFine.toDate().getTime()) {
                        returnValue = true;
                    }
                });
                return returnValue;
            }
        },
        getColor() {
            let me = this;
            let returnValue = "gray";
            returnValue = me.slot ? me.slot.stateColor : "gray";

            switch (returnValue) {
                case "blue":
                    returnValue = "blue";
                    break;
                case "green":
                    returnValue = "success";
                    break;
                case "yellow":
                    returnValue = "warning";
                    break;
                case "red":
                    returnValue = "danger";
                    break;
                default:
                    returnValue = "gray";
                    break;
            }

            return returnValue;
        },
        isSomministrato() {
            let me = this;
            let returnValue = false;
            if (me.slot) {
                if (me.slot.somministrato) {
                    returnValue = true;
                }
            }
            return returnValue;
        },
        getDataSomministrazione() {
            let me = this;
            let returnValue = "";
            if (me.slot) {
                if (me.slot.somministrato) {
                    returnValue = moment(me.slot.somministrato.statoDataOra).format("HH:mm");
                }
            }
            return returnValue;
        },
        loadDataSomministrazioni() {
            // let me = this;
            // let link = process.env.VUE_APP_PATH_API + me.pathResource + "?idPaziente=" + me.idPaziente;
            // me.isBusy = true;
            // me.showModalLoading = true;
            // axios
            //     .get(link)
            //     .then((response) => {
            //         me.showModalLoading = false;
            //         me.storicoTerapie = response.data.data;
            //     })
            //     .catch(() => {
            //         me.showModalLoading = false;
            //     });
        },
        onClosePopover() {},
    },
};
</script>

<style scoped>
.danger {
    color: red;
}
.success {
    color: green;
}
.blue {
    color: rgb(0, 30, 128);
}
.warning {
    color: #ffc107;
}
.gray {
    color: gray !important;
}
.b-popover {
    max-width: 600px;
}
</style>
